import {useState} from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {formatDateTime} from "../helpers/timeFormatters";

export default function SelectSummary({summaries, setSelectedSummary}) {
  const [summary, setSummary] = useState(false);
  const [summaryType, setSummaryType] = useState(false);

  const summaryTypes = [
    {name: "Brief", field: "briefSummary"},
    {name: "Narrative", field: "narrativeSummary"},
    {name: "Bullet-Point", field: "bulletptSummary"},
    {name: "SOAP", field: "soapSummary"},
    {name: "Comprehensive", field: "comprehensiveSummary"},
  ];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSelectedSummary(
          summaries.find((s) => s.sid == summary)[summaryType]
        );
      }}
    >
      <Box mt="2">
        <FormControl id={"form"}>
          <FormLabel>Attach Appointment Summary</FormLabel>
          <Flex gap="4">
            <Select
              variant="flushed"
              placeholder="Select Appointment Date"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              isRequired={true}
            >
              {summaries?.map(
                (s, key) =>
                  s.processedDatetime && (
                    <option key={key} value={s.sid}>
                      {formatDateTime(s.processedDatetime)}
                    </option>
                  )
              )}
            </Select>
            <Select
              variant="flushed"
              placeholder="Select Summary Type"
              value={summaryType}
              onChange={(e) => setSummaryType(e.target.value)}
              isRequired={true}
            >
              {summaryTypes?.map((st, key) => (
                <option key={key} value={st.field}>
                  {st.name}
                </option>
              ))}
            </Select>
          </Flex>
          <Button px="10" mt="4" colorScheme="blue" type="submit">
            Attach
          </Button>
        </FormControl>
      </Box>
    </form>
  );
}
