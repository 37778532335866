import axios from "axios";
import {url} from "../../../../crudRequests";
import {nanoid} from "nanoid";

export async function deleteProvider(state, provId) {
  let configService = {
    method: "GET",
    url: `${url}/deleteProvider`,
    params: {provId},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService);
}

export default async function removeProvider(provId, dashState, queryFn) {
  const res = await deleteProvider(dashState, provId);
  await queryFn();
  return;
}
