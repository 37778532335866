import {
  Box,
  Flex,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Button,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import {
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {HiOutlinePlus} from "react-icons/hi";
import {IoSearch} from "react-icons/io5";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../../pages/Patients";
import CustomFields from "./CustomFields";
import GroupedSalesList from "./GroupedSalesList";
import SalesList from "./SalesList";
import GroupingFields from "./GroupingFields";
import Filters from "./Filters";
import fetchSales from "../helpers/fetchSales";
import {GiReceiveMoney} from "react-icons/gi";
import {MdClose} from "react-icons/md";

export default function Sales() {
  const p = useContext(PatientContext);
  let patient = p?.[0];

  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);

  const toast = useToast();
  const [prevLocation, setPrevLocation] = useState(dashState.selectedLocation);
  const [fields, setFields] = useState({
    "Patient Name": "pName",
    "Clinician Name": "dName",
    Type: "type",
    "Service Date & Time": "serviceDate",
  });
  const [groupings, setGroupings] = useState([]);
  const [sales, setSales] = useState([]);
  const [applyPayment, setApplyPayment] = useState({amount: 0, from: ""});
  const [page, setPage] = useState(0);
  const limit = 10;
  let loadingRef = useRef(null);
  let rootRef = useRef(null);
  const mirrorTable = useRef([]);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({});
  const [allowSelection, setAllowSelection] = useState("");
  const [selections, setSelections] = useState(new Set());
  const [waiting, setWaiting] = useState(false);
  const isLoadingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingRef.current) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: rootRef.current,
        threshold: 1.0,
      }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    fetchSales(
      dashState,
      page * limit,
      limit,
      setSales,
      (v) => {
        isLoadingRef.current = v;
      },
      toast,
      patient
    );
  }, [page]);

  if (prevLocation !== dashState.selectedLocation) {
    setPrevLocation(dashState.selectedLocation);
    if (page === 0) {
      fetchSales(
        dashState,
        0,
        limit,
        setSales,
        (v) => {
          isLoadingRef.current = v;
        },
        toast,
        patient
      );
    } else setPage(0);
  }

  return (
    <TableContainer
      ref={rootRef}
      maxW="8xl"
      // borderColor="gray.50"
      borderRadius="md"
      boxShadow="md"
      flex={1}
      overflow="hidden"
      display="flex"
      flexDirection={"column"}
    >
      <Box pr="1" flex={1} height={"full"} overflow="scroll">
        <Box p="4" gap="8" mx="2">
          {/*<Box mb="4">
            <GroupingFields
              {...{
                groupings,
                setGroupings,
                setSales,
                patient,
                dashState,
              }}
            />
          </Box>*/}
          <Filters {...{query, setQuery}} />
          <Flex my="2">
            <CustomFields {...{setFields, fields}} />
            <InputGroup ml="5" p="1" pt="0">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" ml="2" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search payments..."
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              />
            </InputGroup>
          </Flex>
        </Box>

        {groupings.length > 0 ? (
          <GroupedSalesList {...{sales, groupings, query}} />
        ) : (
          <SalesList
            {...{
              sales,
              fields,
              query,
              searchText,
              dispatch,
              dashState,
              mirrorTable,
            }}
          />
        )}
        <div ref={loadingRef} />
      </Box>
    </TableContainer>
  );
}
