import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState} from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react";
import {assignForm} from "./helpers/assignForm";

export default function AssignForm({forms, setAssignedForms}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState();

  return (
    <form
      onSubmit={(e) =>
        assignForm(
          e,
          forms,
          selectedForm,
          setAssignedForms,
          patient,
          dashState,
          setIsLoading,
          toast
        )
      }
    >
      <Box maxW={"20rem"} mt="12">
        <FormControl id={"form"}>
          <FormLabel>Assign New Form</FormLabel>
          <Select
            variant="flushed"
            placeholder="-"
            value={selectedForm}
            onChange={(e) => setSelectedForm(e.target.value)}
          >
            {forms.map((form, key) => (
              <option key={key} value={form.fid}>
                {form.formTitle}
              </option>
            ))}
          </Select>
        </FormControl>
        <Button
          isLoading={isLoading}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Assign
        </Button>
      </Box>
    </form>
  );
}
