import {useReducer, useState, useEffect, createContext} from "react";
import {useLocation} from "react-router-dom";
import {scheduleReducer, init} from "../additional_files/reducer";
import {verifyUser} from "../authRequests";
import {Fade} from "@chakra-ui/react";
import Navigation from "../components/Navigation";
import PaymentsTable from "../components/Payments/PaymentsTable";

export const DashStateContext = createContext();
export const DashDispatchContext = createContext();

export default function Payments({
  renderedFromBilling,
  stateFromBilling,
  dispatchFromBilling,
}) {
  return (
    <PaymentsTable
      {...{
        renderedFromBilling,
        stateFromBilling,
        dispatchFromBilling,
      }}
    />
  );
}
