import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
} from "@chakra-ui/react";
import {previewFile} from "./helpers/previewFile";
import {DashStateContext} from "../../../pages/Patients";
import {useContext} from "react";
import {extractFileName} from "./helpers/extractFileName";
import {FileLogsModal} from "./FileLogsModal";

export default function ItemsTable({
  files,
  searchQuery,
  selectedFile,
  setSelectedFile,
  props,
  path,
  setPath,
  setPreview,
  toast,
  isTask,
}) {
  const dashState = useContext(DashStateContext);

  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption>
          {files.length === 0 && (
            <Text fontSize="11px" letterSpacing="0.08rem" textAlign={"center"}>
              NO FOLDERS OR FILES FOUND
            </Text>
          )}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Size</Th>
            <Th>Modified</Th>
          </Tr>
        </Thead>
        <Tbody fontSize={"14px"}>
          {files
            .sort((a, b) => {
              const aDate = parseInt(a.name.split("-")[0].split("_")[1] || 0);
              const bDate = parseInt(b.name.split("-")[0].split("_")[1] || 0);
              return bDate - aDate;
            })
            .map((file) => {
              return (
                file.name
                  .toLowerCase()
                  .includes(searchQuery.trim().toLowerCase()) && (
                  <Tr
                    bg={selectedFile === file.name && `gray.100`}
                    _hover={{
                      bg: selectedFile === file.name ? `gray.100` : `gray.50`,
                    }}
                    onClick={() => {
                      setSelectedFile(
                        selectedFile !== file.name ? file.name : ""
                      );
                    }}
                    onDoubleClickCapture={() => {
                      props?.onClick
                        ? props.onClick({
                            name: file.name,
                            path,
                          })
                        : file.name.slice(-1) === "/"
                        ? setPath(!path.includes(file.name) && path + file.name)
                        : !isTask &&
                          previewFile(
                            dashState,
                            path,
                            file.name,
                            setPreview,
                            toast
                          );
                    }}
                  >
                    <Td w="60%">
                      <Flex>
                        <Box textAlign={"center"} w="42px">
                          <Image
                            mx="auto"
                            src={`/images/Doctor/${
                              file.name.toLowerCase().slice(-1) === "/"
                                ? `folder`
                                : file.name.toLowerCase().includes(".pdf")
                                ? `pdf`
                                : [".doc", ".docx"].some((ext) =>
                                    file.name.toLowerCase().includes(ext)
                                  )
                                ? `word`
                                : [".xls", ".xlsx"].some((ext) =>
                                    file.name.toLowerCase().includes(ext)
                                  )
                                ? `excel`
                                : [".ppt", ".pptx"].some((ext) =>
                                    file.name.toLowerCase().includes(ext)
                                  )
                                ? `powerpoint`
                                : [
                                    ".png",
                                    ".jpg",
                                    ".jpeg",
                                    ".gif",
                                    ".heic",
                                    ".svg",
                                    ".webp",
                                  ].some((ext) =>
                                    file.name.toLowerCase().includes(ext)
                                  )
                                ? `image`
                                : [
                                    ".mp3",
                                    ".mp4",
                                    ".mkv",
                                    ".flv",
                                    ".f4v",
                                    ".swf",
                                    ".avi",
                                    ".mov",
                                    ".wmv",
                                    "webm",
                                    "avchd",
                                  ].some((ext) =>
                                    file.name.toLowerCase().includes(ext)
                                  )
                                ? `media`
                                : `file`
                            }.png`}
                            maxH="8"
                          />
                        </Box>
                        <Text
                          ml="3"
                          mt="1.5"
                          w="40vw"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {extractFileName(file.name, "name")}
                        </Text>
                      </Flex>
                    </Td>
                    <Td w="20%">{extractFileName(file.name, "size")}</Td>
                    <Td w="20%">{extractFileName(file.name, "modified")}</Td>
                  </Tr>
                )
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
