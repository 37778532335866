import axios from "axios";
import {url} from "../../../../crudRequests";
import {nanoid} from "nanoid";

export async function insertProvider(state, data) {
  let configService = {
    method: "POST",
    url: `${url}/insertProvider`,
    data: {...data},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}

export default async function uploadProvider(data, dashState, queryFn) {
  const res = await insertProvider(dashState, {
    ...data,
    oid: dashState.organization.oid,
    lid: dashState.selectedLocation,
  });
  await queryFn();
}
