import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  Text,
  Button,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import {stringifyValue} from "../helpers/stringifyValue";
import {useEffect, useState, useRef} from "react";
import {lightFormat} from "date-fns";
import {getFileData} from "../../../appointments/payment/helpers/getFileData"; //"./helpers/getFileData";
import PreviewFile from "../../../appointments/payment/helpers/PreviewFile";
import {crudStorage} from "../../../appointments/payment/helpers/crudStorage";
import InvoicOrReceipt from "../../../Billing/InvoiceOrReceipt";
export default function SimpleTransactionsList({
  sales: transactions,
  fields,
  query,
  searchText,
  dispatch,
  dashState,
  allowSelection,
  setAllowSelection,
  selections,
  setSelections,
  applyPayment,
  mirrorTable,
}) {
  const [appt, setAppt] = useState(null);
  const toast = useToast();
  const [preview, setPreview] = useState(null);
  const [selectedTr, setSelectedTr] = useState(null);
  const [fetchingFileData, setFetchingFileData] = useState(false);

  function showcCheckbox(transaction) {
    switch (allowSelection) {
      case "cancel":
        return (
          (transaction?.tpp &&
            transaction?.amountPaidByTpp !== transaction?.tppAmount) ||
          transaction?.amountPaidByPatient !== transaction?.patientAmount
        );

      case "payment":
        return (
          (applyPayment.from === "tpp" &&
            transaction?.tpp &&
            transaction?.amountPaidByTpp !== transaction?.tppAmount) ||
          (applyPayment.from === "patient" &&
            transaction?.amountPaidByPatient !== transaction?.patientAmount)
        );

      default:
        return true;
    }
  }

  return (
    <Box overflowY="auto">
      {preview && (
        <PreviewFile
          preview={preview}
          setPreview={setPreview}
          appointment={selectedTr}
          state={dashState}
        />
      )}
      <Table size="md">
        <Thead position="sticky" top="0" bg="gray.100">
          <Tr>
            {allowSelection && <Th fontSize={"11px"}>Selection</Th>}
            {(() => {
              mirrorTable.current = [[]];
            })()}
            <Th fontSize={"11px"}>
              {(() => {
                mirrorTable.current[0].push("Status");
                return "Status";
              })()}
            </Th>
            {Object.keys(fields).map((field) => {
              mirrorTable.current[0].push(field);
              return <Th fontSize={"11px"}>{field}</Th>;
            })}
            <Th fontSize={"11px"}>
              {(() => {
                mirrorTable.current[0].push("Service Cost");
                return "Service Cost";
              })()}
            </Th>
            <Th fontSize={"11px"}>
              {(() => {
                mirrorTable.current[0].push("Cost Paid");
                return "Cost Paid";
              })()}
            </Th>
            <Th fontSize={"11px"}>
              {(() => {
                mirrorTable.current[0].push("Outstanding Balance");
                return "Outstanding Balance";
              })()}
            </Th>
            <Th fontSize={"11px"}>Invoice / Receipt</Th>
          </Tr>
        </Thead>
        <Tbody>
          {(query.fromDate && query.toDate
            ? transactions?.filter((transaction) => {
                const transactionDate = new Date(transaction.serviceDate);
                const fromDate = new Date(query.fromDate);
                const toDate = new Date(query.toDate);

                return transactionDate >= fromDate && transactionDate <= toDate;
              })
            : query.fromAmount || query.toAmount
            ? transactions?.filter((transaction) => {
                let transactionAmount = 0;
                switch (query.amountType) {
                  case "overallOutstandingBalance":
                    transactionAmount =
                      transaction?.tppAmount -
                      transaction?.amountPaidByTpp +
                      (transaction?.patientAmount -
                        transaction?.amountPaidByPatient);
                    break;
                  case "patientOutstandingBalance":
                    transactionAmount =
                      transaction?.patientAmount -
                      transaction?.amountPaidByPatient;
                    break;
                  case "tppOutstandingBalance":
                    transactionAmount =
                      transaction?.tppAmount - transaction?.amountPaidByTpp;
                    break;
                  case "overallCostPaid":
                    transactionAmount =
                      transaction?.amountPaidByTpp +
                      transaction?.amountPaidByPatient;
                    break;
                  case "patientCostPaid":
                    transactionAmount = transaction?.amountPaidByPatient;
                    break;
                  case "tppCostPaid":
                    transactionAmount = transaction?.amountPaidByTpp;
                    break;
                  case "overallServiceCost":
                    transactionAmount =
                      transaction?.tppAmount + transaction?.patientAmount;
                    break;
                  case "patientServiceCost":
                    transactionAmount = transaction?.patientAmount;
                    break;
                  case "tppServiceCost":
                    transactionAmount = transaction?.tppAmount;
                    break;
                }
                const fromAmount = query.fromAmount || 0;
                const toAmount = query.toAmount || Infinity;

                return (
                  transactionAmount >= fromAmount &&
                  transactionAmount <= toAmount
                );
              })
            : transactions
          )
            ?.filter((transaction) =>
              Object.entries(fields).some(([displayKey, field]) =>
                String(
                  stringifyValue(
                    field
                      .split(".")
                      .reduce((o, k) => (o ? o[k] : null), transaction)
                  ),
                  null,
                  displayKey
                )
                  ?.toLowerCase()
                  ?.includes(searchText.toLowerCase())
              )
            )
            ?.map((transaction, i) => {
              mirrorTable.current[i + 1] = [];
              return (
                <Tr key={i}>
                  <Td fontSize={"15px"}>
                    {transaction?.amountPaidByPatient ==
                      transaction?.patientAmount &&
                    transaction?.amountPaidByTpp == transaction?.tppAmount ? (
                      <Badge colorScheme="green" rounded="md" px="2" py="0.5">
                        {(() => {
                          mirrorTable.current[i + 1].push("Fully Paid");
                          return "Fully Paid";
                        })()}
                      </Badge>
                    ) : transaction?.amountPaidByPatient > 0 ||
                      transaction?.amountPaidByTpp > 0 ? (
                      <Badge colorScheme="orange" rounded="md" px="2" py="0.5">
                        {(() => {
                          mirrorTable.current[i + 1].push("Partially Paid");
                          return "Partially Paid";
                        })()}
                      </Badge>
                    ) : new Date(transaction?.serviceDate) > new Date() ? (
                      <Badge colorScheme="blue" rounded="md" px="2" py="0.5">
                        {(() => {
                          mirrorTable.current[i + 1].push("Due at Service");
                          return "Due at Service";
                        })()}
                      </Badge>
                    ) : (
                      <Badge colorScheme="red" rounded="md" px="2" py="0.5">
                        {(() => {
                          mirrorTable.current[i + 1].push("Overdue");
                          return "Overdue";
                        })()}
                      </Badge>
                    )}
                  </Td>
                  {Object.entries(fields).map(([displayKey, field]) => (
                    <Td fontSize={"15px"}>
                      {(() => {
                        let fieldvalue = field
                          .split(".")
                          .reduce((o, k) => (o ? o[k] : null), transaction);

                        let stringValue = stringifyValue(
                          fieldvalue,
                          null,
                          displayKey
                        );
                        mirrorTable.current[i + 1].push(stringValue);

                        if (Array.isArray(fieldvalue)) {
                          return (
                            <ul>
                              {stringValue.split(" \n").map((item, index) => (
                                <li key={index}>
                                  {item.split(" \n").map((itm, idx) => {
                                    if (itm.split(":")[1])
                                      return (
                                        <div key={idx}>
                                          <Text
                                            fontWeight="semibold"
                                            display="inline-block"
                                          >
                                            {itm.split(":")[0]}:
                                          </Text>{" "}
                                          {itm.split(":")[1] || ""}
                                        </div>
                                      );
                                    else return itm;
                                  })}
                                </li>
                              ))}
                            </ul>
                          );
                        } else if (
                          fieldvalue !== null &&
                          typeof fieldvalue === "object"
                        ) {
                          return (
                            <div
                              style={{marginTop: "12px", marginBottom: "12px"}}
                            >
                              {stringValue.split(" \n").map((item, index) => (
                                <div key={index}>
                                  <Text
                                    fontWeight="semibold"
                                    display="inline-block"
                                  >
                                    {item.split(":")[0]}:
                                  </Text>{" "}
                                  {item.split(":")[1]}
                                </div>
                              ))}
                            </div>
                          );
                        }
                        return stringValue;
                      })()}
                    </Td>
                  ))}
                  <Td fontSize="15px">
                    {(() => {
                      let obj = {
                        "Patient: ": `$${parseFloat(
                          transaction?.patientAmount
                        ).toFixed(2)}`,
                        "Third Party Payer: ": `$${transaction?.tppAmount}`,
                        "Total: ": `$${(
                          parseFloat(transaction?.tppAmount) +
                          parseFloat(transaction?.patientAmount)
                        ).toFixed(2)}`,
                      };
                      mirrorTable.current[i + 1].push(
                        Object.entries(obj)
                          .map(([k, v]) => `${k}${v} \n`)
                          .join("")
                      );
                      return Object.entries(obj).map(([k, v], idx) => (
                        <>
                          <Text
                            key={idx}
                            fontWeight="semibold"
                            display="inline-block"
                          >
                            {k}
                          </Text>{" "}
                          {v}
                          <br />
                        </>
                      ));
                    })()}
                  </Td>

                  <Td fontSize="15px">
                    {(() => {
                      let obj = {
                        "Patient: ": `$${parseFloat(
                          transaction?.amountPaidByPatient
                        ).toFixed(2)}`,
                        "Third Party Payer: ": `$${transaction?.amountPaidByTpp}`,
                        "Total: ": `$${(
                          parseFloat(transaction?.amountPaidByTpp) +
                          parseFloat(transaction?.amountPaidByPatient)
                        ).toFixed(2)}`,
                      };
                      mirrorTable.current[i + 1].push(
                        Object.entries(obj)
                          .map(([k, v]) => `${k}${v} \n`)
                          .join("")
                      );
                      return Object.entries(obj).map(([k, v], idx) => (
                        <>
                          <Text
                            key={idx}
                            fontWeight="semibold"
                            display="inline-block"
                          >
                            {k}
                          </Text>{" "}
                          {v}
                          <br />
                        </>
                      ));
                    })()}
                  </Td>
                  <Td fontSize="15px">
                    {(() => {
                      let obj = {
                        "Patient: ": `$${
                          transaction?.patientAmount -
                          transaction?.amountPaidByPatient
                        }`,
                        "Third Party Payer: ": `$${
                          transaction?.tppAmount - transaction?.amountPaidByTpp
                        }`,
                        "Total: ": `$${
                          transaction?.tppAmount -
                          transaction?.amountPaidByTpp +
                          (transaction?.patientAmount -
                            transaction?.amountPaidByPatient)
                        }`,
                      };
                      mirrorTable.current[i + 1].push(
                        Object.entries(obj)
                          .map(([k, v]) => `${k}${v} \n`)
                          .join("")
                      );
                      return Object.entries(obj).map(([k, v], idx) => (
                        <>
                          <Text
                            key={idx}
                            fontWeight="semibold"
                            display="inline-block"
                          >
                            {k}
                          </Text>{" "}
                          {v}
                          <br />
                        </>
                      ));
                    })()}
                  </Td>
                  <Td fontSize="15px" gap={2}>
                    <InvoicOrReceipt
                      {...{
                        transaction,
                        dashState,
                        setPreview,
                        fetchingFileData,
                        setFetchingFileData,
                        setSelectedTr,
                        toast,
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
        {transactions.length == 0 && (
          <TableCaption mb="4">No sales transactions to display.</TableCaption>
        )}
      </Table>
    </Box>
  );
}
