// takes the journal entries for 1 date and returns the total amount of deteriorations and improvements
export const getSymIds = (journal) => {
  let improvements = [];
  let deteriorations = [];
  let events = [];

  // Iterate through each journal entry
  journal?.journalEntries.forEach((entry) => {
    // Check if the entry has a 'symptoms' property
    if (
      entry?.symptoms?.improvements &&
      Array.isArray(entry.symptoms.improvements)
    ) {
      improvements = [
        ...improvements,
        ...[...(entry.symptoms.improvements || [])],
      ];
    }

    if (
      entry?.symptoms?.deteriorations &&
      Array.isArray(entry.symptoms.deteriorations)
    ) {
      deteriorations = [
        ...deteriorations,
        ...[...(entry.symptoms.deteriorations || [])],
      ];
    }
    if (entry?.events && Array.isArray(entry.events)) {
      events = [...events, ...[...(entry.events || [])]];
    }
  });
  return {
    improvements: [...(new Set(improvements) || [])],
    deteriorations: [...(new Set(deteriorations) || [])],
    events: [...(new Set(events) || [])],
  };
};

export const getCounts = (journal) => {
  let totalImprovements = 0;
  let totalDeteriorations = 0;
  let totalEvents = 0;

  // Iterate through each journal entry
  journal?.journalEntries.forEach((entry) => {
    // Check if the entry has a 'symptoms' property
    if (
      entry?.symptoms?.improvements &&
      Array.isArray(entry.symptoms.improvements)
    ) {
      totalImprovements += entry.symptoms.improvements.length;
    }

    if (
      entry?.symptoms?.deteriorations &&
      Array.isArray(entry.symptoms.deteriorations)
    ) {
      totalDeteriorations += entry.symptoms.deteriorations.length;
    }
    if (entry?.events && Array.isArray(entry.events)) {
      totalEvents += entry.events.length;
    }
  });
  return [totalImprovements, totalDeteriorations, totalEvents];
};

export const generateGraphData = (journals) => {
  const counts = journals?.map((journal) => [
    journal.date,
    new Date(journal.date).getTime(),
    ...getCounts(journal),
  ]);
  const data = [
    {
      name: "Improvements",
      data: counts?.map((row) => [row[1], row[2]]),
    },
    {
      name: "Deteriorations",
      data: counts?.map((row) => [row[1], row[3]]),
    },
    {
      name: "Events",
      data: counts?.map((row) => [row[1], row[4]]),
    },
  ];

  return {data, counts};
};
