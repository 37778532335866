import {formatDateTime} from "../../helpers/timeFormatters";
import {getAuthor} from "./getAuthor";
import {crud} from "../../../../crudRequests";

import {crudStorageUpload} from "../../Files/helpers/crudStorageUpload";
import {lightFormat} from "date-fns";
import {v4 as uuidv4} from "uuid";
export const completeChart = async (
  dashState,
  patient,
  editorRef,
  reportId,
  reportTitle,
  setReportId,
  setDirty,
  setSigned,
  evt,
  setEvt,
  toast,
  reports,
  setReports,
  apptId,
  setControlDisabled,
  setReportTitle
) => {
  let currentContent = "";
  let generatingToast = "";
  try {
    if (editorRef.current) {
      setControlDisabled(true);
      if (reportTitle) {
        generatingToast = toast({
          title: "Completing report",
          description: "Please wait a few seconds...",
          status: "loading",
          variant: "subtle",
          isClosable: true,
        });
        const content = editorRef.current.getContent();
        currentContent = content;
        editorRef.current.setContent(
          content +
            "<br/><br/>" +
            "<i>Completed and signed by <b>" +
            getAuthor(dashState).name +
            "</b> on " +
            formatDateTime(false) +
            "</i>."
        );
        const signedContent = editorRef.current.getContent();
        let completedAt = new Date().getTime();
        if (reportId) {
          // report already exists
          await storeReport({
            editor: editorRef.current,
            patient,
            state: dashState,
            reportTitle,
            reportId,
            toast,
            apptId,
            completedAt,
          });

          await crud(dashState, [
            {
              db: dashState.db,
              collection: "reports",
              parameters: [
                {id: reportId},
                {
                  $set: {
                    title: reportTitle,
                    content: signedContent,
                    aid: apptId,
                    completed: true,
                    completedAt,
                  },
                },
              ],
              method: "updateOne",
            },
          ])
            .then((res) => {
              setReports((prev) => {
                return prev.filter((r) => r.id !== reportId);
              });
              setDirty(false);
              editorRef.current.setDirty(false);
              setSigned(true);
              setReportId("");
              setEvt((prev) => prev + 1);
              editorRef.current.setContent("");
              setControlDisabled(false);
              setReportTitle("");
              generatingToast && toast.close(generatingToast);
            })
            .catch((err) => {
              setControlDisabled(false);
              editorRef.current.setContent(currentContent);
              generatingToast && toast.close(generatingToast);
              console.error("complete error: ", err);
            });
        } else {
          // save a new report
          const reportId = uuidv4();
          const newReport = {
            id: reportId,
            creator: getAuthor(dashState),
            pid: patient.pid,
            date: new Date().toISOString(),
            title: reportTitle,
            content: signedContent,
            aid: apptId,
            completed: true,
            completedAt,
          };

          await storeReport({
            editor: editorRef.current,
            patient,
            state: dashState,
            reportTitle,
            reportId,
            toast,
            apptId,
            completedAt,
          });

          await crud(dashState, [
            {
              db: dashState.db,
              collection: "reports",
              parameters: [newReport],
              method: "insertOne",
            },
          ])
            .then(async (res) => {
              setDirty(false);
              editorRef.current.setDirty(false);
              setSigned(true);
              setReportId("");
              setEvt((prev) => prev + 1);
              editorRef.current.setContent("");
              setControlDisabled(false);
              setReportTitle("");
              generatingToast && toast.close(generatingToast);
            })
            .catch((err) => {
              setControlDisabled(false);
              editorRef.current.setContent(currentContent);
              generatingToast && toast.close(generatingToast);
              console.error("complete error: ", err);
            });
        }
      } else {
        setControlDisabled(false);
        toast({
          title: "Enter a chart title",
          description:
            "Please create a title for this chart in order to save it.",
          status: "error",
          isClosable: true,
          duration: 5000,
        });
      }
    }
  } catch (error) {
    setControlDisabled(false);
    editorRef.current.setContent(currentContent);
    generatingToast && toast.close(generatingToast);
    console.error("complete error: ", error);
  }
};

async function storeReport({
  editor,
  patient,
  state,
  reportTitle,
  reportId,
  toast,
  apptId,
  completedAt,
}) {
  try {
    let BlobPdf = await editor.plugins.export.convert("clientpdf", {});
    let d = new Date();

    await crudStorageUpload(
      state,
      new File(
        [BlobPdf],
        "prefix_" +
          completedAt +
          "-" +
          BlobPdf.size / 1000 +
          "-" +
          `${reportTitle}_(${reportId}_${apptId || ""}).pdf`
      ),
      "brightlighthealth",
      patient["pid"] + `/Charts/`
    );

    toast({
      title: "Chart Saved in Files",
      description: "Successfully saved chart draft in files.",
      status: "success",
      isClosable: true,
      duration: 3000,
    });
  } catch (error) {
    toast({
      title: "Draft File Saved",
      description: "An error occurred while completing the chart.",
      status: "error",
      isClosable: true,
      duration: 3000,
    });
    throw error;
  }
}
