import {
  Box,
  Text,
  useToast,
  Center,
  Flex,
  Button,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  StatGroup,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormLabel,
  FormControl,
  InputLeftAddon,
  Portal,
  Select,
} from "@chakra-ui/react";
import {useState} from "react";
import {lightFormat} from "date-fns";
export default function UnavailableList({
  intervals,
  track,
  groups,
  type,
  selected,
  setSelected,
  radioValue,
  untilRemovedGroup,
}) {
  console.log(untilRemovedGroup);
  return (
    <Box mt={"4"}>
      <Text
        color={"blue.600"}
        mb="1"
        ml="1"
        fontSize={"lg"}
        textAlign={"center"}
        fontWeight="600"
      >
        Unavailable intervals
      </Text>
      <TableContainer
        rounded={"lg"}
        border={"1px solid"}
        borderColor={"gray.100"}
        overflowY="auto"
        h="20rem"
        w={"full"}
        fontSize={"xs"}
        pb="0.5rem"
      >
        <Table variant="unstyled" size="xs">
          <Tbody p="2" pb="4">
            {type === "individual"
              ? radioValue === "weekdays"
                ? untilRemovedGroup.map((u, i) => {
                    let trc = u.indexSet;
                    let format = u.format;
                    return (
                      <Tr
                        key={i}
                        onClick={() => {
                          setSelected((prev) => {
                            let n = new Set(prev);
                            n.has(trc) ? n.delete(trc) : n.add(trc);
                            return n;
                          });
                        }}
                      >
                        <Td bg="white" key={i} textAlign={"center"}>
                          <Box
                            p="2"
                            mt="1"
                            mx="1"
                            borderRadius={"30px"}
                            bg={selected.has(trc) ? "blue.700" : "blue.300"}
                            color={"white"}
                            cursor={"pointer"}
                            rounded={"md"}
                          >
                            {format}
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })
                : intervals.map((p, i) => {
                    // if (track.has(i)) return null;
                    return (
                      <Tr
                        key={i}
                        onClick={() => {
                          setSelected((prev) => {
                            let n = new Set(prev);
                            n.has(i) ? n.delete(i) : n.add(i);
                            return n;
                          });
                        }}
                      >
                        <Td bg="white" key={i} textAlign={"center"}>
                          <Box
                            p="2"
                            mt="1"
                            mx="1"
                            borderRadius={"30px"}
                            bg={selected.has(i) ? "blue.700" : "blue.300"}
                            color={"white"}
                            cursor={"pointer"}
                            rounded={"md"}
                          >
                            {ListItemFromat({type, data: p})}
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })
              : groups.map((e, i) => {
                  let ntv = e[0];
                  let trc = e[1];
                  return (
                    <Tr
                      key={i}
                      onClick={() => {
                        setSelected((prev) => {
                          let n = new Set(prev);
                          n.has(trc) ? n.delete(trc) : n.add(trc);
                          return n;
                        });
                      }}
                    >
                      <Td bg="white" key={i} textAlign={"center"}>
                        <Box
                          p="2"
                          mt="1"
                          mx="1"
                          borderRadius={"30px"}
                          bg={selected.has(trc) ? "blue.700" : "blue.300"}
                          color={"white"}
                          cursor={"pointer"}
                          rounded={"md"}
                        >
                          {ListItemFromat({type, data: ntv})}
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ListItemFromat({type, data}) {
  if (type === "range") {
    let start = data[0];
    let end = data[data.length - 1];

    return `${lightFormat(start[0], "dd/MM/yyyy")} - ${lightFormat(
      end[0],
      "dd/MM/yyyy"
    )} (${lightFormat(start[0], "HH:mm")} - ${lightFormat(end[1], "HH:mm")})`;
  }
  if (type === "individual") {
    let start = data[0];
    let end = data[1];
    return `${lightFormat(start, "dd/MM/yyyy")} (${lightFormat(
      start,
      "HH:mm aaa"
    )} - ${lightFormat(end, "HH:mm")})`;
  }
  return null;
}
