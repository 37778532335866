import {DashStateContext, PatientContext} from "../../../pages/Patients";
import React, {useContext, useState, useEffect, useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {
  getAdvTemplateList,
  getAdvTemplateGetTemplate,
  getAdvTemplateCreateCategory,
  getAdvTemplateCreateTemplate,
  getAdvTemplateRenameCategory,
  getAdvTemplateRenameTemplate,
  getAdvTemplateDeleteCategory,
  getAdvTemplateDeleteTemplate,
  getAdvTemplateMoveTemplate,
  getAdvTemplateMoveCategoryItems,
} from "./helpers/templates";
import {formatDateTime} from "../helpers/timeFormatters";
import {getMergeTagsList} from "./helpers/mergetags";
import chartMetadata from "./helpers/chartMetadata.json";
import {
  Box,
  Text,
  Input,
  useToast,
  Flex,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import ChartControls from "./ChartControls";
import addSummary from "./helpers/addSummary";
import addPrescription from "./helpers/addPrescription";
import addForm from "./helpers/addForm";
import addIntake from "./helpers/addIntake";

export default function WYSIWYGEditor({
  reports,
  setReports,
  selectedSummary,
  setSelectedSummary,
  selectedPrescription,
  setSelectedPrescription,
  selectedForm,
  setSelectedForm,
  intake,
  appointments,
  apptLinkToChart,
  setApptLinkToChart,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();

  const [reportTitle, setReportTitle] = useState("");
  const [apptId, setApptId] = useState(apptLinkToChart?.aid || "");
  const [prevLinkAppId, setPrevLinkAppId] = useState(apptId);

  const [reportId, setReportId] = useState(null);
  const [mergeTags, setMergeTags] = useState(null);
  const editorRef = useRef(null);
  const [dirty, setDirty] = useState(false);
  const [signed, setSigned] = useState(false);

  if (prevLinkAppId !== apptLinkToChart?.aid) {
    setPrevLinkAppId(apptLinkToChart?.aid);
    setApptId(apptLinkToChart?.aid);
  }

  useEffect(() => {
    getMergeTagsList(dashState, patient).then((res) => {
      console.log(res);
      setMergeTags(res);
    });
  }, []);

  useEffect(() => {
    addSummary(selectedSummary, setSelectedSummary, editorRef);
  }, [selectedSummary]);

  useEffect(() => {
    addPrescription(selectedPrescription, setSelectedPrescription, editorRef);
  }, [selectedPrescription]);

  useEffect(() => {
    addForm(selectedForm, setSelectedForm, editorRef);
  }, [selectedForm]);

  useEffect(() => {
    addIntake(intake, editorRef);
  }, [intake]);

  return (
    <Box>
      <Flex my="4" w="full">
        <FormControl id="apptId" w="22rem">
          <FormLabel>Chart Title</FormLabel>
          <Input
            value={reportTitle}
            onChange={(e) =>
              setReportTitle(e.target.value.replace(/[\\/:*?"<>|]/g, ""))
            }
            placeholder="Chart Title"
            variant="flushed"
          />
        </FormControl>
        <FormControl w="22rem" ml="2rem">
          <FormLabel>Link to Appointment</FormLabel>
          <Select
            variant="flushed"
            placeholder="-"
            value={apptId}
            onChange={(e) => {
              setApptId(e.target.value);
            }}
          >
            {appointments.map((a, i) => (
              <option p="1" key={i} value={a.aid}>
                {formatDateTime(a.ISOdate)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>

      {mergeTags ? (
        <Editor
          apiKey={"1dl7nzks0e4r3vc01md74xanaig9kaa7os5y6ga6p6oblxxq"}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onDirty={() => setDirty(true)}
          initialValue=""
          init={{
            width: "100%",
            height: "55vh",
            branding: chartMetadata.branding,
            plugins: chartMetadata.plugins,
            toolbar: chartMetadata.toolbar,
            toolbar_mode: "sliding",
            content_style: chartMetadata.content_style,
            casechange_title_case_minors:
              chartMetadata.casechange_title_case_minors,
            image_advtab: chartMetadata.image_advtab,

            advtemplate_list: getAdvTemplateList(dashState),
            advtemplate_get_template: getAdvTemplateGetTemplate(dashState),
            advtemplate_create_category:
              getAdvTemplateCreateCategory(dashState),
            advtemplate_create_template:
              getAdvTemplateCreateTemplate(dashState),
            advtemplate_rename_category:
              getAdvTemplateRenameCategory(dashState),
            advtemplate_rename_template:
              getAdvTemplateRenameTemplate(dashState),
            advtemplate_delete_category:
              getAdvTemplateDeleteCategory(dashState),
            advtemplate_delete_template:
              getAdvTemplateDeleteTemplate(dashState),
            advtemplate_move_template: getAdvTemplateMoveTemplate(dashState),
            advtemplate_move_category_items:
              getAdvTemplateMoveCategoryItems(dashState),

            mergetags_list: mergeTags,

            setup: (editor) => {
              editor.addShortcut("meta+s", "save", () => {
                console.log("Save");
                //saveDraft();
              });
              editor.addShortcut("esc", "fullscreen", () => {
                console.log("esc");
                editor.execCommand("mceFullScreen");
              });
            },
          }}
        />
      ) : (
        <Text textAlign="center" fontWeight="medium" color="gray.600" mt="4">
          Loading merge tags...
        </Text>
      )}
      <ChartControls
        reports={reports}
        setReports={setReports}
        editorRef={editorRef}
        signed={signed}
        setSigned={setSigned}
        reportId={reportId}
        reportTitle={reportTitle}
        setReportId={setReportId}
        setDirty={setDirty}
        setReportTitle={setReportTitle}
        {...{apptId, setApptId, setApptLinkToChart}}
      />
    </Box>
  );
}
