import {
  Box,
  Flex,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Button,
  Badge,
  Tooltip,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  HStack,
  VStack,
  Text,
  Radio,
  FormControl,
  NumberInput,
  NumberInputField,
  FormLabel,
  Switch,
  Link,
} from "@chakra-ui/react";

import {useState, useRef, useEffect} from "react";
import {lightFormat, addWeeks, addMonths, addYears} from "date-fns";
import {BiCopy} from "react-icons/bi";

export default function MeetingLinks({appointment}) {
  const [open, setOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (!appointment) {
      setOpen(false);
    }
  }, [appointment]);

  return (
    <>
      <Accordion index={open ? [0] : []} my="4" allowToggle>
        <AccordionItem border="none">
          <Flex>
            <h2>
              <AccordionButton
                color={"blue.500"}
                borderBottom={"1px solid"}
                borderBottomColor={"blue.500"}
                fontWeight={500}
                fontSize={"13px"}
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
              >
                <Box as="span" flex="1" textAlign="left">
                  Meeting access
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
          </Flex>
          <AccordionPanel rounded={"sm"} p="2" bg="white">
            {appointment && (
              <VStack justify={"start"} flexWrap={"wrap"}>
                <Box
                  w="full"
                  fontSize={"13px"}
                  gap="2"
                  justify={"start"}
                  flexWrap={"wrap"}
                  whiteSpace={"pre-wrap"}
                >
                  <Text mr="1.5" as="span" fontWeight={600}>
                    Link:
                  </Text>
                  {appointment?.patientApptLink ? (
                    <>
                      <Link
                        isExternal
                        href={appointment?.patientApptLink}
                        color="blue.500"
                      >
                        {appointment?.patientApptLink}
                      </Link>

                      <Icon
                        onClick={() => {
                          navigator.clipboard.writeText(
                            appointment?.patientApptLink
                          );
                          toast({
                            title: "Link copied to clipboard",
                            status: "info",
                            duration: 2000,
                          });
                        }}
                        boxSize={4}
                        cursor={"pointer"}
                        as={BiCopy}
                        ml="2"
                      />
                    </>
                  ) : (
                    "N/A"
                  )}
                </Box>
                <Box
                  fontSize={"13px"}
                  gap="2"
                  justify={"start"}
                  flexWrap={"wrap"}
                  whiteSpace={"pre-wrap"}
                  w="full"
                >
                  <Text mr="1.5" as="span" fontWeight={600}>
                    Patient phone:
                  </Text>
                  <Text mr="" as="span">
                    {appointment?.pPhone || "N/A"}
                  </Text>

                  {appointment?.pPhone && (
                    <Icon
                      onClick={() => {
                        navigator.clipboard.writeText(
                          appointment?.pPhone || ""
                        );
                        toast({
                          title: "Phone copied to clipboard",
                          status: "info",
                          duration: 2000,
                        });
                      }}
                      boxSize={4}
                      cursor={"pointer"}
                      as={BiCopy}
                      ml="2"
                    />
                  )}
                </Box>
              </VStack>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
