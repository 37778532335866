import axios from "axios";
import React, {useState, useEffect, useRef} from "react";

import {drugAutocomplete} from "../../../crudRequests";
import {defaultMedicationObj} from "./Prescription";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListIcon,
  ListItem,
  Select,
  Spinner,
  StackDivider,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  CheckCircleIcon,
  InfoOutlineIcon,
  MinusIcon,
  SearchIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import moment from "moment";
import getInteractions from "./helpers/getInteractions";

export function datediff(first, second) {
  var start = moment(first);
  var end = moment(second);
  return end.diff(start, "days");
}

export const DrugSection = ({
  updateFormValue,
  drugName,
  state,
  pastPrescriptions,
  signetur,
  dosage,
  form,
  qty,
  refills,
  additionalNotes,
  frequency,
  length_days,
  autoexpire,
  start_date,
  // no_start_date,
  end_date,
  prn,
  drug_use,
  unit,
  route,
  index,
  removeSelf,
}) => {
  const [selectedDrug, setSelectedDrug] = useState(null);
  const getDrugForm = (drug_code) => {
    let config = {
      method: "get",
      url: `https://health-products.canada.ca/api/drug/Form/?lang=en&type=json&id=${drug_code}`,
    };

    return axios
      .request(config)
      .then((response) => {
        updateFormValue("form", response?.data[0]?.pharmaceutical_form_name);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDrugRoute = (drug_code) => {
    let config = {
      method: "get",
      url: `https://health-products.canada.ca/api/drug/Route/?lang=en&type=json&id=${drug_code}`,
    };

    return axios
      .request(config)
      .then((response) => {
        updateFormValue(
          "route",
          response?.data[0]?.route_of_administration_name
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDrugDosage = (drug_code) => {
    let config = {
      method: "get",
      url: `https://health-products.canada.ca/api/drug/activeingredient/?lang=en&type=json&id=${drug_code}`,
    };

    return axios
      .request(config)
      .then((response) => {
        updateFormValue(
          "dosage",
          `${response?.data[0]?.strength} ${response?.data[0]?.strength_unit}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDosageValue = (drug_code) => {
    let config = {
      method: "get",
      url: `https://health-products.canada.ca/api/drug/activeingredient/?lang=en&type=json&id=${drug_code}`,
    };

    return axios.request(config).catch((error) => {
      console.log(error);
    });
  };

  const loadDrugData = async (drug_code) => {
    await Promise.all([
      getDrugForm(drug_code),
      getDrugRoute(drug_code),
      getDrugDosage(drug_code),
    ]);
  };

  // list of drugs for the autocomplete feature
  const [possibleDrugs, setPossibleDrugs] = useState([]);

  const timeout = useRef();
  const firstRender = useRef(true);

  useEffect(() => {
    if (drugName && !selectedDrug && !firstRender.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        drugAutocomplete(state, drugName)
          .then((res) => {
            const uniqueDrugs = Array.from(
              new Set(res?.data?.map((drug) => drug.alt_name))
            ).map((alt_name) =>
              res?.data?.find((drug) => drug.alt_name === alt_name)
            );
            setPossibleDrugs(uniqueDrugs || []);
          })
          .catch((e) => {
            console.error(e);
          });
      }, 1000);
    } else {
      clearTimeout(timeout.current);
      setPossibleDrugs([]);
    }
  }, [drugName]);
  useEffect(() => {
    firstRender.current = false;
  }, []);
  const [drugInteractions, setDrugInteractions] = useState([]);

  const [interactionError, setInteractionError] = useState(false);

  const [loadingInteractions, setLoadingInteractions] = useState(false);

  const onSelectDrug = async (drug) => {
    try {
      setLoadingInteractions(true);
      setInteractionError(false);
      setDrugInteractions([]);
      if (!drug?.brand_name) {
        setLoadingInteractions(false);
        setInteractionError(true);
        return;
      }
      let response = await getInteractions(state, pastPrescriptions, drug);
      await loadDrugData(drug.drug_code);

      if (response.success) {
        setDrugInteractions(response.data || []);
      } else {
        setInteractionError(response.message);
      }
    } catch {
      setLoadingInteractions(false);
    } finally {
      setLoadingInteractions(false);
    }
  };

  const freqOptions = {
    "q.i.d.": 4,
    "q.o.d.": 0.5,
    "q.a.d.": 0.5,
    "q.p.": 0,
    "q.s.": 0,
    "q.v.": 0,
    "q.h.s.": 1,
    "t.i.d.": 3,
    "ad.lib.": 0,
    "alt.dieb.": 0.5,
    "alt.hor.": 12,
    "alt.noct.": 0.5,
    "b.i.d.": 2,
    "dieb. alt.": 0.5,
    "o.d.": 0,
    "o.h.": 0,
    "o.m.": 1,
    "o.n.": 1,
    "p.r.n.": 0,
    q1h: 24,
    q2h: 12,
    q3h: 8,
    q4h: 6,
    q6h: 4,
    "q.d.": 1,
    "q.h.": 24,
  };

  const routeOptions = [
    "Oral Route",
    "Sublingual Route",
    "Buccal Route",
    "Intravenous Route",
    "Intramuscular Route",
    "Subcutaneous Route",
    "Inhalation Route",
    "Nasal Route",
    "Rectal Route",
    "Vaginal Route",
    "Cutaneous Route",
    "Otic Route",
    "Ocular Route",
    "Transdermal Route",
  ];

  const drugUseOptions = ["Continuous", "Short Term"];

  const unitOptions = ["TAB", "BOX", "EA"];
  const toast = useToast();

  const getAlertText = () => {
    const highInteractions = [];
    const otherInteractions = [];
    const naInteractions = [];
    drugInteractions?.flat()?.map(({severityId, text, severity}) => {
      switch (severityId) {
        case 3:
          highInteractions.push(`${text} Severity: ${severity}`);
          break;
        case 2:
          naInteractions.push(`${text} Severity: ${severity}`);
        default:
          otherInteractions.push(`${text} Severity: ${severity}`);
          break;
      }
    });
    return [...highInteractions, ...otherInteractions, ...naInteractions];
  };

  const {isOpen, onOpen, onClose} = useDisclosure();
  useEffect(() => {
    updateFormValue("qty", (freqOptions[frequency] || 0) * (length_days || 0));
  }, [frequency, length_days]);

  return (
    <VStack w="full" boxShadow="md">
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Possible interactions
            </AlertDialogHeader>

            <AlertDialogBody>
              {getAlertText()?.map((text, index) => (
                <List key={index} spacing={2}>
                  <ListItem display="flex" alignItems="start" mb={4}>
                    <ListIcon as={InfoOutlineIcon} color="yellow.500" />
                    <Text
                      verticalAlign="start"
                      fontSize="sm"
                      color="black"
                      textTransform="capitalize"
                    >
                      {text}
                    </Text>
                  </ListItem>
                </List>
              ))}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>Close</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {index !== 0 && (
        <Flex w="full" p="6" pb="0" justify="end">
          <MinusIcon size="sm" cursor="pointer" onClick={removeSelf} />
        </Flex>
      )}

      <Grid w="full" p="10" templateColumns="repeat(7,1fr)" gap="4">
        <GridItem colSpan={3}>
          {/* <div className="col-span-3"> */}
          <FormControl isRequired>
            <FormLabel>medication name</FormLabel>
            <InputGroup>
              <Input
                value={drugName}
                placeholder="search..."
                onChange={(e) => {
                  updateFormValue("drugName", e.target.value);
                  if (selectedDrug) setSelectedDrug(null);
                  setDrugInteractions([]);
                }}
              />
              <InputRightElement>
                <SearchIcon
                  cursor="pointer"
                  onClick={async () => {
                    if (!drugName || loadingInteractions) return;
                    setSelectedDrug({brand_name: drugName});
                    await onSelectDrug({brand_name: drugName});
                  }}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>dosage</FormLabel>
            <Input
              value={dosage}
              onChange={(e) => updateFormValue("dosage", e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>form</FormLabel>
            <Input
              value={form}
              onChange={(e) => updateFormValue("form", e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan="3">
          {!selectedDrug && drugName ? (
            <VStack
              maxH="250px"
              overflowY="scroll"
              w="100%"
              divider={<StackDivider borderColor="gray.200" />}
            >
              {possibleDrugs?.map((item, index) => (
                <Box
                  w="100%"
                  cursor="pointer"
                  onClick={async () => {
                    setSelectedDrug(item);
                    updateFormValue("drugName", item.brand_name);
                    await onSelectDrug(item);
                  }}
                  key={index}
                >
                  <Text paddingLeft={4} fontSize="sm">
                    {item?.alt_name}
                  </Text>
                </Box>
              ))}
            </VStack>
          ) : (
            <Box w="full" h="full">
              {loadingInteractions ? (
                <Grid
                  w="full"
                  h="full"
                  rounded="md"
                  placeItems="center"
                  gap={4}
                  p={4}
                  textColor="#FF7A00"
                >
                  <Text fontSize="sm">verifying any drug interactions</Text>
                  <Spinner size="md" />
                </Grid>
              ) : interactionError ? (
                <Grid
                  w="full"
                  h="full"
                  rounded="md"
                  placeItems="center"
                  gap={4}
                  p={4}
                >
                  <Flex className="flex gap-2 items-center">
                    <WarningIcon color="#FFA601" />
                    <Text fontSize="md" textColor="#FFA601">
                      unable to find drug interactions!
                    </Text>
                  </Flex>
                  <Text maxW="400px" textAlign="center">
                    unable to find drug interactions for {drugName} with the
                    patients currently prescribed drugs
                  </Text>
                </Grid>
              ) : drugInteractions?.length === 0 && selectedDrug ? (
                <Grid
                  w="full"
                  h="full"
                  rounded="md"
                  placeItems="center"
                  gap={4}
                  p={4}
                >
                  <Flex gap={2} alignItems="center">
                    <CheckCircleIcon fontSize="md" color="#009045" />
                    <Text fontSize="md" textColor="#009045">
                      no drug interactions found
                    </Text>
                  </Flex>
                  <Text textAlign="center" maxW="400px">
                    {drugName} can be safely prescribed as it does not interact
                    with any of this patient&apos;s existing medications.
                  </Text>
                </Grid>
              ) : drugInteractions?.length > 0 ? (
                <Grid
                  w="full"
                  h="full"
                  rounded="md"
                  placeItems="center"
                  gap={4}
                  p={4}
                >
                  <Flex gap={2} alignItems="center">
                    <WarningIcon color="#FFA601" />
                    <Text fontSize="md" textColor="#FFA601">
                      potential drug interactions found!
                      {/* </p> */}
                    </Text>
                  </Flex>
                  <Text textAlign="center" maxW="400px">
                    {drugName} may interact with 1 or more drugs that the
                    patient is already taking: see{" "}
                    <Text textColor="#5754FF" cursor="pointer" onClick={onOpen}>
                      here
                    </Text>
                  </Text>
                </Grid>
              ) : null}
            </Box>
          )}
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isRequired>
            <FormLabel>frequency</FormLabel>
            <Select
              onChange={(e) => {
                const qtyPerDay = freqOptions[e.target.value];
                updateFormValue("frequency", e.target.value);
              }}
              value={frequency}
            >
              <option value="">Select an option</option>
              {Object.keys(freqOptions).map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>start date</FormLabel>
            <Input
              type="date"
              value={start_date}
              onChange={(e) => {
                if (end_date && datediff(e.target.value, end_date) < 0) {
                  toast({
                    title: "Invalid Date",
                    description: "Start date cannot be after the end date",
                    status: "error",
                    isClosable: true,
                  });
                  return;
                }
                updateFormValue("start_date", e.target.value);
                // calculate length in days based off difference in start and end dates
                if (end_date) {
                  updateFormValue(
                    "length_days",
                    datediff(e.target.value, end_date)
                  );
                }
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>end date</FormLabel>
            <Input
              type="date"
              value={end_date}
              onChange={(e) => {
                if (start_date && datediff(start_date, e.target.value) < 0) {
                  toast({
                    title: "Invalid Date",
                    description: "Start date cannot be after the end date",
                    status: "error",
                    isClosable: true,
                  });
                  return;
                }
                updateFormValue("end_date", e.target.value);
                // calculate length in days based off difference in start and end dates
                if (start_date) {
                  updateFormValue(
                    "length_days",
                    datediff(start_date, e.target.value)
                  );
                }
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>length (days)</FormLabel>
            <Input
              value={length_days}
              onChange={(e) => {
                updateFormValue("length_days", e.target.value);
                if (end_date) {
                  try {
                    updateFormValue(
                      "end_date",
                      moment(start_date)
                        .add(e.target.value, "days")
                        .format("YYYY-MM-DD")
                    );
                  } catch (e) {
                    console.log({e});
                  }
                }
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex gap={8}>
            <FormControl>
              <Grid w={14} templateRows="auto 1fr">
                <FormLabel>autoexpire</FormLabel>
                <Checkbox
                  w={8}
                  placeSelf="center"
                  value={autoexpire}
                  checked={autoexpire}
                  onChange={(e) => updateFormValue("autoexpire", !autoexpire)}
                />
              </Grid>
            </FormControl>
            <FormControl>
              <Grid w="105px" templateRows="auto 1fr">
                <FormLabel textAlign="center">PRN</FormLabel>
                <Checkbox
                  w={8}
                  placeSelf="center"
                  value={prn}
                  checked={prn}
                  onChange={(e) => updateFormValue("prn", !prn)}
                />
              </Grid>
            </FormControl>
          </Flex>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>route</FormLabel>
            <Select
              onChange={(e) => updateFormValue("route", e.target.value)}
              value={route}
            >
              {routeOptions.map((name) => (
                <option key={name}>{name}</option>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>drug use</FormLabel>
            <Select
              onChange={(e) => updateFormValue("drug_use", e.target.value)}
              value={drug_use}
            >
              {drugUseOptions.map((name) => (
                <option key={name}>{name}</option>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel>quantity</FormLabel>
            <Input
              type="number"
              value={qty}
              onChange={(e) => updateFormValue("qty", e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl isRequired>
            <FormLabel>unit</FormLabel>
            <Select
              onChange={(e) => updateFormValue("unit", e.target.value)}
              value={unit}
            >
              {unitOptions.map((name) => (
                <option key={name}>{name}</option>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isRequired>
            <FormLabel>refills</FormLabel>
            <Input
              value={refills}
              onChange={(e) => updateFormValue("refills", e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={7}>
          <FormControl>
            <FormLabel>Signetur (additional information)</FormLabel>
            <Textarea
              value={additionalNotes}
              onChange={(e) =>
                updateFormValue("additionalNotes", e.target.value)
              }
            />
          </FormControl>
        </GridItem>
      </Grid>
    </VStack>
  );
};

// the medication section from the prescription page
const PrescribeDrug = ({
  medications,
  setMedications,
  pastPrescriptions,
  state,
}) => {
  return (
    <VStack align={"self-start"} gap="6" w="full">
      {medications.map((medication, index) => {
        const getFormValue = (name) => medication[name];
        return (
          <DrugSection
            index={index}
            removeSelf={() => {
              setMedications((prev) => prev.filter((_, i) => i !== index));
            }}
            updateFormValue={(name, value) =>
              setMedications((prev) => {
                let copy = [...prev];
                // get the medication from the array
                let obj = prev[index];
                // update the value
                obj[name] = value;
                // update the array
                copy[index] = obj;
                return copy;
              })
            }
            drugName={getFormValue("drugName")}
            state={state}
            pastPrescriptions={pastPrescriptions}
            signetur={getFormValue("signetur")}
            dosage={getFormValue("dosage")}
            form={getFormValue("form")}
            qty={getFormValue("qty")}
            refills={getFormValue("refills")}
            additionalNotes={getFormValue("additionalNotes")}
            frequency={getFormValue("frequency")}
            length_days={getFormValue("length_days")}
            autoexpire={getFormValue("autoexpire")}
            start_date={getFormValue("start_date")}
            end_date={getFormValue("end_date")}
            prn={getFormValue("prn")}
            drug_use={getFormValue("drug_use")}
            unit={getFormValue("unit")}
          />
        );
      })}
      <Button
        bg="#9898B9"
        width="30%"
        onClick={() =>
          setMedications((prev) => [...prev, {...defaultMedicationObj}])
        }
        textColor="white"
      >
        Add Additional Medication
      </Button>
    </VStack>
  );
};

export default PrescribeDrug;
