import React, {useState, useRef, useEffect} from "react";
import {notificationsData} from "../additional_files/notifications";
import {crud} from "../crudRequests";
import {
  appointmentAvailable,
  generateAppointmentsIntervals,
} from "../additional_files/helpers";
import {useToast} from "@chakra-ui/react";
import Swal from "sweetalert2";
import {lightFormat} from "date-fns";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export default function RescheduleAppointment(props) {
  const [disabled, setDisabled] = useState(false);
  const {appointment, stateChanger, schState, dispatch, socket} = props;
  const toast = useToast();
  const appointmentsIntervals = useRef(null);

  useEffect(() => {
    if (schState.userType === "admin") {
      let {doctorsPayload} = schState;
      let docAppointments = doctorsPayload[appointment.did].appointments;
      let apptIndex = docAppointments.findIndex(
        (a) => a.aid === appointment.aid
      );
      let apptArr = [
        ...docAppointments.slice(0, apptIndex),
        ...docAppointments.slice(apptIndex + 1),
      ];
      appointmentsIntervals.current = generateAppointmentsIntervals(apptArr);
    } else {
      let docAppointments = schState.appointments;
      let apptIndex = docAppointments.findIndex(
        (a) => a.aid === appointment.aid
      );
      let apptArr = [
        ...docAppointments.slice(0, apptIndex),
        ...docAppointments.slice(apptIndex + 1),
      ];
      appointmentsIntervals.current = generateAppointmentsIntervals(apptArr);
    }
  }, [appointment, schState]);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [inputDate, setInputDate] = useState(
    lightFormat(appointment.ISOdate, "yyyy-MM-dd")
  );
  const [inputTime, setInputTime] = useState(
    lightFormat(appointment.ISOdate, "HH:mm")
  );
  const [duration, setDuration] = useState("15 min");

  async function rescheduleAppointment() {
    if (!disabled) {
      setSuccessMessage("");
      setErrorMessage("");
      let result;
      if (appointment?.recurrenceId) {
        result = await Swal.fire({
          title: "Recurring event",
          text: "This event belongs to a series of recurring appointments, are you sure you want to reschedule it?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm",
        });
        if (!result?.isConfirmed) return;
      }

      let location = await crud(schState, [
        {
          db: schState.db,
          collection: "locations",
          parameters: [{lid: schState.selectedLocation}],
          method: "findOne",
        },
      ]);
      location = location.data[0];
      let sender = location?.name;

      const time =
        parseInt(inputTime.split(":")[0]) > 12
          ? (parseInt(inputTime.split(":")[0]) - 12).toString() +
            ":" +
            inputTime.split(":")[1] +
            " PM"
          : parseInt(inputTime.split(":")[0]).toString() === "0"
          ? "12:" + inputTime.split(":")[1] + " AM"
          : parseInt(inputTime.split(":")[0]).toString() +
            ":" +
            inputTime.split(":")[1] +
            " AM";

      let dateArray = inputDate.split("-").map((e) => parseInt(e));
      let h_min = inputTime.split(":").map((e) => parseInt(e));
      dateArray[1]--;
      const selectedDate = new Date(...dateArray);
      const date =
        weekday[selectedDate.getDay()] +
        ", " +
        months[selectedDate.getMonth()] +
        " " +
        selectedDate.getDate() +
        ", " +
        selectedDate.getFullYear();

      const updateBody = {
        aid: appointment.aid,
        rescheduled:
          appointment.ISOdate +
          "|" +
          (schState.doctor ? schState.doctor.did : schState.admin.id),
        noShow: false,
        cancelled: false,
        pConfirmed: false,
        date,
        time,
        duration,
        ISOdate: new Date(...dateArray.concat(h_min)).getTime(),
      };
      let payload = {...appointment, ...updateBody};

      if (result?.isConfirmed) {
        updateBody.recurrenceId = payload.recurrenceId = "";
      }

      if (appointmentAvailable(appointmentsIntervals.current, updateBody)) {
        const generatingToast = toast({
          title: "Rescheduling appointment.",
          status: "loading",
          variant: "subtle",
          duration: null,
          isClosable: true,
        });
        setDisabled(true);
        let smsOption = {
          to: payload.pPhone,
          sms:
            "Dear " +
            payload.pName +
            ", Your appointment with " +
            payload.dName +
            " has been rescheduled" +
            " for " +
            new Date(payload.ISOdate).toString().replace(/\(.+\)/g, "") +
            ". Sincerely, Brightlight Health Inc.",
        };

        crud(
          schState,
          [
            {
              db: schState.db,
              collection: "appointments",
              parameters: [{aid: updateBody.aid}, {$set: updateBody}],
              method: "updateOne",
            },
            {
              db: schState.db,
              collection: "billing",
              parameters: [
                {aid: updateBody.aid},
                {
                  $set: {
                    time: updateBody.duration,
                    serviceDate: updateBody.ISOdate,
                  },
                },
              ],
              method: "updateOne",
            },
          ],
          {
            apptReminderNotification: {
              appt: payload,
              sender,
              org: schState.organization,
              location,
            },
            email: {
              type: "update_appointment",
              content: payload,
              options: {reschedule: updateBody.ISOdate},
              sender,
              org: schState.organization,
              location,
            },
            sms: smsOption,
          }
        )
          .then(async (res) => {
            dispatch({type: "UPDATE_APPOINTMENT", payload});
            let {ntf, ntfList} = notificationsData(
              schState.userType,
              "Updated appointment",
              updateBody
            );
            toast.close(generatingToast);
            socket?.emit?.("update_appt", payload, {ntf});
            toast({
              title: "The appointment has been successfully updated!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            let requestObjs = [
              {
                db: schState.db,
                collection: "notifications",
                parameters: [
                  {userType: ntfList[0].userType},
                  {$push: {[ntfList[0].id]: ntfList[0].notification}},
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
              {
                db: schState.db,
                collection: "notifications",
                parameters: [
                  {userType: ntfList[1].userType},
                  {$push: {[ntfList[1].id]: ntfList[0].notification}},
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
            ];
            await crud(schState, requestObjs);
            stateChanger(false);
          })
          .catch(function (error) {
            console.log(error);
          });
        //setAppointmentsIntervals(state.appointments)
        setSuccessMessage("The appointment has been successfully updated!");
      } else {
        setErrorMessage("The times for this appointment are already taken.");
      }
    }
  }

  return (
    <>
      <div
        className="fixed inset-0 show z-[999] overflow-hidden [margin-top:0_!important] flex justify-center items-center"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            style={{backgroundColor: "#F6F5FF"}}
          >
            {errorMessage && (
              <div>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-red-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-red-500 dark:text-red-400">
                        Error
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {successMessage && (
              <div>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-green-500 dark:text-green-400">
                        Appointment Rescheduled
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        The appointment has been successfully updated!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex -mb-10 ">
              <h3
                className="text-2xl whitespace-nowrap  font-medium text-gray-800 m-4 -mr-2   mb-6"
                id="modal-title"
                style={{color: "#C0BFFF"}}
              >
                Reschedule Appointment
              </h3>
              <div className="ml-32 pl-5">
                <button
                  type="button"
                  className="- text-gray-400 bg-transparent rounded-lg text-sm  ml-auto inline-flex items-center"
                  data-modal-toggle="small-modal"
                  style={{boxShadow: "none"}}
                  onClick={() => stateChanger(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <form className="mt-6">
              <div className="flex">
                <div className="w-full flex justify-evenly items-center">
                  <label className="block mx-1 mt-3" htmlFor="date">
                    <span className="text-[11px] text-off ml-2">
                      Select Date
                    </span>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      onChange={(e) => setInputDate(e.target.value)}
                      value={inputDate}
                      className="drop-shadow block w-[9rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </label>
                  <label className="block mx-1 mt-3" htmlFor="time">
                    <span className="text-[11px] text-off ml-2">
                      Select Time
                    </span>
                    <input
                      onChange={(e) => setInputTime(e.target.value)}
                      type="time"
                      name="time"
                      id="time"
                      value={inputTime}
                      className="drop-shadow block w-[9rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </label>
                  <label className="block mx-1 mt-3" htmlFor="duration">
                    <span className="text-[11px] text-off ml-2">
                      Select Duration
                    </span>
                    <select
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      type="duration"
                      name="duration"
                      id="duration"
                      defaultValue={appointment.duration}
                      className="drop-shadow block w-[9rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    >
                      <option value="15 min">15 minutes</option>
                      <option value="30 min">30 minutes</option>
                      <option value="45 min">45 minutes</option>
                      <option value="60 min">60 minutes</option>
                      <option value="75 min">75 minutes</option>
                      <option value="90 min">90 minutes</option>
                      <option value="120 min">120 minutes</option>
                    </select>
                  </label>
                </div>
              </div>

              <div className="mt-6 w-[27.25rem] mb-4 mx-3.5 rounded-lg text-background bg-med">
                <button
                  type="button"
                  className="w-full py-2 rounded-lg font-medium"
                  onClick={rescheduleAppointment}
                >
                  Reschedule Appointment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
