import Swal from "sweetalert2";
import {
  generatePaymentLink,
  createCharge,
  crud,
  sendCardReqLink,
  sendInvRecEmail,
  getPaymentMethods,
  executePayment,
} from "../../../crudRequests";
import createPaymentRecord from "../../appointments/payment/helpers/createPaymentRecord";
import deleteFiles from "../../appointments/payment/helpers/deleteFiles";
import {isoToApptDate} from "../../../additional_files/helpers";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import {lightFormat} from "date-fns";
import InvRecpDF from "../../Invoice";
import {initialInvoices} from "../../appointments/payment/uploads";
import {pdf} from "@react-pdf/renderer";
import {
  fullInvoice as productFullInvoice,
  patientInvoice as productPatientInvoice,
} from "../../Products/helpers/uploads";
export async function cancelTransactions(
  transaction,
  schState,
  dispatch,
  socket,
  toast,
  cancellationReason,
  allowAction
) {
  try {
    let result = transaction;
    if (transaction.type === "product") {
      let upTr = await crud(schState, [
        {
          db: schState.db,
          collection: "billing",
          parameters: [
            {tid: transaction.tid},
            {
              $set: {
                cancelled: true,
                cancellationReason,
              },
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
        {
          db: schState.db,
          collection: "patients",
          parameters: [{pid: transaction?.pid}],
          method: "findOne",
        },
      ]);

      let invData = {
        state: schState,
        transaction: upTr.data[0],
        patient: upTr.data[1],
        cancelled: true,
      };

      await Promise.all([
        productFullInvoice(invData),
        productPatientInvoice(invData),
      ]);

      result = {...transaction, cancelled: true};
    } else {
      let appt = await crud(schState, [
        {
          db: schState.db,
          collection: transaction?.nonSessionService
            ? "nonSessionServices"
            : "appointments",
          parameters: [{aid: transaction.aid}],
          method: "findOne",
        },
      ]);
      appt = appt.data[0];

      if (!appt) return;

      let apptUpdateBody = {
        cancellationReason,
      };

      let trUpdateBody = {
        cancelled: true,
        cancellationReason,
      };

      if (!allowAction) {
        apptUpdateBody.paymentStatus = trUpdateBody.paymentStatus = "paid";
        apptUpdateBody.amountPaidByPatient = trUpdateBody.amountPaidByPatient =
          appt.patientAmount;
        apptUpdateBody.amountPaidByTpp = trUpdateBody.amountPaidByTpp =
          appt.tppAmount;

        if (appt.paymentStatus !== "paid") {
          apptUpdateBody.paymentMethod = trUpdateBody.paymentMethod =
            "Billing Transaction Cancelled";
        }
      } else apptUpdateBody.cancelled = true;

      let res = await crud(schState, [
        {
          db: schState.db,
          collection: transaction?.nonSessionService
            ? "nonSessionServices"
            : "appointments",
          parameters: [
            {aid: transaction.aid},
            {
              $set: apptUpdateBody,
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
        {
          db: schState.db,
          collection: "billing",
          parameters: [
            {aid: transaction.aid},
            {
              $set: trUpdateBody,
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
      ]);

      appt = res.data[0];

      await initialInvoices({appt, state: schState});

      dispatch({
        type: "UPDATE_APPOINTMENT",
        payload: appt,
      });

      socket?.emit?.("update_appt", appt, {});
      result = {...transaction, ...trUpdateBody};
    }
    return result;
  } catch (error) {
    toast({
      title: "Cancellation Error",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    console.log(error);
    return transaction;
  }
}
