import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {
  BsPersonCircle,
  BsCheckCircleFill,
  BsFillInfoCircleFill,
} from "react-icons/bs";
import BookNowDoctorInfo from "./BookNowDoctorInfo";

export default function BookNowSelections({
  bookState,
  dispatch,
  servicesList,
  doctorList,
}) {
  const [doctor, setDoctor] = useState(null);
  return (
    <div className="w-full h-full flex flex-col  px-10 pt-4 space-y-3">
      {doctor && <BookNowDoctorInfo {...{doctor, setDoctor}} />}
      <div className="flex">
        <h1 className="text-[#636363] sm:text-xl text-lg font-semibold flex-1 text-center">
          Book your appointment
        </h1>
      </div>

      <div className="relative  flex-1 w-full sm:overflow-hidden ">
        <div className="flex flex-wrap  flex-col sm:flex-row w-full h-full  z-30  sm:overflow-hidden overflow-scroll sbar2  space-x-2">
          <div className="sm:flex-1 shrink-0 overflow-hidden p-2 sm:h-full flex flex-col">
            <div className="flex relative overflow-hidden justify-center items-center px-2">
              <span className="w-[18rem] clip_selection  overflow-hidden   flex justify-center items-center">
                <img
                  src="/images/Clinic/bn_services.png"
                  alt=""
                  className="w-full"
                />
              </span>
              <p className="pl-2 bottom-[50%] absolute text-sm font-semibold text-white">
                Services
              </p>
            </div>

            <div className="sm:flel-1 w-full  relative h-full -mt-[2rem] overflow-hidden p-2 rounded-3xl bg-white">
              <div className="h-full overflow-y-scroll sbar2 ">
                <ul className="flex flex-wrap w-full flex-col sm:items-center space-x-2  space-y-2 p-2  pb-10">
                  {servicesList.map((s, i) => (
                    <li
                      className="cursor-pointer"
                      onClick={() => {
                        dispatch({type: "SELECT_SERVICE", payload: s});
                      }}
                    >
                      <div
                        className="relative py-4 space-y-1 bg-[#F5F5F5] flex items-center flex-col rounded-2xl w-full h-[9rem] text-[636363] text-xs m-1 p-4 lg:w-[17rem] min-w-[17rem] "
                        style={{
                          backgroundColor:
                            bookState.selectedService === s && "#D7D7D7",
                        }}
                      >
                        {bookState.selectedService === s && (
                          <span className="h-4 w-4 absolute top-2 left-2 flex justify-center transition items-center rounded-full text-[1rem]  text-[#363636]">
                            <BsCheckCircleFill />
                          </span>
                        )}
                        <div className="flex-1 overflow-y-scroll sbar2 p-[2px] w-full ">
                          <p className="font-regular text-sm px-2 leading-5">
                            {s.description}.
                          </p>
                        </div>

                        <div className="w-full px-2 pt-2 flex justify-between items-center">
                          <p className="font-regular text-white text-xs py-1 p-2 rounded-xl bg-[#565656]">
                            {`$${
                              s.cost ||
                              s.defaultCost ||
                              s.costsByInsurer?.defaultCost ||
                              100
                            }`}
                          </p>

                          <p className="font-regular text-xs px-2 flex flex-col">
                            {
                              <span className="font-semibold">
                                {s.defaultDuration
                                  ? `${s.defaultDuration}`
                                  : ""}
                              </span>
                            }
                            {<span>{s.attendance || "Virtual"}</span>}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="flex-1 shrink-0 overflow-hidden p-2 h-full flex flex-col">
            <div className="flex relative overflow-hidden justify-center items-center px-2">
              <span className="w-[18rem] clip_selection  overflow-hidden   flex justify-center items-center">
                <img
                  src="/images/Clinic/bn_doctors.png"
                  alt=""
                  className="w-full"
                />
              </span>
              <p className="pl-2 bottom-[50%] absolute text-sm font-semibold text-white">
                Clinicians
              </p>
            </div>

            <div className="sm:flel-1 w-full  relative h-full -mt-[2rem] overflow-hidden p-2 rounded-3xl bg-white">
              <div className="h-full overflow-y-scroll sbar2 ">
                <ul className="flex flex-wrap w-full flex-col sm:items-center space-x-2  space-y-2 p-4 pb-10">
                  {doctorList.map((e, i) => (
                    <li
                      onClick={() => {
                        dispatch({type: "SELECT_DOCTOR", payload: e});
                      }}
                      key={i}
                      style={{
                        backgroundColor:
                          bookState.selectedDoctor === e && "#D7D7D7",
                      }}
                      className="hover:cursor-pointer relative bg-[#F5F5F5] sm:w-[15rem]  flex  items-center rounded-2xl min-h-[5.5rem]  p-4  space-x-2"
                    >
                      {bookState.selectedDoctor === e && (
                        <span className="h-4 w-4 absolute top-2 left-2 flex justify-center transition items-center rounded-full text-[1rem]  text-[#363636]">
                          <BsCheckCircleFill />
                        </span>
                      )}
                      <span
                        className="h-4 w-4 absolute bottom-3 right-3 flex justify-center transition items-center rounded-full text-[1rem]  text-[#363636]"
                        onClick={(eve) => {
                          eve.stopPropagation();
                          setDoctor(e);
                        }}
                      >
                        <BsFillInfoCircleFill />
                      </span>
                      <span className="flex justify-center h-14 w-14 items-center">
                        {e.photo ? (
                          <img
                            src={e.photo}
                            alt=""
                            className="hover:cursor-pointer  h-full rounded-full drop-shadow-lg"
                          />
                        ) : (
                          <BsPersonCircle className="text-[3rem] text-white" />
                        )}
                      </span>
                      <div className=" flex  flex-col text-[#636363] ">
                        <p className="text-[12px] leading-4  font-semibold">
                          {e.name}
                        </p>
                        <span className="font-light text-[9px]">
                          {e.accreditations || ""}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col space-y-1  h-[4rem]">
        <button
          style={{
            backgroundColor:
              bookState.selectedService &&
              bookState.selectedDoctor &&
              "#D7D7D7",
          }}
          className="text-[#636363] font-medium p-2 px-20 text-center text-sm bg-[#F2F0F0] font-500 rounded-full"
          onClick={() => {
            bookState.selectedDoctor &&
              bookState.selectedService &&
              dispatch({type: "SELECT_THREAD", payload: "calendar"});
          }}
        >
          Book Now
        </button>
        {!(bookState.selectedService && bookState.selectedDoctor) && (
          <p className="text-xs text-[#636363]">
            *Select both the service and clinican to book your appointment
          </p>
        )}
      </div>
    </div>
  );
}
