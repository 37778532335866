import React from "react";

import {
  Document,
  Page,
  PDFViewer,
  View,
  Text,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import lightFormat from "date-fns/lightFormat";
import InterRegular from "../../additional_files/Inter/static/Inter-Regular.ttf";
import InterMedium from "../../additional_files/Inter/static/Inter-Medium.ttf";
import InterSemibold from "../../additional_files/Inter/static/Inter-SemiBold.ttf";
import InterBold from "../../additional_files/Inter/static/Inter-Bold.ttf";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
///import Body from "./DaySheetPdfBody";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: InterRegular,
    },
    {
      src: InterMedium,
      fontWeight: 500,
    },
    {
      src: InterSemibold,
      fontWeight: 600,
    },
    {
      src: InterBold,
      fontWeight: 700,
    },
  ],
});

export default function ({pdfData, date, returnDoc}) {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: "Inter",
      color: "#1f2937",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "col",
    },
  });

  let Doc = (
    <Document>
      <Page style={styles.page} size={"A4"}>
        <View style={{width: "100%", flex: 1}}>
          <Header location={pdfData.location} />
          <Body {...{pdfData, date}} />
        </View>
      </Page>
    </Document>
  );

  if (returnDoc) return Doc;

  return (
    <PDFViewer width={"100%"} height={"100%"}>
      {Doc}
    </PDFViewer>
  );
}

function Body({pdfData, date}) {
  let style1 = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 40,
    borderBottom: "3px solid black",
    paddingBottom: 5,
  };

  let style2 = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid red",
  };

  const dateFromatter = () => {
    let d = date;
    return `${weekday[d.getDay()]}, ${
      months[d.getMonth()]
    } ${d.getDate()}, ${d.getFullYear()}`;
  };

  return (
    <>
      <View style={{paddingHorizontal: 10}}>
        <View style={style1}>
          <Text style={{fontWeight: 600, fontSize: 16, paddingBottom: 5}}>
            Day Sheet
          </Text>
          <Text style={{fontWeight: 500, fontSize: 12}}>{dateFromatter()}</Text>
        </View>
      </View>
      <View style={{paddingHorizontal: 10}}>
        {pdfData.apptData.map((e, i) => (
          <Table key={i} data={e} location={pdfData.location} />
        ))}
      </View>
    </>
  );
}

function Table({data, location}) {
  let colorsMap = {
    "No Show": "#000000",
    Cancelled: "#FF0000",
    Arrived: "#2dd4bf",
    Confirmed: "#5754FF",
    Rescheduled: "#FF9900",
    Unconfirmed: "#8F8CFF",
  };

  function statusName(appt) {
    let statusMap = {
      noShow: "No Show",
      cancelled: "Cancelled",
      arrived: "Arrived",
      pConfirmed: "Confirmed",
      rescheduled: "Rescheduled",
    };

    for (let [key, val] of Object.entries(statusMap)) {
      if (appt[key]) return val;
    }
    return "Unconfirmed";
  }

  let style1 = {
    flexDirection: "column",
    display: "flex",
    width: "100%",
    gap: 2,
    fontWeight: 600,
  };

  let style2 = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderRadius: 4,
    padding: 8,

    overflow: "hidden",
  };
  let style3 = {textAlign: "center", fontSize: 9, flex: 1};

  let styles4 = {
    fontWeight: 600,
    gap: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: 10,
    padding: 2,
  };

  const header = ["Patient", "Email", "Phone", "Time", "Status", "Service boocked"];

  return (
    <>
      <View style={styles4}>
        <Text style={{fontSize: 10}}>Staff Member:</Text>
        <Text style={{fontSize: 12}}>{data[0]?.dName}</Text>
      </View>
      <View style={style1}>
        <View style={{...style2, marginBottom: 4, backgroundColor: "#94a3b8"}}>
          {header.map((e, i) => (
            <Text key={i} style={{...style3, color: "#fff"}}>
              {e}
            </Text>
          ))}
        </View>

        {data.map((e, i) => {
          let status = statusName(e);
          let statusColor = colorsMap[status];
          let time = "";

          try {
            time = `${lightFormat(e.ISOdate, "h:mm aaa")} - ${lightFormat(
              e.ISOdate + parseInt(e.duration) * 60000,
              "h:mm aaa"
            )}`;
          } catch {}
          return (
            <View
              style={{
                ...style2,
                backgroundColor: i % 2 === 0 ? "#e2e8f0" : "#fff",
              }}
            >
              <Text key={i} style={style3}>
                {e.pName}
              </Text>
              <Text key={i} style={style3}>
                {e.pEmail}
              </Text>
              <Text key={i} style={style3}>
                {e.pPhone}
              </Text>
              <Text key={i} style={style3}>
                {time}
              </Text>
              <Text key={i} style={{...style3, color: statusColor}}>
                {status}
              </Text>
              <Text key={i} style={style3}>
                {e.service}
              </Text>
            </View>
          );
        })}
      </View>
    </>
  );
}

function Header({location}) {
  let infoStyle = {
    fontSize: "10px",
    marginTop: 2,
  };
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        display: "flex",
        paddingHorizontal: 15,
      }}
    >
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",

          display: "flex",
        }}
      >
        {location?.logo && (
          <Image
            style={{
              height: "30px",
            }}
            src={location.logo}
          ></Image>
        )}
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "col",
          fontSize: 9,
          display: "flex",
        }}
      >
        <Text style={infoStyle}>{`${location?.name || "N/A"}`}</Text>
        <Text style={infoStyle}>{`${location?.address || "N/A"}`}</Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              display: "flex",
              marginRight: 3,
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 1,
              }}
            >
              {"Ph:"}
            </Text>
            <Text>{`${location?.phone || "N/A"}`}</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 1,
              }}
            >
              {"Fx:"}
            </Text>
            <Text>{`${location?.fax || "N/A"}`}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
