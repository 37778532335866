import React, {useState, useEffect} from "react";

import {
  Text,
  Badge,
  Stack,
  Table,
  Grid,
  GridItem,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import {EditIcon} from "@chakra-ui/icons";

import {PatientMembershipBillingAside} from "./PatientMembershipBillingAside";
import {PatientMembershipBalancesModal} from "./PatientMembershipBalancesModal";
import {CancelPatientMembership} from "./CancelPatientMembership";

export default function PatientMembershipTable({
  patientMemberships,
  schState,
  dispatch,
  patient,
  setPatientRefetch,
}) {
  const [selectedMembership, setSelectedMembership] = useState(null);
  useEffect(() => {}, [patientMemberships]);
  return (
    <>
      <Stack direction="column" spacing={4} alignItems="center" w="100%">
        <Table height="100%">
          <TableCaption>Patient's membership history</TableCaption>
          <Thead bg="gray.100">
            <Tr>
              <Th>Membership name</Th>
              <Th>Start date</Th>
              <Th>End date</Th>
              <Th>Price</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {patientMemberships?.map((membership) => {
              return (
                <Tr
                  key={membership.uuid}
                  bg={selectedMembership === membership.uuid ? "blue.100" : ""}
                >
                  <Td fontSize={"15px"}>
                    <Text>{membership.name}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>
                      {new Date(membership.startDate).toLocaleDateString(
                        "en-CA"
                      )}
                    </Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>
                      {" "}
                      {new Date(membership.endDate).toLocaleDateString("en-CA")}
                    </Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>$ {parseFloat(membership.price).toFixed(2)}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text fontWeight={"semibold"}>{membership.status}</Text>
                  </Td>
                  <Td>
                    <Stack direction="row">
                      <IconButton
                        variant="outline"
                        aria-label="open menu"
                        icon={<EditIcon />}
                        bg="blue.400"
                        opacity="75%"
                        boxShadow="lg"
                        onClick={() => {
                          setSelectedMembership(membership.uuid);
                        }}
                        size="sm"
                      />
                      <PatientMembershipBalancesModal
                        membership={membership}
                        schState={schState}
                      />
                      <CancelPatientMembership
                        membership={membership}
                        schState={schState}
                        dispatch={dispatch}
                        patient={patient}
                        setPatientRefetch={setPatientRefetch}
                      />
                    </Stack>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Stack>
      {
        <PatientMembershipBillingAside
          patient={patient}
          membership={patientMemberships.find(
            (memb) => memb.uuid === selectedMembership
          )}
          membUuid={selectedMembership}
          schState={schState}
          dispatch={dispatch}
          setSelectedMembership={setSelectedMembership}
          setPatientRefetch={setPatientRefetch}
        />
      }
    </>
  );
}
