import {useState} from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {formatDateTime} from "../helpers/timeFormatters";

export default function SelectForm({forms, setSelectedForm}) {
  const [form, setForm] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSelectedForm(forms.find((f) => f.fid == form));
      }}
    >
      <Box mt="2">
        <FormControl id={"form"}>
          <FormLabel>Attach Form</FormLabel>
          <Flex gap="4">
            <Select
              variant="flushed"
              placeholder="Select Form"
              value={form}
              onChange={(e) => setForm(e.target.value)}
            >
              {forms?.map((f, key) => (
                <option key={key} value={f.fid}>
                  {f.formTitle +
                    " (completed " +
                    formatDateTime(f.completedDatetime, true) +
                    ")"}
                </option>
              ))}
            </Select>
          </Flex>
          <Button px="10" mt="4" colorScheme="blue" type="submit">
            Attach
          </Button>
        </FormControl>
      </Box>
    </form>
  );
}
