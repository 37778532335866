import {RiDeleteBin5Line} from "react-icons/ri";
import SignatureCanvas from "react-signature-canvas";
import {MdClose} from "react-icons/md";
import {PiSignatureDuotone} from "react-icons/pi";
export default function SignatureModal({
  sigRef,
  loading,
  saveSignature,
  signatureValue,
  setShowModal,
  onSave,
}) {
  return (
    <div className="flex justify-center items-center [margin-top:0_!important] fixed z-[999] inset-0 bg-black/40">
      <div className="relative p-8 rounded-md bg-white w-[500px] flex justify-center items-center">
        {
          <MdClose
            className="text-black cursor-pointer text-lg absolute right-3 top-3"
            onClick={() => {
              setShowModal(null);
              sigRef.current.clear();
              saveSignature("");
            }}
          />
        }
        <form
          className="w-full h-full flex justify-center items-start flex-col"
          onSubmit={(e) => {
            e.preventDefault();
            onSave();
          }}
        >
          <div className="relative flex justify-center items-start flex-col shadow-md border h-full w-full my-3 bg-white rounded-md">
            <span className="absolute text-xs -top-[22px] left-3 text-[#8F8CFF]  bg-white px-[2px]">
              <span className="text-[2rem]">
                <PiSignatureDuotone />
              </span>
            </span>
            <span
              className="flex absolute cursor-pointer hover:text-indigo-600 -top-[15px] right-3 justify-center items-center text-[#a6a3f8] text-[1.4rem] bg-white"
              onClick={(e) => {
                e.stopPropagation();
                sigRef.current.clear();
                saveSignature("");
              }}
            >
              <RiDeleteBin5Line />
            </span>
            <div className="mx-auto border flex overflow-hidden justify-center w-full">
              <SignatureCanvas
                penColor="#111827"
                maxWidth={2.5}
                ref={sigRef}
                onEnd={() =>
                  saveSignature(sigRef.current.toDataURL("image/png"))
                }
                canvasProps={{height: 150, width: 450}}
              />
            </div>
          </div>
          <button
            className="bg-[rgba(89,84,232,0.75)] mt-2 self-end flex justify-center disabled:cursor-not-allowed cursor-pointer items-center text-white p-1 text-[1rem] rounded-[10px] px-6 focus:outline-none hover:bg-[#5754FF]"
            disabled={loading || !signatureValue}
          >
            <span className="space-x-2 flex justify-center items-center">
              <span>Save Signature</span>
            </span>
            {loading && (
              <span className="">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
