import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Divider,
  Text,
  Stack,
  TableCaption,
  Table,
  Tr,
  Td,
  Thead,
  Th,
  Tbody,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import {CheckCircleIcon} from "@chakra-ui/icons";

export function PatientMembershipBalancesModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const serviceMap = {};
  for (const service of props.schState?.services) {
    if (
      Object.keys(props.membership.selectedServices).includes(service.serviceId)
    ) {
      serviceMap[service.serviceId] = service;
    }
  }
  return (
    <Stack>
      <IconButton
        icon={<CheckCircleIcon />}
        variant="outline"
        onClick={onOpen}
        bg="purple.400"
        opacity="75%"
        boxShadow="lg"
        size="sm"
      />
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">
            Patient summary for {props.membership.name}
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Valid from{" "}
              {new Date(props.membership.startDate).toLocaleDateString("en-CA")}{" "}
              to{" "}
              {new Date(props.membership.endDate).toLocaleDateString("en-CA")}
            </Text>
            <Table height="100%">
              <TableCaption>
                Patient's remaining balance for this membership
              </TableCaption>
              <Thead bg="gray.100">
                <Tr>
                  <Th>Service</Th>
                  <Th>Description</Th>
                  <Th>Category</Th>
                  <Th>Included</Th>
                  <Th>Reserved</Th>
                  <Th>Consumed</Th>
                  <Th>Balance</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(props.membership.selectedServices).map(
                  (service) => {
                    const serviceData =
                      props.membership.selectedServices[service];
                    return (
                      <Tr key={service}>
                        <Td fontSize={"15px"}>
                          <Text>{serviceMap[service]?.serviceName}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{serviceMap[service]?.description}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{serviceMap[service]?.category}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{serviceData.quantity}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{serviceData.reserved || 0}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{serviceData.consumed || 0}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          {serviceData.quantity -
                            (serviceData.consumed || 0) -
                            (serviceData.reserved || 0)}
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose} w="1/3">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
