import {crud} from "../../../../crudRequests";

export async function updatePatientStatus(
  patient,
  dashState,
  inactive,
  deceased,
  deleted,
  toast
) {
  const {_id, ...patientToUpdate} = patient;
  crud(dashState, [
    {
      db: dashState.db,
      collection: "patients",
      parameters: [
        {pid: patientToUpdate.pid},
        {
          $set: {
            ...patientToUpdate,
            inactive: inactive,
            deceased: deceased,
            deleted: deleted,
          },
        },
      ],
      method: "updateOne",
    },
  ])
    .then((res) => {
      toast({
        title: "Patient Status Updated",
        status: "success",
        isClosable: true,
      });
    })
    .catch((err) => {
      toast({
        title: "Patient Not Updated",
        description: err?.message || "An error occurred.",
        status: "error",
        isClosable: true,
      });
      console.log(err);
    });
}
