import React, {useState, useEffect, useRef} from "react";
import {MdClose} from "react-icons/md";
export default function PDFModal({data, showModal}) {
  let iframeStyle = {
    height: "100%",
    width: "100%",

  };
  return (
    <div className="fixed inset-0 bg-black/50 z-[9999] flex justify-center items-center">
      <div className="h-[90%] w-[50rem] relative flex justify-center items-center p-10 bg-white rounded-lg">
        {
          <MdClose
            className="text-black cursor-pointer text-lg absolute right-4 top-4 "
            onClick={() => {
                showModal(null)
            }}
          />
        }
        <iframe
          src={data}
          style={iframeStyle}
          className="w-full hfull  rounded-lg sbar2 border"
        ></iframe>
      </div>
    </div>
  );
}
