import React, {useState, useEffect, useRef, useMemo} from "react";
import {nanoid} from "nanoid";
import Swal from "sweetalert2";
import {MdModeEditOutline, MdDeleteForever} from "react-icons/md";
import {useToast} from "@chakra-ui/react";
import {crud} from "../crudRequests";
import TriggerInfoSearch from "./TriggerInfoSearch";

import {Skeleton, SkeletonCircle, SkeletonText} from "@chakra-ui/react";
export default function ProviderTypesTable({schState, dispatch}) {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState("");
  const [types, setTypes] = useState(schState.providerTypes || []);
  const [selectedType, setSelectedType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const searchRef = useRef(null);
  const searchText = useRef("");
  const toast = useToast();

  //console.log(schState.providerTypes);

  useEffect(() => {
    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };
    window.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  useEffect(() => {
    setTypes(schState.providerTypes || []);
  }, [schState.selectedLocation, schState.providerTypes]);

  function update(type) {
    if (searchValue.length === 0) {
      return type;
    } else if (type["name"]?.toLowerCase().includes(searchValue)) {
      return type;
    } else {
      return null;
    }
  }

  let f = types.filter(update);

  return (
    <>
      {showModal && (
        <AddOrEditModal
          state={schState}
          dispatch={dispatch}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setShowModal={setShowModal}
          setTypes={setTypes}
        />
      )}

      <div className="relative p-8 pb-4 pt-5 w-full h-full flex flex-col patients-table-container">
        <div className="bg-off relative mx-4  bg-opacity-25 text-opacity-100 flex items-center rounded-full">
          <span
            className="text-xl text-off absolute left-3"
            ref={searchRef}
            onClick={() => {
              if (searchRef.current) {
                searchRef.current.className = "hidden";
                searchText.current.focus();
              }
            }}
          >
            <span className="font-semibold">Search type</span>{" "}
            <span className="font-light">
              by name<span className="font-semibold">...</span>
            </span>
          </span>
          <span className="text-xl text-off absolute right-2">
            <img src="/images/Doctor/search_icon.png" alt="" className="w-6" />
          </span>
          <input
            type="text"
            className="w-full bg-off p-3 px-4 bg-opacity-0 text-off rounded-full focus:outline-none  ring-[#8F8CFF] focus:ring-2 focus:ring-[#8F8CFF]"
            ref={searchText}
            onChange={() =>
              setSearchValue(searchText.current.value.toLowerCase())
            }
            onFocus={() => {
              if (searchRef.current) searchRef.current.className = "hidden";
            }}
            onBlur={() => {
              if (!searchText.current.value) {
                setTimeout(() => {
                  if (searchRef.current) {
                    searchRef.current.className =
                      "text-xl text-off absolute left-3";
                  }
                }, 100);
              }
            }}
          />
        </div>
        <TriggerInfoSearch text="Currently, provider types records are searchable on this page by a name." />

        <div className="flex mt-4">
          <button
            className={
              "cursor-pointer focus:outline-none  text-sm bg-background ring-[3px] ring-dark text-dark px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              if (!searchText.current.value) {
                setSearchValue("");
                searchText.current.value = "";
                setTimeout(() => {
                  searchRef.current.className =
                    "text-xl text-off absolute left-3";
                }, 100);
              }
            }}
          >
            <span> Clear </span>
          </button>
          <button
            className={
              "cursor-pointer text-sm ring-[3px] ring-dark bg-dark text-background px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              setSelectedType(null);
              setShowModal(true);
            }}
          >
            <span> Add a Type </span>
          </button>
        </div>
        <div
          className="rounded-md m-4 mt-2 py-2 px-5 text-sm text-background font-medium text-center"
          style={{backgroundColor: "#8F8CFF"}}
        >
          <ol className="grid grid-cols-3 gap-3 text-center align-middle sm:text-[13px] ml-2 lg:text-base">
            <li>Type Name</li>
            <li>Tax (%)</li>
            <li>Actions</li>
          </ol>
        </div>
        <div className="overflow-hidden flex justify-center">
          <div className="overflow-y-scroll  w-full">
            {f.length > 0 ? (
              f.map((r, idx) => {
                return (
                  <div
                    key={idx}
                    className="rounded-md m-4 mt-0 py-3 px-5 text-sm text-dark text-opacity-75 font-medium text-center h-fit"
                    style={{backgroundColor: "#DFDEFF"}}
                  >
                    <ol className="grid grid-cols-3 gap-4 place-items-center  text-xs md:text-sm align-middle text-center">
                      <li>{r.name}</li>
                      <li>{r.tax || 0}</li>
                      <li>
                        <button
                          className="text-xl mx-1 cursor-pointer"
                          onClick={() => {
                            setSelectedType(r);
                            setShowModal(true);
                          }}
                        >
                          <MdModeEditOutline />
                        </button>
                        <button
                          className="font-bold text-xl"
                          onClick={() => {
                            Swal.fire({
                              title: `Are you sure you want to remove this provider type?`,
                              html: `This provider type will be permanently removed.`,
                              icon: "warning",
                              showCancelButton: true,
                            }).then(async (value) => {
                              if (value.isConfirmed) {
                                await crud(schState, [
                                  {
                                    db: schState.db,
                                    collection: "provider_types",
                                    parameters: [{typeId: r.typeId}],
                                    method: "deleteOne",
                                  },
                                ])
                                  .then((e) => {
                                    setTypes((prev) =>
                                      prev.filter((t) => t.typeId !== r.typeId)
                                    );
                                    dispatch({
                                      type: "DELETE_PROVIDER_TYPE",
                                      payload: r.typeId,
                                    });
                                    toast({
                                      title:
                                        "The provider type has been successfully removed!",
                                      status: "success",
                                      duration: 3000,
                                      isClosable: true,
                                    });
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                    //if (error.code !== "ERR_CANCELED") window.location.href = "../login"
                                  });
                              }
                            });
                          }}
                        >
                          <span>
                            <MdDeleteForever />
                          </span>
                        </button>
                      </li>
                    </ol>
                  </div>
                );
              })
            ) : (
              <span className="ml-5 text-sm text-off">No types to display</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const AddOrEditModal = ({
  state,
  dispatch,
  selectedType,
  setSelectedType,
  setShowModal,
  setTypes,
}) => {
  const [loading, setLoading] = useState("");
  const toast = useToast();
  const defaultData = useMemo(
    () => ({
      typeId: nanoid(10),
      lid: state.selectedLocation,
      name: "",
      tax: 0,
    }),
    []
  );

  const [fields, setFields] = useState(
    selectedType ? {...defaultData, ...selectedType} : defaultData
  );

  let {_id, ...payload} = fields;

  function validate() {
    return !!fields.name;
  }

  const addOrUpdate = async () => {
    try {
      if (validate()) {
        setLoading(true);
        payload.tax = parseFloat(payload.tax);
        payload.lid = state.selectedLocation;

        if (!selectedType) {
          await crud(state, [
            {
              db: state.db,
              collection: "provider_types",
              parameters: [{...payload}],
              method: "insertOne",
            },
          ]);
          setLoading(false);
          setSelectedType(null);
          setShowModal(false);
          setTypes((prev) => prev.concat(payload));
          dispatch({type: "NEW_PROVIDER_TYPE", payload: payload});
          toast({
            title: "The new provider type has been created successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          await crud(state, [
            {
              db: state.db,
              collection: "provider_types",
              parameters: [{typeId: payload.typeId}, {$set: {...payload}}],
              method: "updateOne",
            },
          ]);
          setLoading(false);
          setSelectedType(null);
          setShowModal(false);
          setTypes((prev) => {
            return prev.map((r) =>
              r.typeId === payload.typeId ? {...r, ...payload} : r
            );
          });
          dispatch({type: "UPDATE_PROVIDER_TYPE", payload: payload});
          toast({
            title: "The provider type has been successfully updated!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } else
        toast({
          title: "Please complete the mandatory fields!",
          description: "Mandatory fields must be completed!",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
    } catch (error) {
      setLoading(false);
      setSelectedType(null);
      setShowModal(false);
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 show z-[999] overflow-hidden flex justify-center items-center"
      style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div
        className="relative flex max-h-[800px] w-[500px]  items-center p-2 py-4 bg-white overflow-y-hidden rounded-3xl  sbar2 justify-center "
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="relative h-full w-full overflow-y-scroll rounded-3xl p-5 sbar">
          <div className="bg-[#f6f5ff] mx-2 text-left  px-4 pt-10 rounded-xl overflow-hidden relative">
            {/* close button */}
            <button
              type="button"
              className="- text-gray-400  bg-transparent  absolute top-5 right-5 rounded-lg text-sm   inline-flex items-center cursor-pointer focus:outline-none"
              data-modal-toggle="small-modal"
              style={{boxShadow: "none"}}
              onClick={() => {
                setShowModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <h3
              className="text-2xl font-medium px-5 mb-3 text-gray-800"
              style={{color: "rgb(192, 191, 255)"}}
            >
              {!selectedType ? "Create Provider Type" : "Edit Provider Type"}
            </h3>

            <div className="h-full mt-5">
              <div className="flex gap-2">
                <div className="overflow-hidden  w-full rounded-md pb-2 ">
                  <label className="block px-2 my-1 w-full" htmlFor="name">
                    <span className="text-[13px] ml-2 font-medium  text-off ">
                      Provider Type Name*
                    </span>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={fields.name}
                      onChange={(e) => {
                        setFields((prev) => {
                          return {
                            ...prev,
                            name: e.target.value,
                          };
                        });
                      }}
                      className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </label>
                </div>
                <div className="overflow-hidden  w-full rounded-md pb-2">
                  <label className="block px-2 my-1 w-full" htmlFor="name">
                    <span className="text-[13px] ml-2 font-medium  text-off ">
                      Tax
                    </span>
                    <input
                      type="number"
                      name="name"
                      id="name"
                      value={fields.tax}
                      onChange={(e) => {
                        let value = e.target.value;

                        if (!isNaN(value) && !value.match(/-/g)) {
                          if (value === "") value = 0;
                          setFields((prev) => {
                            return {
                              ...prev,
                              tax: value,
                            };
                          });
                        }
                      }}
                      className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-6  mb-4 mx-auto">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  addOrUpdate();
                }}
                disabled={loading}
                className=" w-full rounded-lg disabled:cursor-not-allowed flex justify-center items-center text-background bg-med py-2"
              >
                {!selectedType ? "Create Provider Type" : "Save changes"}
                {loading && (
                  <span className="">
                    <svg
                      className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25 stroke-[4px]"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Warning({warning, setWarning, className}) {
  return (
    <div
      className={
        className ||
        "show z-10 absolute top-3 left-3 text-xs bg-yellow-500 p-3 w-48 rounded-md"
      }
    >
      <div className="font-bold flex">
        <span className="flex text-white items-center space-x-1">
          <img
            alt=""
            src="/images/Clinic/warn.png"
            className="h-4  inline-block"
          />{" "}
          <span> Warning:</span>
        </span>
        <span
          className="ml-auto mr-1 text-sm text-white underline cursor-pointer font-light"
          onClick={(e) => setWarning("")}
        >
          x
        </span>
      </div>
      <div className="font-regular mt-1 text-white">{warning}</div>
    </div>
  );
}
