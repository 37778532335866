import {crud} from "../../../crudRequests";

export default async function fetchProducts(
  state,
  setIsLoading,
  setProducts,
  toast
) {
  setIsLoading(true);
  //let matchQuery = {lid: state.selectedLocation};
  let matchQuery = {};

  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "products",
        parameters: [matchQuery],
        method: "find",
      },
    ]);

    setProducts(res.data[0]);

    setIsLoading(false);
  } catch (err) {
    console.log("error", err);
    toast({
      title: "Failed to fetch products data",
      description:
        err?.message || "An error occurred while fetching products data.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
  }
}
