import {TiMessages} from "react-icons/ti";
import {MdNotifications, MdOutlineClose} from "react-icons/md";
import {GoCalendar} from "react-icons/go";
import {useEffect, useRef, useState} from "react";
import {FaWpforms} from "react-icons/fa";
import {crud} from "../crudRequests";
import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  lightFormat,
  startOfMonth,
  endOfMonth,
} from "date-fns";

export default function Notification({schState, dispatch}) {
  const cont = useRef(
    (schState.notifications || []).reduce((ac, e) => ac + (e.read ? 0 : 1), 0)
  );
  cont.current = (schState.notifications || []).reduce(
    (ac, e) => ac + (e.read ? 0 : 1),
    0
  );

  const [showList, setShowList] = useState(false);
  const sl = useRef(showList);
  sl.current = showList;
  useEffect(() => {
    let f = (e) => {
      if (sl.current) {
        setShowList(false);
      }
    };
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, []);

  return (
    <div className="absolute top-6 z-[33] right-[6rem] flex justify-center items-center">
      <div
        className="bg-light opacity-75 p-[5px] rounded-md cursor-pointer shrink-0"
        onClick={(e) => {
          e.stopPropagation();
          schState.notifications.length > 0 && setShowList(!showList);
          showList && dispatch({type: "READ_NOTIFICATION"});
        }}
      >
        <span className="flex justify-center items-center">
          <MdNotifications className="text-dark text-[32px]" />
        </span>
        {cont.current > 0 && (
          <span className="absolute top-[2px] -right-[1px] h-[2px] w-[2px] p-[7px] rounded-full flex justify-center items-center leading-[8px] font-medium bg-[#FF0000] text-[8px] text-white">
            {cont.current}
          </span>
        )}
      </div>
      {showList && schState.notifications.length > 0 && (
        <NotificationList {...{schState, dispatch, cont, setShowList}} />
      )}
    </div>
  );
}

function NotificationList({schState, dispatch, cont, setShowList}) {
  useEffect(() => {
    return () => {
      setShowList(false);
      if (cont.current > 0) {
        let userId =
          schState.userType === "admin"
            ? schState.selectedLocation + schState.admin?.oid
            : schState.doctor.did;
        dispatch({type: "READ_NOTIFICATIONS"});
        crud(schState, [
          {
            db: schState.db,
            collection: "notifications",
            parameters: [
              {userType: schState.userType},
              {$set: {[`${userId}.$[].read`]: true}},
            ],
            method: "findOneAndUpdate",
          },
        ]);
      }
    };
  }, []);

  return (
    <div className="absolute show top-14 right-0  h-52 w-72  flex items-start flex-col space-y-2">
      <p
        className="rounded-full p-2 leading-[5px] text-[10px] text-[#DFDEFF] font-semibold bg-[#7977FF] cursor-pointer"
        onClick={(e) => {
          let userId =
            schState.userType === "admin"
              ? schState.selectedLocation + schState.admin?.oid
              : schState.doctor.did;
          e.stopPropagation();
          dispatch({type: "CLEAR_NOTIFICATIONS"});
          setShowList(false);
          crud(schState, [
            {
              db: schState.db,
              collection: "notifications",
              parameters: [
                {userType: schState.userType},
                {$set: {[userId]: []}},
              ],
              method: "updateOne",
            },
          ]);
        }}
      >
        Clear All
      </p>
      <div className="w-full overflow-hidden max-h-40 px-1">
        <ul className="overflow-y-scroll board p-1 h-full space-y-2 rounded-lg  bg-white/50">
          {(schState.notifications || []).map((ntf, i) =>
            notificationsFrmatter(ntf, i, schState, dispatch)
          )}
        </ul>
      </div>
    </div>
  );
}

let colorsMap = {
  "No Show": "#000000",
  Cancelled: "#FF0000",
  Confirmed: "#5754FF",
  Rescheduled: "#FF9900",
  Unconfirmed: "#8F8CFF",
};

function notificationsFrmatter(ntf, idx, schState, dispatch) {
  const onDelete = (e) => {
    e.stopPropagation();
    dispatch({type: "DELETE_NOTIFICATION", payload: {ntfId: ntf.ntfId}});
    let userId =
      schState.userType === "admin"
        ? schState.selectedLocation + schState.admin?.oid
        : schState.doctor.did;

    crud(schState, [
      {
        db: schState.db,
        collection: "notifications",
        parameters: [
          {userType: schState.userType},
          {$pull: {[userId]: {ntfId: ntf.ntfId}}},
        ],
        method: "updateOne",
      },
    ]);
  };

  switch (ntf.type) {
    case "New appointment":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#c5c3f9" : null}}
          className="bg-[#DFDEFF] h-14 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">
            {lightFormat(new Date(ntf.creationDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>
          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <GoCalendar />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction with P. ${ntf.pName} `}</span>
              <span
                style={{color: colorsMap[ntf.status]}}
              >{`${ntf.status} `}</span>{" "}
              for
              <span className="font-semibold">{` ${dateFormatter(
                ntf.apptDate
              )}`}</span>
            </p>
          </div>
        </li>
      );

    case "Form Completed":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#c5c3f9" : null}}
          className="bg-[#DFDEFF] h-16 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">
            {lightFormat(new Date(ntf.completedDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>
          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <FaWpforms />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Form`}</span>
              <span className="font-semibold">{` ${ntf.formTitle} `}</span>
              <span>{`completed by ${ntf.pName}. `}</span>
            </p>
          </div>
        </li>
      );

    case "Updated appointment":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#c5c3f9" : null}}
          className="bg-[#DFDEFF] h-14 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">
            {lightFormat(new Date(ntf.creationDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>

          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <GoCalendar />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction with P. ${ntf.pName} `}</span>
              <span
                style={{color: colorsMap[ntf.status]}}
              >{`${ntf.status} `}</span>{" "}
              for
              <span className="font-semibold">{` ${dateFormatter(
                ntf.apptDate
              )}`}</span>
            </p>
          </div>
        </li>
      );

    case "New chatbot intake":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#c5c3f9" : null}}
          className="bg-[#DFDEFF] h-14 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">
            {lightFormat(new Date(ntf.creationDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>

          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <TiMessages />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction registered for P. ${ntf.pName}`}</span>
            </p>
          </div>
        </li>
      );

    default:
      break;
  }
}
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function dateFormatter(date) {
  date = new Date(date);
  return `${months[date.getMonth()]} ${date.getDate()} at ${lightFormat(
    date,
    "h:mm aaa"
  )}`;
}
