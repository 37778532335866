import {getAuthor} from "./getAuthor";
import {crud} from "../../../../crudRequests";

import {v4 as uuidv4} from "uuid";
export const saveDraft = async (
  dashState,
  patient,
  editorRef,
  reportId,
  reportTitle,
  setReportId,
  setDirty,
  evt,
  setEvt,
  toast,
  reports,
  setReports,
  apptId,
  setControlDisabled
) => {
  try {
    if (editorRef.current && patient) {
      const content = editorRef.current.getContent();
      setControlDisabled(true);
      if (!reportTitle) {
        setControlDisabled(false);
        toast({
          title: "Enter a chart title",
          description:
            "Please create a title for this chart in order to save it.",
          status: "error",
          isClosable: true,
          duration: 5000,
        });
        return;
      }

      if (reportId) {
        // report already exists
        await crud(dashState, [
          {
            db: dashState.db,
            collection: "reports",
            parameters: [
              {id: reportId},
              {
                $set: {
                  title: reportTitle,
                  aid: apptId,
                  content: content,
                },
              },
            ],
            method: "updateOne",
          },
        ])
          .then(async (res) => {
            setReports((prev) => {
              return prev.map((r) =>
                r.id === reportId ? {...r, title: reportTitle, content} : r
              );
            });

            setDirty(false);
            editorRef.current.setDirty(false);
            setEvt((prev) => prev + 1);
            setControlDisabled(false);
            toast({
              title: "Draft Saved",
              description: "Successfully saved chart draft.",
              status: "success",
              isClosable: true,
              duration: 3000,
            });
          })
          .catch((err) => {
            setControlDisabled(false);
            console.error("saveDraft error: ", err);
          });
      } else {
        // saving a new report

        const reportId = uuidv4();
        const newReport = {
          id: reportId,
          creator: getAuthor(dashState),
          pid: patient.pid,
          date: new Date().toISOString(),
          title: reportTitle,
          aid: apptId,
          content: content,
          completed: false,
        };

        await crud(dashState, [
          {
            db: dashState.db,
            collection: "reports",
            parameters: [newReport],
            method: "insertOne",
          },
        ])
          .then((res) => {
            setReports((prev) => prev.concat(newReport));
            setReportId(reportId);

            setDirty(false);
            editorRef.current.setDirty(false);
            setEvt((prev) => prev + 1);
            setControlDisabled(false);
            toast({
              title: "Draft Saved",
              description: "Successfully saved chart draft.",
              status: "success",
              isClosable: true,
              duration: 3000,
            });
          })
          .catch((err) => {
            setControlDisabled(false);
            console.error("saveDraft error: ", err);
          });
      }
    }
  } catch (error) {
    setControlDisabled(false);
    console.error("saveDraft error: ", error);
  }
};
