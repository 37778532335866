import {
  PDFDownloadLink,
  Document,
  Page,
  PDFViewer,
  View,
  Text,
  StyleSheet,
  Note,
  Image,
  Font,
} from "@react-pdf/renderer";

import InterRegular from "../additional_files/Inter/static/Inter-Regular.ttf";
import InterMedium from "../additional_files/Inter/static/Inter-Medium.ttf";
import InterSemibold from "../additional_files/Inter/static/Inter-SemiBold.ttf";
import InterBold from "../additional_files/Inter/static/Inter-Bold.ttf";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: InterRegular,
    },
    {
      src: InterMedium,
      fontWeight: 500,
    },
    {
      src: InterSemibold,
      fontWeight: 600,
    },
    {
      src: InterBold,
      fontWeight: 700,
    },
  ],
});

export default function FormsPDF({form, state, returnDoc, completed}) {
  const styles = StyleSheet.create({
    page: {padding: 50, paddingTop: 60, fontFamily: "Inter"},
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#1f2937",
      width: "100%",
    },
    organizationDescription: {
      fontSize: "14px",
      color: "#1f2937",
      // marginLeft: "10px",
      marginTop: "10px", // Adjusted spacing
    },
    formContainer: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  });

  let locSrc = state.locations?.find((l) => l.lid === state.selectedLocation);
  let orgSrc = state.organization;

  const Doc = () => (
    <Document>
      <Page style={styles.page} size="A4" wrap={true}>
        <View
          style={{
            flexDirection: "row",
            marginBottom: "20px",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/*orgSrc?.description && (
            <Text style={styles.organizationDescription}>
               {orgSrc?.description} 
            </Text>
          )*/}
          {locSrc?.logo && (
            <Image
              style={{
                height: "40px",
                padding: "4px",
              }}
              src={locSrc.logo}
            ></Image>
          )}
        </View>

        <View style={styles.formContainer}>
          {Object.values(form).map((val, i) => (
            <View style={{}} key={i}>
              {PdfElements(Object.values(val), completed)}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
  if (returnDoc) return <Doc />;
  return (
    <PDFViewer width={"100%"} height={"100%"}>
      {<Doc />}
    </PDFViewer>
  );
}

function PdfElements(row, completed) {
  return (
    <>
      <View
        style={{
          flexDirection: "row",
          marginTop: "10px",
          flexWrap: "wrap",
        }}
      >
        {row
          .filter(
            (ele) => ele.key !== "Select Menu" && ele.key !== "Check Boxes"
          )
          .map((e, i) => (
            <GetPDFElement returnDoc={completed} ele={e} key={i} />
          ))}
      </View>
      <View>
        {row
          .filter(
            (ele) => ele.key === "Select Menu" || ele.key === "Check Boxes"
          )
          .map((e, i) => (
            <GetPDFElement returnDoc={completed} ele={e} key={i} />
          ))}
      </View>
    </>
  );
}

function GetPDFElement({ele, completed}) {
  switch (ele.key) {
    case "Select Menu":
      return select(ele, completed);

    case "Check Boxes":
      return checkboxes(ele, completed);

    case "Text":
      return text(ele, completed);

    case "Heading":
      return heading(ele, completed);

    case "Subheading":
      return subHeading(ele, completed);

    case "Paragraph":
      return paragraph(ele, completed);

    case "Note":
      return note(ele, completed);

    case "Slider":
      return slider(ele, completed);

    case "Date":
      return date(ele, completed);

    case "Line Break":
      return linebreak(ele, completed);

    default:
      return null;
  }
}

function slider(ele, completed) {
  return (
    <View
      style={{
        justifyContent: "center",
        marginBottom: "20px",
        marginTop: "10px",
        marginRight: "20px",
      }}
    >
      <Text
        style={{
          color: "#1f2937",
          fontSize: "12px",
          marginBottom: "40px",
        }}
      >
        {ele.children[0].children}
      </Text>

      <Text
        style={{
          fontSize: "11px",
          borderBottom: "1px solid #475569",
          width: "200px",
          textAlign: "center",
        }}
      ></Text>

      <View
        style={{
          flexDirection: "row",
          marginTop: "10px",
          alignItems: "flex-end",
        }}
      >
        {" "}
      </View>
    </View>
  );
}

function date(ele, completed) {
  return (
    <View
      style={{
        justifyContent: "center",
        marginBottom: "20px",
        marginTop: "10px",
        marginRight: "20px",
      }}
    >
      <Text
        style={{
          color: "#1f2937",
          fontSize: "12px",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      >
        {ele.children[0].children}
      </Text>
      <View style={{marginTop: "10px"}}>
        <Text
          style={{
            fontSize: "11px",
            borderBottom: "1px solid #475569",
            width: "200px",
            textAlign: "center",
          }}
        ></Text>
        <Text
          style={{
            fontSize: "11px",
            color: "#475569",
            textAlign: "center",
            marginTop: "5px",
            justifyContent: "center",
            width: "200px",
          }}
        >
          dd/mm/yyyy
        </Text>
      </View>
    </View>
  );
}

function linebreak(ele, completed) {
  return (
    <Text
      style={{
        marginVertical: "40px",
        fontSize: "11px",
        borderBottom: "1px solid #475569",
        width: "100%",
        textAlign: "center",
      }}
    ></Text>
  );
}

function select(ele, completed) {
  return (
    <View
      style={{
        marginBottom: "20px",
        marginRight: "20px",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          color: "#1f2937",
          fontSize: "12px",
          marginTop: "10px",
        }}
      >
        {ele.children[0].children}
      </Text>

      <View
        style={{
          flexDirection: "row",
          marginTop: "10px",
          flexWrap: "wrap",
        }}
      >
        {ele.children[1].children.map((e, i) => (
          <View
            key={i}
            style={{
              flexDirection: "row",
              marginTop: "15px",
              marginRight: "20px",
              alignItems: "flex-end",
            }}
          >
            <Text
              style={{
                fontSize: "11px",
                borderBottom: "1px solid #475569",
                width: "20px",
                textAlign: "center",
                marginRight: "5px",
              }}
            >
              {ele.children[1].props?.value === e.props?.value?.toString()
                ? "X"
                : ""}
            </Text>
            <Text style={{fontSize: "11px", color: "#1f2937"}}>
              {e.props?.value?.toString()}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}

function checkboxes(ele, completed) {
  return (
    <View
      style={{
        marginBottom: "10px",
        marginRight: "20px",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          color: "#1f2937",
          fontSize: "12px",
          marginTop: "10px",
        }}
      >
        {ele.children[0].children}
      </Text>

      <View
        style={{
          flexDirection: "row",
          marginTop: "10px",
          flexWrap: "wrap",
          textAlign: "center",
        }}
      >
        {ele.children[1].children.map((e, i) => (
          <View
            key={i}
            style={{
              flexDirection: "row",
              alignItems: "flex-end",
              textAlign: "center",
              marginRight: "10px",
              marginTop: "15px",
            }}
          >
            <Text
              style={{
                border: "1px solid #475569",
                width: "14px",
                height: "14px",
                marginRight: "5px",
                borderRadius: "2px",
                textAlign: "center",
              }}
            ></Text>
            <Text
              style={{
                fontSize: "12px",
                textAlign: "center",
                marginRight: "2px",
              }}
            >
              {e.children[0].props.checked ? "X" : ""}
            </Text>
            <Text style={{fontSize: "11px", color: "#374151"}}>
              {e.children[0].props.value?.toString()}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}

function note(ele, completed) {
  return (
    <View
      style={{
        marginBottom: "20px",
        marginTop: "10px",
        marginRight: "20px",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          color: "#1f2937",
          fontSize: "12px",
          fontWeight: "medium",
        }}
      >
        {ele.children[0].children?.toString()}
      </Text>

      <View
        style={{
          flexDirection: "row",
          marginTop: "10px",
          width: "450px",
          height: "70px",
          flexWrap: "wrap",
          border: "1px solid black",
          marginRight: "10px",
        }}
      >
        <Text
          style={{
            color: "#374151",
            fontSize: "11px",
            paddingLeft: "10px",
          }}
        >
          {ele.children[1]?.props.value || ""}
        </Text>
      </View>
    </View>
  );
}

function text(ele, completed) {
  return (
    <View
      style={{
        marginBottom: "30px",
        marginTop: "10px",
        marginRight: "20px",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          color: "#1f2937",
          fontSize: "12px",
          fontWeight: "medium",
          marginRight: "25px",
        }}
      >
        {ele.children[0].children?.toString()}
      </Text>

      {ele.children[0].children?.toString() && (
        <Text
          style={{
            fontSize: "11px",
            borderBottom: "1px solid #475569",
            width: "200px",
            textAlign: "center",
            marginTop: "40px",
          }}
        />
      )}
      <Text
        style={{
          color: "#374151",
          fontSize: "11px",
          paddingLeft: "10px",
        }}
      >
        {ele.children[1]?.props.value || ""}
      </Text>

      {ele.children[1]?.props.value && (
        <Text
          style={{
            fontSize: "11px",
            borderBottom: "1px solid #475569",
            width: "200px",
            textAlign: "center",
            marginTop: "40px",
          }}
        />
      )}
    </View>
  );
}

function heading(ele, completed) {
  return (
    <View style={{marginBottom: "10px"}}>
      <Text
        style={{
          fontWeight: "bold",
          fontSize: "12px",
          color: "#1f2937",
        }}
      >
        {ele.children[0].children?.toString()}
      </Text>
    </View>
  );
}

function subHeading(ele, completed) {
  console.log(ele.children[0].children?.toString());
  return (
    <View style={{marginTop: "10px", marginBottom: "10px"}}>
      <Text
        style={{
          fontWeight: "bold",
          fontSize: "12px",
          color: "#1f2937",
        }}
      >
        {ele.children[0].children?.toString()}
      </Text>
    </View>
  );
}

function paragraph(ele, completed) {
  return (
    <View
      style={{
        marginBottom: "20px",
        marginRight: "20px",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: "11px",
          color: "#374151",
          paddingBottom: "10px",
        }}
      >
        {ele.children[0].children?.toString()}
      </Text>
    </View>
  );
}

////
