import {crud} from "../../../../crudRequests";
import {fetchAndExpire} from "./fetchAndExpire";

export default async function expirePrescription(
  presId,
  setPrescriptions,
  state,
  {pid},
  toast
) {
  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "prescriptions",
        parameters: [{presId}, {$set: {expired: true}}],
        method: "updateOne",
      },
    ]);

    toast({
      title: "Prescription expired",
      description: "Prescription expired successfully",
      status: "success",
      isClosable: true,
    });
    // refetch the prescriptions
    await fetchAndExpire(setPrescriptions, state, {pid}, toast);
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
}
