import {
  Box,
  useToast,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Icon,
  TableContainer,
} from "@chakra-ui/react";
import {FiDownload} from "react-icons/fi";
import {downloadFile} from "../Files/helpers/downloadFile";

import Loader from "../../appointments/clinicAidClaimModal/Loader";

function PatientRequisitionRow(props) {
  const toast = useToast();

  return (
    <Tr>
      <Td>{`${props.patient.lName}, ${props.patient.fName}`}</Td>
      <Td>{`${props.clinician.lastName}, ${props.clinician.firstName}`}</Td>
      <Td>{props.data.type}</Td>
      <Td>{props.data.timestamp}</Td>
      <Td>
        <Badge
          onClick={() => {
            downloadFile(
              props.state,
              `${props.data.pid}/Requisitions/`,
              props.data.fileName,
              toast
            );
          }}
          fontSize="12px"
          rounded="md"
          p="1"
          px="2"
          ml="2"
          colorScheme="orange"
          cursor={"pointer"}
        >
          <Icon as={FiDownload} fontSize="15px" mt="-0.5" /> Download
        </Badge>
      </Td>
    </Tr>
  );
}

export default function PatientRequisitionTable(props) {
  if (props.loading) {
    return <Loader />;
  }
  const drMap = {};
  if (props.dashState.doctor) {
    drMap[props.dashState.doctor.did] = props.dashState.doctor;
  } else {
    for (const doc of props.dashState.doctors) {
      drMap[doc.did] = doc;
    }
  }

  return (
    <>
      <Box gap="8" w="100%" my="6">
        <TableContainer w="full" borderRadius="md">
          <Table variant="simple" w="full">
            <Thead bg="cyan.100">
              <Tr>
                <Th>Patient name</Th>
                <Th>Clinician name</Th>
                <Th>Requisition</Th>
                <Th>Created at</Th>
                <Th>File</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.data?.length ? (
                props.data.map((patientReq) => {
                  if (!drMap[patientReq.did]) return null;
                  return (
                    <PatientRequisitionRow
                      state={props.dashState}
                      data={patientReq}
                      patient={props.patient}
                      clinician={drMap[patientReq.did]}
                    />
                  );
                })
              ) : (
                <Text>There are no requistions for this patient.</Text>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
