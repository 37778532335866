import React, {useState} from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  HStack,
  Select,
  Spinner,
  Center,
  Text,
} from "@chakra-ui/react";
import {ChevronUpIcon, ChevronDownIcon} from "@chakra-ui/icons";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
export const flattenData = (
  data,
  parentKey = "",
  depth = 0,
  maxDepth = Infinity
) => {
  let rows = [];

  if (depth >= maxDepth) {
    return rows;
  }

  for (const key in data) {
    const item = data[key];
    const formattedKey = !key || key === "undefined" ? "N/A" : key;

    const groupRow = {
      [`GroupKey`]: parentKey ? `${parentKey} > ${formattedKey}` : formattedKey,
      isGroupRow: true,
    };

    rows.push(groupRow);

    if (Array.isArray(item)) {
      rows.push(
        ...item.map((el) => ({
          [`GroupKey`]: groupRow[`GroupKey`],
          ...el,
          isGroupRow: false,
        }))
      );
    } else {
      const subRows = flattenData(
        item,
        groupRow[`GroupKey`],
        depth + 1,
        maxDepth
      );
      rows.push(...subRows);
    }
  }

  return rows.filter((row) => row.isGroupRow || row._id);
};

const GroupedTable = ({data, groupBy = [], visibleFields}) => {
  const flattenedData = flattenData(data, "", 0, groupBy.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const paginatedData = flattenedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(flattenedData.length / itemsPerPage);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead backgroundColor="gray.100">
          <Tr>
            <Th>Group</Th>
            {visibleFields.map((field) => (
              <Th key={field.name}>{field.label}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {paginatedData.map((item, index) =>
            item.isGroupRow ? (
              <Tr key={index} backgroundColor="gray.50">
                <Td colSpan={visibleFields.length + 1} fontWeight="bold">
                  {item.GroupKey}
                </Td>
              </Tr>
            ) : (
              <Tr key={index}>
                <Td></Td>
                {visibleFields.map((field) => {
                  function getNestedValue(obj, path) {
                    return path
                      .split(".")
                      .reduce((acc, part) => acc && acc[part], obj);
                  }

                  function formatValue(value) {
                    if (value instanceof Date) {
                      return value.toLocaleDateString();
                    }
                    if (value === true) {
                      return "True";
                    }
                    if (value === false) {
                      return "False";
                    }
                    return value;
                  }

                  const cellValue = getNestedValue(item, field.name);
                  return <Td key={field.name}>{formatValue(cellValue)}</Td>;
                })}
              </Tr>
            )
          )}
        </Tbody>
      </Table>
      <HStack justify="space-between" mt={4}>
        <HStack>
          <IconButton
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            icon={<ChevronLeftIcon />}
            isDisabled={currentPage === 1}
          />
          <Box>
            Page {currentPage} of {totalPages}
          </Box>
          <IconButton
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            icon={<ChevronRightIcon />}
            isDisabled={currentPage === totalPages}
          />
        </HStack>
        <HStack>
          <Box>Items per page:</Box>
          <Select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            width="auto"
          >
            {[10, 20, 30, 40, 50].map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </Select>
        </HStack>
      </HStack>
    </TableContainer>
  );
};

export const DynamicReportTable = ({
  data,
  collection,
  activeColumns,
  groupBy,
  loading,
}) => {
  const [sortConfig, setSortConfig] = useState({key: null, direction: ""});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({key, direction});
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <ChevronUpIcon ml={1} />
      ) : (
        <ChevronDownIcon ml={1} />
      );
    }
    return null;
  };

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      return [...(data.filteredData || [])].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return data?.filteredData || [];
  }, [data?.filteredData, sortConfig]);

  if (loading) {
    return (
      <Box width="100%" height="180px">
        <Center height="100%">
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#827FFF"
            size="lg"
          />
        </Center>
      </Box>
    );
  }
  if (!data) {
    return <Text>Apply an existing report or create a new one</Text>;
  }
  const visibleFields = collection.fields.filter((field) => {
    return activeColumns[field.name] === true;
  });
  if (data.groupedData && Object.keys(data.groupedData).length) {
    return (
      <GroupedTable
        data={data.groupedData}
        groupBy={groupBy}
        visibleFields={visibleFields}
      />
    );
  }
  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(data.filteredData.length / itemsPerPage);
  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <Thead backgroundColor="gray.100">
            <Tr>
              {visibleFields.map((field) => (
                <Th key={field.name} onClick={() => handleSort(field.name)}>
                  {field.label}
                  {renderSortIcon(field.name)}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData.map((item, index) => (
              <Tr key={index}>
                {visibleFields.map((field) => {
                  function getNestedValue(obj, path) {
                    return path
                      .split(".")
                      .reduce((acc, part) => acc && acc[part], obj);
                  }

                  function formatValue(value) {
                    if (value instanceof Date) {
                      return value.toLocaleDateString();
                    }
                    if (value === true) {
                      return "True";
                    }
                    if (value === false) {
                      return "False";
                    }
                    return value;
                  }

                  const cellValue = getNestedValue(item, field.name);
                  return <Td key={field.name}>{formatValue(cellValue)}</Td>;
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <HStack justify="space-between" mt={4}>
        <HStack>
          <IconButton
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            icon={<ChevronLeftIcon />}
            isDisabled={currentPage === 1}
          />
          <Box>
            Page {currentPage} of {totalPages}
          </Box>
          <IconButton
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            icon={<ChevronRightIcon />}
            isDisabled={currentPage === totalPages}
          />
        </HStack>
        <HStack>
          <Box>Items per page:</Box>
          <Select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            width="auto"
          >
            {[10, 20, 30, 40, 50].map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </Select>
        </HStack>
      </HStack>
    </Box>
  );
};
