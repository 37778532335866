export const getTaskDate = (timestamp) => {
  if (!timestamp) return ""

  const date = new Date(timestamp)
  const [month, day, year] = [
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate() + 1).padStart(2, '0'),
    date.getFullYear()
  ]

  return `${month}-${day}-${year}`
}
