import React, {useMemo, useState, useEffect, useRef} from "react";
import {useSearchParams} from "react-router-dom";
import {
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TableCaption,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Icon,
  Badge,
  useToast,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import {PhoneIcon, EmailIcon} from "@chakra-ui/icons";
import renderTextByAccess from "../utils/renderTextByAccess";
import verifyAccess from "../utils/verifyAccess";
import renderBlurredText from "../utils/renderBlurredText";
import {DashStateContext} from "../pages/Patients";
import {patientsFilter} from "./Patients/helpers/patientsFilter";
import {getLastVisit} from "./Patients/helpers/getLastVisit";
import {getNextVisit} from "./Patients/helpers/getNextVisit";
import {fetchPatient} from "./Patients/helpers/fetchPatient";
import PaginationBar from "./Patients/PaginationBar";
import {getAge} from "./Patients/helpers/getAge";
import AddPatient from "./Patients/AddPatient";

export default function FaxPatientsTable({
  setPatient,
  dashState,
  selectedPid,
  dispatch,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const toast = useToast();

  const [searching, setSearching] = useState(false);
  const [patients, setPatients] = useState(dashState.patients);
  const searchTextRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [reset, setReset] = useState({});
  const reloadingDataRef = useRef(null);
  let appointments = dashState?.appointments;

  reloadingDataRef.current = dashState?.changingLocation;

  useEffect(() => {
    if (!reloadingDataRef.current) {
      setSearching(true);
      let request = () => {
        patientsFilter({
          searchText,
          limit: 30,
          skip,
          patients: dashState.patients.filter((patient) => !patient.deleted),
          selectedDoctor: dashState.selectedDoctor,
        })
          .then((pts) => {
            //remove deleted patients here

            if (Math.ceil(pts.count / 30) <= skip) {
              setSkip(0);
              setReset({});
            }
            setSearching(false);
            setPatients(pts.data);
            setCount(pts.count);
          })
          .catch((e) => {
            setSearching(false);
            console.log(e);
          });
      };

      let id = setTimeout(() => {
        request();
      }, 1000);

      return () => {
        clearTimeout(id);
      };
    }
  }, [
    dashState.selectedDoctor?.did,
    dashState.selectedDoctor,
    searchText,
    skip,
    dashState.patients,
    dashState.changingLocation,
  ]);

  useEffect(() => {
    searchParams.get("query") &&
      fetchPatient(dashState, searchParams.get("query"), setPatient, toast);
  }, []);

  const memoedPatientBlurredData = useMemo(() => {
    const map = {};
    patients.forEach((pt) => {
      map[pt.pid] = {
        fName: renderTextByAccess(dashState, pt, "fName"),
        lName: renderTextByAccess(dashState, pt, "lName"),
        age: renderTextByAccess(dashState, pt, "age"),
        email: renderTextByAccess(dashState, pt, "email"),
      };
    });
    return map;
  }, [patients]);

  return (
    <>
      <DashStateContext.Provider value={dashState}>
        <TableContainer
          maxW="8xl"
          border="1px solid"
          borderColor="gray.50"
          borderRadius="md"
          boxShadow="md"
        >
          <Flex p="4" gap="8">
            <AddPatient dispatch={dispatch} setPatient={setPatient} />
            <InputGroup>
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" />
              </InputLeftElement>
              <Input
                type="text"
                ml="-1"
                placeholder="Search patients"
                ref={searchTextRef}
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              />
            </InputGroup>
          </Flex>

          <Table size="md">
            <Thead bg="gray.100">
              <Tr>
                <Th fontSize={"11px"}>Name</Th>
                <Th fontSize={"11px"}>Status</Th>
                <Th fontSize={"11px"}>Last Visit</Th>
                <Th fontSize={"11px"}>Next Visit</Th>
                <Th fontSize={"11px"}>Third Party Payer</Th>
                <Th fontSize={"11px"}>Contact</Th>
              </Tr>
            </Thead>
            <Tbody>
              {patients.map((patient, i) => {
                return (
                  <Tr key={i} bg={patient?.pid === selectedPid && "lightBlue"}>
                    <Td
                      maxW="250px"
                      fontSize={"15px"}
                      cursor={"pointer"}
                      onClick={() =>
                        fetchPatient(dashState, patient.pid, setPatient, toast)
                      }
                    >
                      <Text fontWeight={"semibold"}>
                        {memoedPatientBlurredData[patient.pid]?.["lName"]}
                        {", "}
                        {memoedPatientBlurredData[patient.pid]?.["fName"]}
                      </Text>
                      <Text fontSize={"11px"}>
                        {verifyAccess(dashState, "dob")
                          ? patient.dob === ""
                            ? "unknown "
                            : getAge(patient.dob) + " y/o "
                          : renderBlurredText("00 y/o ", true)}{" "}
                        {patient.gender}
                      </Text>
                    </Td>

                    <Td fontSize={"15px"}>
                      {patient?.deleted ? (
                        <Badge colorScheme="black" rounded="md" px="2" py="0.5">
                          Recently Deleted
                        </Badge>
                      ) : patient?.deceased ? (
                        <Badge colorScheme="red" rounded="md" px="2" py="0.5">
                          Deceased
                        </Badge>
                      ) : patient?.inactive ? (
                        <Badge
                          colorScheme="orange"
                          rounded="md"
                          px="2"
                          py="0.5"
                        >
                          Inactive
                        </Badge>
                      ) : (
                        <Badge colorScheme="blue" rounded="md" px="2" py="0.5">
                          Active
                        </Badge>
                      )}
                    </Td>
                    <Td fontSize={"15px"}>
                      {getLastVisit(patient, appointments).component}
                    </Td>
                    <Td fontSize={"15px"}>
                      {getNextVisit(patient, appointments).component}
                    </Td>
                    <Td fontSize={"15px"}>
                      {Array.isArray(patient?.tpp)
                        ? patient.tpp
                            .map((payer) =>
                              dashState.insurers.find(
                                (insurer) => insurer.iid === payer?.insurerId
                              )
                            )
                            .filter((insurer) => insurer !== undefined)
                            .map((insurer) => insurer.name)
                            .join(", ")
                        : "N/A"}
                    </Td>
                    <Td fontSize={"15px"}>
                      <Icon
                        aria-label="phone"
                        as={PhoneIcon}
                        mx="2"
                        color="gray.600"
                        cursor={"pointer"}
                        fontSize={"18px"}
                        onClick={() =>
                          verifyAccess(dashState, "phone")
                            ? (window.location.href = `tel:` + patient["phone"])
                            : null
                        }
                      />
                      <Icon
                        aria-label="email"
                        mx="2"
                        color="gray.600"
                        cursor={"pointer"}
                        fontSize={"18px"}
                        as={EmailIcon}
                        onClick={() =>
                          verifyAccess(dashState, "email")
                            ? (window.location.href =
                                `mailto:` + patient["email"])
                            : null
                        }
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            {searching && <TableCaption>Loading patients...</TableCaption>}
            {patients.length == 0 && !searching && (
              <TableCaption>No patients to display.</TableCaption>
            )}
          </Table>

          <PaginationBar {...{skip, setSkip, count, searching, reset}} />
        </TableContainer>
      </DashStateContext.Provider>
    </>
  );
}
