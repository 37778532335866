import React, {useEffect, useState} from "react";
import moment from "moment";
import {crud} from "../../../crudRequests";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

function transformData(inputData) {
  const events = [];

  inputData.forEach((entry, index) => {
    if (entry.complianceEntries && entry.complianceEntries.length > 0) {
      entry.complianceEntries.forEach((complianceEntry, subIndex) => {
        const taken = complianceEntry.taken || complianceEntry.taken === "yes";
        const color = taken ? "#1ccb9e" : "#fd3153";

        let doo = new Date(entry.date);
        doo = new Date(
          doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
        );

        const event = {
          id: events.length + 1,
          color: color,
          start: doo,
          title: `${complianceEntry.drugName}`,
          reasoning: complianceEntry?.reasoning || "",
          taken: taken,
        };
        events.push(event);
      });
    }
  });

  return events;
}

function transformPrescriptionData(prescriptions, date) {
  const events = [];
  // console.log({length: prescriptions.length});
  if (prescriptions && prescriptions?.length > 0) {
    prescriptions?.forEach((entry, index) => {
      const event = {
        id: events.length + 1,
        color: "blue.500",
        start: date,
        title: `${entry?.drugName}`,
        reasoning: null,
        taken: false,
      };
      events.push(event);
    });
    return events;
  }
  return [];
}

const MonthEventDisplayer = ({events, prescriptions}) => {
  const [currentMonth, setCurrentMonth] = useState(moment());

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, "month"));
  };

  const getMonthEvents = () => {
    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");

    return events.filter((event) => {
      const eventDate = moment(event.start);
      return eventDate.isBetween(startOfMonth, endOfMonth, null, "[]");
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const onClose = () => setIsOpen(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
  };

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading>{currentMonth.format("MMMM YYYY")}</Heading>
        <Box>
          <Button rounded="full" p={0} onClick={handlePrevMonth} mr={2}>
            <ChevronLeftIcon />
          </Button>
          <Button rounded="full" p={0} onClick={handleNextMonth}>
            <ChevronRightIcon />
          </Button>
        </Box>
      </Flex>
      <SimpleGrid columns={7} borderLeft="1px" pl={0} borderColor="#e5e7eb">
        {Array.from({length: currentMonth.daysInMonth()}).map((_, index) => {
          const currentDate = currentMonth.clone().date(index + 1);
          const dayEvents = getMonthEvents().filter((event) =>
            moment(event.start).isSame(currentDate, "day")
          );

          let possiblePrescriptions = [];
          if (moment().isSameOrAfter(currentDate)) {
            possiblePrescriptions = prescriptions?.filter((prescription) => {
              // Parse start_date and calculate end_date
              if (!prescription?.start_date || !prescription?.length_days) {
                return false;
              }
              const startDate = moment(prescription?.start_date);
              const endDate = startDate
                .clone()
                .add(prescription?.length_days, "days");

              // Check if targetDate is within the date range
              return moment(currentDate).isBetween(
                startDate,
                endDate,
                null,
                "()"
              );
            });
          }

          let active =
            dayEvents?.length > 0
              ? dayEvents
              : transformPrescriptionData(possiblePrescriptions, currentDate);
          // console.log({active});

          //  console.log({possiblePrescriptions});

          return (
            <Box
              key={index}
              p={2}
              borderWidth="1px"
              h={175}
              position="relative"
              bg={dayEvents?.length === 0 && "rgba(0,0,0,0.1)"}
            >
              <Text textAlign="center" size="xs" mb={2}>
                {currentDate.format("D")}
              </Text>
              <Popover>
                {
                  <PopoverTrigger>
                    <Box
                      // overflowY="scroll"
                      disabled={active?.length - 3 > 0}
                      cursor="pointer"
                      // maxH="100px"
                      position="relative"
                      _after={{
                        content: `"${
                          active?.length > 3
                            ? `... ${active?.length - 3} more`
                            : ""
                        }"`,
                        display: "block",
                        position: "absolute",
                        bottom: "0",
                        left: "50%",
                        transform: "translate(-50%, 125%)",
                        color: "black",
                        p: 1,
                        rounded: "md",
                        fontSize: "x-small",
                      }}
                    >
                      <Flex flexDirection="column" alignItems="center">
                        {active?.slice(0, 3).map((event, eventIndex) => (
                          <Box
                            p={2}
                            py={1}
                            rounded="md"
                            bg={event?.color}
                            key={eventIndex}
                            mb={2}
                            w={100}
                            zIndex={1}
                            cursor="pointer"
                            onClick={() =>
                              !event?.taken &&
                              dayEvents.length > 0 &&
                              handleEventClick(event)
                            }
                          >
                            <Text
                              color="white"
                              fontSize="x-small"
                              noOfLines={1}
                            >
                              {event.title}
                            </Text>
                          </Box>
                        ))}
                      </Flex>
                    </Box>
                  </PopoverTrigger>
                }
                <PopoverContent zIndex={2}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody mt={5}>
                    {active?.map((event, eventIndex) => (
                      <Box
                        key={eventIndex}
                        p={2}
                        bg={event?.color}
                        mb={2}
                        rounded="md"
                        cursor="pointer"
                        onClick={() =>
                          !event?.taken &&
                          dayEvents.length > 0 &&
                          handleEventClick(event)
                        }
                      >
                        <Text color="white" fontSize="x-small">
                          {event.title}
                        </Text>
                      </Box>
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          );
        })}
      </SimpleGrid>
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {selectedEvent?.title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {selectedEvent?.reasoning && (
                <>
                  <Text fontWeight="bold">Reasoning:</Text>
                  <Text>{selectedEvent.reasoning}</Text>
                </>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>Close</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

const ComplianceCal = ({patient, state}) => {
  // load the prescription compliance

  const [data, setData] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);

  const toast = useToast();

  useEffect(() => {
    crud(state, [
      {
        db: state.db,
        collection: "prescriptions",
        parameters: [{pid: patient?.pid}],
        method: "find",
      },
    ]).then((res) => setPrescriptions(res?.data?.[0]));
    crud(state, [
      {
        db: state.db,
        collection: "prescription_compliance",
        parameters: [{pid: patient?.pid}],
        method: "find",
      },
    ]).then((res) => setData(transformData(res?.data?.[0])));
  }, [patient, state]);

  return (
    <Box>
      <MonthEventDisplayer prescriptions={prescriptions || []} events={data} />
    </Box>
  );
};

export default ComplianceCal;
