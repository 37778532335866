import {crud} from "../../../../crudRequests";
import expireManyPrescriptions from "./expireManyPrescriptions";
import isDatePassed from "./isDatePassed";

export async function fetchAndExpire(
  setPrescriptions,
  dashState,
  patient,
  toast,
  expireManyPrescriptions
) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "prescriptions",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);
    const expired =
      res?.data[0]?.filter(
        (item) =>
          !item?.expired &&
          item?.autoexpire &&
          item?.start_date &&
          isDatePassed(item?.start_date, item?.length_days)
      ) || [];
    if (expired.length > 0) {
      expireManyPrescriptions(expired);
    }
    setPrescriptions(res?.data[0]?.reverse() || []);
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
}
