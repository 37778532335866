import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Divider,
  Text,
  Stack,
  InputGroup,
  InputLeftElement,
  Table,
  Tr,
  Td,
  Thead,
  Th,
  Select,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  Tbody,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {AddIcon} from "@chakra-ui/icons";
import {useState} from "react";
import {SearchIcon} from "@chakra-ui/icons";
import {crud} from "../../crudRequests";
import {v4} from "uuid";

const format = (val) => `$` + val;
const parse = (val) => val.replace(/^\$/, "");
export function MembershipForm(props) {
  const [sortAndFilteredServices, setSortAndFilteredServices] = useState(
    props.schState.services
  );

  React.useEffect(() => {
    const serviceList = [...props.schState.services];
    setSortAndFilteredServices(
      serviceList
        .filter((svc) => {
          if (
            !props.formValues.nonSessionServicesFilter &&
            !props.formValues.sessionServicesFilter
          ) {
            return false;
          }
          if (
            props.formValues.nonSessionServicesFilter === true &&
            !props.formValues.sessionServicesFilter
          ) {
            return svc["non-sessions"];
          }
          if (
            props.formValues.sessionServicesFilter === true &&
            !props.formValues.nonSessionServicesFilter
          ) {
            return !svc["non-sessions"];
          }
          if (
            props.formValues.nonSessionServicesFilter &&
            props.formValues.sessionServicesFilter
          ) {
            return true;
          }
          return false;
        })
        .filter((svc) => {
          if (
            svc.serviceName
              .toLowerCase()
              .includes(props.formValues.searchValue.toLowerCase())
          ) {
            return true;
          }
          if (
            svc.description
              .toLowerCase()
              .includes(props.formValues.searchValue.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
    );
  }, [
    props.formValues.searchValue,
    props.formValues.sessionServicesFilter,
    props.formValues.nonSessionServicesFilter,
  ]);
  return (
    <Stack
      direction="column"
      spacing={4}
      alignItems="center"
      h="68vh"
      overflow="scroll"
    >
      <Stack boxShadow="xl" p="1rem">
        <Stack direction="row" spacing={4} w="90%" mt="1rem">
          <Input
            placeholder="Membership name"
            value={props.formValues.name}
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                name: evt.target.value.replace(/[\\/:*?"<>|]/g, ""),
              })
            }
          />
          <Text mb="8px">Price</Text>
          <NumberInput
            onChange={(valueString) =>
              props.setFormValues({
                ...props.formValues,
                price: parse(valueString),
              })
            }
            value={format(props.formValues.price)}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Checkbox
            isChecked={props.formValues.active}
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                active: evt.target.checked,
              })
            }
          >
            Active
          </Checkbox>
        </Stack>
        <Input
          placeholder="Description"
          w="70%"
          value={props.formValues.description}
          onChange={(evt) =>
            props.setFormValues({
              ...props.formValues,
              description: evt.target.value,
            })
          }
        />
        <Stack direction="row" spacing={4}>
          <Text>Membership duration</Text>
          <NumberInput
            w="50%"
            min={0}
            value={props.formValues.duration}
            onChange={(evt) => {
              props.setFormValues({...props.formValues, duration: evt});
            }}
          >
            <NumberInputField />
            <NumberInputStepper padding="5px">
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            placeholder="Select option"
            value={props.formValues.durationPeriod}
            onChange={(evt) => {
              props.setFormValues({
                ...props.formValues,
                durationPeriod: evt.target.value,
              });
            }}
          >
            <option value="monthly">Month(s)</option>
            <option value="yearly">Year(s)</option>
          </Select>
        </Stack>
        <Stack direction="row" spacing={4} w="70%">
          <InputGroup w="50%">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              value={props.formValues.searchValue}
              onChange={(evt) =>
                props.setFormValues({
                  ...props.formValues,
                  searchValue: evt.target.value,
                })
              }
              placeholder="Search services"
            />
          </InputGroup>
          <Checkbox
            size="sm"
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                sessionServicesFilter: evt.target.checked,
              })
            }
            isChecked={props.formValues.sessionServicesFilter}
          />
          <Text mb="8px">Session services</Text>
          <Checkbox
            size="sm"
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                nonSessionServicesFilter: evt.target.checked,
              })
            }
            isChecked={props.formValues.nonSessionServicesFilter}
          />
          <Text mb="8px">Non-session services</Text>
        </Stack>
      </Stack>
      <Stack boxShadow="xl" m="1rem">
        <Table>
          <Thead bg="gray.100">
            <Th>Selection</Th>
            <Th>Service</Th>
            <Th>Description</Th>
            <Th>Category</Th>
            <Th>Quantity</Th>
          </Thead>
          <Tbody overflow="scroll">
            {sortAndFilteredServices.map((svc) => {
              return (
                <Tr key={svc.serviceId}>
                  <Td>
                    <Checkbox
                      isChecked={props.selectedServices[svc.serviceId]?.checked}
                      onChange={(evt) =>
                        props.setSelectedServices({
                          ...props.selectedServices,
                          [svc.serviceId]: {
                            checked: evt.target.checked,
                            quantity:
                              evt.target.checked === false
                                ? 0
                                : parseInt(
                                    props.selectedServices[svc.serviceId]
                                      ?.quantity
                                  ) || 1,
                          },
                        })
                      }
                    />
                  </Td>
                  <Td>{svc.serviceName}</Td>
                  <Td>{svc.description}</Td>
                  <Td>{svc.category}</Td>
                  <Td>
                    <NumberInput
                      value={
                        props.selectedServices[svc.serviceId]?.quantity || 0
                      }
                      onChange={(evt) => {
                        props.setSelectedServices({
                          ...props.selectedServices,
                          [svc.serviceId]: {
                            ...props.selectedServices[svc.serviceId],
                            quantity: parseInt(evt),
                          },
                        });
                      }}
                      min={1}
                      disabled={!props.selectedServices[svc.serviceId]?.checked}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Stack>
    </Stack>
  );
}
export function MembershipsCreateModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const [selectedServices, setSelectedServices] = useState({});
  const initialFormState = {
    duration: 6,
    durationPeriod: "monthly",
    searchValue: "",
    description: "",
    name: "",
    price: 0,
    sessionServicesFilter: true,
    nonSessionServicesFilter: true,
    active: true,
  };
  const [formValues, setFormValues] = useState(initialFormState);
  return (
    <>
      <Button
        colorScheme="blue"
        leftIcon={<AddIcon />}
        onClick={onOpen}
        p="1rem"
        w="auto"
      >
        Create
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">Create a new membership type</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <MembershipForm
              schState={props.schState}
              formValues={formValues}
              setFormValues={setFormValues}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="ghost"
              onClick={async () => {
                const membershipData = {
                  name: formValues.name,
                  description: formValues.description,
                  price: formValues.price,
                  status: formValues.active ? "Active" : "Inactive",
                  duration: formValues.duration,
                  durationPeriod: formValues.durationPeriod,
                  selectedServices,
                  mid: v4(),
                };
                await crud(
                  props.schState,
                  [
                    {
                      db: props.schState.db,
                      collection: "locations",
                      parameters: [
                        {lid: props.selectedLocation.lid},
                        {
                          $push: {
                            memberships: membershipData,
                          },
                        },
                      ],
                      method: "updateOne",
                    },
                  ],
                  null
                );
                let {locations} = props.schState;
                let locIndex = props.schState.locations.findIndex(
                  (d) => d.lid === props.selectedLocation.lid
                );
                const updatedMemberships = [
                  ...locations[locIndex].memberships,
                  membershipData,
                ];
                await props.dispatch({
                  type: "UPDATE_LOCATION",
                  payload: {
                    lid: props.selectedLocation.lid,
                    memberships: updatedMemberships,
                  },
                });
                setFormValues({...initialFormState});
                setSelectedServices({});
                toast({
                  title: "Membership created!",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                onClose();
              }}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
