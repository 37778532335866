import {useState, useContext} from "react";
import {Box, Flex, Icon, Input} from "@chakra-ui/react";
import {IoMdSend} from "react-icons/io";
import {useChatSocket} from "./helpers/useChatSocket";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {url} from "../../../crudRequests";
export default function SendMessage({dispatch, rooms, selectedChat, sendRef}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const socket = useChatSocket(
    //"http://localhost:3000/chat",
    url + "/chat",
    rooms,
    dashState?.doctor.did,
    dispatch
  );

  const [inputValue, setInputValue] = useState("");

  return (
    <Flex
      alignItems="center"
      bg={"white"}
      borderTop={"1px"}
      borderColor={"gray.100"}
    >
      <Box w="full">
        <Input
          variant="unstyled"
          placeholder="Type Message"
          p="5"
          onChange={(e) => {
            setInputValue(e.target.value);
            socket.current.emit("writing", {
              chatId: selectedChat.conversationId,
              userId: dashState?.doctor.did,
            });
          }}
          value={inputValue}
        />
      </Box>
      <Box
        ref={sendRef}
        onClick={() => {
          if (inputValue.trim()) {
            let newMessage = {
              db: dashState.db,
              conversationId: selectedChat.conversationId,
              senderId: dashState?.doctor.did,
              messageType: "text",
              message: inputValue.trim(),
              timestamp: new Date().toISOString(),
            };
            socket.current.emit("message", newMessage);
            setInputValue("");
            dispatch({
              type: "NEW_MESSAGE",
              payload: newMessage,
            });
          }
        }}
      >
        <Icon
          as={IoMdSend}
          mr="4"
          fontSize="xl"
          cursor={inputValue != "" ? "pointer" : ""}
          color={inputValue != "" ? "black" : "gray.300"}
        />
      </Box>
    </Flex>
  );
}
