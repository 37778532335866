import {crud} from "../../../crudRequests";

export async function fetchPayments(
  state,
  skip,
  limit,
  setPayments,
  setIsLoading,
  toast,
  patient
) {
  setIsLoading(true);
  let matchQuery = {lid: state.selectedLocation};
  if (patient) matchQuery.pid = patient.pid;
  if (state.userType === "doctor") matchQuery.did = state.doctor?.did;
  console.log(matchQuery);

  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "payments",
        parameters: [matchQuery],
        method: "find",
        findCursor: [
          {method: "skip", arguments: [skip]},
          {method: "limit", arguments: [limit]},
        ],
      },
    ]);

    if (skip && limit !== null) {
      setPayments((o) => [...o, ...res.data[0]]);
    } else {
      setPayments(res.data[0]);
    }
    console.log(res.data, "payments");
    setIsLoading(false);
  } catch (err) {
    console.log("error", err);
    toast({
      title: "Failed to fetch payments data",
      description:
        err?.message || "An error occurred while fetching payments data.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
  }
}
