import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import {useContext} from "react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {updatePatientStatus} from "./helpers/updatePatientStatus";

export default function DeceasedModal({deceasedModal, setDeceasedModal}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();

  return (
    <Modal
      isOpen={deceasedModal}
      onClose={() => setDeceasedModal(null)}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Patient Deceased</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          I confirm to the best of my knowledge that this patient is deceased.
          <br />
          <br />
          <b>This action cannot be undone.</b>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            variant="outline"
            mr="3"
            onClick={() => {
              setDeceasedModal(null);
            }}
          >
            Go Back
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              setDeceasedModal(null);
              setPatient({...patient, deceased: true});
              updatePatientStatus(
                patient,
                dashState,
                patient?.inactive,
                true,
                patient?.deleted,
                toast
              );
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
