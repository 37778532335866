import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import Dropzone from "./Dropzone";
import {uploadFile} from "./helpers/uploadFile";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext} from "react";

export default function UploadFile({
  addFile,
  setAddFile,
  path,
  setFiles,
  setSelectedFile,
  uploadLoader,
  setUploadLoader,
  selectedFileUpload,
  setSelectedFileUpload,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const toast = useToast();

  return (
    <Modal
      isOpen={addFile}
      onClose={() => {
        setSelectedFileUpload("");
        setAddFile(!addFile);
        setUploadLoader(false);
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload file</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Dropzone
            selectedFileUpload={selectedFileUpload}
            setSelectedFileUpload={setSelectedFileUpload}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={uploadLoader}
            onClick={() =>
              !uploadLoader &&
              selectedFileUpload &&
              uploadFile(
                dashState,
                path,
                selectedFileUpload,
                setUploadLoader,
                setSelectedFileUpload,
                setFiles,
                setSelectedFile,
                setAddFile,
                toast
              )
            }
            colorScheme="blue"
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
