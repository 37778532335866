import axios from "axios";
import {url} from "../../../../crudRequests";

export async function deletePharm(state, phid) {
  let configService = {
    method: "GET",
    url: `${url}/deletePharmacy?phid=${phid}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}

export default async function deletePharmacy(dashState, phId, toast, onDelete) {
  try {
    const res = await deletePharm(dashState, phId);
    if (res.status === 200) {
      toast({
        title: "Pharmacy deleted successfully",
        description: "Pharmacy deleted successfully",
        status: "success",
        isClosable: true,
      });
      await onDelete();
    }
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
}
