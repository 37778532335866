import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  Text,
  Button,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
export function compensationRate(e) {
  let comp = e?.doctor?.compensationRate || {};

  let serviceId = e?.serviceId || "";
  if (typeof comp === "object") {
    comp = comp[serviceId] || 0;
  } else {
    comp = 0;
  }

  comp = !isNaN(comp) ? Number(comp) : 0;

  return {
    uiElements: (
      <Badge colorScheme="gray" rounded="md" px="2" py="0.5">
        {`$${parseFloat(((e.amount || 0) * comp) / 100).toFixed(2)} (${comp}%)`}
      </Badge>
    ),
    uiValue: String(comp),
    exportTableEntries: [
      ["Clinician Compensation Rate"],
      [
        {
          type: "number",
          value: comp,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
      ],
    ],
  };
}
