import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

export default function DescriptionModal({description, setDescription}) {
  return (
    <Modal
      isOpen={description}
      onClose={() => setDescription(null)}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Non-Sessions Service Description</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{description}</ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
