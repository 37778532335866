import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect, useMemo} from "react";
import {
  Box,
  Text,
  Icon,
  Button,
  Badge,
  useToast,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Flex,
  GridItem,
  Grid,
  VStack,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";
import Message from "./Message";
import {getAllSymptoms} from "./helpers/symptoms";
import {fetchSymptoms} from "./helpers/fetchSymptoms";

export default function JournalList({state, journals}) {
  const [journalEntries, setJournal] = useState(null);

  const allJournals = useMemo(() => journals?.reverse() || [], [journals]);

  const [symptoms, setSymptoms] = useState([]);
  const toast = useToast();

  useEffect(() => {
    if (journalEntries && journalEntries.length > 0) {
      const symptomIds = getAllSymptoms({journalEntries: journalEntries});
      if (symptomIds.length > 0) {
        fetchSymptoms(setSymptoms, state, toast, symptomIds);
      }
    }
  }, [journalEntries]);

  useEffect(() => {
    if (allJournals?.length > 0) {
      setJournal(allJournals[0]?.journalEntries || []);
    }
  }, [journals]);
  return (
    <Grid templateColumns="auto 1fr" maxH="500px">
      <GridItem overflowY="scroll" maxH="500px">
        <Tabs
          orientation="vertical"
          textAlign={"left"}
          my="2"
          colorScheme="green"
        >
          <TabList>
            <VStack align="stretch">
              {allJournals?.map((journal, i) => {
                return (
                  <Tab
                    sx={{justifyContent: "flex-start"}}
                    onClick={() => {
                      setJournal(journal?.journalEntries || []);
                    }}
                  >
                    {journal.date}
                  </Tab>
                );
              })}
            </VStack>
          </TabList>
        </Tabs>
      </GridItem>
      <GridItem
        padding={4}
        marginLeft={4}
        maxW="800px"
        overflowY="scroll"
        maxH="500px"
      >
        {journalEntries?.length > 0 ? (
          journalEntries?.map((journal) => (
            <Message message={journal} symptoms={symptoms} />
          ))
        ) : (
          <Text>No Journal entries found</Text>
        )}
      </GridItem>
    </Grid>
  );
}
