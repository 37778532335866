import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Icon,
  IconButton,
  Center,
} from "@chakra-ui/react";
import {BsPlusCircleDotted} from "react-icons/bs";
import {AiFillDelete} from "react-icons/ai";

export default function UnlimitedItemFields({label, items, setItems, fields}) {
  const handleOnChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };
  const handleRemove = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };
  return (
    <FormControl id={label} isDisabled={!items} mt="5">
      <FormLabel textAlign={"center"}>{label}</FormLabel>
      {items &&
        items.map((item, index) => (
          <Box display="flex" key={index}>
            <Box mr={5}>
              <Box
                gap={2}
                display={{base: "block", sm: "flex"}}
                textAlign={"center"}
              >
                {fields.map(({name, placeholder, type}) => (
                  <Input
                    key={name}
                    variant="flushed"
                    type={type}
                    placeholder={placeholder}
                    value={item[name]}
                    w={"155px"}
                    onChange={(e) =>
                      handleOnChange(index, name, e.target.value)
                    }
                  />
                ))}
              </Box>
              <Input
                key="note"
                placeholder="Note"
                variant="flushed"
                value={item.note}
                onChange={(e) => handleOnChange(index, "note", e.target.value)}
              />
            </Box>
            <Center>
              <IconButton
                icon={<Icon as={AiFillDelete} />}
                aria-label="Remove Entry"
                onClick={() => handleRemove(index)}
                size="xs"
              />
            </Center>
          </Box>
        ))}
      <Icon
        cursor="pointer"
        onClick={() => {
          const newItem = fields.reduce((acc, f) => {
            acc[f.name] = "";
            return acc;
          }, {});
          setItems(items ? [...items, newItem] : [newItem]);
        }}
        as={BsPlusCircleDotted}
        fontSize={"26px"}
        mt={"4"}
      ></Icon>
    </FormControl>
  );
}
