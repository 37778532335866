import {crud} from "../../../../crudRequests";

export async function startConversation(
  setConversation,
  dispatch,
  dashState,
  patient
) {
  const abortController = new AbortController();
  //let chatsId = (doctor.chatsId||[]).filter((id)=>patient.chatsId?.includes(id))
  let chatId = patient.pid + dashState?.doctor.did;
  let timestamp = new Date().getTime();

  let conversation = {
    conversationId: chatId,
    usersId: [patient.pid, dashState?.doctor.did],
    messages: [],
    timestamp,
    usersMeta: {
      [dashState?.doctor.did]: {
        lastCheck: timestamp,
        lastConnection: "",
        messagesStart: timestamp,
      },
    },
  };

  crud(
    dashState,
    [
      {
        db: dashState?.db,
        collection: "conversations",
        parameters: [conversation],
        method: "insertOne",
      },
      {
        db: dashState?.db,
        collection: "doctors",
        parameters: [
          {did: dashState?.doctor.did},
          {$addToSet: {chatsId: chatId}},
        ],
        method: "updateOne",
      },
      {
        db: dashState?.db,
        collection: "patients",
        parameters: [{pid: patient.pid}, {$addToSet: {chatsId: chatId}}],
        method: "updateOne",
      },
    ],
    {
      socket: {
        rooms: conversation.usersId.filter(
          (id) => id !== dashState?.doctor.did
        ),
        namespace: "/chat",
        arguments: ["new-conversation", conversation],
      },
    },
    null,
    abortController.signal
  ).then(() => {
    setConversation(conversation);
    dispatch({type: "NEW_CONVERSATION", payload: conversation});
  });
}
