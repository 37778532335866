import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {
  crud,
  loadDataForAdminLogin,
  loadDataForDoctorLogin,
} from "../crudRequests";
import Navbar from "../components/Navbar";
import InstanceBar from "../components/InstanceBar";
import {scheduleReducer, init} from "../additional_files/reducer";
import {Country, State} from "country-state-city";

import axios from "axios";
import GetFaxNumbers from "../components/GetFaxNumbers";
import {set} from "date-fns";
import FaxesList from "../components/FaxesList";
import useFindPhaxioNumber from "../hooks/useFindPhaxioNumber";

export default function Faxes(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const loadedRef = useRef(null);
  const [loading, setLoading] = useState();
  const [schState, dispatch] = useReducer(scheduleReducer, state, init);
  const sessionStart = useRef(true);

  useEffect(
    function () {
      if (!state) {
        window.history.back();
      } else verifyUser(state ? state.jwt : "", loadedRef);
    },
    [state]
  );
  const [prevState, setPrevstate] = useState(state);

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  const reloadingDataRef = useRef(null);

  function reloadState(destination, abortController, action) {
    action?.();

    if (schState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  }

  useEffect(() => {
    const abortController = new AbortController();
    if (!schState?.firstLoading) {
      reloadingDataRef.current = true;
      reloadState("../faxes", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [schState.selectedLocation]);

  useEffect(() => {
    // console.log(schState.changingLocation, 2);
    if (schState?.firstLoading)
      navigate("../faxes", {
        state: {
          ...schState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);

  let admAccessSections = schState.admin?.access?.admAccessSections || {
    billings: true,
    permissions: true,
    forms: true,
    services: true,
    clinicians: true,
    locations: true,
  };
  let docAccessSections = {
    billings: true,
    forms: true,
    services: true,
  };

  // const [faxNumber, setFaxNumber] = useState(null);
  const [loadingLoc, setLoadingLoc] = useState(false);
  const [faxNumber, location] = useFindPhaxioNumber(schState, () =>
    setLoadingLoc(false)
  );

  return (
    <div className="relative w-full h-full flex flex-col">
      <div
        className="absolute top-6 z-[30] right-12 bg-light opacity-75 py-2 px-[9px] rounded-md cursor-pointer"
        onClick={logoutUser}
      >
        <img src="/images/Doctor/login.png" alt="" className="w-6" />
      </div>

      <Navbar state={{...schState}} />
      <InstanceBar
        {...{
          dispatch,
          schState,
        }}
      />
      {schState.changingLocation && (
        <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}
      <div
        ref={loadedRef}
        style={{opacity: 0}}
        className="relative flex flex-1 ml-28   overflow-y-scroll sbar3"
      >
        {/*<FormBuilder />*/}

        {loadingLoc && (
          <p className="text-center">Loading your faxing information</p>
        )}
        {!loadingLoc && faxNumber ? (
          <FaxesList
            faxNumber={faxNumber}
            state={schState}
            dispatch={dispatch}
          />
        ) : (
          <GetFaxNumbers state={schState} />
        )}
      </div>
    </div>
  );
}
