import {useState, useRef} from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Input,
  PopoverFooter,
  Flex,
  ButtonGroup,
  useToast,
  FormControl,
  FormLabel,
  useDisclosure,
  FormErrorMessage,
  List,
  ListItem,
  ListIcon,
  Textarea
} from "@chakra-ui/react";
import {createTask} from "./helpers/createTask";
import {Field, Form, Formik} from "formik";
import {validateField} from "./helpers/validateField";
import TaskFiles from "./TaskFiles";
import {extractFileName} from "../Patients/Files/helpers/extractFileName";
import {DeleteIcon} from "@chakra-ui/icons";
import {SearchableSelect} from "../SearchableSelect";

export default function AddTask({dashState, patients, setTasks, doctors, groupedOptions, patientsArr, admins}) {
  const fields = {
    task: "",
    description: "",
    pid: "",
    assignedToId: dashState.doctor?.did ?? "",
    due: "",
  }
  const [files, setFiles] = useState([])
  const {onOpen, onClose, isOpen} = useDisclosure()
  const firstFieldRef = useRef(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [formKey, setFormKey] = useState(Date.now())
  const toast = useToast()
  const disablePatientField = files?.length > 0

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove));
  }

  return (
    <Popover 
      placement="right-start"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={firstFieldRef}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button px="12" colorScheme="blue">
          Add a new task
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader pt={4} fontWeight="bold" border="0">
            Add New Task
          </PopoverHeader>
          <PopoverCloseButton />
          <Formik
            key={formKey}
            initialValues={fields}
            onSubmit={async(values, { setSubmitting, resetForm }) => {
              values.files = files
              values.due = new Date(values.due).getTime()
              let assignedToName = admins.find(admin => admin.id === values.assignedToId)
              assignedToName = assignedToName
              ? `${assignedToName.lastName}, ${assignedToName.firstName}`
              : `${doctors[values.assignedToId]?.lastName}, ${doctors[values.assignedToId]?.firstName}`
              await createTask(
                    values,
                    dashState,
                    setTasks,
                    assignedToName,
                    `${patients[values.pid]?.lName}, ${patients[values.pid]?.fName}`,
                    toast,
              )
              setSubmitting(false)
              setFiles([])
              resetForm()
              setFormKey(Date.now())
              onClose()
            }}
          >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <PopoverBody>
                  <Field name='task' validate={(value) => validateField('Task', value)}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.task && form.touched.task} my="2">
                        <Input {...field} placeholder='Task' variant="flushed" ref={firstFieldRef}/>
                        <FormErrorMessage>{form.errors.task}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='description'>
                    {({ field, form }) => (
                      <FormControl my="2">
                        <Textarea
                          {...field}
                          variant='flushed'
                          placeholder='Description...'
                          size='sm'
                          resize='none'
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name='pid' validate={(value) => validateField('Patient', value, setIsDisabled)}>
                    {({ field, form }) => (
                      <SearchableSelect
                        label="Select patient"
                        placeholder="-"
                        options={patientsArr.map((option) => ({
                          value: option.pid,
                          label: `${option.lName}, ${option.fName}`,
                        }))}
                        isDisabled={disablePatientField}
                        error={form.errors.pid}
                        touched={form.touched.pid}
                        onChange={(option) => setFieldValue('pid', option.value)}
                        value={values.pid}
                      />
                    )}
                  </Field>
                  <Field name='assignedToId' validate={(value) => validateField('Doctor', value)}>
                      {({ field, form }) => (
                      <SearchableSelect
                        label="Select Assigned To"
                        placeholder="-"
                        options={groupedOptions}
                        error={form.errors.assignedToId}
                        touched={form.touched.assignedToId}
                        onChange={(option) => setFieldValue('assignedToId', option.value)}
                        value={values.assignedToId}
                      />
                    )}
                  </Field>
                  <Field name='due' validate={(value) => validateField('Date', value)}>
                      {({ field, form }) => (
                      <FormControl isInvalid={form.errors.due && form.touched.due} my="2" isRequired>
                        <FormLabel color={"gray.400"} mb="0" fontWeight={"normal"}>
                          Need by:
                        </FormLabel>
                        <Input
                          {...field}
                          variant="flushed"
                          placeholder="-"
                          type="date"
                          my="2"
                        />
                        <FormErrorMessage>{form.errors.due}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='files'>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.due && form.touched.due} my="2" isRequired>
                          <TaskFiles form={form} patient={patients[values.pid] ?? null} dashState={dashState} setFilesArray={setFiles} filesArray={files} isDisabled={isDisabled}/>
                          <List my="2">
                            {files?.map((file) =>
                              <ListItem fontSize={'sm'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                <ListIcon as={DeleteIcon} color='red.500' onClick={() => handleRemoveFile(file)} cursor={'pointer'}/>
                                {extractFileName(file.name, 'name')}
                              </ListItem>
                            )}
                          </List>
                      </FormControl>
                    )}
                  </Field>
              </PopoverBody>
              <PopoverFooter border="0">
                <Flex justifyContent="end">
                  <ButtonGroup size="sm">
                    <Button
                      colorScheme="blue"
                      variant="ghost"
                      onClick={onClose}
                    >
                      Go Back
                    </Button>
                    <Button
                      isLoading={isSubmitting}
                      colorScheme="blue"
                      type='submit'
                    >
                      Add Task
                    </Button>
                  </ButtonGroup>
                </Flex>
              </PopoverFooter>
            </Form>
          )}
          </Formik>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
