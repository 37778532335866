import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {
  Box,
  useToast,
  TabList,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import {fetchJournal} from "./helpers/fetchJournal";
import JournalAnalytics from "./JournalAnalytics";
import JournalList from "./JournalList";

export default function Journal() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [journals, setJournals] = useState(null);

  const toast = useToast();

  useEffect(() => {
    fetchJournal(setJournals, dashState, patient, toast);
  }, []);

  return (
    <Box mx="auto" my="6" pb="6" px="8" boxShadow="md" bg="white">
      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>Analytics</Tab>
          <Tab>Journal List</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <JournalAnalytics journals={journals} state={dashState} />
          </TabPanel>
          <TabPanel>
            <JournalList journals={journals} state={dashState} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
