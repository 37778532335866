import {VStack, Box, Text, Center, Input, Icon} from "@chakra-ui/react";
import {FiUploadCloud} from "react-icons/fi";

export default function Dropzone({selectedFileUpload, setSelectedFileUpload}) {
  return (
    <Center w="full">
      <Box
        as="label"
        for="dropzone-file"
        w="full"
        h="40"
        border="2px"
        borderColor={"gray.300"}
        borderStyle="dashed"
        rounded="lg"
        cursor="pointer"
        bg={"gray.50"}
        _hover={{
          bg: "gray.100",
          borderColor: "gray.400",
        }}
      >
        <VStack spacing={4} justify="center" p={5} h="full">
          <Icon as={FiUploadCloud} color="gray.600" fontSize="35px" />
          <Box textAlign="center">
            <Text fontSize="sm" color={"gray.600"} fontWeight="semibold">
              {!selectedFileUpload
                ? "Click to upload or drag and drop "
                : selectedFileUpload?.name}
            </Text>
            <Text fontSize="xs" color={"gray.600"}>
              {!selectedFileUpload
                ? "All file types accepted"
                : parseInt(selectedFileUpload?.size / 1024) +
                  "KB ready for upload"}
            </Text>
          </Box>
          <Input
            type="file"
            id="dropzone-file"
            onChange={(e) =>
              e.target.files[0] && setSelectedFileUpload(e.target.files[0])
            }
            style={{display: "none"}}
          />
        </VStack>
      </Box>
    </Center>
  );
}
