import {deleteChart} from "./helpers/deleteChart";
import {saveDraft} from "./helpers/saveDraft";
import {completeChart} from "./helpers/completeChart";
import {replaceTags} from "./helpers/mergetags";

import {
  ButtonGroup,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  useToast,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import {useContext, useState, useRef} from "react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";

export default function ChartControls({
  editorRef,
  signed,
  setSigned,
  reportId,
  reportTitle,
  setReportId,
  setDirty,
  reports,
  setReports,
  setReportTitle,
  apptId,
  setApptId,
  setApptLinkToChart,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const [controlDisabled, setControlDisabled] = useState(false);
  const dashState = useContext(DashStateContext);

  const toast = useToast();
  const [evt, setEvt] = useState(0);
  const confirmCompleteClose = useRef(null);

  return (
    <Flex justify="center" alignItems={"flex-end"} mt="6">
      <FormControl
        id="reports"
        maxW="175px"
        mx="2"
        isDisabled={controlDisabled}
      >
        <FormLabel color={"blue.500"}>Uncompleted Reports</FormLabel>
        <Select
          variant="flushed"
          placeholder="-"
          maxW="175px"
          value={reportId}
          onChange={(e) => {
            if (editorRef.current && !controlDisabled) {
              let report = reports.find((r) => r.id === e.target.value);
              editorRef.current.setContent(report?.content || "");
              setReportTitle(report?.title || "");
              setReportId(e.target.value);
              setApptId(report?.aid || "");
              setSigned(false);
              if (report?.aid) setApptLinkToChart("");
            }
          }}
        >
          {reports.map((e, i) => (
            <option key={i} value={e.id}>
              {e.title}
            </option>
          ))}
        </Select>
      </FormControl>
      <Button
        px="10"
        mx="2"
        colorScheme="blue"
        variant="ghost"
        isDisabled={controlDisabled}
        onClick={() => {
          replaceTags(editorRef, patient);
        }}
      >
        Fill variables
      </Button>

      <Button
        px="10"
        mx="2"
        colorScheme="blue"
        variant="outline"
        isDisabled={controlDisabled}
        onClick={() => {
          saveDraft(
            dashState,
            patient,
            editorRef,
            reportId,
            reportTitle,
            setReportId,
            setDirty,
            evt,
            setEvt,
            toast,
            reports,
            setReports,
            apptId,
            setControlDisabled
          );
        }}
      >
        Save Draft
      </Button>

      <Popover>
        <PopoverTrigger>
          <Button
            isDisabled={controlDisabled}
            px="10"
            mx="2"
            colorScheme="blue"
          >
            Sign & Complete
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              Confirm Completion
            </PopoverHeader>
            <PopoverCloseButton ref={confirmCompleteClose} />
            <PopoverBody>
              By signing and completing this chart, you are confirming that you
              have reviewed the contents of this chart and that it is accurate
              and complete.{" "}
              <b>You will not be able to edit it after signing.</b>
            </PopoverBody>
            <PopoverFooter border="0">
              <Flex justifyContent="end">
                <ButtonGroup size="sm">
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => {
                      confirmCompleteClose.current.click();
                    }}
                  >
                    Go Back
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      completeChart(
                        dashState,
                        patient,
                        editorRef,
                        reportId,
                        reportTitle,
                        setReportId,
                        setDirty,
                        setSigned,
                        evt,
                        setEvt,
                        toast,
                        reports,
                        setReports,
                        apptId,
                        setControlDisabled,
                        setReportTitle
                      );
                      confirmCompleteClose.current.click();
                    }}
                  >
                    Sign & Complete
                  </Button>
                </ButtonGroup>
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
}
