import {crud} from "../../../../crudRequests";
import {formatDateTime} from "../../helpers/timeFormatters";

const collections = ["patients", "doctors", "locations"];

const collectionData = {};

const getCollection = async (state, patientData, collection) => {
  return crud(state, [
    {
      db: state.db,
      collection: collection,
      parameters:
        collection === "doctors"
          ? state.userType === "admin"
            ? []
            : [{did: state.doctor?.did}]
          : collection === "locations"
          ? [{lid: state.selectedLocation}]
          : [{pid: patientData.pid}],
      method:
        collection === "locations" ||
        collection === "patients" ||
        (collection === "doctors" && state.userType !== "admin")
          ? "findOne"
          : "find",
    },
  ]).then((res) => {
    const data = res.data[0];
    return data;
  });
};

const dateString = (date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString("en-us");
};
const imageString = (image) => {
  return "<img src={" + image + "} />";
};

/**
 * Standardizes the format of the database data recursively.
 */
const parseDB = (data) => {
  if (Array.isArray(data)) {
    const out = {};
    data.forEach((item, index) => {
      if (typeof item === "object" && item !== null && item.date) {
        out[dateString(item.date)] = parseDB(item);
      } else {
        out[index] = parseDB(item);
      }
    });
    return out;
  }

  if (typeof data === "object" && data !== null) {
    const out = {};
    Object.keys(data).forEach((key) => {
      if (key !== "_id") {
        if (
          key === "logo" ||
          key === "photo" ||
          (key === "signature" && typeof data[key] === "string")
        ) {
          // Assuming the base64 string is ready to be used in an img tag
          out[key] = `<img style="height:50px" src="${data[key]}" />`;
        } else {
          out[key] = parseDB(data[key]);
        }
      }
    });
    return out;
  }

  return data;
};

/**
 * Recursive computation for formatting.
 */
const mergeTagListFormatHelper = (data, path) => {
  if (typeof data === "object" && data !== null) {
    if (path === "") {
      // top-level case has different formatting
      return Object.entries(data)
        .map(([key, value]) => {
          return mergeTagListFormatHelper(value, key);
        })
        .filter((item) => Object.keys(item).length > 0); // filter out no data case
    } else {
      if (Object.entries(data).length === 0) return {};
      return {
        title: path,
        menu: Object.entries(data)
          .map(([key, value]) => {
            return mergeTagListFormatHelper(value, path + "." + key);
          })
          .filter((item) => Object.keys(item).length > 0), // empty menu case
      };
    }
  }

  return {
    title: path,
    value: path,
  };
};

/**
 * Formats standardized data into merge tag menu.
 */
const mergeTagListFormat = (colData) => {
  return mergeTagListFormatHelper(colData, "");
};

const getMergeTagsList = async (state, patientData) => {
  for (const collection of collections) {
    const DBdata = await getCollection(state, patientData, collection);
    collectionData[collection] = parseDB(DBdata);
  }
  collectionData["today's date/time"] = formatDateTime(false, true);
  console.log(collectionData);
  return mergeTagListFormat(collectionData);
};

const replaceCallback = (string, callback) => {
  return string.replace(/{{(.*?)}}/g, (match, content) => callback(content));
};

const getReplaceTag = () => {
  return (tag) => {
    const parts = tag.split(".");
    let out = collectionData;
    for (const part of parts) {
      out = out[part];
      if (out === undefined) return `{{${tag}}}`;
    }
    return out;
  };
};

const replaceTags = (editorRef) => {
  if (editorRef.current) {
    const undoManager = editorRef.current.undoManager;
    const newContent = replaceCallback(
      editorRef.current.getContent(),
      getReplaceTag()
    );
    editorRef.current.setContent(newContent);
    undoManager.add();
  }
};

export {getMergeTagsList, replaceTags};
