import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  lightFormat,
  startOfMonth,
  endOfMonth,
} from "date-fns";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function dateString(date) {
  try {
    let d = new Date(date);
    let s = `${weekday[d.getDay()]}, ${
      months[d.getMonth()]
    } ${d.getDate()}, ${d.getFullYear()}`;
    return s;
  } catch (error) {
    return "N/A";
  }
}
