import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {crudStorageUpload} from "../../../../storageRequests";
import verifyFolder from "./verifyFolder";

const blobToFile = (blob, fileName) => {
  const file = new File([blob], fileName, {type: blob.type});
  return file;
};

const captureAsPDF = async (prescriptionRef) => {
  const maxPageHeight = 1100; // Define your maximum page height in pixels
  const prescriptionElement = prescriptionRef.current;
  const canvasList = [];

  // Calculate the total height of the content
  const contentHeight = prescriptionElement.clientHeight;
  let yOffset = 0;

  // Create canvas elements for each page
  while (yOffset < contentHeight) {
    const canvas = await html2canvas(prescriptionElement, {
      width: 850,
      height: maxPageHeight,
      y: yOffset, // Offset the content for each page
    });

    canvasList.push(canvas);
    yOffset += maxPageHeight;
  }

  // Create a PDF with multiple pages
  const pdf = new jsPDF();
  canvasList.forEach((canvas, index) => {
    const imgData = canvas.toDataURL("image/png");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    if (index > 0) {
      pdf.addPage();
    }

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  });

  const pdfData = pdf.output("blob");
  // console.log({pdfData}); // This is the PDF in base64 format
  return pdfData;
};

export default async function uploadFile(
  state,
  pid,
  prescriptionRef,
  therapies,
  medications,
  prescriptionType
) {
  try {
    const pdfBlob = await captureAsPDF(prescriptionRef);
    const medNames =
      prescriptionType === "therapy"
        ? therapies.map(
            (therapy) => `${therapy.therapyName} ${therapy.length_days}`
          )
        : medications.map((med) => `${med.drugName} ${med.dosage}`).join(", ");
    const imgName = `${medNames} _${
      new Date(Date.now()).toLocaleString().split(",")[0]
    }.pdf`.replaceAll("/", "_");
    const pdfFile = blobToFile(pdfBlob, imgName);
    await verifyFolder(state, pid);
    //console.log({pdfFile, therapies, medications, prescriptionType, pid});
    const res = await crudStorageUpload(
      state,
      pdfFile,
      "brightlighthealth",
      `${pid}/prescriptions/`
    );
    console.log({res});
    return imgName;
  } catch (err) {
    console.log({err});
    return;
  }
}
