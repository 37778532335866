import React, {useState, useEffect} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Spinner,
  Table,
  TableCaption,
  Thead,
  Th,
  Td,
  Text,
  Tbody,
  Tr,
  Badge,
} from "@chakra-ui/react";
import {SearchIcon, ViewIcon} from "@chakra-ui/icons";
import {crud} from "../../../crudRequests";

export function FileLogsModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAuditLogs = async () => {
    setLoading(true);
    try {
      const data = await crud(props.dashState, [
        {
          db: props.dashState.db || "blh",
          collection: "auditLogs",
          method: "find",
          parameters: [
            {
              action: {$in: ["FAX-FILE", "EMAIL-FILE", "DOWNLOAD-FILE"]},
              "payload.fileName": props.fileName,
              path: props.path,
            },
          ],
        },
      ]);
      setAuditLogs(data.data[0]);
    } catch (error) {
      console.error("Error fetching audit logs:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Badge
        fontSize="12px"
        rounded="md"
        p="1"
        px="2"
        ml="2"
        colorScheme="pink"
        cursor={"pointer"}
        onClick={() => {
          fetchAuditLogs();
          onOpen();
        }}
      >
        <SearchIcon marginRight="1" />
        Log
      </Badge>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Communication log</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Spinner />
            ) : (
              <Table height="100%">
                <TableCaption>Communication logs for this file</TableCaption>
                <Thead bg="gray.100">
                  <Tr>
                    <Th>Action</Th>
                    <Th>Collection</Th>
                    <Th>Recipient</Th>
                    <Th>Timestamp</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {auditLogs.map((log) => {
                    return (
                      <Tr key={log._id}>
                        <Td fontSize={"15px"}>
                          <Text>{log.action}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{log.collection}</Text>
                        </Td>

                        <Td fontSize={"15px"}>
                          <Text>
                            {log.action === "DOWNLOAD-FILE"
                              ? ""
                              : log.payload?.emails?.join(", ") ||
                                `from ${log.payload.fromNumber} to ${log.payload.toNumber}`}
                          </Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>
                            {new Date(log.payload?.timestamp).toLocaleString()}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
