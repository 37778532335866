import {useContext, useState, useEffect} from "react";
import {
  Box,
  useToast,
  TabList,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  Text,
  Heading,
} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {fetchPrescriptions} from "./helpers/fetchIntake";
import PrescriptionReport from "./PrescriptionReport";
import Prescription from "./Prescription";
import ComplianceCal from "./ComplianceCal";

export default function Prescriptions() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [prescriptions, setPrescriptions] = useState([]);

  const toast = useToast();

  useEffect(() => {
    fetchPrescriptions(setPrescriptions, dashState, patient, toast);
  }, []);

  return (
    <Box mx="auto" my="6" pb="6" px="8" boxShadow="md" bg="white">
      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>Prescriptions</Tab>
          <Tab disabled={dashState.userType !== "doctor"}>Prescribe</Tab>
          <Tab>Compliance</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PrescriptionReport
              patient={patient}
              state={dashState}
              pid={patient.pid}
            />
          </TabPanel>
          <TabPanel>
            {dashState.userType === "doctor" ? (
              <>
                <Prescription state={dashState} patient={patient} />
              </>
            ) : (
              <>
                <Text
                  textAlign="center"
                  fontWeight="medium"
                  color="gray.600"
                  mt="4"
                >
                  You are not authorized to prescribe medication for this
                  patient
                </Text>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <ComplianceCal patient={patient} state={dashState} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
