import {crud} from "../../../../crudRequests";

const updateOrgData = (state, oid, getFormValue) => {
  return crud(state, [
    {
      db: "customers",
      collection: "organizations",
      parameters: [
        {oid},
        {
          $set: {
            address: getFormValue("orgAddr"),
            phone: getFormValue("orgPhone"),
            fax: getFormValue("orgFax"),
          },
        },
      ],
      method: "updateOne",
    },
  ]);
};

export default updateOrgData;
