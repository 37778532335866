import {crud} from "../../../../crudRequests";
import {getStartOfWeek, colors} from "./calcTimeline";

function formatTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "p" : "a";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let timeString = hours + ":" + minutes + ampm;

  return timeString;
}

const convertedSleep = (sleep) => {
  let newSleep = [];
  sleep?.map((s) => {
    const convertedActivities = s.activities?.map((activity) => {
      const startTime = new Date(activity.start_time);
      const endTime = new Date(activity.end_time);
      const color = colors[activity.type];
      const start_time = startTime.toTimeString();
      const end_time = endTime.toTimeString();
      const start_time2 = formatTime(startTime);
      const end_time2 = formatTime(endTime);

      return {
        ...activity,
        startTime,
        endTime,
        color,
        end_time,
        start_time,
        start_time2,
        end_time2,
      };
    });

    const totalAwakeningsDuration = convertedActivities?.reduce(
      (totalDuration, activity) => {
        if (activity.type === "awakenings") {
          totalDuration +=
            (activity.endTime - activity.startTime) / (1000 * 60);
        }
        return totalDuration;
      },
      0
    );

    const ToReturn = {
      ...s,
      timeAsleep: new Date(s.time_asleep),
      timeAwake: new Date(s.time_awake),
      timeInbed: new Date(s.time_inbed),
      timeOutofbed2: formatTime(new Date(s.time_outofbed)),
      timeAsleep2: formatTime(new Date(s.time_asleep)),
      timeAwake2: formatTime(new Date(s.time_awake)),
      timeInbed2: formatTime(new Date(s.time_inbed)),
      timeOutofbed: new Date(s.time_outofbed),
      time_asleep: new Date(s.time_asleep).toTimeString(),
      time_awake: new Date(s.time_awake).toTimeString(),
      time_inbed: new Date(s.time_inbed).toTimeString(),
      time_outofbed: new Date(s.time_outofbed).toTimeString(),
      activities: convertedActivities,
      totalAwakeningsDuration,
    };

    if (!isNaN(new Date(s.time_awake))) {
      newSleep.push(ToReturn);
    }

    return ToReturn;
  });
  return newSleep;
};

export async function fetchSleep(
  setSleep,
  dashState,
  patient,
  toast,
  setallSleep
) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "sleep_diary",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);
    if (res.data[0]) {
      // console.log('responce: ', res.data[0])
      const updatedSleep = res.data[0]
        .map((s) => ({
          ...s,
          day: new Date(new Date(s.day).setDate(new Date(s.day).getDate() + 1)),
        }))
        .sort((a, b) => a.day - b.day);

      const startOfWeek = getStartOfWeek(new Date());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      const filteredSleep = updatedSleep.filter((s) => {
        const sleepDate = new Date(s.day);
        return sleepDate >= startOfWeek && sleepDate <= endOfWeek;
      });
      setSleep(convertedSleep(filteredSleep));
      const updatedSleep2 = convertedSleep(updatedSleep);
      setallSleep(updatedSleep2);
      // console.log('mutated data: ', updatedSleep2)
    }
  } catch (err) {
    toast({
      title: "Failed to fetch sleep data",
      description:
        err?.message || "An error occurred while fetching sleep data.",
      status: "error",
      isClosable: true,
    });
  }
}
