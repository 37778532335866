export function bookNowReducer(state, action) {
  switch (action.type) {
    case "UPDATE_STATE": {
  
      return init(action.payload);
    }

    case "CHANGE_LOCATION": {
      return {...state, selectedLocation: action.payload};
    }
    case "SHOW_LOADER": {
      return {...state, loader: action.payload, selectedInterval: action.payload?null:state.selectedInterval};
    }
  
    case "SELECT_SERVICE": {

       if(state.selectedService===action.payload){
       return {...state, selectedService: null,selectedDoctor:null};
       }  
      
      return {...state, selectedService: action.payload};
    }

    case "SELECT_DOCTOR": {
      if(state.selectedDoctor===action.payload){
        return {...state, selectedService: null,selectedDoctor:null};
        }
      return {...state, selectedDoctor: action.payload};
    }
    case "RESET": {
      
      return {...state, selectedDoctor: null, selectedService: null,showCalendar:false};
    }

    case "SHOW_CALENDAR": {

      return {...state, showCalendar: action.payload, };
    }

    case "SET_INTERVAL": {

      return {...state, selectedInterval: action.payload };
    }

    case "SHOW_PATIENT_FORM": {

      return {...state, showPatientForm: action.payload };
    }
    case "SELECT_THREAD": {

      return {...state, selectedThread: action.payload };
    }





   default: 
   return state;
  }
}
 


export function init(state) {
  //console.log(state,"INITIAL")
  if (!state) return {
    organization:null,
    doctors : [],
    services : [],
    doctorsPayload: {},
    servicesPayload: {},
    selectedDoctor : null,
    selectedService : null,
    location : null,
    loader:false,
    loadingData : true,
    selectedThread:'home'
  };
  else {
    state.loader = false;
    state.services.forEach((s,i )=> {
      state.servicesPayload[s.serviceId] = {};
      state.servicesPayload[s.serviceId].data = s;
      state.servicesPayload[s.serviceId].doctors = [];
     });
  
     state.doctors.forEach((d,i )=> {
     state.doctorsPayload[d.did] = {};
     state.doctorsPayload[d.did].services=[]
     state.doctorsPayload[d.did].data = d;

     d.services?.forEach((serviceId) => {
      if (state.servicesPayload.hasOwnProperty(serviceId)) {
        state.servicesPayload[serviceId].doctors.push(d);
        state.doctorsPayload[d.did].services.push(state.servicesPayload[serviceId].data)
      }
    });
   });


  return state;
  }


}
