import { nanoid } from "nanoid";
import { crud } from "../../../crudRequests";

export const createTask = (
  taskData,
  dashState,
  setTasks,
  assignedToName,
  patient,
  toast,
) => {
  taskData.taskId = nanoid(10)
  let createdDate = new Date()
  createdDate.setDate(createdDate.getDate() - 1)
  taskData.createdDate = createdDate.getTime()
  taskData.createdById = dashState.admin ? dashState.admin.id: dashState.doctor.did
  taskData.createdByName = dashState.admin ? dashState.admin.name: dashState.doctor.name
  taskData.assignedToName = assignedToName
  taskData.patient = patient
  taskData.status = 3
  taskData.lid = dashState.selectedLocation
  return crud(dashState, [
    {
      db: dashState.db,
      collection: "tasks",
      parameters: [taskData],
      method: "insertOne",
    },
  ])
    .then((res) => {
      setTasks(prevTasks => [taskData, ...prevTasks])
      toast({
        title: "New Task Added",
        description: "The task has been added successfully.",
        status: "success",
        isClosable: true,
      })
    })
    .catch(function (error) {
      console.log(error)
      if (error.response.status === 403) {
        window.location.href = "../login"
      } else {
        toast({
          title: "As error occured",
          description: error.response?.data || error.message,
          status: "error",
          isClosable: true,
        });
      }
    });
}
