import {supplementaryCrud} from "../../../crudRequests";

export default async function fetchCategories(
  state,
  setIsLoading,
  setCategories,
  toast
) {
  try {
    setIsLoading(true);
    let req = [
      {
        db: "autocomplete",
        collection: "product_categories",
        parameters: [{}],
        method: "find",
      },
    ];

    let res = await supplementaryCrud(state, req);
    res = res.data[0];
    setCategories(res || []);
    setIsLoading(false);
  } catch (error) {
    setCategories([]);
    setIsLoading(false);
  }
}
