import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {crud} from "../../../crudRequests";
import {membershipReceipt} from "../../appointments/payment/uploads";

export function PatientMembershipPaymentModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [description, setDescription] = React.useState("");
  const toast = useToast();
  return (
    <>
      <Button
        onClick={onOpen}
        fontWeight="light"
        variant="outline"
        textColor="#827FFF"
        borderColor="#827FFF"
        isDisabled={
          props.transaction.paymentStatus === "Paid" ||
          (props.transaction?.patientAmount || 0) -
            (props.transaction?.amountPaidByPatient || 0) ==
            0
        }
      >
        Mark as paid
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Membership payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Payment description</FormLabel>
              <Input
                value={description}
                onChange={(evt) => {
                  setDescription(evt.target.value);
                }}
                placeholder="description"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="#7066e0"
              textColor="white"
              fontWeight="thin"
              mr={3}
              onClick={handleFullPayment()}
              width="1/3"
              isDisabled={!description.length}
            >
              Full amount
            </Button>

            <Button
              variant="solid"
              backgroundColor="#6e7881"
              textColor="white"
              fontWeight="thin"
              width="auto"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

  function handleFullPayment() {
    return () => {
      const generatingToast = toast({
        title: "Updating payment status.",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });
      let patientAmount = parseFloat(props.transaction.patientAmount ?? 0);
      let tppAmount = parseFloat(props.transaction.tppAmount ?? 0);
      let amountPaidByPatient = parseFloat(
        props.transaction.patientAmount ?? 0
      );
      let amountPaidByTpp = parseFloat(props.transaction.amountPaidByTpp ?? 0);
      let otbp = patientAmount - amountPaidByPatient;
      let otbi = tppAmount - amountPaidByTpp;
      const updateBody = {
        ...props.transaction,
        patientPaymentStatus: "paid",
        paymentStatus: parseFloat(otbi) <= 0 ? "paid" : "pending",
      };
      let receiptAmount =
        props.transaction.patientAmount - props.transaction.amountPaidByPatient;
      let {_id, ...data} = updateBody;

      crud(props.state, [
        {
          db: props.state.db,
          collection: "billing",
          parameters: [
            {membershipUuid: props.transaction.membershipUuid},
            {
              $set: {
                patientPaymentStatus: "paid",
                amountPaidByPatient: props.transaction.patientAmount,
                paymentStatus:
                  parseFloat(otbp) <= 0 && parseFloat(otbi) <= 0
                    ? "paid"
                    : "pending",
              },
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
      ])
        .then(async (res) => {
          await crud(props.state, [
            {
              db: props.state.db,
              collection: "billing",
              parameters: [
                {aid: data.aid},
                {
                  $set: {
                    paid: (res.data[1]?.paid || 0) + receiptAmount,
                  },
                },
                {returnNewDocument: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: props.state.db,
              collection: "patients",
              parameters: [
                {
                  pid: props.transaction.pid,
                  "memberships.uuid": props.transaction.membershipUuid,
                },
                {
                  $set: {
                    "memberships.$.status": "Paid",
                  },
                },
                {returnNewDocument: true},
              ],
              method: "findOneAndUpdate",
            },
          ]);
          membershipReceipt({
            state: props.state,
            transaction: props.transaction,
            patient: props.patient,
          });
          toast.close(generatingToast);
          toast({
            title: "The patient amount has been paid!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          const updatedMemberships = props.patient.memberships.map((mData) => {
            return mData.uuid === props.transaction.membershipUuid
              ? {
                  ...mData,
                  status: "Paid",
                }
              : mData;
          });
          props.dispatch({
            type: "UPDATE_PATIENT",
            pid: props.transaction.pid,
            payload: {
              memberships: updatedMemberships,
            },
          });
          props.refetch();
        })
        .catch(function (error) {
          toast({
            title: "Payment Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          console.log(error);
        });
      onClose();
    };
  }
}
