import {useEffect} from "react";
import {ToastContainer, toast} from "react-toastify";
import {isSameDay} from "date-fns";

import "react-toastify/dist/ReactToastify.css";
import {BsFillInfoCircleFill} from "react-icons/bs";

const Msg = ({appt, before, closeToast, toastProps}) => (
  <div className="">
    <h1 className="font-semibold text-sky-600 flex items-center space-x-3">
      <BsFillInfoCircleFill className="text-[1.2rem] mb-[2px]" />
      <span className="">Reminder</span>
    </h1>
    <p>
      <span>Doctor</span>
      <span className="font-semibold">{` ${appt.dName}'s `}</span>
      <span>appointment with patient</span>
      <span className="font-semibold">{` ${appt.pName} `}</span>
      <span>{`in`}</span>
      <span className="font-semibold">{` ${before}.`}</span>
    </p>
  </div>
);

export default function ToastNotification({appointments}) {
  useEffect(() => {
    let current = new Date();
    let currentDayAppts = appointments.filter((appt) => {
      return (
        isSameDay(appt.ISOdate, current) && appt.ISOdate > current.getTime()
      );
    });
    let min10 = 10 * 60 * 1000;
    let min1 = 60 * 1000;

    let currentDayAppts10min = currentDayAppts
      .map((appt) => {
        let delay = appt.ISOdate - min10 - Date.now();
        //delay = delay <= 0 ? 200 : delay;

        return delay >= 0
          ? setTimeout(() => {
              toast.info(<Msg appt={appt} before={"10 minutes"} />, {
                className: "border bg-slate-800 bg-indigo-600",
                icon: false,
              });
            }, delay)
          : null;
      })
      .filter((e) => e);

    let currentDayAppts1min = currentDayAppts
      .map((appt) => {
        let delay = appt.ISOdate - min1 - Date.now();
        //delay = delay <= 0 ? 200 : delay;
        return delay >= 0
          ? setTimeout(() => {
              toast.info(<Msg appt={appt} before={"1 minute"} />, {
                className: "border bg-slate-800 bg-indigo-600",
                icon: false,
              });
            }, delay)
          : null;
      })
      .filter((e) => e);

    return () => {
      currentDayAppts10min
        .concat(currentDayAppts1min)
        .forEach((a) => clearTimeout(a));
    };
  }, [appointments, isSameDay]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={30000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
