import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef} from "react";
import {checkResetParams, resetPassword} from "../authRequests";
import {useParams, useNavigate} from "react-router-dom";
import {
  loadDataForAdminLogin,
  loadDataForDoctorLogin,
  crud,
} from "../crudRequests";
import clientInfo from "../additional_files/loginInfo";
import axios from "axios";
export default function ResetPwdForm() {
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [userType, setUserType] = useState("");
  const [serverError, setServerError] = useState("");
  const [authError, setAuthError] = useState("");
  const [loading, setLoading] = useState("");
  const loadedRef = useRef(null);
  const loaderRef = useRef(null);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  useEffect(() => {
    const checkPasswords = async () => {
      if (password === confirmPassword) {
        setPasswordsMatch(true);
      } else {
        setPasswordsMatch(false);
      }
    };

    checkPasswords();
  }, [password, confirmPassword]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  function reset(e) {
    if (org) {
      e.preventDefault();
      if (passwordsMatch && password.length >= 8) {
        setLoading(true);
        let reset = window.location.href.split("?reset=")[1];
        resetPassword(reset, userId, password, userType, org?.db)
          .then(async (res) => {
            loaderRef.current.className = "block";
            loadedRef.current.className = "hidden";

            if (userType === "doctor")
              loadDataForDoctorLogin(
                {
                  jwt: res.data.token,
                  doctor: res.data.doctor,
                  destination: "../",
                  db: org?.db,
                },
                navigate
              );
            else
              loadDataForAdminLogin(
                {
                  jwt: res.data.token,
                  admin: res.data.admin,
                  destination: "../",
                  db: org?.db,
                },
                navigate
              );

            let jsonIp = await axios({
              url: "https://api.ipify.org?format=json",
            });
            let userId = res.data.doctor?.did || res.data.admin?.id;

            crud({jwt: res.data.token}, [
              {
                db: org?.db,
                collection: "security",
                parameters: [
                  {userId},
                  {
                    $push: {
                      logins: {
                        ip: jsonIp.data.ip,
                        client: clientInfo(),
                        timestamp: new Date(),
                      },
                    },
                  },
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
            ]);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
  }



  useEffect(function () {
    let reset = window.location.href.split("?reset=")[1];
    checkResetParams(reset)
      .then((response) => {
        setEmail(response.data.email);
        setUserId(response.data.userId);
        setUserType(response.data.userType);
        setOrg(response.data.org);
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "../login";
      });
  }, []);


  return (
    <div className="h-screen grid place-items-center">
      <div ref={loaderRef} className="hidden">
        <div className="border p-[6.5rem] rounded-md">
          <div className="flex justify-center align-middle">
            <img src="/images/Doctor/loader.gif" className="h-36" />
          </div>
        </div>
      </div>

      <div ref={loadedRef}>
        <form onSubmit={reset} className="border p-12 rounded-md">
          {org?.logo ? (
            <img src={org?.logo} className="w-48 mx-auto mb-5" />
          ) : (
            <img src="/images/logo.png" className="w-48 mx-auto mb-5" />
          )}
          {authError && (
            <div className="">
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      Invalid link!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {serverError && (
            <div className="">
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      <span className="font-semibold">
                        The server has encountered an
                        <br />
                        internal error.
                      </span>
                      <br />
                      Please try again later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="input-group">
            <div className="input-label text-xs -ml-3 mr-4 -mt-3">
              Please choose a new password (min. 8 letters) for your Brightlight
              Account associated with <b>{email}</b>.
            </div>
            <input
              type="password"
              id="password"
              onChange={handlePasswordChange}
              required
              autoComplete="off"
              className="input mt-[4.5rem] w-72 py-3 px-6 rounded-[4px] my-2 focus:border-[#3871e0] focus:ring-1 focus:ring-[#3871e0] border"
            />
            <label htmlFor="password" className="input-label mt-[4rem]">
              Create Password
            </label>
          </div>
          <div className="input-group">
            <input
              type="password"
              id="password2"
              onChange={handleConfirmPasswordChange}
              required
              autoComplete="off"
              className="input  w-72 py-3 px-6 rounded-[4px] my-2 focus:border-[#3871e0] focus:ring-1 focus:ring-[#3871e0] border"
            />
            <label htmlFor="password2" className="input-label">
              Confirm Password
            </label>
          </div>
          {password && confirmPassword ? (
            passwordsMatch ? (
              <div className="text-green-500 text-xs mb-1">Passwords match</div>
            ) : (
              <div className="text-red-500 text-xs mb-2">
                Passwords do not match
              </div>
            )
          ) : (
            <span></span>
          )}
          {password ? (
            password.length >= 8 ? (
              <div className="text-green-500 text-xs mb-3">
                Password is satisfactory character length
              </div>
            ) : (
              <div className="text-red-500 text-xs mb-3">
                Password must be min. 8 letters
              </div>
            )
          ) : (
            <span></span>
          )}

          <div className="justify-center">
            <button
              disabled={loading}
              type="submit"
              className="w-72 p-2.5 px-5 rounded-[4px] my-2 flex justify-center items-center bg-dark text-background font-medium text-medium right cursor-pointer"
            >
              Continue to account
              {loading && (
                <span className="">
                  <svg
                    className="animate-spin ml-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
