import {Country, State} from "country-state-city";
import {useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import {Select, Button, Text, Heading, Spinner} from "@chakra-ui/react";

import {loadAreaCodes, provisionNumber} from "../crudRequests";
import FaxStepper from "./FaxStepper";
import {useToast} from "@chakra-ui/react";
const GetFaxNumbers = (props) => {
  const countries = Country.getAllCountries();
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState("CA");
  const [state, setState] = useState("");
  const toast = useToast();
  useEffect(() => {
    if (country) {
      const states = State.getStatesOfCountry(country);
      setState(states[0]?.isoCode);
      setStates(states);
    }
  }, [country]);

  useEffect(() => {
    if (props.state.selectedLocation && props.state.locations.length > 0) {
      setLocInfo(
        props.state.locations.filter(
          (loc) => loc?.lid === props?.state?.selectedLocation
        )[0]
      );
    }
  }, [props.state]);

  const [locInfo, setLocInfo] = useState({}); // location info for the organization (lid and oid)
  const [areaCodes, setAreaCodes] = useState(false);
  const [areaCode, setAreaCode] = useState(false);
  const [country_code, setCountryCode] = useState(false);
  // loading state for provisioning a number
  const [loadingProv, setLoadingProv] = useState(false);
  return (
    <div className="w-[800px] p-4 pl-10 gap-4">
      {/* <p className="text-[20px] text-bold">No fax number found.</p> */}
      {/* <p className="text-[16px]">Create a fax number</p> */}
      <Heading marginBottom="4">Create a fax number</Heading>
      <FaxStepper
        steps={[
          {
            title: "Location",
            description: "Location information",
            component: ({nextStep}) => {
              return (
                <>
                  <Text size="md">What country are you in:</Text>

                  <Select
                    name="countries"
                    id="countries"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countries.map((country) => (
                      <option key={state.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                  {country && states && (
                    <>
                      {/* states */}
                      <Text size="md">What state/province are you in?</Text>

                      <Select
                        name="states"
                        id="states"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      >
                        {states.map((state) => (
                          <option key={state.isoCode} value={state.isoCode}>
                            {state.name}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}
                  {country && state && (
                    <Button
                      w={100}
                      // className="bg-[#5754FF] rounded text-gl text-white p-2 w-[200px] text-[14px] mt-2"
                      onClick={() =>
                        loadAreaCodes(
                          props.state,
                          false,
                          country || "",
                          state || ""
                        ).then((res) =>
                          // alert(res.data.success)
                          {
                            // console.log({res});
                            setAreaCodes(res.data);
                            setAreaCode(res.data[0].area_code);
                            setCountryCode(res.data[0].country_code);
                            nextStep();
                          }
                        )
                      }
                    >
                      Next
                    </Button>
                  )}
                </>
              );
            },
          },
          {
            title: "Area Code",
            description: "Select an area code",
            disabled: !areaCodes,
            component: ({nextStep}) => (
              <>
                {areaCodes && (
                  <>
                    {/* states */}
                    <Text size="md">Choose an areacode:</Text>
                    {/* <label htmlFor="areacode" className="mt-2"></label> */}

                    <Select
                      name="areacode"
                      id="areacode"
                      value={areaCode}
                      onChange={(e) => {
                        // console.log({event: e.target.value});
                        setAreaCode(e.target.value);
                      }}
                    >
                      {areaCodes.map((area) => (
                        <option key={area.area_code} value={area.area_code}>
                          {area.area_code} - {area.city}
                        </option>
                      ))}
                    </Select>
                    <Button
                      size="md"
                      w={100}
                      disabled={!areaCode}
                      onClick={nextStep}
                    >
                      Next
                    </Button>
                  </>
                )}
              </>
            ),
          },
          {
            title: "Provision Number",
            description: "Get a fax number",
            disabled: !areaCode,
            component: () => (
              <>
                <Heading as="h3" size="lg">
                  Your information
                </Heading>
                <Text>Country: {country}</Text>
                <Text>State/Province: {state}</Text>
                <Text>Area Code: {areaCode}</Text>
                {!loadingProv ? (
                  areaCode && (
                    <Button
                      w={100}
                      disabled={loadingProv}
                      // className="bg-[#5754FF] rounded text-gl text-white p-2 w-[200px] text-[14px] mt-4"
                      onClick={() => {
                        // console.log(country_code, areaCode, locInfo);
                        setLoadingProv(true);
                        provisionNumber(
                          props.state,
                          locInfo.lid,
                          locInfo.oid,
                          country_code,
                          areaCode
                        )
                          .then((res) => {
                            if (res.status === 200) {
                              toast({
                                title:
                                  "Number provisioned! Please reload the page.",
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                                onCloseComplete: () => window.location.reload(),
                              });
                              window.location.reload();
                            }
                          })
                          .finally(() => setLoadingProv(false));
                      }}
                    >
                      Submit
                    </Button>
                  )
                ) : (
                  <Spinner />
                )}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default GetFaxNumbers;
