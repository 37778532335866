import React from 'react';
import Select from 'react-select';
import {FormControl, FormLabel, FormErrorMessage} from '@chakra-ui/react';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    borderBottom: state.isFocused ? '2px solid #E2E8F0' : '1px solid #E2E8F0',
    borderRadius: '0',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#3182CE',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#A0AEC0',
    '&:hover': {
      color: '#3182CE',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A0AEC0',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#2D3748',
  }),
}

export const SearchableSelect = ({label, placeholder, options, isDisabled, error, touched, onChange, value}) => {
  return (
    <FormControl isInvalid={error && touched} my="2" isRequired>
      <FormLabel color={"gray.400"} mb="0" fontWeight={"normal"}>
        {label}
      </FormLabel>
      <Select
        options={options}
        onChange={onChange}
        isDisabled={isDisabled}
        placeholder={placeholder}
        styles={customStyles}
        value={options.find(option => option.value === value)}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
