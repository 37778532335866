import React from "react";
import {FaFile, FaTasks} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

export default function Navbar(props) {
  const p = window.location.pathname;
  const state = props.state;
  //console.log(state)
  const navigate = useNavigate();

  const adminRoutes = [
    "/admin",
    "/forms",
    "/formBuilder",
    "/clinicians",
    "/services",
    "/billing",
    "/permissions",
    "/faxes",
    "/locations",
    "/createService",
    "/insurers",
    "/clinic",
    "/rooms",
    "/provider_types",
  ];

  return (
    <div
      className="fixed z-[30] float-left h-screen w-28 bg-dark rounded-br-2xl"
      style={{borderTopRightRadius: "75px"}}
    >
      <div className="mt-16 justify-center grid">
        <img src="/images/Doctor/logo.png" className="w-20 mb-12" alt="" />
        {p === "/" ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            /*onClick={() => {
              navigate("../", {
                state: {
                  ...state,
                  firstLoading: true,
                },
              });
            }}*/
          >
            <img src="/images/Doctor/schedule_selected.png" className="w-16" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../", {
                state: {
                  ...state,
                  firstLoading: true,
                  selectedAppointment: null,
                },
              });
            }}
          >
            <img src="/images/Doctor/schedule.png" className="w-12" />
            <span className="text-light font-medium text-[11px] text-center mt-2">
              Schedule
            </span>
          </div>
        )}
        {p.toLowerCase().includes("patient") ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../patients", {
                state: {
                  ...state,
                  firstLoading: true,
                  selectedAppointment: null,
                },
              });
            }}
          >
            <img src="/images/Doctor/patients_selected.png" className="w-16" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../patients", {
                state: {
                  ...state,
                  firstLoading: true,
                  selectedAppointment: null,
                },
              });
            }}
          >
            <img src="/images/Doctor/patients.png" className="w-10" />
            <span className="text-light font-medium text-[11px] text-center mt-2">
              Patients
            </span>
          </div>
        )}

        {
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../admin", {
                state: {
                  ...state,
                  firstLoading: true,
                  selectedAppointment: null,
                },
              });
            }}
          >
            {adminRoutes.includes(p) ? (
              <img
                src="/images/Doctor/admin_selected.png"
                className="w-16"
                alt=""
              />
            ) : (
              <img src="/images/Doctor/admin.png" className="w-12" alt="" />
            )}
            <span className="text-light font-medium text-[11px] text-center mt-2">
              {adminRoutes.includes(p) ? "" : "Admin"}
            </span>
          </div>
        }

        <div
          className="mb-6 justify-center grid cursor-pointer"
          onClick={() => {
            navigate("../tasks", {
              state: {
                ...state,
                firstLoading: true,
                selectedAppointment: null,
              },
            });
          }}
        >
          <FaTasks className="w-10 h-10 text-gray-200" />
          <span className="text-light font-medium text-[11px] text-center mt-1">
            Tasks
          </span>
        </div>
        <div
          className="mb-6 justify-center grid cursor-pointer"
          onClick={() => {
            navigate("../reports", {
              state: {
                ...state,
                firstLoading: true,
                selectedAppointment: null,
              },
            });
          }}
        >
          <FaFile className="w-10 h-10 text-gray-200" />
          <span className="text-light font-medium text-[11px] text-center mt-1">
            Reports
          </span>
        </div>
        {p == "/user" ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {}}
          >
            {/*navigate('../user')*/}
            <img src="/images/Doctor/user_selected.png" className="w-20" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../user", {
                state: {
                  ...state,
                  firstLoading: true,
                  selectedAppointment: null,
                },
              });
            }}
          >
            {/*navigate('../user')*/}
            <img src="/images/Doctor/user.png" className="w-10 ml-1" alt="" />
            <span className="text-light font-medium text-[11px] text-center mt-1">
              User <span style={{fontSize: "9px"}}>(you)</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
