import {useState, useEffect} from "react";
import PatientPayment from "./PatientPayment";
import TppPayment from "./TppPayment";
import ChangeAmountModal from "./ChangeAmountModal";
import {getFileData} from "./helpers/getFileData";
import PreviewFile from "./helpers/PreviewFile";
import {Box, Button, useToast, Badge} from "@chakra-ui/react";
import {viewFiles} from "./helpers/viewFiles";
import {lightFormat} from "date-fns";
import {initialInvoices} from "./uploads";

function PatientMembershipBadge(props) {
  const patient = props.state.patients.find(
    (pat) => pat.pid === props.appointment.pid
  );
  const membership = patient.memberships?.find(
    (memb) => memb.uuid === props.appointment.paidByMembership
  );
  if (!membership) return <></>;
  return (
    <>
      <Badge
        colorScheme="yellow"
        m="1rem"
        maxW="15rem"
        whiteSpace="normal"
        overflowWrap="break-word"
      >
        <Box textAlign="justify">Applied membership {membership.name}</Box>
      </Badge>
    </>
  );
}
export default function PaymentInformation({
  appointment,
  state,
  dispatch,
  socket,
}) {
  const [appt, setAppt] = useState(appointment);
  const [amountModal, setAmountModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [fullInvoiceFile, setFullInvoiceFile] = useState([]);
  const [fullReceiptFile, setFullReceiptFile] = useState([]);

  const toast = useToast();
  const [fetchingData, setFetchingData] = useState(false);
  let invoicesPath = `${appointment?.pid || ""}/Invoices/${
    appointment?.nonSessionService ? "Non-Session-Services" : "Appointments"
  }/${appointment?.aid ? fileNameFormat(appointment) : ""}`;
  let receiptsPath = `${appointment?.pid || ""}/Receipts/${
    appointment?.nonSessionService ? "Non-Session-Services" : "Appointments"
  }/${appointment?.aid ? fileNameFormat(appointment) : ""}`;

  const [creatingInv, setCreatingInv] = useState(false);
  const [invOrRecExist, setInvOrRecExist] = useState({
    ptInv: false,
    fullInv: false,
    tppInv: false,
    check: false,
  });
  let {ptInv, fullInv, tppInv} = invOrRecExist;

  function fileNameFormat(appt) {
    return (
      lightFormat(new Date(appt.ISOdate), "dd-MM-yy HH-mm aaa") +
      ` (${appt.aid})/`
    );
  }

  useEffect(() => {
    if (appointment) {
      setAppt(appointment);
      setFullInvoiceFile([]);
      setFullReceiptFile([]);
      viewFiles(state, setFullInvoiceFile, () => {}, invoicesPath, toast);
      viewFiles(state, setFullReceiptFile, () => {}, receiptsPath, toast);
    }
  }, [appointment, invoicesPath, receiptsPath]);

  //console.log(invoicesPath);
  useEffect(() => {
    setInvOrRecExist((prev) => ({
      ...prev,
      fullInv: !!fullInvoiceFile.find((f) => f.name.slice(-1) !== "/"),
    }));
  }, [fullInvoiceFile]);
  state.locations.find((loc) => loc.lid === state.selectedLocation);
  return (
    <div className="mt-8 w-full space-y-1">
      {!appointment?.nonSessionService && (
        <span className="text-[#A1A1A1] text-xs">payment information</span>
      )}
      <div className="bg-[#D2D2FF] w-full p-5 flex flex-col items-center rounded-lg">
        {preview && (
          <PreviewFile
            preview={preview}
            setPreview={setPreview}
            appointment={appointment}
            state={state}
          />
        )}
        <button
          disabled={appointment?.cancelled || appointment?.paidByMembership}
          onClick={(e) => {
            if (!appointment?.cancelled) setAmountModal(true);
          }}
          className={`focus:outline-none disabled:cursor-not-allowed my-2 mx-10 bg-[#5754FF] text-white flex justify-center items-center   rounded-md p-2 px-10  whitespace-nowrap`}
        >
          <span className="font-medium text-sm">Update Criteria</span>
        </button>
        <div className="w-full flex justify-between space-x-4 my-2 px-[2px]">
          {
            <button
              onClick={(e) => {
                let file = fullInvoiceFile.find(
                  (f) => f.name.slice(-1) !== "/"
                );
                if (!fetchingData && appointment && file) {
                  getFileData(
                    appointment,
                    state,
                    invoicesPath,
                    file.name,
                    setPreview,
                    toast,
                    setFetchingData,
                    "full",
                    "invoice"
                  );
                }
              }}
              disabled={!fullInvoiceFile.find((f) => f.name.slice(-1) !== "/")}
              className={`focus:outline-none mb-[1rem] bg-[#fFF] text-[#5754FF] disabled:cursor-not-allowed flex justify-center items-center rounded-md p-2  w-full whitespace-nowrap`}
            >
              <span className="font-medium text-sm">View Full Invoice</span>
            </button>
          }
          {/*<PrintInvRec {...{appointment, state}} />*/}
          <button
            onClick={(e) => {
              let file = fullReceiptFile.find((f) => f.name.slice(-1) !== "/");

              if (!fetchingData && appointment && file) {
                getFileData(
                  appointment,
                  state,
                  receiptsPath,
                  file.name,
                  setPreview,
                  toast,
                  setFetchingData,
                  "full",
                  "receipt"
                );
              }
            }}
            className={`focus:outline-none mb-[1rem] disabled:cursor-not-allowed  text-[#03A700] flex justify-center items-center bg-transparent ring-[1px] active:ring-0 ring-[#03A700]  rounded-md p-2  w-full whitespace-nowrap`}
            disabled={!fullReceiptFile.find((f) => f.name.slice(-1) !== "/")}
          >
            <span className="font-medium text-sm">View Full Reciept</span>
          </button>
        </div>
        {appointment?.paidByMembership && (
          <PatientMembershipBadge appointment={appointment} state={state} />
        )}
        <div className="space-y-3 flex flex-col  min-w-full min-h-[22rem] relative">
          <PatientPayment
            {...{
              appointment: appt,
              state,
              dispatch,
              setInvOrRecExist,
              invOrRecExist,
            }}
          />

          <TppPayment
            {...{
              appointment: appt,
              state,
              dispatch,
              setInvOrRecExist,
              invOrRecExist,
            }}
          />
          {(ptInv === false ||
            fullInv === false ||
            (tppInv === false && appointment?.tpp)) && (
            <Button
              variant="outline"
              size={"sm"}
              isLoading={creatingInv}
              colorScheme="blue"
              type="submit"
              my={4}
              w="full"
              px="10"
              onClick={async () => {
                if (creatingInv) return;
                setCreatingInv(true);
                await initialInvoices({appt, state});
                dispatch({type: "SELECT_APPOINTMENT", payload: {...appt}});
                setCreatingInv(false);
              }}
            >
              Reload Invoices
            </Button>
          )}
        </div>
        {amountModal && appointment && !appointment?.cancelled && (
          <ChangeAmountModal
            state={state}
            appointment={appointment}
            stateChanger={setAmountModal}
            dispatch={dispatch}
            socket={socket}
          />
        )}
      </div>
    </div>
  );
}
