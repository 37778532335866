import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {crud} from "../crudRequests";
import {BsPlusCircle} from "react-icons/bs";
import {PiListMagnifyingGlass} from "react-icons/pi";
import {MdDeleteForever} from "react-icons/md";
export default function ServiceCriteriaSetter({
  state,
  selectedService,
  setShowModal,
  dispatch,
  criteria,
  costsRef,
  reset,
  creating,
  isfixedCost,
}) {
  const [selectedCriteria, setSelectedCriteria] = useState(new Set());
  const [costs, setCosts] = useState(
    selectedService ? selectedService.costsByCriteria || {} : {}
  );
  const [prevReset, setPrevReset] = useState(reset);
  const [prevCriteria, setPrevCriteria] = useState(reset);
  const [prevIsfixedCost, setPrevIsfixedCost] = useState(isfixedCost);
  costsRef.current = costs;

  if (reset !== prevReset) {
    setPrevReset(reset);
    setSelectedCriteria(new Set());
    setCosts({});
  }
  if (criteria !== prevCriteria || prevIsfixedCost !== isfixedCost) {
    setPrevCriteria(criteria);
    setSelectedCriteria(new Set());
    setPrevIsfixedCost(isfixedCost);
  }

  useEffect(() => {
    setSelectedCriteria(new Set());
  }, [state.services]);

  function criteriaFormatByKey(key, criteria) {
    if (key) {
      let pairs = key
        .split("||")
        .map((e) => {
          let pair = e.split("|");
          let type = pair[0];
          let crt = criteria.find((e) => e.type === type);
          if (crt) {
            switch (type) {
              case "Clinicians": {
                let name = crt.list?.find((d) => d.did === pair[1])?.name;
                if (name) return [type, name];
                return null;
              }

              case "Insurers": {
                let name = crt.list?.find((d) => d.iid === pair[1])?.name;
                if (name) return [type, name];
                return null;
              }

              case "Provider Types": {
                let name = crt.list?.find((d) => d.typeId === pair[1])?.name;
                if (name) return [type, name];
                return null;
              }
              default:
                return pair;
            }
          }
          return null;
        })
        .filter((e) => e);

      return pairs;
    }
    return [];
  }

  return (
    <div className="flex-1 flex flex-col  bg-gradient-to-b from-[#2d29ff44] to-[#7b79ff0f] rounded-3xl p-5 h-full">
      <h1 className="text-[#5754FF] font-semibold text-2xl text-center">
        {"Costs by Criteria"}
      </h1>
      <div
        style={{opacity: isfixedCost ? 0.5 : 1}}
        className="w-full flex justify-between h-[210px] overflow-hidden p-2 px-0 pt-2"
      >
        <SelecctionList
          selectedCriteria={selectedCriteria}
          setSelectedCriteria={setSelectedCriteria}
          criteria={criteria}
          creating={creating}
          isfixedCost={isfixedCost}
          setShowModal={setShowModal}
        />
        <DefinedCriteria
          selectedCriteria={selectedCriteria}
          setSelectedCriteria={setSelectedCriteria}
          costs={costs}
          setCosts={setCosts}
          creating={creating}
          isfixedCost={isfixedCost}
        />
      </div>
      <div className="flex flex-col justify-start flex-1 overflow-hidden w-full space-y-1 -mt-3">
        <span className="text-[12px] font-medium text-off ml-2">
          Customized Costs
        </span>
        <div className="border border-white  w-full rounded-xl overflow-hidden shadow-md flex-1 p-1">
          <ul className="sbar2 space-y-1 h-full overflow-y-scroll  sbar2 w-full p-2 pt-3">
            {Object.keys(costs).length === 0
              ? Array(3)
                  .fill(0)
                  .map((e, i) => (
                    <li
                      key={i}
                      className="rounded-md w-full bg-gray-100/80 h-7"
                    ></li>
                  ))
              : Object.entries(costs).map(([k, v], i) => (
                  <li
                    key={i}
                    className="relative rounded-md w-full  flex justify-center text-xs font-medium space-x-2"
                  >
                    <span
                      className="text-yellow-500 absolute bg-white -right-1 -top-1 border border-yellow-500 cursor-pointer  z-10 text-[16px] hover:text-red-500 hover:border-red-500 flex justify-center items-center h-5 w-5 rounded-full"
                      onClick={() => {
                        if (!creating)
                          setCosts((prev) => {
                            let a = {...prev};
                            delete a[k];
                            return a;
                          });
                      }}
                    >
                      <MdDeleteForever />
                    </span>
                    {criteriaFormatByKey(k, criteria).map(
                      ([type, value], ind) => (
                        <div
                          className="flex-1 p-1 flex items-center flex-col rounded-md bg-white border border-gray-200"
                          key={ind}
                        >
                          <p className=" text-[#5754FF]">{type}</p>
                          <p className="text-off">{value}</p>
                        </div>
                      )
                    )}
                    <div className="w-20 ">
                      <input
                        type="text"
                        className="text-center w-full drop-shadow block px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setCosts((prev) => ({
                              ...prev,
                              [k]:
                                e.target.value === ""
                                  ? 0
                                  : parseFloat(e.target.value),
                            }));
                          }
                        }}
                        value={v}
                      />
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

function DefinedCriteria({selectedCriteria, setCosts}) {
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedIdValues, setSelectedIdValues] = useState({});

  const [showList, setShowList] = useState({});

  useEffect(() => {
    setSelectedValues((prev) => {
      return [...selectedCriteria].reduce((ac, el) => {
        ac[el.type] = prev[el.type];
        return ac;
      }, {});
    });

    setSelectedIdValues((prev) => {
      return [...selectedCriteria].reduce((ac, el) => {
        ac[el.type] = prev[el.type];
        return ac;
      }, {});
    });

    setShowList((prev) => {
      return [...selectedCriteria].reduce((ac, el) => {
        ac[el.type] = prev[el.type];
        return ac;
      }, {});
    });
  }, [selectedCriteria]);

  function costFormatKey(data) {
    let en = Object.entries(data);
    en = en.map((el) => el.join("|"));
    en.sort();
    return en.join("||");
  }

  return (
    <div className="h-full w-full flex-1 overflow-hidden  flex flex-col text-left justify-between items-center pb-2">
      <span className="text-[12px] font-medium text-off self-start">
        Selected Criteria
      </span>
      <div className="flex-1 w-full  flex space-y-2  overflow-hidden border-2 border-slate-200 rounded-xl pr-1">
        <ul className="overflow-y-scroll rounded-xl  max-h-[150px] content-start flex items-start flex-wrap w-full sbar2 pb-2  p-1 px-2">
          {[...selectedCriteria].map((crit, i) => (
            <li
              onClick={(e, i) => {
                setShowList((prev) => ({[crit.type]: !prev[crit.type]}));
              }}
              key={i}
              className="relative [box-shadow:_0px_4px_4px_rgba(0,0,0,0.25)] mt-2 mr-2 hover:cursor-pointer bg-[#C1BFFF] rounded-md max-w-[8rem] min-w-[6rem]  flex-1 "
            >
              <div className=" flex justify-between items-center w-full h-full p-1  space-x-2">
                <p className="text-[#5754FF] text-[12px] leading-4  overflow-hidden  font-semibold flex flex-wrap">
                  {crit.type}
                </p>
                <span className="text-white text-lg">
                  <PiListMagnifyingGlass />
                </span>
              </div>
              <div className="text-white text-[12px] leading-4  overflow-hidden  font-semibold flex flex-wrap">
                {selectedValues[crit.type] && (
                  <span className="p-1 ">{selectedValues[crit.type]}</span>
                )}
              </div>
              {showList[crit.type] && (
                <OptionsList
                  criteria={crit}
                  setSelectedIdValues={setSelectedIdValues}
                  setSelectedValues={setSelectedValues}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="rounded-2xl  mt-3  text-background bg-[#C1BFFF] mx-2 shadow-md">
        <button
          type="button"
          className={
            "w-full hover:bg-[#5754FF] px-10 text-xs flex  py-1 justify-center items-center  hover:ring-0 rounded-full focus:outline-none border-none"
          }
          onClick={() => {
            let en = Object.entries(selectedIdValues).filter(([k, v]) => v);
            if (en.length > 0) {
              let values = Object.fromEntries(en);
              setCosts((prev) => {
                let key = costFormatKey(values);
                return prev.hasOwnProperty(key) ? prev : {...prev, [key]: 100};
              });
            }
          }}
          disabled={false}
        >
          Add Cost
        </button>
      </div>
    </div>
  );
}

function OptionsList({criteria, setSelectedValues, setSelectedIdValues}) {
  let list = [];
  let idList = [];
  switch (criteria.type) {
    case "Insurers":
      list = criteria.list?.map((d) => d.name);
      idList = criteria.list?.map((d) => d.iid);
      break;
    case "Clinicians":
      list = criteria.list?.map((d) => d.name);
      idList = criteria.list?.map((d) => d.did);
      break;
    case "Provider Types":
      list = criteria.list?.map((d) => d.name);
      idList = criteria.list?.map((d) => d.typeId);
      break;

    default:
      list = criteria.list;
      idList = criteria.list;
      break;
  }

  return (
    <div className="shadow-lg z-10 overflow-hidden absolute bg-white/80 rounded-xl p-1 h-20 w-full left-0  top-0 max-h-[12.5rem]">
      <ul className="overflow-y-scroll w-full sbar h-full divide-y-[1px] p-1 space-y-[1px]">
        {list.map((l, i) => (
          <li
            className="text=[#8F8CFF]  flex-wrap flex text-[#8F8CFF] font-semibold  pl-1 text-xs hover:bg-[#aaa4f3] shadow-sm hover:text-white cursor-pointer"
            onClick={(ev) => {
              // ev.stopPropagation();
              setSelectedValues((prev) => ({...prev, [criteria.type]: l}));
              setSelectedIdValues((prev) => ({
                ...prev,
                [criteria.type]: idList[i],
              }));
            }}
            key={i}
          >
            {l}
          </li>
        ))}
      </ul>
    </div>
  );
}

function SelecctionList({
  selectedCriteria,
  setShowModal,
  setSelectedCriteria,
  criteria,
  creating,
  isfixedCost,
}) {
  //flex flex-col justify-center items-center

  return (
    <div className="h-full w-[140px] pb-1  flex  flex-col text-left items-start">
      <span className="text-[12px] font-medium text-off">Criteria</span>
      <div className="w-[130px] overflow-hidden max-h-[180px]">
        <ul className="overflow-y-scroll  h-full  w-full sbar2  p-1 pl-0 space-y-1 ">
          {criteria.map((crit, i) => (
            <li
              onClick={() => {
                if (!creating && !isfixedCost) {
                  !selectedCriteria.has(crit)
                    ? setSelectedCriteria((prev) => {
                        prev.add(crit);
                        let n = new Set(prev);
                        return n;
                      })
                    : setSelectedCriteria((prev) => {
                        prev.delete(crit);
                        let n = new Set(prev);
                        return n;
                      });
                }
              }}
              key={i}
              className={`${
                !creating && !isfixedCost
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              } w-full flex items-center  p-1 py-0 px-0 space-x-1`}
            >
              <span
                className="flex shrink-0 justify-center h-5 w-5 rounded-full items-center"
                style={{
                  backgroundColor: selectedCriteria.has(crit)
                    ? "#7371ff"
                    : "#FFFFFF",
                }}
              ></span>

              <span className="font-semibold text-[1rem] text-[#5754FF]">
                {crit.type}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div
        className={
          "text-background bg-[#C1BFFF] hover:bg-[#5754FF]  my-2  shrink-0 cursor-pointer h-6 w-6 text-lg rounded-full shadow-md  flex  justify-center items-center"
        }
        onClick={() => {
          setShowModal(true);
        }}
      >
        <BsPlusCircle />
      </div>
    </div>
  );
}
