import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import {Button} from "@chakra-ui/react";
import Searchable from "./calendarElements/Searchable";

import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdAttachMoney,
  MdMoneyOff,
} from "react-icons/md";
import {PiArrowCircleDownFill} from "react-icons/pi";
import {VscGroupByRefType} from "react-icons/vsc";
//import AppointmentInformation from "./Clinic.AppointmentInformation";
import AppointmentBoard from "./AppointmentBoard";
import CalendarSartsAt from "./calendarElements/CalendarStrartAt";
import NextAvailableDate from "./calendarElements/NextAvailableDate";
//import UnavailableTimesModal from "./UnavailableModal";
import UnavailableTimesModal from "./ManageShifts";
import {
  startOfWeek,
  lightFormat,
  add,
  sub,
  differenceInMinutes,
  startOfDay,
  endOfDay,
  isSameDay,
} from "date-fns";
import {AiOutlineBars} from "react-icons/ai";
import {TbCalendarOff} from "react-icons/tb";
import BookAppointment from "./BookAppointment";
import {
  BsFillCalendarWeekFill,
  BsCalendar2X,
  BsPersonCircle,
} from "react-icons/bs";
import {
  filterConfirmed,
  filterUnconfirmed,
  filterRescheduled,
  filterCancelled,
  filterArrived,
  filterNoShow,
  filterByMonth,
  filterByWeek,
  filterByDay,
  mergeIntervals,
} from "../additional_files/helpers";
import {setToCurrentWeek} from "../additional_files/unavailable";
import SelectClinicians from "./calendarElements/SelectClinicians";
import DaySheet from "./calendarElements/DaySheet";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function Calendar(props) {
  let appointments = props.selectedDoctor ? props.appointments : [];
  let patients = props.selectedDoctor ? props.patients : [];
  const doctors = props.doctors
    ? props.doctors.sort((a, b) => {
        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
          return -1;
        }
        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
          return 1;
        }
        return 0;
      })
    : [];

  let {userType, selectedDoctor, dispatch, doctorsPayload, schState, socket} =
    props;

  const [date, setDate] = useState(new Date());
  const [colorCriteria, setColorCriteria] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showUnavailableTimesModal, setShowUnavailableTimesModal] =
    useState(false);

  console.log(showUnavailableTimesModal);
  const [apptPosition, setApptPosition] = useState(true);
  const [doctorsPointer, setDoctorPointer] = useState(0);
  const [bookRef, setBookRef] = useState(false);

  const [deltaY, setDeltaY] = useState({value: 0});
  const [view, setView] = useState("day");
  const [showViewPicker, setShowViewPicker] = useState(false);
  const [showColorLegend, setShowColorLegend] = useState(false);
  const gridRef = useRef(null);

  const [filterObj, setFilterObj] = useState({
    confirmed: true,
    unconfirmed: true,
    rescheduled: true,
    cancelled: true,
    noShow: true,
    arrived: true,
  });

  const serviceColorMap = useMemo(() => {
    if (schState?.services.length > 0) {
      return schState?.services.reduce((ac, e) => {
        ac[e.serviceId] = e.color || null;
        return ac;
      }, {});
    }
    return null;
  }, [schState?.services]);

  useLayoutEffect(() => {
    setDoctorPointer(0);
  }, [doctors.length]);

  useEffect(() => {
    let id = setTimeout(() => {
      if (deltaY.value > 0) {
        setDoctorPointer((p) => {
          if (doctors.length - (p + 7) >= 7) {
            return p + 7;
          } else if (doctors.length > 7) {
            return doctors.length - (p + 7);
          }

          return p;
        });
      } else if (deltaY.value < 0) {
        setDoctorPointer((p) => {
          if (p - 7 >= 0) return p - 7;
          return 0;
        });
      }
    }, 200);
    return () => clearTimeout(id);
  }, [deltaY]);

  useEffect(() => {
    let f = (e) => {
      setShowDatePicker(false);
      setShowColorLegend(false);
    };
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, []);

  useLayoutEffect(() => {
    if (!selectedDoctor) {
      view === "month" && setView("week");
    }
  }, [selectedDoctor]);

  function setGridRef(ref) {
    gridRef.current = ref;
  }
  function selectAppointment(appt) {
    props.selectAppointment(appt);
    // setAppointmentRef(appt);
  }
  useLayoutEffect(() => {
    if (props.closeModals) {
      selectAppointment(false);
    }
  }, [props.closeModals]);

  //console.log(appointmentRef)

  let doctorsFilterMap = useMemo(() => {
    let map = {};
    for (let i = doctorsPointer; i < doctorsPointer + 7; i++)
      if (doctors[i]) map[doctors[i].did] = i - doctorsPointer;
    return map;
  }, [doctorsPointer, schState.selectedLocation, doctors]);

  function filterAppointments(obj) {
    let filterMap = {
      noShow: filterNoShow,
      cancelled: filterCancelled,
      arrived: filterArrived,
      confirmed: filterConfirmed,
      rescheduled: filterRescheduled,
      unconfirmed: filterUnconfirmed,
    };

    let rawAppointments = selectedDoctor
      ? appointments
      : schState?.appointments || [];
    // Object.values(doctorsPayload||{}).reduce((ac,e)=>ac.concat(e.appointments),[])

    let selectedFilters = Object.entries(obj)
      .filter(([key, value]) => value)
      .map(([key, value]) => filterMap[key]);

    let filteredList = rawAppointments.filter((appt) => {
      if (appt?.cancelled && !appt?.allowCharging) return false;
      if (!schState?.selectedClinicians.has(appt?.did)) return false;

      if (
        schState?.selectedPatient &&
        schState?.selectedPatient.pid !== appt.pid
      )
        return false;
      for (let filter of selectedFilters) {
        if (filter([appt]).length > 0) {
          return true;
        }
      }
      return false;
    });

    let appointmentsToShow = [];

    //console.log(filteredList);

    switch (view) {
      case "day":
        {
          appointmentsToShow = filterByDay(date, filteredList);
        }
        break;
      case "week":
        {
          appointmentsToShow = filterByWeek(date, filteredList);
        }
        break;

      case "month":
        {
          appointmentsToShow = filterByMonth(date, filteredList);
        }
        break;

      default:
        {
          appointmentsToShow = filterByWeek(date, filteredList);
        }
        break;
    }
    if (!selectedDoctor && doctorsPayload) {
      appointmentsToShow = appointmentsToShow.filter((appt) => {
        return doctorsFilterMap[appt.did] !== undefined;
      });
    }

    return appointmentsToShow;
  }

  function filterUnavailableSlots() {
    let a = [];
    //console.log(doctorsPayload)

    if (!selectedDoctor) {
      Object.keys(doctorsFilterMap).forEach((did) => {
        let u = (doctorsPayload[did]?.unavailable?.untilRemoved || []).map(
          ([start, end]) => {
            return [setToCurrentWeek(date, start), setToCurrentWeek(date, end)];
          }
        );

        (doctorsPayload[did]?.unavailable?.intervals || [])
          .concat(u)
          .forEach((interval) => {
            if (isSameDay(interval[0], interval[1]))
              a.push({did, interval, ISOdate: interval[0]});
            else {
              a.push({
                did,
                interval: [interval[0], endOfDay(interval[0])],
                ISOdate: interval[0],
              });
              a.push({
                did,
                interval: [startOfDay(interval[1]), interval[1]],
                ISOdate: interval[1],
              });
            }
          });
      });
    } else {
      let u = (selectedDoctor.unavailable.untilRemoved || []).map(
        ([start, end]) => {
          return [setToCurrentWeek(date, start), setToCurrentWeek(date, end)];
        }
      );
      (selectedDoctor.unavailable.intervals || [])
        .concat(u)
        .forEach((interval) => {
          if (isSameDay(interval[0], interval[1]))
            a.push({did: selectedDoctor.did, interval, ISOdate: interval[0]});
          else {
            a.push({
              did: selectedDoctor.did,
              interval: [interval[0], endOfDay(interval[0]).getTime()],
              ISOdate: interval[0],
            });
            a.push({
              did: selectedDoctor.did,
              interval: [startOfDay(interval[1]).getTime(), interval[1]],
              ISOdate: interval[1],
            });
          }
        });
    }

    switch (view) {
      case "day":
        {
          a = filterByDay(date, a);
        }
        break;
      case "week":
        {
          a = filterByWeek(date, a);
        }
        break;

      default:
        {
          a = filterByWeek(date, a);
        }
        break;
    }

    return a;
  }

  let unavailableSlots = useMemo(filterUnavailableSlots, [
    doctorsPointer,
    schState,
    view,
    date,
  ]);

  let appointmentsToShow = filterAppointments(filterObj);
  function boardProps() {
    return {
      dispatch,
      date,
      showDatePicker,
      setShowDatePicker,
      view,
      setView,
      selectedDoctor,
      doctorsPointer,
      doctors,
      doctorsFilterMap,
      setDate,
      appointmentsToShow,
      appointmentsByDoctor: appointments,
      patients,
      setAppointmentRef: selectAppointment,
      setApptPosition,
      setGridRef,
      schState,
      unavailableSlots,
      setBookRef,
      socket: socket,
      colorSetter: colorSetter({colorCriteria, serviceColorMap}),
      colorCriteria: colorCriteria,
    };
  }

  return (
    <div className="flex h-full flex-col box-border w-full relative">
      <header className="relative z-50 flex flex-none justify-between items-end border-b border-b-gray-200 pb-2 h-16">
        <div className="flex items-end space-x-2">
          <div className="w-[175px]  self-center">
            <h1 className="text-[17px] text-start font-semibold leading-6 text-off">
              <time dateTime="2022-01-22" className="sm:hidden">
                {months[date.getMonth()].slice(0, 3)} {date.getDate()},{" "}
                {date.getFullYear()}
              </time>
              <time dateTime="2022-01-22" className="hidden sm:inline">
                {months[date.getMonth()]} {date.getDate()}, {date.getFullYear()}{" "}
                &nbsp;{" "}
                <span className="hidden 2xl:block text-off text-sm font-light">
                  {weekday[date.getDay()]}
                </span>
              </time>
            </h1>
          </div>

          <div className="flex items-center">
            {!(view === "day" && selectedDoctor) ? (
              <span
                onClick={(e) => {
                  setShowDatePicker((prev) => !prev);
                  e.stopPropagation();
                }}
                className={
                  showDatePicker
                    ? " hover:cursor-pointer text-off text-2xl hover:text-red-400 rounded-sm"
                    : " hover:cursor-pointer hover:text-indigo-400 text-2xl bg-white rounded-sm text-gray-400"
                }
              >
                {showDatePicker ? <BsCalendar2X /> : <BsFillCalendarWeekFill />}
              </span>
            ) : null}

            <div className="ml-4 relative w-[7.5rem]  h-9 drop-shadow border border-gray-200 rounded-md flex justify-between items-center shadow-sm bg-white p-1 py-2  overflow-hidden">
              <button
                type="button"
                className="flex items-center justify-center bg-white  text-gray-400 hover:text-gray-500 focus:relative focus:outline-none "
                onClick={() => {
                  setDate((prev) => sub(prev, {days: 1}));
                }}
              >
                <span className="sr-only">Previous day</span>
                <MdOutlineArrowBackIos
                  className="text-[1rem]"
                  aria-hidden="true"
                />
              </button>
              <button
                type="button"
                className="hidden border-gray-300 bg-white text-sm font-semibold  text-off  hover:bg-gray-50 hover:text-gray-800 focus:outline-none focus:relative md:block"
                onClick={() => {
                  setDate(new Date());
                }}
              >
                Today
              </button>
              <span className="relative w-[7.5rem] -mx-px h-5  bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex items-center justify-center bg-white   text-off hover:text-gray-800  focus:outline-none "
                onClick={() => {
                  setDate((prev) => add(prev, {days: 1}));
                }}
              >
                <span className="sr-only">Next day</span>
                <MdOutlineArrowForwardIos
                  className="text-[1rem]"
                  aria-hidden="true"
                />
              </button>
            </div>

            <div
              className="ml-2 h-9 relative flex justify-between items-center drop-shadow border border-gray-200 rounded-md shadow-sm bg-white p-2 pr-1"
              onClick={(e) => {
                setShowViewPicker((prev) => !prev);
              }}
            >
              <button
                type="button"
                className="w-24 whitespace-nowrap text-sm text-left pl-2 font-semibold  text-off  focus:outline-none md:block"
              >
                {view === "day"
                  ? "Day view"
                  : view === "week"
                  ? "Week view"
                  : "Month view"}
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex items-center  justify-center  text-off hover:text-gray-8 focus:outline-none "
              >
                <span className="sr-only">Next day</span>
                <PiArrowCircleDownFill
                  className="h-5 w-5 text-off0 hover:text-gray-800"
                  aria-hidden="true"
                />
              </button>
              {showViewPicker && (
                <ViewPicker
                  setView={setView}
                  setShowViewPicker={setShowViewPicker}
                  selectedDoctor={selectedDoctor}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex space-x-2 mx-2 items-end">
          {schState.doctors && <SelectClinicians {...{schState, dispatch}} />}
          <Searchable
            {...{
              schState,
              dispatch,
              role: "Patients",
              appointmentsToShow,
              gridRef,
              setDate,
              setView,
            }}
          />
        </div>

        <div className="space-x-2 flex">
          {schState.selectedDoctor && (
            <NextAvailableDate {...{schState, dispatch, setDate}} />
          )}
          <CalendarSartsAt {...{schState, dispatch}} />
        </div>
      </header>

      <div className="bg-off bg-opacity-[3%] relative flex justify-between p-2 pt-0  text-sm border-x border-b border-b-gray-200">
        <div className="flex space-x-4">
          {/*<p
            className={`${
              colorCriteria === "biling_status" ? "text-[#10b981]" : "text-off"
            }`}
            onClick={() => {
              colorCriteria === "biling_status"
                ? setColorCriteria("")
                : setColorCriteria("biling_status");
            }}
          >
            <span className="text-xs cursor-pointer">Billing Status</span>
            {
              <span className="hover:cursor-pointer text-[25px] bg-white rounded-sm">
                {colorCriteria === "biling_status" ? (
                  <MdAttachMoney className="ml-5" />
                ) : (
                  <MdMoneyOff className="ml-5" />
                )}
              </span>
            }
          </p>*/}
          <div
            className={`${
              colorCriteria === "sevice_type" ? "text-gray-800" : "text-off"
            } flex flex-col relative justify-center items-center`}
          >
            {showColorLegend && <ServicesLegend services={schState.services} />}
            <span className="text-xs cursor-pointer">Service Type</span>
            <div className=" flex justify-center items-center space-x-2">
              <span
                className="hover:cursor-pointer text-[25px] bg-white rounded-sm"
                onClick={() => {
                  colorCriteria === "sevice_type"
                    ? setColorCriteria("")
                    : setColorCriteria("sevice_type");
                }}
              >
                <VscGroupByRefType />
              </span>

              {colorCriteria === "sevice_type" && (
                <span
                  className="hover:cursor-pointer show  text-[25px] bg-white rounded-sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowColorLegend(!showColorLegend);
                  }}
                >
                  <AiOutlineBars />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className=" flex flex-col justify-center flex-1 space-y-2 items-center">
          <p className=" top-2 text-off text-xs">Appointment Filters</p>
          <div className="space-x-4 flex justify-center items-center">
            <p
              onClick={() =>
                setFilterObj((prev) => ({...prev, confirmed: !prev.confirmed}))
              }
              className="relative whitespace-nowrap hover:cursor-pointer flex justify-center items-center space-x-[0.4rem] text-[#5754FF] font-semibold"
            >
              <span
                className={
                  filterObj.confirmed
                    ? "w-[17px] h-[17px] rounded-md inline-block bg-[#5754FF]"
                    : "w-[17px] h-[17px] rounded-md inline-block border-2 border-[#5754FF]"
                }
              ></span>
              <span className="inline-block relative">Confirmed</span>
            </p>

            <p
              onClick={() =>
                setFilterObj((prev) => ({
                  ...prev,
                  unconfirmed: !prev.unconfirmed,
                }))
              }
              className="whitespace-nowrap hover:cursor-pointer flex justify-center items-center space-x-[0.4rem] text-[#8F8CFF] font-semibold"
            >
              <span
                className={
                  filterObj.unconfirmed
                    ? "w-[17px] h-[17px] rounded-md inline-block bg-[#8F8CFF]"
                    : "w-[17px] h-[17px] rounded-md inline-block border-2 border-[#8F8CFF]"
                }
              ></span>
              <span className="inline-block relative">Unconfirmed</span>
            </p>

            <p
              onClick={() =>
                setFilterObj((prev) => ({
                  ...prev,
                  rescheduled: !prev.rescheduled,
                }))
              }
              className="whitespace-nowrap hover:cursor-pointer flex justify-center items-center space-x-[0.4rem] text-[#FF9900] font-semibold"
            >
              <span
                className={
                  filterObj.rescheduled
                    ? "w-[17px] h-[17px] rounded-md inline-block bg-[#FF9900]"
                    : "w-[17px] h-[17px] rounded-md inline-block border-2 border-[#FF9900]"
                }
              ></span>
              <span className="inline-block relative">Rescheduled</span>
            </p>
            {/*<p
              onClick={() =>
                setFilterObj((prev) => ({...prev, cancelled: !prev.cancelled}))
              }
              className="whitespace-nowrap hover:cursor-pointer flex justify-center items-center space-x-[0.4rem] text-[#FF0000] font-semibold"
            >
              <span
                className={
                  filterObj.cancelled
                    ? "w-[17px] h-[17px] rounded-md inline-block bg-[#FF0000]"
                    : "w-[17px] h-[17px] rounded-md inline-block border-2 border-[#FF0000]"
                }
              ></span>
              <span className="inline-block relative">Cancelled</span>
            </p>*/}
            <p
              onClick={() =>
                setFilterObj((prev) => ({...prev, noShow: !prev.noShow}))
              }
              className="whitespace-nowrap hover:cursor-pointer flex justify-center items-center space-x-[0.4rem] text-[#000000] font-semibold"
            >
              <span
                className={
                  filterObj.noShow
                    ? "w-[17px] h-[17px] rounded-md inline-block bg-[#000000]"
                    : "w-[17px] h-[17px] rounded-md inline-block border-2 border-[#000000]"
                }
              ></span>
              <span className="inline-block relative">No Show</span>
            </p>
            <p
              onClick={() =>
                setFilterObj((prev) => ({...prev, arrived: !prev.arrived}))
              }
              className="whitespace-nowrap hover:cursor-pointer flex justify-center items-center space-x-[0.4rem] text-[#2dd4bf] font-semibold"
            >
              <span
                className={
                  filterObj.arrived
                    ? "w-[17px] h-[17px] rounded-md inline-block bg-[#2dd4bf]"
                    : "w-[17px] h-[17px] rounded-md inline-block border-2 border-[#2dd4bf]"
                }
              ></span>
              <span className="inline-block relative">Arrived</span>
            </p>
          </div>
        </div>
        <div className="flex items-end space-x-4">
          <DaySheet schState={schState} date={date} />

          <Button
            ml="4"
            size={"sm"}
            leftIcon={<TbCalendarOff />}
            onClick={() => {
              setShowUnavailableTimesModal(true);
            }}
            colorScheme="blue"
            variant="ghost"
          >
            Manage Shifts
          </Button>
        </div>
      </div>

      {(view === "day" || view === "month") && selectedDoctor ? null : (
        <div
          className="border-x bg-gray-50 px-14 w-full flex items-stretch relative"
          onWheel={(e) => {
            !props.selectedDoctor &&
              doctors.length > 7 &&
              setDeltaY({value: e.deltaY});
          }}
        >
          {!props.selectedDoctor && doctors.length > 0 && (
            <>
              <span
                className="absolute left-0 h-full w-14 text-xs text-gray-400 hover:cursor-pointer hover:text-indigo-500 flex justify-center items-center"
                onClick={() => {
                  if (doctors.length > 7)
                    setDoctorPointer((p) => {
                      if (p - 7 >= 0) return p - 7;
                      return 0;
                    });
                }}
              >
                <MdOutlineArrowBackIos className="h-8 w-8" />
              </span>
              <span
                className="absolute right-0 h-full w-14 text-xs text-gray-400 hover:cursor-pointer hover:text-indigo-500 flex justify-center items-center"
                onClick={() => {
                  if (doctors.length > 7)
                    setDoctorPointer((p) => {
                      if (doctors.length - (p + 7) >= 7) {
                        return p + 7;
                      } else if (doctors.length > 7) {
                        return doctors.length - (p + 7);
                      }

                      return p;
                    });
                }}
              >
                <MdOutlineArrowForwardIos className="h-8 w-8" />
              </span>
            </>
          )}

          {!props.selectedDoctor ? (
            doctors.length === 0 ? null : (
              <SetDoctorsList
                {...{doctors, doctorsPointer, dispatch, setDoctorPointer}}
              />
            )
          ) : (
            <SetDaysOfWeek {...{date, setDate}} />
          )}
        </div>
      )}
      {/*(!props.selectedDoctor && view === "day" ? (
        <UnavaibleLists doctorsPayload={doctorsPayload} doctorsFilterMap={doctorsFilterMap} dispatch={dispatch} jwt={schState?.jwt} date={date} />
      ) : null)*/}

      <AppointmentBoard {...boardProps()} />
      {/*appointmentRef && (
        <AppointmentInformation
          {...{
            dispatch,
            apptPosition,
            selectedDoctor,
            gridRef,
            view,
            doctors,
            schState,
            userType,
            setBookRef,
          }}
          identifier={appointmentRef}
          jwt={props.jwt}
          appointments={appointments}
          stateChanger={selectAppointment}
        />
      )*/}

      {showUnavailableTimesModal && (
        <UnavailableTimesModal
          schState={schState}
          stateChanger={setShowUnavailableTimesModal}
          isOpen={showUnavailableTimesModal}
          dispatch={dispatch}
          date={date}
        />
      )}
      {bookRef && (
        <BookAppointment
          schState={schState}
          stateChanger={setBookRef}
          dispatch={dispatch}
          defaultData={bookRef}
        />
      )}
    </div>
  );
}

function SetDoctorsList({doctors, doctorsPointer, dispatch, setDoctorPointer}) {
  let listOfDoctors = Array(7).fill(0);
  return listOfDoctors.map((e, i) => (
    <div
      onClick={() =>
        dispatch({
          type: "SELECT_DOCTOR",
          payload: doctors[doctorsPointer + i],
        })
      }
      key={i}
      className="hover:cursor-pointer flex-1  space-x-1 py-1 border-l last:border-r flex justify-center"
    >
      {doctors[doctorsPointer + i] ? (
        <div className="[box-shadow:_0px_4px_4px_rgba(0,0,0,0.25)] hover:cursor-pointer bg-[#7270FF] w-full h-full flex mx-[2px]  items-center rounded-md p-2 space-x-1 overflow-hidden">
          <span className="flex justify-center h-7 w-7 items-center flex-none">
            {doctors[doctorsPointer + i].photo ? (
              <img
                src={doctors[doctorsPointer + i].photo}
                alt=""
                className="hover:cursor-pointer hover:bg-[] h-full rounded-md drop-shadow-lg"
              />
            ) : (
              <BsPersonCircle className="text-[1.5rem] text-white" />
            )}
          </span>
          <div className="flex">
            <p className="ml-1 text-white text-[12px] leading-3 text-start font-medium ">
              {`${doctors[doctorsPointer + i].lastName}, ${
                doctors[doctorsPointer + i].firstName
              }`}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  ));
}

function colorSetter({state, colorCriteria, serviceColorMap}) {
  switch (colorCriteria) {
    case "biling_status":
      return (appt) => {
        let otbp =
          parseFloat(appt?.patientAmount ?? 0) -
          parseFloat(appt?.amountPaidByPatient ?? 0);

        let otbi =
          parseFloat(appt?.tppAmount ?? 0) -
          parseFloat(appt?.amountPaidByTpp ?? 0);

        if (otbp <= 0 && otbi <= 0) return "#10b981";
        if (otbp <= 0 || appt.received) return "#9FE2BF";
        return "#7F7B91";
      };

    case "sevice_type": {
      return (appt) => serviceColorMap?.[appt.serviceId] || null;
    }

    default:
      return null;
  }
}

function SetDaysOfWeek({date, setDate}) {
  let listOfWeekDays = [];
  let start = startOfWeek(date, {weekStartsOn: 1});
  for (let i = 0; i < 7; i++)
    listOfWeekDays.push(
      <div
        onClick={() => setDate(add(start, {days: i}))}
        key={i}
        className="hover:cursor-pointer flex-1 space-x-1 py-1 border-l last:border-r flex justify-center items-center"
      >
        <span>{weekday[(i + 1) % 7].slice(0, 3)}</span>
        <span
          className={
            date.getDate() === add(start, {days: i}).getDate()
              ? "text-white font-bold p-2 w-8 h-8 flex justify-center items-center rounded-[50%] bg-indigo-700"
              : "text-black font-bold p-1 flex-none"
          }
        >
          {add(start, {days: i}).getDate()}
        </span>
      </div>
    );
  return listOfWeekDays;
}

function ViewPicker({setView, setShowViewPicker, selectedDoctor}) {
  return (
    <div className="absolute z-[9999] left-0 overflow-hidden  top-[110%] w-[100%] rounded-md border border-gray-300 bg-white">
      <ul className=" text-start divide-y">
        <li
          onClick={(e) => {
            setView("day");
            e.stopPropagation();
            setShowViewPicker(false);
          }}
          className="pl-2 py-1 hover:cursor-pointer text-sm font-medium  hover:bg-gray-100 hover: text-off "
        >
          Day view
        </li>
        <li
          onClick={(e) => {
            setView("week");
            e.stopPropagation();
            setShowViewPicker(false);
          }}
          className="pl-2 py-1 hover:cursor-pointer text-sm font-medium  hover:bg-gray-100 hover: text-off "
        >
          Week view
        </li>
        {selectedDoctor && (
          <li
            onClick={(e) => {
              setView("month");
              e.stopPropagation();
              setShowViewPicker(false);
            }}
            className="pl-2 py-1 hover:cursor-pointer text-sm font-medium hover:bg-gray-100 hover: text-off "
          >
            Month view
          </li>
        )}
      </ul>
    </div>
  );
}

function ServicesLegend({services}) {
  return (
    <div className="max-h-[10rem] min-w-[10rem] max-w-[20rem] z-[999] p-2 flex pr-1 flex-col overflow-hidden  absolute top-0 left-[102%]  bg-white drop-shadow border border-gray-200 rounded-md">
      <ul className="flex-1 overflow-auto  sbar2 space-y-1 text-[12px] text-off font-medium flex flex-col">
        {services?.map((e, i) => (
          <li
            className=" text-[11px]  text-start px-1 flex items-center space-x-1"
            key={i}
          >
            <span
              className="flex justify-center shrink-0 items-center h-5 w-5 border-2  bg-white rounded-md text-off"
              style={{backgroundColor: e.color || "#64748b"}}
            ></span>
            <span>{`${e.serviceName}`}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
