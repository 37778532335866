import React, {useState, useEffect, useRef, useReducer} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForAdminLogin, loadDataForDoctorLogin} from "../crudRequests";
import Navbar from "../components/Navbar";
import InstanceBar from "../components/InstanceBar";
import {scheduleReducer, init} from "../additional_files/reducer";
import ToastNotification from "../components/toastNotification/ToastNotification";

export default function Patients(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const loadedRef = useRef(null);
  const [loading, setLoading] = useState();
  const [schState, dispatch] = useReducer(scheduleReducer, state, init);
  const sessionStart = useRef(true);
  const [prevState, setPrevstate] = useState(state);

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  //console.log(schState.selectedLocation)
  useEffect(() => {
    ///console.log(state, 'EN FETCH')
    const abortController = new AbortController();
    if (!sessionStart.current) {
      dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      if (schState.userType === "admin")
        loadDataForAdminLogin(
          {
            jwt: schState.jwt,
            selectedLocation: schState.selectedLocation,
            admin: schState.admin,
            destination: "../admin",
            db: schState.db,
            organization: schState.organization,
          },
          navigate,
          null,
          abortController.signal
        );
      else
        loadDataForDoctorLogin(
          {
            jwt: schState.jwt,
            selectedLocation: schState.selectedLocation,
            doctor: schState.doctor,
            destination: "../admin",
            db: schState.db,
            organization: schState.organization,
          },
          navigate,
          null,
          abortController.signal
        );
    }

    return () => {
      abortController.abort();
      dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
    };
  }, [schState.selectedLocation, dispatch]);
  useEffect(() => {
    sessionStart.current = false;
  }, []);

  let admAccessSections = schState.admin?.access?.admAccessSections || {
    billing: true,
    permissions: true,
    forms: true,
    services: true,
    clinicians: true,
    locations: true,
    insurers: true,
  };
  let docAccessSections = {
    billing: true,
    forms: true,
    services: true,
  };

  //console.log(admAccessSections)

  return (
    <div className="relative w-full h-full flex flex-col ">
      <ToastNotification appointments={schState.appointments || []} />
      <div
        className="absolute top-6 z-[60] right-12 bg-light opacity-75 py-2 px-[9px] rounded-md cursor-pointer"
        onClick={logoutUser}
      >
        <img src="/images/Doctor/login.png" alt="" className="w-6" />
      </div>
      <Navbar state={{...schState}} />
      <InstanceBar
        {...{
          dispatch,
          schState,
        }}
      />
      {schState.changingLocation && (
        <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}
      <div
        ref={loadedRef}
        style={{opacity: 0}}
        className="relative flex flex-1 ml-28  overflow-hidden sbar3"
      >
        {/*<FormBuilder />*/}
        <div className="relative  grid grid-cols-2 gap-4 py-10 px-56 overflow-y-scroll w-full mb-5">
          {(schState?.admin
            ? admAccessSections.billing
            : docAccessSections.billing) && (
            <div
              className="flex justify-center items-center cursor-pointer bg-light bg-opacity-50 text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/billing", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Billing
            </div>
          )}

          {(schState?.admin
            ? admAccessSections.permissions
            : docAccessSections.permissions) && (
            <div
              className="flex justify-center items-center cursor-pointer bg-med text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/permissions", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Permissions
            </div>
          )}
          {(schState?.admin
            ? admAccessSections.forms
            : docAccessSections.forms) && (
            <div
              className="flex justify-center items-center cursor-pointer bg-med text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/forms", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Forms
            </div>
          )}
          {/* TODO: add protection to this route */}
          <div
            className="flex justify-center items-center cursor-pointer bg-med text-white text-5xl rounded-md text-center p-8 py-12"
            onClick={() => {
              navigate("/faxes", {
                state: {
                  ...schState,
                },
              });
            }}
          >
            Faxes
          </div>
          {(schState?.admin
            ? admAccessSections.services
            : docAccessSections.services) && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark bg-opacity-60 text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/services", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Services
            </div>
          )}
          {schState?.admin && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark bg-opacity-60 text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/products", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Products
            </div>
          )}
          {schState?.admin && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark bg-opacity-80 text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/provider_types", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Provider Types
            </div>
          )}

          {(schState?.admin
            ? admAccessSections.clinicians
            : docAccessSections.clinicians) && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark bg-opacity-90 text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/clinicians", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Clinicians
            </div>
          )}
          {(schState?.admin
            ? admAccessSections.locations
            : docAccessSections.locations) && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/locations", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Locations
            </div>
          )}
          {(schState?.admin
            ? admAccessSections.insurers
            : docAccessSections.insurers) && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/insurers", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Insurers
            </div>
          )}
          {schState?.admin?.super && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/clinic", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Clinic
            </div>
          )}
          {schState?.admin?.super && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/rooms", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Rooms
            </div>
          )}
          {schState?.admin?.super && (
            <div
              className="flex justify-center items-center cursor-pointer bg-dark text-white text-5xl rounded-md text-center p-8 py-12"
              onClick={() => {
                navigate("/memberships", {
                  state: {
                    ...schState,
                  },
                });
              }}
            >
              Memberships
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
