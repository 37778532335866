import {DeleteIcon} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, {useRef, useState} from "react"
import {deleteTask} from "./helpers/deleteTask";

export default function DeleteTask({
  taskId,
  dashState,
  setTasks,
  toast
}) {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const cancelRef = useRef()

  return (
    <>
      <Tooltip hasArrow label="Delete" rounded={"sm"}>
        <IconButton
          colorScheme="red"
          icon={<DeleteIcon/>}
          isLoading={isLoading}
          size='sm'
          m={1}
          onClick={onOpen}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Task
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme='red'
                isLoading={isLoading}
                onClick={async() => {
                  setIsLoading(true)
                  await deleteTask(
                    taskId,
                    dashState,
                    setTasks,
                    toast
                  )
                  setIsLoading(false)
                  onClose()
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
