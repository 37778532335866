import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect, useRef} from "react";
import {Icon, Box, Flex, Button, useToast, useDisclosure} from "@chakra-ui/react";
import PreviewForm from "./PreviewForm";
import AssignForm from "./AssignForm";
import {RiDatabase2Line} from "react-icons/ri";
import FormMetadataModal from "./FormMetadataModal";
import AssignedAndCompletedForms from "./AssignedAndCompletedForms";
import {fetchForms} from "./helpers/fetchForms";
import {MdDelete} from "react-icons/md";
import {deleteForm} from "./helpers/deleteForm";
import {AlertDialogComponent} from "../../AlertDialogComponent";
//import FormsPDF from "./FormsPDF";

export default function Forms() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();

  const [forms, setForms] = useState([]);
  const [assignedForms, setAssignedForms] = useState([]);
  const [completedForms, setCompletedForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [formMetadata, setFormMetadata] = useState(null);
  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef = useRef()
  //const [viewPDF, setViewPDF] = useState(null);

  useEffect(() => {
    fetchForms(
      setForms,
      setAssignedForms,
      setCompletedForms,
      dashState,
      patient,
      toast
    );
  }, []);

  return (
    <Box mx="auto" my="6" pb="12" px="8" boxShadow="md" bg="white">
      <Flex>
        <Box>
          <AssignedAndCompletedForms
            completedForms={completedForms}
            setSelectedForm={setSelectedForm}
            assignedForms={assignedForms}
          />
          <AssignForm forms={forms} setAssignedForms={setAssignedForms} />
        </Box>
        <Box ml="10">
          {selectedForm && (
            <Box>
              <Box textAlign={"right"} mb="4">
                {!selectedForm.completed &&
                  <AlertDialogComponent
                    btnMessage="Delete"
                    rightIcon={<Icon as={MdDelete} ml="1"/>}
                    leftIcon={null}
                    title=""
                    deleteFunction={() => deleteForm(dashState, selectedForm.fsid, setAssignedForms, toast, setSelectedForm)}
                  />
                }
                <Button
                  onClick={() => setFormMetadata(selectedForm)}
                  colorScheme="blue"
                  variant="ghost"
                >
                  View Metadata <Icon as={RiDatabase2Line} ml="1" />
                </Button>
                {/*<Button
                onClick={() => setViewPDF(selectedForm)}
                colorScheme="blue"
                variant="ghost"
              >
                View PDF <Icon as={FaRegFilePdf} ml="1" />
              </Button>*/}
              </Box>
              <PreviewForm
                completed={selectedForm?.completed}
                formFields={JSON.parse(selectedForm?.formFields)}
              />
            </Box>
          )}
          <FormMetadataModal
            formMetadata={formMetadata}
            setFormMetadata={setFormMetadata}
          />
          {/*<FormsPDF viewPDF={viewPDF} setViewPDF={setViewPDF} />*/}
        </Box>
      </Flex>
    </Box>
  );
}
