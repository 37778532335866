import {useState, useMemo} from "react";
import {Box, Heading} from "@chakra-ui/react";
import {generateGraphData} from "./helpers/chartFunctions";
import Chart from "react-apexcharts";
import SymptomsReport from "./SymptomsReport";

export default function JournalAnalytics({journals, state}) {
  const graphData = useMemo(() => generateGraphData(journals), [journals]);
  let data = graphData?.data;
  let counts = graphData?.counts;
  const [selectedDate, setSelectedDate] = useState(null);
  console.log(data);

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
      stacked: true,
      events: {
        markerClick: (event, chartContext, data) => {
          setSelectedDate(counts?.[data?.dataPointIndex]?.[0]);
        },
      },
    },
    colors: ["#00E396", "#E53E3E", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    xaxis: {
      type: "datetime",
    },
  };

  return (
    <Box mx="auto" my="6" pb="6" px="8" bg="white">
      <Heading>Symptoms over time</Heading>
      {data && (
        <Chart options={chartOptions} series={data} type="area" height={350} />
      )}
      <SymptomsReport
        journal={
          journals?.find((journal) => journal?.date === selectedDate) || {}
        }
        state={state}
      />
    </Box>
  );
}
