import {viewFiles} from "./helpers/viewFiles";
import {GoHomeFill} from "react-icons/go";
import {Box, Icon, Avatar, Text, Flex} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext} from "react";

export default function Path({
  path,
  setFiles,
  setSelectedFile,
  toast,
  setPath,
  patient,
  state,
}) {
  const dashState = useContext(DashStateContext) ?? state;

  return (
    <Flex>
      {path &&
        path
          .split("/")
          .filter(Boolean)
          .map((folder, i) => {
            return (
              <Flex
                whiteSpace={"nowrap"}
                key={i}
                w="full"
                flexDirection={"row"}
                justify="flex-start"
              >
                <Text
                  as="span"
                  color="blue.500"
                  cursor={"pointer"}
                  onClick={() => {
                    const updatedPath = path.split(folder)[0] + folder + "/";
                    path == updatedPath
                      ? viewFiles(
                          dashState,
                          setFiles,
                          setSelectedFile,
                          path,
                          toast
                        )
                      : setPath(updatedPath);
                  }}
                >
                  {folder == patient.pid ? (
                    <>
                      <Avatar
                        size={"sm"}
                        mt="-1"
                        name={patient.fName + " " + patient.lName}
                      />
                      <Icon as={GoHomeFill} fontSize={"20px"} mt="-0.5" />
                    </>
                  ) : (
                    folder
                  )}
                </Text>
                <Text as="span" color="gray.300" mx="1">
                  /
                </Text>
              </Flex>
            );
          })}
    </Flex>
  );
}
