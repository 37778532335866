import {
  Button,
  FormControl,
  FormLabel,
  VStack,
  Grid,
  useToast,
  Box,
  Textarea,
  GridItem,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {useContext, useState} from "react";
import {updatePatient} from "./helpers/updatePatient";
import UnlimitedItemFields from "./Fields/UnlimitedItemField";

export default function MedicalHistory() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  return (
    <form
      onSubmit={(e) =>
        updatePatient(e, patient, dashState, setIsLoading, toast, dispatch)
      }
    >
      <VStack spacing={4}>
        <Box
          gap={14}
          textAlign={"center"}
          display={{base: "block", lg: "flex"}}
        >
          <UnlimitedItemFields
            label="Medications"
            items={patient.medications}
            setItems={(medications) => setPatient({...patient, medications})}
            fields={[
              {name: "name", placeholder: "Name", type: "text"},
              {name: "dosage", placeholder: "Dosage", type: "text"},
            ]}
          />

          <UnlimitedItemFields
            label="Allergies"
            items={patient.allergies}
            setItems={(allergies) => setPatient({...patient, allergies})}
            fields={[
              {name: "name", placeholder: "Name", type: "text"},
              {name: "reaction", placeholder: "Reaction", type: "text"},
            ]}
          />
        </Box>

        <Box
          gap={14}
          textAlign={"center"}
          display={{base: "block", lg: "flex"}}
        >
          <UnlimitedItemFields
            label="Pre-diagnosed Medical Diseases"
            items={patient.preDiagnosedMedicalConditions}
            setItems={(preDiagnosedMedicalConditions) =>
              setPatient({...patient, preDiagnosedMedicalConditions})
            }
            fields={[
              {name: "name", placeholder: "Name", type: "text"},
              {name: "since", placeholder: "Since", type: "text"},
            ]}
          />

          <UnlimitedItemFields
            label="Pre-diagnosed Mental Health Disorders"
            items={patient.preDiagnosedMentalHealthDisorders}
            setItems={(preDiagnosedMentalHealthDisorders) =>
              setPatient({...patient, preDiagnosedMentalHealthDisorders})
            }
            fields={[
              {name: "name", placeholder: "Name", type: "text"},
              {name: "since", placeholder: "Since", type: "text"},
            ]}
          />
        </Box>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={6}
          mt="5"
        >
          <GridItem colSpan={4}>
            <FormControl>
              <FormLabel>Family Health/Mental Health Problems</FormLabel>
              <Textarea
                variant="flushed"
                placeholder="Father has had diabetes for 20 years. Aunt suffers from depression since childhood."
                value={patient.familyHealthProblems}
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    familyHealthProblems: e.target.value,
                  });
                }}
              ></Textarea>
            </FormControl>
          </GridItem>
        </Grid>
        <Button
          isLoading={isLoading && true}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Save
        </Button>
      </VStack>
    </form>
  );
}
