import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";
import AddTaskDrawer from "./AddTaskDrawer";
import {createHashTable} from "../../utils/createHashTable";
import {alphabeticalSort} from "./helpers/alphabeticalSort";
import FiltersTasks from "./FiltersTasks";
import GridTable from "./GridTable";
import {getTasks} from "./helpers/getTasks";
import {crud} from "../../crudRequests";

export const ButtonTasks = ({dashState, patient}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const doctors = useMemo(
    () => createHashTable(dashState.doctors ?? [dashState.doctor], "did"),
    [dashState.doctor, dashState.doctors]
  );
  const doctorsArr = useMemo(
    () =>
      alphabeticalSort(
        dashState.doctors ? [...dashState.doctors] : [dashState.doctor],
        "lastName"
      ),
    [dashState.doctor, dashState.doctors]
  );
  const patients = useMemo(
    () => createHashTable(dashState.patients, "pid"),
    [dashState.patients]
  );
  const patientsArr = useMemo(
    () =>
      alphabeticalSort(
        dashState.patients ? [...dashState.patients] : [],
        "lName"
      ),
    [dashState.patients]
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState({column: "due", direction: "asc"});
  const showCompletedRef = useRef(true);
  const [admins, setAdmins] = useState([]);

  const fetchTasks = async (
    isAppendingTasks = false,
    query = "",
    page = 1,
    showCompleted = true,
    column,
    order
  ) => {
    try {
      setIsLoading(true);
      const data = await getTasks(
        dashState,
        page,
        query,
        patient?.pid,
        showCompleted,
        column,
        order,
        35,
        6
      );
      isAppendingTasks
        ? setTasks((prevTasks) => [...prevTasks, ...data])
        : setTasks(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };

  const getAdmins = async (state) => {
    try {
      const requestObjs = [
        {
          db: state.db || "blh",
          collection: "admins",
          parameters: [
            {
              $project: {
                id: 1,
                name: 1,
                firstName: 1,
                lastName: 1,
              },
            },
          ],
          method: "aggregate",
        },
      ];

      const res = await crud(state, requestObjs, null, null);
      return res.data[0];
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  useEffect(() => {
    const fetchAdmins = async () => {
      if (dashState.doctors) {
        const admins = await getAdmins(dashState);
        admins.map((admin) => {
          if (!admin.lastName) {
            admin.lastName = admin.name.split(" ")[1] || "";
            admin.firstName = admin.name.split(" ")[0];
          }
          return admin;
        });

        setAdmins(alphabeticalSort(admins, "lastName"));
      }
    };
    fetchAdmins();
    fetchTasks();
  }, [dashState.selectedLocation]);

  const groupedOptions = useMemo(() => {
    if (doctorsArr.length === 1) {
      return doctorsArr.map((option) => ({
        value: option.did,
        label: `${option.lastName}, ${option.firstName}`,
      }));
    }

    return [
      {
        label: "Clinicians",
        options: doctorsArr.map((option) => ({
          value: option.did,
          label: `${option.lastName}, ${option.firstName}`,
        })),
      },
      {
        label: "Admins",
        options: admins.map((option) => ({
          value: option.id,
          label: `${option.lastName}, ${option.firstName}`,
        })),
      },
    ];
  }, [doctorsArr, admins]);

  return (
    <Box position="fixed" bottom="4" right="4" zIndex="docked">
      <Button
        leftIcon={<AddIcon />}
        colorScheme="blue"
        variant="solid"
        onClick={onOpen}
      >
        Task
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Tasks</DrawerHeader>
          <Tabs p={5} h={"100%"}>
            <TabList>
              <Tab>Add Task</Tab>
              <Tab>Tasks</Tab>
            </TabList>
            <TabPanels h={"80vh"}>
              <TabPanel p={0} mt={2}>
                <AddTaskDrawer
                  dashState={dashState}
                  patients={patients}
                  setTasks={setTasks}
                  patientId={patient?.pid}
                  doctors={doctors}
                  groupedOptions={groupedOptions}
                  onClose={onClose}
                  patientsArr={patientsArr}
                  admins={admins}
                />
              </TabPanel>
              <TabPanel h={"100%"} display={"flex"} flexDirection={"column"}>
                <Flex p="4" gap="8">
                  <FiltersTasks
                    fetchTasks={fetchTasks}
                    tasks={tasks}
                    sort={sort}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    showCompletedRef={showCompletedRef}
                    isPatient={true}
                  />
                </Flex>
                <GridTable
                  dashState={dashState}
                  patient={patient}
                  fetchTasks={fetchTasks}
                  searchQuery={searchQuery}
                  isLoading={isLoading}
                  tasks={tasks}
                  setTasks={setTasks}
                  sort={sort}
                  setSort={setSort}
                  doctors={doctors}
                  groupedOptions={groupedOptions}
                  patients={patients}
                  patientsArr={patientsArr}
                  showCompletedRef={showCompletedRef}
                  admins={admins}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
