import {crud} from "../../../crudRequests"

export const getTasks = async (
  state, 
  page = 1,
  searchQuery = '',
  patientId,
  showCompleted,
  sortColumn = 'due',
  sortOrder = 'asc',
  limit = 35,
  itemsPerPage = 7,
) => {
  sortOrder = sortOrder === 'asc' ? 1 : -1
  try {
    const { userType } = state
    let matchStage = { lid: state.selectedLocation }

    if (userType === 'doctor') {
      matchStage = {
        lid: state.selectedLocation,
        assignedToId: state.doctor.did
      }
    } else if (userType === 'admin' && !state.admin?.super) {
      matchStage = {
        lid: state.selectedLocation,
        createdById: state.admin.id
      }
    }

    if (searchQuery) {
      matchStage.task = { $regex: searchQuery, $options: 'i' }
    }

    if (patientId) {
      matchStage.pid = patientId
    }

    if (!showCompleted) {
      matchStage.status = {$ne: 2}
    } 
    const skip = page === 1 ? 0 : page * itemsPerPage

    const requestObjs = [
      {
        db: state.db || "blh",
        collection: "tasks",
        parameters: [
          { $match: matchStage },
          {
            $addFields: {
              sortField: { $toLower: `$${sortColumn}` }
            }
          },
          { $sort: { sortField: sortOrder } }, 
          { $skip: skip },
          { $limit: limit },
          {
            $project: {
              sortField: 0
            }
          }
        ],
        method: "aggregate"
      },
    ];

    const res = await crud(state, requestObjs, null, null)
    return res.data[0]
  } catch (error) {
    console.log(error.message)
    return []
  }
}
