import {crud} from "../../../../crudRequests";

export function fetchForms(
  setForms,
  setAssignedForms,
  setCompletedForms,
  dashState,
  patient,
  toast
) {
  const abortController = new AbortController();
  crud(
    dashState,
    [
      {
        db: dashState.db,
        collection: "form_submissions",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ],
    null,
    abortController.signal
  )
    .then(async (fsub) => {
      let res = await crud(
        dashState,
        [
          {
            db: dashState.db,
            collection: "forms",
            parameters: [{}],
            method: "find",
          },
        ],
        null,
        abortController.signal
      );
      setForms(res.data[0]);

      let arr = fsub.data[0].reduce(
        (ac, e) => {
          if (e.completed) {
            ac[1].push(e);
          } else ac[0].push(e);
          return ac;
        },
        [[], []]
      );
      setAssignedForms(arr[0]);
      setCompletedForms(arr[1]);
    })
    .catch((err) => {
      toast({
        title: "Failed to fetch form data",
        description:
          err?.message || "An error occurred while fetching form data.",
        status: "error",
        isClosable: true,
      });
    });
}
