import { crud } from "../../../crudRequests";

export const updateTasks = (
  taskData,
  dashState,
  setTasks,
  assignedToName,
  patient,
  toast,
) => {
  delete taskData._id
  taskData.due = new Date(taskData.due).getTime()
  taskData.status = parseFloat(taskData.status)
  taskData.assignedToName = assignedToName
  taskData.patient = patient
  if (taskData?.status === 2) {
    let completedDate = new Date()
    completedDate.setDate(completedDate.getDate() - 1)
    taskData.completedDate = completedDate.getTime()
  } else {
    taskData.completedDate = null
  }
  return crud(dashState, [
    {
      db: dashState.db,
      collection: "tasks",
      parameters: [{taskId: taskData.taskId}, {$set: taskData}],
      method: "updateOne",
    },
  ])
    .then((res) => {
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.taskId === taskData.taskId ? { ...task, ...taskData } : task
        )
      )
      toast({
        title: "Task updated",
        description: "The task has been updated successfully.",
        status: "success",
        isClosable: true,
      });
    })
    .catch(function (error) {
      if (error.response.status === 403) {
        window.location.href = "../login"
      } else {
        toast({
          title: "As error occured",
          description: error.response?.data || error.message,
          status: "error",
          isClosable: true,
        });
      }
    });
}
