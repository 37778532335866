import {crud} from "../../crudRequests";
import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
  useMemo,
} from "react";

export default function CalendarSartsAt({schState, dispatch}) {
  let prev = schState.selectedDoctor
    ? schState.selectedDoctor.calendarSartsAt || "07:00"
    : schState.admin?.calendarSartsAt || "07:00";
  const [start, setStart] = useState(prev);
  const [prevStart, setPrevStart] = useState(start);
  const timerId = useRef(0);
  const abort = useRef(new AbortController());

  if (prevStart !== prev) {
    setStart(prev);
    setPrevStart(prev);
  }

  // console.log(schState.admin);

  const onStartChange = (e) => {
    setStart(e.target.value);
    clearTimeout(timerId.current);
    abort.current.abort();
    abort.current = new AbortController();

    timerId.current = setTimeout(() => {
      //console.log("k");
      let query = {
        [schState.selectedDoctor ? "did" : "id"]:
          schState.selectedDoctor?.did || schState.admin?.id,
      };

      crud(
        schState,
        [
          {
            db: schState.db,
            collection: schState.selectedDoctor ? "doctors" : "admins",
            parameters: [query, {$set: {calendarSartsAt: e.target.value}}],
            method: "updateOne",
          },
        ],
        null,
        abort.current.signal
      ).then(() => {
        schState.selectedDoctor
          ? dispatch({
              type: "UPDATE_DOCTOR",
              payload: {
                did: schState.selectedDoctor?.did,
                calendarSartsAt: e.target.value,
              },
            })
          : dispatch({
              type: "UPDATE_ADMIN",
              payload: {calendarSartsAt: e.target.value},
            });
      });
    }, 2000);
  };

  return (
    <div className="mx-1 flex flex-col items-start">
      <span className="text-[11px] ml-1  text-off">Calendar start</span>
      <input
        onChange={onStartChange}
        type="time"
        name="time"
        id="time"
        value={start}
        className="drop-shadow block w-[8.5rem] h-9 px-3 py-1 text-sm text-off font-medium bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
      />
    </div>
  );
}
