import {
  startOfMonth,
  endOfMonth,
  add,
  sub,
  isSameDay,
  lightFormat,
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  eachDayOfInterval,
  isSameMonth,
} from "date-fns";

import {useMemo, useRef, useState} from "react";
import {Button, HStack, Tag, Text} from "@chakra-ui/react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function DatePicker(props) {
  let {
    currentUnavailability,
    date,
    setDate,
    schState,
    isRange,
    range,
    setRange,
    selectedDays,
    setSelectedDays,
    start,
    setStart,
    end,
    setEnd,
  } = props;

  const mDown = useRef(false);

  const daysList = useMemo(() => {
    let Mstart = startOfMonth(date);
    let Mend = endOfMonth(date);
    let Wstart = startOfWeek(Mstart, {weekStartsOn: 1});
    let Wend = endOfWeek(Mend, {weekStartsOn: 1});
    let Days = eachDayOfInterval({
      start: Wstart,
      end: Wend,
    });
    return Days;
  }, [date]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div
      className=" p-2 h-[32rem] mt-2"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={
          "flex items-center justify-center text-center text-gray-900 w-full"
        }
      >
        <span
          onClick={() => {
            setDate(new Date());
          }}
          className="px-1 cursor-pointer text-gray-400 hover:text-gray-700  text-xs font-semibold "
        >
          Today
        </span>
      </div>
      <div
        className={
          "flex items-center border-b pb-1 justify-between text-center text-gray-900 w-full"
        }
      >
        <MdKeyboardDoubleArrowLeft
          className="text-gray-400 text-2xl cursor-pointer hover:text-gray-700"
          onClick={() => {
            setDate((prev) => sub(prev, {years: 1}));
          }}
        />
        <MdKeyboardArrowLeft
          onClick={() => {
            setDate((prev) => sub(prev, {months: 1}));
          }}
          className="text-gray-400 text-2xl cursor-pointer hover:text-gray-700"
        />

        <div className="text-sm  w-32 font-semibold">
          {months[date.getMonth()]} {date.getFullYear()}
        </div>
        <MdKeyboardArrowRight
          onClick={() => {
            setDate((prev) => add(prev, {months: 1}));
          }}
          className="text-gray-400 text-2xl cursor-pointer hover:text-gray-700"
        />
        <MdKeyboardDoubleArrowRight
          className="text-gray-400 text-2xl cursor-pointer hover:text-gray-700"
          onClick={() => {
            setDate((prev) => add(prev, {years: 1}));
          }}
        />
      </div>
      <div className="mt-1 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>M</div> <div>T</div> <div>W</div> <div>T</div> <div>F</div>{" "}
        <div>S</div> <div>S</div>
      </div>
      <div
        className="isolate flex-1  mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
        onMouseLeave={() => {
          if (isRange) {
            mDown.current = false;
          }
        }}
      >
        {daysList.map((day, dayIdx) => {
          let isCurrentMonth = isSameMonth(day, date);

          let isToday = isSameDay(day, new Date());
          return (
            <button
              onClick={() => {
                if (isRange) {
                  if (range[0] && range[1]) {
                    setRange([day, null]);
                    return;
                  }
                  if (range[0]) {
                    if (range[0] >= day) setRange([day, null]);
                    else
                      setRange((prev) => {
                        return [prev[0], day];
                      });
                  } else setRange([day, null]);
                } else {
                  let dFormat = lightFormat(day, "dd/MM/yyyy");
                  let newSelectedDays = new Set(selectedDays);
                  if (selectedDays.has(dFormat)) {
                    newSelectedDays.delete(dFormat);
                  } else {
                    newSelectedDays.add(dFormat);
                  }

                  setSelectedDays(newSelectedDays);
                }
              }}
              onMouseDown={() => {
                if (isRange) {
                  if (!range[0] || (range[0] && range[1]) || day === range[0]) {
                    mDown.current = true;
                    setRange([day, null]);
                  }
                }
              }}
              onMouseEnter={() => {
                if (isRange && mDown.current) {
                  if (range[0] > day) {
                    setRange([day, null]);
                  } else {
                    setRange((prev) => {
                      return [prev[0], day];
                    });
                  }
                }
              }}
              onMouseUp={() => {
                if (isRange && mDown.current) {
                  mDown.current = false;
                }
              }}
              key={dayIdx}
              type="button"
              className={classNames(
                "py-[4px] hover:bg-gray-100 bg-white w-10 h-10 focus:z-10 relative text-gray-900",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === daysList.length - 7 && "rounded-bl-lg",
                dayIdx === daysList.length - 1 && "rounded-br-lg"
              )}
              style={colorFormat({
                range,
                day,
                selectedDays,
                date,
                currentUnavailability,
              })}
            >
              <time dateTime={lightFormat(day, "yyyy-MM-dd")} className={""}>
                {day.getDate()}
              </time>
            </button>
          );
        })}
      </div>
      {
        <Button
          shadow={"md"}
          onClick={() => {
            setRange && setRange([]);
            setSelectedDays && setSelectedDays(new Set());
          }}
          size={"sm"}
          p="2"
          mt="3"
          color={"gray.500"}
          h="2rem"
          w="full"
          colorScheme="gray"
          mr={3}
        >
          Clear
        </Button>
      }
      {isRange && (
        <HStack w="full" gap="4" mt="8">
          <HStack>
            <Tag>From:</Tag>{" "}
            {
              <Text fontSize={"sm"} textAlign={"start"} minW="5rem">
                {range?.[0] && lightFormat(range[0], " dd/MM/yyyy")}
              </Text>
            }
          </HStack>
          <HStack>
            <Tag>To:</Tag>{" "}
            {range[1] && (
              <Text fontSize={"sm"} textAlign={"start"} minW="5rem">
                {range?.[1] && lightFormat(range[1], " dd/MM/yyyy")}
              </Text>
            )}
          </HStack>
        </HStack>
      )}
      <UnavailableRange {...{start, setStart, end, setEnd}} />
    </div>
  );
}

function colorFormat({range, day, selectedDays, date, currentUnavailability}) {
  let a = {color: null, backgroundColor: null};
  let isCurrentMonth = isSameMonth(day, date);
  let isToday = isSameDay(day, new Date());
  let dayFormat = lightFormat(day, "dd/MM/yyyy");
  if (!isCurrentMonth) {
    a.backgroundColor = "#f9fafb";
  }

  /*if (currentUnavailability.has(dayFormat)) {
    a.backgroundColor = "#CBD5E0";
  }*/
  if (isToday) {
    a.color = "#2563eb";
  }
  if (
    (range?.length > 0 &&
      ((day >= range[0] && day <= range[1]) || day === range[0])) ||
    selectedDays?.has(dayFormat)
  ) {
    a.color = "#ffffff";
    a.backgroundColor = "#C0BFFF";
  }
  return a;
}

function UnavailableRange({start, setStart, end, setEnd}) {
  return (
    <div
      className="space-y-2   mt-4  w-[13rem]"
      onClick={(e) => e.stopPropagation()}
    >
      <p className="text-slate-400 ml-1 font-normal text-xs text-start">
        Select interval
      </p>

      <div className="flex items-center space-x-3">
        <input
          type="time"
          name="time"
          id="time"
          pattern="[0-9]{2}:[0-9]{2}"
          required
          value={start}
          className="drop-shadow block p-2 px-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          onChange={(e) => {
            setStart(e.target.value);
          }}
        />
        <span>-</span>
        <input
          type="time"
          name="time"
          id="time"
          pattern="[0-9]{2}:[0-9]{2}"
          required
          value={end}
          className="drop-shadow block  p-2 px-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          onChange={(e) => {
            setEnd(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
