export const getAllSymptoms = ({journalEntries}) => {
  let symptoms = [];
  let allimprovments = [];
  let alldets = [];
  let allevents = [];
  journalEntries?.map((item) => {
    symptoms.push(...(item?.events || []));
    allevents.push(...(item?.events || []));
    symptoms.push(...(item?.symptoms?.improvements || []));
    allimprovments.push(...(item?.symptoms?.improvements || []));
    symptoms.push(...(item?.symptoms?.deteriorations || []));
    alldets.push(...(item?.symptoms?.deteriorations || []));
  });
  return symptoms?.filter((n) => n) || [];
};
