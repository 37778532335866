import {FiSearch} from "react-icons/fi";
import {Box, Icon, InputGroup, Input, InputLeftElement} from "@chakra-ui/react";

export default function SearchItems({searchQuery, setSearchQuery}) {
  return (
    <Box my="6">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FiSearch} color="gray.600" />
        </InputLeftElement>
        <Input
          placeholder="Search files or folders"
          variant="flushed"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>
    </Box>
  );
}
