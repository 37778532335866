import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  Button,
  Box,
  Flex,
  Input,
  FormLabel,
  Text,
  FormControl,
  FormHelperText,
  ButtonGroup,
} from "@chakra-ui/react";
import {VscFileSymlinkFile} from "react-icons/vsc";
import React, {useState, useEffect, useRef} from "react";
import {lightFormat} from "date-fns";
import {LuGanttChartSquare} from "react-icons/lu";
import {newBillingQuery} from "./helpers/billingQueries";
import {Action} from "@remix-run/router";
export default function NewQuery({
  state,
  toast,
  query,
  setQuery,
  setBillingQueries,
  fields,
  groupings,
}) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");

  return (
    <Popover
      closeOnBlur={false}
      placement="left"
      isOpen={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <PopoverTrigger>
        <Box display={"inline"}>
          <Button
            colorScheme="blue"
            variant="outline"
            m="1.5"
            px="6"
            size="sm"
            ml="3"
            onClick={async () => {
              setShow(true);
            }}
          >
            New query
          </Button>
        </Box>
      </PopoverTrigger>

      <PopoverContent zIndex={99999}>
        <PopoverHeader fontWeight="semibold">
          <Flex justify={"space-between"} align={"center"}>
            <Text color={"blue.500"}>New Query</Text>{" "}
          </Flex>
        </PopoverHeader>
        <PopoverArrow />

        <PopoverBody>
          <FormControl size={"sm"}>
            <FormLabel fontSize={"xs"} mb="0.5" color={"gray.500"}>
              Query Name
            </FormLabel>
            <Input
              size={"sm"}
              rounded={"md"}
              variant="filled"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <FormHelperText>Save the current billing state.</FormHelperText>
          </FormControl>
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button onClick={() => setShow(false)} variant="outline">
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="green"
              loadingText="Saving..."
              onClick={() => {
                if (name) {
                  newBillingQuery(
                    state,
                    toast,
                    {...query, name, fields: Object.keys(fields), groupings},
                    setQuery,
                    setBillingQueries,
                    setIsLoading,
                    () => setShow(false)
                  );
                }
              }}
            >
              Save Query
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
