import {crud} from "../../../crudRequests";

export async function fetchTransactions(
  state,
  skip,
  limit,
  setTransactions,
  setIsLoading,
  toast,
  patient
) {
  setIsLoading(true);
  let matchQuery = {lid: state.selectedLocation};
  if (patient) matchQuery.pid = patient.pid;
  if (state.userType === "doctor") matchQuery.did = state.doctor?.did;

  try {
    const pipeline = [
      {$match: matchQuery},
      {
        $lookup: {
          from: "patients",
          localField: "pid",
          foreignField: "pid",
          as: "patient",
        },
      },
      {
        $unwind: {
          path: "$patient",
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $lookup: {
          from: "appointments",
          localField: "aid",
          foreignField: "aid",
          as: "appointment",
        },
      },
      {
        $unwind: {
          path: "$appointment",
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $lookup: {
          from: "services",
          localField: "serviceId",
          foreignField: "serviceId",
          as: "service",
        },
      },
      {
        $unwind: {
          path: "$service",
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $lookup: {
          from: "doctors",
          localField: "did",
          foreignField: "did",
          as: "doctor",
        },
      },
      {
        $unwind: {
          path: "$doctor",
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $lookup: {
          from: "insurers",
          localField: "tpp",
          foreignField: "iid",
          as: "insurer",
        },
      },
      {
        $unwind: {
          path: "$insurer",
          preserveNullAndEmptyArrays: true,
        },
      },
    ];
    if (skip !== null && limit !== null) {
      pipeline.push({$skip: skip});
      pipeline.push({$limit: limit});
    }
    console.log("fetch");
    const res = await crud(state, [
      {
        db: state.db,
        collection: "billing",
        parameters: pipeline,
        method: "aggregate",
      },
    ]);
    // console.log(res.data[0]);
    try {
      if (skip && limit !== null) {
        setTransactions((transactions) => [
          ...(Array.isArray(transactions) ? transactions : []),
          ...(Array.isArray(res.data[0]) ? res.data[0] : []),
        ]);
      } else {
        setTransactions(res.data[0]);
      }
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  } catch (err) {
    console.log(err);
    toast({
      title: "Failed to fetch transactions data",
      description:
        err?.message || "An error occurred while fetching transactions data.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
  }
}
