import React, {useState, useEffect} from "react";
import {
  sendCardReqLink,
  getPaymentMethods,
  executeMembershipPayment,
} from "../../../crudRequests";
import createPaymentRecord from "../../appointments/payment/helpers/createPaymentRecord";
import {loadStripe} from "@stripe/stripe-js";
import {BiLink} from "react-icons/bi";
import {
  useToast,
  useDisclosure,
  FormControl,
  FormLabel,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export default function CreateCharge({
  state,
  patient,
  stateChanger,
  appt,
  dispatch,
  socket,
  setErrorMessage,
  setSuccess,
  refetch,
  transaction,
}) {
  const [sendingLink, setSendingLink] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(function () {
    if (state.db) {
      getPaymentMethods(state, patient.pid)
        .then((paymentMethods) => {
          setCards(paymentMethods.data);
          setSelectedCard(paymentMethods.data.find((c) => c?.default));
        })
        .catch((error) => {
          setErrorMessage(error.response?.data || error.message);
          console.log(error);
        });
    }
  }, []);

  return (
    <div className="relative   rounded-[10px] py-4  flex flex-col items-start space-y-4">
      <div className="flex justify-start items-center  px-8">
        <h1 className="text-[rgba(87,84,255,0.75)] font-semibold text-2xl leading-[25px]">
          Payment
        </h1>
      </div>
      <CardsList {...{cards, selectedCard, setSelectedCard}} />
      <div
        className="hover:text-yellow-400 cursor-pointer self-end text-[rgba(87,84,255,0.75)] mr-9 flex justify-end items-center"
        onClick={() => {
          setSendingLink(true);
          setErrorMessage(false);
          setSuccess(false);
          sendCardReqLink(state, patient)
            .then(() => {
              setSendingLink(false);
              setSuccess("The card request link has been sent!");
            })
            .catch((error) => {
              setSendingLink(false);
              setErrorMessage(error.response?.data?.toString());
              setSuccess(false);
            });
        }}
      >
        <span>
          <BiLink />
        </span>
        <span className="text-xs">Send Card Request</span>
        {sendingLink && (
          <span className="">
            <svg
              className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25 stroke-[4px]"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        )}
      </div>
      <SendFormChakra
        state={state}
        patient={patient}
        appt={appt}
        transaction={transaction}
        stateChanger={stateChanger}
        dispatch={dispatch}
        socket={socket}
        setErrorMessage={setErrorMessage}
        setSuccess={setSuccess}
        selectedCard={selectedCard}
        refetch={refetch}
      />
    </div>
  );
}

function SendFormChakra(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [description, setDescription] = useState("");
  const toast = useToast();
  const handleSubmit = async () => {
    const stripe = await loadStripe(
      props.state.organization?.stpPublicKey || "Invalid key"
    );
    if (props.patient && stripe) {
      props.setSuccess(false);
      props.setErrorMessage(false);
      const handleServerResponse = async (response) => {
        try {
          if (response.error) {
            props.setErrorMessage(response.error);
          } else if (response.requires_action) {
            const {error: errorAction, paymentIntent} =
              await stripe.handleCardAction(
                response.payment_intent_client_secret
              );
            if (errorAction) {
              props.setErrorMessage(errorAction.message);
            } else {
              const paymentResponset = await executeMembershipPayment(
                props.state,
                props.appt,
                props.patient.pid,
                null,
                paymentIntent.id
              );

              handleServerResponse(paymentResponset.data);
            }
          } else {
            if (response?.billing && response?.success) {
              await createPaymentRecord({
                state: props.state,
                transaction: response?.billing,
                description: "description",
                date: Date.now(),
                amount: response?.amountPaid,
                payer: "patient",
              });
            }
            const updatedMemberships = props.patient.memberships.map(
              (mData) => {
                return mData.uuid === props.transaction.membershipUuid
                  ? {
                      ...mData,
                      status: "Paid",
                    }
                  : mData;
              }
            );
            props.dispatch({
              type: "UPDATE_PATIENT",
              pid: props.transaction.pid,
              payload: {
                memberships: updatedMemberships,
              },
            });
            props.refetch();
            props.setSuccess("Successful payment!");
            toast({
              title: "Successful payment!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            props.stateChanger(false);
          }
        } catch (error) {
          props.setErrorMessage(error.response?.data || error.message);
        }
      };
      const paymentResponset = await executeMembershipPayment(
        props.state,
        props.appt,
        props.patient.pid,
        props.selectedCard
      );
      handleServerResponse(paymentResponset.data);
    }
  };
  return (
    <>
      <Button
        backgroundColor="#5754FF"
        textColor="white"
        fontWeight="thin"
        onClick={onOpen}
      >
        Generate Charge
        {`(${(
          (props.transaction.patientAmount ?? props.transaction.amount) -
          (props.transaction.amountPaidByPatient ?? 0)
        ).toFixed(2)} CAD)`}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment description</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                value={description}
                onChange={(evt) => {
                  setDescription(evt.target.value);
                }}
                type="textarea"
                placeholder="Type the description..."
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor="#7066e0"
              textColor="white"
              fontWeight="thin"
              variant="ghost"
              m="1"
              onClick={handleSubmit}
              isDisabled={!description}
            >
              OK
            </Button>
            <Button
              m="1"
              variant="solid"
              backgroundColor="#6e7881"
              textColor="white"
              fontWeight="thin"
              width="auto"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function CardsList({cards, selectedCard, setSelectedCard}) {
  return (
    <div className="space-y-2 mt-4 w-full px-8">
      <div
        className="rounded-[10px] py-2 text-background font-semibold text-center mr-2"
        style={{backgroundColor: "#8F8CFF"}}
      >
        <ul className="flex justify-center items-center  lg:text-base">
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Card
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Exp Date
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Selected Card
          </li>
          {/*<li className="flex justify-center items-center flex-1 text-[16px]">Action</li>*/}
        </ul>
      </div>
      <div className="overflow-hidden flex justify-center max-h-[6rem]">
        <div className="overflow-y-scroll  w-full space-y-2 pr-2 pb-2 sbar2">
          {cards.length > 0 ? (
            cards.map((c, i) => {
              return (
                <div
                  key={i}
                  className=" rounded-[10px] mt-0 py-2 bg-[#DFDEFF] text-sm text-dark text-opacity-75 font-semibold text-center h-fit"
                  style={{
                    backgroundColor: c === selectedCard ? "#c4c2fb" : "#DFDEFF",
                  }}
                >
                  <ul className="flex justify-center items-center md:text-sm w-full">
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {"****" + c.card.last4}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {`${c.card.exp_month}/${c.card.exp_year}`}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      <div
                        className="bg-[#5754FF] rounded-full p-2 w-20 cursor-pointer transition duration-500"
                        onClick={() => {
                          setSelectedCard(c);
                        }}
                        style={{
                          backgroundColor:
                            c === selectedCard ? "#5754FF" : "#FFFFFF",
                          boxShadow:
                            c !== selectedCard && "0 0 0 2px inset #5754FF",
                        }}
                      >
                        <p
                          className="w-3 h-3 rounded-full bg-white transition duration-500"
                          style={{
                            transform:
                              c === selectedCard
                                ? "translateX(55px)"
                                : "translateX(0)",
                            backgroundColor:
                              c === selectedCard ? "#FFFFFF" : "#5754FF",
                          }}
                        ></p>
                      </div>
                    </li>
                  </ul>
                </div>
              );
            })
          ) : (
            <div
              className=" rounded-[10px] mt-0 py-2 bg-[#DFDEFF] text-sm text-dark text-opacity-75 font-semibold text-center h-fit"
              style={{
                backgroundColor: "#DFDEFF",
              }}
            >
              <span>No registered card</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
