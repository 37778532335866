import {crudStorage} from "./crudStorage";
import {apiAction, crud} from "../../../../crudRequests";
export function downloadFile(dashState, path, fileName, toast) {
  crudStorage(dashState, "download", path + fileName, "brightlighthealth", "")
    .then((res) => {
      crud(dashState, [
        {
          db: dashState.db || "blh",
          collection: "auditLogs",
          parameters: [
            {
              collection: "files",
              action: "DOWNLOAD-FILE",
              triggeredBy: dashState.admin.id,
              payload: {
                fileName: fileName,
                path: path,
                timestamp: new Date().toISOString(),
              },
            },
          ],
          method: "insertOne",
        },
      ]);
      if (path.includes("Requisition")) {
        apiAction(dashState, {
          action: "DOWNLOAD-REQUISITION",
          payload: {
            fileName,
            path,
          },
        });
      }
      console.log(res.data);
      window.open(res.data, "_blank");
    })
    .catch((err) => {
      toast({
        title: "Failed to download file",
        description:
          err?.message || "An error occurred while downloading file.",
        status: "error",
        isClosable: true,
      });
    });
}
