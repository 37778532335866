import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {crud} from "../crudRequests";
import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Input,
  PopoverFooter,
  Flex,
  ButtonGroup,
  useToast,
  FormControl,
  FormLabel,
  Select,
  Switch,
} from "@chakra-ui/react";
import {BsPersonCircle, BsInfoCircleFill} from "react-icons/bs";

let fieldsInit = {
  serviceName: "",
  category: "",
  description: "",
  defaultCost: 100,
  defaultDuration: "15 min",
  color: "#64748b",
};
export default function ServiceBasicFields({
  state,
  dispatch,
  taxable,
  setTaxable,
  isfixedCost,
  setIsfixedCost,
  setNonSessions,
  nonSessions,
  selectedService,
  selectedDoctorsIdRef,
  fieldsValuesRef,
  reset,
}) {
  const [selectedUsers, setSelectedUsers] = useState(() => {
    if (selectedService) {
      let doctorsIds = (state.doctors || [state.doctor] || [])
        .filter((d) => {
          let servicesId = new Set(d?.services || []);
          return servicesId.has(selectedService.serviceId);
        })
        .map((e) => e.did);

      return new Set(doctorsIds);
    }
    return new Set();
  });

  selectedDoctorsIdRef.current = selectedUsers;

  const [applyToAll, setApplyToAll] = useState(false);
  const [fieldsValues, setFieldsValue] = useState(() => {
    if (selectedService) {
      let {
        serviceName,
        category,
        description,
        defaultCost,
        defaultDuration,
        color,
        serviceCodes,

        ...rest
      } = selectedService;
      return {
        serviceName,
        category,
        description,
        defaultCost,
        defaultDuration,
        color,
        serviceCodes,
      };
    }

    return fieldsInit;
  });
  fieldsValuesRef.current = fieldsValues;

  const [prevReset, setPrevReset] = useState(reset);

  const ids = useMemo(() => {
    return state.doctors
      ? Object.keys(state.doctorsPayload)
      : [state.doctor?.did];
  }, [state.doctorsPayload, state.doctor]);

  if (reset !== prevReset) {
    setPrevReset(reset);
    setFieldsValue(fieldsInit);
    setSelectedUsers(new Set());
    setApplyToAll(false);
  }

  return (
    <div className="flex-1 flex flex-col   bg-gradient-to-b from-[#2d29ff44] to-[#7b79ff0f] rounded-3xl p-5 min-h-[370px] space-y-2">
      <h1 className="text-[#5754FF] font-semibold text-2xl text-center">
        {"Basic Fields"}
      </h1>
      <div className="w-full  justify-center items-center  flex-1 overflow-y-hidden">
        <form className="h-full">
          <div className="flex relative h-full">
            <div className="h-full overflow-y-scroll sbar2 py-2 pt-0 ">
              <label className="block mx-3.5" htmlFor="name">
                <span className="text-[12px] font-medium text-off ml-2">
                  Service Name
                </span>
                <input
                  type="text"
                  name="serviceName"
                  id="serviceName"
                  value={fieldsValues.serviceName || ""}
                  onChange={(e) => {
                    setFieldsValue((prev) => ({
                      ...prev,
                      serviceName: e.target.value,
                    }));
                  }}
                  className="drop-shadow block w-[17rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                ></input>
              </label>
              <label className="block mx-3.5 mt-1">
                <span className="text-[12px] font-medium text-off ml-2">
                  Category
                </span>
                <input
                  type="text"
                  className="drop-shadow block w-[17rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  value={fieldsValues.category || ""}
                  onChange={(e) => {
                    setFieldsValue((prev) => ({
                      ...prev,
                      category: e.target.value,
                    }));
                  }}
                ></input>
              </label>
              <div className="mx-3.5 mt-3">
                <label
                  className="flex cursor-pointer"
                  htmlFor=""
                  onClick={() => {
                    setNonSessions((prev) => !prev);
                  }}
                >
                  <div
                    className={`drop-shadow relative  mt-1 border-none flex justify-center items-center   w-4 h-4 border-2  cursor-pointer  bg-white border-white rounded-full`}
                  >
                    <span
                      className="w-4 h-4 rounded-full relative z-10 border"
                      style={{
                        backgroundColor: nonSessions ? "#7371ff" : "#FFFFFF",
                      }}
                    ></span>
                  </div>
                  <span
                    className="text-[16px] font-medium text-white ml-2"
                    style={{
                      color: nonSessions ? "#7371ff" : "#FFFFFF",
                    }}
                  >
                    Non-Sessions Service
                  </span>
                </label>
              </div>
              <div className="flex  w-full mt-1 justify-between px-3.5 pr-5 z-10 pt-2 relative">
                <label className="block" htmlFor="color-picker">
                  <span className="text-[12px] font-medium text-off ml-2">
                    Legend Color
                  </span>
                  <div
                    className={`drop-shadow relative ml-2 mt-1 border-none flex justify-center items-center   w-7 h-7 border-2  cursor-pointer  bg-white border-white rounded-md`}
                  >
                    <input
                      className="bg-transparent h-0 w-0 absolute right-2 top-1  outline-[0px]  border-none border-[0px]"
                      type="color"
                      id="color-picker"
                      onChange={(e) => {
                        setFieldsValue((prev) => ({
                          ...prev,
                          color: e.target.value,
                        }));
                      }}
                      value={fieldsValues.color || "#64748b"}
                    />
                    <span
                      className="w-6 h-6 rounded relative z-10 "
                      style={{backgroundColor: fieldsValues.color || "#64748b"}}
                    ></span>
                  </div>
                </label>

                <div className="flex flex-col  items-end">
                  <FormControl
                    mt="2"
                    ml="2"
                    display="flex"
                    alignItems="center"
                    justifyContent={"flex-end"}
                    pr="1"
                  >
                    <FormLabel
                      cursor={"pointer"}
                      fontSize={"12px"}
                      htmlFor="taxable"
                      mb="0"
                      fontWeight={"semibold"}
                      color={taxable ? "#7371ff" : "white"}
                    >
                      Taxable
                    </FormLabel>
                    <Switch
                      isChecked={taxable}
                      onChange={(e) => {
                        setTaxable((prev) => !prev);
                      }}
                      size="sm"
                      id="taxable"
                    />
                  </FormControl>
                  <FormControl
                    mt="2"
                    ml="2"
                    display="flex"
                    alignItems="center"
                    justifyContent={"flex-end"}
                    pr="1"
                  >
                    <FormLabel
                      cursor={"pointer"}
                      fontSize={"12px"}
                      htmlFor="fixed-cost"
                      mb="0"
                      fontWeight={"semibold"}
                      color={isfixedCost ? "#5754FF" : "white"}
                    >
                      Fixed Cost?
                    </FormLabel>
                    <Switch
                      isChecked={isfixedCost}
                      onChange={(e) => {
                        setIsfixedCost((prev) => !prev);
                      }}
                      size="sm"
                      id="fixed-cost"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="flex w-full mt-2 items-start justify-between px-3.5">
                <label className="block" htmlFor="duration">
                  <span className="text-[12px] font-medium text-off ml-2">
                    Default Duration
                  </span>
                  <select
                    onChange={(e) => {
                      setFieldsValue((prev) => ({
                        ...prev,
                        defaultDuration: e.target.value,
                      }));
                    }}
                    value={fieldsValues.defaultDuration || "15 min"}
                    type="duration"
                    name="duration"
                    id="duration"
                    className="drop-shadow block w-[8rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  >
                    <option value="15 min">15 minutes</option>
                    <option value="30 min">30 minutes</option>
                    <option value="45 min">45 minutes</option>
                    <option value="60 min">60 minutes</option>
                    <option value="75 min">75 minutes</option>
                    <option value="90 min">90 minutes</option>
                    <option value="120 min">120 minutes</option>
                  </select>
                </label>
                <label className="flex flex-col items-end">
                  <div>
                    <span className="text-[12px] font-medium text-off ml-2">
                      Default Cost/hrs
                    </span>
                    <input
                      type="text"
                      className="drop-shadow block w-[8rem]  text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      value={fieldsValues.defaultCost}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setFieldsValue((prev) => ({
                            ...prev,
                            defaultCost:
                              e.target.value === ""
                                ? 0
                                : e.target.value
                                    .replace(/[-+ ]/g, "")
                                    .slice(0, 10),
                          }));
                        }
                      }}
                    ></input>
                  </div>
                </label>
              </div>

              <label className="block mx-3.5 mt-2">
                <span className="text-[12px] font-medium text-off ml-2">
                  Service Description
                </span>
                <textarea
                  placeholder=" Write note..."
                  value={fieldsValues.description || ""}
                  onChange={(e) => {
                    setFieldsValue((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  className="h-[4.5rem] drop-shadow block w-[17rem] px-3 py-2.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <div className="mx-3.5 mt-2">
                <MultipleEntriesInput
                  {...{
                    serviceCodes: fieldsValues.serviceCodes || [],
                    setFields: setFieldsValue,
                    label: "Service Codes",
                    fieldName: "serviceCodes",
                  }}
                />
              </div>
            </div>
            <div className="p-5 pb-0  h-full overflow-y-scroll sbar2   pt-1 px-2">
              <UserList
                ids={ids}
                setApplyToAll={setApplyToAll}
                applyToAll={applyToAll}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                userList={state.admin ? state.doctors : [state.doctor]}
              />
              <ProviderTypesList
                {...{
                  selectedUsers,
                  setSelectedUsers,
                  ptList: state.providerTypes,
                  userList: state.admin ? state.doctors : [state.doctor],
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

function UserList({
  selectedUsers,
  setSelectedUsers,
  userList,
  setApplyToAll,
  applyToAll,
  ids,
  defining,
}) {
  return (
    <div className="w-[220px]  overflow-hidden  flex  flex-col text-left justify-between space-y-2 pb-2 ">
      <div className="h-full  w-[200px] flex flex-col overflow-hidden">
        <span className="text-[12px] font-medium text-off ml-2">
          Clinicians
        </span>
        <ul className="overflow-y-scroll max-h-[160px] w-full sbar2  p-1 pr-2 space-y-1">
          {(userList || []).map((e, i) => {
            const [first, ...last] = e.name.split(" ");
            return (
              <li
                onClick={() => {
                  let id = e.did || e.id;
                  !selectedUsers.has(id)
                    ? setSelectedUsers((prev) => {
                        prev.add(id);
                        let n = new Set(prev);
                        setApplyToAll(false);
                        return n;
                      })
                    : setSelectedUsers((prev) => {
                        prev.delete(id);
                        let n = new Set(prev);
                        setApplyToAll(false);
                        return n;
                      });
                }}
                key={i}
                style={{
                  backgroundColor: selectedUsers.has(e.did || e.id)
                    ? "#7371ff"
                    : null,
                }}
                className="[box-shadow:_0px_4px_4px_rgba(0,0,0,0.25)] hover:cursor-pointer bg-[#C1BFFF] w-full flex  items-center rounded-full p-1 py-1.5 space-x-1"
              >
                <span className="flex justify-center h-7 w-7 items-center shrink-0">
                  {e.photo ? (
                    <img
                      src={e.photo}
                      alt=""
                      className="hover:cursor-pointer hover:bg-[] h-full rounded-full drop-shadow-lg"
                    />
                  ) : (
                    <BsPersonCircle className="text-[1.5rem] text-white" />
                  )}
                </span>
                <div className=" flex  flex-col">
                  <p className="text-white text-[12px] leading-4  font-semibold">
                    {`${last}, ${first}`}
                  </p>
                  <span className="font-light text-[7px] text-white">
                    {e.accreditations || ""}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
        <p className="text-[#606FFF] text-xs mt-4 font-medium flex items-center ml-1 space-x-2 ">
          <span
            className="h-4 w-4 rounded-[5px] border-2 inline-block border-[#7371ff] hover:cursor-pointer"
            style={{
              backgroundColor: applyToAll ? "#7371ff" : "#FFFFFF",
            }}
            onClick={() => {
              setApplyToAll((a) => {
                if (!a) {
                  let n = new Set(ids);
                  setSelectedUsers(n);
                  return true;
                } else {
                  let n = new Set();
                  setSelectedUsers(n);
                  return false;
                }
              });
            }}
          ></span>
          <span>Selected all</span>
        </p>
      </div>
    </div>
  );
}
function ProviderTypesList({
  selectedUsers,
  setSelectedUsers,
  ptList,
  userList,
}) {
  return (
    <div className="w-[220px] pt-4   flex  flex-col text-left justify-between space-y-2 pb-2 ">
      <div className="h-full  w-[200px] flex flex-col overflow-hidden">
        <span className="text-[12px] font-medium text-off ml-2">
          Provider Types
        </span>
        <ul className="overflow-y-scroll max-h-[160px] w-full sbar2  p-1 pr-2 space-y-1 pb-4 ">
          {(ptList || []).map((e, i) => {
            let backgroundColor = "#7371ff";
            let n = 0;
            for (let d of userList) {
              if (e.typeId !== d.providerType) n++;
              if (e.typeId === d.providerType && !selectedUsers.has(d.did)) {
                backgroundColor = null;
                break;
              }

              if (n === userList.length) backgroundColor = null;
            }

            return (
              <li
                onClick={() => {
                  if (backgroundColor === "#7371ff") {
                    setSelectedUsers((prev) => {
                      return new Set([
                        ...userList
                          .filter(
                            (u) =>
                              u.providerType !== e.typeId && prev.has(u.did)
                          )
                          .map((d) => d.did),
                      ]);
                    });
                  } else {
                    setSelectedUsers((prev) => {
                      return new Set([
                        ...userList
                          .filter((u) => u.providerType === e.typeId)
                          .map((d) => d.did),
                        ...prev,
                      ]);
                    });
                  }
                }}
                key={i}
                style={{
                  backgroundColor,
                }}
                className="[box-shadow:_0px_4px_4px_rgba(0,0,0,0.25)] hover:cursor-pointer bg-[#C1BFFF] w-full flex  items-center rounded-full p-1 py-1.5 space-x-1"
              >
                <div className=" flex justify-center w-full flex-wrap ">
                  <p className="text-white text-[12px] leading-4  font-semibold">
                    {e.name}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function MultipleEntriesInput({serviceCodes, setFields, label, fieldName}) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      const newEntries = new Set([...serviceCodes, inputValue.trim()]);
      setInputValue("");
      setFields((prev) => {
        return {
          ...prev,
          [fieldName]: [...newEntries],
        };
      });
    }
  };

  return (
    <div className="space-y-5">
      <label className="relative block my-1 text-left" htmlFor="lastName">
        <span className="text-[12px] font-medium text-off ml-2">{label}</span>
        <input
          name="lastName"
          id="lastName"
          className=" block w-full peer  py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          value={inputValue}
        />
        <div className="hidden w-full absolute top-full right-2  text-[10px] text-yellow-500 peer-focus:flex justify-end items-center space-x-1">
          <span className="">{"Type an option and press enter."}</span>
          <BsInfoCircleFill />
        </div>
      </label>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl max-w-[16rem] content-start flex  items-start flex-wrap  max-h-[80px]  w-full sbar2 p-2">
          {serviceCodes.map((entry, index) => (
            <li
              key={index}
              className="inline-block mx-1 my-1  py-1 text-sm text-off font-medium bg-white rounded-md cursor-pointer relative px-4"
              onClick={() => {
                setFields((prev) => {
                  return {
                    ...prev,
                    [fieldName]: prev[fieldName].filter((f) => f !== entry),
                  };
                });
              }}
            >
              <svg
                aria-hidden="true"
                className="w-2 h-2 absolute to left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="">{entry}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
