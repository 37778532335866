import {crudStorage} from "./crudStorage";
import {viewFiles} from "./viewFiles";

export function createFolder(
  dashState,
  setFolderName,
  setAddFolder,
  path,
  folderName,
  setFiles,
  setSelectedFile,
  toast
) {
  if (!path.split("/").includes(folderName)) {
    crudStorage(
      dashState,
      "createDirectory",
      "",
      "brightlighthealth",
      path + folderName
    )
      .then((res) => {
        viewFiles(dashState, setFiles, setSelectedFile, path, toast);
        setFolderName("");
        setAddFolder(false);
      })
      .catch((err) => {
        toast({
          title: "Failed to create a folder",
          description:
            err?.message || "An error occurred while creating a folder.",
          status: "error",
          isClosable: true,
        });
      });
  } else {
    toast({
      title: "Failed to create a folder",
      description:
        "Please select a different name for this folder as this name already exists on this path.",
      status: "error",
      isClosable: true,
    });
  }
}
