import {crud} from "../../../crudRequests";

export default async function fetchSales(state, setIsLoading, setSales, toast) {
  setIsLoading(true);

  try {
    const count = await crud(state, [
      {
        db: state.db,
        collection: "billing",
        parameters: [{lid: state.selectedLocation, type: "product"}],
        method: "count",
      },
    ]);

    setSales(count.data[0]);

    setIsLoading(false);
  } catch (err) {
    console.log("error", err);
    toast({
      title: "Failed to sales data",
      description:
        err?.message || "An error occurred while fetching sales data.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
  }
}
