import {Text, Box} from "@chakra-ui/react";
import {useEffect, useRef, useContext, useLayoutEffect} from "react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {isSameDay} from "date-fns";
import {shortWeekday, monthsNames} from "../helpers/timeFormatters";
import PatientStatus from "./PatientStatus";
import Messages from "./Messages";
import SendMessage from "./SendMessage";

export default function Chat({chatState, dispatch}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  let {conversations, rooms, selectedChat, usersStatus, writing, loading} =
    chatState;

  const chatEndRef = useRef(null);
  const sendRef = useRef(null);
  const writingRef = useRef(null);
  let dateMarkingRef = 0;

  useLayoutEffect(() => {
    chatEndRef.current?.scrollIntoView({behavior: "smooth"});

    if (writing) {
      writingRef.current = setTimeout(() => {
        dispatch({
          type: "WRITING",
          payload: null,
        });
      }, 4000);
    }

    return () => {
      clearTimeout(writingRef.current);
    };
  }, [writing]);

  useLayoutEffect(() => {
    chatEndRef.current?.scrollIntoView({behavior: "smooth"});
  }, [selectedChat]);

  useEffect(() => {
    const send = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter")
        sendRef.current.click();
    };
    window.addEventListener("keydown", send);
    return () => {
      window.removeEventListener("keydown", send);
    };
  }, []);

  return (
    <Box mx="auto" my="6" pb="6" px="8" boxShadow="md" bg="white">
      <PatientStatus
        selectedChat={selectedChat}
        usersStatus={usersStatus}
        chatEndRef={chatEndRef}
      />
      <Box h="50vh" bg="white" p="2" overflowY="scroll" pb={"5"}>
        {selectedChat?.messages?.map((m, i) => {
          let cDate = new Date(m.timestamp);
          let mark = null;

          if (!isSameDay(cDate, dateMarkingRef)) {
            dateMarkingRef = new Date(m.timestamp);
            mark = (
              <Box key={conversations.length + i}>
                <Text textAlign={"center"} fontSize={"10px"} py={"35px"}>
                  {`${
                    new Date().getFullYear() == cDate.getFullYear()
                      ? shortWeekday[cDate.getDay()] + ", "
                      : ""
                  } ${monthsNames[cDate.getMonth()]} ${cDate.getDate()}${
                    new Date().getFullYear() != cDate.getFullYear()
                      ? ", " + cDate.getFullYear()
                      : ""
                  }`}
                </Text>
              </Box>
            );
          }
          return (
            <Messages
              m={m}
              i={i}
              mark={mark}
              usersStatus={usersStatus}
              selectedChat={selectedChat}
            />
          );
        })}
        {writing && (
          <Box ml={"16px"} mt={"20px"} fontSize={"xs"} color={"grey.300"}>
            <Text as={"i"}>
              {writing.chatId === selectedChat?.conversationId &&
                writing.userId === patient.pid &&
                (patient.fName + " " + patient.lName || "The patient ") +
                  " is typing..."}
            </Text>
          </Box>
        )}
        <Box ref={chatEndRef} />
      </Box>
      <SendMessage
        dispatch={dispatch}
        rooms={rooms}
        selectedChat={selectedChat}
        sendRef={sendRef}
      />
    </Box>
  );
}
