import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef, useReducer} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForAdminLogin, loadDataForDoctorLogin} from "../crudRequests";
import Navbar from "../components/Navbar";
import NewForm from "../components/NewForm";
import AllForms from "../components/AllForms";
import ToastNotification from "../components/toastNotification/ToastNotification";
import {scheduleReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import InstanceBar from "../components/InstanceBar";
import {FixFormKeysScriptBtn} from "../components/FixFormKeysScriptBtn";
export default function Forms(props) {
  const navigate = useNavigate();
  const [createEdit, setCreateEdit] = useState(false);
  const [loading, setLoading] = useState();
  const loadedRef = useRef(null);
  const {state} = useLocation();
  const [schState, dispatch] = useReducer(scheduleReducer, state, init);
  const sessionStart = useRef(true);

  useEffect(
    function () {
      if (!state) {
        window.history.back();
      } else verifyUser(state ? state.jwt : "", loadedRef);
    },
    [state]
  );

  const [prevState, setPrevstate] = useState(state);

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  const reloadingDataRef = useRef(null);

  function reloadState(destination, abortController, action) {
    action?.();

    if (schState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  }

  useEffect(() => {
    const abortController = new AbortController();
    if (!schState?.firstLoading) {
      reloadingDataRef.current = true;
      reloadState("../forms", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [schState.selectedLocation]);

  useEffect(() => {
    // console.log(schState.changingLocation, 2);
    if (schState?.firstLoading)
      navigate("../forms", {
        state: {
          ...schState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);

  return (
    <div
      ref={loadedRef}
      style={{opacity: 0}}
      className=" h-[100vh] flex flex-col"
    >
      {schState.changingLocation && (
        <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}
      <div
        className="absolute top-6 z-[30] right-12 bg-light opacity-75 py-2 px-[9px] rounded-md cursor-pointer"
        onClick={logoutUser}
      >
        <img src="/images/Doctor/login.png" alt="" className="w-6" />
      </div>

      <Navbar state={{...schState}} />
      <InstanceBar
        {...{
          dispatch,
          schState,
        }}
      />

      <ToastNotification appointments={schState.appointments || []} />
      <div className="relative w-[calc(100%-7rem)] overflow-hidden flex-1 pt-2 pb-12 pl-10 pr-2 ml-28">
        {
          <div className="flex space-x-8  font-semibold pl-2">
            <button
              className={
                !createEdit
                  ? "bg-[#5754FF] h-12 w-32 flex justify-center items-center text-white text-[1rem] rounded-[10px] focus:outline-none"
                  : "bg-white h-12 w-32 flex justify-center items-center text-[#5754FF] ring-4 ring-inset ring-[#5754FF] text-[1rem] rounded-[10px] focus:outline-none"
              }
              onClick={() => {
                setCreateEdit(false);
              }}
            >
              All Forms
            </button>
            <button
              className={
                createEdit
                  ? "bg-[#5754FF] h-12 w-32 flex justify-center items-center text-white text-[1rem] rounded-[10px] focus:outline-none"
                  : "bg-white h-12 w-32 flex justify-center items-center text-[#5754FF] ring-4 ring-inset ring-[#5754FF] text-[0/rem] rounded-[10px] focus:outline-none"
              }
              onClick={() => {
                setCreateEdit("new");
              }}
            >
              Create
            </button>
            {/* <FixFormKeysScriptBtn state={state} /> */}
          </div>
        }
        {createEdit ? (
          <NewForm
            state={{...schState}}
            createEdit={createEdit}
            setCreateEdit={setCreateEdit}
          />
        ) : (
          state && (
            <AllForms state={{...schState}} setCreateEdit={setCreateEdit} />
          )
        )}
      </div>
    </div>
  );
}
