import React, {useState, useEffect, useRef} from "react";
import Swal from "sweetalert2";
import {BsPlusCircleDotted} from "react-icons/bs";
import {AiFillCloseCircle} from "react-icons/ai";

export default function EditFormField(props) {
  const close = props.close;
  const field = props.field;
  const updateForm = props.updateForm;

  const headingText = useRef(null);
  const paragraphText = useRef(null);
  const label = useRef(null);
  const placeholder = useRef(null);
  const acceptType = useRef(null);
  const [STATE_options, STATE_setOptions] = useState(
    field[0].key == "Select Menu"
      ? field[0].children[1].children
          .filter((option) => option.props.value)
          .map((option) => option.props.value)
      : field[0].key == "Check Boxes" &&
          field[0].children[1].children
            .filter((option) => option.children[0].props.value)
            .map((option) => option.children[0].props.value)
  );
  console.log(STATE_options);

  function updateField() {
    updateForm((prevForm) => ({
      ...prevForm,
      [field[1]]: {
        ...prevForm[field[1]],
        [field[2]]:
          field[0].key == "Heading"
            ? {
                element: "div",
                key: "Heading",
                props: {className: "mt-6 mb-3"},
                children: [
                  {
                    element: "h1",
                    props: {
                      className: "text-off font-semibold text-3xl",
                    },
                    children: headingText.current.value,
                  },
                ],
              }
            : field[0].key == "Subheading"
            ? {
                element: "div",
                key: "Subheading",
                props: {className: "mt-6 mb-3"},
                children: [
                  {
                    element: "h3",
                    props: {
                      className: "text-off font-medium text-xl",
                    },
                    children: headingText.current.value,
                  },
                ],
              }
            : field[0].key == "Paragraph"
            ? {
                element: "div",
                key: "Paragraph",
                props: {className: "mt-2 mb-3"},
                children: [
                  {
                    element: "p",
                    props: {
                      className: "text-off font-normal text-md",
                    },
                    children: paragraphText.current.value,
                  },
                ],
              }
            : field[0].key == "Text"
            ? {
                element: "div",
                key: "Text",
                props: {
                  className: "mt-2",
                },
                children: [
                  {
                    element: "span",
                    props: {
                      className: "text-xs text-off",
                    },
                    children: label.current.value,
                  },
                  {
                    element: "input",
                    props: {
                      type: "text",
                      placeholder: placeholder.current.value,
                      className:
                        "block border-none rounded-md p-2 mt-1 text-off w-full",
                    },
                    key: "",
                  },
                ],
              }
            : field[0].key == "Note"
            ? {
                element: "div",
                key: "Note",
                props: {
                  className: "mt-2",
                },
                children: [
                  {
                    element: "span",
                    props: {
                      className: "text-xs text-off",
                    },
                    children: label.current.value,
                  },
                  {
                    element: "textarea",
                    props: {
                      className:
                        "block border-none rounded-md p-2 mt-1 text-off w-full",
                      placeholder: placeholder.current.value,
                      rows: 1,
                    },
                    key: "",
                  },
                ],
              }
            : field[0].key == "Select Menu"
            ? {
                element: "div",
                key: "Select Menu",
                props: {className: "mt-2"},
                children: [
                  {
                    element: "span",
                    props: {className: "text-xs text-off"},
                    children: label.current.value,
                  },
                  {
                    element: "select",
                    props: {
                      className:
                        "block border-none rounded-md p-2 mt-1 text-off w-full",
                    },
                    children: STATE_options.map((op) => {
                      return {
                        element: "option",
                        props: {value: op},
                        children: op,
                      };
                    }),
                  },
                ],
              }
            : field[0].key == "Check Boxes"
            ? {
                element: "div",
                key: "Check Boxes",
                props: {className: "mt-2"},
                children: [
                  {
                    element: "span",
                    props: {className: "text-xs text-off"},
                    children: label.current.value,
                  },
                  {
                    element: "div",
                    children: STATE_options.map((op) => {
                      return {
                        element: "span",
                        children: [
                          {
                            element: "input",
                            props: {
                              type: "checkbox",
                              name: op,
                              className:
                                "focus:ring-0 border-[#D1D1D1] rounded-md p-2",
                              value: op,
                            },
                          },
                          {
                            element: "label",
                            props: {className: "text-off ml-1 mr-4"},
                            children: op,
                          },
                        ],
                      };
                    }),
                  },
                ],
              }
            : field[0].key == "Date"
            ? {
                element: "div",
                key: "Date",
                props: {
                  className: "mt-2",
                },
                children: [
                  {
                    element: "span",
                    props: {
                      className: "text-xs text-off",
                    },
                    children: label.current.value,
                  },
                  {
                    element: "input",
                    props: {
                      type: "date",
                      placeholder: "",
                      className:
                        "block border-none rounded-md p-2 mt-1 text-off w-full",
                    },
                    key: "",
                  },
                ],
              }
            : field[0].key == "File Upload"
            ? {
                element: "div",
                key: "File Upload",
                props: {className: "mt-2"},
                children: [
                  {
                    element: "span",
                    props: {
                      className: "text-xs text-off",
                    },
                    children: label.current.value,
                  },
                  {
                    element: "input",
                    props: {
                      type: "file",
                      name: "fileInput",
                      id: "fileInput",
                      className: "w-full text-xs text-off",
                      accept: acceptType.current.value,
                    },
                    key: "",
                  },
                ],
              }
            : field[0].key == "Slider"
            ? {
                element: "div",
                key: "Slider",
                props: {
                  className: "mt-2",
                },
                children: [
                  {
                    element: "span",
                    props: {
                      className: "text-xs text-off",
                    },
                    children: label.current.value,
                  },
                  {
                    element: "input",
                    props: {
                      type: "range",
                      placeholder: "",
                      className:
                        "block mt-2 w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer h-2",
                    },
                    key: "",
                  },
                ],
              }
            : "",
      },
    }));
    close(false);
  }

  function removeField() {
    Swal.fire({
      title: "Remove Field?",
      text: "Are you sure you would like to permanently remove this field from the form? You won't be able to revert this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it.",
    }).then((result) => {
      if (result.isConfirmed) {
        updateForm((prevForm) => ({
          ...prevForm,
          [field[1]]: {
            ...prevForm[field[1]],
            [field[2]]: "",
          },
        }));
        close(false);
      }
    });
  }

  return (
    <>
      <div
        className="fixed z-[70] top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            style={{backgroundColor: "#F6F5FF"}}
          >
            <div className="flex -mb-7">
              <h3
                className="text-3xl font-medium  text-gray-800 m-4 mb-6"
                id="modal-title"
                style={{color: "#C0BFFF"}}
              >
                Edit {field[0].key}
              </h3>
              <div className="absolute right-6 top-4">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center"
                  data-modal-toggle="small-modal"
                  style={{boxShadow: "none"}}
                  onClick={() => close(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <form className="mt-6" action="#">
              {field[0].key == "Heading" || field[0].key == "Subheading" ? (
                <>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">
                      Edit {field[0].key}
                    </span>
                    <br />
                    <input
                      type="text"
                      ref={headingText}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      defaultValue={field[0].children[0].children}
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    />
                  </div>
                </>
              ) : field[0].key == "Paragraph" ? (
                <>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">
                      Edit {field[0].key}
                    </span>
                    <br />
                    <textarea
                      type="text"
                      ref={paragraphText}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    >
                      {field[0].children[0].children}
                    </textarea>
                  </div>
                </>
              ) : field[0].key == "Text" || field[0].key == "Note" ? (
                <>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">Edit Label</span>
                    <br />
                    <input
                      type="text"
                      ref={label}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      defaultValue={field[0].children[0].children}
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    />
                  </div>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">
                      Edit Placeholder
                    </span>
                    <br />
                    <input
                      type="text"
                      ref={placeholder}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      defaultValue={field[0].children[1].props.placeholder}
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    />
                  </div>
                </>
              ) : field[0].key == "Select Menu" ||
                field[0].key == "Check Boxes" ? (
                <>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">Edit Label</span>
                    <br />
                    <input
                      type="text"
                      ref={label}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      defaultValue={field[0].children[0].children}
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    />
                  </div>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">
                      Edit Options/Categories
                    </span>
                    <br />
                    {STATE_options.map((op, i) => {
                      return (
                        <div className="relative">
                          <input
                            type="text"
                            className="border-none rounded-md p-2 mt-1 text-off w-full"
                            value={op}
                            onChange={(e) => {
                              let arrOptions = [...STATE_options];
                              arrOptions[i] = e.target.value;
                              STATE_setOptions(arrOptions);
                              console.log(STATE_options);
                            }}
                            onKeyUp={(e) => e.key === "Enter" && updateField()}
                          />
                          {i != 0 && (
                            <div className="absolute top-[6px] right-[2px]">
                              <AiFillCloseCircle
                                className="text-med cursor-pointer"
                                size={12}
                                onClick={() => {
                                  STATE_setOptions([
                                    ...STATE_options.filter(
                                      (op) => op !== STATE_options[i]
                                    ),
                                  ]);
                                }}
                              ></AiFillCloseCircle>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div className="mt-2">
                      <BsPlusCircleDotted
                        className="text-med mx-auto cursor-pointer"
                        size={30}
                        onClick={() =>
                          STATE_setOptions((prevOptions) => [
                            ...prevOptions,
                            "",
                          ])
                        }
                      ></BsPlusCircleDotted>
                    </div>
                  </div>
                </>
              ) : field[0].key == "Date" || field[0].key == "Slider" ? (
                <>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">Edit Label</span>
                    <br />
                    <input
                      type="text"
                      ref={label}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      defaultValue={field[0].children[0].children}
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    />
                  </div>
                </>
              ) : field[0].key == "File Upload" ? (
                <>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">Edit Label</span>
                    <br />
                    <input
                      type="text"
                      ref={label}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      defaultValue={field[0].children[0].children}
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    />
                  </div>
                  <div className="my-2 mx-[16px]">
                    <span className="text-xs text-off ml-0.5">
                      Edit Upload Accepted File Types
                    </span>
                    <br />
                    <select
                      ref={acceptType}
                      className="border-none rounded-md p-2 mt-1 text-off w-full"
                      defaultValue={field[0].children[1].props.accept}
                      onKeyUp={(e) => e.key === "Enter" && updateField()}
                    >
                      <option value="*">All</option>
                      <option value="image/*">Images</option>
                      <option value=".pdf">PDFs</option>
                      <option value="audio/*,video/*">Audio/Video</option>
                    </select>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="flex mt-6 w-[19rem] mb-2 mx-4 rounded-lg text-background ">
                <button
                  type="button"
                  className="w-full py-2 rounded-lg font-medium bg-red-500 mr-2"
                  onClick={removeField}
                >
                  Remove
                </button>
                <button
                  type="button"
                  className="w-full py-2 rounded-lg font-medium bg-dark ml-2"
                  onClick={updateField}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
