import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Button,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import {BsFillFolderFill} from "react-icons/bs";
import {createFolder} from "./helpers/createFolder";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext} from "react";

export default function CreateFolder({
  addFolder,
  setAddFolder,
  path,
  folderName,
  setFolderName,
  setFiles,
  setSelectedFile,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const toast = useToast();

  return (
    <Modal
      isOpen={addFolder}
      onClose={() => {
        setFolderName("");
        setAddFolder(!addFolder);
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create folder</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={BsFillFolderFill} color="gray.600" />
            </InputLeftElement>
            <Input
              placeholder="Enter folder's name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              size="md"
            />
          </InputGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() =>
              createFolder(
                dashState,
                setFolderName,
                setAddFolder,
                path,
                folderName,
                setFiles,
                setSelectedFile,
                toast
              )
            }
            colorScheme="blue"
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
