export const colors = {
  "in-bed":"#B8B7FF",
  "asleep":"#5754FF",
  "awakenings":"#FF7A01",
  "nap":"#03D563",
  "exercise":"#037035",
  "caffeine":"#CDA000",
  "alchohol":"#000000",
  "drugs":"#FF0000"
  }

export const titles = {
  "#B8B7FF":"in bed",
  "#5754FF":  "asleep",
  "#FF7A01":  "awakening",
  "#03D563":  "nap",
  "#037035":  "exercise",
  "#CDA000":  "caffeine",
  "#000000":  "alchohol",
  "#FF0000":  "drugs"
  }

const calculateTimeDifferenceInMinutes = (startDate, endDate) => {
  const timeDifference = new Date(endDate).getTime() - new Date(startDate).getTime();
  const minutes = Math.floor(timeDifference / (1000 * 60));
  return minutes;
}

// creates new intervals with activity added 
function addActivityToTimeline(formattedIntervals, activity, newIntervals, firstTime) {
  let newnewIntervals = []
  if (!firstTime) {
    formattedIntervals = newIntervals
  }
  
  formattedIntervals.forEach((interval, index, intervalsArray) => {

    if (activity.startTime >= interval.startTime && activity.endTime < interval.endTime) {
      const newInterval1 = {
        startTime: interval.startTime,
        endTime: activity.startTime,
        minutesInBetween: calculateMinutesBetween(interval.startTime, activity.startTime),
        color: interval.color,
      };

      const newInterval2 = {
        startTime: activity.startTime,
        endTime: activity.endTime,
        minutesInBetween: calculateMinutesBetween(activity.startTime, activity.endTime),
        color: activity.color,
      };

      const newInterval3 = {
        startTime: activity.endTime,
        endTime: interval.endTime,
        minutesInBetween: calculateMinutesBetween(activity.endTime, interval.endTime),
        color: interval.color,
      };
      newnewIntervals.push(newInterval1, newInterval2, newInterval3);

      } else{
          newnewIntervals.push(interval);
      }
    });
    return [newnewIntervals, firstTime]
    }
  
// adds activities to timeline if they should be on timeline
   function addsActivitiesToIntervals(data, sleepData) {
    const formattedIntervals = data.intervals;
    const formattedActivities = sleepData.activities;

    let newIntervals = []
    let firstTime = true
    let formattedIntervalsGiven = formattedIntervals

    formattedActivities.forEach((activity) => {
      if ((new Date(activity.startTime)>new Date(formattedIntervals[0].startTime))&& (new Date(activity.endTime)<new Date(formattedIntervals[4].endTime))){
        let results = addActivityToTimeline(formattedIntervalsGiven, activity, newIntervals, firstTime)
        newIntervals = results[0]
        formattedIntervalsGiven = newIntervals
        firstTime = results[1]
      } 
    });
  
    const intervalstouse = newIntervals.length > 0? newIntervals : formattedIntervals
    let newdata = {intervals:"", differenceInMinutes:""}
    newdata.intervals = intervalstouse
    newdata.differenceInMinutes = data.differenceInMinutes
    return newdata
  }

  //main function calc sleep intervals 
export function calculateSleepIntervals(sleepData) {
  const data = calculateSleepIntervalsData(sleepData);
  const finalData = addsActivitiesToIntervals(data, sleepData)
  return finalData;
}

function calculateMinutesBetween(start, end) {
  return Math.round((end - start) / (1000 * 60));
}

// function that calcs timeline log sizes
  export function calculateSleepIntervalsData(sleepData) {
    const startTimeFirstInterval2 = new Date(new Date(sleepData.timeInbed).setHours(new Date(sleepData.timeInbed).getHours() - 14))
    const endTimeLastInterval2 = new Date(new Date(sleepData.timeOutofbed).setHours(new Date(sleepData.timeOutofbed).getHours() + 8))
    const differenceInMinutes = calculateTimeDifferenceInMinutes(startTimeFirstInterval2, endTimeLastInterval2)
    const minutesInBedToAsleep = calculateTimeDifferenceInMinutes(sleepData.timeInbed, sleepData.timeAsleep);
    const minutesAsleepToAwake = calculateTimeDifferenceInMinutes(sleepData.timeAsleep, sleepData.timeAwake);
    const minutesAwakeToOutBed = calculateTimeDifferenceInMinutes(sleepData.timeAwake, sleepData.timeOutofbed);
    const minutesBeforeInBed = calculateTimeDifferenceInMinutes(startTimeFirstInterval2, sleepData.timeInbed);
    const minutesAfterOutBed = calculateTimeDifferenceInMinutes(sleepData.timeOutofbed, endTimeLastInterval2);
  
    const toReturn = {
      differenceInMinutes: differenceInMinutes,
      intervals: [
        {
          startTime: startTimeFirstInterval2,
          endTime: sleepData.timeInbed,
          minutesInBetween: minutesBeforeInBed,
          color: "#E6E6E6",
        },
        {
          startTime: (sleepData.timeInbed),
          endTime: (sleepData.timeAsleep),
          minutesInBetween: minutesInBedToAsleep,
          color: "#B8B7FF",
        },
        {
          startTime: (sleepData.timeAsleep),
          endTime: (sleepData.timeAwake),
          minutesInBetween: minutesAsleepToAwake,
          color: "#5754FF",
        },
        {
          startTime: (sleepData.timeAwake),
          endTime: (sleepData.timeOutofbed),
          minutesInBetween: minutesAwakeToOutBed,
          color: "#B8B7FF",
        },
        {
          startTime: (sleepData.timeOutofbed),
          endTime: (endTimeLastInterval2),
          minutesInBetween: minutesAfterOutBed,
          color: "#E6E6E6",
        },
      ]
    };
    return toReturn;
  }
  
  export const formatMonthAndDay = (date) => {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const formattedDate = new Date(date);
    const month = formattedDate.getMonth(); 
    const day = formattedDate.getDate();

    return `${monthNames[month]} ${day}`;
  };


export const getStartOfWeek = (date) => {
  const dayOfWeek = date.getDay();
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};