import {
  Box,
  Text,
  useToast,
  Center,
  Flex,
  Button,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  StatGroup,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormLabel,
  FormControl,
  InputLeftAddon,
  Portal,
  Select,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import React, {useState, useEffect, useRef} from "react";
export default function SelectPatient({
  patientId,
  setPatientId,
  setFields,
  state,
  patientsMap,
}) {
  const [searchText, setSearchText] = useState("");
  const [showSearch, setShowSearch] = useState("");
  const [matchPatients, setMatchPatients] = useState([]);
  const searchId = useRef();
  const initialFocusRef = React.useRef();

  return (
    <Flex w="full" align={"center"} gap={6} justify={"space-between"} mb="7">
      <Popover
        right-start
        isOpen={showSearch}
        initialFocusRef={initialFocusRef}
        onClose={() => {
          setShowSearch(false);
        }}
      >
        <PopoverTrigger>
          <Box id="search" flex={1}>
            <FormLabel fontSize={"sm"} ml={"1"} color={"gray.500"}>
              Search patient
            </FormLabel>
            <InputGroup size="sm">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" />
              </InputLeftElement>

              <Input
                size="sm"
                variant="outline"
                rounded={"md"}
                type="text"
                onChange={(e) => {
                  clearTimeout(searchId.current);
                  searchId.current = setTimeout(() => {
                    let d = searchMatch(state.patients, e.target.value);
                    setMatchPatients(d);
                    if (d.length > 0) setShowSearch(true);
                  }, 0);

                  setSearchText(e.target.value);
                }}
                value={searchText}
                ref={initialFocusRef}
                placeholder="Search..."
              />
            </InputGroup>
          </Box>
        </PopoverTrigger>

        <PopoverContent zIndex={99999}>
          <PopoverArrow />

          <PopoverBody>
            <TableContainer overflowY="auto" maxH={"200px"}>
              <Table size="md">
                <Tbody>
                  {matchPatients
                    ?.sort((p1, p2) => {
                      let name1 = `${p1.lName}, ${p1.fName}`.toLocaleLowerCase(
                        "en-CA"
                      );
                      let name2 = `${p2.lName}, ${p2.fName}`.toLocaleLowerCase(
                        "en-CA"
                      );
                      if (name1 < name2) {
                        return -1;
                      }
                      if (name1 > name2) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((p, i) => {
                      return (
                        <Tr
                          p="0"
                          cursor={"pointer"}
                          _hover={{
                            backgroundColor: "gray.200",
                          }}
                          key={i}
                          onClick={() => {
                            setPatientId(p.pid);
                            setFields((prev) => ({
                              ...prev,
                              pid: p.pid,
                              firstName: p.fName,
                              lastName: p.lName,
                              pEmail: p.email,
                              phone: p.phone,
                            }));
                            setSearchText(`${p.fName} ${p.lName}`);
                            setShowSearch(false);
                          }}
                        >
                          <Td p="2" key={i} fontSize={"15px"}>
                            {`${p.lName}, ${p.fName}`}
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <FormControl id="categoryId" isRequired flex={1}>
        <FormLabel fontSize={"sm"} color={"gray.500"}>
          Select Patient
        </FormLabel>
        <Select
          variant={"outline"}
          size={"sm"}
          rounded={"md"}
          w={"full"}
          placeholder="-"
          value={patientId || "-"}
          onChange={(e) => {
            setPatientId(e.target.value);

            let p = patientsMap[e.target.value];

            if (p) {
              setSearchText(`${p.fName} ${p.lName}`);
              setFields((prev) => ({
                ...prev,
                pid: p.pid,
                firstName: p.fName,
                lastName: p.lName,
                pEmail: p.email,
                phone: p.phone,
              }));
            }
            setShowSearch(false);
          }}
        >
          {state.patients
            .sort((p1, p2) => {
              let name1 = `${p1.lName}, ${p1.fName}`.toLocaleLowerCase("en-CA");
              let name2 = `${p2.lName}, ${p2.fName}`.toLocaleLowerCase("en-CA");
              if (name1 < name2) {
                return -1;
              }
              if (name1 > name2) {
                return 1;
              }
              return 0;
            })
            .map((c, i) => (
              <option value={c?.pid}>{`${c.lName}, ${c.fName}`}</option>
            ))}
        </Select>
      </FormControl>
    </Flex>
  );
}

function searchMatch(_data = [], entri) {
  let fields = ["fName", "lName", "email", "phone"];

  if (entri === "") return [];
  let d = [];
  try {
    let sanitizedEntri = entri.replace(
      /[$^.*+?=!:|/()[\]{}]/g,
      (e) => "\\" + e
    );
    let regex = new RegExp(`${sanitizedEntri}`, "i");

    d = _data.filter((e, i) => {
      if (`${e.fName} ${e.lName}`.match(regex)) return true;
      for (let field of fields) {
        if (typeof e[field] === "string" && e[field]?.match(regex)) return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }

  return d;
}
