import React, {useEffect, useMemo} from "react";
import {Box, Table, Thead, Tbody, Tr, Th, Td, Text} from "@chakra-ui/react";
import {stringifyValue} from "./helpers/stringifyValue";
import transactionFilter from "./helpers/transactionFilter";

export default function GroupedTransactionsList({
  transactions,
  groupings,
  query,
}) {
  transactions = transactions.filter((tr) =>
    transactionFilter(query.filters || {}, tr)
  );

  const groupedTransactions = useMemo(() => {
    const aggregateTransactionData = (acc, transaction) => {
      acc.patientAmount =
        (acc.patientAmount || 0) + parseInt(transaction.patientAmount);
      acc.tppAmount = (acc.tppAmount || 0) + parseInt(transaction.tppAmount);
      acc.amountPaidByPatient =
        (acc.amountPaidByPatient || 0) +
        parseInt(transaction.amountPaidByPatient);
      acc.amountPaidByTpp =
        (acc.amountPaidByTpp || 0) + parseInt(transaction.amountPaidByTpp);
      return acc;
    };

    const groupData = (acc, transaction, level, path) => {
      if (level >= groupings.length) {
        return aggregateTransactionData(acc, transaction);
      }

      const groupingKey = stringifyValue(
        groupings[level].field
          .split(".")
          .reduce((o, k) => (o ? o[k] : null), transaction),
        groupings,
        groupings[level].label
      );

      const newPath = path.concat(groupingKey);

      if (!acc[groupingKey]) {
        acc[groupingKey] = {_path: newPath, _level: level};
      }

      aggregateTransactionData(acc[groupingKey], transaction);
      groupData(acc[groupingKey], transaction, level + 1, newPath);
      return acc;
    };

    //console.log(groupings);
    return transactions.reduce(
      (acc, transaction) => groupData(acc, transaction, 0, []),
      {}
    );
  }, [transactions, groupings]);

  const recalibrateTotal = (total, level) => {
    return level + 1 != groupings.length ? total * 2 : total;
  };

  const renderGroupedRows = (groupedData, level = 0) => {
    return Object.entries(groupedData).map(([key, value], index) => {
      if (key === "_path" || key === "_level") return null;

      const bgColor =
        level % 5 === 0
          ? "gray.50"
          : level % 5 === 1
          ? "gray.200"
          : level % 5 === 2
          ? "gray.300"
          : level % 5 === 3
          ? "gray.400"
          : level % 5 === 4
          ? "gray.500"
          : "gray.600";
      const isGroup = value._path;

      if (isGroup) {
        // Render the group name and its totals
        return (
          <React.Fragment key={key}>
            <Tr bg={bgColor}>
              <Td fontSize="md" fontWeight="semibold">
                {value._path.join(" > ")}
              </Td>
              <Td fontSize="15px">
                <Text fontWeight="semibold" display="inline-block">
                  Patient:
                </Text>{" "}
                ${recalibrateTotal(value?.patientAmount, level)}
                <br />
                <Text fontWeight="semibold" display="inline-block">
                  Third Party Payer:
                </Text>{" "}
                ${recalibrateTotal(value?.tppAmount, level)}
                <br />
                <Text fontWeight="semibold" display="inline-block" mt="2">
                  Total:
                </Text>{" "}
                $
                {recalibrateTotal(
                  value?.tppAmount + value?.patientAmount,
                  level
                )}
              </Td>
              <Td fontSize="15px">
                <Text fontWeight="semibold" display="inline-block">
                  Patient:
                </Text>{" "}
                ${recalibrateTotal(value?.amountPaidByPatient, level)}
                <br />
                <Text fontWeight="semibold" display="inline-block">
                  Third Party Payer:
                </Text>{" "}
                ${recalibrateTotal(value?.amountPaidByTpp, level)}
                <br />
                <Text fontWeight="semibold" display="inline-block" mt="2">
                  Total:
                </Text>{" "}
                $
                {recalibrateTotal(
                  value?.amountPaidByTpp + value?.amountPaidByPatient,
                  level
                )}
              </Td>
              <Td fontSize="15px">
                <Text fontWeight="semibold" display="inline-block">
                  Patient:
                </Text>{" "}
                $
                {recalibrateTotal(
                  value?.patientAmount - value?.amountPaidByPatient,
                  level
                )}
                <br />
                <Text fontWeight="semibold" display="inline-block">
                  Third Party Payer:
                </Text>{" "}
                $
                {recalibrateTotal(
                  value?.tppAmount - value?.amountPaidByTpp,
                  level
                )}
                <br />
                <Text fontWeight="semibold" display="inline-block" mt="2">
                  Total:
                </Text>{" "}
                $
                {recalibrateTotal(
                  value?.tppAmount -
                    value?.amountPaidByTpp +
                    (value?.patientAmount - value?.amountPaidByPatient),
                  level
                )}
              </Td>
            </Tr>
            {/* Render sub-groups or items */}
            {renderGroupedRows(value, level + 1)}
          </React.Fragment>
        );
      } else {
        return;
      }
    });
  };

  return (
    <Box overflowY="auto">
      <Table size="md">
        <Thead>
          <Tr>
            <Th fontSize={"11px"}>Group</Th>
            <Th fontSize={"11px"}>Service Cost</Th>
            <Th fontSize={"11px"}>Cost Paid</Th>
            <Th fontSize={"11px"}>Outstanding Balance</Th>
          </Tr>
        </Thead>
        <Tbody>{renderGroupedRows(groupedTransactions)}</Tbody>
      </Table>
    </Box>
  );
}
