import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Icon,
  IconButton,
  Stack,
  VStack,
  Badge,
  Flex,
  Text,
  StackDivider,
} from "@chakra-ui/react";

import {BsPlusCircleDotted} from "react-icons/bs";
import {AiOutlineDelete} from "react-icons/ai";

export default function CodesList({
  cList,
  setCList,
  setCodesType,
  setSelectedIndx,
  selectedIndx,
}) {
  return (
    <Flex direction="column" align="start">
      <VStack
        gap={2}
        w="full"
        divider={<StackDivider borderColor="gray.200" />}
      >
        <VStack maxH="150px" align="start" overflowY="scroll" p={1} w="full">
          {cList.map((c, i) => (
            <Flex justify="space-evenly" w="full" align="flex-end" mb={2}>
              <Box>
                <Text fontSize="11px" color="gray.600">
                  Service Codes
                </Text>
                <Input
                  size="xs"
                  w="90px"
                  value={c[0]}
                  onFocus={() => {
                    setCodesType("service");
                    setSelectedIndx(i);
                  }}
                  onChange={(e) => {
                    setCList((prev) => {
                      let newValue = [...prev];
                      newValue[i] = [...newValue[i]];
                      newValue[i][0] = e.target.value;
                      return newValue;
                    });
                  }}
                />
              </Box>
              <Box>
                <Text fontSize="11px" color="gray.600">
                  Diagnostic Codes
                </Text>
                <Input
                  size="xs"
                  w="90px"
                  value={c[1]}
                  onFocus={() => {
                    setCodesType("diagnostic");
                    setSelectedIndx(i);
                  }}
                  onChange={(e) => {
                    setCList((prev) => {
                      let newValue = [...prev];
                      newValue[i] = [...newValue[i]];
                      newValue[i][1] = e.target.value;
                      return newValue;
                    });
                  }}
                />
              </Box>
              <Box>
                <Text fontSize="11px" color="gray.600">
                  Amount
                </Text>
                <Input
                  type="number"
                  size="xs"
                  w="90px"
                  value={c[2]}
                  onFocus={() => {
                    setSelectedIndx(i);
                  }}
                  onChange={(e) => {
                    setCList((prev) => {
                      let v = e.target.value;
                      if (!isNaN(v)) {
                        let newValue = [...prev];
                        newValue[i] = [...newValue[i]];
                        newValue[i][2] = v === "" ? 0 : v;
                        return newValue;
                      }
                      return prev;
                    });
                  }}
                />
              </Box>
              <Badge
                cursor={"pointer"}
                onClick={() => {
                  if (selectedIndx === i || cList.length === 0) {
                    setSelectedIndx(-1);
                  }

                  setCList((prev) => prev.filter((_, ind) => i !== ind));
                }}
                fontSize="10px"
                rounded="md"
                p="1"
                px="2"
                colorScheme="red"
              >
                <Icon as={AiOutlineDelete} fontSize="12px" mt="-0.5" /> Delete
              </Badge>
            </Flex>
          ))}
        </VStack>

        <Icon
          cursor="pointer"
          onClick={() => {
            setCList((prev) => [["", "", ""], ...prev]);
          }}
          as={BsPlusCircleDotted}
          fontSize={"26px"}
          mb={"4"}
        ></Icon>
      </VStack>
    </Flex>
  );
}
