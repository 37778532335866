import React, {
    Fragment,
    useState,
    useEffect,
    useRef,
    useLayoutEffect,
    useContext,
  } from "react";
  import {useParams, useNavigate} from "react-router-dom";
  import PermissionSetter from "./PermissionSetter";
  import PermissionsByUser from "./PermissionsByUser";
  import {crud} from "../crudRequests";


  export default function PermissionsView({state,dispatch}) {
    const [noSuperAdmins, setNoSuperAdmins] = useState([]);
   
    useEffect(() => {
        if(state?.admin?.super){
        const abortController = new AbortController();
        let query = state.selectedLocation?{location:state.selectedLocation,super:false}:{super:false}
      crud(state,[{
        db: state.db,
        collection: "admins",
        parameters: [query],
        method: "find",
         }],null,abortController.signal)
        .then((res) => {
           setNoSuperAdmins(res.data[0]);
         
        })
        .catch((error) => {
     
          if (error.code !== "ERR_CANCELED"); // window.location.href = '../login';
        });
      return () => {
        abortController.abort();
      };
    }  
    }, [state.selectedLocation]);
  
    return (
        <div className="relative p-6 pb-4 pt-5 w-full h-full flex flex-col items-center patients-table-container overflow-y-hidden">
              <div className="flex flex-wrap max-w-[1300px] max-h-[700px] h-full overflow-y-scroll sbar2 p-4 lg:space-y-0 space-y-10 justify-around items-center bg-gradient-to-b rounded-3xl from-[#2d29ff44] to-[#7b79ff0f] w-full">
                  {!state?.admin?.super?<PermissionsByUser user={state?.admin}/>:
                  <PermissionSetter userType='noSuperAdmin' state={state} userList = {noSuperAdmins} dispatch={dispatch} setNoSuperAdmins={setNoSuperAdmins}/>}
                  <PermissionSetter userType='clinicians' state={state} userList = {state.doctors} dispatch={dispatch}/>
              </div>
        </div>
  
    );
  }
  
