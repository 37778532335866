import {useReducer, useState, useEffect, createContext} from "react";
import {useLocation} from "react-router-dom";
import {scheduleReducer, init} from "../additional_files/reducer";
import {verifyUser} from "../authRequests";
import {Fade} from "@chakra-ui/react";
import Navigation from "../components/Navigation";
import TransactionsTable from "../components/Billing/TransactionsTable";

export const DashStateContext = createContext();
export const DashDispatchContext = createContext();

export default function Billing({renderedFromPatient}) {
  const {state} = useLocation();

  const [dashState, dispatch] = useReducer(scheduleReducer, state, init);

  useEffect(function () {
    verifyUser(state ? state.jwt : "");
  }, []);

  return (
    <>
      {!renderedFromPatient ? (
        <Navigation state={dashState} dispatch={dispatch}>
          <DashStateContext.Provider value={dashState}>
            <DashDispatchContext.Provider value={dispatch}>
              <TransactionsTable />
            </DashDispatchContext.Provider>
          </DashStateContext.Provider>
        </Navigation>
      ) : (
        <TransactionsTable renderedFromPatient={renderedFromPatient} />
      )}
    </>
  );
}
/*   <Fade in={dashState} transition={{enter: {duration: 0.5}}} mx="auto">
            <DashStateContext.Provider value={dashState}>
              <DashDispatchContext.Provider value={dispatch}>
                <TransactionsTable />
              </DashDispatchContext.Provider>
            </DashStateContext.Provider>
          </Fade> */
