import React, {useState} from "react";

import PrescriptionGenerator from "./PrescriptionGenerator";
import PrescriptionFax from "./PrescriptionFax";
import {
  Grid,
  GridItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

const PrescriptionModal = ({modal, setModal, ...props}) => {
  const [currPage, setCurrPage] = useState("prescription");
  const [faxUrl, setFaxUrl] = useState(null);
  return (
    <>
      <Modal
        width
        onClose={() => {
          setCurrPage("prescription");
          setModal(false);
        }}
        size="xl"
        isOpen={modal}
        blockScrollOnMount
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="950px" overflowY="scroll">
          <Grid justifyContent="center" justifyItems="center">
            <GridItem>
              <ModalCloseButton />
              {currPage === "prescription" && (
                <PrescriptionGenerator
                  {...props}
                  faxUrl={faxUrl}
                  setFaxUrl={setFaxUrl}
                  setCurrPage={setCurrPage}
                />
              )}
              {/* Faxing component */}
              {currPage === "fax" && (
                <PrescriptionFax state={props.state} faxUrl={faxUrl} />
              )}
            </GridItem>
          </Grid>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PrescriptionModal;
