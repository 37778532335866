import {crud} from "../../../crudRequests";

import Swal from "sweetalert2";

export async function deleteItem({dashState, product, toast, setProducts}) {
  Swal.fire({
    title: "Delete Product?",
    text: "Are you sure you want to delete this product?",
    icon: "warning",
    showDenyButton: true,
    confirmButtonText: "Delete",
    denyButtonText: `Back`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const res = await crud(dashState, [
          {
            db: dashState.db,
            collection: "products",
            parameters: [{id: product.id}],
            method: "deleteOne",
          },
        ]);

        setProducts((prev) => prev.filter((p) => p.id !== product.id));
      } catch (error) {
        toast({
          title: "Failed to delete product",
          description:
            error?.message || "An error occurred while deleting the product.",
          status: "error",
          isClosable: true,
        });
      }
    }
  });
}
