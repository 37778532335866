import { 
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useToast
} from "@chakra-ui/react"
import Path from "../Patients/Files/Path";
import SearchItems from "../Patients/Files/SearchItems";
import ItemsTable from "../Patients/Files/ItemsTable";
import {useEffect, useState} from "react";
import {viewFiles} from "../Patients/Files/helpers/viewFiles";


export default function TaskFiles({patient, dashState, setFilesArray, filesArray, isDisabled}) {
  const [path, setPath] = useState("")
  const [files, setFiles] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const toast = useToast()
  const {isOpen, onOpen, onClose} = useDisclosure()

  useEffect(() => {
    if (patient) {
      viewFiles(dashState, setFiles, setSelectedFile, path, toast)
    }
  }, [path])

  const handleClick = () => {
    setPath(patient["pid"] + "/")
    onOpen()
  }

  return (
    <>
      <Button 
        onClick={handleClick}
        isDisabled={isDisabled}
        colorScheme="purple"
      >
        Attach File
      </Button>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxH="80vh">
          <ModalHeader>Attach File</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto">
            <Box>
              <Flex mt="8" ml="1">
              <Path
                path={path}
                setFiles={setFiles}
                setSelectedFile={setSelectedFile}
                toast={toast}
                setPath={setPath}
                patient={patient}
                state={dashState}
              />
              <Spacer />
              </Flex>
              <SearchItems searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
              <ItemsTable
                files={files}
                searchQuery={searchQuery}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                path={path}
                setPath={setPath}
                toast={toast}
                isTask={true}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
              <Button variant='ghost' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button colorScheme='blue' onClick={() => {
                  if (selectedFile) {
                    setFilesArray([...filesArray, {name: selectedFile, path: path}])
                    onClose()
                  }
                }}
              >
                Add File
              </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
