import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useEffect, useState} from "react";
import {Box} from "@chakra-ui/react";
import CreateRequisitionDialog from "./CreateRequisitionDialog";
import PatientRequisitionTable from "./PatientRequisitionTable";
import {crud} from "../../../crudRequests";

const useGetPatientRequisitions = (state, patientId) => {
  const [requisitionList, setRequisitionList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await crud(state, [
        {
          db: state.db || "blh",
          collection: "requisitions",
          method: "find",
          parameters: [
            {
              pid: patientId,
            },
          ],
        },
      ]);
      setRequisitionList(data.data[0] || []);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return {data: requisitionList, loading, error, refetch: fetchData};
};
export default function Requisitions({}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const {data, loading, error, refetch} = useGetPatientRequisitions(
    dashState,
    patient.pid
  );

  return (
    <Box
      mx="auto"
      my="6"
      p="8"
      boxShadow="md"
      textAlign="center"
      borderRadius="sm"
    >
      <CreateRequisitionDialog
        dashState={dashState}
        patient={patient}
        refetch={refetch}
      />
      <PatientRequisitionTable
        dashState={dashState}
        patient={patient}
        loading={loading}
        data={data}
      />
    </Box>
  );
}
