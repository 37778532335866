import {
  lightFormat,
  nextSunday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  subDays,
  endOfDay,
  setDate,
  addMonths,
  lastDayOfMonth,
  startOfMonth,
  addWeeks,
  isSameMonth,
} from "date-fns";
export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dayOpt = ["first", "second", "third", "fourth", "last"];

export default function monthlyHelper(recurrence, appt) {
  let h = [
    nextSunday,
    nextMonday,
    nextTuesday,
    nextWednesday,
    nextThursday,
    nextFriday,
    nextSaturday,
  ];

  let duration = parseInt(appt.duration.split(" ")[0]) * 60000;

  let frequency = recurrence?.frequency;
  let pattern = recurrence?.pattern;
  let range = recurrence?.range;
  if (frequency && pattern && range) {
    let {every, day, the, weekday} = pattern;
    let {rangeType, occurrences, startDate, endDate} = range;
    // weekdays = [...weekdays];
    day = Number.parseInt(day);
    startDate = new Date(
      ...startDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );
    endDate = new Date(
      ...endDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );
    let hours = new Date(appt.ISOdate).getHours();
    let minutes = new Date(appt.ISOdate).getMinutes();
    let time = hours * 3600000 + minutes * 60000;

    if (pattern.hasOwnProperty("day")) {
      let arr = [];
      let currentDate = startDate.getDate();
      let nextDate;
      if (currentDate <= day) {
        let ld = lastDayOfMonth(startDate);
        nextDate = ld.getDate() < day ? ld : setDate(startDate, day);
      } else {
        nextDate = addMonths(setDate(startDate, day), Number(every));
      }

      let n = occurrences;

      switch (rangeType) {
        case "END_BY":
          while (
            nextDate.getTime() + time >= startDate.getTime() &&
            nextDate.getTime() + time + duration < endOfDay(endDate).getTime()
          ) {
            arr.push(nextDate.getTime() + time);
            let nd = addMonths(nextDate, Number(every));
            let ld = lastDayOfMonth(nd);
            nextDate = ld.getDate() < day ? ld : setDate(nd, day);
          }

          break;
        case "END_AFTER":
          while (nextDate.getTime() + time >= startDate.getTime() && n > 0) {
            arr.push(nextDate.getTime() + time);
            let nd = addMonths(nextDate, Number(every));
            let ld = lastDayOfMonth(nd);
            nextDate = ld.getDate() < day ? ld : setDate(nd, day);
            n--;
          }
          break;

        default:
          break;
      }

      return arr;
    } else if (pattern.hasOwnProperty("weekday")) {
      let arr = [];
      let d = weekdays.indexOf(weekday);
      let position = dayOpt.indexOf(the);
      function next(date) {
        let stOfMonth = startOfMonth(date);
        let dayBefore = subDays(stOfMonth, 1);
        let first = h[d](dayBefore);
        let dayOnPos = addWeeks(first, position);
        return isSameMonth(dayOnPos, first)
          ? dayOnPos
          : addWeeks(first, position - 1);
      }

      let dateOnCurrentMonth = next(startDate);
      let nextDate =
        dateOnCurrentMonth >= startDate
          ? dateOnCurrentMonth
          : next(addMonths(startDate, Number(every)));

      let n = occurrences;
      switch (rangeType) {
        case "END_BY":
          while (
            nextDate.getTime() + time >= startDate.getTime() &&
            nextDate.getTime() + time + duration <= endOfDay(endDate).getTime()
          ) {
            arr.push(nextDate.getTime() + time);
            nextDate = next(addMonths(nextDate, Number(every)));
          }

          break;
        case "END_AFTER":
          while (nextDate.getTime() + time >= startDate.getTime() && n > 0) {
            arr.push(nextDate.getTime() + time);
            nextDate = next(addMonths(nextDate, Number(every)));
            n--;
          }
          break;

        default:
          break;
      }

      return arr;
    }
    return [];
  }

  return [];
}
