import React, {useState, useMemo, useRef, useEffect, useContext} from "react";
import {crud} from "../../../crudRequests";
import {lightFormat} from "date-fns";
import {months, weekday} from "../../../additional_files/helpers";
import {PiWarningBold} from "react-icons/pi";
import {
  fullInvoice,
  patientInvoice,
  insurerInvoice,
} from "../../appointments/payment/uploads";

import {v4 as uuidv4} from "uuid";

import swal from "sweetalert";
import {PiArrowCircleDownFill} from "react-icons/pi";

//import {SocketContext} from "../additional_files/context";

import DefineServiceCriteria from "./DefineServiceCriteria";
import {useToast} from "@chakra-ui/react";
export default function BookNonSessionService({
  patient,
  state,
  dispatch,
  setNsServices,
  setShowEditOrCreateModal,

  setSelectedItem,
}) {
  const insurersMap = useMemo(() => {
    if (state?.insurers.length > 0) {
      return state?.insurers.reduce((ac, e) => {
        ac[e.iid] = e.name || "";
        return ac;
      }, {});
    }
    return {};
  }, [state?.insurers]);

  const providerTypesMap = useMemo(() => {
    if (state?.providerTypes.length > 0) {
      return state?.providerTypes.reduce((ac, e) => {
        ac[e.typeId] = e.name || "";
        return ac;
      }, {});
    }
    return {};
  }, [state?.providerTypes]);

  const toast = useToast();
  const searchRef = useRef(null);
  const bookServiceRef = useRef(null);
  const bookDateRef = useRef(null);
  const bookTimeRef = useRef(null);
  const bookDurationRef = useRef(null);
  const bookTypeRef = useRef(null);
  const bookNoteRef = useRef(null);
  const bookError = useRef(null);
  const clickRef = useRef(true);

  const [doctor, setDoctor] = useState(null);

  const [bookingMedia, setBookingMedia] = useState({
    "in-person": true,
    phone: false,
  });

  const [firstTime, setFirstTime] = useState(false);

  const [selectdServiceIndx, setSelectedServiceIndx] = useState();
  const [services, setServices] = useState(() => {
    let s = new Set(doctor?.services || []);
    return state?.services.filter(
      (e) => s.has(e.serviceId) && e["non-sessions"]
    );
  });

  const [duration, setDuration] = useState(
    services[selectdServiceIndx]?.defaultDuration || "15 min"
  );

  // const socket = useContext(SocketContext);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");

  const [creating, setCreating] = useState(false);
  const criteriaKeyRef = useRef(null);
  const [serviceCriteriaKey, setServiceCriteriaKey] = useState("");
  let service = services[selectdServiceIndx];

  let criteria = useMemo(() => {
    let crt = state.serviceCriteria.filter((c) => c.type !== "Insurers");

    return crt.concat([
      {type: "Insurers", list: state.insurers},
      {type: "Clinicians", list: state?.doctors || [state?.doctor]},
      {type: "Provider Types", list: state.providerTypes},
    ]);
  }, [state.serviceCriteria, state.insurers, state.doctors]);

  let initialCriteria = useMemo(() => {
    let initialCriteriaIdValues = {};
    let initialCriteriaValues = {};

    let tpp = "";

    if (Array.isArray(patient?.tpp)) {
      let insr = patient?.tpp.find(
        (ins) => parseInt(ins.noOfInsuredSessions ?? 0) > 0
      );

      insr =
        insr ||
        patient?.tpp.find((ins) => ins.noOfInsuredSessions === undefined);

      tpp = insr ? insr.insurerId : "";
    }

    if (tpp) {
      initialCriteriaIdValues["Insurers"] = tpp;
      initialCriteriaValues["Insurers"] = insurersMap[tpp] || "";
    }

    if (doctor) {
      initialCriteriaIdValues["Clinicians"] = doctor.did;
      initialCriteriaValues["Clinicians"] = doctor.name;
    }

    if (doctor && providerTypesMap[doctor.providerType]) {
      initialCriteriaIdValues["Provider Types"] = doctor.providerType;
      initialCriteriaValues["Provider Types"] =
        providerTypesMap[doctor.providerType];
    }

    let initialSelectedCriteria = criteria.filter((e) => {
      return (
        (doctor && e.type === "Clinicians") ||
        (doctor &&
          providerTypesMap[doctor.providerType] &&
          e.type === "Provider Types") ||
        (tpp && insurersMap[tpp] && e.type === "Insurers")
      );
    });

    return {
      initialCriteriaIdValues,
      initialCriteriaValues,
      initialSelectedCriteria,
    };
  }, [patient, patient?.tpp, doctor, criteria]);

  function criteriaFormatKey(data) {
    let en = Object.entries(data).filter(([k, v]) => v !== undefined);
    if (en.length > 0) {
      en = en.map((el) => el.join("|"));
      en.sort();
      return en.join("||");
    } else return "";
  }

  useEffect(() => {
    setServices(() => {
      let s = new Set(doctor?.services || []);
      return state?.services.filter(
        (e) => s.has(e.serviceId) && e["non-sessions"]
      );
    });
    setSelectedServiceIndx("");
  }, [state?.services, doctor]);

  useEffect(() => {
    getRooms();
  }, [state.selectedLocation]);

  function getRooms() {
    let req = [
      {
        db: state.db,
        collection: "rooms",
        parameters: [{lid: state.selectedLocation}],
        method: "find",
      },
    ];

    crud(state, req).then((res) => {
      setRooms(res.data[0] || []);
    });
  }

  const patientTppInfo = useMemo(() => {
    if (Array.isArray(patient?.tpp)) {
      let tpp = criteriaKeyRef.current?.["Insurers"] || false;
      return patient?.tpp.find((ins) => ins.insurerId === tpp);
    }
    return null;
  }, [serviceCriteriaKey, patient]);

  async function book() {
    setCreating(true);
    const aid = uuidv4();
    const invoiceId = id(8);
    const receiptId = id(8);
    if (bookServiceRef.current.value === "" || !doctor || !patient) {
      toast({
        title: "Bookikg error.",
        description:
          "Please select a patient, clinician/technician, and service from the lists to proceed to book the service.",
        status: "warning",
        isClosable: true,
      });

      setCreating(false);
    } else {
      const inputTime = bookTimeRef.current.value;

      const time =
        parseInt(inputTime.split(":")[0]) > 12
          ? (parseInt(inputTime.split(":")[0]) - 12).toString() +
            ":" +
            inputTime.split(":")[1] +
            " PM"
          : parseInt(inputTime.split(":")[0]).toString() === "0"
          ? "12:" + inputTime.split(":")[1] + " AM"
          : parseInt(inputTime.split(":")[0]).toString() === "12"
          ? "12:" + inputTime.split(":")[1] + " PM"
          : parseInt(inputTime.split(":")[0]).toString() +
            ":" +
            inputTime.split(":")[1] +
            " AM";

      let dateArray = bookDateRef.current.value
        .split("-")
        .map((e) => parseInt(e));
      let h_min = inputTime.split(":").map((e) => parseInt(e));
      dateArray[1]--;
      const inputDate = new Date(...dateArray);
      const ISOdate = new Date(...dateArray.concat(h_min)).getTime();
      const date =
        weekday[inputDate.getDay()] +
        ", " +
        months[inputDate.getMonth()] +
        " " +
        inputDate.getDate() +
        ", " +
        inputDate.getFullYear();

      let serviceCriteriaKey = criteriaFormatKey(criteriaKeyRef.current);
      let service = services[bookServiceRef.current.value];
      let costsByCriteria = service.defaultCost || 100;

      if (serviceCriteriaKey)
        costsByCriteria =
          service.costsByCriteria?.[serviceCriteriaKey] ?? costsByCriteria;

      let amount = Number.parseFloat(
        (Number.parseFloat(duration.split(" ")[0]) / 60) *
          Number.parseFloat(costsByCriteria)
      ).toFixed(2);

      let tax = 0;
      if (service?.taxable === true) {
        let typeId = criteriaKeyRef.current?.["Provider Types"];

        if (typeId) {
          let providerType = state.providerTypes.find(
            (t) => t.typeId === typeId
          );
          tax = parseFloat(providerType?.tax || 0);
        }
      }

      amount = (amount * (1 + tax / 100)).toFixed(2);

      let tpp = criteriaKeyRef.current?.["Insurers"] || false;
      let tppAmount = tpp ? amount : 0;

      if (
        patientTppInfo &&
        !isNaN(patientTppInfo.sessionDeductionAmount) &&
        parseFloat(patientTppInfo.sessionDeductionAmount) >= 0
      ) {
        tppAmount = parseFloat(patientTppInfo.sessionDeductionAmount);
      }

      let patientAmount = amount - tppAmount;

      const roomName = aid;
      let locationName =
        state.locations?.find((l) => l.lid === state.selectedLocation)?.name ||
        "";
      const payload = {
        nonSessionService: true,
        aid,
        invoiceId,
        receiptId,
        room: selectedRoom,
        lid: state.selectedLocation,
        locationName,
        pid: patient.pid,
        pName: patient.fName + " " + patient.lName,
        pEmail: patient.email,
        pPhone: patient.phone,
        pConfirmed: true,
        did: doctor.did,
        oid: doctor.oid,
        dName: doctor.name,
        dEmail: doctor.email,
        teleconference: bookTypeRef.current.value === "teleconference",
        telephone: bookTypeRef.current.value === "telephone",

        patientApptLink: `https://teleconference.brightlight.ai/${encodeURIComponent(
          roomName
        )}`,
        clinicApptLink: `https://teleconference.brightlight.ai/${encodeURIComponent(
          roomName
        )}`,
        date: date,
        time: time,
        duration,
        description: bookNoteRef.current.value,
        summary: "",
        cancelled: false,
        noShow: false,
        service: services[selectdServiceIndx]?.serviceName,
        serviceId: services[selectdServiceIndx]?.serviceId,
        serviceCodes: services[selectdServiceIndx]?.serviceCodes || [],
        ISOdate,
        createdBy: `${state.userType}|${state.admin?.id || state.doctor?.did}`,
        paymentStatus: "pending",
        serviceCriteriaKey,
        claim: false,
        serviceAmount: amount,
        serviceTax: parseFloat(tax),
        products: [],
        productTax: 0,
        productAmount: 0,
        amount,
        tpp,
        tppAmount,
        patientAmount,
        amountPaidByPatient: 0,
        amountPaidByTpp: 0,
        tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
        patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
        apptCreatedBy: state.userType === "doctor" ? "clinician" : "admin",
        apptBookingMedia: bookingMedia["in-person"] ? "in-person" : "phone",
        firstTime,
        timestamp: new Date().toISOString(),
      };

      let location = await crud(state, [
        {
          db: state.db,
          collection: "locations",
          parameters: [{lid: state.selectedLocation}],
          method: "findOne",
        },
      ]);
      location = location.data[0];
      let sender = location?.name;

      let transaction = {
        type: "service",
        nonSessionService: true,
        tid: uuidv4(),
        invoiceId: payload.invoiceId,
        receiptId: payload.receiptId,
        aid,
        oid: payload.oid,
        lid: payload.lid,
        locationName,
        pid: patient.pid,
        pName: patient.fName + " " + patient.lName,
        pEmail: patient.email,
        did: payload.did,
        dName: payload.dName,
        dEmail: payload.dEmail,
        time: payload.duration,
        claim: payload.claim,
        description: bookNoteRef.current.value,
        amount,
        products: [],
        productTax: 0,
        productAmount: 0,
        serviceAmount: amount,
        serviceTax: payload.serviceTax,
        paid: 0,
        pending: true,
        cancelled: false,
        serviceId: payload.serviceId,
        serviceType: payload.service.trim(),
        serviceDate: payload.ISOdate,
        insurance: patient.insurance,
        dgCodes: [],
        serviceCodes: payload.serviceCodes,
        billingCodes: [],
        tpp,
        tppAmount,
        patientAmount,
        amountPaidByPatient: 0,
        amountPaidByTpp: 0,
        tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
        patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
        assignedProfessional: "clinician",
        timestamp: payload.timestamp,
      };

      let {pid, did, lid, oid} = payload;
      let socketRooms = [pid, did, lid + oid];

      let sendInvEmail = async (state, transaction) => {
        try {
          let invData = {
            state: state,
            transaction,
            doctor,
            patient,
            tpp: transaction.tpp
              ? state?.insurers.find(
                  (insurer) => insurer.iid === transaction.tpp
                )
              : null,
            nonSessionService: true,
          };
          //  await fullInvoice(invData);
          await Promise.all([patientInvoice(invData), fullInvoice(invData)]);
          if (transaction.tpp) await insurerInvoice(invData);
        } catch (error) {
          console.log(error);
          toast({
            title: "Bookikg error.",
            description: error.message,
            status: "error",
            isClosable: true,
          });
        }
      };

      const updateInsuredSessions = () => {
        if (Array.isArray(patient?.tpp)) {
          let insr = patient?.tpp.find((ins) => ins.insurerId === tpp);

          if (insr && insr.noOfInsuredSessions) {
            let noOfInsuredSessions = parseInt(insr.noOfInsuredSessions) - 1;

            let newPatientTpp = patient?.tpp.map((ins) =>
              ins.insurerId === tpp ? {...ins, noOfInsuredSessions} : ins
            );

            crud(
              state,
              [
                {
                  db: state.db,
                  collection: "patients",
                  parameters: [
                    {pid: payload.pid, "tpp.insurerId": tpp},
                    {$set: {"tpp.$.noOfInsuredSessions": noOfInsuredSessions}},
                  ],
                  method: "updateOne",
                },
              ],
              {
                socket: {
                  rooms: socketRooms,
                  arguments: [
                    "update_pt",
                    payload.pid,
                    {tpp: newPatientTpp},
                    {},
                    [payload.did, payload.lid + payload.oid, payload.pid],
                  ],
                },
              }
            ).then(() => {
              dispatch({
                type: "UPDATE_PATIENT",
                pid: payload.pid,
                payload: {tpp: newPatientTpp},
                options: null,
              });
            });
          }
        }
      };

      let action = () => {
        let pt = patient;
        let requestObjs = [
          {
            db: state.db,
            collection: "nonSessionServices",
            parameters: [payload],
            method: "insertOne",
          },

          {
            db: state.db,
            collection: "billing",
            parameters: [transaction],
            method: "insertOne",
          },
        ];

        if (Array.isArray(pt.did)) {
          if (!pt.did.includes(doctor.did) && pt.did.length > 0) {
            swal({
              title: "Confirm new clinician!",
              text: `${payload.pName} is already a patient of another doctor. Are you sure he wants book a service with ${payload.dName}?`,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                requestObjs.push({
                  db: state.db,
                  collection: "patients",
                  parameters: [
                    {pid: pt.pid},
                    {$set: {did: [...pt.did, doctor.did]}},
                  ],
                  method: "updateOne",
                });
                crud(state, requestObjs)
                  .then((res) => {
                    sendInvEmail(state, transaction);
                    updateInsuredSessions();
                    setNsServices((prev) => [...prev, payload]);
                    toast({
                      title: "The service has been successfully booked!",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    setCreating(false);
                    setShowEditOrCreateModal(false);
                    setSelectedItem(null);
                  })
                  .catch(function (error) {
                    console.log(error);
                    setCreating(false);
                  });
              } else {
                setCreating(false);
              }
            });
          } else {
            if (pt.did.length === 0) {
              requestObjs.push({
                db: state.db,
                collection: "patients",
                parameters: [{pid: pt.pid}, {$set: {did: [doctor.did]}}],
                method: "updateOne",
              });
            }
            crud(state, requestObjs).then((res) => {
              // sendInvoiceEmail && sendInvEmail(state, transaction);
              sendInvEmail(state, transaction);
              updateInsuredSessions();
              setNsServices((prev) => [...prev, payload]);
              toast({
                title: "The service has been successfully booked!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setCreating(false);
              setShowEditOrCreateModal(false);
              setSelectedItem(null);
            });
          }
        }
      };

      setCreating(true);
      action();
    }
  }

  // console.log(patientTppInfo);
  return (
    <div className="relative h-full w-full overflow-y-scroll pr-2 ">
      <div className="relative  w-full p-4 transition-all">
        <form className="" action="#">
          <div className="flex">
            <div className="">
              <Selections
                {...{state}}
                currentDoctor={state.selectedDoctor}
                role="Doctor"
                onSelect={setDoctor}
                defaultDoctor={null}
              />

              <label className="block relative mx-3.5 mt-1" htmlFor="doctor">
                <span className="text-[11px] text-off ml-2">Description</span>
                <span
                  className="absolute z-10 top-8 left-3 text-gray-400 text-xs"
                  ref={searchRef}
                  onClick={() => {
                    searchRef.current.className = "hidden";
                    bookNoteRef.current.focus();
                  }}
                >
                  Write description...
                </span>
                <textarea
                  ref={bookNoteRef}
                  onFocus={() => {
                    searchRef.current.className = "hidden";
                  }}
                  onBlur={() => {
                    if (!bookNoteRef.current.value) {
                      setTimeout(() => {
                        if (searchRef.current)
                          searchRef.current.className =
                            "absolute z-10 top-8 left-3 text-gray-400 text-xs";
                      }, 100);
                    }
                  }}
                  className="h-[4.5rem] drop-shadow block w-[17rem] px-3 py-2.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <div className="w-full flex justify-between">
                <BookingMedia {...{setBookingMedia, bookingMedia}} />
                <FirstTime {...{firstTime, setFirstTime}} />
              </div>
            </div>
            <div className="flex-1 flex flex-col items-center">
              <label className="block mx-1 mb-1" htmlFor="service">
                <span className="text-[11px] text-off ml-2">
                  Select Service
                </span>
                <select
                  onChange={(e) => {
                    setSelectedServiceIndx(e.target.value);
                    setDuration(
                      services[e.target.value]?.defaultDuration || "15 min"
                    );
                  }}
                  ref={bookServiceRef}
                  value={selectdServiceIndx}
                  type="service"
                  name="service"
                  id="service"
                  className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                >
                  <option value="">-</option>
                  {services.map((p, i) => {
                    return (
                      <option key={i} value={i}>
                        {p.serviceName}
                      </option>
                    );
                  })}
                </select>
              </label>
              {service && (
                <Details
                  width={"10rem"}
                  details={[
                    {Category: service.category || "-"},
                    {"Default Cost/hrs": service.defaultCost || "-"},
                    {"Default Duration": service.defaultDuration || "-"},
                  ]}
                />
              )}

              <label className="block mx-1 mt-1" htmlFor="service">
                <span className="text-[11px] text-off ml-2">Select Room</span>
                <select
                  onChange={(e) => {
                    setSelectedRoom(e.target.value);
                  }}
                  value={selectedRoom}
                  type="room"
                  name="room"
                  id="room"
                  className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                >
                  <option value="">-</option>
                  {rooms.map((r, i) => {
                    return (
                      <option key={i} value={r.rid}>
                        {r.roomName}
                      </option>
                    );
                  })}
                </select>
              </label>

              <label className="block mx-1 mt-1" htmlFor="type">
                <span className="text-[11px] text-off ml-2">Select Type</span>
                <select
                  ref={bookTypeRef}
                  type="type"
                  name="type"
                  id="type"
                  className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                >
                  <option value="teleconference">🖥 &nbsp;Virtual</option>
                  <option value="live">🎙 &nbsp;In Person</option>
                  <option value="telephone">📞 &nbsp;Telephone</option>
                </select>
              </label>
              <label className=" mx-1 mt-1 hidden" htmlFor="date">
                <span className="text-[11px] text-off ml-2">Select Date</span>
                <input
                  ref={bookDateRef}
                  type="date"
                  name="date"
                  id="date"
                  defaultValue={lightFormat(new Date(), "yyyy-MM-dd")}
                  className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="hidden   mx-1 mt-1" htmlFor="time">
                <span className="text-[11px] text-off ml-2">Select Time</span>
                <input
                  ref={bookTimeRef}
                  type="time"
                  name="time"
                  id="time"
                  defaultValue={lightFormat(new Date(), "HH:mm")}
                  className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="mx-1 mt-1 " htmlFor="duration">
                <span className="text-[11px] text-off ml-2">
                  Select Duration
                </span>
                <select
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  value={duration || "15 min"}
                  ref={bookDurationRef}
                  type="duration"
                  name="duration"
                  id="duration"
                  className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                >
                  <option value="15 min">15 minutes</option>
                  <option value="30 min">30 minutes</option>
                  <option value="45 min">45 minutes</option>
                  <option value="60 min">60 minutes</option>
                  <option value="75 min">75 minutes</option>
                  <option value="90 min">90 minutes</option>
                </select>
              </label>
              {/*<label className="block mx-1 mt-1  w-[10rem]">
                    <span className="text-[11px] text-off ml-2">
                      Auto-email invoice
                    </span>
                    <AutoEmailInvoice
                      {...{sendInvoiceEmail, setSendInvoiceEmail}}
                    />
                  </label>*/}
            </div>
          </div>

          <DefineServiceCriteria
            {...{
              criteria,
              initialCriteria,
              criteriaKeyRef,
              creating,
              action: (crt) => {
                setServiceCriteriaKey(criteriaFormatKey(crt));
              },
            }}
          />
          <div className="mt-6 w-[27.25rem] mb-4 mx-auto rounded-lg text-background bg-[#3182CE]">
            <button
              type="button"
              className="w-full py-2 disabled:cursor-not-allowed rounded-lg font-medium flex justify-center items-center"
              onClick={() => {
                if (!creating) book();
              }}
              disabled={creating}
            >
              Book Non-Session Service
              {creating && (
                <span className="">
                  <svg
                    className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25 stroke-[4px]"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function Selections(options) {
  const [value, setValue] = useState("");
  const [showSearchList, setShowSearchList] = useState(true);
  const [showOptionsList, setShowOptionsList] = useState(false);
  const state = options.state;
  const currentPatient = options.currentPatient;
  const currentDoctor = options.currentDoctor;
  const role = options.role;
  const clickRef = useRef(false);
  const [doctors, setDoctors] = useState(state.doctors || []);

  useEffect(() => {
    if (state.userType === "doctor")
      crud(state, [
        {
          db: state.db,
          collection: "doctors",
          parameters: [{}],
          method: "find",
        },
      ]).then((res) => {
        setDoctors(res.data[0]);
      });
  }, []);

  useEffect(() => {
    if (options.defaultDoctor) setValue(`${options.defaultDoctor.lastName}, ${options.defaultDoctor.firstName}`);
  }, []);

  useEffect(() => {
    const handler = () => {
      setShowSearchList((e) => e && !e);
      if (!clickRef.current) {
        setShowOptionsList((e) => e && !e);
      }

      clickRef.current = false;
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  return (
    <label className="block mx-3.5 mt-1" htmlFor="doctor">
      <span className="text-[11px] text-off ml-2">
        {`${currentPatient || currentDoctor ? "Selected" : "Select"}  ${
          role == "Doctor" ? "Clinician" : role
        }`}
      </span>

      {(!state.selectedDoctor && role === "Doctor") ||
      (!currentPatient && role === "Patient") ||
      role === "Supervisor" ? (
        <div className="relative flex justify-center items-center">
          {showSearchList && (
            <SearchList
              onSelect={(e) => {
                setValue(
                  role === "Doctor" || role === "Supervisor"
                    ? `${e.name}`
                    : e.fName + " " + e.lName
                );
                setShowOptionsList(false);
                setShowSearchList(false);
                options.onSelect(e);
              }}
              {...{state, doctors, role}}
              value={value}
              fields={
                role === "Doctor" || role === "Supervisor"
                  ? ["email", "name"]
                  : ["fName", "lName", "email", "healthCard", "phone"]
              }
            />
          )}

          {showOptionsList && (
            <OptionsList
              {...{state, role, doctors, showOptionsList}}
              onSelect={(e) => {
                setValue(
                  role === "Doctor" || role === "Supervisor"
                    ? `${e.name}`
                    : e.fName + " " + e.lName
                );
                setShowOptionsList(false);
                setShowSearchList(false);
                options.onSelect(e);
              }}
            />
          )}

          <input
            onClick={(e) => {
              setShowSearchList(true);
              setShowOptionsList(false);
            }}
            className="drop-shadow block w-[17rem]  px-4 py-3 pr-8 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            type="text"
            value={value}
            onChange={(e) => {
              setValue(e.target.value.trim());
              setShowOptionsList(false);
              setShowSearchList(true);
            }}
          />
          <span
            className="absolute right-2.5  h-full flex justify-center items-center hover:cursor-pointer"
            onClick={(e) => {
              //e.stopPropagation();
              clickRef.current = true;
              setShowSearchList(false);
              setShowOptionsList((s) => !s);
            }}
          >
            <PiArrowCircleDownFill
              className="h-5 w-5 text-off"
              aria-hidden="true"
            />
          </span>
        </div>
      ) : (
        <input
          type="text"
          className="drop-shadow block w-[17rem] whitespace-nowrap overflow-hidden px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          readOnly={true}
          defaultValue={
            currentPatient
              ? currentPatient.fName +
                " " +
                currentPatient.lName +
                " (" +
                currentPatient.email +
                ")"
              : `${currentDoctor.name}`
          }
        />
      )}
    </label>
  );
}

function searchMatch(_data = [], entri, fields) {
  if (entri === "") return [];
  let sanitizedEntri = entri.replace(/[$^.*+?=!:|/()[\]{}]/g, (e) => "\\" + e);
  let regex = new RegExp(`${sanitizedEntri}`, "i");
  //console.log(regex);
  let d = _data.filter((e, i) => {
    for (let field of fields) {
      if (typeof e[field] === "string" && e[field]?.match(regex)) return true;
    }
    return false;
  });
  //console.log(d)
  return d;
}

function OptionsList({onSelect, state, role, doctors}) {
  let {doctorsPayload} = state;

  return (
    <>
      {(role === "Doctor" || role === "Supervisor") && doctors ? (
        <div className="max-h-[5rem]  flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-[999] bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 overflow-x-hidden text-sm text-off font-medium flex flex-col">
            {doctors.map((e, i) => (
              <li
                className="hover:bg-gray-100 hover:text-gray-600 hover:cursor-pointer px-4 "
                key={i}
                onClick={() => onSelect(e)}
              >
                {`${e.name}`}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="max-h-[8rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-20 bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 text-sm text-off font-medium flex flex-col overflow-x-hidden">
            {state.userType === "doctor" ? (
              state.patients.map((pat, i) => {
                // console.log(renderTextByAccess(state, pat, "fName", undefined, " "))
                return (
                  <li
                    className="hover:bg-gray-100 hover:text-gray-600 hover:cursor-pointer px-4 whitespace-nowrap"
                    key={i}
                    onClick={() => onSelect(pat)}
                  >
                    {`${pat["fName"]} ${pat["lName"]} `}
                  </li>
                );
              })
            ) : (
              <>
                {doctors
                  .filter((ele) => doctorsPayload[ele.did].patients.length > 0)
                  .map((d, i) => (
                    <li className="" key={i}>
                      <div className="text-[#1A202C] px-4">{`${d.name}`}</div>
                      <ul className="">
                        {doctorsPayload[d.did].patients.map((pat, i) => (
                          <li
                            className="hover:bg-gray-100 hover:text-gray-500 hover:cursor-pointer px-8 whitespace-nowrap"
                            key={i}
                            onClick={() => onSelect(pat)}
                          >
                            {`${pat["fName"]} ${pat["lName"]} `}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                {state.unassignedPatients.length > 0 && (
                  <li className="">
                    <div className="text-gray-800 px-4">{"Unassigned"}</div>
                    <ul className="">
                      {state.unassignedPatients.map((pat, i) => (
                        <li
                          className="hover:bg-gray-100 hover:text-gray-500 hover:cursor-pointer px-8 whitespace-nowrap"
                          key={i}
                          onClick={() => onSelect(pat)}
                        >
                          {`${pat["fName"]} ${pat["lName"]} `}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

function SearchList({state, value, fields, role, onSelect, doctors}) {
  const [searchArr, setSearchArr] = useState([]);

  useEffect(() => {
    let search = () => {
      let a = searchMatch(
        role === "Doctor" || role === "Supervisor"
          ? state.doctors || doctors
          : state.patients,
        value,
        fields
      );
      setSearchArr(a);
    };
    let id = setTimeout(search, 200);
    return () => {
      clearTimeout(id);
    };
  }, [value]);

  //console.log(searchArr)
  return (
    <>
      {searchArr.length !== 0 ? (
        <div className="max-h-[5rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-[999] bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 text-sm text-off font-medium flex flex-col">
            {searchArr.map((e, i) => (
              <li
                className="hover:bg-gray-100 hover:text-gray-500 hover:cursor-pointer px-4"
                key={i}
                onClick={() => onSelect(e)}
              >
                {role === "Doctor" || role === "Supervisor"
                  ? `${e.name}`
                  : e.fName + " " + e.lName}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}

function BookingMedia({setBookingMedia, bookingMedia}) {
  let entries = Object.entries(bookingMedia);

  return (
    <div className="flex flex-col items-start space-y-2 mx-4 my-7">
      <p className="text-off font-normal text-sm text-start">Booking Media</p>
      <div className="flex space-x-5">
        {entries.map(([key, value], i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-2"
          >
            <div
              className=" h-7 w-7 rounded-md shadow02 bg-white hover:cursor-pointer"
              style={{
                backgroundColor: value ? "#1A202C" : null,
              }}
              onClick={() => {
                setBookingMedia((prev) => {
                  let n = Object.entries(prev).map(([k, v]) => [k, k == key]);
                  return Object.fromEntries(n);
                });
              }}
            ></div>
            <p className="text-slate-400 text-sm">{key}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function FirstTime({firstTime, setFirstTime}) {
  return (
    <div className="flex flex-col items-start space-y-2 mx-4 my-7">
      <p className="text-off font-normal text-sm text-start">First Time</p>
      <div className="flex space-x-5">
        <div className="flex flex-col justify-center items-center space-y-2">
          <div
            className=" h-7 w-7 rounded-md shadow02 bg-white hover:cursor-pointer"
            style={{
              backgroundColor: firstTime ? "#1A202C" : null,
            }}
            onClick={() => {
              setFirstTime((prev) => !prev);
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function Details({details, width}) {
  const boxRef = useRef();

  return (
    <div
      ref={boxRef}
      className="anim_height p-2 block mx-3.5 mt-1 shadow-inner whitespace-nowrap overflow-hidden  text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl "
      style={{width: width || "100%"}}
    >
      <ul className="flex-1 overflow-scroll h-full sbar2 overflow-x-hidden  text-off font-medium flex flex-col">
        {details.map((e, i) => {
          let [key, value] = Object.entries(e)[0];
          return (
            <li className="text-xs" key={i}>
              <span className="text-[#1A202C] font-medium">{`${key}: `}</span>
              <span>{value}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function getAge(dateString) {
  try {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getUTCFullYear() - birthDate.getUTCFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } catch (error) {
    return "-";
  }
}
function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
