import axios from "axios";
import {url} from "../../../../crudRequests";

function faxNumbreFormat(n) {
  let f = String(n ?? "");
  f = f.replace(/[^0-9+]+/g, "");
  if (!f.startsWith("+")) f = `+1${f}`;

  return f;
}

export async function fax(state, toNumber, fileUrl, fromNumber) {
  let configService = {
    method: "POST",
    url: `${url}/fax`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      toNumber: faxNumbreFormat(toNumber),
      fileUrl,
      fromNumber: faxNumbreFormat(fromNumber),
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export default async function faxer(
  dashState,
  toNumber,
  faxUrl,
  fromNumber,
  toast
) {
  try {
    const res = await fax(dashState, toNumber, faxUrl, fromNumber);
    if (res.status === 200) {
      toast({
        title: "Success!",
        description:
          "Fax initiated successfully, please check status on Admin / Faxes / Outgoing!",
        status: "info",
        isClosable: true,
      });
    }
  } catch (err) {
    toast({
      title: "Failed to send fax",
      description: err?.message || "An error occurred while faxing",
      status: "error",
      isClosable: true,
    });
  }
}
