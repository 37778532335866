import {crud} from "../../../../crudRequests";

export async function saveNote(
  newNote,
  payload,
  selectedNote,
  dashState,
  setIsLoading,
  toast,
  setNotes
) {
  try {
    setIsLoading(true);
    if (selectedNote) {
      const {_id, ...noteToUpdate} = selectedNote;
      await crud(dashState, [
        {
          db: dashState.db,
          collection: "patientNotes",
          parameters: [{noteId: selectedNote.noteId}, {$set: payload}],
          method: "updateOne",
        },
      ]);
      setNotes((prev) =>
        prev.map((nt) => {
          return nt.noteId === selectedNote.noteId
            ? {...selectedNote, ...payload}
            : nt;
        })
      );
      toast({
        title: "Note Updated",
        description: "Note information successfully updated",
        status: "success",
        isClosable: true,
      });
    } else {
      let res = await crud(dashState, [
        {
          db: dashState.db,
          collection: "patientNotes",
          parameters: [newNote],
          method: "insertOne",
        },
      ]);
      setNotes((prev) => [newNote].concat(prev));

      console.log(newNote);
      console.log(res.data);
      toast({
        title: "Note Updated",
        description: "Note information successfully saved",
        status: "success",
        isClosable: true,
      });
    }
  } catch (error) {
    toast({
      title: "Note Not Saved",
      description: error?.message || "An error occurred.",
      status: "error",
      isClosable: true,
    });
  } finally {
    setIsLoading(false);
  }
}
