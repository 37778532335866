import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Grid,
  Box,
} from "@chakra-ui/react";

function FaxStepper({steps}) {
  const {activeStep, setActiveStep, goToNext} = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <>
      <Stepper size="lg" index={activeStep}>
        {steps.map((step, index) => (
          <Step
            key={index}
            onClick={() => !step?.disabled && setActiveStep(index)}
          >
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Grid gap="4" marginTop="4">
        {steps?.[activeStep]?.component &&
          steps[activeStep].component({
            nextStep: goToNext,
          })}
      </Grid>
    </>
  );
}

export default FaxStepper;
