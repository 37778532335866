
import React, { useState, useEffect, useRef, useReducer } from "react";
import {crud}  from "../crudRequests";
export default function TFA({schState, dispatch}) {
    const [ac, setAc] = useState(schState.admin?.tfa||schState.doctor?.tfa)
    
    useEffect(() => {
      crud(schState, [
        {
          db: schState.db,
          collection: "security",
          parameters: [{ userId: schState.admin?.id||schState.doctor?.did}],
          method: "findOne",
        },
      ]).then((res)=>setAc(res.data[0].tfa));
      ;
    
    }, []);
  return (
    <div className=" space-y-5">
      <div className="flex items-center space-x-4">
        <h1 className="text-[rgba(87,84,255,0.75)] text-2xl font-semibold">
          Two-Factor Authentication
        </h1>
        <span className="bg-[#5754FF] rounded-full p-1 px-2 text-white text-xs">
          Recomended
        </span>
      </div>
      <p className="text-sm text-[#A2A2A2] max-w-[32rem]">
        By turning 2FA on you consent to receiving identity confirmation emails
        from Brightlight after every login attempt to secure your account
        accessibility.
      </p>
      <div className="space-y-2">
        <p className="text-[#8F8CFF] text-sm">Two-factor authentication is currently  
        <span className="font-bold">{ac?' active':' disabled'} </span> </p>
        
        <div className="bg-[#5754FF] rounded-full p-2 w-40 cursor-pointer transition duration-500"
        onClick={()=>{
          if(schState.doctor)
          {
            crud(schState,[{
              db: schState.db,
              collection: "security",
              parameters: [{userId:schState.doctor?.did}, {$set: {userId:schState.doctor?.did,tfa:ac?false:true}},{upsert: true}],
              method: "findOneAndUpdate",
               }])
          }  
          else{
            crud(schState,[{
              db: schState.db,
              collection: "security",
              parameters: [{userId:schState.admin?.id}, {$set: {userId:schState.admin?.id,tfa:ac?false:true}},{upsert: true}],
              method: "findOneAndUpdate",
               }])
          } 
          setAc(e=>!e)
        }}
        style={{ backgroundColor:ac?'#5754FF':'#FFFFFF',boxShadow:!ac&&'0 0 0 2px inset #5754FF'}}
        >
          <p className="w-7 h-7 rounded-full bg-white transition duration-500"
          style={{transform:ac?'translateX(118px)':'translateX(0)', backgroundColor:ac?'#FFFFFF':'#5754FF'}}
          >

          </p>
        </div>
      </div>
    </div>
  );
}
