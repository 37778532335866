import React, {useState, useRef} from "react";
import {crud} from "../../crudRequests";
import {BiEdit} from "react-icons/bi";
import {GiHealing} from "react-icons/gi";
import {FaClinicMedical} from "react-icons/fa";

export default function EditClinic({schState, dispatch}) {
  const organization = schState.organization;
  const [loading, setLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [fieldsValues, setFieldsValues] = useState(organization);
  //console.log(organization.stpPublicKey);
  async function updateClinic() {
    setLoading(true);
    setUpdateSuccess(false);
    setUpdateError(false);
    let payload = {};
    Object.getOwnPropertyNames(fieldsValues).forEach((e) => {
      if (e !== "stpSecretKey" && e !== "_id" && e !== "clinicAidPassword") {
        payload[e] = fieldsValues[e]?.trim();
      } else if (e === "stpSecretKey" && fieldsValues.stpSecretKey?.trim()) {
        payload[e] = fieldsValues.stpSecretKey?.trim();
      } else if (e === "clinicAidPassword" && fieldsValues.clinicAidPassword) {
        payload[e] = fieldsValues.clinicAidPassword;
      }
    });

    crud(schState, [
      {
        db: "customers",
        collection: "organizations",
        parameters: [{oid: organization.oid}, {$set: payload}],
        method: "updateOne",
      },
    ])
      .then(() => {
        setLoading(false);
        setUpdateSuccess(true);
        dispatch({type: "UPDATE_ORGANIZATION", payload});
      })
      .catch(function (error) {
        setLoading(false);
        setUpdateError(error.message);
      });
  }

  return (
    <div className="relative bg-white border shadow-md  pt-4 pb-4  text-left align-bottom transition-all transform rounded-lg sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
      {updateError && (
        <div>
          <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="flex items-center justify-center w-12 bg-red-500">
              <svg
                className="w-6 h-6 mx-2 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
              </svg>
            </div>
            <div className="px-4 py-2 -mx-3">
              <div className="mx-3">
                <span className="font-semibold text-red-500 dark:text-red-400">
                  {updateError}
                </span>
                <p className="text-sm text-gray-600 dark:text-gray-200">...</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {updateSuccess && (
        <div className="show">
          <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="flex items-center justify-center w-12 bg-green-500">
              <svg
                className="w-6 h-6 mx-2 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
              </svg>
            </div>
            <div className="px-4 py-2 -mx-3">
              <div className="mx-3">
                <span className="font-semibold text-green-500 dark:text-green-400">
                  Clinic Updated
                </span>
                <p className="text-sm text-gray-600 dark:text-gray-200">
                  The Clinic information has been updated successfully!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center w-full">
        <ProfilePhoto fields={fieldsValues} setFields={setFieldsValues} />
      </div>

      <form
        className="mt-3 w-full"
        onSubmit={(e) => {
          e.preventDefault();
          updateClinic();
        }}
      >
        <div className="flex  justify-between w-full">
          <label className="block  mt-3" htmlFor="name">
            <span className="text-[11px] text-off ml-1">Clinic Name</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              value={fieldsValues.name || ""}
              type="text"
              name="name"
              id="name"
              className="drop-shadow block px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
          <label className="block  mt-3" htmlFor="state">
            <span className="text-[11px] text-off ml-1">State</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  state: e.target.value,
                }));
              }}
              value={fieldsValues.state || ""}
              name="state"
              id="state"
              className="drop-shadow block  px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>
        <div className="flex  justify-between w-full">
          <label className="block  mt-3" htmlFor="email">
            <span className={`text-[11px] text-off ml-1`}>Email</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
              value={fieldsValues.email || ""}
              type="text"
              name="email"
              id="email"
              className={`drop-shadow block w-[8rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40`}
            />
          </label>
          <label className="block  mt-3" htmlFor="phone">
            <span className="text-[11px] text-off ml-1">Phone</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  phone: e.target.value,
                }));
              }}
              value={fieldsValues.phone || ""}
              type="text"
              name="phone"
              id="phone"
              onCopy={(e) => e.preventDefault()}
              className="drop-shadow block w-[8rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
          <label className="block  mt-3" htmlFor="fax">
            <span className="text-[11px] text-off ml-1">Fax</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  fax: e.target.value,
                }));
              }}
              value={fieldsValues.fax || ""}
              type="text"
              name="fax"
              id="fax"
              onCopy={(e) => e.preventDefault()}
              className="drop-shadow block w-[8rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>

        <div className="flex  justify-between w-full">
          <label className="block  mt-3" htmlFor="address">
            <span className="text-[11px] text-off ml-1">Address</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  address: e.target.value,
                }));
              }}
              value={fieldsValues.address || ""}
              type="text"
              name="address"
              id="address"
              onCopy={(e) => e.preventDefault()}
              className="drop-shadow block  px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
          <label className="block  mt-3" htmlFor="city">
            <span className="text-[11px] text-off ml-1">City</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  city: e.target.value,
                }));
              }}
              value={fieldsValues.city || ""}
              type="text"
              name="city"
              id="city"
              onCopy={(e) => e.preventDefault()}
              className="drop-shadow block px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>

        <div className="flex  justify-between w-full">
          <label className="block  mt-3" htmlFor="businessId">
            <span className="text-[11px] text-off ml-1">Business Number</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  businessId: e.target.value,
                }));
              }}
              value={fieldsValues.businessId || ""}
              type="text"
              name="businessId"
              id="businessId"
              className="drop-shadow block w-[13rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>

          <label className="block relative  mt-3" htmlFor="hstId">
            <span className="text-[11px] text-off ml-1">HST Number</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  hstId: e.target.value,
                }));
              }}
              value={fieldsValues.hstId || ""}
              type="text"
              name="hstId"
              id="hstId"
              className="drop-shadow block w-[13rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>
        <div className="flex  justify-between w-full">
          <label className="block  mt-3 w-full" htmlFor="clinicAidUrl">
            <span className="text-[11px] text-off ml-1">ClinicAid API URL</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  clinicAidUrl: e.target.value,
                }));
              }}
              value={fieldsValues.clinicAidUrl || ""}
              type="text"
              name="clinicAidUrl"
              id="clinicAidUrl"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>
        <div className="flex  justify-between w-full">
          <label className="block  mt-3" htmlFor="clinicAidUserId">
            <span className="text-[11px] text-off ml-1">ClinicAid User ID</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  clinicAidUserId: e.target.value,
                }));
              }}
              value={fieldsValues.clinicAidUserId || ""}
              type="text"
              name="clinicAidUserId"
              id="clinicAidUserId"
              className="drop-shadow block w-[13rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>

          <label className="block relative  mt-3" htmlFor="clinicAidPassword">
            <span className="text-[11px] text-off ml-1">
              ClinicAid Password
            </span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  clinicAidPassword: e.target.value,
                }));
              }}
              value={fieldsValues.clinicAidPassword || ""}
              type="password"
              name="clinicAidPassword"
              id="clinicAidPassword"
              className="drop-shadow block w-[13rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
            {fieldsValues.clinicAidPassword &&
              fieldsValues.clinicAidPassword.length < 8 && (
                <span className="inline-block text-[11px] left-1 absolute top-full text-red-400">
                  Password must be min. 8 letters
                </span>
              )}
          </label>
        </div>

        <div className="flex  justify-between w-full">
          <label className="block  mt-3 w-full" htmlFor="stpPublicKey">
            <span className="text-[11px] text-off ml-1">Stripe Public Key</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  stpPublicKey: e.target.value,
                }));
              }}
              value={fieldsValues.stpPublicKey || ""}
              type="text"
              name="stpPublicKey"
              id="stpPublicKey"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>

        <div className="flex  justify-between w-full">
          <label className="block  mt-3 w-full" htmlFor="stpSecretKey">
            <span className="text-[11px] text-off ml-1">Stripe Secret Key</span>
            <input
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  stpSecretKey: e.target.value,
                }));
              }}
              value={fieldsValues.stpSecretKey || ""}
              type="password"
              name="stpSecretKey"
              id="stpSecretKey"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>
        <div className="flex  justify-between w-full">
          <label className="block  mt-3 w-full" htmlFor="description">
            <span className="text-[11px] text-off ml-1">Description</span>
            <textarea
              placeholder=" Write description..."
              id="description"
              value={fieldsValues.description || ""}
              onChange={(e) => {
                setUpdateSuccess(false);
                setUpdateError(false);
                setFieldsValues((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
              className="drop-shadow block w-[20rem] px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:ring-1 focus:border-gray-800/50 focus:outline-none focus:ring-gray-700/80 focus:ring-opacity-40"
            />
          </label>
        </div>
        <div className="mt-6 w-full mx-auto mb-4  rounded-lg  bg-gray-800/50  text-white p-1 text-[1rem]   hover:bg-gray-800">
          <button
            className="w-full py-2 disabled:cursor-not-allowed rounded-lg font-medium flex justify-center items-center focus:outline-none"
            disabled={
              loading ||
              (fieldsValues.clinicAidPassword &&
                fieldsValues.clinicAidPassword.length < 8)
            }
          >
            Save Changes
            {loading && (
              <span className="">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
function ProfilePhoto({fields, setFields}) {
  const fileRef = useRef(null);
  function onUpdatePhoto(ele) {
    let files = ele.target.files;
    let file = files[0];
    if (!file?.type.match(/image.*/i)) return;
    var reader = new FileReader();

    reader.addEventListener("load", (e) => {
      setFields((prev) => ({...prev, logo: e.target.result}));
    });
    reader.readAsDataURL(file);
  }

  return (
    <div className="flex justify-center items-center">
      <div className="h-14 p-2 bg-white rounded-lg shadow-md flex justify-center items-center overflow-hidden relative">
        <input
          ref={fileRef}
          onChange={(e) => {
            onUpdatePhoto(e);
          }}
          className="absolute top-0 opacity-0 z-0"
          type="file"
          name="updatePhoto"
        />
        {fields?.logo ? (
          <img
            src={fields.logo}
            alt=""
            className="relative z-10 hover:cursor-pointer  h-full"
          />
        ) : (
          <FaClinicMedical className="relative text-[2rem] text-gray-700 z-10 hover:cursor-pointer  h-full rounded-full" />
        )}
        <div
          className="absolute group transition text-transparent hover:text-white text-lg inset-0 h-full w-full rounded-lg z-20 flex justify-center items-center hover:bg-gray-400/30 hover:cursor-pointer"
          onClick={() => {
            fileRef.current.click();
          }}
        >
          <BiEdit className="" />
        </div>
      </div>
    </div>
  );
}
