import axios from "axios";
import {url} from "../../../../crudRequests";
import {nanoid} from "nanoid";

export async function updateProvider(state, data) {
  let configService = {
    method: "POST",
    url: `${url}/updateProvider`,
    data: {data},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService);
}

export default async function changeProvider(data, dashState, queryFn) {
  const res = await updateProvider(dashState, data);
  await queryFn();
  return;
}
