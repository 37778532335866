import React, {useState, useEffect, useRef, useMemo} from "react";
import {nanoid} from "nanoid";
import Swal from "sweetalert2";
import {MdModeEditOutline, MdDeleteForever} from "react-icons/md";

import {crud, addDoctor} from "../crudRequests";
import TriggerInfoSearch from "./TriggerInfoSearch";
import {BiEdit} from "react-icons/bi";
import {GiHealing} from "react-icons/gi";
import {BsInfoCircleFill} from "react-icons/bs";
export default function InsurersTable({schState, dispatch}) {
  const [prevInsurers, setPrevInsurers] = useState(schState.insurers);
  const [searchValue, setSearchValue] = useState("");
  const [insurers, setInsurers] = useState(schState.insurers || []);
  const [selectedInsurer, setSelectedInsurer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const searchRef = useRef(null);
  const searchText = useRef("");

  if (prevInsurers !== schState.insurers) {
    setPrevInsurers(schState.insurers);
    searchValue ? search(searchValue) : setInsurers(schState.insurers);
  }

  useEffect(() => {
    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };
    window.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  function search(s) {
    function update(insurer) {
      if (s.length === 0) {
        return insurer;
      } else if (insurer["name"]?.toLowerCase().includes(s)) {
        return insurer;
      } else if (insurer["phone"]?.toLowerCase().includes(s)) {
        return insurer;
      } else if (insurer["email"]?.toLowerCase().includes(s)) {
        return insurer;
      } else if (insurer["website"]?.toLowerCase().includes(s)) {
        return insurer;
      } else if (insurer["specialties"]?.join(" ").toLowerCase().includes(s)) {
        return insurer;
      } else {
        return null;
      }
    }

    let filteredInsurers = schState.insurers.filter(update);
    setSearchValue(s);
    setInsurers(filteredInsurers);
  }

  return (
    <>
      {showModal && (
        <AddOrEditInsurerModal
          state={schState}
          dispatch={dispatch}
          selectedInsurer={selectedInsurer}
          setSelectedInsurer={setSelectedInsurer}
          setShowModal={setShowModal}
        />
      )}

      <div className="relative p-8 pb-4 pt-5 w-full h-full flex flex-col patients-table-container">
        <div className="bg-off relative mx-4  bg-opacity-25 text-opacity-100 flex items-center rounded-full">
          <span
            className="text-xl text-off absolute left-3"
            ref={searchRef}
            onClick={() => {
              searchRef.current.className = "hidden";
              searchText.current.focus();
            }}
          >
            <span className="font-semibold">Search insurer</span>{" "}
            <span className="font-light">
              by any field<span className="font-semibold">...</span>
            </span>
          </span>
          <span className="text-xl text-off absolute right-2">
            <img src="/images/Doctor/search_icon.png" alt="" className="w-6" />
          </span>
          <input
            type="text"
            className="w-full bg-off p-3 px-4 bg-opacity-0 text-off rounded-full focus:outline-none  ring-[#8F8CFF] focus:ring-2 focus:ring-[#8F8CFF]"
            ref={searchText}
            onChange={() => search(searchText.current.value.toLowerCase())}
            onFocus={() => {
              searchRef.current.className = "hidden";
            }}
            onBlur={() => {
              if (!searchText.current.value) {
                setTimeout(() => {
                  if (searchRef.current) {
                    searchRef.current.className =
                      "text-xl text-off absolute left-3";
                  }
                }, 100);
              }
            }}
          />
        </div>
        <TriggerInfoSearch text="Currently, insurers records are searchable on this page by a name, phone, email, website ans specialties." />

        <div className="flex mt-4">
          <button
            className={
              "cursor-pointer focus:outline-none  text-sm bg-background ring-[3px] ring-dark text-dark px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              setInsurers(schState.insurers);
              setSearchValue("");
              searchText.current.value = "";
              setTimeout(() => {
                searchRef.current.className =
                  "text-xl text-off absolute left-3";
              }, 100);
            }}
          >
            <span> Clear </span>
          </button>
          <button
            className={
              "cursor-pointer text-sm ring-[3px] ring-dark bg-dark text-background px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              setSelectedInsurer(null);
              setShowModal(true);
            }}
          >
            <span> Add a Insurer </span>
          </button>
        </div>
        <div
          className="rounded-md m-4 mt-2 py-2 px-5 text-sm text-background font-medium text-center"
          style={{backgroundColor: "#8F8CFF"}}
        >
          <ol className="grid grid-cols-9 gap-3 text-center align-middle sm:text-[13px] ml-2 lg:text-base">
            <li>Name</li>
            <li>Phone</li>
            <li>Fax</li>
            <li>E-mail</li>
            <li>Address</li>
            <li>Default Attention To</li>

            <li>Website</li>
            <li>Specialties</li>
            <li>Actions</li>
          </ol>
        </div>
        <div className="overflow-hidden flex justify-center">
          <div className="overflow-y-scroll  w-full">
            {insurers.length !== 0 ? (
              insurers.map((insurer, idx) => {
                return (
                  <div
                    key={idx}
                    className="rounded-md m-4 mt-0 py-3 px-5 text-sm text-dark text-opacity-75 font-medium text-center h-fit"
                    style={{backgroundColor: "#DFDEFF"}}
                  >
                    <ol className="grid grid-cols-9 gap-4 place-items-center  text-xs md:text-sm align-middle text-center">
                      {/*<li className="h-12 p-1 bg-white rounded-lg opacity-80">
                        {insurer.logo ? (
                          <img
                            src={insurer.logo}
                            alt="profilePhoto"
                            className="h-full object-fill"
                          />
                        ) : (
                          <GiHealing className="text-[2rem] text-[#8e8df9]" />
                        )}
                      </li>*/}
                      <li>{insurer.name}</li>
                      <li>{insurer.phone || "-"}</li>
                      <li>{insurer.fax || "-"}</li>
                      <li className="relative group">
                        <span>
                          {insurer.email
                            ? (insurer.email.slice(0, 15) || "") +
                              (insurer.email.length > 15 ? "..." : "")
                            : "-"}
                        </span>
                        {insurer.email && (
                          <p className="absolute overflow-y-scroll top-0 right-full w-[200px] max-h-[200px] hidden group-hover:block text-white rounded-lg bg-black text-xs  p-2 sbar2">
                            <span className="">{insurer.email || ""}</span>
                          </p>
                        )}
                      </li>
                      <li className="relative group">
                        <span>
                          {insurer.address
                            ? (insurer.address.slice(0, 15) || "") +
                              (insurer.address.length > 15 ? "..." : "")
                            : "-"}
                        </span>
                        {insurer.address && (
                          <p className="absolute overflow-y-scroll top-0 right-full w-[200px] max-h-[200px] hidden group-hover:block text-white rounded-lg bg-black text-xs  p-2 sbar2">
                            <span className="">{insurer.address || ""}</span>
                          </p>
                        )}
                      </li>
                      <li className="relative group">
                        <span>
                          {insurer.defaultAttentionTo
                            ? (insurer.defaultAttentionTo.slice(0, 15) || "") +
                              (insurer.defaultAttentionTo.length > 15
                                ? "..."
                                : "")
                            : "-"}
                        </span>
                        {insurer.defaultAttentionTo && (
                          <p className="absolute overflow-y-scroll top-0 right-full w-[200px] max-h-[200px] hidden group-hover:block text-white rounded-lg bg-black text-xs  p-2 sbar2">
                            <span className="">
                              {insurer.defaultAttentionTo || ""}
                            </span>
                          </p>
                        )}
                      </li>

                      <li className="relative group">
                        <a
                          className="cursor-pointer decoration-0 no-underline"
                          target="_blank"
                          href={insurer.website || ""}
                        >
                          {insurer.website
                            ? (insurer.website?.slice(0, 15) || "") +
                              (insurer.website?.length > 15 ? "..." : "")
                            : "-"}
                        </a>
                        {insurer.website && (
                          <p className="absolute overflow-y-scroll top-0 right-full w-[200px] max-h-[200px] hidden group-hover:block text-white rounded-lg bg-black text-xs p-2 sbar2">
                            <span className="">{insurer.website || ""}</span>
                          </p>
                        )}
                      </li>

                      <li className="relative group">
                        <span>
                          {insurer.specialties
                            ? insurer.specialties.join(", ").slice(0, 40) +
                              (insurer.specialties.join(", ").length > 40
                                ? "..."
                                : "")
                            : "-"}
                        </span>
                        {insurer.specialties?.join(", ").length > 0 && (
                          <p className="absolute overflow-y-scroll top-0 right-full w-[200px] max-h-[200px] hidden group-hover:block text-white rounded-lg bg-black text-xs pr-2  sbar2">
                            <span className="">
                              {insurer.specialties?.join(", ")}
                            </span>
                          </p>
                        )}
                      </li>

                      <li>
                        <button
                          className="text-xl mx-1 cursor-pointer"
                          onClick={() => {
                            setSelectedInsurer(insurer);
                            setShowModal(true);
                          }}
                        >
                          <MdModeEditOutline />
                        </button>
                        <button
                          className="font-bold text-xl"
                          onClick={() => {
                            Swal.fire({
                              title: `Are you sure you want to remove this insurer?`,
                              html: `This insurer will be permanently removed.`,
                              icon: "warning",
                              showCancelButton: true,
                            }).then(async (value) => {
                              if (value.isConfirmed) {
                                await crud(schState, [
                                  {
                                    db: schState.db,
                                    collection: "insurers",
                                    parameters: [{iid: insurer.iid}],
                                    method: "deleteOne",
                                  },
                                ])
                                  .then((e) => {
                                    dispatch({
                                      type: "DELETE_INSURER",
                                      payload: insurer.iid,
                                    });
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                    //if (error.code !== "ERR_CANCELED") window.location.href = "../login"
                                  });
                              }
                            });
                          }}
                        >
                          <span>
                            <MdDeleteForever />
                          </span>
                        </button>
                      </li>
                    </ol>
                  </div>
                );
              })
            ) : (
              <span className="ml-5 text-sm text-off">
                No insurers to display
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const AddOrEditInsurerModal = ({
  state,
  dispatch,
  selectedInsurer,
  setSelectedInsurer,
  setShowModal,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState("");
  const defaultData = useMemo(
    () => ({
      iid: nanoid(10),
      oid: state.admin?.oid || state.doctor?.oid || "",
      name: "",
      phone: "",
      fax: "",
      email: "",
      website: "",
      specialties: [],
      services: [],
      logo: "",
      description: "",
      defaultAttentionTo: "",
      address: "",
    }),
    []
  );

  const [fields, setFields] = useState(
    selectedInsurer ? {...defaultData, ...selectedInsurer} : defaultData
  );

  const [warning, setWarning] = useState("");
  let {_id, ...payload} = fields;

  function validate() {
    return !!fields.name;
  }

  const addOrUpdate = async () => {
    try {
      if (validate()) {
        setWarning("");
        setLoading(true);
        payload.lid = state.selectedLocation;
        if (!selectedInsurer) {
          await crud(state, [
            {
              db: state.db,
              collection: "insurers",
              parameters: [{...payload}],
              method: "insertOne",
            },
          ]);
          dispatch({type: "NEW_INSURER", payload});
          setLoading(false);
          setSelectedInsurer(null);
          setShowModal(false);
        } else {
          await crud(state, [
            {
              db: state.db,
              collection: "insurers",
              parameters: [{iid: payload.iid}, {$set: {...payload}}],
              method: "updateOne",
            },
          ]);
          console.log(payload);
          dispatch({type: "UPDATE_INSURER", payload});
          setLoading(false);
          setSelectedInsurer(null);
          setShowModal(false);
        }
      } else setWarning("Please complete the mandatory fields!");
    } catch (error) {
      setLoading(false);
      setSelectedInsurer(null);
      setShowModal(false);
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 show z-[999] overflow-hidden flex justify-center items-center"
      style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div
        className="relative flex max-h-[800px] h-[90vh] items-center p-2 py-4 bg-white overflow-y-hidden rounded-3xl  sbar2 justify-center w-[700px]"
        onClick={(e) => {
          e.stopPropagation();
          setWarning("");
        }}
      >
        {warning && <Warning warning={warning} setWarning={setWarning} />}
        <div className="relative h-full w-full overflow-y-scroll rounded-3xl p-5 sbar">
          <div className="bg-[#f6f5ff] mx-2 text-left  px-4 pt-10 rounded-xl overflow-hidden relative">
            {/* close button */}
            <button
              type="button"
              className="- text-gray-400  bg-transparent  absolute top-5 right-5 rounded-lg text-sm   inline-flex items-center cursor-pointer focus:outline-none"
              data-modal-toggle="small-modal"
              style={{boxShadow: "none"}}
              onClick={() => {
                setShowModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <h3
              className="text-2xl font-medium px-5 mb-3 text-gray-800"
              style={{color: "rgb(192, 191, 255)"}}
            >
              {!selectedInsurer ? "Add Insurer" : "Edit Insurer"}
            </h3>

            <ProfilePhoto fields={fields} setFields={setFields} />

            <div className="h-full mt-3">
              <div className="overflow-hidden grid grid-cols-2 gap-3  rounded-md pb-2">
                <label className="block px-2 my-1" htmlFor="firstName">
                  <span className="text-[13px] ml-2  text-off ">
                    Company Name*
                  </span>
                  <input
                    type="text"
                    name="CompanyName"
                    id="CompanyName"
                    value={fields.name}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          name: e.target.value,
                        };
                      });
                    }}
                    className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  />
                </label>
                <label className="block px-2 my-1" htmlFor="email">
                  <span className="text-[13px] ml-2  text-off ">E-mail</span>
                  <input
                    name="email"
                    id="email"
                    value={fields.email}
                    className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          email: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
                <label className="block px-2 my-1" htmlFor="phone">
                  <span className="text-[13px] ml-2  text-off ">
                    Phone Number
                  </span>
                  <input
                    name="phone"
                    id="phone"
                    value={fields.phone}
                    className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      if (!isNaN(e.target.value))
                        setFields((prev) => {
                          return {
                            ...prev,
                            phone: e.target.value,
                          };
                        });
                    }}
                  />
                </label>
                <label className="block px-2 my-1" htmlFor="fax">
                  <span className="text-[13px] ml-2  text-off ">Fax</span>
                  <input
                    name="fax"
                    id="fax"
                    value={fields.fax}
                    className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      if (!isNaN(e.target.value))
                        setFields((prev) => {
                          return {
                            ...prev,
                            fax: e.target.value,
                          };
                        });
                    }}
                  />
                </label>

                <label
                  className="inline-block px-2 my-1 w-full  "
                  htmlFor="website"
                >
                  <span className="text-[13px] ml-2  text-off ">Website</span>
                  <input
                    name="website"
                    id="website"
                    value={fields?.website}
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          website: e.target.value,
                        };
                      });
                    }}
                  />
                </label>

                <label
                  className="inline-block px-2 my-1 w-full  "
                  htmlFor="address"
                >
                  <span className="text-[13px] ml-2  text-off ">Address</span>
                  <input
                    name="address"
                    id="address"
                    value={fields?.address}
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          address: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
                <label className="block px-2 my-1">
                  <span className="text-[13px] ml-2  text-off ">
                    Description
                  </span>
                  <textarea
                    placeholder=" Write a brief description..."
                    value={fields.description || ""}
                    onChange={(e) => {
                      setFields((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                    }}
                    className="h-[4.5rem] block placeholder:font-normal w-[17rem] placeholder:opacity-50 px-3 py-2.5 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  />
                </label>
                <label
                  className="inline-block px-2 my-1 w-full  "
                  htmlFor="defaultAttentionTo"
                >
                  <span className="text-[13px] ml-2  text-off ">
                    Default Attention To
                  </span>
                  <input
                    name="defaultAttentionTo"
                    id="defaultAttentionTo"
                    value={fields?.defaultAttentionTo}
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          defaultAttentionTo: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
              </div>

              {/*<PreferredInsurances
                insurers={state.insurers}
                insurances={insurances}
                setInsurances={setInsurances}
              />*/}
              <MultipleEntriesInput
                label="Specialties"
                specialties={fields.specialties}
                setFields={setFields}
                fieldName={"specialties"}
              />
            </div>
            {errorMessage && (
              <div
                className="bg-red-100 border border-red-400 text-red-700  py-3 px-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Error!</strong>
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}

            <div className="mt-6 w-4/5 mb-4 mx-auto">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  addOrUpdate();
                }}
                disabled={loading}
                className=" w-full rounded-lg flex justify-center items-center text-background bg-med py-2"
              >
                {!selectedInsurer ? "Add Insurer" : "Save changes"}
                {loading && (
                  <span className="">
                    <svg
                      className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25 stroke-[4px]"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function MultipleEntriesInput({specialties, setFields, label, fieldName}) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      const newEntries = new Set([...specialties, inputValue.trim()]);
      setInputValue("");
      setFields((prev) => {
        return {
          ...prev,
          [fieldName]: [...newEntries],
        };
      });
    }
  };

  return (
    <div className="space-y-2 px-2 my-1 w-full">
      <label className="relative block my-1 text-left" htmlFor="lastName">
        <span className="text-[13px] ml-2  text-off  ">{label}</span>
        <input
          name="lastName"
          id="lastName"
          className=" block w-full peer  py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          value={inputValue}
        />
        <div className="hidden w-full absolute top-[0px] right-2  text-[10px] mt-1 text-yellow-500 peer-focus:flex justify-end items-center space-x-1">
          <span className="">{"Type an option and press enter."}</span>
          <BsInfoCircleFill />
        </div>
      </label>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[80px]  w-full sbar2   p-2">
          {specialties.map((entry, index) => (
            <li
              key={index}
              className="inline-block mx-1 my-1  py-1 text-sm text-white font-medium bg-[#ACAAFF] rounded-md cursor-pointer relative px-4"
              onClick={() => {
                setFields((prev) => {
                  return {
                    ...prev,
                    [fieldName]: prev[fieldName].filter((f) => f !== entry),
                  };
                });
              }}
            >
              <svg
                aria-hidden="true"
                className="w-2 h-2 absolute to left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="">{entry}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function PreferredInsurances({insurances, setInsurances, insurers, creating}) {
  //flex flex-col justify-center items-center
  return (
    <div className="px-2 pr-0 w-full pb-1 flex  flex-col text-left items-start">
      <span className="text-[13px] ml-2 text-off">Preferred Insurances</span>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[160px]  w-full sbar2   p-2">
          {insurers.map((crit, i) => (
            <li
              onClick={() => {
                if (!creating) {
                  !insurances?.has(crit.iid)
                    ? setInsurances((prev) => {
                        let n = new Set(prev);
                        n.add(crit.iid);
                        return n;
                      })
                    : setInsurances((prev) => {
                        let n = new Set(prev);
                        n.delete(crit.iid);
                        return n;
                      });
                }
              }}
              key={i}
              className="hover:cursor-pointer m-1 flex items-center  p-1 py-0 px-0 space-x-1"
            >
              <span
                className=" h-5 w-5 rounded-md shadow-ntf bg-white hover:cursor-pointer"
                style={{
                  backgroundColor: insurances.has(crit.iid)
                    ? "#9F9DFA"
                    : "#FFFFFF",
                }}
              ></span>

              <span className="font-medium text-[0.9rem] text-[#8e8df9]">
                {crit.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function ProfilePhoto({fields, setFields}) {
  const fileRef = useRef(null);
  function onUpdatePhoto(ele) {
    let files = ele.target.files;
    let file = files[0];
    if (!file?.type.match(/image.*/i)) return;
    var reader = new FileReader();

    reader.addEventListener("load", (e) => {
      setFields((prev) => ({...prev, logo: e.target.result}));
    });
    reader.readAsDataURL(file);
  }
  return (
    <div className="flex justify-center items-center">
      <div className="h-14 p-2 bg-white rounded-lg shadow-lg flex justify-center items-center overflow-hidden relative">
        <input
          ref={fileRef}
          onChange={(e) => {
            onUpdatePhoto(e);
          }}
          className="absolute top-0 opacity-0 z-0"
          type="file"
          name="updatePhoto"
        />
        {fields?.logo ? (
          <img
            src={fields.logo}
            alt=""
            className="relative z-10 hover:cursor-pointer  h-full"
          />
        ) : (
          <GiHealing className="relative text-[2rem] text-[#8e8df9] z-10 hover:cursor-pointer  h-full rounded-full" />
        )}
        <div
          className="absolute group transition text-transparent hover:text-white text-lg inset-0 h-full w-full rounded-lg z-20 flex justify-center items-center hover:bg-sky-400/30 hover:cursor-pointer"
          onClick={() => {
            fileRef.current.click();
          }}
        >
          <BiEdit className="" />
        </div>
      </div>
    </div>
  );
}
function Warning({warning, setWarning, className}) {
  return (
    <div
      className={
        className ||
        "show z-10 absolute top-3 left-3 text-xs bg-yellow-500 p-3 w-48 rounded-md"
      }
    >
      <div className="font-bold flex">
        <span className="flex text-white items-center space-x-1">
          <img
            alt=""
            src="/images/Clinic/warn.png"
            className="h-4  inline-block"
          />{" "}
          <span> Warning:</span>
        </span>
        <span
          className="ml-auto mr-1 text-sm text-white underline cursor-pointer font-light"
          onClick={(e) => setWarning("")}
        >
          x
        </span>
      </div>
      <div className="font-regular mt-1 text-white">{warning}</div>
    </div>
  );
}
