import React, {useState, useEffect, useRef} from "react";
import Swal from "sweetalert2";
import PreviewForm from "./PreviewForm";
import {crud, viewForms} from "../crudRequests";
import {RiEditCircleFill} from "react-icons/ri";
import {IoDuplicate} from "react-icons/io5";
import {
  AiFillDelete,
  AiOutlineFileProtect,
  AiOutlineFileAdd,
} from "react-icons/ai";
import {v4 as uuidv4} from "uuid";
import {lightFormat} from "date-fns";
import PDFModal from "./PDFViewerModal";
import {MdOutlineClose} from "react-icons/md";
import duplicateForm from "./Forms/helpers/duplicateForm";
import {useToast} from "@chakra-ui/react";

export default function AllForms(props) {
  const state = props.state;
  const setCreateEdit = props.setCreateEdit;
  const [STATE_forms, STATE_setForms] = useState(null);
  const [STATE_consentForms, STATE_setConsentForms] = useState([]);
  const [STATE_searchText, STATE_setSearchText] = useState("");
  const [STATE_previewForm, STATE_setPreviewForm] = useState(null);
  const [showPdf, setShowPdf] = useState(null);
  const [duplicating, setDuplicating] = useState(null);
  const toast = useToast();
  const searchText = useRef(null);
  const searchRef = useRef(null);
  const fileRef = useRef(null);

  useEffect(() => {
    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowPdf(null);
      }
    };
    window.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  //console.log(STATE_forms);
  function removeForm(fid, collection) {
    Swal.fire({
      title: "Remove Form?",
      text: "Are you sure you would like to permanently remove this form from the database? You won't be able to revert this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it.",
    }).then((result) => {
      if (result.isConfirmed) {
        crud(state, [
          {
            db: state.db,
            collection: collection,
            parameters: [{fid}],
            method: "deleteOne",
          },
        ]).then(() =>
          crud(state, [
            {
              db: state.db,
              collection: "forms",
              //parameters: [{lid: state.selectedLocation}],
              parameters: [{}],
              method: "find",
            },
            {
              db: state.db,
              collection: "consentForms",
              parameters: [{}],
              method: "find",
            },
          ]).then((res) => {
            STATE_setForms(res.data[0]);
            STATE_setConsentForms(res?.data[1]);
          })
        );
      }
    });
  }

  function editForm(form) {
    setCreateEdit(form);
  }

  useEffect(
    function () {
      crud(state, [
        {
          db: state.db,
          collection: "forms",
          //parameters: [{lid: state.selectedLocation}],
          parameters: [{}],
          method: "find",
        },
        {
          db: state.db,
          collection: "consentForms",
          parameters: [{}],
          method: "find",
        },
      ]).then((res) => {
        STATE_setForms(res?.data[0]);
        STATE_setConsentForms(res?.data[1]);
      });
    },
    [state.selectedLocation]
  );

  async function onUpdateConsent(ele) {
    let files = ele.target.files;

    files = Array.from(files);
    /// if (!file.type.match(/image.*/i)) return;
    files = files.map((f) => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.addEventListener("load", (e) => {
          resolve({
            formTitle: f.name,
            data: e.target.result,
            type: f.type,
            fid: uuidv4(),
            lid: state.selectedLocation,
          });
        });
        reader.readAsDataURL(f);
      });
    });

    let res = await Promise.all(files);
    // console.log(res)
    await crud(state, [
      {
        db: state.db,
        collection: "consentForms",
        parameters: [res],
        method: "insertMany",
      },
    ])
      .then((e) => {
        STATE_setConsentForms((prev) => prev.concat(res));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      {showPdf && <PDFModal data={showPdf} showModal={setShowPdf} />}

      <div className="relative mb-5 space-y-6">
        <div className="flex items-center my-2 relative bg-off mx-2 mb-2   bg-opacity-25 text-opacity-100  w-[18rem] rounded-lg">
          <span
            className=" text-[16px] text-off absolute left-3"
            ref={searchRef}
            onClick={() => {
              searchRef.current.className = "hidden";
              searchText.current.focus();
            }}
          >
            <span className="font-semibold">Search forms</span>
          </span>
          <span className="text-xl text-off absolute right-2">
            <img
              src="/images/Doctor/search_icon.png"
              className="w-6 inline"
              alt=""
            />
          </span>
          <input
            type="text"
            className="w-full bg-off p-2 bg-opacity-0 text-off rounded-lg focus:outline-none  ring-[#8F8CFF] focus:ring-2 focus:ring-[#8F8CFF]"
            ref={searchText}
            value={STATE_searchText}
            onChange={(e) => STATE_setSearchText(e.target.value)}
            onFocus={() => {
              searchRef.current.className = "hidden";
            }}
            onBlur={() => {
              if (!STATE_searchText) {
                setTimeout(() => {
                  if (searchRef.current)
                    searchRef.current.className =
                      "text-xl text-off absolute left-3";
                }, 100);
              }
            }}
          />
        </div>
        <div className="ml-2 flex items-center ">
          <input
            ref={fileRef}
            onChange={(e) => {
              onUpdateConsent(e);
            }}
            className="absolute top-0 opacity-0 z-0 h-0 w-0"
            type="file"
            name="consentFiles"
            accept=".pdf"
            multiple
          />

          <div className="max-w-[42rem] mr-5 min-w-[22rem] min-h-[5rem] border relative max-h-[100px]  mb-2 border-[#d3d2ed] rounded-lg p-2">
            <span
              className="flex absolute cursor-pointer hover:text-indigo-600 -top-[18px] right-3 justify-center items-center text-[#a6a3f8] text-[1.7rem] bg-white"
              onClick={(e) => {
                e.stopPropagation();
                fileRef.current.click();
              }}
            >
              <AiOutlineFileAdd />
            </span>
            <span className="absolute text-xs -top-[9px] left-3 text-[#8F8CFF] font-medium bg-white px-[2px]">
              Consent forms
            </span>
            <ul className="w-full h-full max-h-40 overflow-scroll sbar2 flex items-start flex-wrap">
              {STATE_consentForms?.length > 0 &&
                STATE_consentForms.map((e, i) => (
                  <li
                    key={i}
                    className="text-[#FFFF] relative m-2 flex items-center cursor-pointer border p-4 rounded-lg bg-[#818cf8] group"
                    onClick={() => {
                      setShowPdf(e.data);
                    }}
                  >
                    <span
                      className="flex absolute top-[4px] right-[4px] justify-center items-center"
                      onClick={(eve) => {
                        eve.stopPropagation();
                        removeForm(e.fid, "consentForms");
                      }}
                    >
                      <AiFillDelete className="text-[1rem]  text-white text-opacity-75" />
                    </span>
                    <span className="text-[2rem] felx">
                      <AiOutlineFileProtect className="" />
                    </span>
                    <span className=" text-xs font-medium">{e.formTitle}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="px-2 overflow-scroll h-[calc(100vh-21rem)]  mb-5 flex content-start items-start flex-wrap pb-5">
        {STATE_forms ? (
          STATE_forms.map((form, i) => {
            return (
              form?.formTitle
                .toLowerCase()
                .trim()
                .includes(STATE_searchText.toLowerCase().trim()) && (
                <div
                  className=" bg-[#EEEEFF] p-4 w-[20rem] rounded-xl mr-10 mb-10 cursor-pointer relative"
                  key={i}
                >
                  <div
                    className="flex"
                    onClick={() => {
                      try {
                        let f = JSON.parse(form.formFields);
                        STATE_setPreviewForm(f);
                      } catch (e) {
                        console.log(e.message);
                      }
                    }}
                  >
                    <div className="h-[6rem] w-[0.5rem] rounded-3xl bg-gradient-to-b from-dark to-[#EEEEFF]"></div>
                    <div className="ml-6">
                      <div className="text-dark text-opacity-75 font-semibold text-xl">
                        {form.formTitle.slice(0, 16)}
                        {form.formTitle.length > 16 && "..."}
                      </div>
                      <div className="text-med text-md leading-4 mt-4">
                        {form.formDescription.slice(0, 60)}
                        {form.formDescription.length > 60 && "..."}
                      </div>
                      <div className="text-off text-opacity-60 text-md leading-4 mt-4 text-xs">
                        created on <i>{form.createdAt.split("T")[0]}</i> by{" "}
                        <b>{form.creatorName}</b>
                      </div>
                    </div>
                  </div>
                  <div className="  flex gap-1 absolute  top-3 right-3">
                    <RiEditCircleFill
                      onClick={() => editForm(form)}
                      className="  cursor-pointer text-dark text-opacity-75"
                      size={20}
                    />
                    <AiFillDelete
                      onClick={() => removeForm(form.fid, "forms")}
                      className=" cursor-pointer text-dark text-opacity-75"
                      size={20}
                    />
                    <IoDuplicate
                      onClick={() => {
                        if (!duplicating)
                          duplicateForm(
                            state,
                            form,
                            toast,
                            setDuplicating,
                            STATE_setForms
                          );
                      }}
                      className={`${
                        duplicating ? "cursor-not-allowed" : "cursor-pointer"
                      }   text-dark text-opacity-75`}
                      size={20}
                    />
                  </div>
                </div>
              )
            );
          })
        ) : (
          <>
            <div className="rounded-md">
              <div className="flex justify-center align-middle">
                <img src="/images/Doctor/loader.gif" className="h-36" />
              </div>
            </div>
          </>
        )}
      </div>

      {STATE_forms?.length == 0 && (
        <span className="ml-3 text-sm text-off">No forms to display</span>
      )}
      {STATE_previewForm && (
        <PreviewForm
          form={STATE_previewForm}
          type="view"
          state={state}
          close={STATE_setPreviewForm}
        />
      )}
    </>
  );
}
