import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {crud} from "../crudRequests";
import {BsPersonCircle} from "react-icons/bs";
import {IoMdCheckmarkCircle} from "react-icons/io";
let options = {
    instantMessaging: "Instant Messaging",
    transferPatient: "Transfer Patient",
    bookAppointment: "Book Appointment",
    documents: "Documents",
    notes: "Notes",
    patientName: "patient name",
    patientAge: "patient age",
    patientGender: "patient gender",
    patientEmail: "patient email",
    patientPhone: "patient phone",
    patientAddress: "patient address",
  };
export default function PermissionSetter({
    user,
}) {

  const [access, setAccess] = useState(user.access||{});
 
  //console.log(access)

  return (
    <div className="shadow- flex flex-col  bg-gradient-to-b from-[#2d29ff44] to-[#7b79ff0f] rounded-3xl p-5 h-[320px] space-y-2">
      <h1 className="text-[#7A79AD] w-[420px] font-semibold text-[1.7rem] text-center">
        {
          `Your access set by ${access.setBy}`}
      </h1>
      <div className="w-full flex justify-evenly  flex-1 overflow-y-hidden p-2">
        <SelecctionList
          access={access}
        />
      </div>
    </div>
  );
}


function SelecctionList({access}) {
 
  //flex flex-col justify-center items-center
  return (
    <div className="h-full w-[440px] text-left space-y-2 overflow-y-hidden">
      {
        <div className="h-full w-[200px]  overflow-y-hidden">
          <ul className="overflow-y-scroll h-full w-full sbar2  p-1 space-y-1">
            {Object.entries(options).map(([key, value], i) => (
              <li             
                key={i}
                className="hover:cursor-pointer w-full flex items-center  p-1 space-x-1"
              >
                <span
                  className="flex justify-center h-5 w-5 rounded-full items-center"
                  style={{
                    backgroundColor: access[key] ? "#7A79AD" : "#FFFFFF",
                  }}
                ></span>

                <span className="font-semibold text-[1rem] text-[#7A79AD]">
                  {value}
                </span>
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  );
}
