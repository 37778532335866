import {
  startOfWeek,
  add,
  differenceInDays,
  lightFormat,
  differenceInMinutes,
  startOfDay,
} from "date-fns";
import {isoToApptDate} from "../../additional_files/helpers";
import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
  useMemo,
} from "react";
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function SetTimeLine({
  date,
  view,
  selectedDoctor,
  schState,
  doctorsFilterMap,
  lineData,
  boardRef,
  defaultData,
}) {
  let {style, time} = useMemo(() => {
    let lengthPerMinute = 192 / 1440;
    let time = "";
    let style = {};
    if (lineData) {
      let y = lineData.y - 64 >= 0 ? lineData.y - 64 : 0;
      let x = lineData.x >= 0 ? lineData.x : 0;
      let minFromTop = Math.floor((1470 / boardRef.offsetHeight) * y);
      let sOfWeek = startOfWeek(new Date(date), {weekStartsOn: 1});
      let xunit = Math.floor(x / (boardRef.offsetWidth / 7));
      let end = view === "week" ? add(sOfWeek, {days: xunit}) : new Date(date);
      let fmin = Math.round(minFromTop / 15) * 15;
      end.setHours(0, 0, 0, 0);
      end.setMinutes(fmin);

      let top = `${fmin * lengthPerMinute + 4}rem`;
      let marging = 4;
      let left = selectedDoctor
        ? `calc(${
            differenceInDays(end, sOfWeek) * (view === "day" ? 0 : 14.2857)
          }% + ${marging / 2}px)`
        : `calc(${xunit * 14.2857}% + ${marging / 2}px)`;

      let width = selectedDoctor
        ? `calc(${view === "day" ? 100 : 14.2857}% - ${marging}px)`
        : `calc(${14.2857}% - ${marging}px)`;
      style = {top, left, width};
      time = `${months[end.getMonth()]} ${end.getDate()}  ${lightFormat(
        end,
        "h:mm aa"
      )}`;

      let did =
        Object.entries(doctorsFilterMap).find(([k, v]) => v === xunit)?.[0] ||
        "";
      let doctor =
        selectedDoctor ||
        (did ? schState.doctors[schState.doctorsPayload[did].index] : null);

      defaultData.current = {date: end, doctor};
    }
    return {style, time};
  }, [lineData]);

  //console.log(time)
  return (
    <>
      {lineData && (
        <div
          style={style}
          className="absolute z-10 border-t border-yellow-500 flex justify-center cursor-pointer"
        >
          <p className="absolute px-2 bottom-[2px] rounded-md border border-yellow-500 text-yellow-500 text-xs">
            {time}
          </p>
        </div>
      )}
    </>
  );
}

export function SetTimeLineIndicator({view, boardRef}) {
  const [top, setTop] = useState(0);

  useEffect(() => {
    let lengthPerMinute = 192 / 1440;

    let id = setInterval(() => {
      let start = startOfDay(new Date());
      let diff = differenceInMinutes(new Date(), start, {
        roundingMethod: "floor",
      });
      let y = `${diff * lengthPerMinute + 4}rem`;
      setTop(y);
    }, 1000);

    return () => clearInterval(id);
  }, []);

  return (
    <>
      {top && (
        <div
          style={{top}}
          className="absolute w-full z-0   border-b-[2px] border-gray-800 flex items-center cursor-pointer"
        >
          <span className="absolute h-3 w-3 rounded-full bg-gray-800"></span>
        </div>
      )}
    </>
  );
}
