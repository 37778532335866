import {crudStorage} from "./crudStorage";
import {viewFiles} from "./viewFiles";
import Swal from "sweetalert2";

export function deleteItem(
  dashState,
  path,
  selectedFile,
  setFiles,
  setSelectedFile,
  toast
) {
  if (selectedFile.slice(-1) != "/") {
    Swal.fire({
      title: "Delete File?",
      text: "Are you sure you want to delete this file and all of its contents?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Back`,
    }).then((result) => {
      if (result.isConfirmed) {
        crudStorage(
          dashState,
          "deleteFile",
          path + selectedFile,
          "brightlighthealth",
          ""
        )
          .then(() =>
            viewFiles(dashState, setFiles, setSelectedFile, path, toast)
          )
          .catch((err) => {
            toast({
              title: "Failed to delete file",
              description:
                err?.message || "An error occurred while deleting file.",
              status: "error",
              isClosable: true,
            });
          });
      }
    });
  } else {
    Swal.fire({
      title: "Delete Folder?",
      text: "Are you sure you want to delete this folder?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Back`,
    }).then((result) => {
      if (result.isConfirmed) {
        crudStorage(
          dashState,
          "deleteDirectory",
          "",
          "brightlighthealth",
          path + selectedFile.replace("/", "") // folder
        )
          .then(() =>
            viewFiles(dashState, setFiles, setSelectedFile, path, toast)
          )
          .catch((err) => {
            toast({
              title: "Failed to delete folder",
              description:
                err.response?.data?.message?.code == "DirectoryNotEmpty"
                  ? "You must empty this folder before deleting it."
                  : err?.message || "An error occurred while deleting folder.",
              status: "error",
              isClosable: true,
            });
          });
      }
    });
  }
}
