export default function addSummary(
  selectedSummary,
  setSelectedSummary,
  editorRef
) {
  if (selectedSummary) {
    editorRef?.current?.insertContent(
      "<p>" + selectedSummary.replaceAll("\n", "<br/>") + "</p>"
    );
    setSelectedSummary(null);
  }
}
