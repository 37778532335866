import {
  Button,
  Input,
  Flex,
  ButtonGroup,
  useToast,
  FormControl,
  FormLabel,
  Select,
  Tooltip,
  Badge,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Center,
  FormErrorMessage,
  List,
  ListItem,
  ListIcon,
  Textarea,
} from "@chakra-ui/react";
import {DeleteIcon, EditIcon} from "@chakra-ui/icons";
import {updateTasks} from "./helpers/updateTasks";
import {Field, Form, Formik} from "formik";
import {validateField} from "./helpers/validateField";
import TaskFiles from "./TaskFiles";
import {extractFileName} from "../Patients/Files/helpers/extractFileName";
import {useState} from "react";
import {getTaskDate} from "./helpers/getTaskDate";
import {status} from "./helpers/const";
import { SearchableSelect } from "../SearchableSelect";

export default function UpdateTask({dashState, taskData, patients, setTasks, patientId, doctors, groupedOptions, patientsArr, admins}) {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [files, setFiles] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [prevtaskData, setPrevTaskData] = useState()
  const toast = useToast()
  const disablePatientField = files?.length > 0 || patientId

  if (taskData !== prevtaskData) {
    setPrevTaskData(taskData)
    setFiles(taskData.files ?? [])
    const [month, day, year] = getTaskDate(taskData?.due).split('-')
    taskData.due = `${year}-${month}-${day}`
  }

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove));
  }

  return (
    <> 
      <Center>
        <Tooltip hasArrow label="Update" rounded={"sm"}>
          <Badge
            colorScheme="blue"
            rounded="md"
            fontSize='lg'
            py="1.5"
            px='2'
            cursor={"pointer"}
            onClick={onOpen}
            m={1}
          >
            <EditIcon />
          </Badge>
        </Tooltip>
      </Center>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
          <DrawerContent className="overflow-scroll">
            <DrawerCloseButton />
            <DrawerHeader>Update Task</DrawerHeader>
            <Formik
              initialValues={taskData}
              onSubmit={async(values, { setSubmitting }) => {
                values.files = files
                if (JSON.stringify(taskData) !== JSON.stringify(values)) {
                  let assignedToName = admins.find(admin => admin.id === values.assignedToId)
                  assignedToName = assignedToName
                  ? `${assignedToName.lastName}, ${assignedToName.firstName}`
                  : `${doctors[values.assignedToId]?.lastName}, ${doctors[values.assignedToId]?.firstName}`
                  await updateTasks(
                    values,
                    dashState,
                    setTasks,
                    assignedToName,
                    `${patients[values.pid]?.lName}, ${patients[values.pid]?.fName}`,
                    toast,
                  )
                }
                setSubmitting(false)
                onClose()
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <DrawerBody className="overflow-hidden p-4">
                    <Field name='task' validate={(value) => validateField('Task', value)}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.task && form.touched.task} my="2">
                          <Input {...field} placeholder='Task' variant="flushed"/>
                          <FormErrorMessage>{form.errors.task}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='description'>
                      {({ field, form }) => (
                        <FormControl my="2">
                          <Textarea
                            {...field}
                            variant='flushed'
                            placeholder='Description...'
                            size='sm'
                            resize='none'
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name='pid' validate={(value) => validateField('Patient', value, setIsDisabled)}>
                      {({ field, form }) => (
                        <SearchableSelect
                          label="Select patient"
                          placeholder="-"
                          options={patientsArr.map((option) => ({
                            value: option.pid,
                            label: `${option.lName}, ${option.fName}`,
                          }))}
                          isDisabled={disablePatientField}
                          error={form.errors.pid}
                          touched={form.touched.pid}
                          onChange={(option) => setFieldValue('pid', option.value)}
                          value={values.pid}
                        />
                      )}
                    </Field>
                    {dashState.doctors &&
                      <Field name='assignedToId' validate={(value) => validateField('Doctor', value)}>
                        {({ field, form }) => (
                          <SearchableSelect
                            label="Select Assigned To"
                            placeholder="-"
                            options={groupedOptions}
                            error={form.errors.assignedToId}
                            touched={form.touched.assignedToId}
                            onChange={(option) => setFieldValue('assignedToId', option.value)}
                            value={values.assignedToId}
                          />
                        )}
                      </Field>
                    }
                    <Field name='due' validate={(value) => validateField('Date', value)}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.due && form.touched.due} my="2" isRequired>
                          <FormLabel color={"gray.400"} mb="0" fontWeight={"normal"}>
                            Need by:
                          </FormLabel>
                          <Input
                            {...field}
                            variant="flushed"
                            placeholder="-"
                            type="date"
                            my="2"
                          />
                          <FormErrorMessage>{form.errors.due}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='status' validate={(value) => validateField('Status', value)}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.status && form.touched.status} my="2" isRequired>
                          <FormLabel color={"gray.400"} mb="0" fontWeight={"normal"}>
                            Status:
                          </FormLabel>
                          <Select 
                            {...field}
                            placeholder=""
                            variant="flushed"
                          >
                            <option key={3} value={3}>
                              {status[3]}
                            </option>
                            <option key={1} value={1}>
                              {status[1]}
                            </option>
                            <option key={2} value={2}>
                              {status[2]}
                            </option>
                          </Select>
                          <FormErrorMessage>{form.errors.due}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='files'>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.due && form.touched.due} my="2" isRequired>
                          <TaskFiles form={form} patient={patients[values?.pid] ?? null} dashState={dashState} setFilesArray={setFiles} filesArray={files} isDisabled={isDisabled}/>
                          <List my="2">
                            {files?.map((file) =>
                              <ListItem fontSize={'sm'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                <ListIcon as={DeleteIcon} color='red.500' onClick={() => handleRemoveFile(file)} cursor={'pointer'}/>
                                {extractFileName(file.name, 'name')}
                              </ListItem>
                            )}
                          </List>
                      </FormControl>
                    )}
                  </Field>
                  </DrawerBody>
                  <DrawerFooter>
                    <Flex justifyContent="end">
                      <ButtonGroup size="sm">
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          isLoading={isSubmitting}
                          colorScheme="blue"
                          type="submit"
                        >
                          Update Task
                        </Button>
                      </ButtonGroup>
                    </Flex>
                  </DrawerFooter>
                </Form>
              )}
            </Formik>
          </DrawerContent>
      </Drawer>
    </>
  );
}
