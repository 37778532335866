import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  FormControl,
  Switch,
  Icon,
  Text,
  VStack,
  Textarea,
  Button,
  Center,
} from "@chakra-ui/react";
import React, {
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import {MdInfoOutline} from "react-icons/md";
export default function CancelAppointment({
  onConfirm,
  onClose,
  isOpen,
  status,
  title,
}) {
  const [allowAction, setAllowAction] = useState(false);
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const initialRef = useRef(null);
  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="0">
          <Center>
            <Icon
              className="show-icon"
              boxSize={20}
              color="yellow.400"
              as={MdInfoOutline}
            />
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="0">
          <form
            id="my-form"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoading(true);
              await onConfirm({reason, allowAction, status});
              onClose(false);
              setIsLoading(false);
            }}
          >
            <VStack gap={2}>
              <Text fontSize={"2xl"} textAlign={"center"} fontWeight={600}>
                {title}
              </Text>
              {status !== "no-show" && (
                <Textarea
                  ref={initialRef}
                  required
                  isInvalid={reason === ""}
                  resize={"vertical"}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Write the reason for the cancellation..."
                  size="sm"
                />
              )}
            </VStack>
            <FormControl mt="2" display="flex" alignItems="center">
              <FormLabel
                color={allowAction ? "blue.500" : "gray.400"}
                htmlFor="allowAction"
                mb="0"
                cursor={"pointer"}
              >
                {status === "cancel-billing"
                  ? `Cancel the appointment?`
                  : `Allow payment of this appointment?`}
              </FormLabel>
              <Switch
                isChecked={allowAction}
                onChange={(e) => {
                  setAllowAction((prev) => !prev);
                }}
                id="allowAction"
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            type="submit"
            form="my-form"
            colorScheme="blue"
            mr={3}
          >
            Confirm
          </Button>
          <Button onClick={() => onClose(false)} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
