import {
  startOfMonth,
  endOfMonth,
  add,
  sub,
  isSameDay,
  lightFormat,
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  eachDayOfInterval,
} from "date-fns";
import {isoToApptDate, statusInDays} from "../additional_files/helpers";
import {MdOutlineArrowBackIos, MdOutlineArrowForwardIos} from "react-icons/md";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function DatePicker(props) {
  let {date, setDate, view, appointmentsByDoctor, schState} = props;
  let statusInDaysMap = statusInDays(
    appointmentsByDoctor.filter((appt) =>
      schState?.selectedPatient && schState?.selectedPatient?.pid !== appt.pid
        ? false
        : true
    )
  );

  let colorsMap = {
    noShow: "#000000",
    cancelled: "#FF0000",
    arrived: "#2dd4bf",
    pConfirmed: "#5754FF",
    rescheduled: "#FF9900",
    unconfirmed: "#8F8CFF",
  };

  function setDaysOfMonth(date) {
    let listOfMonthDays = [];
    let Mstart = startOfMonth(date);
    let Mend = endOfMonth(date);
    let Wstart = startOfWeek(Mstart, {weekStartsOn: 1});
    let Wend = endOfWeek(Mend, {weekStartsOn: 1});
    let Days = eachDayOfInterval({
      start: Wstart,
      end: Wend,
    });

    listOfMonthDays = Days.map((day, dayIdx) => {
      let dateString = `${weekday[day.getDay()]}, ${
        months[day.getMonth()]
      } ${day.getDate()}, ${day.getFullYear()}`;
      let colorsArr = [];
      colorsArr[0] =
        statusInDaysMap.get(dateString)?.has("pConfirmed") &&
        colorsMap["pConfirmed"];
      colorsArr[1] =
        statusInDaysMap.get(dateString)?.has("unconfirmed") &&
        colorsMap["unconfirmed"];
      colorsArr[2] =
        statusInDaysMap.get(dateString)?.has("rescheduled") &&
        colorsMap["rescheduled"];
      colorsArr[3] =
        statusInDaysMap.get(dateString)?.has("cancelled") &&
        colorsMap["cancelled"];
      colorsArr[4] =
        statusInDaysMap.get(dateString)?.has("noShow") && colorsMap["noShow"];
      colorsArr[5] =
        statusInDaysMap.get(dateString)?.has("arrived") && colorsMap["arrived"];

      let isCurrentMonth = isWithinInterval(day, {start: Mstart, end: Mend});
      let isSelected = isSameDay(day, date);
      let isToday = isSameDay(day, new Date());
      return (
        <button
          key={dayIdx}
          type="button"
          data-modal-toggle="small-modal"
          onClick={() => {
            setDate(day);
          }}
          className={classNames(
            "py-[4px] hover:bg-gray-100 focus:z-10 relative",
            isCurrentMonth ? "bg-white" : "bg-gray-50",
            (isSelected || day.isToday) && "font-semibold",
            isSelected && "text-white",
            !isSelected && isCurrentMonth && !isToday && "text-gray-900",
            !isSelected && !isCurrentMonth && !isToday && "text-gray-400",
            isToday && !isSelected && "text-indigo-600",
            dayIdx === 0 && "rounded-tl-lg",
            dayIdx === 6 && "rounded-tr-lg",
            dayIdx === Days.length - 7 && "rounded-bl-lg",
            dayIdx === Days.length - 1 && "rounded-br-lg"
          )}
        >
          <time
            dateTime={lightFormat(day, "yyyy-MM-dd")}
            className={classNames(
              "mx-auto flex h-[26px] w-[26px] items-center justify-center rounded-full",
              isSelected && isToday && "bg-indigo-600",
              isSelected && !isToday && "bg-gray-900"
            )}
          >
            {day.getDate()}
          </time>
          {appointmentsByDoctor?.length > 0 && (
            <div className="absolute flex justify-evenly items-center flex-col right-1 top-0 h-full ">
              {colorsArr.map((dotColor, i) => {
                return (
                  <span
                    key={i}
                    className="h-[5px] w-[5px] block rounded-full bg-gray-300"
                    style={{backgroundColor: `${dotColor || ""}`}}
                  ></span>
                );
              })}
            </div>
          )}
        </button>
      );
    });

    return listOfMonthDays;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div
      className={
        view !== "day"
          ? "show py-10 pt-3 p-2 absolute   z-40 top-0  h-full right-0 hidden w-1/2 max-w-md flex-none bg-gray-100/50  rounded-lg lg:flex"
          : view === "day" && !props.selectedDoctor
          ? "show py-10 p-2 absolute  z-40 -top-10 h-full right-0 hidden w-1/2 max-w-md flex-none bg-gray-100/50  rounded-lg lg:flex"
          : "hidden w-1/2 max-w-md flex-none border-l border-gray-100 py-10  p-2 lg:flex"
      }
      onClick={(e) => e.stopPropagation()}
    >
      <div className="w-full h-full">
        <div
          className={
            view !== "day" || (view === "day" && !props.selectedDoctor)
              ? "flex items-center text-center text-gray-900 w-full"
              : "flex items-center text-center text-gray-900"
          }
        >
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => {
              setDate((prev) => sub(prev, {months: 1}));
            }}
          >
            <span className="sr-only">Previous month</span>
            <MdOutlineArrowBackIos className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto font-semibold">
            {months[date.getMonth()]} {date.getFullYear()}
          </div>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => {
              setDate((prev) => add(prev, {months: 1}));
            }}
          >
            <span className="sr-only">Next month</span>
            <MdOutlineArrowForwardIos className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-2 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>M</div> <div>T</div> <div>W</div> <div>T</div> <div>F</div>{" "}
          <div>S</div> <div>S</div>
        </div>
        <div className="isolate flex-1  mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          {setDaysOfMonth(date)}
        </div>
      </div>
    </div>
  );
}
