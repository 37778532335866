import React, {useState} from "react";
import {crud} from "../crudRequests";
import {nanoid} from "nanoid";
import ImageInput from "./ImageInput";
//
import timeZones from "../additional_files/timeZones.json";
const ModalAddLocation = ({
  show,
  setShow,
  state,
  locationData,
  setLocationData,
  allLocations,
  populatePeopleByLid,
  setLocations,
  dispatch,
  modalMode = "add",
}) => {
  const oid =
    state.userType === "doctor" ? state.doctor?.oid : state.admin?.oid;
  const [errorMessage, setErrorMessage] = useState("");
  let timeZoneList = timeZones.sort();
  const insertLidIntoCurrentAdmin = async (state, newLid) => {
    if (state.userType !== "admin") return;
    const prevLocations = state.admin.location;
    const newLocations = [...prevLocations, newLid];
    const updateBody = {
      super: true,
      id: state.admin.id,
      location: newLocations,
    };

    if (newLocations.length < 2) {
      throw new Error("Admin must have at least one location");
    }

    console.log("updating admin", updateBody);
    const res = await crud(state, [
      {
        db: state.db,
        collection: "admins",
        parameters: [{id: updateBody.id}, {$set: updateBody}],
        method: "updateOne",
      },
    ]);
    if (res.status === 200) {
      console.log("inserted new Lid into admin", res);
      dispatch({type: "UPDATE_USER", payload: {location: newLocations}});
      populatePeopleByLid(newLid);
    } else {
      setErrorMessage("Error updating admin");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // since edit and add use the same modal, we need to check which mode we're in
    if (modalMode === "edit") {
      let {_id, stpSecretKey, ...data} = locationData;

      if (stpSecretKey?.trim()) {
        data.stpSecretKey = stpSecretKey;
      }

      const res = await crud(state, [
        {
          db: state.db,
          collection: "locations",
          parameters: [{lid: data.lid}, {$set: data}],
          method: "updateOne",
        },
      ]);

      if (res.status === 200) {
        const updatedLocations = allLocations.current.map((location) => {
          if (location.lid === data.lid) {
            return locationData;
          }
          return location;
        });
        dispatch({type: "UPDATE_LOCATIONS", payload: updatedLocations});
        allLocations.current = updatedLocations;

        setLocations(updatedLocations);
        setShow(false);
      } else {
        setErrorMessage("Error updating location");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }

      return;
    }

    const locationPayload = {
      lid: nanoid(10),
      oid,
      name: locationData.name,
      description: locationData.description,
      logo: locationData.logo || "",
      address: locationData.address || "",
      phone: locationData.phone || "",
      fax: locationData.fax || "",
      phaxioNumber: locationData.phaxioNumber || "",
      productsTaxRate: locationData.productsTaxRate || 0,
      stpSecretKey: locationData.stpSecretKey || "",
      stpPublicKey: locationData.stpPublicKey || "",
      invRecNotes: locationData.invRecNotes || "",
      timeZone: locationData.timeZone || "",
    };

    const res = await crud(state, [
      {
        db: state.db,
        collection: "locations",
        parameters: [locationPayload],
        method: "insertOne",
      },
    ]);
    if (res.status === 200) {
      setLocationData({
        name: "",
        description: "",
        logo: "",
        imgList: "",
        address: "",
        phone: "",
        fax: "",
        phaxioNumber: "",
        productsTaxRate: 0,
        stpSecretKey: "",
        stpPublicKey: "",
        invRecNotes: "",
        timeZone: "",
      });

      dispatch({type: "NEW_LOCATION", payload: locationPayload});
      await insertLidIntoCurrentAdmin(state, locationPayload.lid);

      const updatedLocations = [
        ...allLocations.current,
        {...locationPayload, _id: res.data.insertedId},
      ];
      allLocations.current = updatedLocations;
      setLocations(updatedLocations);
      setShow(false);
    } else {
      setErrorMessage("Error adding location");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };
  return (
    <dialog open={show} className="z-[9999]">
      <div className="fixed inset-0 z-9999 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className="relative inline-block align-bottom bg-[#f6f5ff] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <button
              type="button"
              className="z-[9999] cursor-pointer text-gray-400 p-1 bg-transparent  absolute top-5 right-5 rounded-lg text-sm  ml-auto inline-flex items-center focus:outline-none"
              data-modal-toggle="small-modal"
              style={{boxShadow: "none"}}
              onClick={() => setShow(false)}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <div className="px-4 pt-5  pb-3 sm:p-6 sm:pb-4">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-3xl font-medium text-gray-800 mb-6"
                  style={{color: "rgb(192, 191, 255)"}}
                >
                  {modalMode === "add" ? "Add Location" : "Edit Location"}
                </h3>
              </div>
            </div>

            <form
              onSubmit={submitHandler}
              className="h-full"
              name="addServiceForm"
              id="addServiceForm"
            >
              <div className=" overflow-hidden sm:rounded-md">
                <div className="px-4 pb-2">
                  <div className="flex items-center  justify-center mb-5">
                    <label className="block mx-3.5">
                      <span className="text-[13px] text-off ml-2">Logo </span>
                      <ImageInput
                        setData={setLocationData}
                        field={"logo"}
                        previous={locationData.logo}
                        showModal={show}
                      />
                    </label>
                  </div>
                  <div className="flex justify-between w-full gap-2">
                    <label className="block mx-3.5 mt-1" htmlFor="locationName">
                      <span className="text-[13px] text-off ml-2">
                        Location Name
                      </span>
                      <input
                        type="text"
                        name="locationName"
                        id="locationName"
                        required
                        value={locationData.name}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            name: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label className="block mx-3.5 mt-1" htmlFor="address">
                      <span className="text-[13px] text-off ml-2">Address</span>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        required
                        value={locationData.address}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            address: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex justify-between w-full gap-2">
                    <label className="block mx-3.5 mt-1" htmlFor="phone">
                      <span className="text-[13px] text-off ml-2">Phone</span>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        required
                        value={locationData.phone}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            phone: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label className="block mx-3.5 mt-1" htmlFor="fax">
                      <span className="text-[13px] text-off ml-2">Fax</span>
                      <input
                        type="text"
                        name="fax"
                        id="fax"
                        required
                        value={locationData.phaxioNumber}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            phaxioNumber: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex  w-full gap-2">
                    <label
                      className="block mx-3.5 mt-1 flex-1"
                      htmlFor="productsTaxRate"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Product Tax Rate (%)
                      </span>
                      <input
                        type="number"
                        name="productsTaxRate"
                        id="productsTaxRate"
                        required
                        value={locationData.productsTaxRate}
                        onChange={(e) => {
                          let value = e.target.value;

                          if (!isNaN(value) && !value.match(/-/g)) {
                            setLocationData({
                              ...locationData,
                              productsTaxRate: value,
                            });
                          }
                        }}
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label
                      className="block mx-3.5 mt-1 flex-1"
                      htmlFor="timeZone"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Select Timezone
                      </span>
                      <select
                        onChange={(e) => {
                          setLocationData({
                            ...locationData,
                            timeZone: e.currentTarget.value,
                          });
                        }}
                        value={locationData.timeZone || ""}
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      >
                        <option value="">-</option>
                        {timeZoneList.map((option) => (
                          <option value={option}>{option}</option>
                        ))}
                      </select>
                    </label>
                  </div>
                  <div className="flex px-3.5 justify-between w-full">
                    <label
                      className="block  mt-3 w-full"
                      htmlFor="stpPublicKey"
                    >
                      <span className="text-[11px] text-off ml-1">
                        Stripe Public Key
                      </span>
                      <input
                        onChange={(e) => {
                          setLocationData({
                            ...locationData,
                            stpPublicKey: e.target.value,
                          });
                        }}
                        value={locationData.stpPublicKey || ""}
                        type="text"
                        name="stpPublicKey"
                        id="stpPublicKey"
                        className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>

                  <div className="flex px-3.5 justify-between w-full">
                    <label
                      className="block  mt-3 w-full"
                      htmlFor="stpSecretKey"
                    >
                      <span className="text-[11px] text-off ml-1">
                        Stripe Secret Key
                      </span>
                      <input
                        onChange={(e) => {
                          setLocationData({
                            ...locationData,
                            stpSecretKey: e.target.value,
                          });
                        }}
                        value={locationData.stpSecretKey || ""}
                        type="password"
                        name="stpSecretKey"
                        id="stpSecretKey"
                        className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex  justify-between w-full">
                    <label
                      className="block w-full mx-3.5 mt-3"
                      htmlFor="invRecNotes"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Invoice/Receipt Notes
                      </span>
                      <textarea
                        name="invRecNotes"
                        id="invRecNotes"
                        required
                        value={locationData.invRecNotes}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            invRecNotes: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                  <div className="flex  justify-between w-full">
                    <label
                      className="block w-full mx-3.5 mt-3"
                      htmlFor="locationDescription"
                    >
                      <span className="text-[13px] text-off ml-2">
                        Description
                      </span>
                      <textarea
                        name="locationDescription"
                        id="locationDescription"
                        required
                        value={locationData.description}
                        onChange={(e) =>
                          setLocationData({
                            ...locationData,
                            description: e.target.value,
                          })
                        }
                        className="drop-shadow block w-full px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </form>
            {errorMessage && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Error!</strong>
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}
            <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                form="addServiceForm"
                className="mt-6 w-[27.25rem] mb-4 mx-3.5 rounded-lg text-background bg-med py-2"
              >
                {modalMode === "add" ? "Add Location" : "Edit Location"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ModalAddLocation;
