import {useEffect, useRef, useState} from "react";
import {
  BsSearch,
  BsPencilFill,
  BsTrash,
  BsStar,
  BsStarFill,
} from "react-icons/bs";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";

import useFindPhaxioNumber from "./helpers/useFindPhaxioNumber";
import deletePharmacy from "./helpers/deletePharmacy";
import fax from "./helpers/fax";
import pharmAutocomplete from "./helpers/pharmAutocomplete";
import updatePharmacy from "./helpers/updatePharmacy";
import uploadPharmacy from "./helpers/uploadPharmacy";
import {crud} from "../../../crudRequests";

const AddOrEditPharmacy = (props) => {
  const [formValues, setFormValues] = useState({
    name: props.name || "",
    phone: props.phone || "",
    fax: props.fax || "",
    address: props.address || "",
  });
  const toast = useToast();
  return (
    <>
      <Heading mt={4}>
        {/* if the _id is passed, then that means the pharmacy already exists and is going to be edited */}
        {props._id ? "Add" : "Edit"} Pharmacy
      </Heading>
      <Grid my={4} templateColumns="1fr 1fr">
        <Image
          src="/images/Doctor/rod.png"
          alt="Rod of Asclepius"
          placeSelf="center"
        />
        <Grid templateColumns="2" gap={4}>
          <GridItem colSpan={2}>
            <Text>pharmacy name</Text>
            <Input
              value={formValues.name}
              onChange={(e) =>
                setFormValues((prev) => ({...prev, name: e.target.value}))
              }
            />
          </GridItem>
          <div>
            <Text>phone</Text>
            <Input
              value={formValues.phone}
              onChange={(e) =>
                setFormValues((prev) => ({...prev, phone: e.target.value}))
              }
            />
          </div>
          <div>
            <Text>fax</Text>
            <Input
              value={formValues.fax}
              onChange={(e) =>
                setFormValues((prev) => ({...prev, fax: e.target.value}))
              }
            />
          </div>
          <GridItem colSpan={2}>
            <Text>address</Text>
            <Input
              value={formValues.address}
              onChange={(e) =>
                setFormValues((prev) => ({...prev, address: e.target.value}))
              }
            />
          </GridItem>
          <Button
            bg="black"
            textColor="white"
            onClick={() => {
              if (props._id) {
                updatePharmacy(
                  props.state,
                  props.phid,
                  formValues,
                  props.query,
                  props.setOpenPage,
                  toast
                );
              } else {
                uploadPharmacy(
                  formValues,
                  props.state,
                  props.query,
                  props.setOpenPage,
                  toast
                );
              }
            }}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const PrescriptionFax = ({state, faxUrl}) => {
  const toast = useToast();
  const [pharmacies, setPharmacies] = useState([
    // {
    //   name: "test",
    //   phone: "test",
    //   fax: "+16473707206",
    //   address: "test",
    //   _id: 0,
    //   phid: 0,
    // },
  ]);

  //   the selected pharmacy
  const [pharmacy, setPharmacy] = useState(null);
  useEffect(() => {
    setLoading(true);
    pharmAutocomplete(state, "", setPharmacies, toast, () => setLoading(false));
  }, []);

  const [addPharm, setAddPharm] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [updatePharm, setUpdatePharm] = useState(false);
  // current pharmacy being edited
  const [currEditPharm, setCurrEditPharm] = useState(false);

  const timeout = useRef();

  const [fromNumber] = useFindPhaxioNumber(state);

  const [favPharmacies, setFavPharmacies] = useState([]);
  const [favOnly, setFavOnly] = useState(false);

  useEffect(() => {
    crud(state, [
      {
        db: state.db,
        collection: "locations",
        parameters: [
          {lid: state.selectedLocation, oid: state.organization.oid},
        ],
        method: "findOne",
      },
    ]).then((res) => {
      if (res?.data[0]?.favPharmacies)
        setFavPharmacies(res.data[0].favPharmacies);
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      timeout.current = setTimeout(() => {
        setLoading(true);
        pharmAutocomplete(
          state,
          search,
          setPharmacies,
          toast,
          () => setLoading(false),
          favOnly ? favPharmacies : []
        );
      }, 1000);
    }
  }, [search, favOnly]);

  const addFavPharm = (phid) => {
    crud(state, [
      {
        db: state.db,
        collection: "locations",
        parameters: [
          {lid: state.selectedLocation, oid: state.organization.oid},
          {$addToSet: {favPharmacies: phid}},
        ],
        method: "updateOne",
      },
    ]).then((res) => {
      //console.log({res});
    });
  };

  const removeFavPharm = (phid) => {
    crud(state, [
      {
        db: state.db,
        collection: "locations",
        parameters: [
          {lid: state.selectedLocation, oid: state.organization.oid},
          {$pull: {favPharmacies: phid}},
        ],
        method: "updateOne",
      },
    ]).then((res) => {
      //console.log({res});
    });
  };

  if (addPharm)
    return (
      <AddOrEditPharmacy
        state={state}
        setOpenPage={setAddPharm}
        query={() =>
          pharmAutocomplete(state, search, setPharmacies, toast, () =>
            setLoading(false)
          )
        }
      />
    );

  if (updatePharm)
    return (
      <AddOrEditPharmacy
        state={state}
        setOpenPage={setUpdatePharm}
        query={() =>
          pharmAutocomplete(state, search, setPharmacies, toast, () =>
            setLoading(false)
          )
        }
        {...currEditPharm}
      />
    );

  return (
    <Box h="600px" w="550px">
      <Heading my={4} mt={8}>
        Pharmacies
      </Heading>
      <Grid gap={2} templateColumns="1fr auto" mb={4}>
        <InputGroup>
          <Input
            onChange={(e) => {
              setSearch(e.target.value);
              setPharmacy(null);
            }}
            value={search}
            placeholder="search..."
          />
          <InputRightElement>
            <SearchIcon cursor="pointer" />
          </InputRightElement>
        </InputGroup>

        <Button onClick={() => setAddPharm(true)}>add pharmacy</Button>
      </Grid>
      <Flex mb={4} gap={2}>
        <Button
          bg={favOnly ? "gray.300" : "black"}
          color={favOnly ? "black" : "white"}
          onClick={() => setFavOnly(false)}
        >
          All
        </Button>
        <Button
          bg={!favOnly ? "gray.200" : "black"}
          color={!favOnly ? "black" : "white"}
          onClick={() => setFavOnly(true)}
        >
          Favourites
        </Button>
      </Flex>
      {/* pharmacies list */}
      {loading ? (
        <Box justifySelf="center" h="350px">
          <Spinner />
        </Box>
      ) : (
        <Box justifySelf="center" overflowY="scroll" h="350px" w="550px">
          {pharmacies?.map((item) => (
            <Grid
              key={item._id}
              p={2}
              mb={2}
              borderBottom="black"
              templateColumns="1fr auto auto"
              gap={2}
              cursor="pointer"
              textAlign="start"
              w="full"
              bg={pharmacy?._id === item?._id ? "#757575" : ""}
              onClick={() => setPharmacy(item)}
            >
              <Box className="text-[12px]">
                <Text fontSize="sm">{item.name}</Text>
                <Text fontSize="xs">
                  <b>address:</b> {item.address}
                </Text>
                <Text fontSize="xs">
                  <b>phone:</b> {item.phone}
                </Text>
                <Text fontSize="xs">
                  <b>fax:</b> {item.fax}
                </Text>
              </Box>
              <Flex gap={2}>
                {item?.oid && item?.lid && (
                  <>
                    <BsPencilFill
                      size={20}
                      onClick={() => {
                        setCurrEditPharm(item);
                        setUpdatePharm(true);
                      }}
                    />
                    <BsTrash
                      size={20}
                      onClick={() => {
                        deletePharmacy(state, item.phid, toast, () => {
                          setLoading(true);
                          pharmAutocomplete(
                            state,
                            search,
                            setPharmacies,
                            toast,
                            () => setLoading(false)
                          );
                        });
                      }}
                    />
                  </>
                )}

                {favPharmacies?.includes(item?.phid) ? (
                  <BsStarFill
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFavPharm(item.phid);
                      setFavPharmacies((prev) =>
                        prev.filter((i) => i !== item.phid)
                      );
                    }}
                    bg="yellow"
                  />
                ) : (
                  <BsStar
                    onClick={(e) => {
                      e.stopPropagation();
                      addFavPharm(item.phid);
                      setFavPharmacies((prev) => [...prev, item.phid]);
                    }}
                    bg="yellow"
                  />
                )}
              </Flex>
            </Grid>
          ))}
        </Box>
      )}
      {pharmacy && fromNumber && pharmacy?.fax && faxUrl && (
        <Button
          px="10px"
          py="20px"
          w="150px"
          my={4}
          rounded="full"
          justifyContent="center"
          alignItems="center"
          bg="#272727"
          fontSize="md"
          textColor="white"
          onClick={() => {
            if (pharmacy?.fax && faxUrl) {
              fax(state, pharmacy.fax, faxUrl, fromNumber, toast);
            }
          }}
        >
          fax
        </Button>
      )}
    </Box>
  );
};

export default PrescriptionFax;
