import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect} from "react";
import {viewFiles} from "./helpers/viewFiles";
import {Box, Flex, Button, useToast, Spacer} from "@chakra-ui/react";
import CreateFolder from "./CreateFolder";
import UploadFile from "./UploadFile";
import Path from "./Path";
import SelectedControls from "./SelectedControls";
import ItemsTable from "./ItemsTable";
import SearchItems from "./SearchItems";

export default function Files(props) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const toast = useToast();

  const [path, setPath] = useState(props.path || patient["pid"] + "/");
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState(false);
  const [addFolder, setAddFolder] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [folderName, setFolderName] = useState("");
  const [selectedFileUpload, setSelectedFileUpload] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);

  useEffect(
    () => viewFiles(dashState, setFiles, setSelectedFile, path, toast),
    [path]
  );

  return (
    <Box mx="auto" my="6" pb="6" px="8" boxShadow="md" bg="white">
      <CreateFolder
        addFolder={addFolder}
        setAddFolder={setAddFolder}
        path={path}
        folderName={folderName}
        setFolderName={setFolderName}
        setFiles={setFiles}
        setSelectedFile={setSelectedFile}
      />
      <UploadFile
        addFile={addFile}
        setAddFile={setAddFile}
        path={path}
        setFiles={setFiles}
        setSelectedFile={setSelectedFile}
        uploadLoader={uploadLoader}
        setUploadLoader={setUploadLoader}
        selectedFileUpload={selectedFileUpload}
        setSelectedFileUpload={setSelectedFileUpload}
      />
      <Flex textAlign={"center"} justifyContent={"center"} mb="4">
        <Button
          colorScheme="blue"
          variant="outline"
          rounded="md"
          px="8"
          py="6"
          mx="2"
          onClick={() => setAddFolder(!addFolder)}
        >
          Create folder
        </Button>
        <Button
          colorScheme="blue"
          rounded="md"
          px="8"
          py="6"
          mx="2"
          onClick={() => setAddFile(!addFile)}
        >
          Upload file
        </Button>
      </Flex>
      <Flex mt="8" ml="1">
        <Path
          path={path}
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          toast={toast}
          setPath={setPath}
          patient={patient}
        />
        <Spacer />
        <SelectedControls
          selectedFile={selectedFile}
          path={path}
          toast={toast}
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          preview={preview}
          setPreview={setPreview}
          patient={patient}
        />
      </Flex>
      <SearchItems searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <ItemsTable
        files={files}
        searchQuery={searchQuery}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        props={props}
        path={path}
        setPath={setPath}
        setPreview={setPreview}
        toast={toast}
      />
    </Box>
  );
}
