import {useReducer, useState, useEffect, createContext} from "react";
import {useLocation} from "react-router-dom";
import {scheduleReducer, init} from "../additional_files/reducer";
import {verifyUser} from "../authRequests";
import Navigation from "../components/Navigation";
import Inventory from "../components/Products";

export const DashStateContext = createContext();
export const DashDispatchContext = createContext();

export default function Products() {
  const {state} = useLocation();

  const [dashState, dispatch] = useReducer(scheduleReducer, state, init);

  useEffect(function () {
    verifyUser(state ? state.jwt : "");
  }, []);

  return (
    <>
      {
        <Navigation state={dashState} dispatch={dispatch}>
          <DashStateContext.Provider value={dashState}>
            <DashDispatchContext.Provider value={dispatch}>
              <Inventory />
            </DashDispatchContext.Provider>
          </DashStateContext.Provider>
        </Navigation>
      }
    </>
  );
}
