import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {updatePatientStatus} from "./helpers/updatePatientStatus";

export default function DeletedModal({
  deletedModal,
  setDeletedModal,
  clearPatientSelection,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={deletedModal}
      onClose={() => setDeletedModal(null)}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Patient</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          I wish to delete this patient and all of their associated data
          including all of their chart and files.
          <br />
          <br />
          <b>This action cannot be undone.</b>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            variant="outline"
            mr="3"
            onClick={() => {
              setDeletedModal(null);
            }}
          >
            Go Back
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              setDeletedModal(null);
              setPatient({...patient, deleted: true});
              updatePatientStatus(
                patient,
                dashState,
                patient?.inactive,
                patient?.deceased,
                true,
                toast
              );
              clearPatientSelection(null);
              navigate(`/patients`, {
                state: {
                  ...dashState,
                  firstLoading: true,
                },
              });
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
