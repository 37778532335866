import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
  Button,
  NumberInput,
  NumberInputField,
  FormControl,
  Grid,
} from "@chakra-ui/react";
import {nanoid} from "nanoid";
import {
  useState,
  useMemo,
} from "react";
import {MdNumbers, MdAttachMoney} from "react-icons/md";
import {createItem} from "./helpers/createItem";
import {updateItem} from "./helpers/updateItem";
export default function EditOrCreate({
  showEditOrCreateModal,
  setShowEditOrCreateModal,
  selectedItem,
  setSelectedItem,
  dashState,
  dispatch,
  setProducts,
  categories,
  categoriesMap,
  toast,
  waiting,
  setWaiting,
}) {
  const defaultData = useMemo(
    () => ({
      id: nanoid(10),
      name: "",
      description: "",
      category: "",
      price: 0,
      stock: 0,
      categoryId: "",
      lid: dashState.selectedLocation,
    }),
    []
  );

  const [fields, setFields] = useState(
    selectedItem ? {...defaultData, ...selectedItem} : defaultData
  );

  return (
    <Drawer
      isOpen={showEditOrCreateModal}
      placement="right"
      size={"sm"}
      onClose={() => {
        setShowEditOrCreateModal(false);
        setSelectedItem(null);
      }}
      trapFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottomWidth="1px"
          color={"#1A202C"}
          fontWeight={600}
        >
          {selectedItem ? "Update Product" : "Create Product"}
        </DrawerHeader>

        <DrawerBody pt="7">
          <Box h={"full"} w="full" overflow={"auto"}>
            <form
              id="prodform"
              onSubmit={async (e) => {
                e.preventDefault();
                if ((fields.minInventory && !fields.maxInventory) || (!fields.minInventory && fields.maxInventory)) {
                  return toast({
                    title: "Failed to update product",
                    description: "Max Inventory has to be bigger than Min Inventory",
                    status: "error",
                    isClosable: true,
                  });
                }
                if (!waiting) {
                  setWaiting(true);
                  let product = {...fields};
                  product.price = parseFloat(fields.price);
                  product.stock = parseFloat(fields.stock);
                  let action = () => {
                    setShowEditOrCreateModal(false);
                    setSelectedItem(null);
                  };
                  selectedItem
                    ? await updateItem({
                        dashState,
                        product,
                        toast,
                        setProducts,
                        action,
                      })
                    : await createItem({
                        dashState,
                        product,
                        toast,
                        setProducts,
                        action,
                      });
                  setWaiting(false);
                }
              }}
              m="2"
              display="none"
            >
              <Grid templateColumns={"repeat(2, 1fr)"} gap={6}>
                {/* Row 1 */}
                <FormControl id="name" isRequired>
                  <FormLabel color={"gray.500"}>Product Name</FormLabel>
                  <Input
                    placeholder="Cream"
                    variant="flushed"
                    type="text"
                    value={fields.name}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          name: e.target.value,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl id="categoryId" isRequired>
                  <FormLabel color={"gray.500"}>Category</FormLabel>
                  <Select
                    variant="flushed"
                    placeholder="-"
                    value={fields.categoryId}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          categoryId: e.target.value,
                          category: categoriesMap[e.target.value]?.name || "",
                        };
                      });
                    }}
                  >
                    {categories.map((c) => (
                      <option value={c.id}>{c.name}</option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl id="price" isRequired>
                  <FormLabel color={"gray.500"}>Price</FormLabel>

                  <InputGroup>
                    <InputLeftAddon>
                      {" "}
                      <MdAttachMoney />
                    </InputLeftAddon>
                    <Input
                      type="number"
                      readOnly={
                        !(
                          dashState.admin?.super ||
                          (dashState.admin || dashState.doctor).access
                            ?.productsTaxRate
                        )
                      }
                      placeholder="0"
                      value={fields.price}
                      onChange={(e) => {
                        let value = e.target.value;

                        if (!isNaN(value) && !value.match(/-/g)) {
                          if (value === "") value = 0;
                          setFields((prev) => {
                            return {
                              ...prev,
                              price: value,
                            };
                          });
                        }
                      }}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl id="stock" isRequired>
                  <FormLabel color={"gray.500"}>Quantity</FormLabel>

                  <InputGroup>
                    <InputLeftAddon>
                      {" "}
                      <MdNumbers color={"blue.500"} />
                    </InputLeftAddon>
                    <Input
                      type="number"
                      readOnly={
                        !(
                          dashState.admin?.super ||
                          (dashState.admin || dashState.doctor).access
                            ?.productsTaxRate
                        )
                      }
                      placeholder="0"
                      value={fields.stock}
                      onChange={(e) => {
                        let value = e.target.value;

                        if (!isNaN(value) && !value.match(/-/g)) {
                          if (value === "") value = 0;
                          setFields((prev) => {
                            return {
                              ...prev,
                              stock: value,
                            };
                          });
                        }
                      }}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl id="minInventory">
                  <FormLabel color={"gray.500"}>Min Inventory</FormLabel>
                    <NumberInput
                      defaultValue={fields.minInventory ?? ""}
                      min={0}
                      max={fields.maxInventory - 1}
                      onChange={(e)=>{setFields({...fields, minInventory: isNaN(parseFloat(e)) ? null : parseFloat(e)})}}
                    >
                      <NumberInputField />
                    </NumberInput>
                </FormControl>
                <FormControl id="maxInventory">
                  <FormLabel color={"gray.500"}>Max Inventory</FormLabel>
                  <NumberInput
                      defaultValue={fields.maxInventory ?? ""}
                      min={fields.minInventory + 1}
                      onChange={(e)=>{setFields({...fields, maxInventory: isNaN(parseFloat(e)) ? null : parseFloat(e)})}}
                    >
                      <NumberInputField />
                    </NumberInput>
                </FormControl>
              </Grid>
              <FormControl id="description" mt={"6"}>
                <FormLabel color={"gray.500"}>Description</FormLabel>

                <InputGroup>
                  <Textarea
                    placeholder="Product description..."
                    size="sm"
                    resize={"vertical"}
                    rounded={"md"}
                    value={fields.description}
                    onChange={(e) => {
                      let value = e.target.value;

                      setFields((prev) => {
                        return {
                          ...prev,
                          description: value,
                        };
                      });
                    }}
                  />
                </InputGroup>
              </FormControl>
            </form>
          </Box>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            disabled={waiting}
            variant="outline"
            mr={3}
            px="10"
            cursor={waiting ? "not-allowed" : "pointer"}
            onClick={() => {
              setShowEditOrCreateModal(false);
              setSelectedItem(null);
            }}
          >
            Cancel
          </Button>
          <Button
            cursor={waiting ? "not-allowed" : "pointer"}
            type="submit"
            form="prodform"
            mr={3}
            px="10"
            colorScheme="blue"
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
