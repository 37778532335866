import React, {useState} from "react";
import {LiaPrintSolid} from "react-icons/lia";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  lightFormat,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import DaySheetPdf from "./DaySheetPdf";
export default function DaySheet({schState, date}) {
  const [loading, setLoading] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [pdfData, setPdfData] = useState({});

  const generatePDF = () => {
    setLoading(true);
    let data = {
      location: schState.locations.find(
        (loc) => loc.lid === schState.selectedLocation
      ),
    };
    let apptData = [];
    let selectedClinicians = schState?.selectedClinicians;
    if (schState.admin) {
      let doctorsPayload = schState?.doctorsPayload;

      if (doctorsPayload) {
        apptData = Object.entries(doctorsPayload)
          .filter(
            ([key, value]) =>
              selectedClinicians.has(key) && value.appointments.length > 0
          )
          .map(([key, value]) => {
            let arr = value.appointments.filter((a) =>
              isSameDay(a.ISOdate, date)
            );

            arr.sort((a, b) => a.ISOdate - b.ISOdate);

            return arr;
          })
          .filter((value) => value.length > 0);
        apptData.sort((a, b) => {
          let j = a[0]?.dName?.toLowerCase() || 0;
          let k = b[0]?.dName?.toLowerCase() || 0;
          return j < k ? -1 : j > k ? 1 : 0;
        });
      }
    } else if (schState.doctor) {
      let arr = schState.appointments.filter((a) => isSameDay(a.ISOdate, date));
      arr.sort((a, b) => a.ISOdate - b.ISOdate);
      apptData = [arr];
    }

    data.apptData = apptData;
    setPdfData(data);
    setLoading(false);
    setShowPDF(true);
  };

  return (
    <>
      <Button
        ml="4"
        size={"sm"}
        leftIcon={<LiaPrintSolid className="text-[1rem]" />}
        loadingText="Creating day sheet... "
        isLoading={loading}
        onClick={() => {
          generatePDF();
        }}
        colorScheme="blue"
        variant="ghost"
      >
        Day Sheet
      </Button>
      {showPDF && (
        <Modal
          onClose={() => setShowPDF(false)}
          isCentered
          size={"4xl"}
          isOpen={showPDF}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Box h="80vh" position={"relative"} zIndex={50} p={4}>
                <DaySheetPdf pdfData={pdfData} date={date} />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => setShowPDF(false)}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
