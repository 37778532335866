import axios from "axios";
import {url} from "../../../../crudRequests";
import {nanoid} from "nanoid";

export async function insertPharmacy(state, data) {
  let configService = {
    method: "POST",
    url: `${url}/insertPharmacy`,
    data: {
      data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}

export default async function uploadPharmacy(
  item,
  dashState,
  queryFn,
  setOpenPage,
  toast
) {
  try {
    if (!dashState?.organization?.oid || !dashState?.selectedLocation) return;
    const res = await insertPharmacy(dashState, {
      ...item,
      oid: dashState.organization.oid,
      lid: dashState.selectedLocation,
      phid: nanoid(10),
    });
    if (res.status === 200) {
      toast({
        title: "Success!",
        description: "Pharmacy updated successfully!",
        status: "success",
        isClosable: true,
      });
    }
    queryFn();
    setOpenPage(false);
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
}
