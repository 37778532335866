import {
  Box,
  Text,
  useToast,
  Center,
  Flex,
  Button,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Stack,
  Heading,
  Square,
  Circle,
} from "@chakra-ui/react";

import {
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {MdOutlineCategory} from "react-icons/md";
import {BiCategory} from "react-icons/bi";

import {MdOutlineInventory2} from "react-icons/md";
export default function ProductsCard({products}) {
  return (
    <Flex
      overflow="hidden"
      variant="outline"
      gap={1}
      border={"1px solid"}
      borderColor={"gray.200"}
      rounded={"lg"}
      boxShadow={"sm"}
      p="2"
      color={"gray.700"}
      align={"center"}
      w="10rem"
    >
      <Flex
        bg="blue.300"
        rounded={"full"}
        justify={"center"}
        align={"center"}
        h="2.5rem"
        w="2.5rem"
        m="1"
      >
        <Icon as={MdOutlineInventory2} fontSize="25px" color={"white"} />
      </Flex>

      <Stack
        mx="1"
        flex={1}
        justify={"center"}
        gap={0}
        align={"center"}
        pl="2"
        borderLeft={"1px solid"}
        borderColor={"gray.200"}
      >
        <Heading size="lg">{products.length}</Heading>

        <Text fontSize={"15px"} fontWeight={600}>
          Products
        </Text>
      </Stack>
    </Flex>
  );
}
