import {
  Box,
  Flex,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import {useContext, useState, useRef, useEffect} from "react";
import {DashStateContext, DashDispatchContext} from "../../pages/Payments";

import {fetchPayments} from "./helpers/fetchPayments";
import {IoSearch} from "react-icons/io5";
import CustomFields from "./CustomFields";
import GroupedTransactionsList from "./GroupedTransactionsList";
import SimplePaymentsList from "./SimplePaymentsList";
import GroupingFields from "./GroupingFields";
import ApplyPayment from "./ApplyPayment";
import Filters from "./Filters";
import {PatientContext} from "../../pages/Patients";
export default function PaymentsTable({
  renderedFromBilling,
  stateFromBilling,
  dispatchFromBilling,
}) {
  const p = useContext(PatientContext);
  let patient = p?.[0];

  let contextState = useContext(DashStateContext);

  const dashState = renderedFromBilling ? stateFromBilling : contextState;

  let contextDispatch = useContext(DashDispatchContext);

  const dispatch = renderedFromBilling ? dispatchFromBilling : contextDispatch;

  const toast = useToast();
  const [prevLocation, setPrevLocation] = useState(dashState.selectedLocation);
  const [fields, setFields] = useState({
    "Patient Name": "pName",
    "Clinician Name": "dName",
    Payer: "payerName",
    "Service Date & Time": "date",
    Amount: "amount",
    "Service Name": "serviceType",

    Description: "description",
  });
  const [groupings, setGroupings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [applyPayment, setApplyPayment] = useState({amount: 0, from: ""});
  const [page, setPage] = useState(0);
  const limit = 10;
  let loadingRef = useRef(null);
  let rootRef = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({});

  const isLoadingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingRef.current) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: rootRef.current,
        threshold: 1.0,
      }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    fetchPayments(
      dashState,
      page * limit,
      limit,
      setPayments,
      (v) => {
        isLoadingRef.current = v;
      },
      toast,
      patient
    );
    console.log(page);
  }, [page]);

  if (prevLocation !== dashState.selectedLocation) {
    setPrevLocation(dashState.selectedLocation);
    if (page === 0) {
      fetchPayments(
        dashState,
        0,
        limit,
        setPayments,
        (v) => {
          isLoadingRef.current = v;
        },
        toast,
        patient
      );
    } else setPage(0);
  }

  return (
    <TableContainer
      ref={rootRef}
      maxW="8xl"
      // borderColor="gray.50"
      borderRadius="md"
      boxShadow="md"
      h="full"
      overflow="hidden"
      display="flex"
      flexDirection={"column"}
    >
      <Box pr="1" flex={1} height={"full"} overflow="scroll">
        <Box p="4" gap="8" mx="2">
          <Filters {...{query, setQuery}} />
          <Flex my="2">
            <InputGroup ml="5" p="1" pt="0">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" ml="2" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search payments..."
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              />
            </InputGroup>
          </Flex>
        </Box>

        <SimplePaymentsList
          {...{
            payments,
            fields,
            query,
            searchText,
            dispatch,
            dashState,
          }}
        />
        <div ref={loadingRef} />
      </Box>
    </TableContainer>
  );
}
