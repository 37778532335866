import {useEffect, useState} from "react";

import {crud} from "../../../../crudRequests";

const useFindPhaxioNumber = (state, callback) => {
  const [number, setNumber] = useState(false);
  const [location, setLocation] = useState(false);

  useEffect(() => {
    if (state?.locations?.length > 0 && state?.selectedLocation) {
      const location = state.locations.filter(
        (loc) => loc?.lid === state?.selectedLocation
      )[0];
      setLocation(location);
      crud(state, [
        {
          db: state.db || "blh",
          collection: "locations",
          parameters: [
            {oid: location?.oid, lid: location?.lid},
            {phaxioNumber: 1},
          ],
          method: "findOne",
        },
      ])
        .then((res) => {
          console.log({number: res?.data?.[0]?.phaxioNumber || ""});
          setNumber(res?.data?.[0]?.phaxioNumber || "");
        })
        .finally(callback);
      console.log({number, location, locations: state.locations});
    }
  }, [state]);

  return [number, location];
};

export default useFindPhaxioNumber;
