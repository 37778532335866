import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Select,
  FormLabel,
  HStack,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  StackDivider,
  useToast,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import {BsCalendarDay} from "react-icons/bs";
import {lightFormat} from "date-fns";
import {useEffect, useMemo, useState} from "react";
import DatePicker from "./DatePicker";
import UnavailableList from "./UnavailableList";
import WeekdayPicker from "./WeekdayPicker";
import {BsCalendar2Week, BsCalendar2RangeFill} from "react-icons/bs";
import {
  createIntervals,
  updateClinicianUnavailableTimes,
  groupIntervals,
  removeIntervals,
  untilRemoveWeekIntervals,
  groupUntilRemoved,
} from "./helpers";
export default function UnavailableTimesModal({
  schState,
  stateChanger,
  dispatch,
  isOpen,
}) {
  const [clinician, setClinician] = useState(schState.selectedDoctor || "");
  const [wDays, setWdays] = useState(new Set());
  const [date, setDate] = useState(new Date());
  const [radioValue, setRadioValue] = useState("calendar");
  const [range, setRange] = useState([]);
  const [selectedDays, setSelectedDays] = useState(new Set());
  const [tabIndex, setTabIndex] = useState(0);
  const [start, setStart] = useState("00:00");
  const [end, setEnd] = useState("00:00");
  const [remove, setRemove] = useState(false);
  const [apply, setApply] = useState(false);
  const [selected, setSelected] = useState(new Set());
  const toast = useToast();
  const {
    groups,
    track,
    intervals,
    untilRemoved,
    untilRemovedGroup,
    currentUnavailability,
  } = useMemo(() => {
    let unavailable = clinician?.unavailable || {};
    let intervals = unavailable.intervals || [];
    let untilRemoved = unavailable.untilRemoved || [];
    let currentUnavailability = new Set();
    try {
      currentUnavailability = intervals.reduce((acc, el) => {
        acc.add(lightFormat(el[0], "dd/MM/yyyy"));
        return acc;
      }, new Set());
    } catch (error) {
      console.log(error);
    }

    return {
      ...groupIntervals(intervals),
      intervals,
      untilRemovedGroup: groupUntilRemoved(untilRemoved),
      currentUnavailability,
      untilRemoved,
    };
  }, [clinician]);

  return (
    <Modal
      scrollBehavior={"inside"}
      isCentered
      isOpen={isOpen}
      onClose={() => stateChanger(false)}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="blue.500" fontSize={"2xl"}>
          Set Unavailable Time Slots
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mt="0" px="8">
          {schState?.doctors?.length > 0 ? (
            <FormControl w="10rem" zIndex={10}>
              <FormLabel
                htmlFor="clinicians"
                color={"gray.400"}
                mb="1"
                ml="1"
                fontWeight={"normal"}
                fontSize={"xs"}
              >
                Clinician
              </FormLabel>
              <Select
                w="10rem"
                bg="blue.50"
                id="clinicians"
                fontSize={"sm"}
                variant="filled"
                placeholder="-"
                value={clinician?.did || ""}
                onChange={(e) => {
                  try {
                    if (!e.target.value) setClinician(null);
                    let py = schState.doctorsPayload || {};
                    let index = py[e.target.value]?.index;
                    let cln = schState.doctors[index];
                    setClinician(cln || null);
                  } catch (error) {
                    setClinician(null);
                  }
                }}
              >
                {schState?.doctors.map((d, i) => (
                  <option p="1" key={i} value={d.did}>
                    {d.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          ) : null}

          <Tabs
            mt="-7"
            variant="soft-rounded"
            colorScheme="blue"
            align="end"
            onChange={(index) => setTabIndex(index)}
            pb="1"
            isLazy
          >
            <TabList>
              <Tab
                onClick={() => {
                  setRange([]);
                  setSelected(new Set());
                }}
                gap={2}
                fontSize={"0.8rem"}
                px="2"
                py="1"
              >
                <BsCalendar2Week /> <Text>Individual Dates</Text>{" "}
              </Tab>
              <Tab
                onClick={() => {
                  setSelectedDays(new Set());
                  setSelected(new Set());
                  setWdays(new Set());
                  setRadioValue("calendar");
                }}
                gap={2}
                fontSize={"0.8rem"}
                px="2"
                py="1"
              >
                {" "}
                <BsCalendar2RangeFill /> <Text>Date Range</Text>
              </Tab>
            </TabList>

            <TabPanels
              border="1px solid"
              borderColor={"gray.300"}
              rounded={"md"}
              p="2"
              mt="4"
              mx="0"
              pb="4"
              h="28rem"
              overflowY={"scroll"}
            >
              <TabPanel p="0" minH="full">
                <HStack
                  px="4"
                  gb="white"
                  divider={<StackDivider borderColor="gray.200" />}
                  gap={4}
                  align={"self-start"}
                  minH="28rem"
                >
                  <Box w={"19rem"} h="full">
                    <RadioGroup
                      mt="4"
                      size="sm"
                      onChange={(c) => {
                        setRadioValue(c);
                        if (c === "calendar") {
                          setWdays(new Set());
                        } else setSelectedDays(new Set());
                        setSelected(new Set());
                      }}
                      value={radioValue}
                    >
                      <Stack direction="row">
                        <Radio value="calendar">Calendar</Radio>
                        <Radio value="weekdays">Weekdays</Radio>
                      </Stack>
                    </RadioGroup>
                    {radioValue === "weekdays" ? (
                      <WeekdayPicker
                        {...{wDays, setWdays, start, setStart, end, setEnd}}
                      />
                    ) : (
                      <DatePicker
                        {...{
                          radioValue,
                          currentUnavailability,
                          date,
                          schState,
                          selectedDays,
                          setSelectedDays,
                          setDate,
                          isRange: false,
                          start,
                          setStart,
                          end,
                          setEnd,
                        }}
                      />
                    )}
                  </Box>
                  <Box flex={1} w={"19rem"}>
                    <UnavailableList
                      {...{
                        intervals,
                        track,
                        groups,
                        type: "individual",
                        selected,
                        setSelected,
                        radioValue,
                        untilRemovedGroup,
                      }}
                    />
                    <Button
                      isDisabled={!clinician || selected.size == 0}
                      isLoading={remove}
                      loadingText="Updating unavailability"
                      shadow={"md"}
                      onClick={async () => {
                        setRemove(true);
                        let newUnavailable = await removeIntervals({
                          state: schState,
                          selected,
                          type: "individual",
                          clinician,
                          intervals,
                          radioValue,
                          untilRemoved,
                        });

                        dispatch({
                          type: "ADD_UNAVAILABLE",
                          payload: {
                            did: clinician?.did,
                            unavailable: newUnavailable,
                          },
                        });
                        setClinician((prev) => ({
                          ...prev,
                          unavailable: newUnavailable,
                        }));
                        setSelected(new Set());
                        setRemove(false);
                      }}
                      size={"sm"}
                      p="2"
                      mt="3"
                      color={"gray.00"}
                      h="2rem"
                      w="full"
                      colorScheme="gray"
                      mr={3}
                    >
                      Remove Time Slot(s)
                    </Button>
                  </Box>
                </HStack>
              </TabPanel>
              <TabPanel p="0">
                <HStack
                  px="4"
                  gb="white"
                  divider={<StackDivider borderColor="gray.200" />}
                  gap={4}
                  align={"self-start"}
                >
                  <Box>
                    <DatePicker
                      {...{
                        currentUnavailability,
                        date,
                        schState,
                        range,
                        setRange,
                        setDate,
                        isRange: true,
                        start,
                        setStart,
                        end,
                        setEnd,
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <UnavailableList
                      {...{
                        intervals,
                        track,
                        groups,
                        type: "range",
                        selected,
                        setSelected,
                      }}
                    />
                    <Button
                      isDisabled={!clinician || selected.size == 0}
                      isLoading={remove}
                      loadingText="Updating unavailability"
                      shadow={"md"}
                      onClick={async () => {
                        setRemove(true);
                        let newUnavailable = await removeIntervals({
                          state: schState,
                          selected,
                          type: "range",
                          clinician,
                          intervals,
                          radioValue,
                        });
                        dispatch({
                          type: "ADD_UNAVAILABLE",
                          payload: {
                            did: clinician?.did,
                            unavailable: newUnavailable,
                          },
                        });
                        setClinician((prev) => ({
                          ...prev,
                          unavailable: newUnavailable,
                        }));
                        setSelected(new Set());
                        setRemove(false);
                      }}
                      size={"sm"}
                      p="2"
                      mt="3"
                      color={"gray.00"}
                      h="2rem"
                      w="full"
                      colorScheme="gray"
                      mr={3}
                    >
                      Remove Time Slot(s)
                    </Button>
                  </Box>
                </HStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={apply}
            loadingText="Updating..."
            isDisabled={
              !clinician ||
              (selectedDays.size === 0 && !range?.[1] && wDays.size === 0)
            }
            onClick={async () => {
              let startRange = new Date(0);
              let endRange = new Date(0);
              let dif =
                endRange.setHours(...end.split(":"), 0, 0) -
                startRange.setHours(...start.split(":"), 0, 0);
              if (dif <= 0) {
                toast({
                  title: `Invalid interval (${start} - ${end})`,
                  description: "Please select a valid range!",
                  status: "error",
                  isClosable: true,
                });
                return;
              }
              setApply(true);
              let untilRemoved = tabIndex === 0 && radioValue === "weekdays";
              let ntvs = [];
              if (untilRemoved) {
                ntvs = untilRemoveWeekIntervals({
                  date,
                  timeInterval: [start, end],
                  wDays,
                });
              } else {
                ntvs = createIntervals({
                  daysRange: range,
                  timeInterval: [start, end],
                  selectedDays,
                });
              }

              await updateClinicianUnavailableTimes({
                state: schState,
                clinician,
                setClinician,
                intervals: ntvs,
                dispatch,
                date,
                untilRemoved,
              });
              setApply(false);
            }}
            size={"sm"}
            w="10rem"
            colorScheme="blue"
            mr={3}
          >
            Apply
          </Button>
          <Button
            onClick={() => stateChanger(false)}
            size={"sm"}
            w="10rem"
            colorScheme="gray"
            mr={3}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
