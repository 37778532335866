import {
  Button,
  Input,
  Flex,
  ButtonGroup,
  useToast,
  FormControl,
  FormLabel,
  DrawerBody,
  DrawerFooter,
  FormErrorMessage,
  List,
  ListItem,
  ListIcon,
  Textarea,
} from "@chakra-ui/react";
import {DeleteIcon} from "@chakra-ui/icons";
import {Field, Form, Formik} from "formik";
import {validateField} from "./helpers/validateField";
import TaskFiles from "./TaskFiles";
import {extractFileName} from "../Patients/Files/helpers/extractFileName";
import {useRef, useState} from "react";
import {createTask} from "./helpers/createTask";
import { SearchableSelect } from "../SearchableSelect";

export default function AddTaskDrawer({dashState, patients, setTasks, patientId, doctors, groupedOptions, onClose, patientsArr, admins}) {
  const fields = {
    task: "",
    pid: patientId ?? "",
    assignedToId: dashState.doctor?.did ?? "",
    due: "",
  }
  const [files, setFiles] = useState([])
  const firstFieldRef = useRef(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [formKey, setFormKey] = useState(Date.now())
  const toast = useToast()
  const disablePatientField = files?.length > 0 || patientId

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove));
  }

  return (
    <Formik
      key={formKey}
      initialValues={fields}
      onSubmit={async(values, { setSubmitting, resetForm }) => {
        values.files = files
        values.due = new Date(values.due).getTime()
        let assignedToName = admins.find(admin => admin.id === values.assignedToId)
              assignedToName = assignedToName
              ? `${assignedToName.lastName}, ${assignedToName.firstName}`
              : `${doctors[values.assignedToId]?.lastName}, ${doctors[values.assignedToId]?.firstName}`
        await createTask(
              values,
              dashState,
              setTasks,
              assignedToName,
              `${patients[values.pid]?.lName}, ${patients[values.pid]?.fName}`,
              toast,
        )
        setSubmitting(false)
        resetForm()
        setFormKey(Date.now())
        setFiles([])
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <DrawerBody className="overflow-hidden p-4" p="0">
            <Field name='task' validate={(value) => validateField('Task', value)}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.task && form.touched.task} my="5">
                      <Input {...field} placeholder='Task' variant="flushed" ref={firstFieldRef}/>
                      <FormErrorMessage>{form.errors.task}</FormErrorMessage>
                    </FormControl>
                  )}
            </Field>
            <Field name='description'>
              {({ field, form }) => (
                <FormControl my="2">
                  <Textarea
                    {...field}
                    variant='flushed'
                    placeholder='Description...'
                    size='sm'
                    resize='none'
                  />
                </FormControl>
              )}
            </Field>
            <Field name='pid' validate={(value) => validateField('Patient', value, setIsDisabled)}>
              {({ field, form }) => (
                <SearchableSelect
                  label="Select patient"
                  placeholder="-"
                  options={patientsArr.map((option) => ({
                    value: option.pid,
                    label: `${option.lName}, ${option.fName}`,
                  }))}
                  isDisabled={disablePatientField}
                  error={form.errors.pid}
                  touched={form.touched.pid}
                  onChange={(option) => setFieldValue('pid', option.value)}
                  value={values.pid}
                />
              )}
            </Field>
            <Field name='assignedToId' validate={(value) => validateField('Doctor', value)}>
                {({ field, form }) => (
                <SearchableSelect
                  label="Select Assigned To"
                  placeholder="-"
                  options={groupedOptions}
                  error={form.errors.assignedToId}
                  touched={form.touched.assignedToId}
                  onChange={(option) => setFieldValue('assignedToId', option.value)}
                  value={values.assignedToId}
                />
              )}
            </Field>
            <Field name='due' validate={(value) => validateField('Date', value)}>
                {({ field, form }) => (
                <FormControl isInvalid={form.errors.due && form.touched.due} my="5" isRequired>
                  <FormLabel color={"gray.400"} mb="0" fontWeight={"normal"}>
                    Need by:
                  </FormLabel>
                  <Input
                    {...field}
                    variant="flushed"
                    placeholder="-"
                    type="date"
                    my="2"
                  />
                  <FormErrorMessage>{form.errors.due}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='files'>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.due && form.touched.due} my="5" isRequired>
                    <TaskFiles form={form} patient={patients[values.pid] ?? null} dashState={dashState} setFilesArray={setFiles} filesArray={files} isDisabled={isDisabled}/>
                    <List my="2">
                      {files?.map((file) =>
                        <ListItem fontSize={'sm'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                          <ListIcon as={DeleteIcon} color='red.500' onClick={() => handleRemoveFile(file)} cursor={'pointer'}/>
                          {extractFileName(file.name, 'name')}
                        </ListItem>
                      )}
                    </List>
                </FormControl>
              )}
            </Field>
          </DrawerBody>
          <DrawerFooter p="0">
            <Flex justifyContent="end">
              <ButtonGroup size="sm">
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  onClick={onClose}
                >
                  Go Back
                </Button>
                <Button
                  isLoading={isSubmitting}
                  colorScheme="blue"
                  type='submit'
                >
                  Add Task
                </Button>
              </ButtonGroup>
            </Flex>
          </DrawerFooter>
        </Form>
      )}
    </Formik>
  );
}