import {Text} from "@chakra-ui/react";

export const stringifyValue = (val, groupings) => {
  if (groupings?.length > 0) {
    if (Array.isArray(val)) {
      return (
        "[" +
        val.map((item, index) => stringifyValue(item, groupings)).join(", ") +
        "]"
      );
    } else if (
      new Date(val).toString() !== "Invalid Date" &&
      new Date(val).toLocaleDateString() !== "12/31/1969"
    ) {
      return `${new Date(val).toLocaleString()}`;
    } else if (val !== null && typeof val === "object") {
      const objectStrings = Object.entries(val).map(([key, value]) => {
        return `${key}: ${stringifyValue(value, groupings)}`;
      });
      return "{" + objectStrings.join(", ") + "}";
    } else if (val === null || val === undefined) {
      return "null";
    } else {
      return String(val);
    }
  } else {
    if (Array.isArray(val)) {
      return (
        <ul>
          {val.map((item, index) => (
            <li key={index}>{stringifyValue(item)}</li>
          ))}
        </ul>
      );
    } else if (
      new Date(val).toString() !== "Invalid Date" &&
      new Date(val).toLocaleDateString() !== "12/31/1969"
    ) {
      return <Text as="span">{new Date(val).toLocaleString()}</Text>;
    } else if (val !== null && typeof val === "object") {
      return (
        <div style={{marginTop: "12px", marginBottom: "12px"}}>
          {Object.entries(val).map(([key, value], index) => (
            <div key={index}>
              <Text fontWeight="semibold" display="inline-block">
                {key}:
              </Text>{" "}
              {stringifyValue(value)}
            </div>
          ))}
        </div>
      );
    } else if (val === null || val === undefined) {
      return "null";
    } else {
      return String(val);
    }
  }
};
