import getHTMLElement from "./getHTMLElement";

export default function addPrescription(
  selectedForm,
  setSelectedForm,
  editorRef
) {
  if (selectedForm) {
    let html = `<div style="padding: 0x 30px; display: flex; flex-direction: column; flex-wrap: wrap;"> 
    <h2 style="font-size: 15px; font-weight: 900; color: #1f2937;">
    ${
      Object.values(JSON.parse(selectedForm.formFields))[0][0].children[0]
        .children
    }</h2>
    ${Object.values(JSON.parse(selectedForm.formFields))
      .splice(1)
      .map(
        (val, i) =>
          `<section>
            <div style="display: flex; flex-wrap: wrap;margin-top: 5px;">
              ${Object.values(val)
                .filter(
                  (ele) =>
                    ele.key !== "Select Menu" && ele.key !== "Check Boxes"
                )
                .map((e, i) => getHTMLElement(e))
                .join("\n")}
            </div>
            <div>
              ${Object.values(val)
                .filter(
                  (ele) =>
                    ele.key === "Select Menu" || ele.key === "Check Boxes"
                )
                .map((e, i) => getHTMLElement(e))
                .join("\n")}
            </div>
          </section>`
      )
      .join("\n")}</div></br>`;
    editorRef?.current?.insertContent(html);
    setSelectedForm(null);
  }
}
