import React, {useState, useEffect} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {crudStorage, crudStorageUpload} from "../storageRequests";
import {IoClose} from "react-icons/io5";
import {BiSolidArrowToTop} from "react-icons/bi";
import {RxOpenInNewWindow} from "react-icons/rx";
import {FiSearch, FiUpload, FiUploadCloud} from "react-icons/fi";
import {AiOutlineDelete, AiOutlinePlus} from "react-icons/ai";
import {PiUploadFill} from "react-icons/pi";
import {RiLoader5Fill} from "react-icons/ri";
import {
  BsPlusCircleFill,
  BsFillFolderFill,
  BsPlusCircleDotted,
} from "react-icons/bs";
import {set} from "date-fns";
import {useToast} from "@chakra-ui/react";
function App(props) {
  const state = props.state;
  const p = props.patientData;
  const toast = useToast();
  const [path, setPath] = useState(props.path || p["pid"] + "/");
  const [files, setFiles] = useState([]);
  const [add, setAdd] = useState(false);
  const [addFolder, setAddFolder] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [folderName, setFolderName] = useState("");
  const [downloadLink, setDownloadLink] = useState();
  const [selectedFileUpload, setSelectedFileUpload] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);

  useEffect(() => {
    downloadLink && window.open(downloadLink, "_blank");
  }, [downloadLink]);

  useEffect(viewFiles, [path]);

  function viewFiles() {
    crudStorage(state, "view", "", "brightlighthealth", path)
      .then((res) => {
        setFiles(res.data);
        setSelectedFile("");
      })
      .catch((err) => {
        console.log(err);
        err.response?.status == 403
          ? window.location.reload()
          : console.log(err);
      });
  }

  function downloadFile(fileName) {
    crudStorage(state, "download", path + fileName, "brightlighthealth", "")
      .then((res) => setDownloadLink(res.data))
      .catch((err) => {
        err.response.status == 403
          ? window.location.reload()
          : console.log(err);
      });
  }

  function uploadFile() {
    setUploadLoader(true);
    crudStorageUpload(
      state,
      new File(
        [selectedFileUpload],
        "prefix_" +
          new Date().getTime() +
          "-" +
          selectedFileUpload.size / 1000 +
          "-" +
          selectedFileUpload.name
      ),
      "brightlighthealth",
      path + folderName
    )
      .then((res) => {
        setUploadLoader(false);
        viewFiles();
        setSelectedFileUpload("");
        setAddFile(false);
        setAdd(!add);
      })
      .catch((err) => {
        console.log(err);
        setUploadLoader(false);
        viewFiles();
        setSelectedFileUpload("");
        setAddFile(false);
        setAdd(!add);
      });
  }

  function createFolder() {
    crudStorage(
      state,
      "createDirectory",
      "",
      "brightlighthealth",
      path + folderName
    )
      .then((res) => {
        viewFiles();
        setFolderName("");
        setAddFolder(false);
        setAdd(!add);
      })
      .catch((err) => {
        err.response.status == 403
          ? window.location.reload()
          : console.log(err);
      });
  }

  function deleteItem() {
    if (selectedFile.slice(-1) != "/") {
      Swal.fire({
        title: "Delete File?",
        text: "Are you sure you want to delete this file and all of its contents?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: `Back`,
      }).then((result) => {
        if (result.isConfirmed) {
          crudStorage(
            state,
            "deleteFile",
            path + selectedFile,
            "brightlighthealth",
            ""
          )
            .then(viewFiles)
            .catch((err) => {
              err.response.status == 403
                ? window.location.reload()
                : console.log(err);
            });
        }
      });
    } else {
      Swal.fire({
        title: "Delete Folder?",
        text: "Are you sure you want to delete this folder?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: `Back`,
      }).then((result) => {
        if (result.isConfirmed) {
          crudStorage(
            state,
            "deleteDirectory",
            "",
            "brightlighthealth",
            path + selectedFile.replace("/", "") // folder
          )
            .then(viewFiles)
            .catch((err) => {
              err.response.status == 403
                ? window.location.reload()
                : console.log(err);
              err.response?.data?.message?.code == "DirectoryNotEmpty" &&
                toast({
                  title: "You must empty this folder before deleting it!",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
            });
        }
      });
    }
  }

  return (
    <div
      id="fileStorage"
      className="rounded-md min-h-screen bg-white border border-gray rounded-md h-2 mb-8 pb-6 px-2 flex flex-col relative"
    >
      {addFolder && (
        <div
          className="z-[10] top-0 left-0 absolute h-full justify-center flex w-full outline-none overflow-x-hidden overflow-y-auto"
          style={{backgroundColor: "rgb(0,0,0,0.5)"}}
        >
          <div className="relative text-left bg-white rounded-md text-off w-[24rem] h-[12rem] m-12 p-7 py-12 space-y-6">
            <IoClose
              className="absolute top-5 right-7 text-off/80 cursor-pointer hover:text-off/60"
              size={20}
              onClick={() => {
                setFolderName("");
                setAddFolder(!addFolder);
                setAdd(!add);
              }}
            />

            <div className="text-2xl font-medium inline">
              <BsFillFolderFill className="inline ml-1 mr-3 -mt-1 " size={30} />
              Create folder
            </div>
            <div>
              <input
                className="border border-gray text-off text-md px-3 py-1.5 rounded-md drop-shadow w-[14.5rem]"
                placeholder="Set folder's name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
              <button
                className="bg-off/20 px-4 py-2 ml-3 rounded-md inline"
                onClick={createFolder}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
      {addFile && (
        <div
          className="z-[10] top-0 left-0 absolute h-full justify-center flex w-full outline-none overflow-x-hidden overflow-y-auto"
          style={{backgroundColor: "rgb(0,0,0,0.5)"}}
        >
          <div className="relative text-left bg-white rounded-md text-off w-[24rem] h-[23rem] m-12 p-7 py-12 space-y-6">
            <IoClose
              className="absolute top-5 right-7 text-off/80 cursor-pointer hover:text-off/60"
              size={20}
              onClick={() => {
                setSelectedFileUpload("");
                setAddFile(!addFile);
                setAdd(!add);
              }}
            />

            <div className="text-2xl font-medium inline">
              <PiUploadFill className="inline ml-1 mr-3 -mt-1 " size={30} />
              Upload file
            </div>
            <div>
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <FiUploadCloud
                      className="inline ml-1 mr-3 -mt-1 "
                      size={30}
                    />
                    {!selectedFileUpload ? (
                      <span className="px-2 text-center">
                        <p class="my-2 text-sm text-gray-500 dark:text-gray-400">
                          <span class="font-semibold">Click to upload</span> or
                          drag and drop
                        </p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">
                          All file types accepted
                        </p>
                      </span>
                    ) : (
                      <span className="px-2 text-center">
                        <p class="my-2 text-sm text-gray-500 dark:text-gray-400">
                          <span class="font-semibold">
                            {selectedFileUpload?.name}
                          </span>{" "}
                        </p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">
                          {parseInt(selectedFileUpload?.size / 1000)} KB{" "}
                          <span className="font-light">ready for upload</span>
                        </p>
                      </span>
                    )}
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    onChange={(e) => setSelectedFileUpload(e.target.files[0])}
                    class="hidden"
                  />
                </label>
              </div>
              <button
                className="bg-off/20 w-full text-center py-2 mt-3 rounded-md inline"
                onClick={() => !uploadLoader && uploadFile()}
                disabled={!selectedFileUpload}
              >
                Upload
                {uploadLoader && (
                  <RiLoader5Fill
                    className="inline ml-2 -mt-0.5 animate-spin"
                    size={20}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="text-off/40 text-[28px] font-medium mx-4 mt-5 pb-1">
        <img
          src="/images/Doctor/azure.png"
          className="inline h-10 mr-2 -mt-1"
        />
        File Storage
      </div>
      <div className="text-off/70 text-[16px] font-medium mx-4"></div>
      <div className="mx-4 mt-4 mb-6 flex relative">
        <img
          src="/images/Doctor/black_logo.png"
          className="w-10 mr-4 drop-shadow"
        />
        <div className="relative">
          <input
            className="border border-gray text-off text-md pl-[2.75rem] pr-4 py-1.5 rounded-full drop-shadow w-[22.2rem]"
            placeholder="Search in this folder"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FiSearch className="text-off/80 absolute top-2.5 left-4" size={20} />
        </div>
        {!add ? (
          <BsPlusCircleFill
            className="text-off/80 mt-0.5 ml-4 cursor-pointer hover:text-off/60"
            onClick={() => setAdd(!add)}
            size={35}
          />
        ) : (
          <BiSolidArrowToTop
            className="text-off/80 mt-0.5 ml-4 cursor-pointer hover:text-off/60"
            onClick={() => setAdd(!add)}
            size={35}
          />
        )}
        {add && (
          <div className="absolute right-1 top-[3rem] font-medium text-sm">
            <div
              className="text-off/70 py-1.5 px-3 hover:bg-off/10 cursor-pointer rounded-md border border-off/40"
              onClick={() => setAddFolder(!addFolder)}
            >
              <BsPlusCircleDotted className="inline -mt-0.5 mr-1" /> Create new
              folder
            </div>
            <div
              className="text-off/70 py-1.5 px-3 hover:bg-off/10 cursor-pointer rounded-md border border-off/40 mt-1"
              onClick={() => setAddFile(!addFile)}
            >
              <FiUpload className="inline -mt-0.5 mr-1" /> Upload document
            </div>
          </div>
        )}
      </div>
      <div className="mx-4 mt-1 flex relative">
        <div className="ml-0.5 bg-off/10 text-off text-sm font-medium px-4 py-1.5 rounded-md">
          {path &&
            path.split("/").map((folder) => {
              return (
                <>
                  {" "}
                  /{" "}
                  <span
                    className="text-dark/60 cursor-pointer"
                    onClick={() => {
                      const updatedPath = path.split(folder)[0] + folder + "/";
                      path == updatedPath ? viewFiles() : setPath(updatedPath);
                    }}
                  >
                    {folder.replace(p["pid"], p["fName"] + " " + p["lName"])}
                  </span>
                </>
              );
            })}
        </div>
        {!add && selectedFile && selectedFile?.slice(-1) != "/" && (
          <RxOpenInNewWindow
            onClick={() => downloadFile(selectedFile)}
            className="text-off/80 absolute top-0.5 right-10 cursor-pointer"
            size={27}
          />
        )}
        {!add && selectedFile && (
          <>
            <AiOutlineDelete
              onClick={deleteItem}
              className="text-off/80 absolute top-0.5 right-2 cursor-pointer"
              size={27}
            />
            {/* {props.onClick && (
              <AiOutlinePlus
                onClick={() =>
                  getDownloadUrl(selectedFile.name).then((res) =>
                    props.onClick({
                      name: selectedFile.name,
                      path,
                      downloadUrl: res.data,
                    })
                  )
                }
                className="text-off/80 absolute top-0.5 right-16 cursor-pointer"
                size={27}
              />
            )} */}
          </>
        )}
      </div>
      <div className="mt-8 mx-6">
        <div className="flex tracking-wider text-[12px] text-black/70 border-b pb-2">
          <div className="w-[17rem] pl-1.5">NAME</div>
          <div className="w-[6rem]">SIZE</div>
          <div className="w-[2rem]">MODIFIED</div>
        </div>
      </div>
      <div className="mx-3 text-black/70 pb-2 pl-3 relative overflow-scroll h-full">
        <div className="">
          {files.map((file) => {
            return (
              file.name
                .toLowerCase()
                .includes(searchQuery.trim().toLowerCase()) && (
                <div
                  className={`${
                    selectedFile == file.name && `!bg-gray-200 `
                  } border-b border-gray w-full flex hover:bg-gray-100 cursor-pointer`}
                  onClick={() =>
                    setSelectedFile(selectedFile != file.name ? file.name : "")
                  }
                  onDoubleClickCapture={() =>
                    props.onClick
                      ? props.onClick({
                          name: file.name,
                          path,
                        })
                      : file.name.slice(-1) == "/"
                      ? setPath(!path.includes(file.name) && path + file.name)
                      : downloadFile(file.name)
                  }
                >
                  <div className="my-3 mr-3 justify-center flex items-center text-sm w-[3rem]">
                    <img
                      src={`/images/Doctor/${
                        file.name.toLowerCase().slice(-1) === "/"
                          ? `folder`
                          : file.name.toLowerCase().includes(".pdf")
                          ? `pdf`
                          : [".doc", ".docx"].some((ext) =>
                              file.name.toLowerCase().includes(ext)
                            )
                          ? `word`
                          : [".xls", ".xlsx"].some((ext) =>
                              file.name.toLowerCase().includes(ext)
                            )
                          ? `excel`
                          : [".ppt", ".pptx"].some((ext) =>
                              file.name.toLowerCase().includes(ext)
                            )
                          ? `powerpoint`
                          : [
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".gif",
                              ".heic",
                              ".svg",
                              ".webp",
                            ].some((ext) =>
                              file.name.toLowerCase().includes(ext)
                            )
                          ? `image`
                          : [
                              ".mp3",
                              ".mp4",
                              ".mkv",
                              ".flv",
                              ".f4v",
                              ".swf",
                              ".avi",
                              ".mov",
                              ".wmv",
                              "webm",
                              "avchd",
                            ].some((ext) =>
                              file.name.toLowerCase().includes(ext)
                            )
                          ? `media`
                          : `file`
                      }.png`}
                      className="h-8 rounded-sm"
                    />
                  </div>
                  <div className="my-4 text-sm w-[13.3rem]">
                    {file.name?.split("-")[0]?.slice(0, 6) == "prefix"
                      ? file.name?.split("-")[2].length > 24
                        ? file.name?.split("-")[2].slice(0, 24) + "..."
                        : file.name?.split("-")[2]
                      : file.name?.length > 24
                      ? file.name?.slice(0, 24) + "..."
                      : file.name}
                  </div>
                  <div className="my-4 text-sm w-[6rem]">
                    {file.name?.split("-")[0]?.slice(0, 6) == "prefix" &&
                      parseInt(file.name.split("-")[1]) + " Kib"}
                  </div>
                  <div className="my-4 text-sm w-[2rem]">
                    {file.name?.split("-")[0]?.slice(0, 6) == "prefix" &&
                      new Date(
                        parseInt(file.name.split("-")[0].split("_")[1])
                      ).toLocaleDateString("en-US")}
                  </div>
                </div>
              )
            );
          })}
          {files.length == 0 && (
            <div className="text-center mt-10 tracking-wider text-[12px]">
              NO FOLDERS OR FILES FOUND
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
