import React, {useState, useEffect, useRef} from "react";
import {v4 as uuidv4} from "uuid";
import {crud} from "../crudRequests";
import {MdModeEditOutline, MdDeleteForever, MdClose} from "react-icons/md";
import {AiFillSave} from "react-icons/ai";
import TriggerInfoSearch from "./TriggerInfoSearch";
import swal from "sweetalert";
import Swal from "sweetalert2";
import {useNavigate, useLocation} from "react-router-dom";
import ServiceCriteria from "./ServiceCriteria";
import {Button, Stack, Checkbox} from "@chakra-ui/react";
import {HiOutlinePlus} from "react-icons/hi";
export default function ServicesTable({state, dispatch}) {
  const navigate = useNavigate();

  const [services, setServices] = useState(state.services);
  const [checked, setChecked] = useState({s: true, ns: true});

  const [selectedService, setSelectedService] = useState(false);
  const [showModal, setShowEditOrCreateModal] = useState(false);
  const {state: rowState} = useLocation();
  const searchRef = useRef(null);
  const searchText = useRef("");
  const allServices = useRef(services);

  useEffect(() => {
    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setSelectedService(false);
        setShowEditOrCreateModal(false);
      }
    };
    window.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  useEffect(() => {
    setServices(state.services);
    allServices.current = state.services;
  }, [state.services]);

  function search(s) {
    function update(service) {
      if (s.length === 0) {
        return service;
      } else if (service["serviceId"].toLowerCase().includes(s)) {
        return service;
      } else if (service["serviceName"].toLowerCase().includes(s)) {
        return service;
      } else if (service["description"]?.toLowerCase().includes(s)) {
        return service;
      } else if (service["category"]?.toLowerCase().includes(s)) {
        return service;
      } else {
        return null;
      }
    }

    let filteredServices = allServices.current.filter(update);

    setServices(filteredServices);
  }

  let filteredServices = services.filter((s) =>
    s["non-sessions"] ? checked.ns : checked.s
  );

  return (
    <>
      {showModal && (
        <EditOrCreateService
          {...{
            allServices,
            state,
            setShowEditOrCreateModal,
            dispatch,
            selectedService,
            setSelectedService,
            setServices,
          }}
        />
      )}
      <div className="relative p-8 pb-4 pt-5 w-full h-full flex flex-col patients-table-container">
        <div className="bg-off relative mx-4  bg-opacity-25 text-opacity-100 flex items-center rounded-full">
          <span
            className="text-xl text-off absolute left-3"
            ref={searchRef}
            onClick={() => {
              searchRef.current.className = "hidden";
              searchText.current.focus();
            }}
          >
            <span className="font-semibold">Search services</span>{" "}
            <span className="font-light">
              by any field<span className="font-semibold">...</span>
            </span>
          </span>
          <span className="text-xl text-off absolute right-2">
            <img src="/images/Doctor/search_icon.png" alt="" className="w-6" />
          </span>
          <input
            type="text"
            className="w-full bg-off p-3 px-4 bg-opacity-0 text-off rounded-full focus:outline-none  ring-[#8F8CFF] focus:ring-2 focus:ring-[#8F8CFF]"
            ref={searchText}
            onChange={() => search(searchText.current.value.toLowerCase())}
            onFocus={() => {
              searchRef.current.className = "hidden";
            }}
            onBlur={() => {
              if (!searchText.current.value) {
                setTimeout(() => {
                  if (searchRef.current) {
                    searchRef.current.className =
                      "text-xl text-off absolute left-3";
                  }
                }, 100);
              }
            }}
          />
        </div>
        <TriggerInfoSearch text="Service records are searchable on this page by their name, description, category or id." />
        <div className="flex mt-4  mx-4 justify-between">
          <Stack spacing={5} direction="row">
            <Checkbox
              color="#8F8CFF"
              isChecked={checked.s}
              size="lg"
              fontWeight={600}
              onChange={() => setChecked((prev) => ({...prev, s: !prev.s}))}
            >
              Session services
            </Checkbox>
            <Checkbox
              color="#8F8CFF"
              isChecked={checked.ns}
              size="lg"
              fontWeight={600}
              onChange={() => setChecked((prev) => ({...prev, ns: !prev.ns}))}
            >
              Non-session services
            </Checkbox>
          </Stack>
          <Stack direction={"row"}>
            <Button
              variant={"outline"}
              boxShadow={"inset 0px 0px 0px 2px #8F8CFF"}
              color={"#8F8CFF"}
              onClick={() => {
                setServices(allServices.current);
                searchText.current.value = "";
                setTimeout(() => {
                  searchRef.current.className =
                    "text-xl text-off absolute left-3";
                }, 100);
              }}
            >
              Clear
            </Button>
            <Button
              leftIcon={<HiOutlinePlus />}
              bg="#8F8CFF"
              color={"#FFFFFF"}
              onClick={() => setShowEditOrCreateModal(true)}
              _hover={{bgColor: "#5754FF"}}
            >
              Add Service
            </Button>
          </Stack>
        </div>
        <div
          className="rounded-md m-4 mt-2 py-2 px-5 text-sm text-background font-medium text-center"
          style={{backgroundColor: "#8F8CFF"}}
        >
          <ol className="grid grid-cols-4 gap-3 text-center align-middle sm:text-[13px] lg:text-base">
            <li>Name</li>
            <li>Description</li>
            <li>Category</li>
            <li>Actions</li>
          </ol>
        </div>
        <div className="overflow-hidden flex justify-center">
          <div className="overflow-y-scroll  w-full">
            {filteredServices.length !== 0 ? (
              filteredServices.map((service, idx) => {
                return (
                  <ServiceItem
                    allServices={allServices}
                    setServices={setServices}
                    service={service}
                    key={service.serviceId}
                    state={state}
                    dispatch={dispatch}
                    onSelect={() => {
                      setSelectedService(service);
                      setShowEditOrCreateModal(true);
                    }}
                  />
                );
              })
            ) : (
              <span className="ml-5 text-sm text-off">
                No services to display
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function EditOrCreateService({
  allServices,
  selectedService,
  setSelectedService,
  setShowEditOrCreateModal,
  state,
  dispatch,
  setServices,
}) {
  return (
    <div className="fixed show  inset-0 bg-white/90 z-[99999]  flex justify-center items-center">
      <ServiceCriteria
        schState={state}
        allServices={allServices}
        setServices={setServices}
        setShowEditOrCreateModal={setShowEditOrCreateModal}
        dispatch={dispatch}
        setSelectedService={setSelectedService}
        selectedService={selectedService}
      />
    </div>
  );
}

function ServiceItem({
  service,
  onSelect,
  state,
  setServices,
  allServices,
  dispatch,
}) {
  return (
    <div
      className="rounded-md  m-4 mt-0 py-3 px-5 text-sm text-dark text-opacity-75 font-medium text-center h-fit"
      style={{backgroundColor: "#DFDEFF"}}
    >
      <ul className="grid grid-cols-4 gap-4 place-items-center  text-xs md:text-sm align-middle text-center">
        <li>
          <input
            disabled
            className={`w-fit px-2 border-0 bg-transparent active:outline-none p-1 focus:outline-none active:border-0 focus:border-0 text-center text-sm`}
            type="text"
            value={service.serviceName}
          />
        </li>
        <li>
          <input
            disabled
            className={`w-fit px-2 border-0 bg-transparent active:outline-none p-0 focus:outline-none active:border-0 focus:border-0 text-center text-sm
            `}
            type="text"
            value={service.description}
          />
        </li>
        <li>
          <input
            disabled
            className={`w-fit px-2 border-0 bg-transparent active:outline-none p-0 focus:outline-none active:border-0 focus:border-0 text-center text-sm`}
            type="text"
            value={service.category || "..."}
          />
        </li>

        <li className="flex justify-between items-center w-12">
          <button
            className="text-xl cursor-pointer"
            onClick={async (e) => {
              e.stopPropagation();
              onSelect();
            }}
          >
            <MdModeEditOutline />
          </button>
          <button
            className="font-bold text-xl"
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to remove this service?`,
                html: `This service will be permanently removed.`,
                icon: "warning",
                showCancelButton: true,
              }).then(async (value) => {
                if (value.isConfirmed) {
                  await crud(state, [
                    {
                      db: state.db,
                      collection: "services",
                      parameters: [{serviceId: service.serviceId}],
                      method: "deleteOne",
                    },
                    {
                      db: state.db,
                      collection: "doctors",
                      parameters: [{}, {$pull: {services: service.serviceId}}],
                      method: "updateMany",
                    },
                  ])
                    .then((e) => {
                      dispatch({
                        type: "DELETE_SERVICE",
                        payload: service.serviceId,
                      });
                      setServices((prev) => {
                        let newServices = prev.filter(
                          (s) => s.serviceId !== service.serviceId
                        );
                        allServices.current = allServices.current.filter(
                          (s) => s.serviceId !== service.serviceId
                        );
                        return newServices;
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      //if (error.code !== "ERR_CANCELED") window.location.href = "../login"
                    });
                }
              });
            }}
          >
            <span>
              <MdDeleteForever />
            </span>
          </button>
        </li>
      </ul>
    </div>
  );
}
