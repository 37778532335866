import React, {useState, useRef} from "react";
import {url, addBookNowPatient} from "../crudRequests";
//import bcrypt from "bcryptjs";
import swal from "sweetalert";
import {nanoid} from "nanoid";
import {bookNowCrud} from "../crudRequests";
import {isoToApptDate} from "../additional_files/helpers";
import {useToast} from "@chakra-ui/react";
import {notificationsData} from "../additional_files/notifications";
let initial = {
  fName: "",
  lName: "",
  email: "",
  phone: "",
  emailInvitation: true,
  phoneInvitation: true,
};
export default function InvitePatient({bookState, dispatch}) {
  const state = bookState;
  const inviteFirstNameRef = useRef(null);
  const inviteLastNameRef = useRef(null);
  const inviteEmailRef = useRef(null);
  const invitePhoneRef = useRef(null);
  const [fields, setFields] = useState(initial);

  const [inviteError, setInviteError] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function invitePatient() {
    if (bookState?.organization?.db) {
      setInviteError("");
      setLoading(true);
      const pid = nanoid(10);

      if (fields.email.trim() === "" && fields.phone.trim() === "") {
        setInviteError("Email or phone required!");
        setLoading(false);
        return;
      }

      if (
        fields.emailInvitation &&
        fields.email &&
        !fields.email
          .trim()
          .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      ) {
        setInviteError("Invalid email format!");
        setLoading(false);
        return;
      }

      if (fields.fName.trim() === "" || fields.lName.trim() === "") {
        setInviteError(
          "Please complete the required fields correctly to schedule your appointment!"
        );
        setLoading(false);
        return;
      }

      {
        let patient = {
          pid: pid,
          fName: fields.fName.trim(),
          lName: fields.lName.trim(),
          phone: fields.phone.trim(),
          email: fields.email.trim(),
          scheduleBeforeIntake: true,
          oid: bookState.organization.oid,
          lid: [bookState.location.lid],
          did: [bookState.selectedDoctor.did],
          referralVia: "Online Booking Page",
          emailPermission: fields.emailInvitation,
          textPermission: fields.phoneInvitation,
        };

        const aid = nanoid(10);
        const invoiceId = id(8);
        const receiptId = id(8);
        let {date, time} = isoToApptDate(bookState.selectedInterval[0]);

        let duration = `${Math.floor(
          (bookState.selectedInterval[1] - bookState.selectedInterval[0]) /
            60000
        )} min`;

        let amount = Number.parseFloat(
          (Number.parseFloat(duration.split(" ")[0]) / 60) *
            Number.parseFloat(bookState.selectedService?.defaultCost || 100)
        ).toFixed(2);

        const payload = {
          aid: aid,
          invoiceId,
          receiptId,
          lid: patient.lid[0],
          pid: patient.pid,
          pName: patient.fName + " " + patient.lName,
          pEmail: patient.email,
          pPhone: patient.phone,
          pConfirmed: true,
          did: bookState.selectedDoctor?.did,
          oid: bookState.organization.oid,
          dName: bookState.selectedDoctor.name,
          dEmail: bookState.selectedDoctor?.email,
          supervisorId: bookState.selectedDoctor?.did,
          supervisorName: bookState.selectedDoctor?.name,
          supervisorEmail: bookState.selectedDoctor?.email,
          teleconference: true,
          // patientApptLink: `https://meet.brightlight.ai?room=${aid}&name=${patient.fName}&email=${patient.email}`,
          patientApptLink: "",
          clinicApptLink: "",
          date: date,
          time: time,
          duration,
          note: "Appointment self-scheduled by the patient.",
          summary: "",
          cancelled: false,
          noShow: false,
          service: bookState.selectedService.serviceName,
          serviceId: bookState.selectedService.serviceId,
          ISOdate: new Date(bookState.selectedInterval[0]).getTime(),
          createdBy: `patient|${patient.pid}`,
          paymentStatu: "pending",
          notifyBefore: [24],
          serviceCriteriaKey: "",
          amount,
          tpp: false,
          tppAmount: 0,
          patientAmount: amount,
          amountPaidByPatient: 0,
          amountPaidByTpp: 0,
          tppPaymentStatus: "paid",
          patientPaymentStatus: "pending",
          apptBookingMedia: "online",
          firstTimeBooking: true,
          apptCreatedBy: "patient",
          assignedProfessional: "clinician",
        };

        let requestObjs = [
          {
            db: bookState.organization.db,
            collection: "bookNow",
            parameters: [payload],
            method: "insertOne",
          },
        ];

        /////
        addBookNowPatient(state, patient, {
          emailInvitation: fields.emailInvitation,
          phoneInvitation: fields.phoneInvitation,
        })
          .then(async (res) => {
            await bookNowCrud(bookState, requestObjs, {});
            dispatch({type: "SHOW_PATIENT_FORM", payload: false});
            toast({
              title: "Information Saved Successfully!",
              description:
                "Your data has been saved successfully. Please check your email to complete your appointment registration!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            await new Promise((resolve) => {
              setTimeout(resolve, 3000);
            });
            dispatch({type: "SET_INTERVAL", payload: null});
            dispatch({type: "SELECT_THREAD", payload: "selections"});
          })
          .catch(async (err) => {
            try {
              setLoading(true);
              let patient = err.response.data;
              if (err.response?.status === 401) {
                let tpp = "";
                let patientTppInfo = null;
                if (Array.isArray(patient?.tpp)) {
                  let insr = patient?.tpp.find(
                    (ins) => parseInt(ins.noOfInsuredSessions ?? 0) > 0
                  );
                  insr =
                    insr ||
                    patient?.tpp.find(
                      (ins) => ins.noOfInsuredSessions === undefined
                    );
                  patientTppInfo = insr;
                  tpp = insr ? insr.insurerId : "";
                }

                let tppAmount = tpp ? amount : 0;

                if (
                  patientTppInfo &&
                  !isNaN(patientTppInfo.sessionDeductionAmount) &&
                  parseFloat(patientTppInfo.sessionDeductionAmount) >= 0
                ) {
                  tppAmount = parseFloat(patientTppInfo.sessionDeductionAmount);
                }

                let patientAmount = amount - tppAmount;

                let payload = {
                  aid: aid,
                  invoiceId,
                  receiptId,
                  lid: bookState.location.lid,
                  pid: patient.pid,
                  pName: patient.fName + " " + patient.lName,
                  pEmail: patient.email,
                  pPhone: patient.phone,
                  pConfirmed: true,
                  did: bookState.selectedDoctor?.did,
                  oid: bookState.organization.oid,
                  dName: bookState.selectedDoctor.name,
                  dEmail: bookState.selectedDoctor?.email,
                  supervisorId: bookState.selectedDoctor?.did,
                  supervisorName: bookState.selectedDoctor?.name,
                  supervisorEmail: bookState.selectedDoctor?.email,
                  teleconference: true,
                  patientApptLink: "",
                  clinicApptLink: "",
                  date: date,
                  time: time,
                  duration,
                  service: bookState.selectedService.serviceName,
                  serviceId: bookState.selectedService.serviceId,
                  note: "Appointment self-scheduled by the patient.",
                  summary: "",
                  cancelled: false,
                  noShow: false,
                  ISOdate: new Date(bookState.selectedInterval[0]).getTime(),
                  createdBy: `patient|${patient.pid}`,
                  paymentStatu: "pending",
                  notifyBefore: [24],
                  serviceCriteriaKey: "",
                  claim: false,
                  amount,
                  tpp,
                  tppAmount,
                  patientAmount,
                  amountPaidByPatient: 0,
                  amountPaidByTpp: 0,
                  tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
                  patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
                  apptBookingMedia: "online",
                  firstTimeBooking: false,
                  apptCreatedBy: "patient",
                };

                let requestObjs = [
                  {
                    db: bookState?.organization.db,
                    collection: "bookNow",
                    parameters: [payload],
                    method: "insertOne",
                  },
                ];
                await bookNowCrud(bookState, requestObjs, {});
                dispatch({type: "SHOW_PATIENT_FORM", payload: false});
                toast({
                  title: "The appointment has been successfully booked!",
                  description: `You are being redirected to the patient portal.`,
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                await new Promise((resolve) => {
                  setTimeout(resolve, 3000);
                });
                window.location.href =
                  "https://www.patient.brightlight.ai/login";
              } else {
                setLoading(false);
                setInviteError(err.response.data);
              }
            } catch (err) {
              setLoading(false);
              console.log(err);
              setInviteError("An error has occurred!");
            }
          });
        // setTimeout(stateChanger(false), 2500);
      }
    }
  }

  return (
    <>
      <div
        className="fixed z-[70] top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            style={{backgroundColor: "#F6F5FF"}}
          >
            {inviteError && (
              <div className>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-red-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-red-500 dark:text-red-400">
                        Error
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        {inviteError}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {inviteSuccess && (
              <div className>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-green-500 dark:text-green-400">
                        Booked appointment
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        Your appointment has been successfully booked!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex -mb-7">
              <h3
                className="text-3xl font-medium  text-gray-800 m-4 mb-6"
                id="modal-title"
                style={{color: "#C0BFFF"}}
              >
                Personal Information
              </h3>
              <div className="pl-1.5">
                <button
                  type="button"
                  className="- text-gray-400 bg-transparent rounded-lg text-sm  ml-auto inline-flex items-center"
                  data-modal-toggle="small-modal"
                  style={{boxShadow: "none"}}
                  onClick={() => {
                    dispatch({type: "SHOW_PATIENT_FORM", payload: false});
                  }}
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <form className="mt-6" action="#">
              <label className="block mx-4 mt-3" htmlFor="fName">
                <span className="text-[11px] text-off ml-2">
                  Enter your First Name
                </span>
                <input
                  onChange={(e) => {
                    setInviteError("");
                    setFields((prev) => ({
                      ...prev,
                      fName: e.target.value,
                    }));
                  }}
                  value={fields.fName}
                  type="fName"
                  name="fName"
                  id="fName"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="block mx-4 mt-3" htmlFor="lName">
                <span className="text-[11px] text-off ml-2">
                  Enter your Last Name
                </span>
                <input
                  onChange={(e) => {
                    setInviteError("");
                    setFields((prev) => ({
                      ...prev,
                      lName: e.target.value,
                    }));
                  }}
                  value={fields.lName}
                  type="lName"
                  name="lName"
                  id="lName"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="block mx-4 mt-3" htmlFor="email">
                <span className="text-[11px] text-off ml-2">
                  Enter your Email
                </span>
                <input
                  onChange={(e) => {
                    setInviteError("");
                    setFields((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  value={fields.email}
                  type="email"
                  name="email"
                  id="email"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="block mx-4 mt-3" htmlFor="phone">
                <span className="text-[11px] text-off ml-2">
                  Enter yor Phone
                </span>
                <input
                  onChange={(e) => {
                    if (e.target.value.match(/^\+?\d*$/)) {
                      setInviteError("");
                      setFields((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }));
                    }
                  }}
                  value={fields.phone}
                  type="phone"
                  name="phone"
                  id="phone"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <div className="flex space-x-1 mx-4">
                <label className="block  mt-1">
                  <span className="text-[11px] text-off ml-2">Send email?</span>
                  <AutoSendInvitation
                    {...{field: "emailInvitation", fields, setFields}}
                  />
                </label>
                <label className="block  mt-1  ">
                  <span className="text-[11px] text-off ml-2">Send text?</span>
                  <AutoSendInvitation
                    {...{field: "phoneInvitation", fields, setFields}}
                  />
                </label>
              </div>
              <div className="mt-6 w-[19rem] mb-4 mx-4 rounded-lg text-background bg-med">
                <button
                  disabled={loading}
                  type="button"
                  className="w-full py-2 space-x-1 rounded-lg font-medium flex justify-center items-center"
                  onClick={() => {
                    if (!loading) invitePatient();
                  }}
                >
                  Book an appointment
                  {loading && (
                    <span className="">
                      <svg
                        className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25 stroke-[4px]"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

function AutoSendInvitation({field, fields, setFields}) {
  return (
    <div
      className="bg-[#9F9DFA] ml-2 rounded-full p-2 w-20 cursor-pointer transition duration-500"
      onClick={() => {
        setFields((prev) => ({
          ...prev,
          [field]: !prev[field],
        }));
      }}
      style={{
        backgroundColor: fields[field] ? "#9F9DFA" : "#FFFFFF",
        boxShadow: !fields[field] && "0 0 0 2px inset #9F9DFA",
      }}
    >
      <p
        className="w-3 h-3 rounded-full bg-white transition duration-500"
        style={{
          transform: fields[field] ? "translateX(55px)" : "translateX(0)",
          backgroundColor: fields[field] ? "#FFFFFF" : "#9F9DFA",
        }}
      ></p>
    </div>
  );
}
function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
