import {crud} from "../../../../crudRequests";

export async function fetchIntake(setIntake, dashState, patient, toast) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "intakes",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);
    if (res.data[0][0]) {
      setIntake(res.data[0][0]);
    } else {
      toast({
        title: "No intake data to attach",
        description:
          "This patient has not gone through the Brightlight Intake.",
        status: "warning",
        isClosable: true,
      });
    }
  } catch (err) {
    toast({
      title: "Failed to fetch intake data",
      description:
        err?.message || "An error occurred while fetching intake data.",
      status: "error",
      isClosable: true,
    });
  }
}
