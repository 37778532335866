export const statusColor = {
  1: "blue",
  2: "green",
  3: "purple"
}

export const status = {
  1: "Active",
  2: "Completed",
  3: "To Do"
}

export const columns = [
  {
    label: "Task",
    column: "task",
    sort: true
  },
  {
    label: "Description",
    column: "description",
    sort: false
  },
  {
    label: "Status",
    column: "status",
    sort: true
  },
  {
    label: "Patient",
    column: "patient",
    sort: true
  },
  {
    label: "Assigned To",
    column: "assignedToName",
    sort: true
  },
  {
    label: "Created By",
    column: "createdByName",
    sort: true
  },
  {
    label: "Created Date",
    column: "createdDate",
    sort: true
  },
  {
    label: "Need By",
    column: "due",
    sort: true
  },
  {
    label: "Completed Date",
    column: "completedDate",
    sort: true
  },
  {
    label: "Action",
    column: "action",
    sort: false
  },
]
