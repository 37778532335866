import React from "react";
import {RiFileExcel2Line} from "react-icons/ri";
import {Button, useToast} from "@chakra-ui/react";

import * as XLSX from "xlsx";
import {flattenData} from "./DynamicReportTable";

const MAX_CHAR_LENGTH = 32767;

const filterActiveColumns = (data, activeColumns) => {
  return data.map((row) => {
    const filteredRow = {};
    for (let key in row) {
      if (activeColumns[key]) {
        filteredRow[key] = row[key];
      }
    }
    return filteredRow;
  });
};

const truncateTextFields = (data) => {
  return data.map((row) => {
    const truncatedRow = {};
    for (let key in row) {
      if (typeof row[key] === "string" && row[key].length > MAX_CHAR_LENGTH) {
        truncatedRow[key] = row[key].slice(0, MAX_CHAR_LENGTH);
      } else {
        truncatedRow[key] = row[key];
      }
    }
    return truncatedRow;
  });
};

const exportToExcel = (data, activeColumns, filename = "data.xlsx") => {
  const filteredData = filterActiveColumns(data, activeColumns);
  const truncatedData = truncateTextFields(filteredData);
  const worksheet = XLSX.utils.json_to_sheet(truncatedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const workbookOut = XLSX.write(workbook, {bookType: "xlsx", type: "array"});
  const blob = new Blob([workbookOut], {type: "application/octet-stream"});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const ExportToExcelButton = (props) => {
  const toast = useToast();

  return (
    <Button
      ml="4"
      leftIcon={<RiFileExcel2Line />}
      colorScheme="teal"
      variant="solid"
      isDisabled={!props.data}
      onClick={() => {
        exportToExcel(
          props.data?.groupedData
            ? flattenData(
                props.data.groupedData,
                "",
                0,
                props.data.groupBy.length
              )
            : props.data.filteredData,
          {GroupKey: true, ...props.activeColumns}
        );
        toast({
          title: "Data export succesful.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }}
    >
      Export to excel
    </Button>
  );
};
