import {crud} from "../../../../crudRequests";

export async function fetchConversation(
  setConversation,
  dashState,
  patient,
  toast
) {
  const abortController = new AbortController();
  //let chatsId = (doctor.chatsId||[]).filter((id)=>patient.chatsId?.includes(id))
  let chatId = patient.pid + dashState?.doctor?.did;

  try {
    const res = await crud(
      dashState,
      [
        {
          db: dashState.db,
          collection: "conversations",
          parameters: [{conversationId: chatId}],
          method: "find",
        },
      ],
      null,
      abortController.signal
    );
    setConversation(res.data?.[0]);
  } catch (err) {
    toast({
      title: "Failed to fetch conversation data",
      description:
        err?.message || "An error occurred while fetching conversation data.",
      status: "error",
      isClosable: true,
    });
  }
}
