import {crudStorage} from "../../../../storageRequests";

export default function verifyFolder(state, pid) {
  try {
    crudStorage(state, "view", "", "brightlighthealth", `${pid}/`).catch(
      (err) => {
        // add the folder in case the error exists because the folder doesn't exist
        if (err.response.status == 500) {
          createFolder(state, pid);
        }
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function createFolder(state, pid) {
  crudStorage(state, "createDirectory", "", "brightlighthealth", `${pid}/`)
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      err.response.status == 403 ? window.location.reload() : console.log(err);
    });
}
