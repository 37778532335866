import {crud} from "../../../../crudRequests";

export async function fetchAppointments(
  setAppointments,
  dashState,
  patient,
  toast
) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "appointments",
        // parameters: [{pid: patient.pid, ISOdate: {$gte: Date.now()}}],
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);

    setAppointments(res.data[0]);
  } catch (err) {
    console.log(err);
    /*toast({
      title: "Failed to fetch appointments data",
      description:
        err?.message || "An error occurred while fetching appointments data.",
      status: "error",
      isClosable: true,
    });*/
  }
}
