import {
  Box,
  AvatarGroup,
  Avatar,
  Text,
  Flex,
  VStack,
  Badge,
  Tooltip,
  Tag,
  Icon,
  Button,
  Spinner,
  useDisclosure,
  useToast,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  HStack,
  Circle,
} from "@chakra-ui/react";
import {lightFormat} from "date-fns";
import {EditIcon, DeleteIcon} from "@chakra-ui/icons";
import {useContext, useState, useEffect, useMemo} from "react";
import {deleteNote} from "./helpers/deleteNote";
export default function Note({
  setNotes,
  dispatch,
  state,
  note,
  setPreview,
  setSelectedNote,
  cliniciansMap,
  adminsMap,
  onOpen,
}) {
  let user = useMemo(() => {
    switch (note.userType) {
      case "clinician":
        return cliniciansMap[note.userId];
      case "admin":
        return adminsMap[note.userId];

      default:
        return cliniciansMap[note.userId];
    }
  }, [note]);
  const toast = useToast();

  return (
    <Card maxW="md" bg="gray.50">
      <CardHeader>
        <Flex spacing="4" justify={"space-between"} align={"flex-start"}>
          <Flex gap="2" alignItems="center">
            <Avatar showBorder size="md" name={user?.name} src={user?.photo} />

            <VStack textAlign={"start"} align={"self-start"} gap={0}>
              <Heading size="xs">{user?.name}</Heading>
              <Text fontSize={"xs"}>{user?.title}</Text>
            </VStack>
          </Flex>
          <HStack position={"relative"} gap="1.5" top={"-8px"} right={"-8px"}>
            <Box
              bg="gray.400"
              flexShrink={0}
              rounded={"full"}
              w={"8px"}
              h={"8px"}
            />
            <Box
              bg="gray.400"
              flexShrink={0}
              rounded={"full"}
              w={"8px"}
              h={"8px"}
            />
            <Box
              bg="gray.400"
              flexShrink={0}
              rounded={"full"}
              w={"8px"}
              h={"8px"}
            />
          </HStack>
        </Flex>
      </CardHeader>
      <CardBody pt="0">
        <VStack align={"self-start"}>
          <Heading color={"blue.500"} size="sm" textAlign={"start"}>
            {note.title}
          </Heading>
          <Text w="full" fontSize="sm" textAlign={"start"}>
            {note.description}
          </Text>
        </VStack>
      </CardBody>

      <CardFooter pt="0">
        <VStack w="full" gap="4">
          <Flex
            w="full"
            gap="1"
            fontSize={"xs"}
            color={"gray.500"}
            alignItems="center"
            flexWrap="wrap"
            justify={"end"}
            display={{
              base: "none",
              md: "flex",
            }}
            pr="0.5"
          >
            <Text>{"Created at:"}</Text>
            <Text color={"gray.700"} fontWeight={600}>
              {note?.createdAt
                ? lightFormat(note?.createdAt, "dd/MM/yyyy hh:mm aaa")
                : "-"}
            </Text>
          </Flex>
          <Flex gap="2" w="full">
            <Button
              size={"xs"}
              flex={1}
              variant="outline"
              colorScheme="blue"
              leftIcon={<EditIcon />}
              onClick={() => {
                onOpen();
                setSelectedNote(note);
              }}
            >
              Edit
            </Button>

            <Button
              size={"xs"}
              flex={1}
              variant="outline"
              colorScheme="red"
              leftIcon={<DeleteIcon />}
              onClick={() => deleteNote(note, state, setNotes, toast)}
            >
              Delete
            </Button>
          </Flex>
        </VStack>
      </CardFooter>
    </Card>
  );
}
