import {Center, Icon, Tooltip, Text, Flex} from "@chakra-ui/react";
import {BsFillTelephoneFill, BsPersonWorkspace} from "react-icons/bs";
import {ImUsers} from "react-icons/im";
export default function ApptType({appt, full}) {
  if (!appt) return null;

  let type = appt.teleconference ? 'Virtual' : appt.telephone ? 'Telephone' : 'In Person';

  return (
    <>
      {!full ? (
        <Tooltip hasArrow label={type} rounded={"md"}>
          <Center position={"absolute"} top={1} right={1} color={"white"}>
            {appt.teleconference ? (
              <Icon as={BsPersonWorkspace} boxSize={3} />
            ) : appt.telephone ? (
              <Icon as={BsFillTelephoneFill} boxSize={3} />
            ) : (
              <Icon as={ImUsers} boxSize={3} />
            )}
          </Center>
        </Tooltip>
      ) : (
        <Flex color={"#6271FF"} align={"flex-end"} gap="2">
          <Text fontWeight={400} fontSize={"sm"}>
            {type}
          </Text>
            {appt.teleconference ? (
              <Icon as={BsPersonWorkspace} boxSize={3} />
            ) : appt.telephone ? (
              <Icon as={BsFillTelephoneFill} boxSize={3} />
            ) : (
              <Icon as={ImUsers} boxSize={3} />
            )}
        </Flex>
      )}
    </>
  );
}
