import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import FormsPDF from "./FormsPDF";
import {MdClose} from "react-icons/md";
import {ImFilePdf} from "react-icons/im";
export default function PreviewForm(props) {
  const form = props.form;
  const type = props.type;
  const close = props.close;
  const state = props.state;
  const [showPDF, setShowPDF] = useState(false);

  function renderElement(obj) {
    const {element, key, props = {}, children} = obj;

    if (children && !Array.isArray(children)) {
      return React.createElement(element, {key, ...props}, children);
    }

    const childElements = children
      ? children.map((child, index) =>
          renderElement({...child, key: child.key || index})
        )
      : null;

    return React.createElement(element, {key, ...props}, childElements);
  }

  return (
    <>
      <div
        className="fixed z-[70] inset-0 w-full h-screen outline-none overflow-x-hidden overflow-y-auto"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="relative flex items-end justify-center min-h-screen px-4 pt-4 pb-24 text-center sm:block sm:p-0">
          {showPDF && (
            <div className="absolute inset-0 realtive bg-white/80 flex z-10 items-center justify-center min-h-screen">
              <div className="h-[95%] w-[50rem] relative flex justify-center items-center p-10 bg-white rounded-lg">
                {
                  <MdClose
                    className="text-black cursor-pointer text-lg absolute z-20 right-4 top-4 "
                    onClick={() => {
                      setShowPDF(null);
                    }}
                  />
                }
                <FormsPDF form={form} state={state} />
              </div>
            </div>
          )}

          <div className="bg-[#EFEFEF] relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
            <div className="pt-1">
              <div className="absolute right-12 top-6">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center"
                  data-modal-toggle="small-modal"
                  style={{boxShadow: "none"}}
                  onClick={() => close(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="h-[30rem] overflow-y-scroll" id="hi">
              {Object.keys(form).map((rowIdx) => {
                return (
                  <div
                    className={`grid space-x-4 mb-2 px-4 grid-cols-${
                      Object.keys(form[rowIdx]).length
                    }`}
                  >
                    {Object.keys(form[rowIdx]).map((colIdx) => {
                      return (
                        <div>
                          {form[rowIdx][colIdx] &&
                            renderElement(form[rowIdx][colIdx])}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            {type && type != "nobtn" ? (
              <button
                type="button"
                className="focus:ring-0 w-[20rem] py-2 rounded-lg font-medium bg-off text-white my-5 mt-7 block mx-auto"
              >
                Complete Form <span className="font-thin">(sample button)</span>
              </button>
            ) : (
              <div className="mb-8">&nbsp;</div>
            )}
            <div className="flex justify-center items-center ">
              <div
                className="text-[#493b3b] hover:text-[#dc2626] flex justify-center items-center space-x-1 cursor-pointer"
                onClick={() => setShowPDF(true)}
              >
                <span>
                  <ImFilePdf />
                </span>
                <span>View PDF</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
