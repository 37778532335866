import {
  Grid,
  Text,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Checkbox,
  Flex,
} from "@chakra-ui/react";
import SelectSingleClinician from "./SelectSingleClinician";
export default function RequistionForm(props) {
  const handleStateUpdate = (fieldName, value) => {
    props.setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  return (
    <FormControl>
      <Flex>
        {!props.isDoctor && (
          <>
            <SelectSingleClinician
              label="Clinician"
              schState={props.state}
              dispatch={(evt) => {
                if (evt.type === "SELECT_DOCTOR") {
                  handleStateUpdate("did", evt.payload.did);
                }
              }}
              formData={props.formData}
              handleStateUpdate={handleStateUpdate}
              source="did"
            />
            <SelectSingleClinician
              label="Copy to"
              schState={props.state}
              dispatch={(evt) => {
                if (evt.type === "SELECT_DOCTOR") {
                  handleStateUpdate("copyToDid", evt.payload.did);
                }
              }}
              formData={props.formData}
              handleStateUpdate={handleStateUpdate}
              source="copyToDid"
            />
          </>
        )}
      </Flex>
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(16, 1fr)"
        w="100%"
        my="6"
        border="solid"
        borderColor="cyan.100"
      >
        {props?.requisitionData?.schema
          .filter((inputConfig) => inputConfig.source === "form")
          .map((inputConfig, i) => {
            switch (inputConfig.type) {
              case "boolean":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan}
                    rowSpan={inputConfig.rowSpan}
                    key={inputConfig.pdfMapping}
                  >
                    <Flex alignItems="center" p="4px">
                      <Checkbox
                        checked={props.formData[inputConfig.pdfMapping]}
                        onChange={(evt) =>
                          handleStateUpdate(
                            inputConfig.pdfMapping,
                            evt.target.checked
                          )
                        }
                        spacing="1rem"
                      >
                        {inputConfig.label}
                      </Checkbox>
                    </Flex>
                  </GridItem>
                );
              case "text":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan}
                    rowSpan={inputConfig.rowSpan}
                    key={inputConfig.pdfMapping}
                  >
                    <Flex alignItems="center" p="4px">
                      <FormLabel>{inputConfig.label}</FormLabel>
                      <Input
                        type="string"
                        value={props.formData[inputConfig.pdfMapping]}
                        onChange={(evt) => {
                          if (
                            inputConfig.maxLength &&
                            evt.target.value.length <= inputConfig.maxLength
                          ) {
                            handleStateUpdate(
                              inputConfig.pdfMapping,
                              evt.target.value
                            );
                          }
                        }}
                      />
                    </Flex>
                  </GridItem>
                );
              case "filler":
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan}
                    rowSpan={inputConfig.rowSpan}
                    bg="gray.100"
                    key={i}
                    p="4px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    textAlign="center"
                  >
                    <Text fontWeight="bold">{inputConfig.label}</Text>
                  </GridItem>
                );
                return (
                  <GridItem
                    colSpan={inputConfig.colSpan}
                    rowSpan={inputConfig.rowSpan}
                    bg="gray.100"
                    key={i}
                  >
                    <Text fontWeight="bold">{inputConfig.label}</Text>
                  </GridItem>
                );
            }
            return <Text>{inputConfig.label}</Text>;
          })}
      </Grid>
    </FormControl>
  );
}
