import {startOfWeek, add, differenceInDays, lightFormat} from "date-fns";
import {isoToApptDate} from "./helpers";
import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
  useMemo,
} from "react";
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function canDrop(cardData, boardRef, multDocDayView) {
  if (!cardData) return;
  if (multDocDayView) {
    //if (cardData.x < (boardRef.offsetWidth / 7) * cardData.vUnit) return false;
    // if (cardData.x + cardData.width > (boardRef.offsetWidth / 7) * (cardData.vUnit + 1)) return false;
    if (
      cardData.x + cardData.width >
      (boardRef.offsetWidth / 7) * cardData.doctorsCount
    )
      return false;
  }

  if (cardData.y < 64) {
    return false;
  }
  if (cardData.x < 0) {
    return false;
  }

  if (cardData.y + cardData.height > boardRef.offsetHeight) {
    return false;
  }

  if (cardData.x + cardData.width > boardRef.offsetWidth) {
    return false;
  }

  return true;
}

export function dropUpdatePayload(
  cardData,
  boardRef,
  appt,
  view,
  multDocDayView,
  schState,
  doctorsFilterMap
) {
  let y = cardData.y - 64 >= 0 ? cardData.y - 64 : 0;
  let x = cardData.x >= 0 ? cardData.x : 0;
  let min = Math.floor((1470 / boardRef.offsetHeight) * y);
  let start = startOfWeek(new Date(appt.ISOdate), {weekStartsOn: 1});
  let end =
    view === "week"
      ? add(start, {days: Math.round(x / (boardRef.offsetWidth / 7))})
      : new Date(appt.ISOdate);
  let fmin = Math.round(min / 15) * 15;
  end.setHours(0, 0, 0, 0);
  end.setMinutes(fmin);

  // console.log(end);
  let {date, time} = isoToApptDate(end.toISOString());

  const updateBody = {
    rescheduled:
      (appt.ISOdate || "") +
      "|" +
      (schState.doctor ? schState.doctor.did : schState.admin.id),
    noShow: false,
    cancelled: false,
    pConfirmed: false,
    date: date,
    time: time,
    ISOdate: end.getTime(),
  };

  if (multDocDayView) {
    let xunit = Math.round(x / (boardRef.offsetWidth / 7));
    let did =
      Object.entries(doctorsFilterMap).find(([k, v]) => v === xunit)?.[0] ||
      appt.did;
    let doctor = schState.doctors[schState.doctorsPayload[did].index];
    //console.log(doctor)
    updateBody.did = did;
    updateBody.dName = doctor.name;
    updateBody.dEmail = doctor.email;
  }

  return {...appt, ...updateBody};
}

export function SetTimeLine({
  date,
  view,
  selectedDoctor,
  schState,
  doctorsFilterMap,
  lineData,
  boardRef,
  defaultData,
}) {
  let {style, time} = useMemo(() => {
    let lengthPerMinute = 192 / 1440;
    let time = "";
    let style = {};
    if (lineData) {
      let y = lineData.y - 64 >= 0 ? lineData.y - 64 : 0;
      let x = lineData.x >= 0 ? lineData.x : 0;
      let minFromTop = Math.floor((1470 / boardRef.offsetHeight) * y);
      let sOfWeek = startOfWeek(new Date(date), {weekStartsOn: 1});
      let xunit = Math.floor(x / (boardRef.offsetWidth / 7));
      let end = view === "week" ? add(sOfWeek, {days: xunit}) : new Date(date);
      let fmin = Math.round(minFromTop / 15) * 15;
      end.setHours(0, 0, 0, 0);
      end.setMinutes(fmin);

      let top = `${fmin * lengthPerMinute + 4}rem`;
      let marging = 4;
      let left = selectedDoctor
        ? `calc(${
            differenceInDays(end, sOfWeek) * (view === "day" ? 0 : 14.2857)
          }% + ${marging / 2}px)`
        : `calc(${xunit * 14.2857}% + ${marging / 2}px)`;

      let width = selectedDoctor
        ? `calc(${view === "day" ? 100 : 14.2857}% - ${marging}px)`
        : `calc(${14.2857}% - ${marging}px)`;
      style = {top, left, width};
      time = `${months[end.getMonth()]} ${end.getDate()}  ${lightFormat(
        end,
        "h:mm aa"
      )}`;

      let did =
        Object.entries(doctorsFilterMap).find(([k, v]) => v === xunit)?.[0] ||
        "";
      let doctor =
        selectedDoctor ||
        (did ? schState.doctors[schState.doctorsPayload[did].index] : null);

      defaultData.current = {date: end, doctor};
    }
    return {style, time};
  }, [lineData]);

  //console.log(time)
  return (
    <>
      {lineData && (
        <div
          style={style}
          className="absolute z-10 border-t border-yellow-500 flex justify-center cursor-pointer"
        >
          <p className="absolute px-2 bottom-[2px] rounded-md border border-yellow-500 text-yellow-500 text-xs">
            {time}
          </p>
        </div>
      )}
    </>
  );
}
