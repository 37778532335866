import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
} from "@chakra-ui/react";
import React, {useState, useEffect, useRef} from "react";
import {crud, clinicAidClaim, getClinicAidCodes} from "../../../crudRequests";
import {useToast} from "@chakra-ui/react";
import CodesTable from "./CodesTable";
import SelectionsTable from "./SelectionsTable";
import Loader from "./Loader";
import CodesList from "./CodesList";
export default function AidClaimModal({appt, action, state, dispatch}) {
  const toast = useToast();
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [sendingAidClaim, setSendingAidClaim] = useState(false);
  const [loading, setLoading] = useState(true);

  const [diagnosticCodes, setDiagnosticCodes] = useState([]);
  const [serviceCodes, setServiceCodes] = useState(() => {
    let service = state.services?.find((s) => s.serviceId === appt.serviceId);
    let serviceCodes = (service?.serviceCodes || []).map((c) => ({
      code: c,
      description: service.description || "",
      amount: service.defaultCost,
    }));
    return serviceCodes;
  });

  const [cList, setCList] = useState(
    serviceCodes.map((c) => [c.code, "", c.amount])
  );
  let [selectedIndx, setSelectedIndx] = useState(-1);
  const [codesType, setCodesType] = useState("service");

  let scValue =
    codesType === "service" && selectedIndx !== -1 && cList.length > 0
      ? cList[selectedIndx][0]
      : "";
  let dcValue =
    codesType === "diagnostic" && selectedIndx !== -1 && cList.length > 0
      ? cList[selectedIndx][1]
      : "";

  async function getData() {
    try {
      let dc = await getClinicAidCodes(state, "diagnostic");
      let sc = await getClinicAidCodes(state, "service");

      if (dc.data?.result === "success") {
        setDiagnosticCodes(dc.data?.data);
      }

      if (sc.data?.result === "success") {
        setServiceCodes((prev) => {
          return [...prev, ...sc.data?.data];
        });
      }

      setLoading(false);
    } catch (error) {
      toast({
        title: "ClinicAid Claim error",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    getData();
    return () => action();
  }, []);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        action();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>File ClinicAid Claim</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Loader />
          ) : (
            <Box>
              <CodesList
                {...{
                  cList,
                  setCList,
                  setCodesType,
                  setSelectedIndx,
                  selectedIndx,
                }}
              />
              <Flex height={"200px"} alignItems="center" direction={"column"}>
                <CodesTable
                  codesType={codesType}
                  onSelect={(code, amount) => {
                    if (selectedIndx !== -1) {
                      if (codesType === "service") {
                        setCList((prev) => {
                          let newValue = [...prev];
                          newValue[selectedIndx] = [...newValue[selectedIndx]];
                          newValue[selectedIndx][0] = code;
                          newValue[selectedIndx][2] = amount;

                          return newValue;
                        });
                      } else {
                        setCList((prev) => {
                          let newValue = [...prev];
                          newValue[selectedIndx] = [...newValue[selectedIndx]];
                          newValue[selectedIndx][1] = code;
                          return newValue;
                        });
                      }
                    }
                  }}
                  codes={
                    codesType === "service"
                      ? serviceCodes.filter(
                          (c) =>
                            c.code.match(new RegExp(scValue, "i")) ||
                            c.description?.match(new RegExp(scValue, "i"))
                        )
                      : diagnosticCodes.filter(
                          (c) =>
                            c.code.match(new RegExp(dcValue, "i")) ||
                            c.description?.match(new RegExp(dcValue, "i"))
                        )
                  }
                />
              </Flex>
            </Box>
          )}
        </ModalBody>

        {!loading && (
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              cursor={
                !sendingAidClaim && cList.length > 0 ? "pointer" : "not-allowed"
              }
              onClick={async () => {
                if (!sendingAidClaim && cList.length > 0) {
                  let f = cList.find((c) => {
                    return c[0].trim() === "" || c[1].trim() === "";
                  });
                  if (f) {
                    toast({
                      title: "Incomplete Fields",
                      description: "Please complete all fields.",
                      status: "warning",
                      isClosable: true,
                    });
                    return;
                  }
                  setSendingAidClaim(true);
                  const generatingToast = toast({
                    title: "Generating Clinic Aid Claim",
                    description: "Please wait a few seconds.",
                    status: "loading",
                    variant: "subtle",
                    duration: null,
                    isClosable: true,
                  });
                  try {
                    let res = await clinicAidClaim(
                      state,
                      appt?.aid,
                      cList.map((s) => ({
                        sCode: s[0],
                        dCode: s[1],
                        amount: parseFloat(s[2]),
                      }))
                    );
                    res = res.data;

                    toast.close(generatingToast);
                    if (res.aidClaimRespomse.result === "error") {
                      let s =
                        "Error in generating ClinicAid claim. Please try again.";
                      try {
                        s =
                          Object.entries(res.aidClaimRespomse?.errors)
                            .map(
                              ([key, value], i) =>
                                `${key}: (${Object.entries(value?.field_errors)
                                  .map(([k, v]) => `${k}: ${v.join()}`)
                                  .join(", ")}\n ${
                                  value?.standard_errors.length > 0
                                    ? "- " + value?.standard_errors.join(", ")
                                    : ""
                                } )`
                            )
                            .join("\n") + ".";
                      } catch {}
                      toast({
                        title: "ClinicAid Claim error",
                        description: s,
                        status: "error",
                        isClosable: true,
                      });
                    } else {
                      toast({
                        title:
                          "Your claim is succesfully created in ClinicAid! Please log in to ClinicAid to see the claim status.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      console.log(res.apptUpdateData);
                      dispatch({
                        type: "UPDATE_APPOINTMENT",
                        payload: {...appt, ...res.apptUpdateData},
                      });
                      onClose();
                      action();
                    }

                    setSendingAidClaim(false);
                  } catch (e) {
                    console.log(e.message);
                    setSendingAidClaim(false);
                    toast.close(generatingToast);
                    toast({
                      title: "ClinicAid Claim error",
                      description: e.message,
                      status: "error",
                      isClosable: true,
                    });
                  }
                }
              }}
            >
              Create Invoice
            </Button>
            <Button
              onClick={() => {
                onClose();
                action();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
