import {
  lightFormat,
  nextSunday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  subDays,
  endOfDay,
  addWeeks,
  addMonths,
  setDate,
  lastDayOfMonth,
  startOfMonth,
  isSameMonth,
  getDaysInMonth,
  addYears,
  setMonth,
} from "date-fns";
export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dayOpt = ["first", "second", "third", "fourth", "last"];

export default function yearlyHelper(recurrence, appt) {
  let h = [
    nextSunday,
    nextMonday,
    nextTuesday,
    nextWednesday,
    nextThursday,
    nextFriday,
    nextSaturday,
  ];

  let duration = parseInt(appt.duration.split(" ")[0]) * 60000;

  let frequency = recurrence?.frequency;
  let pattern = recurrence?.pattern;
  let range = recurrence?.range;
  if (frequency && pattern && range) {
    let {every, day, month, the, weekday} = pattern;
    let {rangeType, occurrences, startDate, endDate} = range;

    day = Number.parseInt(day);
    month = Number.parseInt(month);
    startDate = new Date(
      ...startDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );
    endDate = new Date(
      ...endDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );

    let hours = new Date(appt.ISOdate).getHours();
    let minutes = new Date(appt.ISOdate).getMinutes();
    let time = hours * 3600000 + minutes * 60000;

    if (pattern.hasOwnProperty("day")) {
      let arr = [];
      let stDate = startDate.getDate();
      let stMonth = startDate.getMonth();
      let stYear = startDate.getFullYear();

      let nextDate;
      if (stDate <= day && stMonth <= month) {
        let ld = getDaysInMonth(new Date(stYear, month));
        nextDate =
          ld < day ? new Date(stYear, month, ld) : new Date(stYear, month, day);
      } else {
        let nextYear = stYear + Number(every);
        let ld = getDaysInMonth(new Date(nextYear, month));
        nextDate =
          ld < day
            ? new Date(nextYear, month, ld)
            : new Date(nextYear, month, day);
      }

      let n = occurrences;

      switch (rangeType) {
        case "END_BY":
          while (
            nextDate.getTime() + time >= startDate.getTime() &&
            nextDate.getTime() + time + duration < endOfDay(endDate).getTime()
          ) {
            arr.push(nextDate.getTime() + time);
            let nextYear = addYears(nextDate, Number(every)).getFullYear();
            let ld = getDaysInMonth(new Date(nextYear, month));
            nextDate =
              ld < day
                ? new Date(nextYear, month, ld)
                : new Date(nextYear, month, day);
          }

          break;
        case "END_AFTER":
          while (nextDate.getTime() + time >= startDate.getTime() && n > 0) {
            arr.push(nextDate.getTime() + time);
            let nextYear = addYears(nextDate, Number(every)).getFullYear();
            let ld = getDaysInMonth(new Date(nextYear, month));
            nextDate =
              ld < day
                ? new Date(nextYear, month, ld)
                : new Date(nextYear, month, day);
            n--;
          }
          break;

        default:
          break;
      }

      return arr;
    } else if (pattern.hasOwnProperty("weekday")) {
      let arr = [];
      let d = Number(weekday);
      let position = dayOpt.indexOf(the);
      function next(date, month) {
        let stOfMonth = startOfMonth(setMonth(date, month));
        let dayBefore = subDays(stOfMonth, 1);
        let first = h[d](dayBefore);
        let dayOnPos = addWeeks(first, position);
        return isSameMonth(dayOnPos, first)
          ? dayOnPos
          : addWeeks(first, position - 1);
      }

      let dateOnCurrentMonth = next(startDate, month);
      let nextDate =
        dateOnCurrentMonth >= startDate
          ? dateOnCurrentMonth
          : next(addYears(startDate, Number(every)), month);

      let n = occurrences;
      switch (rangeType) {
        case "END_BY":
          while (
            nextDate.getTime() + time >= startDate.getTime() &&
            nextDate.getTime() + time + duration <= endOfDay(endDate).getTime()
          ) {
            arr.push(nextDate.getTime() + time);
            nextDate = next(addYears(nextDate, Number(every)), month);
          }

          break;
        case "END_AFTER":
          while (nextDate.getTime() + time >= startDate.getTime() && n > 0) {
            arr.push(nextDate.getTime() + time);
            nextDate = next(addYears(nextDate, Number(every)), month);
            n--;
          }
          break;

        default:
          break;
      }

      return arr;
    }
    return [];
  }

  return [];
}
