import React, {useState, useEffect, useRef, useReducer} from "react";
import {changePassword} from "../crudRequests";

export default function ChangePassword({schState, dispatch}) {
  const [cp, setCp] = useState("");
  const [np, setNp] = useState("");
  const [cnp, setCnp] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  return (
    <form
      className=" space-y-4"
      onSubmit={(e) => {
        e.preventDefault();

        if (np === cnp && np.length >= 8) {
          setLoading(true);

          changePassword(schState, {
            userType: schState.userType,
            user: schState.doctor || schState.admin,
            userId: schState.doctor?.did || schState.admin?.id,
            currentPassword: cp,
            newPassword: np,
            db: schState.db,
          })
            .then((res) => {
              setLoading(false);
              setStatus("Successful update!");
              setCp("");
              setNp("");
              setCnp("");
              dispatch({type: "UPDATE_USER", payload: {pwd: res.data.pwd}});
            })
            .catch((err) => {
              setStatus(err.response.data);
              console.log(err);
              setLoading(false);
            });
        }
      }}
    >
      <h1 className="text-[rgba(87,84,255,0.75)] font-semibold mb-0 text-2xl">
        Change Password
      </h1>
      <div className="flex space-x-3 items-end">
        <div className="space-y-4">
          <div className=" w-full space-y-1 relative">
            <span className="text-sm text-[#A2A2A2] block">
              Current Password
            </span>
            {status && (
              <span
                className="text-[11px] text-[#3dcb55] block absolute right-2 top-0"
                style={{
                  color: status.includes("update") ? "#3dcb55" : "#f44b4b",
                }}
              >
                {status}
              </span>
            )}
            <input
              type="password"
              autoComplete="off"
              value={cp}
              onChange={(e) => {
                setStatus("");
                setCp(e.target.value);
              }}
              className="bg-[#EFEEEE] px-2 text-[#5754FF] font-semibold rounded-[10px] border-none h-12 focus:ring-[rgba(87,84,255,0.75)] focus:ring-2 w-full"
            />
          </div>

          <div className="flex justify-between items-center space-x-4">
            <div className="space-y-1 relative">
              <span className="text-sm text-[#A2A2A2] block">
                Enter New Password
              </span>
              <input
                value={np}
                onChange={(e) => {
                  setStatus("");
                  setNp(e.target.value);
                }}
                type="password"
                autoComplete="off"
                className="w-[180px] px-2 bg-[#EFEEEE] text-[#5754FF] font-semibold rounded-[10px] border-none h-12 focus:ring-[rgba(87,84,255,0.75)] focus:ring-2"
              />
              {np && np.length < 8 && (
                <span className="inline-block text-[11px] left-1 absolute top-full text-red-400">
                  Password must be min. 8 letters
                </span>
              )}
            </div>

            <div className="space-y-1 relative">
              <span className="text-sm text-[#A2A2A2] block">
                Confirm New Password
              </span>
              <input
                value={cnp}
                onChange={(e) => {
                  setStatus("");
                  setCnp(e.target.value);
                }}
                type="password"
                autoComplete="off"
                className="w-[180px] px-2 bg-[#EFEEEE] text-[#5754FF] font-semibold rounded-[10px] border-none h-12 focus:ring-[rgba(87,84,255,0.75)] focus:ring-2"
              />

              {np && cnp && np !== cnp && (
                <span className="inline-block text-[11px] left-1 absolute top-full text-red-400">
                  Passwords do not match
                </span>
              )}
            </div>
          </div>
        </div>

        <p className="text-sm text-[#A2A2A2] w-28  leading-[17px]">
          If you can’t remember your current password, please log out and click
          on the Forgot password link.
        </p>
      </div>
      <button
        className="bg-[rgba(89,84,232,0.75)] flex justify-center disabled:cursor-not-allowed cursor-pointer items-center text-white p-1 text-[1rem] rounded-[10px] w-[180px] focus:outline-none hover:bg-[#5754FF]"
        disabled={np !== cnp || np.length < 8 || !cp || loading}
        style={{marginTop: "2rem"}}
      >
        Change Password
        {loading && (
          <span className="">
            <svg
              className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25 stroke-[4px]"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        )}
      </button>
    </form>
  );
}
