export const extractFileName = (fileName, property) => {
  if (property === 'name') {
    const secondHyphenIndex = fileName.indexOf('-', fileName.indexOf('-') + 1);
    let name = fileName.slice(secondHyphenIndex + 1).split('_(');
    
    if (name.length > 1) {
        const extension = (fileName.match(/\.[^.]+$/) || [])[0] || "";
        return `${name[0]}${extension}`;
    }
    return name[0]
  }
  if (property === 'size') {
    if (fileName.split("-")[0]?.slice(0, 6) === "prefix") return parseInt(fileName.split("-")[1]) + " Kib"
  }

  if (property === 'modified') {
    if (fileName.split("-")[0]?.slice(0, 6) === "prefix") return new Date(parseInt(fileName.split("-")[0].split("_")[1])).toLocaleDateString("en-US")
  }
}
