import {crud} from "../../../crudRequests";
import {v4 as uuidv4} from "uuid";
export default async function duplicateForm(
  state,
  form,
  toast,
  setDuplicating,
  setForms
) {
  /*const generatingToast = toast({
    title: "Duplicating form",
    description: "Please wait a few seconds.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });*/
  try {
    const fid = uuidv4();
    let {_id, ...payload} = form;
    setDuplicating(true);
    payload = {...payload, fid};
    await crud(state, [
      {
        db: state.db,
        collection: "forms",
        parameters: [payload],
        method: "insertOne",
      },
    ]);
    // toast.close(generatingToast);
    setDuplicating(false);
    setForms((prev) => prev.concat(payload));
  } catch (err) {
    // toast.close(generatingToast);
    setDuplicating(false);
    toast({
      title: "Duplicating form error",
      description: "An error occurred while duplicating the form.",
      status: "error",
      isClosable: true,
    });
  }
}
