import {useEffect, useState} from "react";
import {
  Heading,
  Spinner,
  Box,
  Text,
  Button,
  Grid,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tag,
  HStack,
} from "@chakra-ui/react";
import {SortableColumnHeader} from "./SortableColumnHeader";
import {columns, handleSort} from "./Faxes/helpers";
import FilterByDate from "./Faxes/FilterByDate.js";
import {apiAction, crud, queryFaxFile, queryFaxes} from "../crudRequests";
import PreviewFile from "./PreviewFile";
import {AiOutlineClose} from "react-icons/ai";
import {crudStorageUpload} from "../storageRequests";
import {endOfDay, startOfDay, startOfYesterday, endOfToday} from "date-fns";
import {useToast} from "@chakra-ui/react";
import moment from "moment";
import FaxPatientsTable from "./FaxPatientsTable";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const SendToPatient = ({
  state,
  dispatch,
  open,
  setOpen,
  openPreview,
  dataUrl,
  location,
  faxId,
  requery,
}) => {
  const [selectedPid, setSelectedPid] = useState(null);
  const [attachLoading, setAttachLoading] = useState(false);
  const toast = useToast();
  if (open) {
    return (
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setSelectedPid(null);
        }}
        isCentered
        scrollBehavior={"inside"}
        size={"5xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className="bg-white rounded-[25px] w-[1150px] p-8 px-10 grid ">
              <FaxPatientsTable
                dashState={state}
                dispatch={dispatch}
                selectedPid={selectedPid}
                setPatient={(patient) => setSelectedPid(patient?.pid)}
              />
              {/* </div> */}
              <div className="flex mt-4 gap-2">
                <button
                  onClick={() => openPreview(true)}
                  className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center p-x-10 text-md text-white bg-[#5754FF]"
                >
                  Preview File
                </button>
                <button
                  disabled={!selectedPid || attachLoading}
                  className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center bg-[#272727] p-x-10 text-md text-white"
                  onClick={async () => {
                    const binaryPdf = atob(dataUrl); // Decode base64 PDF string to binary
                    const binaryPdfLength = binaryPdf.length;
                    const byteArray = new Uint8Array(binaryPdfLength);

                    for (let i = 0; i < binaryPdfLength; i++) {
                      byteArray[i] = binaryPdf.charCodeAt(i);
                    }
                    const fileName = `${new Date()
                      .toLocaleString("en-CA", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hourCycle: "h23",
                      })
                      .split(/[.:,]+/)
                      .join(" ")}.pdf`;
                    const file = new File([byteArray], fileName, {
                      type: "application/pdf",
                    });
                    setAttachLoading(true);
                    const res = await crudStorageUpload(
                      state,
                      file,
                      "brightlighthealth",
                      `${selectedPid}/Incoming Faxes/`
                    );
                    if (res.status === 200) {
                      // add the fax to assigned
                      if (location?.lid && location?.oid) {
                        await apiAction(state, {
                          action: "ATTACH-FAX",
                          payload: {
                            faxId,
                            patientId: selectedPid,
                          },
                          oid: location.oid,
                          lid: location.lid,
                        });
                      }
                      toast({
                        title: "File attached to patient!",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      await requery();
                      setAttachLoading(false);
                      setOpen(false);
                    }
                  }}
                >
                  Attach
                </button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
};

const Fax = ({
  fax,
  state,
  setPreviewData,
  setOpen,
  setSendOpen,
  setFaxId,
  faxDocument,
}) => {
  const attached =
    faxDocument?.status === "Attached"
      ? `${faxDocument?.patientData?.fName} ${faxDocument?.patientData?.lName}`
      : "No";
  useEffect(() => {
    queryFaxFile(state, fax.id, "s").then((res) => {
      setThumbnail(`data:image/jpg;base64,${res?.data}`);
    });
  }, [fax]);

  const handleClick = () => {
    queryFaxFile(state, fax.id, "").then((res) => {
      setFaxId(fax.id);
      setPreviewData(res?.data);
      setOpen(true);
    });
  };

  const [thumbnail, setThumbnail] = useState(false);
  return (
    <Tr key={fax.id}>
      <Td>
        <Tag
          variant="solid"
          colorScheme={(() => {
            if (fax?.status === "success") {
              return "green";
            } else if (fax?.status === "failure") {
              return "red";
            } else {
              return "yellow";
            }
          })()}
        >
          {fax?.status}
        </Tag>
      </Td>

      <Td>
        <Tag
          variant="solid"
          colorScheme={attached === "No" ? "red" : "green"}
          className="whitespace-nowrap"
        >
          {attached}
        </Tag>
      </Td>

      <Td>{moment(fax?.created_at || "").format("MMMM Do YYYY, h:mm a")}</Td>
      <Td>{moment(fax?.completed_at || "").format("MMMM Do YYYY, h:mm a")}</Td>
      <Td>{fax?.caller_id || fax?.from_number}</Td>
      <Td>{fax?.to_number || fax?.recipients?.[0]?.phone_number}</Td>
      <Td cursor="pointer">
        {" "}
        {thumbnail && (
          <img
            onClick={handleClick}
            w="50px"
            h="15px"
            className="place-self-center"
            src={thumbnail}
            alt="file"
          />
        )}
      </Td>
      <Td>
        <button
          onClick={() => {
            queryFaxFile(state, fax.id, "").then((res) => {
              setFaxId(fax.id);
              setPreviewData(res?.data);
              setSendOpen(true);
            });
          }}
          className="bg-[#5754FF] py-2 rounded text-white w-[125px] text-[12px] my-2 place-self-center"
        >
          Attach to patient
        </button>
      </Td>
    </Tr>
  );
};

export const AllFaxes = ({state, faxNumber, direction, dispatch}) => {
  const [faxes, setFaxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [faxMap, setFaxMap] = useState();
  const [previewData, setPreviewData] = useState(null);
  const [open, setOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const [sort, setSort] = useState({column: "created_at", direction: "desc"});
  const [filterRange, setFilterRange] = useState({
    created_before: endOfToday().toISOString(),
    created_after: startOfYesterday().toISOString(),
  });

  useEffect(() => {
    setLoading(true);
    queryFaxes(
      state,
      faxNumber,
      direction,
      filterRange.created_before,
      filterRange.created_after
    ).then((faxes) => {
      setFaxes(faxes?.data?.data || []);
      setLoading(false);
    });
  }, [faxNumber, state, direction]);

  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (state.selectedLocation && state.locations.length > 0) {
      const location = state.locations.filter(
        (loc) => loc?.lid === state?.selectedLocation
      )[0];
      setLocation(location);
    }
  }, [state]);

  const requery = () => {
    if (location?.lid && location?.oid) {
      crud(state, [
        {
          db: state.db || "blh",
          collection: "faxes",
          method: "aggregate",
          parameters: [
            {
              $match: {
                faxId: {$in: faxes.map((fD) => fD.id)},
              },
            },
            {
              $lookup: {
                from: "patients",
                localField: "patientId",
                foreignField: "pid",
                as: "patientData",
              },
            },
            {$unwind: {path: "$patientData"}},
          ],
        },
      ])
        .then((res) => {
          const map = new Map();
          res?.data?.[0]?.forEach((faxDoc) => {
            map.set(faxDoc.faxId, faxDoc);
          });
          setFaxMap(map);
        })
        .catch((err) => console.log("error getting deleted faxes list"));
    }
  };

  useEffect(() => {
    if (location?.lid && location?.oid && faxes) {
      crud(state, [
        {
          db: state.db || "blh",
          collection: "faxes",
          method: "aggregate",
          parameters: [
            {
              $match: {
                faxId: {$in: faxes.map((fD) => fD.id)},
              },
            },
            {
              $lookup: {
                from: "patients",
                localField: "patientId",
                foreignField: "pid",
                as: "patientData",
              },
            },
            {$unwind: {path: "$patientData"}},
          ],
        },
      ])
        .then((res) => {
          const map = new Map();
          res?.data?.[0]?.forEach((faxDoc) => {
            map.set(faxDoc.faxId, faxDoc);
          });
          setFaxMap(map);
        })
        .catch((err) => console.log("error getting deleted faxes list", err));
    }
  }, [location, faxes]);

  function reload({created_before, created_after}) {
    setLoading(true);
    queryFaxes(state, faxNumber, direction, created_before, created_after)
      .then((faxes) => {
        setFaxes(faxes?.data?.data || []);
        requery();
      })
      .finally(() => setLoading(false));
  }

  const [faxId, setFaxId] = useState(null);

  return (
    <>
      <SendToPatient
        dataUrl={previewData}
        state={state}
        dispatch={dispatch}
        faxId={faxId}
        setOpen={setSendOpen}
        openPreview={setOpen}
        location={location}
        requery={requery}
        open={sendOpen}
      />
      <PreviewFile
        dataUrl={previewData}
        fileName="file.pdf"
        open={open}
        setOpen={setOpen}
        state={state}
        location={location}
      />
      <div>
        <div className="p-5 pl-0 ml-10">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <HStack w="full" justify={"space-between"}>
                <Button
                  onClick={() => {
                    reload({...filterRange});
                  }}
                >
                  Reload
                </Button>
                <FilterByDate {...{...filterRange, setFilterRange, reload}} />
              </HStack>
              <div className="flex gap-4 mt-4">
                {faxes?.length > 0 && (
                  <Table marginTop="4">
                    {/* <TableCaption>Your Faxes</TableCaption> */}
                    <Thead>
                      <Tr>
                        {columns.map((column, i) =>
                          column.sort ? (
                            <SortableColumnHeader
                              key={i}
                              label={column.label}
                              currentSort={sort}
                              column={column.column}
                              onSort={setSort}
                              onClick={(column, sort) => {
                                //handleSort(column,  sort)
                              }}
                            />
                          ) : (
                            <Th key={i} textAlign={"center"}>
                              {column.label}
                            </Th>
                          )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {handleSort({
                        columnName: sort.column,
                        direction: sort.direction,
                        data: faxes,
                      }).map((fax, index) => (
                        <Fax
                          state={state}
                          faxDocument={faxMap?.get(fax.id)}
                          fax={fax}
                          key={index}
                          setOpen={setOpen}
                          setFaxId={setFaxId}
                          setPreviewData={setPreviewData}
                          setSendOpen={setSendOpen}
                        />
                      ))}
                    </Tbody>
                  </Table>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
