import React, {useState, useEffect} from "react";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Divider,
  Text,
  Grid,
  Flex,
  Box,
  Badge,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import {PatientMembershipBalancesModal} from "./PatientMembershipBalancesModal";
import InvoicOrReceipt from "../../Billing/InvoiceOrReceipt";
import {crud} from "../../../crudRequests";
import PreviewFile from "../../appointments/payment/helpers/PreviewFile";
// import CreateChargeModal from "../../appointments/payment/CreateChargeModal";
import CreateMembershipChargeModal from "./CreateMembershipChargeModal";
import {PatientMembershipPaymentModal} from "./PatientMembershipPayment";

const MembershipPaymentInformation = (props) => {
  const toast = useToast();
  const [preview, setPreview] = useState(null);
  const [selectedTr, setSelectedTr] = useState(null);
  const [fetchingFileData, setFetchingFileData] = useState(false);
  const [chargeModal, setChargeModal] = useState(false);

  return (
    <Grid marginLeft="1rem" padding="1rem" bg="#d2d2ff" borderRadius="md">
      {preview && (
        <PreviewFile
          preview={preview}
          setPreview={setPreview}
          appointment={selectedTr}
          state={props.dashState}
        />
      )}
      {chargeModal && (
        <CreateMembershipChargeModal
          state={props.dashState}
          appointment={props.transaction}
          stateChanger={setChargeModal}
          dispatch={() => {}}
          socket={{}}
          refetch={props.refetch}
          transaction={props.transaction}
        />
      )}
      <GridItem>
        <Flex direction="column" align="center">
          <InvoicOrReceipt
            transaction={props.transaction}
            dashState={props.dashState}
            {...{
              setPreview,
              fetchingFileData,
              setFetchingFileData,
              setSelectedTr,
              toast,
            }}
          />
        </Flex>
      </GridItem>

      <Text
        marginTop="1rem"
        fontSize="xs"
        fontWeight={"thick"}
        color="gray.400"
      >
        patient:
      </Text>
      <GridItem padding="1rem" bg="#bbbbff" borderRadius="md">
        <Box direction="row" marginBottom="1rem" width="full">
          <Flex justify="space-between" align="center">
            <Button
              onClick={() => {
                setChargeModal(true);
              }}
              fontWeight="light"
              bgColor="#827fff"
              textColor="white"
              isDisabled={
                (props.transaction?.patientAmount || 0) -
                  (props.transaction?.amountPaidByPatient || 0) ==
                0
              }
            >
              Bill via CC
            </Button>
            <PatientMembershipPaymentModal
              state={props.dashState}
              transaction={props.transaction}
              dispatch={props.dispatch}
              refetch={props.refetch}
              onClose={props.onClose}
              patient={props.patient}
            />
          </Flex>
        </Box>
        <Box
          borderRadius="2xl"
          padding="1rem"
          bg="#5754ff"
          marginX="3rem"
          textAlign={"center"}
        >
          <GridItem w="100%">
            <Text fontSize="xs" textColor="white">
              outstanding balance:
              <span className="font-bold">
                {"$ "}
                {parseFloat(
                  (props.transaction?.patientAmount || 0) -
                    (props.transaction?.amountPaidByPatient || 0)
                ).toFixed(2)}
              </span>
            </Text>
            <Text fontSize="xs" textColor="white">
              amount paid:{" "}
              <span className="font-bold">
                $
                {parseFloat(
                  props.transaction?.amountPaidByPatient || 0
                ).toFixed(2)}
              </span>
            </Text>
          </GridItem>
        </Box>
      </GridItem>
    </Grid>
  );
};
export const PatientMembershipBillingAside = (props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [transaction, setTransaction] = useState(null);
  async function fetchData() {
    let billing = await crud(props.schState, [
      {
        db: props.schState.db,
        collection: "billing",
        parameters: [{membershipUuid: props.membUuid}],
        method: "findOne",
      },
    ]);
    setTransaction(billing.data[0]);
  }
  useEffect(() => {
    props.membUuid && fetchData();
    if (props.membUuid && !isOpen) {
      onOpen();
    }
  }, [props.membUuid]);

  if (!props.membership || !transaction) return <></>;
  return (
    <>
      <Drawer
        isOpen={isOpen}
        size="sm"
        placement="right"
        onClose={() => {
          props.setSelectedMembership(null);
          return onClose();
        }}
      >
        <DrawerContent bg="#f6f5fe">
          <DrawerCloseButton />
          <DrawerHeader
            marginTop="1rem"
            marginRight="1rem"
            color="#5754FF"
            fontSize="2xl"
            fontWeight="bold"
          >
            {props.membership.name}
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <Text
              marginTop="1rem"
              fontSize="xs"
              fontWeight={"thick"}
              color="gray.400"
            >
              description:
            </Text>
            <Text marginLeft="3">{props.membership.description}</Text>
            <Text
              marginTop="1rem"
              fontSize="xs"
              fontWeight={"thick"}
              color="gray.400"
            >
              current status:
            </Text>
            <Badge
              p="1"
              marginLeft="3"
              paddingX={"2rem"}
              borderRadius={"xl"}
              colorScheme={
                transaction?.paymentStatus === "pending" ? "red" : "green"
              }
              variant="solid"
              textColor={"white"}
            >
              {transaction?.paymentStatus === "pending"
                ? "Payment pending"
                : transaction?.paymentStatus}
            </Badge>
            <Text
              marginTop="1rem"
              fontSize="xs"
              fontWeight={"thick"}
              color="gray.400"
            >
              payment information:
            </Text>
            <MembershipPaymentInformation
              membership={props.membership}
              transaction={transaction}
              dashState={props.schState}
              dispatch={props.dispatch}
              patient={props.patient}
              refetch={() => {
                props.setPatientRefetch(true);
                fetchData();
              }}
              onClose={() => {
                props.setSelectedMembership(null);
                return onClose();
              }}
            />
          </DrawerBody>

          <DrawerFooter>
            <Button
              backgroundColor="#6171ff"
              textColor="white"
              fontWeight="normal"
              onClick={() => {
                props.setSelectedMembership(null);
                return onClose();
              }}
              w="full"
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
