import weeklyHelper from "./weeklyHelper";
import monthyHelper from "./monthlyHelper";
import yearlyHelper from "./yearlyHelper";
import {v4 as uuidv4} from "uuid";
import {isoToApptDate} from "../../../../../additional_files/helpers";
import {crud} from "../../../../../crudRequests";

export default async function createRecurrence({
  recurrence,
  dispatch,
  state,
  apptPayload,
  transaction,
  doctor,
  patient,
  location,
  sender,
}) {
  try {
    let dateArray = [];
    validateRage(recurrence);
    function dataFactory(list) {
      let rec = {...recurrence};
      let recurrenceId = uuidv4();
      if (recurrence.frequency === "weekly") {
        let {every, weekdays} = recurrence.pattern;
        rec.pattern = {every, weekdays: [...weekdays]};
      }

      let appointments = list.map((e) => ({
        ...apptPayload,
        ISOdate: e,
        aid: uuidv4(),
        recurrenceId,
        recurrence: rec,
        ...isoToApptDate(e),
      }));
      let transactions = appointments.map((appt) => ({
        ...transaction,
        serviceDate: appt.ISOdate,
        tid: uuidv4(),
        aid: appt.aid,
        recurrenceId: appt.recurrenceId,
      }));

      return {appointments, transactions};
    }

    switch (recurrence.frequency) {
      case "weekly": {
        dateArray = weeklyHelper(recurrence, apptPayload);

        let {appointments, transactions} = dataFactory(dateArray);
        await dbRequest({
          appointments,
          transactions,
          state,
          patient,
          doctor,
          location,
          sender,
          recurrence,
        });
        dispatch({
          type: "NEW_RECURRENCE",
          did: doctor.did,
          pid: patient.pid,
          lid: state.selectedLocation,
          payload: appointments,
        });
        dispatch({
          type: "UPDATE_PATIENT",
          pid: patient.pid,
          payload: {
            ...patient,
            did: [...new Set([].concat(patient.did).concat(doctor.did))],
          },
        });
        return;
      }

      case "monthly": {
        dateArray = monthyHelper(recurrence, apptPayload);
        let {appointments, transactions} = dataFactory(dateArray);
        await dbRequest({
          appointments,
          transactions,
          state,
          patient,
          doctor,
          location,
          sender,
          recurrence,
        });
        dispatch({
          type: "NEW_RECURRENCE",
          did: doctor.did,
          pid: patient.pid,
          lid: state.selectedLocation,
          payload: appointments,
        });
        dispatch({
          type: "UPDATE_PATIENT",
          pid: patient.pid,
          payload: {
            ...patient,
            did: [...new Set([].concat(patient.did).concat(doctor.did))],
          },
        });
        return;
      }

      case "yearly": {
        dateArray = yearlyHelper(recurrence, apptPayload);
        let {appointments, transactions} = dataFactory(dateArray);
        await dbRequest({
          appointments,
          transactions,
          state,
          patient,
          doctor,
          location,
          sender,
          recurrence,
        });
        dispatch({
          type: "NEW_RECURRENCE",
          did: doctor.did,
          pid: patient.pid,
          lid: state.selectedLocation,
          payload: appointments,
        });
        dispatch({
          type: "UPDATE_PATIENT",
          pid: patient.pid,
          payload: {
            ...patient,
            did: [...new Set([].concat(patient.did).concat(doctor.did))],
          },
        });
        return;
      }

      default:
        break;
    }

    return dateArray;
  } catch (error) {
    throw error;
  }
}

async function dbRequest({
  appointments,
  transactions,
  state,
  patient,
  doctor,
  location,
  sender,
  recurrence,
}) {
  if (appointments.length === 0) return;
  let firstAppt = appointments[0];
  let firstDate = firstAppt?.ISOdate;
  let duration = firstAppt?.duration;
  let defaultTime = firstAppt?.time;
  let patientFirstName = patient.fName;
  let doctorName = doctor.name;
  let reminderNotification = notificationTextFormat(firstAppt.notifyBefore);
  let recurrenceDetails = recurrenceTextFormat(recurrence);

  let emailOption = {
    type: "recurring_appointments",
    content: {
      pEmail: patient.email,
      dEmail: doctor.email,
      recurrenceDetails,
      duration,
      defaultTime,
      firstDate,
      doctorName,
      patientFirstName,
      reminderNotification,
      firstAppt,
    },
    sender,
    org: state.organization,
    location,
  };

  let requestObjs = [
    {
      db: state.db,
      collection: "appointments",
      parameters: [appointments],
      method: "insertMany",
    },
    {
      db: state.db,
      collection: "patients",
      parameters: [{pid: patient.pid}, {$addToSet: {did: doctor.did}}],
      method: "updateOne",
    },

    {
      db: state.db,
      collection: "billing",
      parameters: [transactions],
      method: "insertMany",
    },
  ];

  return crud(state, requestObjs, {
    email: emailOption,
    apptReminderNotification: {
      recurringAppointments: appointments,
      sender,
      org: state.organization,
      location,
    },
  });
}

function validateRage(recurrence) {
  let range = recurrence?.range;
  if (range) {
    let {rangeType, startDate, endDate} = range;
    startDate = new Date(
      ...startDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );
    endDate = new Date(
      ...endDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );

    if (rangeType === "END_BY") {
      if (startDate >= endDate) throw new Error("Invalid recurrence range!");
    }
  }
}

function recurrenceTextFormat(recurrence) {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let pattern = recurrence?.pattern;
  let text = "";

  switch (recurrence.frequency) {
    case "weekly": {
      text = `every ${pattern.every} week${
        pattern.every > 1 ? "s" : ""
      } on ${weekday
        .filter((_, i) => pattern.weekdays?.has(String(i)))
        .join(", ")
        .replace(/, ([^,]*)$/, " and $1")}`;
      break;
    }
    case "monthly": {
      if (pattern.hasOwnProperty("day")) {
        text = `for the ${getOrdinalSuffix(pattern.day)} every ${
          pattern.every
        } month${pattern.every > 1 ? "s" : ""}`;
      } else if (pattern.hasOwnProperty("weekday")) {
        text = `for the ${pattern.the} ${pattern.weekday} every ${
          pattern.every
        } month${pattern.every > 1 ? "s" : ""}`;
      }
      break;
    }
    case "yearly": {
      if (pattern.hasOwnProperty("day")) {
        text = `on ${months[pattern.month]} ${getOrdinalSuffix(
          pattern.day
        )} every ${pattern.every} year${pattern.every > 1 ? "s" : ""}`;
      } else if (pattern.hasOwnProperty("weekday")) {
        text = `for the ${pattern.the} ${weekday[pattern.weekday]} of ${
          months[pattern.month]
        } every ${pattern.every} year${pattern.every > 1 ? "s" : ""}`;
      }
      break;
    }
    default: {
      text = "";
      break;
    }
  }

  return text;
}

function notificationTextFormat(notifyBefore) {
  let ntf = [...(notifyBefore || [])];
  ntf.sort((a, b) => a - b);
  if (ntf.length === 0) return "24 hours";

  if (ntf.find((e) => e === 168)) {
    if (ntf.length > 1)
      return (
        ntf.filter((e) => e !== 168).join(", ") + " hours" + " and one week"
      );
    else return "one week";
  } else return ntf.join(", ").replace(/, ([^,]*)$/, " and $1") + " hours";
}

function getOrdinalSuffix(day) {
  if (day > 31 || day < 1) {
    throw new Error("Invalid day. Please enter a number between 1 and 31.");
  }

  let suffix = "th";
  if (day % 10 === 1 && day % 100 !== 11) {
    suffix = "st";
  } else if (day % 10 === 2 && day % 100 !== 12) {
    suffix = "nd";
  } else if (day % 10 === 3 && day % 100 !== 13) {
    suffix = "rd";
  }

  return day + suffix;
}
