export const columns = [
  {
    label: "Status",
    column: "status",
    sort: true,
  },
  {
    label: "Assigned",
    column: "assigned",
    sort: false,
  },
  {
    label: "Created At",
    column: "created_at",
    sort: true,
  },
  {
    label: "Completed At",
    column: "completed_at",
    sort: true,
  },
  {
    label: "From Number",
    column: "from_number",
    sort: true,
  },
  {
    label: "Recipients",
    column: "recipients",
    sort: true,
  },
  {
    label: "Preview",
    column: "preview",
    sort: false,
  },
  {
    label: "Attach",
    column: "attach",
    sort: false,
  },
];

export function handleSort({columnName, direction, data}) {
  let dataCopy = [...data];
  switch (columnName) {
    case "status":
      return dataCopy.sort((a, b) => {
        try {
          if (direction === "asc") {
            return (a.status || "").localeCompare(b.status || "");
          } else {
            return (b.status || "").localeCompare(a.status || "");
          }
        } catch (e) {
          return data;
        }
      });
    case "from_number":
      return dataCopy.sort((a, b) => {
        try {
          if (direction === "asc") {
            return String(a?.caller_id || a?.from_number || "").localeCompare(
              String(b?.caller_id || b?.from_number || "")
            );
          } else {
            return String(b?.caller_id || b?.from_number || "").localeCompare(
              String(a?.caller_id || a?.from_number || "")
            );
          }
        } catch (e) {
          return data;
        }
      });
    case "recipients":
      return dataCopy.sort((a, b) => {
        try {
          if (direction === "asc") {
            return String(
              a?.to_number || a?.recipients?.[0]?.phone_number || ""
            ).localeCompare(
              String(b?.to_number || b?.recipients?.[0]?.phone_number || "")
            );
          } else {
            return String(
              b?.to_number || b?.recipients?.[0]?.phone_number || ""
            ).localeCompare(
              String(a?.to_number || a?.recipients?.[0]?.phone_number || "")
            );
          }
        } catch (e) {
          return data;
        }
      });
    case "created_at":
      return dataCopy.sort((a, b) => {
        try {
          if (direction === "asc") {
            return new Date(a.created_at) - new Date(b.created_at);
          } else {
            return new Date(b.created_at) - new Date(a.created_at);
          }
        } catch (e) {
          return data;
        }
      });

    case "completed_at":
      return dataCopy.sort((a, b) => {
        try {
          if (direction === "asc") {
            return new Date(a.completed_at) - new Date(b.completed_at);
          } else {
            return new Date(b.completed_at) - new Date(a.completed_at);
          }
        } catch (e) {
          return data;
        }
      });

    default:
      return data;
  }
}
