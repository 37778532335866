import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  Select,
  useToast,
  Text,
  InputGroup,
  HStack,
} from "@chakra-ui/react";
import {
  lightFormat,
  startOfYesterday,
  endOfToday,
  endOfDay,
  startOfDay,
} from "date-fns";
import {useRef} from "react";
export default function FilterByDate({
  created_before,
  created_after,
  setFilterRange,
  reload,
}) {
  const reqRef = useRef();

  let valid = validateDate({created_before, created_after});
  return (
    <HStack gap="8">
      <FormControl
        isInvalid={!valid.after}
        id="created_after"
        display={"flex"}
        alignItems={"center"}
      >
        <FormLabel mb={"0"}>From:</FormLabel>
        <Input
          type="date"
          value={lightFormat(new Date(created_after), "yyyy-MM-dd")}
          onChange={(e) => {
            clearTimeout(reqRef.current);
            let value =
              e.target.value || lightFormat(startOfYesterday(), "yyyy-MM-dd");
            let dateArray = value.split("-");
            --dateArray[1];
            let ca = startOfDay(new Date(...dateArray)).toISOString();
            setFilterRange((prev) => ({
              ...prev,
              created_after: ca,
            }));

            let v = validateDate({created_before, created_after: ca});
            if (v.after && v.before)
              reqRef.current = setTimeout(
                () => reload({created_before, created_after: ca}),
                1000
              );
          }}
        />
      </FormControl>
      <FormControl
        isInvalid={!valid.before}
        id="created_before"
        display={"flex"}
        alignItems={"center"}
      >
        <FormLabel mb={"0"}>To:</FormLabel>
        <Input
          type="date"
          value={lightFormat(new Date(created_before), "yyyy-MM-dd")}
          onChange={(e) => {
            clearTimeout(reqRef.current);
            let value =
              e.target.value || lightFormat(endOfToday(), "yyyy-MM-dd");

            let dateArray = value.split("-");
            --dateArray[1];
            let cb = endOfDay(new Date(...dateArray)).toISOString();
            setFilterRange((prev) => ({
              ...prev,
              created_before: cb,
            }));
            let v = validateDate({created_before: cb, created_after});
            if (v.after && v.before)
              reqRef.current = setTimeout(
                () => reload({created_before: cb, created_after}),
                1000
              );
          }}
        />
      </FormControl>
    </HStack>
  );
}

function validateDate({created_before, created_after}) {
  let from = new Date(created_after);
  let to = new Date(created_before);

  return {
    after: from !== "Invalid Date" && from <= to,
    before: to !== "Invalid Date" && to >= from,
  };
}
