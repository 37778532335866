import React, {useContext, useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {LiaPrescriptionSolid} from "react-icons/lia";
import {
  Box,
  Center,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {nanoid} from "nanoid";

import {crud} from "../../../crudRequests";
import {getFileUrl} from "../../../storageRequests";
import uploadFile from "./helpers/uploadFile";
import {PatientContext} from "../../../pages/Patients";

const PrescriptionGenerator = ({
  drName,
  orgAddr,
  orgPhone,
  orgFax,
  ptName,
  ptAddr,
  ptDOB,
  ptHCN,
  form,
  signature,
  dosage,
  registrationId,
  drId,
  did,
  pid,
  orgClinic,
  orgLocation,
  medications,
  state,
  ptGender,
  onSave,
  ptPhone,
  setCurrPage,
  setFaxUrl,
  disableSave,
  therapies,
  prescriptionType,
}) => {
  const prescriptionRef = useRef(null);
  const toast = useToast();
  const [patient, setPatient] = useContext(PatientContext);

  function createPrescription() {
    return uploadFile(
      state,
      pid,
      prescriptionRef,
      therapies,
      medications,
      prescriptionType
    ).then((fileName) =>
      getFileUrl(
        state,
        "brightlighthealth",
        fileName,
        `${pid}/prescriptions/`
      ).then((res) => {
        console.log(res?.data?.downloadUrl);
        setFaxUrl(res?.data?.downloadUrl);
        // console.log({therapies});
        const dataArr = [
          prescriptionType === "therapy"
            ? therapies.map((therapy) => ({
                ...therapy,
                drName,
                orgAddr,
                orgPhone,
                orgFax,
                ptName,
                presId: nanoid(10),
                ptAddr,
                ptDOB,
                ptPhone,
                ptHCN,
                signature,
                ptGender,
                orgClinic,
                orgLocation,
                registrationId,
                did: drId,
                pid,
                prescriptionType,
                orgName: "brightlighthealth",
                fileName,
                directory: `${pid}/prescriptions/`,
              }))
            : medications.map((med) => ({
                ...med,
                drName,
                orgAddr,
                orgPhone,
                orgFax,
                ptName,
                presId: nanoid(10),
                ptAddr,
                ptDOB,
                ptPhone,
                ptHCN,
                signature,
                ptGender,
                orgClinic,
                orgLocation,
                registrationId,
                did: drId,
                pid,
                prescriptionType,
                orgName: "brightlighthealth",
                fileName,
                directory: `${pid}/prescriptions/`,
              })),
        ];
        crud(state, [
          {
            db: state.db || "blh",
            collection: "prescriptions",
            parameters: dataArr,
            method: "insertMany",
          },
        ]).then((res) => {
          onSave();
          if (patient.prescriptions?.length > 0) {
            setPatient((prev) => ({
              ...prev,
              prescriptions: [...prev?.prescriptions, ...dataArr],
            }));
          } else {
            setPatient((prev) => ({
              ...prev,
              prescriptions: [...dataArr],
            }));
          }
        });
      })
    );
  }

  const handlePrint = useReactToPrint({content: () => prescriptionRef.current});

  const [loading, setLoading] = useState(false);

  const [therapyUrls, setTherapyUrls] = useState({});
  const [firstName, ...lastName] = ptName.split(" ");
  useEffect(() => {
    if (prescriptionType === "therapy" && therapies) {
      for (const therapy of therapies) {
        for (const file of therapy?.files) {
          if (!therapyUrls[file.name]) {
            getFileUrl(state, "brightlighthealth", file.name, file.path).then(
              async (res) => {
                fetch(res?.data?.downloadUrl, {
                  method: "GET",
                  headers: {},
                  credentials: "include",
                })
                  .then((res) => console.log({res}))
                  .catch((err) => console.log({err}));
              }
            );
          }
        }
      }
    }
  }, [prescriptionType]);
  const prescriptionDoctor =
    state.doctors?.find((d) => d.did === did) || state.doctor;

  return (
    <>
      {/* prescription component */}
      <Modal isCentered isOpen={loading} onClose={() => setLoading(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Loading...</ModalHeader>
          <ModalBody>
            <Center>
              <Spinner />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ModalBody>
        {/* {loading && (
          // <div className="absolute w-[100%] h-[100%] bg-[rgba(0,0,0,0.9)] left-0 top-0 grid place-items-center gap-8">
          //   <p className="text-[25px] self-end text-white">Loading...</p>
          //   <div className="self-start">
          //     <Spinner />
          //   </div>
          // </div>
          
        )} */}
        <Box mt={5} overflowY="scroll" maxH="800px">
          <Box ref={prescriptionRef}>
            <Grid w="850px" h="1100px" templateColumns="2" p="8" bg="white">
              <div className="grid grid-cols-2 col-span-2">
                <LiaPrescriptionSolid
                  className="justify-self-start self-center w-[150px]"
                  size={120}
                />
                {/* doctors information section */}
                <div className="self-center text-lg justify-self-end text-end ">
                  <p className="font-bold">{orgClinic}</p>
                  <p>{orgLocation}</p>
                  <p className="">{orgAddr}</p>
                  <div className="flex gap-2  ">
                    <p>
                      <b>Phone:</b> {orgPhone}
                    </p>
                    <p>
                      <b>Fax:</b> {orgFax}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full h-1 bg-black col-span-2 " />
              {/* patients information section */}
              <div className="col-span-2 ">
                <div className="text-lg text-black ">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>
                      Patient Name: <b>{`${lastName}, ${firstName}`}</b>
                    </p>
                    <p className="text-black">
                      Date:{" "}
                      <b>
                        {new Date().toJSON().slice(0, 10).replace(/-/g, "/")}
                      </b>
                    </p>
                  </div>
                  <p>
                    Patient Gender, DOB:{" "}
                    <b>
                      {ptGender}, {ptDOB}{" "}
                    </b>
                  </p>
                  <p>
                    Patient HCN: <b>{ptHCN}</b>
                  </p>
                  <p>
                    Patient Address: <b>{ptAddr}</b>
                  </p>
                  <p>
                    Patient Phone: <b>{ptPhone}</b>
                  </p>
                </div>
              </div>
              {prescriptionType === "therapy" &&
                therapies?.map(
                  ({therapyName, length_days, additionalNotes}) => {
                    return (
                      <div>
                        <div className="col-span-2 text-[22px]">
                          <p>
                            Therapy: <b>{therapyName}</b>{" "}
                            {length_days &&
                              `for ${length_days} day${
                                length_days !== 1 && "s"
                              } `}
                          </p>
                          <p className="text-[15px] mt-5">
                            <b>Additional Info:</b>{" "}
                            {additionalNotes
                              ? additionalNotes
                              : "no info given"}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )}
              {prescriptionType === "medication" &&
                medications?.map(
                  ({
                    drugName,
                    signetur,
                    qty,
                    refills,
                    additionalNotes,
                    route,
                    dosage,
                    drug_use,
                    frequency,
                    length_days,
                    no_start_date,
                    start_date,
                    prn,
                    form,
                    unit,
                  }) => (
                    <div>
                      {/* drug information section */}
                      <div className="col-span-2 text-[22px]">
                        <p>
                          Rx:{" "}
                          <b>
                            {drugName} {dosage + "," || ""} {form + "," || ""}{" "}
                            {route + "," || ""} {drug_use || ""}
                          </b>
                        </p>
                        <p>
                          Qty/Frequency:{" "}
                          <b>
                            {qty} {unit} ({refills} refills) /{" "}
                            {frequency?.toUpperCase()}{" "}
                            {length_days && `for ${length_days} days `}
                            {prn && "(PRN)"}
                          </b>
                        </p>
                        {/* <p>
                          Sig: <b>{signetur}</b>
                        </p> */}
                        <p className="text-[15px] mt-5">
                          <b>Signetur (Additional Information):</b>{" "}
                          {additionalNotes ? additionalNotes : "no info given"}
                        </p>
                      </div>
                    </div>
                  )
                )}

              <div className="col-span-2 text-lg self-center">
                <p className=" text-black">
                  Clinician Name:{" "}
                  <b>{`${prescriptionDoctor?.lastName}, ${prescriptionDoctor?.firstName}`}</b>{" "}
                </p>
                <p className="text-black">
                  Clinician Registration #:{" "}
                  <b>
                    {registrationId}
                    {"(CPSO)"}
                  </b>{" "}
                </p>
                <p className=" text-black">Clinician Signature:</p>
                <div className="flex justify-start gap-4 ">
                  <img
                    width="150px"
                    className="pb-2 border-b-black border-b-[1px] mt-2"
                    src={signature}
                    alt="signature"
                  />
                </div>
              </div>
            </Grid>
          </Box>
        </Box>
      </ModalBody>
      <Center>
        <ModalFooter
          display="flex"
          justifyContent="center"
          gap="2"
          flexWrap="wrap"
          m="8"
          justifySelf="center"
          w="650px"
        >
          {/* action buttons */}
          {!disableSave && (
            <button
              disabled={loading}
              className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center bg-[#757575] p-x-10 text-md text-white"
              onClick={() => {
                setLoading(true);
                toast.promise(
                  createPrescription()
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false)),
                  {
                    success: {
                      title: "Success",
                      description: "Prescription saved",
                    },
                    error: {
                      title: "Error!",
                      description: "Something went wrong",
                    },
                    loading: {title: "Loading...", description: "Please wait"},
                  }
                );
              }}
            >
              save
            </button>
          )}
          <button
            className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center bg-[#757575] p-x-10 text-md text-white"
            onClick={handlePrint}
          >
            print
          </button>
          {prescriptionType !== "therapy" && (
            <button
              className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center bg-[#757575] p-x-10 text-md text-white"
              onClick={() => {
                // create the image and get the uploaded URL
                setLoading(true);
                uploadFile(
                  state,
                  pid,
                  prescriptionRef,
                  therapies,
                  medications,
                  prescriptionType
                )
                  .then((fileName) => {
                    getFileUrl(
                      state,
                      "brightlighthealth",
                      fileName,
                      `${pid}/prescriptions/`
                    ).then((res) => {
                      console.log(res?.data?.downloadUrl);
                      setFaxUrl(res?.data?.downloadUrl);
                      // change the page to fax
                      setLoading(false);
                      setCurrPage("fax");
                    });
                  })
                  .catch((err) => {
                    toast({
                      title: "Please try again",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    // swal("Error!", "Please try again", "error");
                    setLoading(false);
                  });
              }}
            >
              fax
            </button>
          )}
          {!disableSave && (
            <>
              <button
                className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center bg-[#272727] p-x-10 text-md text-white"
                disabled={loading}
                onClick={async () => {
                  setLoading(true);
                  toast.promise(
                    createPrescription().then(() => {
                      setLoading(false);
                      handlePrint();
                    }),
                    {
                      success: {
                        title: "Success",
                        description: "Prescription saved",
                      },
                      error: {
                        title: "Error!",
                        description: "Something went wrong",
                      },
                      loading: {
                        title: "Loading...",
                        description: "Please wait",
                      },
                    }
                  );
                }}
              >
                save & print
              </button>
              {prescriptionType !== "therapy" && (
                <button
                  className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center bg-[#272727] p-x-10 text-md text-white"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    toast.promise(
                      createPrescription()
                        .then(() => {
                          setLoading(false);
                          setCurrPage("fax");
                        })
                        .catch((err) => setLoading(false)),
                      {
                        success: {
                          title: "Success",
                          description: "Prescription saved",
                        },
                        error: {
                          title: "Error!",
                          description: "Something went wrong",
                        },
                        loading: {
                          title: "Loading...",
                          description: "Please wait",
                        },
                      }
                    );
                  }}
                >
                  save & fax
                </button>
              )}
            </>
          )}
        </ModalFooter>
      </Center>
    </>
  );
};

export default PrescriptionGenerator;
