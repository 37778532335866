import {useState} from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {formatDateTime} from "../helpers/timeFormatters";

export default function SelectPrescription({
  prescriptions,
  setSelectedPrescription,
}) {
  const [prescription, setPrescription] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSelectedPrescription(
          prescriptions.find((p) => p.presId == prescription)
        );
      }}
    >
      <Box mt="2">
        <FormControl id={"form"}>
          <FormLabel>Attach Prescription</FormLabel>
          <Flex gap="4">
            <Select
              variant="flushed"
              placeholder="Select Medication"
              value={prescription}
              onChange={(e) => setPrescription(e.target.value)}
            >
              {prescriptions?.map((p, key) => (
                <option key={key} value={p.presId}>
                  {p.drugName +
                    " " +
                    p.dosage +
                    " " +
                    p.frequency +
                    " (till " +
                    formatDateTime(p.expiry_date || "unspecified time", true) +
                    ")"}
                </option>
              ))}
            </Select>
          </Flex>
          <Button px="10" mt="4" colorScheme="blue" type="submit">
            Attach
          </Button>
        </FormControl>
      </Box>
    </form>
  );
}
