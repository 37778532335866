import React, {useState, useMemo, useRef, useEffect, useContext} from "react";
import {PiArrowCircleDownFill} from "react-icons/pi";

import {TiArrowForwardOutline, TiArrowBackOutline} from "react-icons/ti";
import {
  Checkbox,
  CheckboxGroup,
  Button,
  ButtonGroup,
  Text,
  Box,
} from "@chakra-ui/react";
import {crud} from "../../crudRequests";
export default function SelectClinicians({schState, dispatch}) {
  const [all, setAll] = useState(true);
  const [prevDoc, setPrevDoc] = useState(schState?.selectedDoctor);
  const [inputValue, setInputValue] = useState("Search...");
  const [searchValue, setSearchValue] = useState("");
  const [showOptionsList, setShowOptionsList] = useState(false);

  const clickRef = useRef(false);
  const inputRef = useRef();

  if (schState?.selectedDoctor !== prevDoc) {
    setPrevDoc(schState?.selectedDoctor);
    schState?.selectedDoctor &&
      setInputValue(`${schState?.selectedDoctor?.name}`);
  }

  useEffect(() => {
    const handler = () => {
      if (!clickRef.current) {
        setShowOptionsList((e) => e && !e);
      }
      clickRef.current = false;
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  return (
    <div className="mx-1 flex flex-col items-start relative">
      <p className="text-[11px] px-1 mb-1 text-off flex items-end w-full justify-between">
        {
          <>
            <span className="flex flex-col justify-start items-start leading-[11px]">
              <span>Clinicians</span>
            </span>

            {schState?.selectedDoctor && (
              <span
                className="flex flex-col items-center leading-[11px] text-off hover:text-dark"
                onClick={() => {
                  setInputValue("");
                  setSearchValue("");
                  dispatch({
                    type: "SELECT_DOCTOR",
                    payload: null,
                  });
                }}
              >
                <span>Back</span>

                <span className=" text-[1rem] cursor-pointer">
                  <TiArrowBackOutline />
                </span>
              </span>
            )}
          </>
        }
      </p>
      {
        <div className="relative flex justify-center items-center">
          {showOptionsList && (
            <OptionsList
              {...{
                schState,
                setAll,
                dispatch,
                searchValue,
                setSearchValue,
                setInputValue,
              }}
              onSelect={(e) => {
                setSearchValue("");
                setShowOptionsList(false);
                dispatch({
                  type: "SELECT_DOCTOR",
                  payload: e,
                });
              }}
            />
          )}

          <input
            ref={inputRef}
            value={inputValue}
            onFocus={() => {
              setInputValue("");
              setSearchValue("");
            }}
            onBlur={() => {
              if (!schState.selectedDoctor) {
                setAll(true);
                setInputValue("Search...");
              } else {
                setInputValue(`${schState.selectedDoctor.name}`);
                setAll(false);
              }
            }}
            onChange={(e) => {
              let {value} = e.target;
              setInputValue(value);
              setSearchValue(value);
              setShowOptionsList(true);
            }}
            className="drop-shadow block w-[11rem] h-9 px-2 py-2 pr-8 text-[13px] text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            type="text"
          />

          <span
            className="absolute right-0 w-8 h-full z-10 flex justify-center items-center hover:cursor-pointer"
            onClick={(e) => {
              clickRef.current = true;
              setShowOptionsList((prev) => !prev);

              setSearchValue("");
            }}
          >
            <PiArrowCircleDownFill
              className="h-5 w-5 text-off hover:text-gray-800"
              aria-hidden="true"
            />
          </span>
        </div>
      }
    </div>
  );
}

function OptionsList({
  onSelect,
  schState,
  dispatch,
  setAll,
  searchValue,
  setSearchValue,
  setInputValue,
}) {
  let selectedClinicians = schState.selectedClinicians;

  const timerId = useRef(0);
  const abort = useRef(new AbortController());

  const selectedChange = async (selected) => {
    try {
      abort.current.abort();
      abort.current = new AbortController();
      clearTimeout(timerId.current);

      timerId.current = setTimeout(async () => {
        let query = {
          id: schState?.admin?.id,
        };

        await crud(
          schState,
          [
            {
              db: schState.db,
              collection: "admins",
              parameters: [
                query,
                {$set: {defaultCliniciansInCalendar: [...selected]}},
              ],
              method: "updateOne",
            },
          ],
          null,
          abort.current.signal
        );
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="max-h-[10rem] pb-2 flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-10 bg-white drop-shadow border border-gray-200 rounded-md"
    >
      <ul className="flex-1 pt-1 overflow-auto sbar2 space-y-2 overflow-x-hidden text-[12px] text-off font-medium flex flex-col">
        {searchMatch(schState.doctors || "", searchValue, [
          "email",
          "name",
          "fName",
          "lName",
        ])
          .sort((p1, p2) => {
            let name1 = `${p1.lastName}, ${p1.firstName}`.toLocaleLowerCase(
              "en-CA"
            );
            let name2 = `${p2.lastName}, ${p2.firstName}`.toLocaleLowerCase(
              "en-CA"
            );
            if (name1 < name2) {
              return -1;
            }
            if (name1 > name2) {
              return 1;
            }
            return 0;
          })
          .map((e, i) => (
            <li
              className="justify-between gap-2 text-[12px]  text-start text-off  px-2 flex items-center"
              key={i}
            >
              <Button
                flexWrap={"wrap"}
                display={"flex"}
                justifyContent={"start"}
                size={"sm"}
                flex={1}
                colorScheme="gray"
                variant="outline"
                disabled={!selectedClinicians.has(e.did)}
                cursor={
                  !selectedClinicians.has(e.did) ? "not-allowed" : "pointer"
                }
                onClick={() => {
                  if (selectedClinicians.has(e.did)) {
                    onSelect(e);
                    setAll(false);
                  }
                }}
              >
                <Text
                  overflow={"hidden"}
                  fontSize={"xs"}
                >{`${e.lastName}, ${e.firstName}`}</Text>
              </Button>

              <Checkbox
                isChecked={selectedClinicians.has(e.did)}
                onChange={(ele) => {
                  let s = new Set(selectedClinicians);

                  if (ele.target.checked) {
                    s.add(e.did);
                    dispatch({
                      type: "SELECT_CLINICIANS",
                      payload: s,
                    });
                    selectedChange(s);
                  } else {
                    if (schState.selectedDoctor?.did == e.did) {
                      dispatch({
                        type: "SELECT_DOCTOR",
                        payload: null,
                      });
                    }

                    s.delete(e.did);
                    dispatch({
                      type: "SELECT_CLINICIANS",
                      payload: s,
                    });
                    selectedChange(s);
                  }
                }}
              ></Checkbox>
            </li>
          ))}
      </ul>

      {searchValue === "" && (
        <Box display={"flex"} m="2" mb="0" justifyContent={"center"}>
          <Button
            size={"xs"}
            w="full"
            colorScheme={
              (schState.selectedClinicians?.size || 0) ===
              schState.doctors.length
                ? "blue"
                : "gray"
            }
            variant="solid"
            onClick={() => {
              setInputValue("Search...");
              setSearchValue("");
              let all =
                (schState.selectedClinicians?.size || 0) ===
                schState.doctors.length;
              let s = new Set(
                !all ? (schState.doctors || []).map((d) => d.did) : []
              );
              dispatch({
                type: "SELECT_CLINICIANS",
                payload: s,
              });

              dispatch({
                type: "SELECT_DOCTOR",
                payload: null,
              });
              selectedChange(s);
            }}
          >
            <Text fontSize={"xs"}>{"Remove/Select All"}</Text>
          </Button>
        </Box>
      )}
    </div>
  );
}

function searchMatch(_data = [], entri, fields) {
  if (entri === "") return _data;
  try {
    let sanitizedEntri = entri.replace(
      /[$^.*+?=!:|/()[\]{}]/g,
      (e) => "\\" + e
    );
    let regex = new RegExp(`${sanitizedEntri}`, "i");
    //console.log(regex);
    let d = _data.filter((e, i) => {
      for (let field of fields) {
        if (typeof e[field] === "string" && e[field]?.match(regex)) return true;
      }
      return false;
    });
    //console.log(d)
    return d;
  } catch (error) {
    console.log(error);
    return [];
  }
}
