import {crud} from "../../../../crudRequests";
import {fetchAndExpire} from "./fetchAndExpire";

export default async function renewPrescription(
  presId,
  setPrescriptions,
  state,
  {pid},
  toast,
  start_date,
  end_date,
  length_days
) {
  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "prescriptions",
        parameters: [
          {presId},
          {
            $set: {
              expired: false,
              end_date,
              length_days,
              start_date,
              updated_at: new Date(),
            },
          },
        ],
        method: "updateOne",
      },
    ]);
    // refetch the prescriptions
    await fetchAndExpire(setPrescriptions, state, {pid}, toast);
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
}
