import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Text,
  Stack,
  Box,
  StackDivider,
  useToast,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {fetchSymptoms} from "./helpers/fetchSymptoms";
import {getSymIds} from "./helpers/chartFunctions";

// the date is the expected date for that journal
const SymptomsReport = ({journal, state}) => {
  const toast = useToast();

  const [deteriorations, setDets] = useState([]);
  const [improvements, setImprovs] = useState([]);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    if (journal && journal.journalEntries) {
      const {improvements, deteriorations, events} = getSymIds(journal);
      try {
        if (improvements) fetchSymptoms(setImprovs, state, toast, improvements);
        if (deteriorations)
          fetchSymptoms(setDets, state, toast, deteriorations);
        if (events) fetchSymptoms(setEvents, state, toast, events);
      } catch (e) {
        console.log(e);
      }
    }
  }, [journal]);

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Symptom Report</Heading>
        <Text>{journal.date}</Text>
      </CardHeader>
      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Improvements
            </Heading>
            <UnorderedList>
              {improvements?.map((symptom) => (
                <ListItem>{symptom?.shortDesc}</ListItem>
              ))}
            </UnorderedList>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Deteriorations
            </Heading>
            <UnorderedList>
              {deteriorations?.map((symptom) => (
                <ListItem>{symptom?.shortDesc}</ListItem>
              ))}
            </UnorderedList>
          </Box>

          <Box>
            <Heading size="xs" textTransform="uppercase">
              Events
            </Heading>
            <UnorderedList>
              {events?.map((symptom) => (
                <ListItem>{symptom?.shortDesc}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default SymptomsReport;
