import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"

export const AlertDialogComponent = ({btnMessage, leftIcon, rightIcon, title, body, deleteFunction}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const cancelRef = useRef()

  return (
    <>
      <Button
        colorScheme='red'
        onClick={onOpen}
        leftIcon={leftIcon} 
        rightIcon={rightIcon}
      >
        {btnMessage}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {body ?? "Are you sure? You can't undo this action afterwards."}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme='red'
                isLoading={isLoading}
                onClick={async () => {
                  setIsLoading(true)
                  await deleteFunction()
                  setIsLoading(false)
                  onClose()
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
