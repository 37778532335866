import React from "react";
import swal from "sweetalert";
import {useToast} from "@chakra-ui/react";
function TriggerInfoSearch({
  label = "Learn more about which field types are supported",
  title = "Search Compatible Fields",
  icon = "info",
  text,
}) {
  const toast = useToast();
  return (
    <span className="font-semibold pt-1 inline-block" style={{fontSize: "9px"}}>
      <img
        src="/images/Doctor/info_icon.png"
        className="w-4 inline-block -mt-0.5 ml-5 cursor-pointer"
        alt=""
      />
      &nbsp;{" "}
      <span
        className="text-off font-medium cursor-pointer"
        onClick={() =>
          toast({
            title,
            status: "info",
            description: text,
            duration: 5000,
            isClosable: true,
            position: "top",
          })
        }
      >
        {label}
      </span>
    </span>
  );
}

export default TriggerInfoSearch;
