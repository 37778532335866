import {
  Tabs,
  TabList,
  Tab,
  Avatar,
  AvatarBadge,
  Tooltip,
  Text,
  Spacer,
} from "@chakra-ui/react";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext} from "react";

export default function PatientStatus({selectedChat, usersStatus, chatEndRef}) {
  const [patient, setPatient] = useContext(PatientContext);

  return (
    <Tabs>
      <TabList>
        <Tab>
          <Tooltip
            label={patient.lName + ", " + patient.fName}
            placement="right"
          >
            <Avatar name={patient.lName + ", " + patient.fName}>
              <AvatarBadge
                boxSize="0.75em"
                bg={
                  usersStatus[selectedChat?.usersId[0]] === "connected"
                    ? "green.300"
                    : "gray.400"
                }
              />
            </Avatar>
          </Tooltip>
        </Tab>
        <Text fontSize={"12px"} mt="6">
          This patient is currently{" "}
          <b>
            {usersStatus[selectedChat?.usersId[0]] === "connected"
              ? "active"
              : "inactive"}
          </b>
        </Text>
        <Spacer />
        <Text
          color="blue.500"
          fontSize={"12px"}
          mt="6"
          mr="1"
          cursor={"pointer"}
          onClick={() =>
            chatEndRef.current?.scrollIntoView({behavior: "smooth"})
          }
        >
          Scroll To Bottom
        </Text>
      </TabList>
    </Tabs>
  );
}
