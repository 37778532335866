import {formatDateTime} from "../../helpers/timeFormatters";

export default function addPrescription(
  selectedPrescription,
  setSelectedPrescription,
  editorRef
) {
  if (selectedPrescription) {
    editorRef?.current?.insertContent(
      "<p>" +
        "Prescription:<br/><br/><b>Rx:</b> " +
        selectedPrescription.drugName +
        " " +
        selectedPrescription.dosage +
        " " +
        selectedPrescription.form +
        " " +
        selectedPrescription.route +
        " " +
        selectedPrescription.drug_use +
        "<br/><b>Frequency:</b> " +
        selectedPrescription.frequency +
        " for " +
        selectedPrescription.length_days +
        " days starting " +
        formatDateTime(selectedPrescription.start_date, true) +
        /*"<br/><b>Sig:</b> " +
            selectedPrescription.signetur +
            "<br/><b>Qty:</b> " +
            selectedPrescription.qty +
            " " +
            selectedPrescription.unit +
            " (" +
            selectedPrescription.refills +
            " refills)" +*/
        "</p>"
    );
    setSelectedPrescription(null);
  }
}
