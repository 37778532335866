import React, {useEffect, useRef, useState} from "react";
const ImageToBase64 = ({
  setData,
  field,
  previous,
  showModal,
  size = "120px",
}) => {
  const [imageBase64, setImageBase64] = useState(previous);
  const fileInputRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file && file?.type.match(/image.*/i)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64 = event.target.result;
        setImageBase64(base64);
        setData((prev) => ({
          ...prev,
          [field]: base64,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (!showModal) {
      setImageBase64(null);
    }
    if (showModal && previous) {
      setImageBase64(previous);
    }
  }, [showModal, previous]);

  return (
    <div className="flex items-center w-full justify-center">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{display: "none", pointerEvents: "none"}}
      />

      <label
        className="mx-auto"
        style={{cursor: "pointer"}}
        onClick={() => fileInputRef.current.click()}
      >
        {imageBase64 ? (
          <img
            src={imageBase64}
            alt="Preview"
            style={{maxWidth: size, maxHeight: size}}
          />
        ) : (
          <img
            src={previous ? previous : "images/Admin/DoctorInput.png"}
            alt="Placeholder"
            style={{width: size}}
          />
        )}
        <div style={{display: "none"}}>Select Image</div>
      </label>
    </div>
  );
};

export default ImageToBase64;
