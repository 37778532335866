import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState, useEffect, useReducer} from "react";
import {Box, Text, Icon, Button, Badge, useToast} from "@chakra-ui/react";
import {BiSolidMessageSquareDetail} from "react-icons/bi";
import MessageBox from "./MessageBox";
import {fetchConversation} from "./helpers/fetchConversation";
import {startConversation} from "./helpers/startConversation";
import {chatReducer} from "./helpers/chatReducer";

export default function DMs() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const [chatState, dispatch] = useReducer(chatReducer, {
    conversations: [],
    rooms: [],
    selectedChat: null,
    chatsMap: {},
    usersStatus: {},
    writing: null,
    loading: true,
  });

  const [conversation, setConversation] = useState();

  const toast = useToast();

  useEffect(() => {
    fetchConversation(setConversation, dashState, patient, toast);
  }, []);

  useEffect(() => {
    conversation?.[0] &&
      dispatch({type: "UPDATE_STATE", payload: conversation[0]});
  }, [conversation]);

  return dashState.userType == "doctor" ? (
    conversation?.length == 0 ? (
      <Box textAlign={"center"}>
        <Button
          colorScheme="blue"
          variant="outline"
          rounded="md"
          px="8"
          py="6"
          my="4"
          onClick={() =>
            startConversation(setConversation, dispatch, dashState, patient)
          }
        >
          <Icon as={BiSolidMessageSquareDetail} mr="2" /> Start Conversation
        </Button>
      </Box>
    ) : (
      <MessageBox chatState={chatState} dispatch={dispatch} />
    )
  ) : (
    <Box mx="auto" my="6" pb="6" px="8" boxShadow="md" bg="white">
      <Text textAlign="center" fontWeight="medium" color="gray.600" mt="4">
        Only clinicians can access messages with patients
      </Text>
    </Box>
  );
}
