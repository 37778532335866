import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Divider,
  Text,
  Stack,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import {DeleteIcon} from "@chakra-ui/icons";
import {crud} from "../../../crudRequests";

export function CancelPatientMembership(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const serviceMap = {};
  const toast = useToast();

  for (const service of props.schState?.services) {
    if (
      Object.keys(props.membership.selectedServices).includes(service.serviceId)
    ) {
      serviceMap[service.serviceId] = service;
    }
  }
  return (
    <Stack>
      <IconButton
        icon={<DeleteIcon />}
        variant="outline"
        onClick={onOpen}
        bg="red.400"
        opacity="75%"
        boxShadow="lg"
        size="sm"
      />
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">
            Confirm membership cancellation
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please note this will remove any pending membership balances if
              the membership is active.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={async () => {
                await crud(props.schState, [
                  {
                    db: props.schState.db,
                    collection: "patients",
                    parameters: [
                      {
                        pid: props.patient.pid,
                        "memberships.uuid": props.membership.uuid,
                      },
                      {
                        $set: {
                          "memberships.$.status": "Cancelled",
                        },
                      },
                      {returnNewDocument: true},
                    ],
                    method: "findOneAndUpdate",
                  },
                ]);
                const updatedMemberships = props.patient.memberships.map(
                  (mData) => {
                    return mData.uuid === props.membership.uuid
                      ? {
                          ...mData,
                          status: "Cancelled",
                        }
                      : mData;
                  }
                );
                props.dispatch({
                  type: "UPDATE_PATIENT",
                  pid: props.patient.pid,
                  payload: {
                    memberships: updatedMemberships,
                  },
                });
                toast({
                  title: "The membership has been cancelled.",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                props.setPatientRefetch(true);
                onClose();
              }}
              colorScheme="red"
              mr={3}
              w="1/3"
            >
              Confirm
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose} w="1/3">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
