import {crud} from "../../../../crudRequests";
import {nanoid} from "nanoid";

const getAdvTemplateList = (state) => {
  return () => {
    return crud(state, [
      {
        db: state.db,
        collection: "templates",
        parameters: [{}],
        method: "find",
      },
    ])
      .then((res1) => {
        const templates = res1.data[0];

        return crud(state, [
          {
            db: state.db,
            collection: "template_categories",
            parameters: [{}],
            method: "find",
          },
        ])
          .then((res2) => {
            const categories = res2.data[0];

            // O(N*M) when could be O(N+M) technically but hopefully number of categories should be small

            for (const category of categories) {
              category.items = templates.filter((template) => {
                if (template.categoryId === category.id) {
                  return true;
                }
                return false;
              });
            }

            for (const template of templates) {
              if (template.categoryId === "uncategorized") {
                categories.push(template);
              }
            }

            return categories;
          })
          .catch((err) => {
            console.error("getAdvTemplateList get categories error: ", err);
          });
      })
      .catch((err) => {
        console.error("getAdvTemplateList get templates error: ", err);
      });
  };
};

const getAdvTemplateGetTemplate = (state) => {
  return (id) => {
    return crud(state, [
      {
        db: state.db,
        collection: "templates",
        parameters: [{id}],
        method: "findOne",
      },
    ])
      .then((res) => {
        return res.data[0];
      })
      .catch((err) => {
        console.error("getAdvTemplateGetTemplate error: ", err);
      });
  };
};

const getAdvTemplateCreateCategory = (state) => {
  return (title) => {
    const id = nanoid() + nanoid();
    return crud(state, [
      {
        db: state.db,
        collection: "template_categories",
        parameters: [{id, title}],
        method: "insertOne",
      },
    ])
      .then((res) => {
        return {id};
      })
      .catch((err) => {
        console.error("getAdvTemplateCreateCategory error: ", err);
      });
  };
};

const getAdvTemplateCreateTemplate = (state) => {
  return (title, content, categoryId) => {
    const id = nanoid() + nanoid();
    return crud(state, [
      {
        db: state.db,
        collection: "templates",
        parameters: [
          {
            id,
            title,
            content,
            categoryId: categoryId ? categoryId : "uncategorized",
          },
        ],
        method: "insertOne",
      },
    ])
      .then((res) => {
        return {id};
      })
      .catch((err) => {
        console.error("getAdvTemplateCreateTemplate error: ", err);
      });
  };
};

const getAdvTemplateRenameCategory = (state) => {
  return (id, title) => {
    return crud(state, [
      {
        db: state.db,
        collection: "template_categories",
        parameters: [{id}, {$set: {title}}],
        method: "updateOne",
      },
    ])
      .then((res) => {
        return {};
      })
      .catch((err) => {
        console.error("getAdvTemplateRenameCategory error: ", err);
      });
  };
};

const getAdvTemplateRenameTemplate = (state) => {
  return (id, title) => {
    return crud(state, [
      {
        db: state.db,
        collection: "templates",
        parameters: [{id}, {$set: {title}}],
        method: "updateOne",
      },
    ])
      .then((res) => {
        return {};
      })
      .catch((err) => {
        console.error("getAdvTemplateRenameTemplate error: ", err);
      });
  };
};

const getAdvTemplateDeleteCategory = (state) => {
  return (id) => {
    return crud(state, [
      {
        db: state.db,
        collection: "template_categories",
        parameters: [{id}],
        method: "deleteOne",
      },
    ])
      .then((res) => {
        return crud(state, [
          {
            db: state.db,
            collection: "templates",
            parameters: [{categoryId: id}],
            method: "deleteMany",
          },
        ])
          .then((res) => {
            return {};
          })
          .catch((err) => {
            console.error("getAdvTemplateDeleteCategory error: ", err);
          });
      })
      .catch((err) => {
        console.error("getAdvTemplateDeleteCategory error: ", err);
      });
  };
};

const getAdvTemplateDeleteTemplate = (state) => {
  return (id) => {
    return crud(state, [
      {
        db: state.db,
        collection: "templates",
        parameters: [{id}],
        method: "deleteOne",
      },
    ])
      .then((res) => {
        return {};
      })
      .catch((err) => {
        console.error("getAdvTemplateDeleteTemplate error: ", err);
      });
  };
};

const getAdvTemplateMoveTemplate = (state) => {
  return (templateId, newCategoryId) => {
    return crud(state, [
      {
        db: state.db,
        collection: "templates",
        parameters: [
          {id: templateId},
          {$set: {categoryId: newCategoryId ? newCategoryId : "uncategorized"}},
        ],
        method: "updateOne",
      },
    ])
      .then((res) => {
        return {};
      })
      .catch((err) => {
        console.error("getAdvTemplateMoveTemplate error: ", err);
      });
  };
};

const getAdvTemplateMoveCategoryItems = (state) => {
  return (oldCategoryId, newCategoryId) => {
    return crud(state, [
      {
        db: state.db,
        collection: "templates",
        parameters: [
          {categoryId: oldCategoryId},
          {$set: {categoryId: newCategoryId ? newCategoryId : "uncategorized"}},
        ],
        method: "updateMany",
      },
    ])
      .then((res) => {
        return {};
      })
      .catch((err) => {
        console.error("getAdvTemplateMoveCategoryItems error: ", err);
      });
  };
};

export {
  getAdvTemplateList,
  getAdvTemplateGetTemplate,
  getAdvTemplateCreateCategory,
  getAdvTemplateCreateTemplate,
  getAdvTemplateRenameCategory,
  getAdvTemplateRenameTemplate,
  getAdvTemplateDeleteCategory,
  getAdvTemplateDeleteTemplate,
  getAdvTemplateMoveTemplate,
  getAdvTemplateMoveCategoryItems,
};
