export const validateField = (fieldName, value, setIsDisabled) => {
  let error;
  if (value && fieldName === 'Patient') {
    setIsDisabled(false)
  } else if (!value) {
    if (fieldName === 'Patient') {
      setIsDisabled(true)
    }
    error = `${fieldName} is required`;
  }
  return error;
}
