import React, {
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import {crud} from "../../crudRequests";
import {RiDeleteBin5Line} from "react-icons/ri";
import {
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import {MdOutlineStickyNote2} from "react-icons/md";
export default function AppointmentNote({appt, schState, dispatch, socket}) {
  const [cancellationReason, setCancellationReason] = useState(
    appt?.cancellationReason || ""
  );
  const [note, setNote] = useState(appt?.note || "");
  const [prevNote, setPrevNote] = useState(appt?.note);
  const [edit, setEdit] = useState(false);
  const [editCancellation, setEditCancellation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const toast = useToast();
  if (appt?.note !== prevNote) {
    setNote(appt?.note || "");
    setPrevNote(appt?.note);
    setEdit(false);
  }
  const handleTabsChange = (index) => {
    setTabIndex(index);
    setNote(appt?.note || "");
    setCancellationReason(appt?.cancellationReason || "");
    setEditCancellation(false);
    setEdit(false);
  };

  const saveNote = () => {
    if (appt && !loading) {
      setLoading(true);
      const generatingToast = toast({
        title: "Saving notes",
        description: "The note for this appointment is being updated.",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });
      let up = tabIndex === 0 ? {note} : {cancellationReason};

      crud(schState, [
        {
          db: schState.db,
          collection: "appointments",
          parameters: [{aid: appt.aid}, {$set: up}],
          method: "updateOne",
        },
      ])
        .then(async (res) => {
          toast.close(generatingToast);
          setLoading(false);
          dispatch({type: "UPDATE_APPOINTMENT", payload: {...appt, ...up}});
          setEdit(false);
          setEditCancellation(false);
          socket?.emit?.("update_appt", {...appt, ...up}, {});
        })
        .catch(function (error) {
          setLoading(false);
          toast.close(generatingToast);
          console.log(error);
        });
    }
  };

  return (
    <div className="text-gray-700 space-y-1 mt-6">
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList color={"#A1A1A1"}>
          <Tab fontSize={"xs"}>Notes</Tab>
          {appt?.cancellationReason && (
            <Tab fontSize={"xs"}>Cancellation Reason</Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel p="0" pt="2">
            <div
              className="relative flex  transition-all justify-center items-start flex-col   h-full    rounded-md w-full"
              htmlFor="time"
            >
              {edit && (
                <span
                  className="flex absolute cursor-pointer hover:text-indigo-600 -top-[18px] right-3 justify-center items-center text-[#a6a3f8] text-[1.7rem] bg-white"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!loading) {
                      saveNote();
                    }
                  }}
                >
                  {<MdOutlineStickyNote2 />}
                </span>
              )}
              <textarea
                value={note}
                onChange={(e) => {
                  appt?.note !== e.target.value && setEdit(true);
                  setNote(e.target.value);
                }}
                width="100%"
                className="h-[4.5rem]  bg-white w-full rounded-md  block  px-3 py-2.5 text-sm text-off font-normal  border border-gray-200   focus:outline-none  focus:border-[#D2D2FF]"
              />
            </div>
          </TabPanel>
          {appt?.cancellationReason && (
            <TabPanel p="0" pt="2">
              <div
                className="relative flex  transition-all justify-center items-start flex-col   h-full    rounded-md w-full"
                htmlFor="time"
              >
                {editCancellation && (
                  <span
                    className="flex absolute cursor-pointer hover:text-indigo-600 -top-[18px] right-3 justify-center items-center text-[#a6a3f8] text-[1.7rem] bg-white"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!loading) {
                        saveNote();
                      }
                    }}
                  >
                    {<MdOutlineStickyNote2 />}
                  </span>
                )}
                <textarea
                  value={cancellationReason}
                  onChange={(e) => {
                    appt?.cancellationReason !== e.target.value &&
                      setEditCancellation(true);
                    setCancellationReason(e.target.value);
                  }}
                  width="100%"
                  className="h-[4.5rem]  bg-white w-full rounded-md  block  px-3 py-2.5 text-sm text-off font-normal  border border-gray-200   focus:outline-none  focus:border-[#D2D2FF]"
                />
              </div>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </div>
  );
}
