import {crud} from "../../crudRequests";
import {nanoid} from "nanoid";
import {BiEdit} from "react-icons/bi";
import {BsInfoCircleFill} from "react-icons/bs";

import React, {useState, useEffect, useRef, useMemo} from "react";
import Signature from "./Signature";
export default function DoctorInformation({state, dispatch}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState("");

  const [fields, setFields] = useState(state?.admin || state?.doctor || {});
  const [insurances, setInsurances] = useState(
    new Set(fields.insurances || [])
  );
  const [userLocations, setUserLocations] = useState(
    new Set((state.admin ? fields.location : fields.lid) || [])
  );

  const [warning, setWarning] = useState("");
  let {_id, ...payload} = fields;
  payload.name = payload.firstName + " " + payload.lastName;
  if (state.doctor) {
    payload.insurances = [...insurances];
    payload.lid = [...userLocations];
  } else payload.location = [...userLocations];

  function validate() {
    return !!(fields.firstName && fields.lastName && fields.email);
  }

  const update = async () => {
    try {
      if (validate()) {
        setWarning("");
        setLoading(true);

        let user = state.admin || state.doctor;
        let id = state.admin ? "id" : "did";
        let idValue = state.admin?.id || state.doctor?.did;

        await crud(state, [
          {
            db: state.db,
            collection: state.admin ? "admins" : "doctors",
            parameters: [{[id]: idValue}, {$set: payload}],
            method: "findOneAndUpdate",
          },
        ]);
        dispatch({
          type: id === "did" ? "UPDATE_DOCTOR" : "UPDATE_ADMIN",
          payload,
        });

        setLoading(false);
      } else setWarning("Please complete the mandatory fields!");
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  return (
    <div
      className="relative flex-col  pr-4 p-0 bg-white overflow-y-hidden flex justify-center w-[700px]"
      onClick={(e) => {
        e.stopPropagation();
        setWarning("");
      }}
    >
      {warning && <Warning warning={warning} setWarning={setWarning} />}
      <div className="relative flex-1 h-full w-full overflow-y-scroll pr-2 ">
        <div className="bg-[#f6f5ff] text-left  px-4 pt-10 pb-6 rounded-xl overflow-hidden relative">
          <h3
            className="text-2xl font-medium px-5 mb-3 text-gray-800"
            style={{color: "rgb(192, 191, 255)"}}
          >
            {"Edit User"}
          </h3>

          <ProfilePhoto fields={fields} setFields={setFields} />

          <div className="h-full mt-3">
            <div className="overflow-hidden grid grid-cols-2 gap-3  rounded-md pb-2">
              <label className="block px-2 my-1" htmlFor="firstName">
                <span className="text-[13px] ml-2  text-off ">
                  User First Name*
                </span>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={fields.firstName || ""}
                  onChange={(e) => {
                    setFields((prev) => {
                      return {
                        ...prev,
                        firstName: e.target.value,
                      };
                    });
                  }}
                  className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="block px-2 my-1" htmlFor="lastName">
                <span className="text-[13px] ml-2  text-off ">
                  User Last Name*
                </span>
                <input
                  name="lastName"
                  id="lastName"
                  value={fields.lastName || ""}
                  className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  onChange={(e) => {
                    setFields((prev) => {
                      return {
                        ...prev,
                        lastName: e.target.value,
                      };
                    });
                  }}
                />
              </label>
              <label className="block px-2 my-1" htmlFor="email">
                <span className="text-[13px] ml-2  text-off ">
                  User E-mail*
                </span>
                <input
                  name="email"
                  id="email"
                  value={fields.email}
                  className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  onChange={(e) => {
                    setFields((prev) => {
                      return {
                        ...prev,
                        email: e.target.value,
                      };
                    });
                  }}
                />
              </label>

              <label className="block px-2 my-1" htmlFor="phone">
                <span className="text-[13px] ml-2  text-off ">
                  User Phone Number
                </span>
                <input
                  id="phone"
                  name="phone"
                  value={fields.phone}
                  className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^(\+|\+?\d+|\d+|^$|(\+?\d+-\d*)*)$/)
                    )
                      setFields((prev) => {
                        return {
                          ...prev,
                          phone: e.target.value,
                        };
                      });
                  }}
                />
              </label>
              <label
                className="inline-block px-2 my-1 w-full  "
                htmlFor="title"
              >
                <span className="text-[13px] ml-2  text-off ">User Title</span>
                <input
                  name="title"
                  id="title"
                  value={fields.title || ""}
                  className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  onChange={(e) => {
                    setFields((prev) => {
                      return {
                        ...prev,
                        title: e.target.value,
                      };
                    });
                  }}
                />
              </label>
              <label
                className="inline-block px-2 my-1 w-full  "
                htmlFor="registrationId"
              >
                <span className="text-[13px] ml-2  text-off ">
                  Registration Number
                </span>
                <input
                  name="registrationId"
                  id="registrationId"
                  value={fields.registrationId}
                  className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  onChange={(e) => {
                    setFields((prev) => {
                      return {
                        ...prev,
                        registrationId: e.target.value,
                      };
                    });
                  }}
                />
              </label>

              <div className="flex items-center justify-between">
                <label
                  className="inline-block px-2 my-1 w-full  "
                  htmlFor="accreditations"
                >
                  <span className="text-[13px] ml-2  text-off ">
                    Accreditations
                  </span>
                  <input
                    name="accreditations"
                    id="accreditations"
                    value={fields?.accreditations}
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          accreditations: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
              </div>
              {state.doctor && (
                <>
                  <label
                    className="inline-block px-2 my-1 w-full  "
                    htmlFor="licenseNumber"
                  >
                    <span className="text-[13px] ml-2  text-off ">
                      License Number
                    </span>
                    <input
                      name="licenseNumber"
                      id="licenseNumber"
                      value={fields.licenseNumber}
                      className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                      onChange={(e) => {
                        setFields((prev) => {
                          return {
                            ...prev,
                            licenseNumber: e.target.value,
                          };
                        });
                      }}
                    />
                  </label>
                  <div className="flex items-center justify-between">
                    <label
                      className="inline-block px-2 my-1 w-full  "
                      htmlFor="registrationId"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Registration Number
                      </span>
                      <input
                        name="registrationId"
                        id="registrationId"
                        value={fields.registrationId}
                        className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              registrationId: e.target.value,
                            };
                          });
                        }}
                      />
                    </label>
                  </div>
                  <div className="flex items-center justify-between">
                    <label
                      className="inline-block px-2 my-1 w-full  "
                      htmlFor="smOffered"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Services/modalities offered
                      </span>
                      <input
                        name="smOffered"
                        id="smOffered"
                        value={fields?.smOffered}
                        className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              smOffered: e.target.value,
                            };
                          });
                        }}
                      />
                    </label>
                  </div>

                  <div className="flex items-center justify-between">
                    <label
                      className="inline-block px-2 my-1 w-full  "
                      htmlFor="education"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Clinician education
                      </span>
                      <input
                        name="education"
                        id="education"
                        value={fields?.education}
                        className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              education: e.target.value,
                            };
                          });
                        }}
                      />
                    </label>
                  </div>

                  <div className="flex items-center justify-between">
                    <label
                      className="inline-block px-2 my-1 w-full  "
                      htmlFor="desiredHperW"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Desired hours/week
                      </span>
                      <input
                        name="desiredHperW"
                        id="desiredHperW"
                        type="text"
                        value={fields?.desiredHperW}
                        className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          if (
                            e.target.value.match(/^\d+$/g) ||
                            e.target.value === ""
                          )
                            setFields((prev) => {
                              return {
                                ...prev,
                                desiredHperW: parseInt(e.target.value || 0),
                              };
                            });
                        }}
                      />
                    </label>
                  </div>
                  <div className="flex items-center justify-between">
                    <label
                      className="inline-block px-2 my-1 w-full  "
                      htmlFor="compensationRate"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Compensation Rate (%)
                      </span>
                      <input
                        name="compensationRate"
                        id="compensationRate"
                        readOnly="true"
                        type="text"
                        value={fields?.compensationRate}
                        className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          if (
                            e.target.value.match(/^\d+$/g) ||
                            e.target.value === ""
                          )
                            setFields((prev) => {
                              return {
                                ...prev,
                                compensationRate: parseInt(e.target.value || 0),
                              };
                            });
                        }}
                      />
                    </label>
                  </div>
                  <label
                    className="inline-block px-2 my-1 w-full"
                    htmlFor="providerType"
                  >
                    <span className="text-[13px] ml-2  text-off ">
                      Provider Types
                    </span>
                    <select
                      onChange={(e) => {
                        setFields((prev) => {
                          return {
                            ...prev,
                            providerType: e.target.value,
                          };
                        });
                      }}
                      value={fields?.providerType || ""}
                      name="providerType"
                      id="providerType"
                      className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    >
                      <option value="">-</option>
                      {state.providerTypes.map((p, i) => (
                        <option key={i} value={p.typeId}>
                          {p.name}
                        </option>
                      ))}
                    </select>
                  </label>
                </>
              )}
              <label className="block mx-3.5 mt-[17.5px]">
                <span className="text-[13px] ml-2  text-off ">Description</span>
                <textarea
                  placeholder=" Write a brief description..."
                  value={fields.description || ""}
                  onChange={(e) => {
                    setFields((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  className="h-[4.5rem] block placeholder:font-normal w-[17rem] placeholder:opacity-50 px-3 py-2.5 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
            </div>
            <div className="mx-3.5">
              <Signature schState={state} dispatch={dispatch} />
            </div>
            <div className="space-y-10 my-7">
              <AssignedLocations
                locations={state.locations}
                userLocations={userLocations}
                setUserLocations={setUserLocations}
              />
              {state.doctor && (
                <PreferredInsurances
                  insurers={state.insurers}
                  insurances={insurances}
                  setInsurances={setInsurances}
                />
              )}

              {state.doctor && (
                <MultipleEntriesInput
                  label="Preferred Disorders"
                  disorders={fields.disorders}
                  setFields={setFields}
                  fieldName={"disorders"}
                />
              )}
            </div>
          </div>
          {errorMessage && (
            <div
              className="bg-red-100 border border-red-400 text-red-700  py-3 px-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Error!</strong>
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}

          <div className="mt-6 w-4/5 mb-4 mx-auto">
            <button
              onClick={(e) => {
                e.stopPropagation();
                update();
              }}
              disabled={loading}
              className=" w-full rounded-lg flex justify-center items-center text-background bg-med py-2"
            >
              {"Save changes"}
              {loading && (
                <span className="">
                  <svg
                    className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25 stroke-[4px]"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function MultipleEntriesInput({disorders, setFields, label, fieldName}) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      const newEntries = new Set([...disorders, inputValue.trim()]);
      setInputValue("");
      setFields((prev) => {
        return {
          ...prev,
          [fieldName]: [...newEntries],
        };
      });
    }
  };

  return (
    <div className="space-y-2">
      <label className="relative block my-1 text-left" htmlFor="lastName">
        <span className="text-[13px] ml-2  text-off  ">{label}</span>
        <input
          name="lastName"
          id="lastName"
          className=" block w-full peer  py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          value={inputValue}
        />
        <div className="hidden w-full absolute top-[0px] right-2  text-[10px] mt-1 text-yellow-500 peer-focus:flex justify-end items-center space-x-1">
          <span className="">{"Type an option and press enter."}</span>
          <BsInfoCircleFill />
        </div>
      </label>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[80px]  w-full sbar2   p-2">
          {disorders.map((entry, index) => (
            <li
              key={index}
              className="inline-block mx-1 my-1  py-1 text-sm text-white font-medium bg-[#ACAAFF] rounded-md cursor-pointer relative px-4"
              onClick={() => {
                setFields((prev) => {
                  return {
                    ...prev,
                    [fieldName]: prev[fieldName].filter((f) => f !== entry),
                  };
                });
              }}
            >
              <svg
                aria-hidden="true"
                className="w-2 h-2 absolute to left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="">{entry}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function PreferredInsurances({insurances, setInsurances, insurers, creating}) {
  //flex flex-col justify-center items-center
  return (
    <div className="px-2 pr-0 w-full pb-1 flex  flex-col text-left items-start">
      <span className="text-[13px] ml-2 text-off">Preferred Insurances</span>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[160px]  w-full sbar2   p-2">
          {insurers.map((crit, i) => (
            <li
              onClick={() => {
                if (!creating) {
                  !insurances?.has(crit.iid)
                    ? setInsurances((prev) => {
                        let n = new Set(prev);
                        n.add(crit.iid);
                        return n;
                      })
                    : setInsurances((prev) => {
                        let n = new Set(prev);
                        n.delete(crit.iid);
                        return n;
                      });
                }
              }}
              key={i}
              className="hover:cursor-pointer m-1 flex items-center  p-1 py-0 px-0 space-x-1"
            >
              <span
                className=" h-5 w-5 rounded-md shadow-ntf bg-white hover:cursor-pointer"
                style={{
                  backgroundColor: insurances.has(crit.iid)
                    ? "#9F9DFA"
                    : "#FFFFFF",
                }}
              ></span>

              <span className="font-medium text-[0.9rem] text-[#8e8df9]">
                {crit.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function AssignedLocations({
  userLocations,
  setUserLocations,
  locations,
  creating,
}) {
  //flex flex-col justify-center items-center
  return (
    <div className="px-2 pr-0 w-full my-4 pb-1 flex  flex-col text-left items-start">
      <span className="text-[13px] ml-2 text-off">Locations</span>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[160px]  w-full sbar2   p-2">
          {locations.map((crit, i) => (
            <li
              onClick={() => {
                if (!creating) {
                  !userLocations?.has(crit.lid)
                    ? setUserLocations((prev) => {
                        let n = new Set(prev);
                        n.add(crit.lid);
                        return n;
                      })
                    : setUserLocations((prev) => {
                        let n = new Set(prev);
                        n.delete(crit.lid);
                        return n;
                      });
                }
              }}
              key={i}
              className="hover:cursor-pointer m-1 flex items-center  p-1 py-0 px-0 space-x-1"
            >
              <span
                className=" h-5 w-5 rounded-md shadow-ntf bg-white hover:cursor-pointer"
                style={{
                  backgroundColor: userLocations.has(crit.lid)
                    ? "#9F9DFA"
                    : "#FFFFFF",
                }}
              ></span>

              <span className="font-medium text-[0.9rem] text-[#8e8df9]">
                {crit.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function ProfilePhoto({fields, setFields}) {
  const fileRef = useRef(null);
  function onUpdatePhoto(ele) {
    let files = ele.target.files;
    let file = files[0];
    if (!file?.type.match(/image.*/i)) return;
    var reader = new FileReader();

    reader.addEventListener("load", (e) => {
      setFields((prev) => ({...prev, photo: e.target.result}));
    });
    reader.readAsDataURL(file);
  }
  return (
    <div className="flex justify-center items-center">
      <div className="h-[70px] w-[70px] rounded-full shadow-lg flex justify-center items-center overflow-hidden relative">
        <input
          ref={fileRef}
          onChange={(e) => {
            onUpdatePhoto(e);
          }}
          className="absolute top-0 opacity-0 z-0"
          type="file"
          name="updatePhoto"
        />
        {fields?.photo ? (
          <img
            src={fields.photo}
            alt=""
            className="relative z-10 hover:cursor-pointer  h-full rounded-full"
          />
        ) : (
          <img
            src={"images/Admin/DoctorInput.png"}
            alt=""
            className="relative z-10 hover:cursor-pointer  h-full rounded-full "
          />
        )}
        <div
          className="absolute group transition text-transparent hover:text-white text-lg inset-0 h-full w-full rounded-full z-20 flex justify-center items-center hover:bg-gray-400/50 hover:cursor-pointer"
          onClick={() => {
            fileRef.current.click();
          }}
        >
          <BiEdit className="" />
        </div>
      </div>
    </div>
  );
}
function Warning({warning, setWarning, className}) {
  return (
    <div
      className={
        className ||
        "show z-10 absolute top-3 left-3 text-xs bg-yellow-500 p-3 w-48 rounded-md"
      }
    >
      <div className="font-bold flex">
        <span className="flex text-white items-center space-x-1">
          <img
            alt=""
            src="/images/Clinic/warn.png"
            className="h-4  inline-block"
          />{" "}
          <span> Warning:</span>
        </span>
        <span
          className="ml-auto mr-1 text-sm text-white underline cursor-pointer font-light"
          onClick={(e) => setWarning("")}
        >
          x
        </span>
      </div>
      <div className="font-regular mt-1 text-white">{warning}</div>
    </div>
  );
}
