import {useState, useRef, useEffect} from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Box,
  Stack,
  Text,
  Checkbox,
  Flex,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import dataFields from "../helpers/dataFields";

/*export default function CustomFields({setFields, fields}) {
  const createClose = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});

  useEffect(() => {
    const initialSelectedFields = {};
    Object.entries(dataFields).forEach(([category, flds]) => {
      Object.entries(flds).forEach(([displayKey, fieldKey]) => {
        initialSelectedFields[fieldKey] =
          Object.values(fields).includes(fieldKey);
      });
    });
    setSelectedFields(initialSelectedFields);
  }, [fields]);

  const handleApplyClick = () => {
    setIsLoading(true);
    const newFields = {};
    for (const category in dataFields) {
      for (const [displayKey, fieldKey] of Object.entries(
        dataFields[category]
      )) {
        if (selectedFields[fieldKey]) {
          newFields[displayKey] = fieldKey;
        }
      }
    }
    setFields(newFields);
    setIsLoading(false);
    createClose.current.click();
  };

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Button px="12" colorScheme="blue">
          Add/Remove Fields
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent width="1200px">
          <PopoverArrow />
          <PopoverHeader pt={4} fontWeight="bold" border="0">
            Add/Remove Fields
          </PopoverHeader>
          <PopoverCloseButton ref={createClose} />
          <PopoverBody>
            <Box overflowX="auto">
              <Stack direction="row" spacing={8}>
                {Object.entries(dataFields).map(([category, fields]) => (
                  <Box key={category}>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      mb={2}
                      color="gray.700"
                    >
                      {category}
                    </Text>
                    {Object.entries(fields).map(([key, value]) => (
                      <>
                        <Checkbox
                          key={key}
                          isChecked={selectedFields[value]}
                          onChange={(e) =>
                            setSelectedFields((prev) => ({
                              ...prev,
                              [value]: e.target.checked,
                            }))
                          }
                          size="sm"
                        >
                          {key}
                        </Checkbox>
                        <br />
                      </>
                    ))}
                  </Box>
                ))}
              </Stack>
            </Box>
          </PopoverBody>
          <PopoverFooter border="0">
            <Flex justifyContent="end">
              <ButtonGroup size="sm">
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  onClick={() => {
                    createClose.current.click();
                  }}
                >
                  Go Back
                </Button>
                <Button
                  isLoading={isLoading}
                  colorScheme="blue"
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}*/

export default function CustomFields({setFields, fields}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});

  useEffect(() => {
    const initialSelectedFields = {};
    Object.entries(dataFields).forEach(([category, flds]) => {
      Object.entries(flds).forEach(([displayKey, fieldKey]) => {
        initialSelectedFields[fieldKey] =
          Object.values(fields).includes(fieldKey);
      });
    });
    setSelectedFields(initialSelectedFields);
  }, [fields]);

  const handleApplyClick = () => {
    setIsLoading(true);
    const newFields = {};
    for (const category in dataFields) {
      for (const [displayKey, fieldKey] of Object.entries(
        dataFields[category]
      )) {
        if (selectedFields[fieldKey]) {
          newFields[displayKey] = fieldKey;
        }
      }
    }
    setFields(newFields);
    setIsLoading(false);
    onClose();
  };
  return (
    <>
      <Button onClick={onOpen} px="12" colorScheme="blue">
        Add/Remove Fields
      </Button>

      <Modal
        isOpen={isOpen}
        size={"4xl"}
        onClose={onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pt={4} fontWeight="bold" border="0">
            Add/Remove Fields
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="row" spacing={8}>
              {Object.entries(dataFields).map(([category, fields]) => (
                <Box key={category}>
                  <Text fontSize="sm" fontWeight="bold" mb={2} color="gray.700">
                    {category}
                  </Text>
                  {Object.entries(fields).map(([key, value]) => (
                    <>
                      <Checkbox
                        key={key}
                        isChecked={selectedFields[value]}
                        onChange={(e) =>
                          setSelectedFields((prev) => ({
                            ...prev,
                            [value]: e.target.checked,
                          }))
                        }
                        size="sm"
                      >
                        {key}
                      </Checkbox>
                      <br />
                    </>
                  ))}
                </Box>
              ))}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="end">
              <ButtonGroup size="sm">
                <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                  Go Back
                </Button>
                <Button
                  isLoading={isLoading}
                  colorScheme="blue"
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
