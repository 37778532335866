import {crud} from "../../crudRequests";
import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
  useMemo,
} from "react";
import {lightFormat} from "date-fns";
import {TiArrowForwardOutline, TiArrowBackOutline} from "react-icons/ti";
export default function NextAvailableDate({schState, dispatch, setDate}) {
  const nd = () => {
    try {
      let next =
        schState.selectedDoctor?.nextAvailableDate ||
        lightFormat(new Date(), "yyyy-MM-dd");
      let dateArray = next.split("-").map((e) => parseInt(e));
      dateArray[1]--;

      return lightFormat(new Date(...dateArray), "yyyy-MM-dd");
    } catch (error) {
      return lightFormat(new Date(), "yyyy-MM-dd");
    }
  };

  let prev = nd();
  const [selectedDate, setSelectedDate] = useState(prev);
  const [prevNext, setPrevNext] = useState(prev);
  const timerId = useRef(0);
  const abort = useRef(new AbortController());

  if (prevNext !== prev) {
    setPrevNext(prev);
    setSelectedDate(prev);
  }

  // console.log(schState.admin);

  const onStartChange = (e) => {
    setSelectedDate(e.target.value);
    clearTimeout(timerId.current);
    abort.current.abort();
    abort.current = new AbortController();

    timerId.current = setTimeout(() => {
      //console.log("k");
      let query = {
        did: schState.selectedDoctor?.did,
      };

      crud(
        schState,
        [
          {
            db: schState.db,
            collection: "doctors",
            parameters: [query, {$set: {nextAvailableDate: e.target.value}}],
            method: "updateOne",
          },
        ],
        null,
        abort.current.signal
      ).then(() => {
        schState.selectedDoctor &&
          dispatch({
            type: "UPDATE_DOCTOR",
            payload: {
              did: schState.selectedDoctor?.did,
              nextAvailableDate: e.target.value,
            },
          });
      });
    }, 2000);
  };

  return (
    <div className="flex space-x-1 items-center">
      <span
        onClick={(e) => {
          let dateArray = selectedDate.split("-").map((e) => parseInt(e));
          dateArray[1]--;

          setDate(new Date(...dateArray));
        }}
        className="text-off mt-4  hover:text-dark text-[1.5rem] cursor-pointer"
      >
        <TiArrowForwardOutline />
      </span>
      <div className="mx-1 flex flex-col items-start">
        <span className="text-[11px] ml-1 text-off">Next Available Date</span>
        <input
          onChange={onStartChange}
          type="date"
          name="date"
          id="time"
          value={selectedDate}
          className="drop-shadow block w-[8.5rem] h-9 px-3 py-1 text-sm text-off font-medium bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
        />
      </div>
    </div>
  );
}
