export default function isDatePassed(inputDate, daysToAdd) {
  // Parse the input date string into a Date object
  const date = new Date(inputDate);

  // Add the specified number of days to the date
  const updatedDate = date;

  updatedDate.setDate(updatedDate.getDate() + parseInt(daysToAdd));
  // Get the current date
  const currentDate = new Date();

  return updatedDate < currentDate;
}
