import {useEffect, useRef, useCallback} from "react";
import io from "socket.io-client";
export function useSocket(url, room, dispatch) {
  const socketRef = useRef({});
  console.log(url);

  const updateAppt = useCallback(async function (data, {ntf, changingDoctor}) {
    console.log("update_appt_socket");
    dispatch({type: "UPDATE_APPOINTMENT", payload: data, changingDoctor});
    if (ntf) dispatch({type: "NEW_NOTIFICATION", payload: ntf});
  }, []);

  const updatPt = useCallback(async function (pid, payload, options) {
    console.log("update_patient");
    dispatch({type: "UPDATE_PATIENT", pid, payload, options});
  }, []);

  const newAppt = useCallback(async function (
    data,
    {ntf, updatePatient, newPatient}
  ) {
    console.log("new_appt");
    dispatch({
      type: "NEW_APPOINTMENT",
      payload: data,
      updatePatient,
      newPatient,
    });

    dispatch({type: "NEW_NOTIFICATION", payload: ntf});
  },
  []);

  const completedForm = useCallback(async function (data, {ntf}) {
    dispatch({type: "NEW_NOTIFICATION", payload: ntf});
  }, []);

  useEffect(() => {
    if (room) {
      socketRef.current = io(url, {
        auth: {
          room,
        },
      });

      socketRef.current.on("update_appt", updateAppt);
      socketRef.current.on("update_pt", updatPt);
      socketRef.current.on("new_appt", newAppt);
      socketRef.current.on("completed_form", completedForm);
      return () => {
        socketRef.current.off("update_appt");
        socketRef.current.off("new_appt");
        socketRef.current.off("update_pt");
        socketRef.current.off("completed_form");
        socketRef.current.disconnect();
      };
    }
  }, [room, updateAppt]);

  return socketRef;
}

export function useChatSocket(url, rooms, userId, dispatch) {
  const socketRef = useRef({});

  const newMessge = useCallback(async function (data) {
    // console.log('message received')
    let {
      messageId,
      conversationId,
      senderId,
      messageType,
      message,
      attachment,
      timestamp,
    } = data;
    dispatch({
      type: "NEW_MESSAGE",
      payload: {
        messageId,
        conversationId,
        senderId,
        messageType,
        message,
        attachment,
        timestamp,
      },
    });
  }, []);

  const userStatus = useCallback(async function ({userId, status}) {
    // console.log(status)
    dispatch({
      type: "SET_USER_STATUS",
      payload: {userId, status},
    });
  }, []);

  const userCurrentStatus = useCallback(async function (status) {
    //  console.log("SET_USERS_CURRENT_STATUS",status)
    dispatch({
      type: "SET_USERS_CURRENT_STATUS",
      payload: status,
    });
  }, []);

  const newConversation = useCallback(async function (conversation) {
    //console.log("new-conversation")
    dispatch({
      type: "NEW_CONVERSATION",
      payload: conversation,
    });
  }, []);

  const writingStatus = useCallback(async function ({chatId, userId}) {
    dispatch({
      type: "WRITING",
      payload: {chatId, userId},
    });
  }, []);

  useEffect(() => {
    if (rooms || userId) {
      socketRef.current = io(url, {
        auth: {
          rooms,
          userId,
        },
      });

      socketRef.current.on("message", newMessge);
      socketRef.current.on("user-status", userStatus);
      socketRef.current.on("writing", writingStatus);
      //socketRef.current.on("new-conversation", newConversation);
      socketRef.current.on("users-current-status", userCurrentStatus);

      return () => {
        socketRef.current.off("message", newMessge);
        socketRef.current.off("user-status", userStatus);
        socketRef.current.off("writing", writingStatus);
        socketRef.current.off("new-conversation", newConversation);
        socketRef.current.off("users-current-status", userCurrentStatus);
        socketRef.current.disconnect();
      };
    }
  }, [rooms]);

  return socketRef;
}
