import Swal from "sweetalert2";
import {
  generatePaymentLink,
  createCharge,
  crud,
  sendCardReqLink,
  sendInvRecEmail,
  getPaymentMethods,
  executePayment,
} from "../../../crudRequests";
import deleteFile from "./deleteFile";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import {lightFormat} from "date-fns";
import InvRecpDF from "./Invoice";
import {pdf} from "@react-pdf/renderer";

export async function fullInvoice({state, transaction, patient, cancelled}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          patient,
          cancelled,
        }}
        returnDoc
        type="FULL_INVOICE"
      />
    ).toBlob();
    let d = new Date();
    await deleteFile(
      state,
      transaction?.pid + `/Invoices/Products/${fileNameFormat(transaction)}/`,
      "Consolidated_Invoice"
    );
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Consolidated_Invoice" +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid + `/Invoices/Products/${fileNameFormat(transaction)}/`
    );
  } catch (e) {
    console.log(e);
  }
}
export async function fullReceipt({state, transaction, patient, cancelled}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          patient,
          cancelled,
        }}
        returnDoc
        type="FULL_RECEIPT"
      />
    ).toBlob();
    let d = new Date();
    await deleteFile(
      state,
      transaction?.pid + `/Receipts/Products/${fileNameFormat(transaction)}/`,
      "Consolidated_Receipt"
    );
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Consolidated_Receipt" +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid + `/Receipts/Products/${fileNameFormat(transaction)}/`
    );
  } catch (e) {
    console.log(e);
  }
}
export async function patientInvoice({state, transaction, patient, cancelled}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          transaction,
          patient,
          state,
          cancelled,
        }}
        returnDoc
        type="PATIENT_INVOICE"
      />
    ).toBlob();
    let d = new Date();
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Invoice " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid + `/Invoices/Products/${fileNameFormat(transaction)}/`
    );
  } catch (e) {
    console.log(e);
  }
}
export async function patientReceipt({
  state,
  transaction,
  patient,
  receiptAmount,
  cancelled,
}) {
  try {
    let blob = await pdf(
      <InvRecpDF
        {...{
          state,
          transaction,
          patient,
          receiptAmount,
          cancelled,
        }}
        returnDoc
        type="PATIENT_RECEIPT"
      />
    ).toBlob();
    let d = new Date();
    await crudStorageUpload(
      state,
      new File(
        [blob],
        "prefix_" +
          d.getTime() +
          "-" +
          blob.size / 1000 +
          "-" +
          "Receipt " +
          lightFormat(d, "hh_mm aaa") +
          ".pdf"
      ),
      "brightlighthealth",
      transaction?.pid +
        `/Receipts/${"Products"}/${fileNameFormat(transaction)}/`
    );

    return blob;
  } catch (e) {
    console.log(e);
  }
}

function fileNameFormat(transaction) {
  return (
    lightFormat(new Date(transaction.serviceDate), "dd-MM-yy HH-mm aaa") +
    ` (${transaction.tid})`
  );
}
