import {
  Grid,
  Box,
  Text,
  useToast,
  Square,
  Flex,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import Note from "./Note";
import {AddIcon} from "@chakra-ui/icons";
import {useContext, useState, useEffect, useMemo} from "react";
import AddOrEdit from "./AddOrEdit";
import {fetchNotes} from "./helpers/fetchNotes";
//import AppointmentNotes from "./AppointmentNotes";
export default function Appointments({handleLinkToChart}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [admins, setAdmins] = useState([]);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const toast = useToast();

  useEffect(() => {
    fetchNotes(setNotes, setAdmins, dashState, patient, toast);
  }, []);

  const cliniciansMap = useMemo(() => {
    return (
      dashState.doctors
        ? dashState.doctors
        : dashState.doctor
        ? [dashState.doctor]
        : []
    ).reduce((acc, el) => {
      acc[el.did] = el;
      return acc;
    }, {});
  }, [dashState.doctors, dashState.doctor]);

  const adminsMap = useMemo(() => {
    return admins.reduce((acc, el) => {
      acc[el.id] = el;
      return acc;
    }, {});
  }, [admins]);

  return (
    <Box
      textAlign="center"
      w="100%"
      height={"full"}
      my="2"
      display="flex"
      flexDirection={"column"}
      flexWrap={"wrap"}
      alignItems={"center"}
      position={"relative"}
      border="1pa solid red"
    >
      {isOpen && (
        <AddOrEdit
          {...{
            selectedNote,
            setSelectedNote,
            isOpen,
            onClose,
            patient,
            dashState,
            setNotes,
          }}
        />
      )}
      <Button
        colorScheme="blue"
        leftIcon={<AddIcon />}
        onClick={() => {
          onOpen();
        }}
      >
        Add Note
      </Button>

      {notes.length > 0 ? (
        <Box overflowY="auto" w="full" mt="6" flex={1}>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr 1fr",
            }}
            mt="4"
            gap={6}
            padding={4}
          >
            {notes.map((note, i) => (
              <Note
                adminsMap={adminsMap}
                cliniciansMap={cliniciansMap}
                setNotes={setNotes}
                dispatch={dispatch}
                state={dashState}
                key={i}
                note={note}
                setSelectedNote={setSelectedNote}
                onOpen={onOpen}
              />
            ))}
          </Grid>
        </Box>
      ) : (
        <Box mx="auto" my="6" pb="6" px="6" boxShadow="md" bg="white">
          <Text textAlign="center" fontWeight="medium" color="gray.600" mt="4">
            No notes found for this patient
          </Text>
        </Box>
      )}
    </Box>
  );
}
