import {
  lightFormat,
  nextSunday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  subDays,
  endOfDay,
  addWeeks,
} from "date-fns";

export default function weeklyHelper(recurrence, appt) {
  let h = [
    nextSunday,
    nextMonday,
    nextTuesday,
    nextWednesday,
    nextThursday,
    nextFriday,
    nextSaturday,
  ];

  let duration = parseInt(appt.duration.split(" ")[0]) * 60000;

  let frequency = recurrence?.frequency;
  let pattern = recurrence?.pattern;
  let range = recurrence?.range;
  if (frequency && pattern && range) {
    let {every, weekdays} = pattern;
    let {rangeType, occurrences, startDate, endDate} = range;
    weekdays = [...weekdays];

    startDate = new Date(
      ...startDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );
    endDate = new Date(
      ...endDate
        .split("-")
        .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
    );
    let hours = new Date(appt.ISOdate).getHours();
    let minutes = new Date(appt.ISOdate).getMinutes();
    let time = hours * 3600000 + minutes * 60000;
    return weekdays
      .map((e) => {
        let arr = [];
        let dayBefore = subDays(startDate, 1);
        let nextWeekDay = h[e](dayBefore);
        let n = occurrences;
        switch (rangeType) {
          case "END_BY":
            while (
              nextWeekDay.getTime() + time >= startDate &&
              nextWeekDay.getTime() + time + duration <= endOfDay(endDate)
            ) {
              arr.push(nextWeekDay.getTime() + time);

              nextWeekDay = addWeeks(nextWeekDay, Number(every));
            }

            break;
          case "END_AFTER":
            while (nextWeekDay.getTime() + time >= startDate && n > 0) {
              arr.push(nextWeekDay.getTime() + time);
              nextWeekDay = addWeeks(nextWeekDay, Number(every));
              n--;
            }
            break;

          default:
            break;
        }

        return arr;
      })
      .flat();
  }

  return [];
}
