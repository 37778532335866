import {
  Box,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Button,
  Badge,
  Tooltip,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  HStack,
  Text,
  CheckboxGroup,
  Checkbox,
  VStack,
  Select,
  FormControl,
  Radio,
} from "@chakra-ui/react";
import {useState} from "react";
import {
  dayCriteria,
  months,
  weekday,
  yearFirstCriteria,
  yearSecondCriteria,
} from "./helpers/criteria";

const dayOpt = ["first", "second", "third", "fourth", "last"];

export default function Yearly({date, recurrentCriteriaRef}) {
  const [every, setEvery] = useState(1);
  const [criteria1, setCriteria1] = useState(yearFirstCriteria(date));
  const [criteria2, setCriteria2] = useState(yearSecondCriteria(date));
  const [selected, setSelected] = useState("criteria1");

  recurrentCriteriaRef.current.frequency = "yearly";
  recurrentCriteriaRef.current.pattern =
    selected === "criteria1" ? {...criteria1, every} : {...criteria2, every};

  return (
    <VStack fontSize={"sm"} gap={3} align={"self-start"}>
      <HStack gap="2" align={"center"} fontWeight={600} color={"gray0.600"}>
        <Flex gap="2">
          <Text>Recur every</Text>

          <NumberInput
            size="xs"
            w="10"
            max={10}
            min={1}
            value={every}
            onChange={(e) =>
              setEvery((prev) => {
                if (e.match(/^\d{0,2}$/)) {
                  let v = e.replace(/[-+,.]/g, "");
                  if (Number(v) > 10) return prev;
                  return v;
                } else return prev;
              })
            }
          >
            <NumberInputField textAlign={"end"} p="1" />
          </NumberInput>
        </Flex>
        <Text>year(s)</Text>
      </HStack>

      <HStack
        w="full"
        gap="2"
        align={"center"}
        fontWeight={600}
        color={selected === "criteria1" ? "gray.600" : "gray.400"}
        fontSize={"xs"}
      >
        <Radio
          size={"sm"}
          onChange={(e) => {
            setSelected("criteria1");
            setCriteria2(yearSecondCriteria(date));
          }}
          isChecked={selected === "criteria1"}
          value="criteria1"
        >
          <Text fontSize={"xs"}>On</Text>
        </Radio>
        <FormControl
          display={"flex"}
          gap={2}
          alignItems={"center"}
          id="1"
          isDisabled={selected === "criteria2"}
        >
          <NumberInput
            size="xs"
            w="10"
            max={31}
            min={1}
            value={criteria1.day}
            clampValueOnBlur={false}
            onBlur={() => {
              if (!Number(criteria1.day))
                setCriteria1((prev) => {
                  return {
                    ...prev,
                    day: yearFirstCriteria(date).day,
                  };
                });
            }}
            onChange={(e) =>
              setCriteria1((prev) => {
                if (e.match(/^\d{0,2}$/)) {
                  let v = e.replace(/[-+,.]/g, "");
                  if (Number(v) > 31) return prev;
                  return {
                    ...prev,
                    day: v,
                  };
                } else return prev;
              })
            }
          >
            <NumberInputField textAlign={"end"} p="1" />
          </NumberInput>
          <Select
            size="xs"
            w="5rem"
            variant="outline"
            value={criteria1.month}
            onChange={(e) => {
              setCriteria1((prev) => {
                return {...prev, month: e.target.value};
              });
            }}
          >
            {months.map((e, i) => (
              <option key={i} value={i}>
                {e.toLowerCase()}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>

      {/*<HStack
        w="full"
        gap="2"
        align={"center"}
        fontWeight={600}
        color={selected !== "criteria1" ? "gray.600" : "gray.400"}
        fontSize={"xs"}
      >
        <Radio
          size={"sm"}
          onChange={(e) => {
            setSelected("criteria2");
            setCriteria1(yearFirstCriteria(date));
          }}
          isChecked={selected === "criteria2"}
          value="criteria2"
        >
          <Text>On the</Text>
        </Radio>
        <FormControl
          flex={1}
          display={"flex"}
          gap={2}
          alignItems="center"
          id="2"
          isDisabled={selected === "criteria1"}
        >
          <Select
            size="xs"
            variant="outline"
            value={criteria2.the}
            onChange={(e) => {
              setCriteria2((prev) => {
                return {...prev, the: e.target.value};
              });
            }}
          >
            {dayOpt.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </Select>
          <Select
            size="xs"
            variant="outline"
            value={criteria2.weekday}
            onChange={(e) => {
              setCriteria2((prev) => {
                return {...prev, weekday: e.target.value};
              });
            }}
          >
            {weekday.map((e, i) => (
              <option key={i} value={i}>
                {e.toLowerCase()}
              </option>
            ))}
          </Select>
          <Text>of</Text>
          <Select
            size="xs"
            variant="outline"
            value={criteria2.month}
            onChange={(e) => {
              setCriteria2((prev) => {
                return {...prev, month: e.target.value};
              });
            }}
          >
            {months.map((e, i) => (
              <option key={i} value={i}>
                {e.toLowerCase()}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>*/}
    </VStack>
  );
}
