import React, { useEffect, useReducer, useState } from 'react'
import Navigation from '../components/Navigation'
import { useLocation } from 'react-router-dom';
import { init, scheduleReducer } from '../additional_files/reducer';
import { verifyUser } from '../authRequests';
import TasksTable from '../components/Tasks/TasksTable';

export default function Tasks () {
    const {state} = useLocation();
    const [patient, setPatient] = useState(null);
    const [dashState, dispatch] = useReducer(scheduleReducer, state, init);
    const [tasks, setTasks] = useState([])

    useEffect(() => {
      verifyUser(state ? state.jwt : "");
    }, [state]);

    return (
      <Navigation
      state={{...dashState}}
      patient={patient}
      setPatient={setPatient}
      dispatch={dispatch}
      >
        {dashState && (
              <TasksTable
              dashState={dashState}
              tasks={tasks}
              setTasks={setTasks}
              />
        )}
      </Navigation>
    )
}
