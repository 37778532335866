export default function getHTMLElement(ele) {
  console.log(ele.key);
  switch (ele.key) {
    case "Select Menu":
      return select(ele);

    case "Check Boxes":
      return checkboxes(ele);

    case "Text":
      return text(ele);

    case "Heading":
      return heading(ele);

    case "Subheading":
      return subHeading(ele);

    case "Paragraph":
      return paragraph(ele);

    case "Note":
      return note(ele);

    case "Slider":
      return slider(ele);

    case "Date":
      return date(ele);

    case "Line Break":
      return linebreak(ele);

    default:
      return null;
  }
}

function slider(ele) {
  return `<div
  style= 'display: flex;flex-direction: column;align-items: flex-start;'
  >
    <p
    style='font-weight:500; font-size: 14px; color: #1f2937;'
    >
      ${ele.children[0].children?.toString() || ""}
    </p>
   
    <div
     style='color:#374151; position: relative; display: flex; align-items: center; font-size: 12px; height:20px;box-shadow: inset 0px 0px 3px #334155; width:200px; border-radius: 20px; border: 1px solid #94a3b8;'
    >
     <p style='height:100%; width:${
       ele.children[1]?.props?.value || 0
     }%; border-radius: 20px; background-color: #3199ee;'></p>
     <span style="position: absolute; top:25px; left:95px">${
       ele.children[1]?.props?.value || ""
     }%</span>
    </div>  
  </div>`;
}

function date(ele) {
  return `<div
  style= 'display: flex;flex-direction: column;align-items: flex-start;'
  >
    <p
    style='font-weight:500; font-size: 14px; color: #1f2937;'
    >
      ${ele.children[0].children?.toString() || ""}
    </p>
  
    <p
     style='color:#374151; font-size: 12px; border-bottom: 1px solid #475569; padding-right: 10px;'
     
    >
      ${ele.children[1]?.props?.value || ""}
    </p>  
  </div>`;
}

function linebreak(ele) {
  return `<p
         style= 'border-bottom: 1px solid #475569; width: 100%; margin: 10px 0px;'
         >

    </p>`;
}

function select(ele) {
  return `<div
      style= 'margin-top: 0px  10px 0px 0px;'
    >
      <p
        style='color:#1f2937; font-size: 14px;'>
    
        ${ele.children[0].children}
      </p>

      <div
        style="display: flex; flex-wrap: wrap;"
      >
        ${ele.children[1].children
          .map(
            (e, i) =>
              `<div
            style=' display: flex; margin: 0px 10px 0 0; align-items: flex-end;'  
          >
            <p
            style='text-align: center;  font-size: 12px; margin-right:5px; border-bottom: 1px solid #475569; width: 20px'
            >
              ${
                ele.children[1].props?.value === e.props?.value?.toString()
                  ? "X"
                  : ""
              }
            </p>
            <p style='color: #1f2937; font-size: 14px;'>
              ${e.props?.value?.toString()}
            </p>
          </div>`
          )
          .join("\n")}
      </div>
    </div>`;
}

function checkboxes(ele) {
  return `<div
    style= 'margin: 0px  10px 0px 0px;'
      
    >
      <p
      style='color:#1f2937; font-size: 14px; '
      >
        ${ele.children[0].children}
      </p>

      <div
      style="display: flex; flex-wrap: wrap; text-align: center;"
      >
        ${ele.children[1].children
          .map(
            (e, i) =>
              `<div
            style='display: flex; justify-content:end; margin: 0px 10px 0 0; align-items:center;'  
          >
            <p
            style='text-align: center; margin-right:5px; border-radius: 2px; font-size: 10px; border: 1px solid #475569; height: 14px; width: 14px'
            >
            ${e.children[0].props.checked ? "X" : ""}
            </p>
          
            <p style="fontSize: 12px; color: #374151;">
              ${e.children[0].props.value?.toString()}
            </p>
          </div>`
          )
          .join("")}
      </div>
    </div>`;
}

function note(ele) {
  return `<div
        style= 'display: flex;flex-direction: column;align-items: flex-start;'
        >
          <p
          style='font-weight:500; font-size: 14px; color: #1f2937; margin-right: 25px;'
          >
            ${ele.children[0].children?.toString() || ""}
          </p>
    
        
          <p
           style='color:#374151; font-size: 12px; border-bottom: 1px solid #475569; padding-right: 10px;'
           
          >
            ${ele.children[1]?.props?.value || ""}
          </p>
    
          
        </div>`;
}

function text(ele) {
  return `<div
  style= 'display: flex;flex-direction: column;align-items: flex-start;'
  >
    <p
    style='font-weight:500; font-size: 14px; color: #1f2937; margin-right: 25px;'
    >
      ${ele.children[0].children?.toString() || ""}
    </p>

  
    <p
     style='color:#374151; font-size: 12px; border-bottom: 1px solid #475569; padding-right: 10px;'
     
    >
      ${ele.children[1]?.props?.value || ""}
    </p>

    
  </div>`;
}

function heading(ele) {
  return `<div style='margin-top:5px;'>
      <p
        style='font-weight:bold; font-size: 16px;color: #1f2937'
      >
        ${ele.children[0].children?.toString()}
      </p>
    </div>`;
}

function subHeading(ele) {
  return `
      <h3
        style='font-weight:bold; margin-top: 5px; font-size: 16px;color: #1f2937;'
      >
        ${ele.children[0].children?.toString()}
      </h3>
    `;
}

function paragraph(ele) {
  return `<div style='margin-bottom: 10px; margin-right: 20px;'     
    >
      <p
        style='font-size:14px; margin:0px; color: #374151;'
      >
        ${ele.children[0].children?.toString()}
      </p>
    </div>`;
}
