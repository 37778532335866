import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  ButtonGroup,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Tooltip,
  Icon,
  IconButton,
  StackDivider,
  HStack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormHelperText,
  Tag,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import {IoReturnUpBack} from "react-icons/io5";
import {MdClear} from "react-icons/md";
import {RxInput} from "react-icons/rx";
import {MdClose} from "react-icons/md";
import React, {useState, useEffect, useRef} from "react";
import {TbMathFunction} from "react-icons/tb";
import {useMemo} from "react";
export default function Calc({
  rowCalcValues,
  setRowCalcValues,
  cellSelection,
  mirrorTable,
  colCalcValues,
  setColCalcValues,
}) {
  const [show, setShow] = useState(false);
  const [colOperation, setColOperation] = useState("");
  const [operationsArray, seTOperationsArray] = useState([]);
  const [prevCellSelection, setPrevCellSelection] = useState(cellSelection);
  if (prevCellSelection !== cellSelection) {
    setPrevCellSelection(cellSelection);
    seTOperationsArray([]);
  }

  const toast = useToast();
  function onChlickEntity({type, name, value}) {
    let l = operationsArray.length;
    let last = l > 0 ? operationsArray[l - 1] : null;
    switch (type) {
      case "operator":
        if (last && last.type !== "operator") {
          seTOperationsArray((prev) => prev.concat({type, name, value}));
        }
        break;
      case "number":
        if (l === 0 || last?.type === "operator") {
          seTOperationsArray((prev) => prev.concat({type, name, value}));
        }
        break;
      case "column":
        if (l === 0 || last?.type === "operator") {
          seTOperationsArray((prev) => prev.concat({type, name, value}));
        }
        break;

      default:
        break;
    }
  }

  function colcCalc() {
    switch (colOperation) {
      case "column-wise summation":
        if (cellSelection.size > 0 && mirrorTable.current.length > 0) {
          let track = new Map();
          cellSelection.forEach((value, key) => {
            let ac = 0;
            [...value].forEach((e) => {
              if (!isNaN(mirrorTable.current[e]?.[key]?.value))
                ac += Number(mirrorTable.current[e]?.[key]?.value);
            });
            track.set(key, ac.toFixed(2));
          });

          setRowCalcValues((prev) => {
            let arr = [...prev];
            track.forEach((value, key) => {
              arr[key] = value;
            });

            return arr;
          });
        }
        break;

      default:
        setRowCalcValues([]);
        break;
    }
  }

  return (
    <Popover
      isLazy
      placement="right-start"
      isOpen={show}
      onClose={() => {
        setShow(false);
        seTOperationsArray([]);
      }}
    >
      <PopoverTrigger>
        <Flex gag={2} align={"center"}>
          <IconButton
            variant="solid"
            colorScheme={cellSelection.size > 0 ? "blue" : "gray"}
            aria-label="Function"
            fontSize="20px"
            icon={<TbMathFunction />}
            cursor={cellSelection.size > 0 ? "pointer" : "not-allowed"}
            opacity={cellSelection.size > 0 ? "1" : "0.5"}
            onClick={(e) => {
              e.stopPropagation();
              if (cellSelection.size > 0) {
                setShow(!show);
              }
            }}
          />
          {(rowCalcValues.length > 0 || colCalcValues.length > 0) && (
            <Icon
              as={MdClose}
              color={"gray.500"}
              _hover={{color: "gray.700"}}
              w={6}
              h={6}
              cursor={"pointer"}
              onClick={() => {
                setRowCalcValues([]);
                setColCalcValues([]);
              }}
            />
          )}
        </Flex>
      </PopoverTrigger>

      <PopoverContent
        w="30rem"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <PopoverHeader fontWeight="semibold" bg={"gray.100"}>
          <Flex justify={"space-between"} align={"center"}>
            <Text>Basic Calculations</Text>{" "}
          </Flex>
        </PopoverHeader>
        <PopoverArrow bg={"gray.100"} />

        <PopoverBody>
          <Stack divider={<StackDivider />} spacing="4">
            <ColCalculations {...{setColOperation, colOperation}} />
            <RowCalculations
              {...{
                mirrorTable,
                cellSelection,
                operationsArray,
                seTOperationsArray,
                onChlickEntity,
                toast,
              }}
            />
          </Stack>
        </PopoverBody>
        <PopoverFooter>
          <Flex justifyContent="end">
            <Stack direction="row">
              <ButtonGroup size="sm">
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  onClick={() => {
                    setShow(false);
                    seTOperationsArray([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    let l = operationsArray.length;
                    let last = l > 0 ? operationsArray[l - 1] : null;
                    for (let i = 0; i < operationsArray.length; i++) {
                      if (
                        operationsArray[i].type === "number" &&
                        operationsArray[i].value === ""
                      ) {
                        toast({
                          title: "Invalid equation format.",
                          description: "Please complete the numerical fields!",
                          status: "error",
                          isClosable: true,
                          duration: 5000,
                        });
                        return;
                      }
                    }

                    if (operationsArray.length > 0) {
                      let valid = false;

                      for (let i = 0; i < operationsArray.length; i++) {
                        if (operationsArray[i].type === "column") {
                          valid = true;
                          break;
                        }
                      }

                      if (!valid) {
                        toast({
                          title: "Invalid equation format.",
                          description: "No column selected!",
                          status: "error",
                          isClosable: true,
                          duration: 5000,
                        });
                        return;
                      }
                    }

                    if (last && last?.type === "operator") {
                      toast({
                        title: "Invalid equation format.",
                        description: "Please remove the last operator!",
                        status: "error",
                        isClosable: true,
                        duration: 5000,
                      });
                      return;
                    }
                    colcCalc();
                    rowCalc({operationsArray, toast, setColCalcValues});
                    setShow(false);
                  }}
                  colorScheme="blue"
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Stack>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function ColCalculations({colOperation, setColOperation}) {
  return (
    <Box>
      <FormControl as="fieldset">
        <FormLabel fontSize="sm" fontWeight="bold" color="blue.500" as="legend">
          Column operations
        </FormLabel>
        <RadioGroup>
          <Stack spacing="10px">
            <Checkbox
              onChange={(e) => {
                if (colOperation === "column-wise summation") {
                  setColOperation("");
                } else {
                  setColOperation("column-wise summation");
                }
              }}
              isChecked={colOperation === "column-wise summation"}
              value={"column-wise summation"}
            >
              Column-wise summation
            </Checkbox>
          </Stack>
        </RadioGroup>
        <FormHelperText>Same column's elements summation</FormHelperText>
      </FormControl>
    </Box>
  );
}

function RowCalculations({
  mirrorTable,
  cellSelection,
  operationsArray,
  seTOperationsArray,
  onChlickEntity,
  toast,
}) {
  const columnsMap = useMemo(() => {
    let a = mirrorTable.current[0];

    if (a?.length > 0) {
      return a.reduce((ac, ele, i) => {
        if (cellSelection.has(i)) {
          ac[ele] = i;
        }
        return ac;
      }, {});
    }
    return {};
  }, [cellSelection]);

  return (
    <Stack gap={2}>
      <Text fontSize="sm" fontWeight="bold" color="blue.500">
        Row operations
      </Text>

      <FormControl mb="2" as="fieldset">
        <FormLabel fontSize="xs" m="1" pl={1} color={"gray.400"}>
          Selected Columns
        </FormLabel>
        <Box
          maxH={"100px"}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
          p="1"
          overflowY={"scroll"}
          pb="2"
        >
          <Flex flexWrap={"wrap"} direction={"row"} gap={2}>
            {Object.entries(columnsMap).map(([key, value], i) => (
              <Button
                key={i}
                size={"xs"}
                onClick={() => {
                  let s = cellSelection.get(value);
                  s = new Set(s);
                  s.delete(0);
                  let arr = [];
                  let v = [...s].forEach((j) => {
                    arr[j] = mirrorTable.current?.[j]?.[value]?.value;
                  });

                  onChlickEntity({type: "column", name: key, value: arr});
                }}
                colorScheme="blue"
              >
                {key}
              </Button>
            ))}
          </Flex>
        </Box>
      </FormControl>
      <FormControl as="fieldset" mx="2">
        <Flex justify={"space-between"}>
          <Flex gap={2}>
            <Button
              onClick={() => onChlickEntity({type: "operator", value: "+"})}
              fontWeight={"semibold"}
              colorScheme="gray"
            >
              +
            </Button>
            <Button
              onClick={() => onChlickEntity({type: "operator", value: "-"})}
              fontWeight={"semibold"}
              colorScheme="gray"
            >
              -
            </Button>
            <Button
              onClick={() => onChlickEntity({type: "operator", value: "*"})}
              fontWeight={"semibold"}
              colorScheme="gray"
            >
              *
            </Button>
            <Button
              onClick={() => onChlickEntity({type: "operator", value: "/"})}
              fontWeight={"semibold"}
              colorScheme="gray"
            >
              /
            </Button>
            <IconButton
              onClick={() => onChlickEntity({type: "number", value: ""})}
              colorScheme="gray"
              icon={<RxInput />}
            />
          </Flex>
          <Stack direction={"row"} pr="4" spacing={2}>
            <IconButton
              onClick={() => seTOperationsArray((prev) => prev.slice(0, -1))}
              colorScheme="gray"
              icon={<IoReturnUpBack />}
            />
            <IconButton
              onClick={() => seTOperationsArray([])}
              colorScheme="gray"
              icon={<MdClear />}
            />
          </Stack>
        </Flex>
        <Flex gap={2} my="8" mr="4" flexWrap={"wrap"}>
          <Text fontSize="md" fontWeight="bold" color="gray.800">
            Fx:
          </Text>

          {operationsArray.map((e, i) => {
            switch (e.type) {
              case "operator":
                return (
                  <Tag
                    size={"sm"}
                    key={i}
                    variant="solid"
                    bg="gray.200"
                    color={"black"}
                  >
                    {e.value}
                  </Tag>
                );

              case "number":
                return (
                  <NumberInput
                    onChange={(valueString) => {
                      seTOperationsArray((prev) =>
                        prev.map((j, k) =>
                          k === i ? {...j, value: valueString} : j
                        )
                      );
                    }}
                    value={operationsArray[i].value}
                    key={i}
                    rounded={"md"}
                    size="xs"
                    defaultValue={0}
                    w="40px"
                  >
                    <NumberInputField rounded={"md"} p="1" py="2" />
                  </NumberInput>
                );

              case "column":
                return (
                  <Tag
                    size={"xs"}
                    fontSize={"xs"}
                    p="1"
                    fontWeight={"normal"}
                    key={i}
                    variant="solid"
                    colorScheme="gray"
                  >
                    {e.name}
                  </Tag>
                );
                break;

              default:
                break;
            }
          })}
        </Flex>

        <FormHelperText>Same row's elements operations</FormHelperText>
      </FormControl>
    </Stack>
  );
}

function rowCalc({operationsArray, toast, setColCalcValues}) {
  let op = [...operationsArray];

  if (op.length === 0) {
    setColCalcValues([]);
    return;
  }

  let pointer = 0;
  while (pointer < op.length - 1) {
    if (
      (op[pointer].type === "operator" && op[pointer].value === "*") ||
      op[pointer].value === "/"
    ) {
      operationBetweenEntities(pointer, op[pointer].value, op);
      --pointer;
    }
    pointer++;
  }

  pointer = 0;
  while (pointer < op.length - 1) {
    if (
      (op[pointer].type === "operator" && op[pointer].value === "+") ||
      op[pointer].value === "-"
    ) {
      operationBetweenEntities(pointer, op[pointer].value, op);
      --pointer;
    }
    pointer++;
  }
  setColCalcValues(op);
  // console.log(op);
}

function operationBetweenEntities(pointer, operator, operationsArray) {
  let left = operationsArray[pointer - 1];
  let right = operationsArray[pointer + 1];

  switch (operator) {
    case "*":
    case "/":
      if (left.type === "column" && right.type === "column") {
        let baseArray =
          left.value.length > right.value.length ? left.value : right.value;
        let result = baseArray.map((e, i) => {
          let lValue = left.value[i];
          let rValue = right.value[i];

          if (lValue === "#!DIV/0" || rValue === "#!DIV/0") return "#!DIV/0";
          if (
            isNaN(lValue) ||
            isNaN(rValue) ||
            lValue === "" ||
            rValue === ""
          ) {
            // console.log(lValue, rValue);
            return "";
          }

          if (operator === "/" && Number(rValue) === 0) return "#!DIV/0";

          let k =
            operator === "/"
              ? Number(lValue) / Number(rValue)
              : Number(lValue) * Number(rValue);
          return k.toFixed(2);
        });
        operationsArray.splice(pointer - 1, 3, {type: "column", value: result});
        return;
      }

      if (left.type === "column" || right.type === "column") {
        let baseArray = left.type === "column" ? left.value : right.value;
        let n = left.type !== "column" ? left.value : right.value;

        let result = baseArray.map((e, i) => {
          if (e === "#!DIV/0") return "#!DIV/0";
          if (isNaN(e) || e === "") return "";

          if (
            operator === "/" &&
            right.type === "number" &&
            Number(right.value) === 0
          )
            return "#!DIV/0";

          if (operator === "/" && right.type === "column" && Number(e) === 0)
            return "#!DIV/0";

          let k =
            operator === "/"
              ? right.type === "number"
                ? Number(e) / Number(n)
                : Number(n) / Number(e)
              : Number(e) * Number(n);

          return k.toFixed(2);
        });
        operationsArray.splice(pointer - 1, 3, {type: "column", value: result});
        return;
      }

      ///both numbers
      if (left.type === "number" || right.type === "number") {
        let lValue = left.value;
        let rValue = right.value;

        if (lValue === "#!DIV/0" || rValue === "#!DIV/0") return "#!DIV/0";
        if (isNaN(lValue) || lValue === "" || isNaN(rValue) || rValue === "")
          return "";
        if (operator === "/" && Number(rValue) === 0) return "#!DIV/0";

        let result =
          operator === "/"
            ? Number(lValue) / Number(rValue)
            : Number(lValue) * Number(rValue);

        operationsArray.splice(pointer - 1, 3, {
          type: "number",
          value: result.toFixed(2),
        });
        return;
      }

      break;

    case "+":
    case "-":
      if (left.type === "column" && right.type === "column") {
        let baseArray =
          left.value.length > right.value.length ? left.value : right.value;
        let result = baseArray.map((e, i) => {
          let lValue = left.value[i];
          let rValue = right.value[i];

          if (lValue === "#!DIV/0" || rValue === "#!DIV/0") return "#!DIV/0";
          if (
            isNaN(lValue) ||
            isNaN(rValue) ||
            lValue === "" ||
            rValue === ""
          ) {
            // console.log(lValue, rValue);
            return "";
          }

          let k =
            operator === "-"
              ? Number(lValue) - Number(rValue)
              : Number(lValue) + Number(rValue);

          return k.toFixed(2);
        });
        operationsArray.splice(pointer - 1, 3, {type: "column", value: result});
        return;
      }

      if (left.type === "column" || right.type === "column") {
        let baseArray = left.type === "column" ? left.value : right.value;
        let n = left.type !== "column" ? left.value : right.value;

        let result = baseArray.map((e, i) => {
          if (e === "#!DIV/0") return "#!DIV/0";
          if (isNaN(e) || e === "") return "";

          let k =
            operator === "-"
              ? right.type === "number"
                ? Number(e) - Number(n)
                : Number(n) - Number(e)
              : Number(e) + Number(n);

          return k.toFixed(2);
        });
        operationsArray.splice(pointer - 1, 3, {type: "column", value: result});
        return;
      }

      ///both numbers
      if (left.type === "number" || right.type === "number") {
        let lValue = left.value;
        let rValue = right.value;

        if (lValue === "#!DIV/0" || rValue === "#!DIV/0") return "#!DIV/0";
        if (isNaN(lValue) || lValue === "" || isNaN(rValue) || rValue === "")
          return "";

        let result =
          operator === "-"
            ? Number(lValue) - Number(rValue)
            : Number(lValue) + Number(rValue);

        operationsArray.splice(pointer - 1, 3, {
          type: "number",
          value: result.toFixed(2),
        });
        return;
      }

      break;

    default:
      break;
  }
}
