import React, {useState, useEffect, useRef} from "react";
import {AiFillEdit} from "react-icons/ai";
import Swal from "sweetalert2";
import EditFormField from "./EditFormField";
import PreviewForm from "./PreviewForm";
import {crud} from "../crudRequests";
import {useToast} from "@chakra-ui/react";

export default function NewForm(props) {
  const state = props.state;
  const createEdit = props.createEdit;
  const setCreateEdit = props.setCreateEdit;
  // const formTitle = useRef(null);
  const [formTitle, setFormTitle] = useState(createEdit?.formTitle);
  const formDescription = useRef(null);
  const toast = useToast();
  function createElementFromObject(obj) {
    const {element, key, props = {}, children} = obj;

    if (typeof children === "string") {
      return React.createElement(element, {key, ...props}, children);
    }

    if (Array.isArray(children)) {
      const childElements = children.map((child, index) =>
        createElementFromObject({...child, key: child.key || index})
      );

      return React.createElement(element, {key, ...props}, childElements);
    }

    return React.createElement(element, {key, ...props}, children);
  }

  const [STATE_previewForm, STATE_setPreviewForm] = useState(null);
  const [STATE_fieldType, STATE_setFieldType] = useState(null);
  const [STATE_editField, STATE_setEditField] = useState(null);
  const [STATE_formGrid, STATE_setFormGrid] = useState(
    createEdit?.formFields ? JSON.parse(createEdit.formFields) : {0: {0: ""}}
  );

  const fieldDictionary = function (divId, field) {
    const commonStyles = {
      marginTop: "0.5rem",
      display: "block",
      borderRadius: "0.25rem",
      color: "#555555",
    };

    return field === "heading" ? (
      {
        element: "div",
        key: "Heading",
        props: {style: {marginTop: "1.5rem", marginBottom: "0.75rem"}},
        children: [
          {
            element: "h1",
            props: {
              style: {
                ...commonStyles,
                fontWeight: "600",
                fontSize: "1.875rem",
              },
            },
            children: "Heading",
          },
        ],
      }
    ) : field === "subheading" ? (
      {
        element: "div",
        key: "Subheading",
        props: {style: {marginTop: "1.5rem", marginBottom: "0.75rem"}},
        children: [
          {
            element: "h3",
            props: {
              style: {
                ...commonStyles,
                fontWeight: "500",
                fontSize: "1.5rem",
              },
            },
            children: "Subheading",
          },
        ],
      }
    ) : field === "paragraph" ? (
      {
        element: "div",
        key: "Paragraph",
        props: {style: {marginTop: "0.5rem", marginBottom: "0.75rem"}},
        children: [
          {
            element: "p",
            props: {
              style: {
                ...commonStyles,
                fontWeight: "400",
                fontSize: "1rem",
              },
            },
            children: "This is a sentence in a paragraph.",
          },
        ],
      }
    ) : field === "text" ? (
      {
        element: "div",
        key: "Text",
        props: {style: {marginTop: "0.5rem"}},
        children: [
          {
            element: "span",
            props: {
              style: {
                fontSize: "0.75rem",
                ...commonStyles,
              },
            },
            children: "Text Label",
          },
          {
            element: "input",
            props: {
              type: "text",
              placeholder: "",
              style: {
                ...commonStyles,
                padding: "0.5rem",
                marginTop: "0.25rem",
                width: "100%",
              },
            },
            key: "",
          },
        ],
      }
    ) : field === "note" ? (
      {
        element: "div",
        key: "Note",
        props: {style: {marginTop: "0.5rem"}},
        children: [
          {
            element: "span",
            props: {
              style: {
                fontSize: "0.75rem",
                ...commonStyles,
              },
            },
            children: "Note Label",
          },
          {
            element: "textarea",
            props: {
              style: {
                ...commonStyles,
                padding: "0.5rem",
                marginTop: "0.25rem",
                width: "100%",
              },
              placeholder: "",
              rows: 1,
            },
            key: "",
          },
        ],
      }
    ) : field === "select" ? (
      {
        element: "div",
        key: "Select Menu",
        props: {style: {marginTop: "0.5rem"}},
        children: [
          {
            element: "span",
            props: {
              style: {
                fontSize: "0.75rem",
                ...commonStyles,
              },
            },
            children: "Select Menu Label",
          },
          {
            element: "select",
            props: {
              style: {
                ...commonStyles,
                marginTop: "0.25rem",
                width: "100%",
              },
            },
            children: [
              {
                element: "option",
                props: {value: "option 1"},
                children: "option 1",
              },
              {
                element: "option",
                props: {value: "option 2"},
                children: "option 2",
              },
              {
                element: "option",
                props: {value: "option 3"},
                children: "option 3",
              },
            ],
          },
        ],
      }
    ) : field === "checkboxes" ? (
      {
        element: "div",
        key: "Check Boxes",
        props: {style: {marginTop: "0.5rem"}},
        children: [
          {
            element: "span",
            props: {
              style: {
                fontSize: "0.75rem",
                ...commonStyles,
              },
            },
            children: "Check Boxes Label",
          },
          {
            element: "div",
            style: {paddingTop: "0.5rem"},
            children: [
              {
                element: "span",
                children: [
                  {
                    element: "input",
                    props: {
                      type: "checkbox",
                      name: "option 1",
                      style: {
                        focusRing: "0",
                        border: "1px solid #D1D1D1",
                        borderRadius: "0.25rem",
                        padding: "0.5rem",
                      },
                      value: "option 1",
                    },
                  },
                  {
                    element: "label",
                    props: {
                      style: {
                        color: "#555555",
                        marginLeft: "0.25rem",
                        marginRight: "1rem",
                      },
                    },
                    children: "option 1",
                  },
                ],
              },
              {
                element: "span",
                children: [
                  {
                    element: "input",
                    props: {
                      type: "checkbox",
                      name: "option 2",
                      style: {
                        focusRing: "0",
                        border: "1px solid #D1D1D1",
                        borderRadius: "0.25rem",
                        padding: "0.5rem",
                      },
                      value: "option 2",
                    },
                  },
                  {
                    element: "label",
                    props: {
                      style: {
                        color: "#555555",
                        marginLeft: "0.25rem",
                        marginRight: "1rem",
                      },
                    },
                    children: "option 2",
                  },
                ],
              },
              {
                element: "span",
                children: [
                  {
                    element: "input",
                    props: {
                      type: "checkbox",
                      name: "option 3",
                      style: {
                        focusRing: "0",
                        border: "1px solid #D1D1D1",
                        borderRadius: "0.25rem",
                        padding: "0.5rem",
                      },
                      value: "option 3",
                    },
                  },
                  {
                    element: "label",
                    props: {
                      style: {
                        color: "#555555",
                        marginLeft: "0.25rem",
                        marginRight: "1rem",
                      },
                    },
                    children: "option 3",
                  },
                ],
              },
            ],
          },
        ],
      }
    ) : field == "linebreak" ? (
      {
        element: "div",
        key: "Line Break",
        props: {style: {marginTop: "1.5rem", marginBottom: "0.75rem"}},
        children: [
          {
            element: "hr",
            key: "",
            props: {style: {border: "1px solid #555555"}},
          },
        ],
      }
    ) : field === "date" ? (
      {
        element: "div",
        key: "Date",
        props: {style: {marginTop: "0.5rem"}},
        children: [
          {
            element: "span",
            props: {
              style: {
                fontSize: "0.75rem",
                ...commonStyles,
              },
            },
            children: "Date Label",
          },
          {
            element: "input",
            props: {
              type: "date",
              placeholder: "",
              style: {
                ...commonStyles,
                padding: "0.5rem",
                marginTop: "0.25rem",
                width: "100%",
              },
            },
            key: "",
          },
        ],
      }
    ) : field == "slider" ? (
      {
        element: "div",
        key: "Slider",
        props: {style: {marginTop: "0.5rem"}},
        children: [
          {
            element: "span",
            props: {
              style: {
                fontSize: "0.75rem",
                color: "#555555",
              },
            },
            children: "Slider Label",
          },
          {
            element: "input",
            props: {
              type: "range",
              placeholder: "",
              style: {
                display: "block",
                marginTop: "0.25rem",
                width: "100%",
                height: "2px",
                backgroundColor: "#D1D1D1",
                borderRadius: "0.25rem",
                appearance: "none",
                cursor: "pointer",
              },
            },
            key: "",
          },
        ],
      }
    ) : field == "upload" ? (
      {
        element: "div",
        key: "File Upload",
        props: {style: {marginTop: "0.5rem"}},
        children: [
          {
            element: "span",
            props: {
              style: {
                fontSize: "0.75rem",
                color: "#555555",
              },
            },
            children: "File Upload Label",
          },
          {
            element: "input",
            props: {
              type: "file",
              name: "fileInput",
              id: "fileInput",
              style: {
                width: "100%",
                fontSize: "0.75rem",
                color: "#555555",
              },
              accept: "*",
            },
            key: "",
          },
        ],
      }
    ) : (
      <></>
    );
  };

  function putFieldInDiv(divId) {
    STATE_setFormGrid((prevState) => ({
      ...prevState,
      [divId.split("-")[0]]: {
        ...prevState[divId.split("-")[0]],
        [divId.split("-")[1]]: fieldDictionary(divId, STATE_fieldType),
      },
    }));
    STATE_setFieldType(null);
  }

  function saveForm(toast) {
    var s = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const fid = Array.apply(null, Array(10))
      .map(function () {
        return s.charAt(Math.floor(Math.random() * s.length));
      })
      .join("");

    let payload = {
      fid: createEdit.fid ? createEdit.fid : fid,
      formTitle: formTitle,
      formDescription: formDescription.current.value,
      creatorName:
        state.userType == "admin" ? state.admin.name : state.doctor.name,
      createdAt: new Date().toISOString(),
      formDisorders: [],
      formFields: JSON.stringify(STATE_formGrid),
      lid: state.selectedLocation,
    };

    createEdit.fid
      ? crud(state, [
          {
            db: state.db,
            collection: "forms",
            parameters: [{fid: payload.fid}, {$set: payload}],
            method: "updateOne",
          },
        ]).then(
          toast({
            title: "Form Updated!",
            description:
              "Your new form was successfully updated with your new fields!",
            status: "success",
            duration: 2000,
            isClosable: true,
            onCloseComplete: () => () => setCreateEdit(false),
          })
        )
      : crud(state, [
          {
            db: state.db,
            collection: "forms",
            parameters: [payload],
            method: "insertOne",
          },
        ]).then(
          toast({
            title: "Form Saved",
            description:
              "Your new form was successfully saved! It is now available to all clinicians within your organization to be sent to all patients.",
            status: "success",
            duration: 2000,
            isClosable: true,
            onCloseComplete: () => () => setCreateEdit(false),
          })
        );
  }

  return (
    <>
      <div className="px-2 py-6 flex overflow-hidden  h-full  flex-1">
        <div className="space-y-4 pr-8 flex-1  overflow-y-scroll">
          {Object.keys(STATE_formGrid).map((rowIdx, i) => {
            return (
              <>
                <div className="row flex space-x-4" id={rowIdx} key={i}>
                  {Object.keys(STATE_formGrid[rowIdx]).map((colIdx, j) => {
                    return (
                      <div
                        key={j + "j"}
                        onClick={() => {
                          STATE_fieldType &&
                            putFieldInDiv(rowIdx + "-" + colIdx);
                        }}
                        className={`${
                          STATE_fieldType && `cursor-pointer`
                        } bg-[#E6E5FF] bg-opacity-[40%] w-full h-[5.5rem] overflow-y-scroll py-4 rounded-[10px] px-4 relative`}
                        id={rowIdx + "-" + colIdx}
                      >
                        {STATE_formGrid[rowIdx][colIdx] &&
                        STATE_formGrid[rowIdx][colIdx].key != "Line Break" ? (
                          <AiFillEdit
                            onClick={() =>
                              STATE_setEditField([
                                STATE_formGrid[rowIdx][colIdx],
                                rowIdx,
                                colIdx,
                              ])
                            }
                            className="absolute right-2 top-2 text-med w-4 h-4 cursor-pointer"
                          ></AiFillEdit>
                        ) : (
                          <div
                            onClick={() => {
                              let state = {...STATE_formGrid};
                              Object.keys(STATE_formGrid[rowIdx]).length > 1
                                ? delete state[rowIdx][colIdx]
                                : delete state[rowIdx];
                              STATE_setFormGrid(state);
                            }}
                            className="absolute right-2 top-2 bg-light w-4 h-4 leading-4 text-[10px] text-white rounded-xl pl-[3px] pt-[-0.5px] cursor-pointer"
                          >
                            &mdash;
                          </div>
                        )}
                        {STATE_formGrid[rowIdx][colIdx] &&
                          createElementFromObject(
                            STATE_formGrid[rowIdx][colIdx]
                          )}
                      </div>
                    );
                  })}
                  {Object.keys(STATE_formGrid[rowIdx]).length < 4 && (
                    <div
                      onClick={() => {
                        STATE_setFormGrid((prevState) => ({
                          ...prevState,
                          [rowIdx]: {
                            ...prevState[rowIdx],
                            [Object.keys(STATE_formGrid[rowIdx]).at(-1) + 1]:
                              "",
                          },
                        }));
                      }}
                      className="!ml-6 bg-med text-white text-center h-[5.5rem] font-medium text-xl rounded-[10px] p-1.5 pt-8 cursor-pointer"
                    >
                      +
                    </div>
                  )}
                </div>
              </>
            );
          })}
          <div
            onClick={() => {
              STATE_setFormGrid((prevState) => {
                return {
                  ...prevState,
                  [parseInt(Object.keys(prevState).at(-1)) + 1]: {0: ""},
                };
              });
            }}
            className="!mt-6 bg-dark text-white text-center w-[6rem] font-medium text-xl rounded-[10px] mx-auto cursor-pointer"
          >
            +
          </div>
        </div>
        <div className="pl-12 mr-12 flex flex-col  flex-1">
          <div className="max-h-[22rem] flex flex-1 overflow-scroll w-[22rem] ">
            <div className="flex flex-col space-y-2 pr-2 pt-2  h-full  w-full ">
              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "heading" ? "heading" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "heading" ? `bg-[#CDCCFF]` : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/heading.png"
                  className="inline h-8 -mt-1 mr-1.5"
                />{" "}
                Heading
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "subheading" ? "subheading" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "subheading"
                    ? `bg-[#CDCCFF]`
                    : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/heading.png"
                  className="inline h-5 -mt-1 mr-1.5"
                />{" "}
                Subheading
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "paragraph" ? "paragraph" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "paragraph"
                    ? `bg-[#CDCCFF]`
                    : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/paragraph.png"
                  className="inline h-5 -mt-1 mr-1.5"
                />{" "}
                Paragraph
              </div>

              <div
                onClick={() =>
                  STATE_setFieldType(STATE_fieldType != "text" ? "text" : null)
                }
                className={` ${
                  STATE_fieldType == "text" ? `bg-[#CDCCFF]` : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/text.png"
                  className="inline h-6 -mt-1 mr-2"
                />{" "}
                Text
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(STATE_fieldType != "note" ? "note" : null)
                }
                className={` ${
                  STATE_fieldType == "note" ? `bg-[#CDCCFF]` : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/note.png"
                  className="inline h-7 -mt-1 mr-2.5"
                />{" "}
                Note
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "select" ? "select" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "select" ? `bg-[#CDCCFF]` : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/select.png"
                  className="inline h-6 -mt-1 mr-2"
                />{" "}
                Select Menu
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "checkboxes" ? "checkboxes" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "checkboxes"
                    ? `bg-[#CDCCFF]`
                    : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/checkboxes.png"
                  className="inline h-5 -mt-1 mr-2"
                />{" "}
                Check Boxes
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(STATE_fieldType != "date" ? "date" : null)
                }
                className={` ${
                  STATE_fieldType == "date" ? `bg-[#CDCCFF]` : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/date.png"
                  className="inline h-6 -mt-1 mr-1.5"
                />{" "}
                Date
              </div>

              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "linebreak" ? "linebreak" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "linebreak"
                    ? `bg-[#CDCCFF]`
                    : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/linebreak.png"
                  className="inline h-4 -mt-1 mr-2"
                />{" "}
                Line Break{" "}
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "upload" ? "upload" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "upload" ? `bg-[#CDCCFF]` : `bg-[#E6E5FF]`
                }  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-10 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <img
                  src="images/Clinic/upload.png"
                  className="inline h-7 -mt-1 mr-2"
                />{" "}
                File Upload
              </div>
              <div
                onClick={() =>
                  STATE_setFieldType(
                    STATE_fieldType != "slider" ? "slider" : null
                  )
                }
                className={` ${
                  STATE_fieldType == "slider" ? `bg-[#CDCCFF]` : `bg-[#E6E5FF]`
                } relative  cursor-pointer bg-opacity-[95%] rounded-3xl p-5 px-8 text-[#8F8CFF] text-xl font-medium text-center`}
              >
                <div className="absolute right-4 top-2 bg-orange-400 w-10 h-4 leading-4 text-[12px] text-white rounded-xl">
                  new
                </div>
                <img
                  src="images/Clinic/slider.png"
                  className="inline h-5 -mt-1 mr-1.5"
                />{" "}
                Slider
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between items-start gap-3  w-[22rem] pr-4 mt-5">
            <input
              type="text"
              placeholder="form-title"
              value={formTitle}
              onChange={(e) =>
                setFormTitle(e.target.value.replace(/[\\/:*?"<>|]/g, ""))
              }
              className="!mt-2 focus:ring-0 relative w-full cursor-pointer bg-off border-none bg-opacity-[15%] rounded-xl p-2 px-5 text-off text-lg font-medium"
            />
            <textarea
              ref={formDescription}
              rows={2}
              className="!mt-2 focus:ring-0 relative w-full cursor-pointer bg-off border-none bg-opacity-[15%] rounded-xl p-2 px-5 text-off text-sm font-normal"
              placeholder="form-description"
            >
              {createEdit?.formDescription}
            </textarea>
          </div>
          <div className="flex !mt-4 w-[22rem] gap-2 pr-4 ">
            <div
              onClick={() => {
                STATE_setPreviewForm(true);
              }}
              className="cursor-pointer w-1/2 rounded-xl bg-dark bg-opacity-75 py-2 px-4 text-white text-center text-md font-medium"
            >
              Preview
            </div>
            <div
              onClick={() => {
                !formTitle
                  ? toast({
                      title: "No Title",
                      description: "Please enter a title for your new form.",
                      status: "warning",
                      duration: 2000,
                      isClosable: true,
                    })
                  : saveForm(toast);
              }}
              className="cursor-pointer w-1/2 ml-2 rounded-xl bg-dark py-2 px-5 text-white text-center text-md font-medium"
            >
              Save
            </div>
          </div>
        </div>
      </div>
      {STATE_editField && (
        <EditFormField
          field={STATE_editField}
          updateForm={STATE_setFormGrid}
          close={STATE_setEditField}
        />
      )}
      {STATE_previewForm && (
        <PreviewForm
          form={STATE_formGrid}
          type="new"
          close={STATE_setPreviewForm}
          state={state}
        />
      )}
    </>
  );
}
