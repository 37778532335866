import {useEffect, useState} from "react";
import {
  Heading,
  Spinner,
  Box,
  Text,
  Button,
  Grid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import {crud, queryFaxFile, queryFaxes} from "../crudRequests";
import PreviewFile from "./PreviewFile";
import {AiOutlineClose} from "react-icons/ai";
import {crudStorageUpload} from "../storageRequests";
import swal from "sweetalert";
// import {Spinner} from "react-bootstrap";
import {useToast} from "@chakra-ui/react";
import {AllFaxes, IncomingFaxes} from "./AllFaxes";

const FaxesList = ({faxNumber, state, dispatch}) => {
  return (
    <Box overflow={"scroll"} w="full">
      <div className="p-5 pl-0 ml-10">
        <Heading as="h2" size="lg">
          Your faxes
        </Heading>
        <Text>{faxNumber}</Text>
        <Tabs>
          <TabList>
            <Tab>Incoming</Tab>
            <Tab>Outgoing</Tab>
          </TabList>

          <TabPanels w="full">
            <TabPanel>
              <AllFaxes
                direction="received"
                state={state}
                faxNumber={faxNumber}
                dispatch={dispatch}
              />
            </TabPanel>
            <TabPanel>
              <AllFaxes
                direction="sent"
                state={state}
                dispatch={dispatch}
                faxNumber={faxNumber}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </Box>
  );
};

export default FaxesList;
