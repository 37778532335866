import {
  Box,
  Flex,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Button,
  Badge,
  Tooltip,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  HStack,
  VStack,
  Text,
  Radio,
  FormControl,
  NumberInput,
  NumberInputField,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import {LuCalendarClock} from "react-icons/lu";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Yearly from "./Yearly";
import {MdOutlineTimelapse} from "react-icons/md";
import {useState, useRef} from "react";
import {lightFormat, addWeeks, addMonths, addYears} from "date-fns";

export default function Recurrent({
  setRecurrent,
  recurrent,
  selectedDate,
  setSelectedDate,
  recurrentCriteriaRef,
}) {
  let date = new Date(
    ...selectedDate
      .split("-")
      .map((e, i) => (i === 1 ? parseInt(e) - 1 : parseInt(e)))
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [occurrences, setOccurrences] = useState(10);
  const [endDate, setEndDate] = useState(
    lightFormat(addWeeks(date, 10), "yyyy-MM-dd")
  );

  const [rangeType, setRangeType] = useState("END_BY");
  recurrentCriteriaRef.current.range = {
    rangeType,
    occurrences,
    startDate: selectedDate,
    endDate,
  };

  return (
    <>
      <Accordion
        index={recurrent ? [0] : []}
        mx={"4"}
        my="6"
        allowToggle
        w={"full"}
      >
        <AccordionItem border="none">
          <Flex gap={4}>
            {
              <h2>
                <AccordionButton
                  rounded="md"
                  padding={0}
                  _hover={{
                    backgroundColor: "none",
                  }}
                >
                  <FormControl display="flex" alignItems="center">
                    <FormLabel
                      cursor={"pointer"}
                      fontWeight={400}
                      fontSize={"13px"}
                      color={recurrent ? "#4299E1" : "#7F7B91"}
                      htmlFor="recurrence"
                      mb="0"
                    >
                      Appointment Recurrence
                    </FormLabel>
                    <Switch
                      id="recurrence"
                      isChecked={recurrent}
                      onChange={(e) => {
                        setRecurrent(e.target.checked);
                      }}
                    />
                  </FormControl>
                </AccordionButton>
              </h2>
            }
          </Flex>
          <AccordionPanel pl="0" rounded={"md"} p="4" bg="white" mt="2" mr="6">
            <Flex
              mt="1"
              align={"self-start"}
              overflowY="hidden"
              direction={"column"}
              h="12rem"
            >
              <Badge
                fontSize="10px"
                rounded="md"
                p="1"
                px="2"
                mb="2"
                colorScheme="blue"
              >
                <Icon as={LuCalendarClock} fontSize="15px" mt="-0.5" />{" "}
                Recurrence pattern
              </Badge>
              <Box flex={1} overflowY="scroll" w="full">
                <Tabs
                  isLazy
                  orientation="vertical"
                  textAlign={"left"}
                  my="2"
                  colorScheme="blue"
                  index={tabIndex}
                  onChange={(index) => {
                    setTabIndex(index);
                    if (index === 0)
                      setEndDate(lightFormat(addWeeks(date, 10), "yyyy-MM-dd"));
                    if (index === 1)
                      setEndDate(
                        lightFormat(addMonths(date, 10), "yyyy-MM-dd")
                      );
                    if (index === 2)
                      setEndDate(lightFormat(addYears(date, 10), "yyyy-MM-dd"));
                  }}
                >
                  <TabList
                    w="5rem"
                    borderRight={"1px solid"}
                    borderColor={"gray.400"}
                    h="7rem"
                  >
                    <Tab fontSize={"13px"}>Weekly</Tab>
                    <Tab fontSize={"13px"}>Monthly</Tab>
                    <Tab fontSize={"13px"}>Yearly</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Weekly {...{date, recurrentCriteriaRef}} />
                    </TabPanel>
                    <TabPanel>
                      <Monthly {...{date, recurrentCriteriaRef}} />
                    </TabPanel>
                    <TabPanel>
                      <Yearly {...{date, recurrentCriteriaRef}} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Flex>
            <Flex
              h="9rem"
              mt="1"
              align={"self-start"}
              overflowY="hidden"
              direction={"column"}
            >
              <Badge
                fontSize="10px"
                rounded="md"
                p="1"
                px="2"
                mb="4"
                colorScheme="blue"
              >
                <Icon as={MdOutlineTimelapse} fontSize="15px" mt="-0.5" />{" "}
                Recurrence Range
              </Badge>
              <Box flex={1} overflowY="scroll" w="full">
                <Flex fontSize={"sm"} gap="2" align={"flex-start"}>
                  <Flex align={"center"} gap="2">
                    <Text>Start:</Text>
                    <Input
                      size={"xs"}
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      type="date"
                    ></Input>
                  </Flex>

                  <VStack gap={3} align={"self-start"}>
                    <Radio
                      size={"sm"}
                      onChange={(e) => {
                        setRangeType("END_BY");
                      }}
                      isChecked={rangeType === "END_BY"}
                      value="endBy"
                    >
                      <Text>End by:</Text>
                    </Radio>
                    <Radio
                      size={"sm"}
                      onChange={(e) => {
                        setRangeType("END_AFTER");
                      }}
                      isChecked={rangeType === "END_AFTER"}
                      value="endAfter"
                    >
                      <Text>End after:</Text>
                    </Radio>
                  </VStack>
                  <VStack>
                    <FormControl
                      display={"flex"}
                      gap={2}
                      alignItems={"center"}
                      flex={1}
                      id="dateEnd"
                      isDisabled={rangeType !== "END_BY"}
                    >
                      <Input
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        size={"xs"}
                        type="date"
                      ></Input>
                    </FormControl>

                    <FormControl
                      display={"flex"}
                      gap={2}
                      alignItems={"center"}
                      flex={1}
                      id="recurrence"
                      isDisabled={rangeType !== "END_AFTER"}
                    >
                      <NumberInput
                        size="xs"
                        w="10"
                        max={50}
                        min={1}
                        value={occurrences}
                        onBlur={() => {
                          if (!occurrences)
                            setOccurrences((prev) => {
                              return 1;
                            });
                        }}
                        onChange={(e) => {
                          setOccurrences((prev) => {
                            if (e.match(/^\d{0,2}$/)) {
                              let v = e.replace(/[-+,.]/g, "");
                              if (Number(v) > 50) return prev;
                              return v;
                            } else return prev;
                          });
                        }}
                      >
                        <NumberInputField textAlign={"end"} p="1" />
                      </NumberInput>

                      <Text>occurrences</Text>
                    </FormControl>
                  </VStack>
                </Flex>
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
