import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  Button,
} from "@chakra-ui/react";
import PaymentInformation from "../../appointments/payment";
import BookNonSessionService from "./BookNonSessionService";
export default function EditOrCreate({
  showEditOrCreateModal,
  setShowEditOrCreateModal,
  selectedItem,
  setSelectedItem,
  patient,
  dashState,
  dispatch,
  setNsServices,
}) {
  function onDispatch({type, payload}) {
    if (type === "UPDATE_APPOINTMENT") {
      setNsServices((prev) => {
        return prev.map((e) =>
          e.aid === payload.aid ? {...e, ...payload} : e
        );
      });
      setSelectedItem((prev) =>
        prev?.aid === payload.aid ? {...prev, ...payload} : prev
      );
    }
  }

  return (
    <Drawer
      isOpen={showEditOrCreateModal}
      placement="right"
      size={"md"}
      onClose={() => {
        setShowEditOrCreateModal(false);
        setSelectedItem(null);
      }}
      trapFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottomWidth="1px"
          color={"#1A202C"}
          fontWeight={600}
        >
          {selectedItem ? "Payment Information" : "Non-Session Service"}
        </DrawerHeader>

        <DrawerBody p={0} zIndex="docked">
          {selectedItem ? (
            <Box m="8">
              <PaymentInformation
                appointment={selectedItem}
                {...{state: dashState}}
                dispatch={onDispatch}
              />
            </Box>
          ) : (
            <BookNonSessionService
              {...{
                patient,
                state: dashState,
                dispatch,
                setNsServices,
                setShowEditOrCreateModal,
                setSelectedItem,
              }}
            />
          )}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            mr={3}
            px="10"
            onClick={() => {
              setShowEditOrCreateModal(false);
              setSelectedItem(null);
            }}
          >
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
