import {crud} from "../../../../crudRequests";

export async function fetchSummaries(setSummaries, dashState, patient, toast) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "summaries",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);
    setSummaries(res.data[0]);
  } catch (err) {
    console.log(err);
    /* toast({
      title: "Failed to fetch summary data",
      description:
        err?.message || "An error occurred while fetching summary data.",
      status: "error",
      isClosable: true,
    });*/
  }
}
