import {crud} from "../../../../crudRequests";

export async function fetchNotes(
  setNotes,
  setAdmins,
  dashState,
  patient,
  toast
) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "patientNotes",
        parameters: [{pid: patient.pid}],
        method: "find",
        findCursor: [{method: "sort", arguments: [{createdAt: -1}]}],
      },
      {
        db: dashState.db,
        collection: "admins",
        parameters: [{}],
        method: "find",
      },
    ]);
    setNotes(res.data[0] || []);
    setAdmins(res.data[1] || []);
  } catch (err) {
    toast({
      title: "Failed to fetch notes",
      description: err?.message || "An error occurred while fetching notes.",
      status: "error",
      isClosable: true,
    });
  }
}
