import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  useToast,
  Checkbox,
  Flex,
  PopoverBody,
  FormErrorMessage,
  Textarea,
  PopoverFooter,
  ButtonGroup,
  Popover,
  useDisclosure,
  PopoverContent,
  Portal,
  PopoverTrigger,
  Stack,
  Spinner,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {useContext, useEffect, useRef, useState} from "react";
import {updatePatient} from "./helpers/updatePatient";
import {SearchableSelect} from "../../SearchableSelect";
import {Field, Form, Formik} from "formik";
import {crud} from "../../../crudRequests";
import {v4} from "uuid";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

function EditReferralDialog({clinician, dashState, refetchClinicians}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const onSave = async (data) => {
    setIsLoading(true);
    delete data._id;
    await crud(dashState, [
      {
        db: dashState.db || "blh",
        collection: "refClinicians",
        parameters: [{refId: clinician.refId}, {$set: {...data}}],
        method: "updateOne",
      },
    ]);
    refetchClinicians();
    setIsLoading(false);

    return onClose();
  };
  return (
    <div>
      <Button px="12" colorScheme="blue" m="12" onClick={onOpen}>
        Edit
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit referring clinician information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={clinician}
              onSubmit={(values, {setSubmitting}) => {
                onSave(values);
                setSubmitting(false);
              }}
            >
              {({isSubmitting}) => (
                <Form>
                  <VStack spacing={4}>
                    <Field name="name">
                      {({field}) => (
                        <FormControl>
                          <FormLabel>Name</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="phone">
                      {({field}) => (
                        <FormControl>
                          <FormLabel>Phone</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="fax">
                      {({field}) => (
                        <FormControl>
                          <FormLabel>Fax</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email">
                      {({field}) => (
                        <FormControl>
                          <FormLabel>Email</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="CPSONumber">
                      {({field}) => (
                        <FormControl>
                          <FormLabel>CPSO Number</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="billingNumber">
                      {({field}) => (
                        <FormControl>
                          <FormLabel>Billing Number</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="address">
                      {({field}) => (
                        <FormControl>
                          <FormLabel>Address</FormLabel>
                          <Textarea {...field} />
                        </FormControl>
                      )}
                    </Field>
                  </VStack>
                  <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="blue"
                      type="submit"
                      isLoading={isLoading}
                      disabled={isLoading}
                      loadingText="Saving..."
                    >
                      {isLoading ? <Spinner size="sm" /> : "Save changes"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

function ReferringClinicianSelect(props) {
  const fields = {
    name: "",
    phone: "",
    fax: "",
    email: "",
    CPSONumber: "",
    billingNumber: "",
    address: "",
  };
  const [formKey, setFormKey] = useState(Date.now());
  const firstFieldRef = useRef(null);
  const {onOpen, onClose, isOpen} = useDisclosure();

  return (
    <>
      <Stack direction="row">
        {props.selectedClinician ? (
          <EditReferralDialog
            clinician={props.selectedClinician}
            dashState={props.dashState}
            setClinicians={props.setClinicians}
            clinicians={props.clinicians}
            setSelectedClinician={props.setSelectedClinician}
            refetchClinicians={props.refetchClinicians}
          />
        ) : (
          <Popover
            placement="right-start"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            initialFocusRef={firstFieldRef}
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Button
                isDisabled={props.disabled}
                px="12"
                colorScheme="blue"
                m="12"
              >
                Add
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <Formik
                  key={formKey}
                  initialValues={fields}
                  onSubmit={async (values, {setSubmitting, resetForm}) => {
                    const newClinician = {...values, refId: v4()};
                    await crud(props.dashState, [
                      {
                        db: props.dashState.db,
                        collection: "refClinicians",
                        parameters: [newClinician],
                        method: "insertOne",
                      },
                    ]);
                    props.setClinicians([...props.clinicians, newClinician]);
                    props.setSelectedClinician(newClinician);
                    setSubmitting(false);
                    resetForm();
                    onClose();
                  }}
                >
                  {({values, setFieldValue, isSubmitting}) => (
                    <Form>
                      <PopoverBody>
                        {Object.keys(fields).map((fieldName, index) => (
                          <Field name={fieldName} key={index}>
                            {({field, form}) => (
                              <FormControl
                                isInvalid={
                                  form.errors[fieldName] &&
                                  form.touched[fieldName]
                                }
                                my="2"
                              >
                                <Input
                                  {...field}
                                  placeholder={
                                    fieldName.charAt(0).toUpperCase() +
                                    fieldName.slice(1)
                                  }
                                  variant="flushed"
                                />
                                <FormErrorMessage>
                                  {form.errors[fieldName]}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        ))}
                      </PopoverBody>
                      <PopoverFooter border="0">
                        <Flex justifyContent="end">
                          <ButtonGroup size="sm">
                            <Button
                              colorScheme="blue"
                              variant="ghost"
                              onClick={onClose}
                            >
                              Go Back
                            </Button>
                            <Button
                              isLoading={isSubmitting}
                              colorScheme="blue"
                              type="submit"
                              disabled={isSubmitting}
                              loadingText="Saving..."
                            >
                              Add ref. clinician
                            </Button>
                          </ButtonGroup>
                        </Flex>
                      </PopoverFooter>
                    </Form>
                  )}
                </Formik>
              </PopoverContent>
            </Portal>
          </Popover>
        )}

        <VStack spacing={4}>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
            gap={6}
          >
            <SearchableSelect
              label="Select ref. clinician"
              placeholder="-"
              options={[
                {value: "", label: "-"},
                ...props.clinicians.map((clinician) => ({
                  value: clinician,
                  label: clinician.name,
                })),
              ]}
              onChange={(option) => {
                props.setSelectedClinician(option.value);
              }}
              value={props?.selectedClinician}
              isDisabled={props.disabled}
            />
            <FormControl id="referringClinicianPhone">
              <FormLabel>Ref. Clinician Phone</FormLabel>
              <Input isDisabled value={props.selectedClinician?.phone || ""} />
            </FormControl>
            <FormControl id="referringClinicianFax">
              <FormLabel>Ref. Clinician Fax</FormLabel>
              <Input isDisabled value={props.selectedClinician?.fax || ""} />
            </FormControl>
            <FormControl id="referringClinicianEmail">
              <FormLabel>Ref. Clinician Email</FormLabel>
              <Input isDisabled value={props.selectedClinician?.email || ""} />
            </FormControl>
          </Grid>
          <Flex gap={6} justify={"center"}>
            <FormControl id="referringClinicianCPSOnumber">
              <FormLabel>Ref. Clinician CPSO#</FormLabel>
              <Input
                isDisabled
                value={props.selectedClinician?.CPSONumber || ""}
              />
            </FormControl>
            <FormControl id="referringClinicianBillingNumber">
              <FormLabel>Ref. Clinician Billing #</FormLabel>
              <Input
                isDisabled
                value={props.selectedClinician?.billingNumber || ""}
              />
            </FormControl>
            <FormControl id="referringClinicianAddress">
              <FormLabel>Ref. Clinician Address</FormLabel>
              <Input
                isDisabled
                value={props.selectedClinician?.address || ""}
              />
            </FormControl>
          </Flex>
        </VStack>
      </Stack>
    </>
  );
}

const fetchAndSetClinicians = async (
  dashState,
  patient,
  setClinicians,
  setSelectedClinician,
  setSelectedGP
) => {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "refClinicians",
        parameters: [{}],
        method: "find",
      },
    ]);

    if (!res?.data) return;

    const cliniciansList = res.data[0];
    // Sort the clinicians list by name
    const sortedCliniciansList = cliniciansList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setClinicians(sortedCliniciansList);

    const refClinician = sortedCliniciansList.find(
      (cl) => cl.refId === patient.refClinicianId
    );
    if (refClinician) setSelectedClinician(refClinician);

    if (patient.referringClinicianIsGP) {
      setSelectedGP(refClinician);
    } else {
      const refGp = sortedCliniciansList.find(
        (cl) => cl.refId === patient.refGpId
      );
      if (refGp) setSelectedGP(refGp);
    }
  } catch (error) {
    console.error("Error fetching clinicians:", error);
  }
};

export default function ReferralHistory(props) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [clinicians, setClinicians] = useState([]);
  const [selectedClinician, setSelectedClinician] = useState("");
  const [selectedGP, setSelectedGP] = useState("");

  useEffect(() => {
    fetchAndSetClinicians(
      dashState,
      patient,
      setClinicians,
      setSelectedClinician,
      setSelectedGP
    );
  }, []);

  useEffect(() => {
    if (patient.referringClinicianIsGP) {
      setPatient({
        ...patient,
        refClinicianId: selectedClinician.refId,
        refGpId: selectedClinician.refId,
      });
      setSelectedGP(selectedClinician);
    } else {
      setPatient({
        ...patient,
        refClinicianId: selectedClinician.refId,
        refGpId: selectedGP.refId,
      });
    }
  }, [selectedGP, selectedClinician]);

  return (
    <>
      <form
        onSubmit={(e) =>
          updatePatient(
            e,
            {
              ...patient,
              refClinicianId: patient.refClinicianId || "",
              refGpId: patient.refGpId || "",
            },
            dashState,
            setIsLoading,
            toast,
            dispatch
          )
        }
      >
        <VStack spacing={4}>
          <ReferringClinicianSelect
            clinicians={clinicians}
            setClinicians={setClinicians}
            selectedClinician={selectedClinician}
            setSelectedClinician={(payload) => {
              setPatient({
                ...patient,
                refClinicianId: selectedClinician?.refId || "",
              });
              setSelectedClinician(payload);
            }}
            dashState={dashState}
            refetchClinicians={() => {
              fetchAndSetClinicians(
                dashState,
                patient,
                setClinicians,
                setSelectedClinician,
                setSelectedGP
              );
            }}
          />
          <FormControl is="referringClinicianIsGP">
            <Checkbox
              isChecked={patient.referringClinicianIsGP}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  referringClinicianIsGP: e.target.checked,
                  refClinicianGP: selectedClinician.refId,
                });
                if (e.target.checked === true) {
                  setSelectedGP(selectedClinician);
                } else {
                  setSelectedGP("");
                }
              }}
              my="3"
            >
              Is the referring clinician also the patient's <b>GP</b>?
            </Checkbox>
          </FormControl>
          <ReferringClinicianSelect
            clinicians={clinicians}
            setClinicians={setClinicians}
            selectedClinician={selectedGP}
            setSelectedClinician={setSelectedGP}
            dashState={dashState}
            disabled={patient.referringClinicianIsGP}
            refetchClinicians={() => {
              fetchAndSetClinicians(
                dashState,
                patient,
                setClinicians,
                setSelectedClinician,
                setSelectedGP
              );
            }}
          />

          <Button
            isLoading={isLoading && true}
            colorScheme="blue"
            type="submit"
            my={4}
            px="10"
            disabled={isLoading}
            loadingText="Saving..."
          >
            {isLoading ? <Spinner size="sm" /> : "Save"}
          </Button>
        </VStack>
      </form>
    </>
  );
}
