import React, {useState, useEffect, useRef, useReducer} from "react";
import {crud} from "../../crudRequests";
import SignatureModal from "./SignatureModal";
import {PiSignatureBold} from "react-icons/pi";
import {saveAs} from "file-saver";
import {TbFileImport, TbFileExport} from "react-icons/tb";
import {useToast} from "@chakra-ui/react";
export default function Signature({schState, dispatch, doctor, setSignature}) {
  let user = doctor || schState.admin || schState.doctor;
  let id = doctor || schState?.doctor ? "did" : "id";
  let idValue = (doctor || schState?.doctor)?.did || schState.admin?.id;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(false);
  const toast = useToast();
  const sigRef = useRef(null);
  const importRef = useRef(null);
  const onSave = () => {
    setLoading(true);
    if (setSignature) {
      setSignature(value);
      setLoading(false);
      setShowModal(false);
    } else {
      crud(schState, [
        {
          db: schState.db,
          collection: doctor || schState?.doctor ? "doctors" : "admins",
          parameters: [{[id]: idValue}, {$set: {signature: value}}],
          method: "findOneAndUpdate",
        },
      ]).then((res) => {
        setShowModal(false);
        setLoading(false);
        dispatch({
          type: id === "did" ? "UPDATE_DOCTOR" : "UPDATE_ADMIN",
          payload: {[id]: idValue, signature: value},
        });
      });
    }
  };

  function onImport(ele) {
    try {
      setLoading(true);
      let files = ele.target.files;
      let file = files[0];
      if (!file?.type.match(/image.*/i)) return;
      var reader = new FileReader();

      reader.addEventListener("load", (e) => {
        setValue(e.target.result);
        setLoading(false);
        if (setSignature) {
          setSignature(e.target.result);
          setLoading(false);
        } else {
          crud(schState, [
            {
              db: schState.db,
              collection: doctor || schState?.doctor ? "doctors" : "admins",
              parameters: [
                {[id]: idValue},
                {$set: {signature: e.target.result}},
              ],
              method: "findOneAndUpdate",
            },
          ]).then((res) => {
            setLoading(false);
            dispatch({
              type: id === "did" ? "UPDATE_DOCTOR" : "UPDATE_ADMIN",
              payload: {[id]: idValue, signature: e.target.result},
            });
          });
        }
      });
      reader.readAsDataURL(file);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      {showModal && (
        <SignatureModal
          {...{
            schState,
            loading,
            dispatch,
            setShowModal,
            onSave,
            sigRef,
            signatureValue: value,
            saveSignature: setValue,
          }}
        />
      )}
      <div className="my-10 flex items-start">
        <div className="flex flex-col items-center space-y-7">
          <h1 className="text-[rgba(87,84,255,0.75)] text-xl font-semibold">
            User Signature
          </h1>
          <div className="">
            <div className="border-b-2 flex justify-center items-center w-40 overflow-hidden border-gray-500 p-2 h-14">
              {user?.signature && (
                <img
                  src={user?.signature}
                  alt="signature"
                  className="w-full object-contain"
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center mt-1 space-x-[2px]">
          <div className="relative group">
            <span
              className="text-gray-700/40 hover:text-gray-700 flex justify-center items-center  rounded-lg p-[2px]  text-[1.2rem] cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              <PiSignatureBold />
            </span>
            <p className="absolute overflow-y-scroll bottom-full left-0 show  hidden group-hover:block text-center text-white rounded-[5px] px-2 bg-gray-700 text-xs p-1 sbar2">
              <span className="">{`Create Signature`}</span>
            </p>
          </div>
          <label
            className="relative group  cursor-pointer flex justify-center items-center"
            htmlFor="import"
            onClick={() => {
              importRef.current.click();
            }}
          >
            <input
              onChange={(e) => {
                !loading && onImport(e);
              }}
              ref={importRef}
              className="absolute w-0 h-0  opacity-0 z-0 cursor-pointer"
              type="file"
              name="import"
              accept=".jpg, .jpeg, .png"
            />
            <span className="text-gray-700/40 z-0 hover:text-gray-700 flex justify-center items-center  rounded-lg p-[2px]  text-[1.2rem] cursor-pointer">
              <TbFileImport />
            </span>
            <p className="absolute overflow-y-scroll bottom-full left-0 show  hidden group-hover:block text-center text-white rounded-[5px] px-2 bg-gray-700 text-xs p-1 sbar2">
              <span className="">{`Import Signature`}</span>
            </p>
          </label>

          {user?.signature && (
            <div className="relative group">
              <span
                className="text-gray-700/40 hover:text-gray-700 flex justify-center items-center  rounded-lg p-[2px]  text-[1.2rem] cursor-pointer"
                onClick={() => {
                  try {
                    let base64Data = user?.signature.split(",")[1];
                    var byteCharacters = atob(base64Data);

                    // Crea un array buffer y un Blob a partir del array de bytes
                    var arrayBuffer = new ArrayBuffer(byteCharacters.length);
                    var uint8Array = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < byteCharacters.length; i++) {
                      uint8Array[i] = byteCharacters.charCodeAt(i);
                    }

                    var blob = new Blob([arrayBuffer], {type: "image/png"});

                    saveAs(blob, `${user.name} signature.png`);
                  } catch (e) {
                    toast({
                      title: `Invalid signature!`,
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                }}
              >
                <TbFileExport />
              </span>
              <p className="absolute overflow-y-scroll bottom-full left-0 show  hidden group-hover:block text-center text-white rounded-[5px] px-2 bg-gray-700 text-xs p-1 sbar2">
                <span className="">{`Export Signature`}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
