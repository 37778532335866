import {Icon, Box, Badge, Tabs, TabList, Tab, VStack} from "@chakra-ui/react";
import {AiFillCheckCircle, AiOutlineCheckCircle} from "react-icons/ai";
import {useState} from "react";

export default function AssignedAndCompletedForms({
  completedForms,
  setSelectedForm,
  assignedForms,
}) {
  const [tabIndexCompleted, setTabIndexCompleted] = useState(null);
  const [tabIndexAssigned, setTabIndexAssigned] = useState(null);

  return (
    <Box h="40vh" w="20rem" mt="1"  overflowY="scroll">
      <Badge
        fontSize="12px"
        rounded="md"
        p="1"
        px="2"
        mb="4"
        colorScheme="green"
      >
        <Icon as={AiFillCheckCircle} fontSize="15px" mt="-0.5" />{" "}
        {completedForms?.length == 0 && "No "} Completed Forms
      </Badge>
      {completedForms?.length > 0 && (
        <Tabs
          orientation="vertical"
          textAlign={"left"}
          my="2"
          colorScheme="green"
          index={tabIndexCompleted}
          onChange={(i) => {
            setTabIndexCompleted(i);
            setTabIndexAssigned(null);
          }}
        >
          <TabList>
            <VStack align="stretch">
              {completedForms?.map((form, i) => {
                return (
                  <Tab
                    sx={{justifyContent: "flex-start"}}
                    onClick={() => {
                      setSelectedForm(form);
                    }}
                  >
                    {form.formTitle.length <= 30
                      ? form.formTitle
                      : form.formTitle.substr(0, 30).trim() + "..."}
                  </Tab>
                );
              })}
            </VStack>
          </TabList>
        </Tabs>
      )}
      <br />
      <Badge
        fontSize="12px"
        rounded="md"
        p="1"
        px="2"
        mb="4"
        colorScheme="blue"
      >
        <Icon as={AiOutlineCheckCircle} fontSize="15px" mt="-0.5" />{" "}
        {assignedForms?.length == 0 && "No "} Assigned Forms
      </Badge>
      {assignedForms?.length > 0 && (
        <Tabs
          orientation="vertical"
          my="2"
          colorScheme="blue"
          index={tabIndexAssigned}
          onChange={(i) => {
            setTabIndexAssigned(i);
            setTabIndexCompleted(null);
          }}
        >
          <TabList>
            <VStack align="stretch">
              {assignedForms?.map((form, i) => {
                return (
                  <Tab
                    sx={{justifyContent: "flex-start"}}
                    onClick={() => {
                      setSelectedForm(form);
                    }}
                  >
                    {form.formTitle.length <= 30
                      ? form.formTitle
                      : form.formTitle.substr(0, 30).trim() + "..."}
                  </Tab>
                );
              })}
            </VStack>
          </TabList>
        </Tabs>
      )}{" "}
    </Box>
  );
}
