import {
  Button,
  ButtonGroup,
  Thead,
  Th,
  Table,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  CloseButton,
  Select,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

export default function Filters({query, setQuery}) {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [instantQuery, setInstantQuery] = useState(false);
  const [amountType, setAmountType] = useState("overallOutstandingBalance");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");

  const setDateQuery = () => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      fromDate: fromDate,
      toDate: toDate,
    }));
  };

  useEffect(() => {
    if (instantQuery) {
      setDateQuery();
      setInstantQuery(false);
    }
  }, [instantQuery]);

  return (
    <>
      <Box mb="4" py="2" bg="gray.50" rounded="md">
        <Table border="hidden">
          <Thead>
            <Th>Filter By Date Range:</Th>
          </Thead>
        </Table>
        <ButtonGroup size="sm" mx="3">
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              fromDate == new Date().toISOString().split("T")[0] &&
              toDate ==
                new Date(new Date().setMonth(new Date().getMonth() + 3))
                  .toISOString()
                  .split("T")[0]
                ? "blue.800"
                : null
            }
            onClick={() => {
              setFromDate(new Date().toISOString().split("T")[0]);
              setToDate(
                new Date(new Date().setMonth(new Date().getMonth() + 3))
                  .toISOString()
                  .split("T")[0]
              );
              setInstantQuery(true);
            }}
          >
            Next 3 months
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              fromDate == new Date().toISOString().split("T")[0] &&
              toDate ==
                new Date(new Date().setMonth(new Date().getMonth() + 1))
                  .toISOString()
                  .split("T")[0]
                ? "blue.800"
                : null
            }
            onClick={() => {
              setFromDate(new Date().toISOString().split("T")[0]);
              setToDate(
                new Date(new Date().setMonth(new Date().getMonth() + 1))
                  .toISOString()
                  .split("T")[0]
              );
              setInstantQuery(true);
            }}
          >
            Next 4 weeks
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              toDate == new Date().toISOString().split("T")[0] &&
              fromDate ==
                new Date(new Date().setMonth(new Date().getMonth() - 1))
                  .toISOString()
                  .split("T")[0]
                ? "blue.800"
                : null
            }
            onClick={() => {
              setToDate(new Date().toISOString().split("T")[0]);
              setFromDate(
                new Date(new Date().setMonth(new Date().getMonth() - 1))
                  .toISOString()
                  .split("T")[0]
              );
              setInstantQuery(true);
            }}
          >
            Last 4 weeks
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              toDate == new Date().toISOString().split("T")[0] &&
              fromDate ==
                new Date(new Date().setMonth(new Date().getMonth() - 3))
                  .toISOString()
                  .split("T")[0]
                ? "blue.800"
                : null
            }
            onClick={() => {
              setToDate(new Date().toISOString().split("T")[0]);
              setFromDate(
                new Date(new Date().setMonth(new Date().getMonth() - 3))
                  .toISOString()
                  .split("T")[0]
              );
              setInstantQuery(true);
            }}
          >
            Last 3 months
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              toDate == new Date().toISOString().split("T")[0] &&
              fromDate ==
                new Date(new Date().setMonth(new Date().getMonth() - 6))
                  .toISOString()
                  .split("T")[0]
                ? "blue.800"
                : null
            }
            onClick={() => {
              setToDate(new Date().toISOString().split("T")[0]);
              setFromDate(
                new Date(new Date().setMonth(new Date().getMonth() - 6))
                  .toISOString()
                  .split("T")[0]
              );
              setInstantQuery(true);
            }}
          >
            Last 6 months
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              toDate == new Date().toISOString().split("T")[0] &&
              fromDate ==
                new Date(new Date().setMonth(new Date().getMonth() - 12))
                  .toISOString()
                  .split("T")[0]
                ? "blue.800"
                : null
            }
            onClick={() => {
              setToDate(new Date().toISOString().split("T")[0]);
              setFromDate(
                new Date(new Date().setMonth(new Date().getMonth() - 12))
                  .toISOString()
                  .split("T")[0]
              );
              setInstantQuery(true);
            }}
          >
            Last year
          </Button>{" "}
        </ButtonGroup>
        <Flex mx="6" my="2">
          <InputGroup size="sm" mr="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="5">
              From:
            </InputLeftElement>
            <Input
              type="date"
              id="fromDate"
              size="sm"
              pl="16"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" ml="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="5">
              To:
            </InputLeftElement>
            <Input
              type="date"
              id="toDate"
              size="sm"
              pl="16"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </InputGroup>
          <Button
            colorScheme="blue"
            variant="ghost"
            ml="4"
            mt="-1"
            onClick={setDateQuery}
          >
            Filter
          </Button>
          {query.fromDate && query.toDate && (
            <Button
              colorScheme="blue"
              variant="ghost"
              ml="2"
              mt="-1"
              onClick={() => {
                setToDate(null);
                setFromDate(null);
                setInstantQuery(true);
              }}
            >
              <CloseButton size="sm" ml="2" color="blue.600" /> Clear
            </Button>
          )}
        </Flex>
      </Box>

      <Box mb="6" py="2" bg="gray.50" rounded="md">
        <Table border="hidden">
          <Thead>
            <Th>Filter By ($) Amount:</Th>
          </Thead>
        </Table>
        <Flex mx="6" my="2">
          <Select
            mr="4"
            w="700px"
            id="amountType"
            size="sm"
            value={amountType}
            onChange={(e) => setAmountType(e.target.value)}
          >
            <option value="overallOutstandingBalance">
              Overall Outstanding Balance
            </option>
            <option value="patientOutstandingBalance">
              Patient Outstanding Balance
            </option>
            <option value="tppOutstandingBalance">
              TPP Outstanding Balance
            </option>
            <option value="overallCostPaid">Overall Cost Paid</option>
            <option value="patientCostPaid">Patient Cost Paid</option>
            <option value="tppCostPaid">TPP Cost Paid</option>
            <option value="overallServiceCost">Overall Service Cost</option>
            <option value="patientServiceCost">Patient Service Cost</option>
            <option value="tppServiceCost">TPP Service Cost</option>
          </Select>
          <InputGroup size="sm" mr="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="2rem">
              From: &nbsp; $
            </InputLeftElement>
            <Input
              id="fromAmount"
              type="number"
              size="sm"
              pl="5.25rem"
              placeholder="Min"
              value={fromAmount}
              onChange={(e) => setFromAmount(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" ml="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="5">
              To: &nbsp; $
            </InputLeftElement>
            <Input
              id="toAmount"
              type="number"
              size="sm"
              pl="16"
              placeholder="Max"
              value={toAmount}
              onChange={(e) => setToAmount(e.target.value)}
            />
          </InputGroup>
          <Button
            colorScheme="blue"
            variant="ghost"
            ml="4"
            mt="-1"
            onClick={() =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                amountType: amountType,
                fromAmount: fromAmount,
                toAmount: toAmount,
              }))
            }
          >
            Filter
          </Button>
          {(query.fromAmount || query.toAmount) && (
            <Button
              colorScheme="blue"
              variant="ghost"
              ml="2"
              mt="-1"
              onClick={() => {
                setFromAmount("");
                setToAmount("");
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  amountType: amountType,
                  fromAmount: null,
                  toAmount: null,
                }));
              }}
            >
              <CloseButton size="sm" ml="2" color="blue.600" /> Clear
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
}
