import {excelTransactionsReport} from "../../../crudRequests";

import {saveAs} from "file-saver";

export default async function getExcel({state, table, location, toast}) {
  excelTransactionsReport(state, table, location)
    .then((res) => {
      saveAs(
        new Blob([new Uint8Array(res.data.buffer.data).buffer]),
        `Transactions report for ${location.name} clinic.xlsx`
      );
    })
    .catch((err) => {
      toast({
        title: "Unable to create excel report",

        status: "error",
        isClosable: true,
        duration: 2000,
      });
    });
}
