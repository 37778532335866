import axios from "axios";
import {url} from "../../../../crudRequests";

export async function fetchInteractions(
  state,
  pastPrescriptions,
  selectedDrug
) {
  let configService = {
    method: "POST",
    url: `${url}/getDrugInteractions`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: {pastPrescriptions, selectedDrug},
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}

export default async function getInteractions(
  state,
  pastPrescriptions,
  selectedDrug,
  setDrugInteractions
) {
  let res;
  try {
    if (pastPrescriptions?.length === 0 || !pastPrescriptions) {
      return {success: true, data: []};
    }
    res = await fetchInteractions(state, pastPrescriptions, selectedDrug);

    const arr = [
      ...(res.data?.interactions || []),
      ...(res.data?.warnings || []),
    ];
    if (arr?.flat()?.length === 0) {
      return {success: true, data: []};
    }
    return {success: true, data: arr};
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: err?.message || "unable to find interactions",
    };
    // toast({
    //   title: "Failed to fetch prescriptions data",
    //   description:
    //     err?.message || "An error occurred while fetching prescriptions data.",
    //   status: "error",
    //   isClosable: true,
    // });
  }
}
