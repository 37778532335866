import PatientMembershipTable from "./PatientMembershipTable";
import {PatientMembershipModal} from "./PatientMembershipModal";
import {useContext, useState, useEffect, useMemo} from "react";
import {Stack} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";

export function PatientMembership() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [refetch, setRefetch] = useState(false);
  const [patientMemberships, setPatientMemberships] = useState(
    patient?.memberships || []
  );
  useEffect(() => {
    if (refetch) {
      const updatedPatient = dashState.patients.find(
        (statePatient) => statePatient.pid === patient.pid
      );
      setPatientMemberships([...updatedPatient.memberships]);
      setRefetch(false);
    }
  }, [refetch]);
  return (
    <Stack direction="column">
      <PatientMembershipModal
        schState={dashState}
        defaultOpen={!patientMemberships.length}
        w="50%"
        patient={patient}
        dispatch={dispatch}
        setPatient={setPatient}
        setPatientMemberships={setPatientMemberships}
        patientMemberships={patientMemberships}
      />
      <PatientMembershipTable
        patientMemberships={patientMemberships}
        schState={dashState}
        patient={patient}
        dispatch={dispatch}
        setPatientRefetch={setRefetch}
      />
    </Stack>
  );
}
