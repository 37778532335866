import {
  Box,
  AvatarGroup,
  Avatar,
  Text,
  Flex,
  VStack,
  Badge,
  Tooltip,
  Tag,
  Icon,
  Button,
  Spinner,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  useToast,
  Center,
} from "@chakra-ui/react";
import Swal from "sweetalert2";

import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useCallback, useContext, useMemo, useState} from "react";
import dateString from "./helpers/dateString";

import DescriptionModal from "./DescriptionModal";
import {BiSolidEdit} from "react-icons/bi";
const fields = [
  "Clinician",
  "Creation Date",
  "Service",
  "Description",
  "Actions",
];

export default function NonSessionList({
  list,
  setNsServices,
  dashState,
  patient,
  setSelectedItem,
  setShowEditOrCreateModal,
}) {
  const toast = useToast();
  const [description, setDescription] = useState("");
  return (
    <Box overflowY="auto" w="full" mt="6" flex={1}>
      <Table size="md">
        <Thead position="sticky" zIndex="base" top="0" bg="gray.100">
          <Tr>
            {fields.map((field) => {
              return <Th fontSize={"11px"}>{field}</Th>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {list.map((ns, i) => {
            let doctor = null;
            if (dashState.userType === "admin") {
              let doctorsPayload = dashState.doctorsPayload;
              let index = doctorsPayload?.[ns.did]?.index;
              doctor = dashState.doctors[index];
            }

            return (
              <Tr key={i}>
                <Td>
                  <Tooltip label={ns.dName}>
                    <Avatar size="md" name={ns.dName} src={doctor?.photo} />
                  </Tooltip>
                </Td>
                <Td>
                  <Text fontWeight={"medium"}>{dateString(ns.timestamp)}</Text>
                </Td>
                <Td>
                  <Tag colorScheme="blue" my="3">
                    {ns.service}
                  </Tag>
                </Td>

                <Td>
                  <Text
                    fontWeight={"light"}
                    color={ns.description ? "blue.500" : "gray.500"}
                    fontSize="12px"
                    mb="1"
                    cursor={ns.description ? "pointer" : "default"}
                    onClick={() => {
                      ns.description && setDescription(ns.description);
                    }}
                  >
                    {ns.description
                      ? "View Description"
                      : "No Description Added"}
                  </Text>
                </Td>
                <Td>
                  <Badge
                    onClick={() => {
                      setSelectedItem(ns);
                      setShowEditOrCreateModal(true);
                    }}
                    fontSize="12px"
                    rounded="md"
                    p="1"
                    px="2"
                    colorScheme="yellow"
                    cursor={"pointer"}
                  >
                    <Center gap={1}>
                      <Icon as={BiSolidEdit} fontSize="15px" mt="-0.5" />
                      <Text>Update Criteria</Text>
                    </Center>
                  </Badge>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <DescriptionModal {...{description, setDescription}} />
    </Box>
  );
}
