import {crud} from "../../../../crudRequests";

export async function fetchSymptoms(setSymptoms, dashState, toast, symptomIds) {
  try {
    const res = await crud(dashState, [
      {
        db: "intake",
        collection: "symptoms",
        parameters: [{symId: {$in: symptomIds}}],
        method: "find",
      },
    ]);
    setSymptoms(res?.data?.[0]);
  } catch (err) {
    toast({
      title: "Failed to fetch symptom data",
      description:
        err?.message || "An error occurred while fetching symptom data.",
      status: "error",
      isClosable: true,
    });
  }
}
