import {useEffect, useState} from "react";
import {
  Button,
  Flex,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  IconButton,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Badge,
  Icon,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import RequistionForm from "./RequistionForm";
import {crud, apiAction} from "../../../crudRequests";
import Loader from "../../appointments/clinicAidClaimModal/Loader";
import {CheckIcon, WarningTwoIcon, EditIcon, ViewIcon} from "@chakra-ui/icons";

const FormSystemDataStatus = (props) => {
  if (Object.keys(props.formData).length === 0) {
    return <></>;
  }
  const systemFields =
    props.selectedReq?.schema.filter((field) => field.source === "system") ||
    [];
  const systemErrors = [];
  for (let field of systemFields) {
    const formValue = props.formData[field.pdfMapping];
    if (!formValue && field.isRequired) {
      systemErrors.push(field);
    } else if (
      field.type === "text" &&
      field.maxLength &&
      formValue.length > field.maxLength
    ) {
      systemErrors.push(field);
    }
  }
  if (systemErrors.length === 0) {
    return (
      <Badge colorScheme="green" variant="solid" h="18px" m="4px">
        <CheckIcon />
      </Badge>
    );
  } else {
    return (
      <>
        <Badge colorScheme="yellow" variant="solid" h="auto" m="4px" py="2px">
          <WarningTwoIcon />
          Missing information, update system data before continuing.
        </Badge>
        <Popover>
          <PopoverTrigger>
            <IconButton
              border="solid"
              // colorScheme="yellow"
              aria-label="View missing data"
              icon={<ViewIcon />}
              h="24px"
              w="auto"
              mx="8px"
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Fields</PopoverHeader>
            <PopoverBody>
              {systemErrors.map((valErr, i) => {
                return <Text key={valErr.pdfMapping}>{valErr.label}</Text>;
              })}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    );
  }
};

const useGetRequisitionTypes = (state) => {
  const [requisitionList, setRequisitionList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await crud(state, [
          {
            db: state.db,
            collection: "requisitionTemplates",
            parameters: [],
            method: "find",
          },
        ]);
        setRequisitionList(data.data[0] || []);
        setError(null);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return {data: requisitionList, loading, error};
};

export default function CreateRequisitionDialog(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requistionType, setRequisitionType] = useState("");
  const isDoctor = Boolean(props.dashState?.doctor?.did);
  const [formData, setFormData] = useState({
    ...(isDoctor && {did: props.dashState.doctor.did}),
  });
  const toast = useToast();

  const {
    data,
    loading: dataLoading,
    error,
  } = useGetRequisitionTypes(props.dashState);
  const selectedLocation = props.dashState.locations.find(
    (loc) => loc.lid === props.dashState.selectedLocation
  );
  const selectedReq = data?.find((reqDoc) => reqDoc.type === requistionType);
  const setFormSystemData = () => {
    if (formData.did && selectedReq) {
      const clinician = isDoctor
        ? props.dashState.doctor
        : props.dashState.doctors.find((dr) => dr.did === formData.did);
      const patientDob = props.patient.dob?.split("-");
      const conditionalData = {};
      if (formData.copyToDid) {
        const additionalClinician = props.dashState.doctors.find(
          (dr) => dr.did === formData.copyToDid
        );
        conditionalData.clinician = true;
        conditionalData.lname = additionalClinician.lastName;
        conditionalData.fname = additionalClinician.firstName;
        conditionalData.address2a = selectedLocation.address;
      }
      setFormData({
        ...formData,
        name: `${clinician.firstName} ${clinician.lastName}`,
        address: selectedLocation.address,
        patienFname: props.patient.fName,
        // patienLname: props.patient.lName,//thisfield actually refers to middle name
        y_birth2: patientDob ? patientDob[0] : null,
        m_birth2: patientDob ? patientDob[1] : null,
        d_birth2: patientDob ? patientDob[2] : null,
        "ClinicianPractitioner Number": clinician.practitionerNumber,
        sex: props.patient.gender === "Male" ? true : false,
        sexF: props.patient.gender === "Male" ? false : true,
        patientTelephone: props.patient.phone,
        "Patient’s Address including Postal Code": `${
          props.patient.address1 || ""
        } ${props.patient.address2 || ""} ${props.patient.address3 || ""}`,
        "Health Number": props.patient?.healthCard?.healthCardNo,
        version: props.patient?.healthCard?.healthCardVerCode,
        contactNumber1: clinician.phone,
        "Patient’s Last Name as per OHIP Card": props.patient.lName,
        Date: new Date().toLocaleDateString("en-CA"),
        Province: props.patient.province,
        urgentPhone: clinician.phone,
        ...conditionalData,
        clinicianSignature: clinician.signature,
      });
    }
  };
  useEffect(() => {
    setFormSystemData();
  }, [formData.did, isDoctor, formData.copyToDid]);
  if (dataLoading) {
    return <Loader />;
  }

  return (
    <>
      <Flex justify="center" align="center">
        <Select
          value={requistionType}
          w="25%"
          onChange={(evt) => {
            setRequisitionType(evt.target.value);
          }}
        >
          <option value="">Select a requistion type</option>
          {data.map((reqTemplate) => {
            return <option value={reqTemplate.type}>{reqTemplate.name}</option>;
          })}
        </Select>
        <Button
          ml="2"
          onClick={() => onOpen()}
          isDisabled={dataLoading || !selectedReq}
        >
          Create
        </Button>
      </Flex>
      {selectedReq && (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedReq.name}{" "}
              <FormSystemDataStatus
                selectedReq={selectedReq}
                formData={formData}
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RequistionForm
                state={props.dashState}
                requisitionData={selectedReq}
                formData={formData}
                setFormData={setFormData}
                isDoctor={isDoctor}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                variant="ghost"
                isDisabled={requestLoading || !formData.did}
                onClick={async () => {
                  setRequestLoading(true);
                  await apiAction(props.dashState, {
                    action: "CREATE-REQUISITION",
                    payload: {
                      type: requistionType,
                      patientId: props.patient.pid,
                      input: formData,
                    },
                  });
                  setRequestLoading(false);
                  setFormData({
                    ...(isDoctor && {did: props.dashState.doctor.did}),
                  });
                  setFormSystemData();
                  props.refetch();
                  toast({
                    title: "Requisiton created succesfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                  onClose();
                }}
              >
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
