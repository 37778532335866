import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Badge,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
} from "@chakra-ui/react";
import React, {useState, useEffect, useRef, useMemo} from "react";
import {crud} from "../../../crudRequests";
import saleProducts from "../helpers/saleProducts.js";
import SelectionsTable from "./SelectionsTable";
import BuyerInformation from "./BuyerInformation.js";

import {v4 as uuidv4} from "uuid";
import SelectPatient from "./SelectPatient.js";
import TieTo from "./TieTo.js";
export default function SalesModal({
  selections,
  setSelections,
  action,
  state,
  dispatch,

  setProducts,
}) {
  const toast = useToast();
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [patientId, setPatientId] = useState(null);

  const [selectedProducts, setSelectedProducts] = useState(
    [...selections.values()].map((e) => ({...e, quantity: 1}))
  );
  const [fields, setFields] = useState(() => {
    let location = state.locations?.find(
      (l) => l.lid === state.selectedLocation
    );

    return {
      pid: "",
      firstName: "",
      lastName: "",
      pEmail: "",
      phone: "",
      tax: location.productsTaxRate || 0,
      note: "",
    };
  });
  const [loading, setLoading] = useState(false);

  const patientsMap = useMemo(() => {
    return state.patients.reduce((acc, c) => {
      acc[c.pid] = c;
      return acc;
    }, {});
  }, []);

  //console.log(fields);

  let amount = selectedProducts.reduce((acc, el) => {
    return acc + parseFloat(el.price) * parseFloat(el.quantity);
  }, 0);

  amount = (amount * (1 + fields.tax / 100)).toFixed(2);

  async function onSell() {
    if (patientsMap[patientId]) {
      setLoading(true);

      let numbAmount = parseFloat(amount);

      const invoiceId = id(8);
      const receiptId = id(8);

      let transaction = {
        type: "product",
        tid: uuidv4(),
        invoiceId,
        receiptId,
        lid: state.selectedLocation,
        pid: fields.pid,
        pName: fields.firstName + " " + fields.lastName,
        pEmail: fields.pEmail,
        amount: numbAmount,
        productAmount: numbAmount,
        productTax: Number(fields.tax || 0),
        pending: false,
        cancelled: false,
        products: selectedProducts,
        paid: numbAmount,
        tppAmount: 0,
        patientAmount: numbAmount,
        amountPaidByPatient: numbAmount,
        amountPaidByTpp: 0,
        patientPaymentStatus: "paid",
        paymentStatus: "paid",
        serviceDate: new Date().getTime(),
        serviceType: "Product Sale",
      };

      if (state.userType === "doctor") {
        transaction.did = state.doctor?.did;
      }

      await saleProducts(
        transaction,
        setSelections,
        setProducts,
        patientsMap[patientId],
        state,
        setLoading,

        toast
      );

      onClose();
      action();
      setLoading(false);
    }
  }

  return (
    <Modal
      isCentered
      size={"2xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        action();
      }}
      closeOnOverlayClick={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent p={1} overflow={"hidden"}>
        <ModalHeader borderBottom={"1px"} borderColor={"gray.400"}>
          Sale Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="10">
          <form
            id="buyerInfo"
            onSubmit={async (e) => {
              e.preventDefault();
              await onSell();
            }}
            m="2"
          >
            <SelectPatient
              {...{patientId, setPatientId, setFields, state, patientsMap}}
            />

            <Flex w="full" direction={"column"} gap={6}>
              <BuyerInformation {...{fields, setFields, state}} />

              <SelectionsTable
                {...{
                  state,
                  selectedProducts,
                  setSelectedProducts,
                  loading,
                  setSelections,
                  closeAcion: () => {
                    action();
                    onClose();
                  },
                }}
              />
            </Flex>
            <Flex align={"center"} mb="2" gap={1} fontSize="1em">
              <Text fontWeight={600}>{`Tax (%):`}</Text>

              <Input
                w="3rem"
                fontWeight={600}
                type="number"
                value={fields.tax}
                onChange={(e) => {
                  let value = e.target.value;

                  if (!isNaN(value) && !value.match(/-/g)) {
                    if (value === "") value = 0;
                    setFields((prev) => {
                      return {
                        ...prev,
                        tax: value,
                      };
                    });
                  }
                }}
                textAlign={"right"}
                size={"xs"}
                p="2"
                rounded={"md"}
              />
            </Flex>
            <Flex align={"center"} gap={1} fontSize="1em">
              <Text fontWeight={600}>{`Total Amount:`}</Text>
              <Badge rounded={"md"} fontSize="1.2em">
                {`$${amount}`}
              </Badge>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          {/*<TieTo {...{entries, onSelect, loading, onSelectEntrie}} />*/}
          <Button
            type="submit"
            form="buyerInfo"
            w="40"
            colorScheme="blue"
            mr={3}
            cursor={!loading ? "pointer" : "not-allowed"}
          >
            Sell
          </Button>
          <Button
            w="40"
            onClick={() => {
              onClose();
              action();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
