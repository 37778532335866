import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {useContext, useEffect, useState} from "react";
import {Box, Button, Grid, useToast} from "@chakra-ui/react";
import WYSIWYGEditor from "./WYSIWYGEditor";
import {fetchSummaries} from "./helpers/fetchSummaries";
import {fetchPrescriptions} from "./helpers/fetchPrescriptions";
import {fetchReports} from "./helpers/fetchReports";
import {fetchForms} from "./helpers/fetchForms";
import {fetchIntake} from "./helpers/fetchIntake";
import {fetchAppointments} from "./helpers/fetchAppointments";
import SelectSummary from "./SelectSummary";
import SelectPrescription from "./SelectPrescription";
import SelectForm from "./SelectForm";

export default function Charts({apptLinkToChart, setApptLinkToChart}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  const toast = useToast();
  const [summaries, setSummaries] = useState(null);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [intake, setIntake] = useState(null);
  const [prescriptions, setPrescriptions] = useState(null);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [forms, setForms] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [reports, setReports] = useState([]);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    fetchSummaries(setSummaries, dashState, patient, toast);
    fetchPrescriptions(setPrescriptions, dashState, patient, toast);
    fetchForms(setForms, dashState, patient, toast);
    fetchReports(setReports, dashState, patient, toast);
    fetchAppointments(setAppointments, dashState, patient, toast);
  }, []);

  return (
    <Box
      mx="auto"
      my="6"
      pb="6"
      px="8"
      boxShadow="md"
      bg="white"
      textAlign={"center"}
    >
      <Button
        px="10"
        colorScheme="blue"
        variant="outline"
        onClick={() => fetchIntake(setIntake, dashState, patient, toast)}
      >
        Attach Patient Intake
      </Button>
      <Grid templateColumns="repeat(3, 1fr)" gap="8" w="100%" my="6">
        <SelectSummary
          summaries={summaries}
          setSelectedSummary={setSelectedSummary}
        />
        <SelectPrescription
          prescriptions={prescriptions}
          setSelectedPrescription={setSelectedPrescription}
        />
        <SelectForm forms={forms} setSelectedForm={setSelectedForm} />
      </Grid>
      <WYSIWYGEditor
        appointments={appointments}
        reports={reports}
        setReports={setReports}
        selectedSummary={selectedSummary}
        setSelectedSummary={setSelectedSummary}
        selectedPrescription={selectedPrescription}
        setSelectedPrescription={setSelectedPrescription}
        selectedForm={selectedForm}
        setSelectedForm={setSelectedForm}
        intake={intake}
        {...{apptLinkToChart, setApptLinkToChart}}
      />
    </Box>
  );
}
