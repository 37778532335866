export default function addIntake(intake, editorRef) {
  if (intake) {
    let intakeText = intake.highRisk
      ? "This patient appears to be at a high risk for harmful actions towards themselves or others.<br/><br/>"
      : "";
    intakeText += intake.differentials
      .map((differential) => {
        const uniqueSymptoms = intake.symptoms?.filter(
          (symptom, index, self) =>
            symptom.symptom.associatedDisorders.includes(
              differential.disorder.did
            ) &&
            index ===
              self.findIndex((s) => s.symptom.name === symptom.symptom.name)
        );

        const keySymptomsDescriptions = uniqueSymptoms
          .map(
            (symptom) =>
              "<span style='line-height:26px;color:#3182ce; border: 1px solid #3182ce; border-radius:9999px; padding: 2px 8px 2px 8px'>" +
              symptom.symptom.name +
              "</span>"
          )
          .join("  ");
        return (
          "<br/><b>" +
          "<mark style='color:#FFFFFF; background-color:#3182ce; border-radius:9999px; padding: 4px 10px 4px 10px'>" +
          differential.disorder.name +
          "</mark>" +
          "</b>" +
          "<br/><br/>" +
          differential.reasoning +
          "<br/><br/><i>Key symptoms:</i> " +
          keySymptomsDescriptions +
          "<br/><br/>"
        );
      })
      .join("");
    editorRef?.current?.insertContent("<p>" + intakeText + "</p>");
  }
}
