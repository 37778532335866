import {crud} from "../../../crudRequests";
import createPaymentRecord from "../../appointments/payment/helpers/createPaymentRecord";
import {
  fullInvoice,
  patientInvoice,
  patientReceipt,
  fullReceipt,
} from "./uploads";
export default async function saleProducts(
  transaction,
  setSelections,
  setProducts,

  patient,
  state,
  setIsLoading,

  toast
) {
  setIsLoading(true);
  const generatingToast = toast({
    title: "Saving sales information...",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });
  try {
    let res = await Promise.all(
      transaction.products.map((p) =>
        crud(state, [
          {
            db: state.db,
            collection: "products",
            parameters: [
              {id: p.id, stock: {$gte: p.quantity}},
              {
                $inc: {stock: -p.quantity},
              },
              {returnNewDocument: true},
            ],
            method: "findOneAndUpdate",
          },
        ])
      )
    );
    //console.log(res.map((t) => t.data));
    let invData = {state, transaction, patient};

    await crud(state, [
      {
        db: state.db,
        collection: "billing",
        parameters: [transaction],
        method: "insertOne",
      },
    ]);

    await createPaymentRecord({
      state,
      transaction: transaction,
      description: "Payment made by the patient.",
      date: Date.now(),
      amount: transaction.amount,
      payer: "patient",
    });

    await patientInvoice({
      ...invData,
      paid: 0,
      amountPaidByPatient: 0,
    });
    await fullInvoice(invData);
    await fullReceipt(invData);
    await patientReceipt({...invData, receiptAmount: transaction.amount});

    let pdcs = await crud(state, [
      {
        db: state.db,
        collection: "products",
        parameters: [{}],
        method: "find",
      },
    ]);
    pdcs = pdcs.data[0];

    setProducts(pdcs);

    setSelections(
      (prev) =>
        new Set(
          pdcs.filter((p) => {
            let n = new Set([...prev].map((e) => e.id));
            return n.has(p.id);
          })
        )
    );

    toast.close(generatingToast);
    toast({
      title: "Successful sale",
      description: "You can view the transaction in the Billing section",
      status: "success",
      isClosable: true,
      duration: 3000,
    });
  } catch (err) {
    setIsLoading(false);
    console.log("error", err);
    toast({
      title: "Failing to save sales information",
      description:
        err?.message || "An error occurred while saving sales information.",
      status: "error",
      isClosable: true,
    });
    toast.close(generatingToast);
  }
}
