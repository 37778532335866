import {crud} from "../../../crudRequests";

export async function createItem({
  dashState,
  product,
  toast,
  setProducts,
  action,
}) {
  try {
    const generatingToast = toast({
      title: "Creating product...",
      status: "loading",
      variant: "subtle",
      duration: null,
      isClosable: true,
    });
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "products",
        parameters: [product],
        method: "insertOne",
      },
    ]);
    setProducts((prev) => prev.concat(product));
    toast.close(generatingToast);
    action?.();
  } catch (error) {
    toast({
      title: "Failed to create product",
      description:
        error?.message || "An error occurred while creating the product.",
      status: "error",
      isClosable: true,
    });
  }
}
