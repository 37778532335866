import {crudStorageUpload} from "./crudStorageUpload";
import {viewFiles} from "./viewFiles";

export function uploadFile(
  dashState,
  path,
  selectedFileUpload,
  setUploadLoader,
  setSelectedFileUpload,
  setFiles,
  setSelectedFile,
  setAddFile,
  toast
) {
  setUploadLoader(true);
  crudStorageUpload(
    dashState,
    new File(
      [selectedFileUpload],
      "prefix_" +
        new Date().getTime() +
        "-" +
        selectedFileUpload.size / 1000 +
        "-" +
        selectedFileUpload.name,
      {
        type: selectedFileUpload.type,
      }
    ),
    "brightlighthealth",
    path
  )
    .then((res) => {
      setUploadLoader(false);
      viewFiles(dashState, setFiles, setSelectedFile, path, toast);
      setSelectedFileUpload("");
      setAddFile(false);
    })
    .catch((err) => {
      setUploadLoader(false);
      toast({
        title: "Failed to upload file",
        description: err?.message || "An error occurred while uploading file.",
        status: "error",
        isClosable: true,
      });
    });
}
