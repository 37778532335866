import {crud} from "../../../../crudRequests";

export async function fetchNonSessions(action, dashState, patient, toast) {
  try {
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "nonSessionServices",
        parameters: [{pid: patient.pid}],
        method: "find",
      },
    ]);
    action(res.data[0]);
  } catch (err) {
    toast({
      title: "Failed to fetch non-sessions services data",
      description:
        err?.message ||
        "An error occurred while fetching non-sessions services data.",
      status: "error",
      isClosable: true,
    });
  }
}
