import React, {useState, useMemo, useRef, useEffect, useContext} from "react";
import {PiArrowCircleDownFill} from "react-icons/pi";
import {BsPersonCircle} from "react-icons/bs";
import {PiUserSquare} from "react-icons/pi";
import renderTextByAccess from "../../utils/renderTextByAccess";
import verifyAccess from "../../utils/verifyAccess";
import {lightFormat, differenceInMinutes, startOfDay} from "date-fns";
import {TiArrowForwardOutline, TiArrowBackOutline} from "react-icons/ti";
import {DashStateContext, PatientContext} from "../../pages/Patients";
import {alphabeticalSort} from "../Tasks/helpers/alphabeticalSort";

export default function Selections({
  role,
  schState,
  dispatch,
  gridRef,
  setDate,
  setView,
}) {
  const [all, setAll] = useState(true);
  const [selectAppt, setSelectAppt] = useState(false);
  const [prevDoc, setPrevDoc] = useState(schState?.selectedDoctor);
  const [value, setValue] = useState(
    role === "Doctors" ? "All Clinicians" : "All Patients"
  );
  const [showSearchList, setShowSearchList] = useState(true);
  const [showOptionsList, setShowOptionsList] = useState(false);
  const [showApptsList, setShowApptsList] = useState(false);

  const clickRef = useRef(false);
  const inputRef = useRef();

  if (schState?.selectedDoctor !== prevDoc) {
    setPrevDoc(schState?.selectedDoctor);
    role === "Doctors" &&
      schState?.selectedDoctor &&
      setValue(`${schState?.selectedDoctor?.lastName}, ${schState?.selectedDoctor?.firstName}`);

    if (role !== "Doctors") {
      if (schState?.selectedPatient) {
        let p = schState?.selectedPatient;
        setValue(p.lName + ", " + p.fName);
      }
    }
  }

  let appointments = useMemo(() => {
    return (schState?.appointments || []).filter(
      (a) =>
        a.pid === schState?.selectedPatient?.pid &&
        schState.selectedClinicians?.has(a.did)
    );
  }, [
    schState.appointments,
    schState.selectedPatient,
    schState.selectedClinicians,
  ]);

  useEffect(() => {
    const handler = () => {
      setShowSearchList((e) => e && !e);
      if (!clickRef.current) {
        setShowOptionsList((e) => e && !e);
        setShowApptsList(false);
      }
      clickRef.current = false;
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  return (
    <div className="mx-1 flex flex-col items-start relative">
      <p className="text-[11px] px-1   text-off flex items-center w-full justify-between">
        {selectAppt && schState.selectedPatient ? (
          <>
            <span className="flex flex-col justify-start items-start leading-[11px]">
              <span>
                {schState.selectedPatient?.lName +
                  ", " +
                  schState.selectedPatient?.fName +
                  "`s"}
              </span>
              <span>appointments</span>
            </span>

            <span
              className="flex flex-col mb-1 items-center leading-[11px] text-off hover:text-dark"
              onClick={(e) => {
                schState.selectedPatient &&
                  setValue(
                    schState.selectedPatient?.lName +
                      ", " +
                      schState.selectedPatient?.fName
                  );
                setSelectAppt(false);
              }}
            >
              <span>Back</span>

              <span className=" text-[1rem] cursor-pointer">
                <TiArrowBackOutline />
              </span>
            </span>
          </>
        ) : role === "Patients" ? (
          `Patients`
        ) : (
          `Clinicians`
        )}
      </p>

      {
        <div className="relative flex justify-center items-center">
          {showSearchList && (
            <SearchList
              onSelect={(e) => {
                setValue(
                  role === "Doctors" ? `${e.name}` : e.lName + ", " + e.fName
                );
                dispatch({
                  type: role === "Doctors" ? "SELECT_DOCTOR" : "SELECT_PATIENT",
                  payload: e,
                });
                if (role !== "Doctors" && e) {
                  setSelectAppt(true);
                }
              }}
              {...{schState, role}}
              value={value}
              setAll={setAll}
              fields={
                role === "Doctors"
                  ? ["email", "name", "fName", "lName"]
                  : [
                      "fName",
                      "lName",
                      "email",
                      "healthCard",
                      "phone",
                      "memberships",
                    ]
              }
            />
          )}

          {showOptionsList && (
            <OptionsList
              {...{schState, role, showOptionsList, setAll}}
              onSelect={(e) => {
                if (e) {
                  if (role === "Doctors") setValue(`${e.name}`);
                  else {
                    let allAppts = (schState?.appointments || []).filter(
                      (app) => schState.selectedClinicians?.has(app.did)
                    );
                    let appt = null;
                    let time = Date.now();
                    for (let i = allAppts.length - 1; i >= 0; i--) {
                      if (allAppts[i].pid === e.pid && !appt) {
                        appt = allAppts[i];
                        continue;
                      }
                      if (
                        allAppts[i].pid === e.pid &&
                        allAppts[i].ISOdate >= time
                      ) {
                        if (
                          appt.ISOdate > allAppts[i].ISOdate ||
                          appt.ISOdate < time
                        ) {
                          appt = allAppts[i];
                        }
                      } else if (
                        allAppts[i].pid === e.pid &&
                        allAppts[i].ISOdate < time
                      ) {
                        if (appt.ISOdate < allAppts[i].ISOdate) {
                          appt = allAppts[i];
                        }
                      }
                    }

                    if (appt) {
                      let pixPerMin = gridRef?.current?.scrollHeight / 1470;
                      let start = startOfDay(appt.ISOdate);
                      let s = new Date(appt.ISOdate);
                      let diff = differenceInMinutes(s, start, {
                        roundingMethod: "floor",
                      });
                      let doctor = null;
                      if (schState.doctor) {
                        doctor = schState.doctor;
                      } else {
                        let index = schState.doctorsPayload?.[appt.did]?.index;
                        doctor = schState.doctors?.[index];
                      }

                      if (doctor) {
                        setDate(start);
                        setView("day");
                        // dispatch({type: "SELECT_APPOINTMENT", payload: appt});
                        dispatch({
                          type: "SELECT_DOCTOR",
                          payload: doctor,
                        });
                        setValue(
                          lightFormat(appt.ISOdate, "dd/MM/yyyy hh:mm aaaa")
                        );
                        setImmediate(
                          () => (gridRef.current.scrollTop = pixPerMin * diff)
                        );
                      }
                    } else {
                      setValue(e.fName + " " + e.lName);
                    }
                  }
                } else {
                  setValue(role === "Doctors" ? "Clinicians" : "Patients");
                }

                dispatch({
                  type: role === "Doctors" ? "SELECT_DOCTOR" : "SELECT_PATIENT",
                  payload: e,
                });

                if (role !== "Doctors" && e) {
                  setSelectAppt(true);
                }
              }}
            />
          )}

          {showApptsList && (
            <ApptList
              {...{
                appointments,
                schState,
                dispatch,
                gridRef,
                setValue,
                setDate,
                setShowApptsList,
                setView,
              }}
            />
          )}

          <input
            ref={inputRef}
            value={value}
            readOnly={all || selectAppt}
            onFocus={() => {
              if (all) {
                setAll(false);
                setValue("");
              }
            }}
            onBlur={() => {
              if (!selectAppt) {
                if (
                  (role !== "Doctors" && !schState.selectedPatient) ||
                  (role === "Doctors" && !schState.selectedDoctor)
                ) {
                  setAll(true);
                  setValue(
                    role === "Doctors" ? "All Clinicians" : "All Patients"
                  );
                }
                if (
                  (role !== "Doctors" && schState.selectedPatient) ||
                  (role === "Doctors" && schState.selectedDoctor)
                ) {
                  setValue(
                    role === "Doctors"
                      ? `${schState.selectedDoctor.name}`
                      : schState.selectedPatient.lName +
                          ", " +
                          schState.selectedPatient.fName
                  );
                  setAll(false);
                }
              }
            }}
            onClick={(e) => {
              if (!selectAppt) {
                setShowSearchList(true);
                setShowOptionsList(false);
              }
            }}
            onChange={(e) => {
              setValue(e.target.value.trim());
              setShowOptionsList(false);
              setShowSearchList(true);
            }}
            className="drop-shadow block w-[11rem] h-9 px-2 py-2 pr-8 text-[13px] text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            type="text"
          />

          <span
            className="absolute right-0 w-8 h-full z-10 flex justify-center items-center hover:cursor-pointer"
            onClick={(e) => {
              if (!selectAppt) {
                clickRef.current = true;
                setShowSearchList(false);
                setShowOptionsList((s) => !s);
              } else {
                clickRef.current = true;
                setShowSearchList(false);
                setShowApptsList((s) => !s);
              }
            }}
          >
            <PiArrowCircleDownFill
              className="h-5 w-5 text-off hover:text-gray-800"
              aria-hidden="true"
            />
          </span>
        </div>
      }
    </div>
  );
}

function OptionsList({onSelect, schState, role, setAll, searchText}) {
  return (
    <>
      {role === "Doctors" ? (
        <div className="max-h-[10rem] pb-2 flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-10 bg-white drop-shadow border border-gray-200 rounded-md">
          <ul className="flex-1 overflow-auto sbar2 space-y-2 overflow-x-hidden text-[12px] text-off font-medium flex flex-col">
            <li
              className="hover:bg-gray-100 font-medium text-start text-gray-900 hover:cursor-pointer px-2"
              key={"doc"}
              onClick={() => {
                onSelect(null);
                setAll(true);
              }}
            >
              {"Select All"}
            </li>
            {schState.doctors?.map((e, i) => (
              <li
                className="hover:bg-gray-100 text-[12px]  text-start text-off hover:cursor-pointer px-2 flex items-center"
                key={i}
                onClick={() => {
                  onSelect(e);
                  setAll(false);
                }}
              >
                <span className="flex justify-center h-6 w-6 mr-1 items-center">
                  {e.photo ? (
                    <img
                      src={e.photo}
                      alt=""
                      className="hover:cursor-pointer hover:bg-[] h-full rounded-md drop-shadow-lg"
                    />
                  ) : (
                    <span className="p-[3px] border-[2px] rounded-md ">
                      <BsPersonCircle className="text-[1rem] text-off" />
                    </span>
                  )}
                </span>
                {`${e.name}`}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="max-h-[8rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-20 bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto pb-2 sbar2 text-[13px] text-off font-normal flex flex-col overflow-x-hidden">
            <li
              className=" hover:bg-gray-100 font-medium  text-start text-gray-900 hover:cursor-pointer px-2 whitespace-nowrap"
              key={"pat"}
              onClick={() => {
                onSelect(null);
                setAll(true);
              }}
            >
              {"All Patients"}
            </li>

            {schState.patients
              .sort((p1, p2) => {
                let name1 = `${p1.lName}, ${p1.fName}`.toLocaleLowerCase(
                  "en-CA"
                );
                let name2 = `${p2.lName}, ${p2.fName}`.toLocaleLowerCase(
                  "en-CA"
                );
                if (name1 < name2) {
                  return -1;
                }
                if (name1 > name2) {
                  return 1;
                }
                return 0;
              })
              .map((pat, i) => {
                return (
                  <li
                    className=" hover:bg-gray-100  text-start text-off hover:cursor-pointer px-2 whitespace-nowrap"
                    key={i}
                    onClick={() => {
                      onSelect(pat);
                      setAll(false);
                    }}
                  >
                    {renderTextByAccess(schState, pat, "lName", undefined, " ")}
                    {", "}
                    {renderTextByAccess(
                      schState,
                      pat,
                      "fName",
                      undefined,
                      " "
                    )}{" "}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
}

function ApptList({
  appointments,
  schState,
  dispatch,
  gridRef,
  setValue,
  setDate,
  setView,
}) {
  return (
    <div className="max-h-[8rem] min-h-[3rem] pb-2 flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-20 bg-white drop-shadow border border-gray-200 rounded-xl">
      <ul className="flex-1 overflow-auto  sbar2 text-[13px] text-off font-normal flex flex-col overflow-x-scroll">
        {appointments.map((appt, i) => {
          return (
            <li
              className=" hover:bg-gray-100  text-start text-off hover:cursor-pointer px-2 whitespace-nowrap"
              key={i}
              onClick={(e) => {
                let pixPerMin = gridRef?.current?.scrollHeight / 1470;
                let start = startOfDay(appt.ISOdate);
                let s = new Date(appt.ISOdate);
                let diff = differenceInMinutes(s, start, {
                  roundingMethod: "floor",
                });
                let doctor = null;
                if (schState.doctor) {
                  doctor = schState.doctor;
                } else {
                  let index = schState.doctorsPayload?.[appt.did]?.index;
                  doctor = schState.doctors?.[index];
                }

                if (doctor) {
                  setDate(start);
                  setView("day");

                  dispatch({
                    type: "SELECT_DOCTOR",
                    payload: doctor,
                  });
                  setValue(lightFormat(appt.ISOdate, "dd/MM/yyyy hh:mm aaaa"));
                  setImmediate(
                    () => (gridRef.current.scrollTop = pixPerMin * diff)
                  );
                }
              }}
            >
              {lightFormat(appt.ISOdate, "dd/MM/yyyy hh:mm aaaa")}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function searchMatch(_data = [], entri, fields) {
  if (entri === "") return [];
  try {
    let sanitizedEntri = entri.replace(
      /[$^.*+?=!:|/()[\]{}]/g,
      (e) => "\\" + e
    );
    let regex = new RegExp(`${sanitizedEntri}`, "i");
    //console.log(regex);
    let d = _data.filter((e, i) => {
      for (let field of fields) {
        if (typeof e[field] === "string" && e[field]?.match(regex)) return true;
      }
      return false;
    });
    //console.log(d)
    return d;
  } catch (error) {
    console.log(error);
    return [];
  }
}

function SearchList({schState, value, fields, role, onSelect, setAll}) {
  const [searchArr, setSearchArr] = useState([]);
  useEffect(() => {
    let search = () => {
      let a = searchMatch(
        role === "Doctors" ? schState.doctors : schState.patients,
        value,
        fields
      );
      setSearchArr(a);
    };
    let id = setTimeout(search, 200);
    return () => {
      clearTimeout(id);
    };
  }, [value]);

  //console.log(searchArr)
  return (
    <>
      {searchArr.length !== 0 ? (
        <div className="max-h-[5rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-10 bg-white drop-shadow border border-gray-200 rounded-md">
          <ul className="flex-1 overflow-auto sbar2 text-[12px] text-off font-medium flex flex-col">
            {searchArr.map((e, i) => (
              <li
                className="hover:bg-gray-100  text-start text-off hover:cursor-pointer px-3"
                key={i}
                onClick={() => {
                  onSelect(e);
                  setAll(false);
                }}
              >
                {role === "Doctors" ? `${e.name}` : e.lName + ", " + e.fName}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}
