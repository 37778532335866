import {crud} from "../../../crudRequests";

export async function updateItem({
  dashState,
  product,
  toast,
  setProducts,
  action,
}) {
  try {
    const generatingToast = toast({
      title: "Updating product...",
      status: "loading",
      variant: "subtle",
      duration: null,
      isClosable: true,
    });

    let {_id, ...payload} = product;
    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "products",
        parameters: [
          {id: product.id},
          {$set: payload},
          {returnNewDocument: true},
        ],
        method: "findOneAndUpdate",
      },
    ]);

    setProducts((prev) => prev.map((p) => (p.id === product.id ? product : p)));
    toast.close(generatingToast);
    action?.();
  } catch (error) {
    toast({
      title: "Failed to update product",
      description:
        error?.message || "An error occurred while updating the product.",
      status: "error",
      isClosable: true,
    });
  }
}
