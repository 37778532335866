import {DashStateContext, DispatchContext, PatientContext} from "../../../pages/Patients";
import {useContext, useState} from "react";
import {Box, Icon, Text, Button, ButtonGroup, useToast} from "@chakra-ui/react";
import {MdDelete} from "react-icons/md";
import {FaUserMinus} from "react-icons/fa";
import {FaMinusCircle} from "react-icons/fa";
import InactiveModal from "./InactiveModal";
import DeceasedModal from "./DeceasedModal";
import DeletedModal from "./DeletedModal";
import Preferences from "./Preferences";
import {FiSend} from "react-icons/fi";
import {sendWelcomeMessage} from "../../../crudRequests";
import {updatePatient} from "../Information/helpers/updatePatient";
export default function Settings(props) {
  const clearPatientSelection = props.setPatient;
  const [patient, setPatient] = useContext(PatientContext);
  const dispatch = useContext(DispatchContext);
  const dashState = useContext(DashStateContext);

  const [inactiveModal, setInactiveModal] = useState(null);
  const [deceasedModal, setDeceasedModal] = useState(null);
  const [deletedModal, setDeletedModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast();

  return (
    <Box
      mx="auto"
      my="6"
      pb="6"
      px="8"
      boxShadow="md"
      bg="white"
      textAlign={"center"}
    >
      {patient?.deleted ? (
        <Text textAlign="center" fontWeight="medium" color="gray.600" mt="4">
          This patient has been deleted.{" "}
          <b>Please contact support if you wish to restore this patient.</b>
        </Text>
      ) : (
        <ButtonGroup pb="4" gap="2">
          <Box>
            <Button
              colorScheme="green"
              isLoading={isLoading}
              loadingText={patient?.welcome ? "Resend Welcome Message" : "Send Welcome Message"}
              variant="outline"
              rounded="md"
              px="8"
              py="6"
              onClick={async () => {
                setIsLoading(true)
                try {
                  await sendWelcomeMessage(dashState, patient, "patient");
                  const updatedPatient = {...patient, welcome: true}
                  await updatePatient(null, updatedPatient, dashState, setIsLoading, toast, dispatch)
                  setPatient(updatedPatient)
                  toast({
                    title: "The welcome message has been sent.",
                    status: "success",
                    isClosable: true,
                  });
                } catch (error) {
                  console.error(error);
                  toast({
                    title: "Welcome Message Error",
                    description:
                      error?.response?.data ||
                      error?.message ||
                      "An error occurred.",
                    status: "error",
                    isClosable: true,
                  });
                }
                setIsLoading(false)
              }}
            >
              <Icon as={FiSend} mr="2" /> {patient?.welcome ? "Resend Welcome Message" : "Send Welcome Message"}
            </Button>
            {patient.welcome ? (
              <Text color={"blue.500"} fontSize='sm'>
                Welcome message has already been sent.
              </Text>
            ) : (
              <Text color={"yellow.500"} fontSize='sm'>
                Welcome message has not been sent yet.
              </Text>
            )}
          </Box>

          {!patient?.deleted && !patient?.inactive && (
            <Button
              colorScheme="blue"
              variant="outline"
              rounded="md"
              px="8"
              py="6"
              onClick={() => setInactiveModal(true)}
            >
              <Icon as={FaMinusCircle} mr="2" /> Patient inactive
            </Button>
          )}
          {!patient?.deleted && !patient?.deceased && (
            <Button
              colorScheme="blue"
              rounded="md"
              px="8"
              py="6"
              onClick={() => setDeceasedModal(true)}
            >
              <Icon as={FaUserMinus} mr="2" /> Patient deceased
            </Button>
          )}
          {!patient?.deleted && (
            <Button
              colorScheme="red"
              variant="solid"
              rounded="md"
              px="8"
              py="6"
              onClick={() => setDeletedModal(true)}
            >
              {" "}
              <Icon as={MdDelete} mr="2" /> Delete patient
            </Button>
          )}
        </ButtonGroup>
      )}
      <InactiveModal {...{inactiveModal, setInactiveModal}} />
      <DeceasedModal {...{deceasedModal, setDeceasedModal}} />
      <DeletedModal
        {...{deletedModal, setDeletedModal, clearPatientSelection}}
      />
      <Box width="30%">
        <Preferences patient={patient} dashState={dashState} />
      </Box>
    </Box>
  );
}
