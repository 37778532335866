import axios from "axios";
import {url} from "../../../../crudRequests";

export async function updatePharmacy(state, phid, data) {
  let configService = {
    method: "POST",
    url: `${url}/updatePharmacy`,
    data: {phid, data},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}

export default async function updatePharm(
  dashState,
  phid,
  item,
  queryFn,
  setOpenPage,
  toast
) {
  try {
    const res = await updatePharmacy(dashState, phid, item);

    if (res.status === 200) {
      toast({
        title: "Success!",
        description: "Pharmacy updated successfully!",
        status: "success",
        isClosable: true,
      });
    }
    queryFn();
    setOpenPage(false);
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
}
