import axios from "axios";
import {url} from "../../../../crudRequests";
import {useToast} from "@chakra-ui/react";
import {useEffect, useState} from "react";

export async function providerAutocomplete(state, query, field, oid, lid) {
  let configService = {
    method: "GET",
    url: `${url}/providerAutocomplete?query=${query}&field=${field}&lid=${lid}&oid=${oid}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export default async function pAutocomplete(
  state,
  query,
  setProviders,
  toast,
  onComplete,
  field
) {
  try {
    if (!state?.organization?.oid || !state?.selectedLocation) return;
    const res = await providerAutocomplete(
      state,
      query,
      field,
      state?.organization.oid,
      state?.selectedLocation
    );
    setProviders(res.data);
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
  if (onComplete) {
    onComplete();
  }
}

export const useProviderSearch = (state) => {
  const [field, setField] = useState("name");
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [search, setSearch] = useState("");

  const toast = useToast();

  const queryFn = (query) => {
    setLoading(true);
    pAutocomplete(
      state,
      query ?? "",
      setProviders,
      toast,
      () => setLoading(false),
      field
    );
  };

  useEffect(() => {
    queryFn();
  }, []);

  return {
    field,
    setField,
    loading,
    setLoading,
    providers,
    setProviders,
    search,
    setSearch,
    queryFn,
  };
};
