import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Badge,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import {AiOutlineDelete} from "react-icons/ai";
import React, {useState, useEffect, useRef, useMemo} from "react";

export default function CompensationRate({
  state,
  clinician,
  setFields,
  entries,
}) {
  const serviceNamesMap = useMemo(() => {
    let clinicianServicesId = clinician.services;
    let services = state?.services.filter((s) =>
      clinicianServicesId.includes(s.serviceId)
    );

    if (services.length > 0) {
      return services.reduce((ac, e) => {
        ac[e.serviceId] = e.serviceName || "";
        return ac;
      }, {});
    }
    return {};
  }, [state?.services]);

  return (
    <Flex direction={"column"} px="2">
      <Text fontSize={"13px"} ml={"1"} mb="1" color={"#7F7B91"}>
        Compensation Rate (%)
      </Text>
      <TableContainer
        overflowY="auto"
        variant="simple"
        maxHeight={"150px"}
        mb="5"
        rounded={"md"}
        border={"1px solid"}
        borderColor={"gray.200"}
        pt="0"
      >
        <Table size="sm">
          <Thead position="sticky" zIndex={10} top="0" bg="white">
            <Tr fontSize={"11px"}>
              <Th>Service</Th>

              <Th>Rate (%)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(entries)
              .filter(([i, j]) => serviceNamesMap[i])
              .map(([k, v], idx) => {
                return (
                  <Tr key={idx} fontSize={"13px"}>
                    <Td>{serviceNamesMap[k]}</Td>

                    <Td>
                      <NumberInput
                        size="sm"
                        maxW={20}
                        min={0}
                        value={v}
                        onChange={(ele) => {
                          setFields((prev) => ({
                            ...prev,
                            compensationRate: {
                              ...prev.compensationRate,
                              [k]: ele,
                            },
                          }));
                        }}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
