import React, { useContext, useState, useEffect } from "react";
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import { colors, formatMonthAndDay } from "./calcTimeline";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
} from "@chakra-ui/react";

export  function Legend(){
    return (
      <Flex flexWrap={"wrap"} direction="row" justifyContent="flex-end">
        
      <Flex
        borderRadius="10px"
        bg="#F5F4F4"
        padding="10px"
        direction="row"
        flexWrap={"wrap"}
      >
        {Object.keys(colors).map((type, index) => (
          <Flex key={index} alignItems="center" marginY="1" marginRight="3">
            <Box
              w="15px"
              h="15px"
              borderRadius="100%"
              bg={colors[type]}
              marginRight="2"
            />
            <Text       color={colors[type]} fontSize="xs">{type}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
    )
  }
  
  export  function NotesPopover(s){
    return (
      <Popover placement="bottom">
      <PopoverTrigger>
      <Button size="sm"        cursor={"pointer"}
        fontSize="xs"
        color="#5754FF"
        textAlign="center"
        border="1px"
        p={2}
        background={"white"}
        borderColor="#5754FF"
        display="flex"
        alignItems="center"
        justifyContent="center">
            View Notes
          </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent >
          <PopoverArrow />
          <PopoverHeader pt={4} fontWeight="bold" border="0">
            {formatMonthAndDay(s.day)}
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            {s.additionalInfo}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
    )
  }