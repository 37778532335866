import React, {useState, useEffect} from "react";
import {crud} from "../crudRequests";
import ModifyLocation from "./ModifyLocation";

const ModalEditPeople = ({
  show,
  setShow,
  locationsMap,
  currentLid,
  populatePeopleByLid,
  state,
}) => {
  const [allDrs, setAllDrs] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [fetchStatus, setFetchStatus] = useState({});

  async function populatePeople() {
    let requestObjs = [
      {
        db: state.db,
        collection: "doctors",
        parameters: [{oid: state.admin?.oid}],
        method: "find",
      },
      {
        db: state.db,
        collection: "admins",
        parameters: [{oid: state.admin?.oid}],
        method: "find",
      },
    ];

    let res = await crud(state, requestObjs);
    const [drs, admins] = res?.data;

    setAllDrs(drs);
    setAllAdmins(admins);
  }

  const toggleDrLocation = (e, dr) => {
    if (!e.target.checked) {
      console.log("we are adding", dr);
      const newDrLocations = [...dr.lid, currentLid];

      const updateBody = {
        did: dr.did,
        lid: newDrLocations,
      };

      crud(state, [
        {
          db: state.db,
          collection: "doctors",
          parameters: [{did: updateBody.did}, {$set: updateBody}],
          method: "updateOne",
        },
      ]).then((res) => {
        if (res.status === 200) {
          setFetchStatus({
            ...fetchStatus,
            [dr.name]: "success",
          });
          populatePeopleByLid(currentLid);
        } else {
          setFetchStatus({
            ...fetchStatus,
            [dr.name]: "error",
          });
        }
      });
    } else {
      console.log("not checked", dr);
      const newDrLocations = dr.lid.filter((lid) => lid !== currentLid);
      crud(state, [
        {
          db: state.db,
          collection: "doctors",
          parameters: [{did: dr.did}, {$set: {lid: newDrLocations}}],
          method: "updateOne",
        },
      ]).then((res) => {
        if (res.status === 200) {
          setFetchStatus({
            ...fetchStatus,
            [dr.name]: "success",
          });

          setTimeout(() => {
            setFetchStatus({
              ...fetchStatus,
              [dr.name]: "",
            });
          }, 1000);
          populatePeopleByLid(currentLid);
        } else {
          setFetchStatus({
            ...fetchStatus,
            [dr.name]: "error",
          });

          setTimeout(() => {
            setFetchStatus({
              ...fetchStatus,
              [dr.name]: "",
            });
          }, 1000);
        }
      });
    }
  };

  const toggleAdminLocation = (e, admin) => {
    console.log("admin toggle");
    if (!e.target.checked) {
      console.log("we are adding ", admin);

      const newDrLocations = [...admin.location, currentLid];
      crud(state, [
        {
          db: state.db,
          collection: "admins",
          parameters: [{id: admin.id}, {$set: {location: newDrLocations}}],
          method: "updateOne",
        },
      ]).then((res) => {
        console.log("res update admin", res);
        if (res.status === 200) {
          setFetchStatus({
            ...fetchStatus,
            [admin.name]: "success",
          });
          setTimeout(() => {
            setFetchStatus({
              ...fetchStatus,
              [admin.name]: "",
            });
          }, 1000);
          populatePeopleByLid(currentLid);
        } else {
          setFetchStatus({
            ...fetchStatus,
            [admin.name]: "error",
          });
          setTimeout(() => {
            setFetchStatus({
              ...fetchStatus,
              [admin.name]: "",
            });
          }, 1000);
        }
      });
    } else {
      console.log("we are removing", admin);
      const newDrLocations = admin.location.filter((lid) => lid !== currentLid);
      crud(state, [
        {
          db: state.db,
          collection: "admins",
          parameters: [{id: admin.id}, {$set: {location: newDrLocations}}],
          method: "updateOne",
        },
      ]).then((res) => {
        if (res.status === 200) {
          setFetchStatus({
            ...fetchStatus,
            [admin.name]: "success",
          });
          setTimeout(() => {
            setFetchStatus({
              ...fetchStatus,
              [admin.name]: "",
            });
          }, 1000);
          populatePeopleByLid(currentLid);
        } else {
          setFetchStatus({
            ...fetchStatus,
            [admin.name]: "error",
          });
          setTimeout(() => {
            setFetchStatus({
              ...fetchStatus,
              [admin.name]: "",
            });
          }, 1000);
        }
      });
    }
  };

  useEffect(() => {
    populatePeople();
  }, []);

  return (
    <dialog open={show}>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-[#f6f5ff] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[800px] sm:w-full relative"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <button
              type="button"
              className="- text-gray-400 p-1 bg-transparent  absolute top-5 right-5 rounded-lg text-sm  ml-auto inline-flex items-center cursor-pointer focus:outline-none"
              data-modal-toggle="small-modal"
              style={{boxShadow: "none"}}
              onClick={() => setShow(false)}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            {/* body */}
            <div
              className="flex
            bg-gradient-to-b from-[#2d29ff44] to-[#7b79ff0f] p-10 gap-3
            "
            >
              <ModifyLocation
                mode="admin"
                gState={state}
                allUsers={allAdmins}
                onToggle={toggleAdminLocation}
                locationsMap={locationsMap}
                currentLid={currentLid}
              />
              <ModifyLocation
                mode="doctor"
                gState={state}
                allUsers={allDrs}
                onToggle={toggleDrLocation}
                locationsMap={locationsMap}
                currentLid={currentLid}
              />
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ModalEditPeople;
