import axios from "axios";
import {url} from "../../../../crudRequests";

export async function pharmAutocomplete(state, query, favPharmacies) {
  let configService = {
    method: "POST",
    url: `${url}/pharmAutocomplete`,
    data: {
      query,
      lid: state.selectedLocation,
      oid: state?.organization?.oid,
      favPharmacies,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    // console.log({res});
    return res;
  });
}

export default async function pAutocomplete(
  state,
  query,
  setPharmacies,
  toast,
  onComplete,
  favPharmacies
) {
  try {
    const res = await pharmAutocomplete(state, query, favPharmacies);
    setPharmacies(res.data);
  } catch (err) {
    toast({
      title: "Failed to fetch prescriptions data",
      description:
        err?.message || "An error occurred while fetching prescriptions data.",
      status: "error",
      isClosable: true,
    });
  }
  if (onComplete) {
    onComplete();
  }
}
