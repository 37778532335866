export const reportCollections = [
  {
    name: "Patients",
    color: "blue",
    collectionName: "patients",
    fields: [
      {name: "fName", label: "First Name", type: "text", default: true},
      {name: "lName", label: "Last Name", type: "text", default: true},
      {name: "preferredName", label: "Preferred Name", type: "text"},
      {name: "referralVia", label: "Referral Via", type: "text"},
      {
        name: "dob",
        label: "Date of Birth",
        type: "date",
        default: true,
      },
      {
        name: "gender",
        label: "Gender",
        type: "radio",
        options: ["Male", "Female", "Other"],
        default: true,
      },
      {name: "address1", label: "Address 1", type: "text"},
      {name: "address2", label: "Address 2", type: "text"},
      {name: "city", label: "City", type: "text", default: true},
      {name: "province", label: "Province", type: "text", default: true},
      {name: "postalCode", label: "Postal Code", type: "text", default: true},
      {name: "country", label: "Country", type: "text", default: true},
      {name: "email", label: "Email", type: "text", default: true},
      {
        name: "phone",
        label: "Phone Number (Cell)",
        type: "text",
        default: true,
      },
      {name: "phoneHome", label: "Phone Number (Home)", type: "text"},
      {
        name: "phoneAlternative",
        label: "Phone Number (Alternative)",
        type: "text",
      },
      {
        name: "emergencyContactName",
        label: "Emergency Contact Name",
        type: "text",
      },
      {
        name: "emergencyContactEmail",
        label: "Emergency Contact Email",
        type: "text",
      },
      {
        name: "emergencyContactPhone",
        label: "Emergency Contact Phone",
        type: "text",
      },
      {
        name: "emergencyContactRelation",
        label: "Emergency Contact Relation",
        type: "text",
      },
      {name: "height", label: "Height", type: "text"},
      {name: "weight", label: "Weight", type: "text"},
      {
        name: "healthCard.healthCardNo",
        label: "Health Card Number",
        type: "text",
      },
      {
        name: "healthCard.healthCardVerCode",
        label: "Health Card Ver. Code",
        type: "text",
      },
      {
        name: "healthCard.healthCardExpiry",
        label: "Health Card Expiry",
        type: "date",
      },
      {name: "healthCardState", label: "Health Card State", type: "text"},
      {name: "insuranceNumber", label: "Insurance Number", type: "text"},
      {name: "policyNumber", label: "Policy Number", type: "text"},
      {name: "authNumber", label: "Auth Number", type: "text"},
      {name: "deductionAmount", label: "Deduction Amount", type: "number"},
      {
        name: "numberOfInsuredSessions",
        label: "# of Insured Sessions",
        type: "number",
      },
      {name: "activeSince", label: "Active Since", type: "date"},
      {
        name: "referringClinicianData.name",
        label: "Reference Clinician - Ref. Clinician Name",
        type: "text",
      },
      {
        name: "referringGPData.name",
        label: "Reference Clinician - GP Name",
        type: "text",
      },
      {name: "occupation", label: "Occupation", type: "text"},
      {name: "employerName", label: "Employer Name", type: "text"},
      {name: "economicStatus", label: "Economic Status", type: "text"},
      {name: "educationLevel", label: "Education Level", type: "text"},
      {name: "tobacco", label: "Tobacco Use", type: "text"},
      {name: "alcohol", label: "Alcohol Use", type: "text"},
      {name: "caffeine", label: "Caffeine Use", type: "text"},
      {name: "cannabis", label: "Cannabis Use", type: "text"},
      {
        name: "preferredPronoun",
        label: "Preferred Pronoun",
        type: "text",
        default: true,
      },
      {name: "sexualOrientation", label: "Sexual Orientation", type: "text"},
      {name: "exerciseFrequency", label: "Exercise Frequency", type: "text"},
      {
        name: "dietaryRestrictions",
        label: "Dietary Restrictions",
        type: "text",
      },
      {
        name: "psychedelics",
        label: "Consumed Psychedelics",
        type: "text",
      },
      {
        name: "hardDrugs",
        label: "Consumed Illicit Drugs",
        type: "text",
      },
      {name: "military", label: "Served in Military", type: "text"},
      {
        name: "disability",
        label: "Disability/Impairments",
        type: "text",
      },

      {
        name: "maritalStatus",
        label: "Marital Status",
        type: "radio",
        options: ["Single", "Married", "Divorced", "Widowed"],
        default: true,
      },
    ],
  },
  {
    name: "Clinicians",
    color: "purple",
    collectionName: "doctors",
    fields: [
      {name: "firstName", label: "First Name", type: "text", default: true},
      {name: "lastName", label: "Last Name", type: "text", default: true},
      {name: "email", label: "Email", type: "text", default: true},
      {name: "phone", label: "Phone Number", type: "text", default: true},
      {name: "title", label: "Title", type: "text", default: true},
      {
        name: "accreditations",
        label: "Accreditations",
        type: "text",
        default: true,
      },
      {
        name: "licenseNumber",
        label: "License Number",
        type: "text",
        default: true,
      },
      {
        name: "registrationId",
        label: "Registration Number",
        type: "text",
        default: true,
      },
      {
        name: "services",
        label: "Services/modalities offered",
        type: "text",
      },
      {name: "education", label: "Education", type: "text", default: true},
      {
        name: "providerTypeData.name",
        label: "Provider Type",
        type: "radio",
        options: [
          "Psychiatrist",
          "Psychologist",
          "Social Worker",
          "Sociologist",
        ],
      },
      {
        name: "supervisorData.firstName",
        label: "Supervisor",
        type: "text",
        default: true,
      },
      {
        name: "practitionerNumber",
        label: "ClinicAid Practitioner Number",
        type: "text",
      },
      {
        name: "ontarioGroupNumber",
        label: "ClinicAid Group Number",
        type: "text",
      },
      {
        name: "ontarioSpecialty",
        label: "ClinicAid Speciality",
        type: "text",
      },
      {
        name: "ontarioMasterNumber",
        label: "ClinicAid Master Number",
        type: "text",
      },
      {
        name: "ontarioServiceLocationIndicator",
        label: "ClnicAid Service Locator Indicator",
        type: "text",
      },
    ],
  },
  {
    name: "Services",
    color: "green",
    collectionName: "services",
    fields: [
      {name: "serviceName", label: "Service Name", type: "text", default: true},
      {
        name: "category",
        label: "Category",
        type: "text",
        default: true,
      },
      {
        name: "description",
        label: "Service Description",
        type: "text",
        default: true,
      },
      {
        name: "non-sessions",
        label: "Non-Session Services",
        type: "radio",
        options: ["True", "False"],
        default: true,
      },
      {
        name: "taxable",
        label: "Taxable",
        type: "radio",
        options: ["True", "False"],
        default: true,
      },
      {
        name: "isfixedCost",
        label: "Fixed Cost",
        type: "radio",
        options: ["True", "False"],

        default: true,
      },
      {
        name: "defaultDuration",
        label: "Default Duration",
        type: "number",
        default: true,
      },
      {
        name: "defaultCost",
        label: "Default Cost/hr",
        type: "number",
        default: true,
      },
    ],
  },
  {
    name: "Appointments",
    collectionName: "appointments",
    color: "orange",
    fields: [
      {name: "calcDate", label: "Date", type: "date", default: true},
      {name: "time", label: "Time", type: "date", default: true},
      {name: "duration", label: "Duration", type: "number", default: true},
      {
        name: "doctorData.firstName",
        label: "Clinician First Name",
        type: "text",
        default: true,
      },
      {
        name: "doctorData.lastName",
        label: "Clinician Last Name",
        type: "text",
        default: true,
      },
      {
        name: "patientData.fName",
        label: "Patient First Name",
        type: "text",
        default: true,
      },
      {
        name: "patientData.lName",
        label: "Patient Last Name",
        type: "text",
        default: true,
      },
      {
        name: "supervisorData.firstName",
        label: "Supervisor First Name",
        type: "text",
        default: true,
      },
      {
        name: "supervisorData.lastName",
        label: "Supervisor Last Name",
        type: "text",
        default: true,
      },
      {name: "service", label: "Service", type: "text", default: true},
      {name: "room", label: "Room", type: "text"},
      {name: "paymentStatus", label: "Payment Status", type: "text"},
      {name: "serviceAmount", label: "Service Cost", type: "number"},
      {
        name: "pConfirmed",
        label: "Patient Confirmed",
        type: "radio",
        options: ["True", "False"],
        default: true,
      },
      {
        name: "cancelled",
        label: "Cancelled",
        type: "radio",
        options: ["True", "False"],
      },
      {
        name: "noShow",
        label: "No Show",
        type: "radio",
        options: ["True", "False"],
      },
    ],
  },
];
