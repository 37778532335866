import {
  Button,
  ButtonGroup,
  Thead,
  Th,
  Tr,
  Table,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  CloseButton,
  Select,
} from "@chakra-ui/react";
import {
  addMonths,
  startOfMonth,
  subMonths,
  addWeeks,
  subWeeks,
  startOfYear,
  subYears,
  lightFormat,
  startOfToday,
} from "date-fns";
import {useEffect, useState} from "react";

export default function Filters({query, setQuery}) {
  /*const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [amountType, setAmountType] = useState("overallOutstandingBalance");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");*/
  const [instantQuery, setInstantQuery] = useState(false);
  const filters = query.filters || {};
  let {daysRange, fromDate, toDate, amountType, fromAmount, toAmount} = filters;

  // console.log({daysRange, fromDate, toDate, amountType, fromAmount, toAmount});

  const setDateQuery = () => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      filters: {
        ...(prevQuery.filters || {}),
        fromDate: fromDate,
        toDate: toDate,
      },
    }));
  };

  /*useEffect(() => {
    if (instantQuery) {
      setDateQuery();
      setInstantQuery(false);
    }
  }, [instantQuery]);*/

  return (
    <>
      <Box mb="4" py="2" bg="gray.50" rounded="md">
        <Table border="hidden">
          <Thead>
            <Tr>
              <Th>Filter By Date Range:</Th>
            </Tr>
          </Thead>
        </Table>
        <ButtonGroup size="sm" mx="3">
          <Button
            colorScheme="blue"
            variant="ghost"
            color={daysRange === "NEXT_3_MONTHS" ? "blue.800" : null}
            onClick={() => {
              let m = startOfMonth(new Date());
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery?.filters || {}),
                  fromDate: m.getTime(),
                  toDate: addMonths(m, 3).getTime(),
                  daysRange: "NEXT_3_MONTHS",
                },
              }));
              setInstantQuery(true);
            }}
          >
            Next 3 months
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={daysRange === "NEXT_4_WEEKS" ? "blue.800" : null}
            onClick={() => {
              let d = startOfToday();
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery?.filters || {}),
                  fromDate: d.getTime(),
                  toDate: addWeeks(d, 4).getTime(),
                  daysRange: "NEXT_4_WEEKS",
                },
              }));

              setInstantQuery(true);
            }}
          >
            Next 4 weeks
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={daysRange === "LAST_4_WEEKS" ? "blue.800" : null}
            onClick={() => {
              let d = startOfToday();
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery?.filters || {}),
                  fromDate: subWeeks(d, 4).getTime(),
                  toDate: d.getTime(),
                  daysRange: "LAST_4_WEEKS",
                },
              }));
              setInstantQuery(true);
            }}
          >
            Last 4 weeks
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={daysRange === "LAST_3_MONTHS" ? "blue.800" : null}
            onClick={() => {
              let m = startOfMonth(new Date());
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery?.filters || {}),
                  fromDate: subMonths(m, 3).getTime(),
                  toDate: m.getTime(),
                  daysRange: "LAST_3_MONTHS",
                },
              }));
              setInstantQuery(true);
            }}
          >
            Last 3 months
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={daysRange === "LAST_6_MONTHS" ? "blue.800" : null}
            onClick={() => {
              let m = startOfMonth(new Date());
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery?.filters || {}),
                  fromDate: subMonths(m, 6).getTime(),
                  toDate: m.getTime(),
                  daysRange: "LAST_6_MONTHS",
                },
              }));
              setInstantQuery(true);
            }}
          >
            Last 6 months
          </Button>{" "}
          <Button
            colorScheme="blue"
            variant="ghost"
            color={daysRange === "LAST_YEAR" ? "blue.800" : null}
            onClick={() => {
              let m = startOfYear(new Date());
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery?.filters || {}),
                  fromDate: subYears(m, 1).getTime(),
                  toDate: m.getTime(),
                  daysRange: "LAST_YEAR",
                },
              }));
              setInstantQuery(true);
            }}
          >
            Last year
          </Button>{" "}
        </ButtonGroup>
        <Flex mx="6" my="2">
          <InputGroup size="sm" mr="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="5">
              From:
            </InputLeftElement>
            <Input
              type="date"
              id="fromDate"
              size="sm"
              pl="16"
              value={fromDate ? lightFormat(fromDate, "yyyy-MM-dd") : ""}
              onChange={(e) =>
                setQuery((prevQuery) => {
                  let {value} = e.target;
                  let dateArray = [];
                  if (value) {
                    dateArray = value.split("-");
                    dateArray[1]--;
                  }

                  let inputDate = new Date(...dateArray);
                  return {
                    ...prevQuery,
                    filters: {
                      ...(prevQuery.filters || {}),
                      fromDate: value ? inputDate.getTime() : "",
                      daysRange: "",
                    },
                  };
                })
              }
            />
          </InputGroup>
          <InputGroup size="sm" ml="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="5">
              To:
            </InputLeftElement>
            <Input
              type="date"
              id="toDate"
              size="sm"
              pl="16"
              value={toDate ? lightFormat(toDate, "yyyy-MM-dd") : ""}
              onChange={(e) =>
                setQuery((prevQuery) => {
                  let {value} = e.target;
                  let dateArray = [];
                  if (value) {
                    dateArray = value.split("-");
                    dateArray[1]--;
                  }

                  let inputDate = new Date(...dateArray);
                  inputDate.setHours(23, 59);
                  return {
                    ...prevQuery,
                    filters: {
                      ...(prevQuery.filters || {}),
                      toDate: value ? inputDate.getTime() : "",
                      daysRange: "",
                    },
                  };
                })
              }
            />
          </InputGroup>
          {/*<Button
            colorScheme="blue"
            variant="ghost"
            ml="4"
            mt="-1"
            onClick={setDateQuery}
          >
            Filter
          </Button>*/}
          {fromDate && toDate && (
            <Button
              colorScheme="blue"
              variant="ghost"
              ml="2"
              mt="-1"
              onClick={() => {
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  filters: {
                    ...(prevQuery?.filters || {}),
                    fromDate: null,
                    toDate: null,
                    daysRange: "",
                  },
                }));
                setInstantQuery(true);
              }}
            >
              <CloseButton size="sm" ml="2" color="blue.600" /> Clear
            </Button>
          )}
        </Flex>
      </Box>

      <Box mb="6" py="2" bg="gray.50" rounded="md">
        <Table border="hidden">
          <Thead>
            <Tr>
              <Th>Filter By ($) Amount:</Th>
            </Tr>
          </Thead>
        </Table>
        <Flex mx="6" my="2">
          <Select
            mr="4"
            w="700px"
            placeholder="-"
            id="amountType"
            size="sm"
            value={amountType || ""}
            onChange={(e) =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery.filters || {}),
                  amountType: e.target.value,
                  fromAmount: e.target.value
                    ? (prevQuery.filters || {}).fromAmount || ""
                    : "",
                  toAmount: e.target.value
                    ? (prevQuery.filters || {}).toAmount
                    : "",
                },
              }))
            }
          >
            <option value="overallOutstandingBalance">
              Overall Outstanding Balance
            </option>
            <option value="patientOutstandingBalance">
              Patient Outstanding Balance
            </option>
            <option value="tppOutstandingBalance">
              TPP Outstanding Balance
            </option>
            <option value="overallCostPaid">Overall Cost Paid</option>
            <option value="patientCostPaid">Patient Cost Paid</option>
            <option value="tppCostPaid">TPP Cost Paid</option>
            <option value="overallServiceCost">Overall Service Cost</option>
            <option value="patientServiceCost">Patient Service Cost</option>
            <option value="tppServiceCost">TPP Service Cost</option>
          </Select>
          <InputGroup size="sm" mr="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} ml="1rem" w="50px">
              From: $
            </InputLeftElement>
            <Input
              id="fromAmount"
              type="number"
              size="sm"
              pl="5.25rem"
              placeholder="Min"
              value={fromAmount || ""}
              onChange={(e) =>
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  filters: {
                    ...(prevQuery.filters || {}),
                    fromAmount: e.target.value,
                  },
                }))
              }
            />
          </InputGroup>
          <InputGroup size="sm" ml="2" rounded="md">
            <InputLeftElement fontWeight={"semibold"} ml="0.2rem" w="50px">
              To: $
            </InputLeftElement>
            <Input
              id="toAmount"
              type="number"
              size="sm"
              pl="16"
              placeholder="Max"
              value={toAmount || ""}
              onChange={(e) =>
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  filters: {
                    ...(prevQuery.filters || {}),
                    toAmount: e.target.value,
                  },
                }))
              }
            />
          </InputGroup>
          {/*<Button
            colorScheme="blue"
            variant="ghost"
            ml="4"
            mt="-1"
            onClick={() =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                filters: {
                  ...(prevQuery.filters || {}),
                  amountType: amountType,
                  fromAmount: fromAmount,
                  toAmount: toAmount,
                },
              }))
            }
          >
            Filter
          </Button>*/}
          {(fromAmount || toAmount) && (
            <Button
              colorScheme="blue"
              variant="ghost"
              ml="2"
              mt="-1"
              onClick={() => {
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  filters: {
                    ...(prevQuery.filters || {}),
                    amountType: amountType,
                    fromAmount: null,
                    toAmount: null,
                  },
                }));
              }}
            >
              <CloseButton size="sm" ml="2" color="blue.600" /> Clear
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
}
