import {Icon, Tooltip} from "@chakra-ui/react";
export default function PartialPaidIcon({appt}) {
  if (!appt) return null;
  let patientAmount = parseFloat(appt.patientAmount ?? 0);
  let tppAmount = parseFloat(appt.tppAmount ?? 0);

  let amountPaidByPatient = parseFloat(appt.amountPaidByPatient ?? 0);
  let amountPaidByTpp = parseFloat(appt.amountPaidByTpp ?? 0);
  let otbp = patientAmount - amountPaidByPatient;
  let otbi = tppAmount - amountPaidByTpp;

  let paid = otbp === 0 && otbi === 0;

  let status = paid
    ? "Fully paid"
    : amountPaidByPatient > 0 || amountPaidByTpp > 0
    ? "Partially paid"
    : "No amount paid";

  let color = "#f87171";
  switch (status) {
    case "Fully paid":
      color = "#38A169";
      break;
    case "Partially paid":
      color = "#facc15";
      break;
    case "No amount paid":
      color = "#f87171";
      break;

    default:
      break;
  }

  return (
    <Tooltip hasArrow label={status} rounded={"md"}>
      <p
        style={{color, borderColor: color}}
        className="absolute  overflow-hidden font-bold text-[12px] top-1 left-1  h-[16px] w-[16px] flex justify-center items-center border-[2px]  rounded-full"
      >
        $
      </p>
    </Tooltip>
  );
}

/*<span
          style={{
            height:
              status === "Fully paid"
                ? "0%"
                : status === "Partially paid"
                ? "50%"
                : "100%",
          }}
          className="absolute opacity-80  bottom-0 w-full  bg-gray-700"
        ></span>*/
