import React, {useState, useReducer} from "react";
import {BsPersonCircle} from "react-icons/bs";
import {scheduleReducer, init} from "../additional_files/reducer";

function ModifyLocation({
  mode,
  gState,
  allUsers,
  onToggle,
  currentLid,
  locationsMap,
}) {
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [availableAdmins, setAvailableAdmins] = useState([]);
  const [availableDoctors, setAvailableDoctors] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [_, dispatch] = useReducer(scheduleReducer, gState, init);

  const entries = mode === "doctor" ? availableDoctors : availableAdmins;

  const updateDoctorsFromGlobalState = (newAccess, drIds) => {
    const newDoctors = gState.doctors.map((dr) => {
      if (drIds.includes(dr.did)) {
        dr.access = newAccess;
      }
      return dr;
    });

    dispatch({type: "UPDATE_MULTIPLE_DOCTORS", payload: newDoctors});
  };

  return (
    <div className="bg-gradient-to-b from-[#2d29ff44] to-[#7b79ff0f] rounded-lg p-5 w-[500px] min-h-[200px]">
      <h3 className="font-[600] text-xl text-dark mb-2">
        {mode === "doctor" ? "Clinician" : "Non super-admin"} Location
      </h3>
      {/* <Tabs openTab={openTab} setOpenTab={setOpenTab} tabs={tabs} /> */}
      <div className="flex  justify-between w-full">
        <SelectProfile
          mode={mode}
          profiles={allUsers}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          onToggle={onToggle}
          locationsMap={locationsMap}
          currentLid={currentLid}
        />
      </div>
    </div>
  );
}

const SelectProfile = ({
  profiles,
  selectedIds,
  setSelectedIds,
  mode,
  onToggle,
  locationsMap,
  currentLid,
}) => {
  const id = mode === "doctor" ? "did" : "id";
  const isInLocation = (profile) => {
    const arr = locationsMap.get(currentLid)?.[mode];
    const status =
      arr?.length > 0 && arr.some((prof) => prof[id] === profile[id]);

    if (profile.name === "Erik Michalik") {
      console.log("arr length", arr?.length);

      console.log(
        `mode ${mode} - Profile ${profile.name} is ${status} in ${currentLid}`
      );
      console.log(locationsMap.get(currentLid));
    }

    return status;
  };

  const toggleSelected = (id) => () => {
    setSelectedIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(id)) {
        newIds.delete(id);
      } else {
        newIds.add(id);
      }
      return newIds;
    });
  };

  return (
    <ul className="overflow-y-scroll max-h-[125px]  sbar2 sbar2-purple w-full  space-y-1">
      {profiles.map((profile, i) => (
        <li
          key={i}
          onClick={(e) => onToggle(e, profile)}
          className={`hover:cursor-pointer ${
            isInLocation(profile) ? "bg-dark" : "bg-med"
          } w-full  justify-start flex items-center px-1 rounded-full h-[40px] mb-[3px] space-x-1 gap-3 `}
        >
          <div className=" h-[35px] w-[35px]">
            {profile.photo ? (
              <img
                src={profile.photo}
                alt=""
                className="h-full w-full rounded-full drop-shadow-lg"
              />
            ) : (
              <BsPersonCircle className="w-full h-full text-white" />
            )}
          </div>
          <div className="flex flex-col">
            <p className="text-white text-[18x] leading-3 font-medium">
              {`${profile.name}`}
            </p>
            <span className="font-light text-[12px] text-white">
              {mode === "doctor" ? "MD, FCRCP" : ""}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ModifyLocation;
