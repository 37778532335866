import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef} from "react";
import {
  checkResetParams,
  resetPassword,
  checkApptConfirmation,
} from "../authRequests";
import {useParams, useNavigate} from "react-router-dom";
import {crud, viewOrganizationByUrl} from "../crudRequests";
import clientInfo from "../additional_files/loginInfo";
import axios from "axios";
import {Center, VStack, Icon, Image, Text, Divider} from "@chakra-ui/react";
import {GiConfirmed} from "react-icons/gi";
import {lightFormat} from "date-fns";
import {VscError} from "react-icons/vsc";
import {FaRegCheckCircle} from "react-icons/fa";
export default function AppointmentConfirmation() {
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [urlBasedOrg, setUrlBasedOrg] = useState();
  const [confirmationData, setConfirmationData] = useState({});
  const [invalid, setInvalid] = useState(false);

  useEffect(function () {
    let action = async () => {
      try {
        let org = await viewOrganizationByUrl(
          /localhost/.test(window.location.host)
            ? "clinic.brightlight.ai"
            : //  : /azurestaticapps/.test(window.location.host)
              //? "clinic.brightlight.ai" // "internal.brightlight.ai"
              window.location.host
        );
        let params = window.location.href.split("?params=")[1];
        let date = lightFormat(new Date(), "dd/MM/yyyy h:mm aaa");
        let apptCnfData = await checkApptConfirmation(params, date);
        setConfirmationData(apptCnfData.data);
        setUrlBasedOrg(org);
      } catch (error) {
        setInvalid(true);
        console.log(error);
      }
    };
    action();
  }, []);

  return (
    <Center bg={"gray.100"} w="full" h="full">
      <VStack
        bg="white"
        w={{base: "90%", sm: "33rem"}}
        gap={6}
        p={10}
        boxShadow={"md"}
        rounded={"xl"}
      >
        {urlBasedOrg?.logo ? (
          <Image
            w="14rem"
            src={urlBasedOrg?.logo}
            alt={urlBasedOrg.name || ""}
          />
        ) : (
          <Image w="14rem" src="/images/logo.png" />
        )}
        <Divider />
        {!invalid ? (
          <>
            <Icon
              className="show-icon"
              boxSize={14}
              color={"blue.600"}
              as={FaRegCheckCircle}
            />
            <Text
              w={"full"}
              textAlign={"center"}
              color={"gary.400"}
              fontSize={"lg"}
            >
              <Text as="span">{"Your appointment with "}</Text>
              <Text as="span">{confirmationData?.appointment?.dName}</Text>
              <Text as="span">{" at "}</Text>
              <Text as="span">
                {confirmationData?.appointment?.ISOdate
                  ? lightFormat(
                      confirmationData?.appointment?.ISOdate,
                      " dd/MM/yyyy hh:mm aaaa"
                    )
                  : " "}
              </Text>
              <Text as="span">{" has been "}</Text>
              <Text as="span" fontWeight={600}>
                Confirmed
              </Text>
              <Text as="span">!</Text>
            </Text>
          </>
        ) : (
          <>
            <Icon
              className="show-icon"
              boxSize={14}
              color={"red.600"}
              as={VscError}
            />
            <Text as="span" fontWeight={600}>
              Invalid link!
            </Text>
          </>
        )}
      </VStack>
    </Center>
  );
}
