import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Text,
  Center,
  Divider,
} from "@chakra-ui/react";

export default function CodesTable({codesType, onSelect, codes}) {
  return (
    <>
      <Flex
        justify={"start"}
        w={"100%"}
        fontWeight={500}
        fontSize="14px"
        color={"gray.600"}
      >
        <Text p={"8px"} w="70px">
          CODE
        </Text>

        {codesType === "service" && (
          <Text p={"8px"} w="80px">
            AMOUNT
          </Text>
        )}

        <Text p={"8px"} flex={1}>
          DESCRIPTION
        </Text>
      </Flex>
      <Divider />
      <TableContainer flex={1} overflowY="auto" height={"100%"} width={"100%"}>
        <Table width={"100%"} variant="simple">
          <Tbody>
            {codes.map((c, i) => (
              <Tr
                key={i}
                cursor={"pointer"}
                fontSize="14px"
                onClick={() => onSelect(c.code, c.amount || "")}
                _hover={{bgColor: "gray.50"}}
              >
                <Td p={"8px"} w="70px">
                  {c.code}
                </Td>
                {codesType === "service" && (
                  <Td p="8px" w="80px">
                    {c.amount}
                  </Td>
                )}
                <Td maxW={"200px"} p="8px" whiteSpace={"wrap"}>
                  {c.description}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
