import {CheckCircleIcon, InfoIcon, WarningIcon} from "@chakra-ui/icons";
import {
  Container,
  Flex,
  Grid,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {lightFormat} from "date-fns";

const Message = ({message, symptoms}) => {
  return (
    <Container maxW={"100%"}>
      {message.author === "bot" ? (
        <>
          <Flex alignItems={"end"}>
            <Text
              m={"10px"}
              ml={"7px"}
              mb="0"
              py={"6px"}
              px={"6px"}
              fontSize={"sm"}
              bg={"gray.200"}
              borderRadius={"xl"}
              maxW={"50%"}
            >
              <Text pt={"4px"} pr={"30px"} pl={"6px"}>
                {message.text}
              </Text>
              <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
                {lightFormat(new Date(message.timestamp), "HH:mm")}
              </Text>
            </Text>
          </Flex>
        </>
      ) : (
        <>
          <Flex justifyContent={"flex-end"}>
            <Text
              mb={"-2.5px"}
              m={"3px"}
              mt="1.5"
              py={"6px"}
              px={"6px"}
              fontSize={"sm"}
              bg={"#4185F0"}
              color={"white"}
              borderRadius={"xl"}
              maxW={"50%"}
            >
              <Text pt={"4px"} pr={"30px"} pl={"6px"}>
                {message.text}
              </Text>

              <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
                {lightFormat(new Date(message.timestamp), "HH:mm")}
              </Text>
            </Text>
          </Flex>
          <HStack justifyContent="flex-end" ml="3" mt="1" spacing="1">
            {message?.symptoms?.improvements?.filter((n) => n)?.length > 0 && (
              <>
                <Popover>
                  <PopoverTrigger>
                    <CheckCircleIcon color="green.500" />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Improvement(s) detected</PopoverHeader>
                    <PopoverBody>
                      {message?.symptoms?.improvements
                        .map(
                          (item) =>
                            symptoms?.filter(
                              (symptom) => symptom.symId === item
                            )?.[0]?.shortDesc
                        )
                        ?.join(", ")}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </>
            )}
            {message?.events?.filter((n) => n)?.length > 0 && (
              <Popover>
                <PopoverTrigger>
                  <InfoIcon color="blue.500" />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Events(s) detected</PopoverHeader>
                  <PopoverBody>
                    {message?.events
                      .map(
                        (item) =>
                          symptoms?.filter(
                            (symptom) => symptom.symId === item
                          )?.[0]?.shortDesc
                      )
                      ?.join(", ")}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
            {message?.symptoms?.deteriorations?.filter((n) => n)?.length >
              0 && (
              <Popover>
                <PopoverTrigger>
                  <WarningIcon color="yellow.500" />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Deterioration(s) detected</PopoverHeader>
                  <PopoverBody>
                    {message?.symptoms?.deteriorations
                      .map(
                        (item) =>
                          symptoms?.filter(
                            (symptom) => symptom.symId === item
                          )?.[0]?.shortDesc
                      )
                      ?.join(", ")}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </HStack>
        </>
      )}
    </Container>
  );
};

export default Message;
