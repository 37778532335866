import React, {
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Icon,
  Checkbox,
  Center
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import {debounce} from 'lodash'

export default function FiltersTasks({
  fetchTasks,
  tasks,
  sort,
  searchQuery,
  setSearchQuery,
  showCompletedRef,
  isPatient
}) {
  const inputRef = useRef(null)
  const widthPercentage = {
    searchInput: isPatient ? '70%' : '63%',
    completedCheckbox: isPatient ? '30%' : '17%'
  }


  const debouncedFetchTasks = useCallback(
    debounce((query, showCompleted, column, order) => {
      fetchTasks(false, query, 1, showCompleted, column, order)
    }, 800),
    []
  )

  const handleSearchChange = (event) => {
    const query = event.target.value
    setSearchQuery(query)
    debouncedFetchTasks(query, showCompletedRef.current, sort.column, sort.direction)
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [tasks])

  return (
    <>
      <Center w={widthPercentage.searchInput}>
        <InputGroup>
          <InputLeftElement>
            <Icon as={IoSearch} color="gray.500" />
          </InputLeftElement>
          <Input
            type="text"
            ml="-1"
            placeholder="Search tasks"
            value={searchQuery}
            onChange={handleSearchChange}
            ref={inputRef}
          />
        </InputGroup>
      </Center>
      <Center w={widthPercentage.completedCheckbox}>
        <Checkbox
          colorScheme="green"
          defaultChecked
          onChange={(e) => {
            showCompletedRef.current = e.target.checked
            fetchTasks(false, searchQuery, 1, e.target.checked, sort.column, sort.direction)
          }}
        >
          <Text as="span" fontSize="sm">Show Completed Tasks</Text>
        </Checkbox>
      </Center>
    </>
  )
}
