import React, {useState, useEffect} from "react";

import {
  Text,
  Flex,
  Stack,
  Checkbox,
  Input,
  Table,
  TableCaption,
  Thead,
  InputLeftElement,
  InputGroup,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
} from "@chakra-ui/react";
import {SortableColumnHeader} from "../SortableColumnHeader";
import {MembershipsCreateModal} from "./MembershipsCreateModal";
import {SearchIcon} from "@chakra-ui/icons";
import {MembershipsDeleteModal} from "./MembershipsDeleteModal";
import {MembershipsEditModal} from "./MembershipsEditModal";

export default function MembershipsTable({schState, dispatch}) {
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({active: true, inactive: false});
  const [membershipsData, setMembershipsData] = useState([]);
  const selectedLocation = schState.locations.find(
    (loc) => loc.lid === schState.selectedLocation
  );
  const [refetch, setRefetch] = useState(false);
  const [sort, setSort] = useState({column: "name", direction: "asc"});
  const fetchData = () => {
    const selectedLocation = schState.locations.find(
      (loc) => loc.lid === schState.selectedLocation
    );
    const responseData = (selectedLocation.memberships || [])
      .filter((membership) => {
        if (!filters.active && membership.status === "Active") return false;
        if (!filters.inactive && membership.status === "Inactive") return false;

        if (membership.status === "Deleted") return false;
        if (membership.name.toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
        if (
          membership.description
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          return true;
        }
        return false;
      })
      .sort((a, b) => {
        if (["price"].includes(sort.column)) {
          if (sort.direction === "asc") {
            return b[sort.column] - a[sort.column];
          } else {
            return a[sort.column] - b[sort.column];
          }
        }
        try {
          if (sort.direction === "asc") {
            return (a[sort.column] || "").localeCompare(b[sort.column] || "");
          } else {
            return (b[sort.column] || "").localeCompare(a[sort.column] || "");
          }
        } catch (e) {}
      });
    setMembershipsData(responseData);
  };
  useEffect(() => {
    fetchData();
    if (refetch) setRefetch(false);
  }, [filters.active, filters.inactive, refetch]);
  return (
    <>
      <Stack
        mx="2rem"
        direction="column"
        spacing={4}
        alignItems="center"
        w="100%"
      >
        <Flex
          justify={"space-between"}
          w="full"
          align={"center"}
          p="2"
          pt="36px"
        >
          <Text color={"blue.400"} fontWeight={"bold"} fontSize={"2rem"}>
            Memberships
          </Text>
        </Flex>
        <Stack
          w="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <InputGroup w="25%">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              value={searchValue}
              onChange={(evt) => setSearchValue(evt.target.value)}
              placeholder="Search memberships"
            />
          </InputGroup>

          <Checkbox
            color="#8F8CFF"
            isChecked={filters.active}
            size="lg"
            fontWeight={600}
            onChange={(evt) =>
              setFilters((prev) => ({...prev, active: evt.target.checked}))
            }
          >
            Active
          </Checkbox>
          <Checkbox
            color="#8F8CFF"
            isChecked={filters.inactive}
            size="lg"
            fontWeight={600}
            onChange={(evt) =>
              setFilters((prev) => ({
                ...prev,
                inactive: evt.target.checked,
              }))
            }
          >
            Inactive
          </Checkbox>

          <MembershipsCreateModal
            schState={schState}
            selectedLocation={selectedLocation}
            dispatch={dispatch}
          />
        </Stack>
        <Box overflowY="scroll" maxHeight="40%" width="full">
          <Table size="lg">
            <Thead bg="gray.100">
              <Tr>
                <SortableColumnHeader
                  label="Membership name"
                  currentSort={sort}
                  column="name"
                  onSort={setSort}
                />
                <SortableColumnHeader
                  label="Description"
                  currentSort={sort}
                  column="description"
                  onSort={setSort}
                />
                <SortableColumnHeader
                  label="Price"
                  currentSort={sort}
                  column="price"
                  onSort={setSort}
                />
                <SortableColumnHeader
                  label="Status"
                  currentSort={sort}
                  column="status"
                  onSort={setSort}
                />
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {membershipsData.map((membership) => {
                return (
                  <Tr key={membership.mid}>
                    <Td>
                      <Text>{membership.name}</Text>
                    </Td>
                    <Td>
                      <Text>{membership.description}</Text>
                    </Td>
                    <Td>
                      <Text>{`$${membership.price}`}</Text>
                    </Td>
                    <Td>
                      <Text>{membership.status}</Text>
                    </Td>
                    <Td>
                      <MembershipsEditModal
                        schState={schState}
                        membership={membership}
                        selectedLocation={selectedLocation}
                        dispatch={dispatch}
                        refetch={() => {
                          setRefetch(true);
                        }}
                      />
                      <MembershipsDeleteModal
                        schState={schState}
                        membership={membership}
                        selectedLocation={selectedLocation}
                        dispatch={dispatch}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Text fontSize="xs">This location's memberships</Text>
      </Stack>
    </>
  );
}
