import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef} from "react";
import {
  loginUser,
  resetUserEmail,
  verifyLoginCode,
  setAuthCode,
} from "../authRequests";

export default function ForgotPwdForm({org}) {
  const resetEmailRef = useRef(null);
  const [serverError, setServerError] = useState("");
  const [authError, setAuthError] = useState("");
  const [loading, setLoading] = useState("");
  const [sentMsg, setSentMsg] = useState("");

  function reset(e) {
    e.preventDefault();
    setLoading(true);
    setAuthError(false);
    setServerError(false);

    resetUserEmail(resetEmailRef.current.value,undefined, org)
      .then((res) => {
        setLoading(false);
        setSentMsg(true);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          setAuthError(true);
          setLoading(false);
        } else if (err.response?.status == 404 || err.response?.status == 500) {
          setServerError(true);
          setLoading(false);
        } else console.log(err.response?.status);
        setLoading(false);
        setSentMsg(false);
      });
  }
  
  return (
    <div>
      <form onSubmit={reset} className="border p-12 rounded-md">
          {org?.logo ? (
              <img src={org?.logo} className="w-48 mx-auto mb-5" />
            ) : (<img src="/images/logo.png" className="w-48 mx-auto mb-5" />)}
        
        {authError && (
          <div className="">
            <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center justify-center w-12 bg-red-500">
                <svg
                  className="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                </svg>
              </div>

              <div className="px-4 py-2 -mx-3">
                <div className="mx-3">
                  <span className="font-semibold text-red-500 dark:text-red-400">
                    Error
                  </span>
                  <p className="text-sm text-gray-600 dark:text-gray-200">
                    Invalid email!
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {serverError && (
          <div className="">
            <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center justify-center w-12 bg-red-500">
                <svg
                  className="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                </svg>
              </div>

              <div className="px-4 py-2 -mx-3">
                <div className="mx-3">
                  <span className="font-semibold text-red-500 dark:text-red-400">
                    Error
                  </span>
                  <p className="text-sm text-gray-600 dark:text-gray-200">
                    <span className="font-semibold">
                      The server has encountered an
                      <br />
                      internal error.
                    </span>
                    <br />
                    Please try again later.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="input-group">
          <div className="input-label text-xs -ml-3 -mt-3">
            Please enter the email registered with your Brightlight Account to
            access a secure link to change your password.
          </div>
          <input
            type="text"
            id="email"
            ref={resetEmailRef}
            required
            autoComplete="off"
            className="input mt-[4.5rem] w-72 py-3 px-6 rounded-[4px] my-2 focus:border-[#3871e0] focus:ring-1 focus:ring-[#3871e0] border"
          />
          <label htmlFor="email" className="input-label mt-[4rem]">
            Email
          </label>
        </div>
        <div className="justify-center">
          <button
            type="submit"
            disabled={loading}
            className="w-72 p-2.5 px-5 rounded-[4px] my-2 flex justify-center items-center bg-dark text-background font-medium text-medium right cursor-pointer"
          >
            Send reset link
            {loading && (
              <span className="">
                <svg
                  className="animate-spin ml-2 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
        {sentMsg && (
          <div className="w-72 text-gray-500 mt-8 rounded-md">
            <div className="bg-light bg-opacity-25 p-4 rounded-md input-label text-xs -mt-3">
              You should have recieved an email with a verification link to
              reset your password.
              <br />
              <br />
              Please follow that link to continue or{" "}
              <span className="text-dark cursor-pointer" onClick={reset}>
                resend
              </span>{" "}
              the email.
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
