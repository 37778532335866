import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  Text,
  Button,
} from "@chakra-ui/react";
import {stringifyValue} from "./helpers/stringifyValue";
import {useEffect, useState} from "react";
import AidClaimModal from "../appointments/clinicAidClaimModal";

export default function SimplePaymentsList({
  payments,
  fields,
  query,
  searchText,
}) {
  //useEffect(() => {}, [query]);

  return (
    <Box overflowY="auto">
      <Table size="md">
        <Thead position="sticky" top="0" bg="gray.100">
          <Tr>
            {Object.keys(fields).map((field) => {
              return <Th fontSize={"11px"}>{field}</Th>;
            })}

            {/*<Th fontSize={"11px"}>Submit ClinicAid Claim</Th>*/}
          </Tr>
        </Thead>
        <Tbody>
          {(query.fromDate && query.toDate
            ? payments?.filter((payment) => {
                const paymentDate = new Date(payment.date);
                const fromDate = new Date(query.fromDate);
                const toDate = new Date(query.toDate);

                return paymentDate >= fromDate && paymentDate <= toDate;
              })
            : query.fromAmount || query.toAmount
            ? payments?.filter((payment) => {
                let paymentAmount = 0;
                switch (query.amountType) {
                  case "overallOutstandingBalance":
                    paymentAmount =
                      payment?.tppAmount -
                      payment?.amountPaidByTpp +
                      (payment?.patientAmount - payment?.amountPaidByPatient);
                    break;
                  case "patientOutstandingBalance":
                    paymentAmount =
                      payment?.patientAmount - payment?.amountPaidByPatient;
                    break;
                  case "tppOutstandingBalance":
                    paymentAmount =
                      payment?.tppAmount - payment?.amountPaidByTpp;
                    break;
                  case "overallCostPaid":
                    paymentAmount =
                      payment?.amountPaidByTpp + payment?.amountPaidByPatient;
                    break;
                  case "patientCostPaid":
                    paymentAmount = payment?.amountPaidByPatient;
                    break;
                  case "tppCostPaid":
                    paymentAmount = payment?.amountPaidByTpp;
                    break;
                  case "overallServiceCost":
                    paymentAmount = payment?.tppAmount + payment?.patientAmount;
                    break;
                  case "patientServiceCost":
                    paymentAmount = payment?.patientAmount;
                    break;
                  case "tppServiceCost":
                    paymentAmount = payment?.tppAmount;
                    break;
                  case "amount":
                    paymentAmount = payment?.amount;
                    break;
                }
                const fromAmount = query.fromAmount || 0;
                const toAmount = query.toAmount || Infinity;

                return paymentAmount >= fromAmount && paymentAmount <= toAmount;
              })
            : payments
          )
            ?.filter((payment) =>
              Object.values(fields).some((field) => {
                if (field === "amount") {
                  return String(payment.amount)
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase());
                }
                return String(
                  stringifyValue(
                    field
                      .split(".")
                      .reduce((o, k) => (o ? o[k] : null), payment)
                  )
                )
                  ?.toLowerCase()
                  ?.includes(searchText.toLowerCase());
              })
            )
            ?.map((payment, i) => {
              return (
                <Tr key={i}>
                  {Object.values(fields).map((field) => (
                    <Td fontSize={"15px"}>
                      {field === "date"
                        ? new Date(payment[field]).toLocaleString()
                        : field === "amount"
                        ? parseFloat(payment[field] || 0).toFixed(2)
                        : payment[field] ?? "N/A"}
                    </Td>
                  ))}
                </Tr>
              );
            })}
        </Tbody>
        {payments.length == 0 && (
          <TableCaption mb="4">No payments to display.</TableCaption>
        )}
      </Table>
    </Box>
  );
}
