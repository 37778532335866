export function chatReducer(state, action) {
  switch (action.type) {
    case "UPDATE_STATE": {
      let conversations = [action.payload];
      let rooms = [action.payload.conversationId];

      return {
        ...state,
        rooms,
        conversations,
        selectedChat: conversations.length > 0 ? conversations[0] : null,
        loading: false,
      };
    }

    case "CHANGE_CHAT": {
      return {...state, selectedChat: action.payload};
    }

    case "SET_USER_STATUS": {
      let {userId, status} = action.payload;
      return {
        ...state,
        usersStatus: {...state.usersStatus, [userId]: status},
      };
    }
    case "SET_USERS_CURRENT_STATUS": {
      let status = action.payload;
      let usersStatus = {...state.usersStatus};
      for (let [userId, s] of Object.entries(status)) {
        usersStatus[userId] = s;
      }
      return {...state, usersStatus};
    }
    case "WRITING": {
      if (!action.payload) return {...state, writing: null};
      let {chatId, userId} = action.payload;
      return {...state, writing: {chatId, userId}};
    }

    case "SET_LOADING": {
      return {...state, loading: action.payload};
    }

    case "NEW_CONVERSATION": {
      let newConversation = action.payload;
      let {conversations, rooms, selectedChat} = state;
      selectedChat = selectedChat || newConversation;

      return {
        ...state,
        conversations: [newConversation].concat(conversations),
        rooms: [newConversation.conversationId].concat(rooms),
        selectedChat,
        loading: false,
      };
    }

    case "NEW_MESSAGE": {
      let index = state.conversations.findIndex(
        (c) => c.conversationId === action.payload.conversationId
      );

      if (index !== -1) {
        let conversation = state.conversations[index];
        let messages = conversation.messages;

        let selectedChat =
          state.selectedChat.conversationId === action.payload.conversationId
            ? {...conversation, messages: messages.concat(action.payload)}
            : state.selectedChat;
        return {
          ...state,
          conversations: [
            ...state.conversations.slice(0, index),
            {...conversation, messages: messages.concat(action.payload)},
            ...state.conversations.slice(index + 1),
          ],
          selectedChat,
          writing: null,
        };
      }

      return state;
    }

    default:
      return state;
  }
}
