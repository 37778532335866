export const getAuthor = (dashState) => {
  if (dashState.userType === "admin") {
    return {
      id: dashState.admin.id,
      name: dashState.admin.name,
      photo: dashState.admin.photo,
    };
  } else if (dashState.userType === "doctor") {
    return {
      id: dashState.doctor.did,
      name: dashState.doctor.name,
      photo: dashState.doctor.photo,
    };
  } else {
    console.error("Unknown user type", dashState.userType);
    return {};
  }
};
