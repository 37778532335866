import React, {useContext, useState, useEffect} from "react";
import {
  Box,
  Text,
  useToast,
  Flex,
  Tooltip,
  Input,
  Button,
} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import {fetchSleep} from "./helpers/fetchSleep";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import {
  calculateSleepIntervals,
  formatMonthAndDay,
  colors,
  titles,
} from "./helpers/calcTimeline";
import {Legend, NotesPopover} from "./helpers/sleepPageComponents";
import {getStartOfWeek} from "./helpers/calcTimeline";
import ReactApexChart from "react-apexcharts";

export default function Sleep() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [sleep, setSleep] = useState(null);
  const [allsleep, setallSleep] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [currentWeekStart, setCurrentWeekStart] = useState(null);
  const toast = useToast();
  let showAbove = true;

  useEffect(() => {
    fetchSleep(setSleep, dashState, patient, toast, setallSleep);
    setCurrentWeekStart(getStartOfWeek(new Date()));
  }, []);

  const formatMonthAndDayRange = (startDate, endDate) => {
    const formattedStart = formatMonthAndDay(startDate);
    const formattedEnd = formatMonthAndDay(endDate);
    return `${formattedStart} - ${formattedEnd}`;
  };

  const handleFilterClick = (startDate2, endDate2) => {
    const startDate = startDate2 ? new Date(startDate2) : null;
    const endDate = endDate2 ? new Date(endDate2) : null;

    const filteredSleep = allsleep.filter((s) => {
      const sleepDate = new Date(s.day);

      if (startDate && endDate) {
        return sleepDate > startDate && sleepDate <= endDate;
      } else if (startDate) {
        return sleepDate > startDate;
      } else if (endDate) {
        return sleepDate <= endDate;
      }

      return true;
    });

    setSleep(filteredSleep);
  };

  const handleToggleWeek = (direction) => {
    const newWeekStart = new Date(currentWeekStart);
    direction === "next"
      ? newWeekStart.setDate(newWeekStart?.getDate() + 7)
      : newWeekStart.setDate(newWeekStart?.getDate() - 7);
    setCurrentWeekStart(getStartOfWeek(newWeekStart));

    const startOfWeek = getStartOfWeek(newWeekStart);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek?.getDate() + 6);

    const filteredSleep = allsleep?.filter((s) => {
      const sleepDate = new Date(s.day);
      return sleepDate >= startOfWeek && sleepDate <= endOfWeek;
    });

    setSleep(filteredSleep);
  };

  const convertTimeStringToDate = (date, timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const resultDate = new Date(date);
    resultDate.setHours(hours);
    resultDate.setMinutes(minutes);
    return resultDate;
  };

  const adjustDateIfNeeded = (baseDate, targetDate) => {
    if (targetDate < baseDate) {
      targetDate.setDate(targetDate?.getDate() + 1);
    }
    return targetDate;
  };

  const calculateSleepEfficiency = (timeAsleepTotal, timeOffsetTotal) => {
    return (
      (timeAsleepTotal / (timeAsleepTotal + timeOffsetTotal)).toFixed(2) * 100
    );
  };

  const timeAsleepTotal = (s) => {
    return (s.time_awake - s.time_asleep) / (1000 * 60);
  };

  const timeOffsetTotal = (s) => {
    return (
      (s.time_asleep - s.time_inbed + (s.time_outofbed - s.time_awake)) /
      (1000 * 60)
    );
  };

  const calcTotalsAndAverages = () => {
    const convertedSleep = sleep?.map((s) => {
      const awakeningActivities = s.activities?.filter(
        (activity) => activity.type === "awakenings"
      );

      const totalAwakeningsDuration = awakeningActivities.reduce(
        (totalDuration, activity) => {
          const startTime = convertTimeStringToDate(s.day, activity.start_time);
          const endTime = adjustDateIfNeeded(
            startTime,
            convertTimeStringToDate(s.day, activity.end_time)
          );
          totalDuration += (endTime - startTime) / (1000 * 60);
          return totalDuration;
        },
        0
      );

      return {
        ...s,
        time_asleep: convertTimeStringToDate(s.day, s.time_asleep),
        time_awake: adjustDateIfNeeded(
          convertTimeStringToDate(s.day, s.time_asleep),
          convertTimeStringToDate(s.day, s.time_awake)
        ),
        time_inbed: convertTimeStringToDate(s.day, s.time_inbed),
        time_outofbed: adjustDateIfNeeded(
          convertTimeStringToDate(s.day, s.time_inbed),
          convertTimeStringToDate(s.day, s.time_outofbed)
        ),
        totalAwakeningsDuration,
      };
    });

    let sleepArray = [];
    let onsetArray = [];
    let awakeningArray = [];
    let sleepEfficiencyArray = [];

    convertedSleep?.forEach((s) => {
      sleepArray.push(timeAsleepTotal(s));
      onsetArray.push(timeOffsetTotal(s));
      awakeningArray.push(s.totalAwakeningsDuration);
      sleepEfficiencyArray.push(
        parseFloat(
          calculateSleepEfficiency(timeAsleepTotal(s), timeOffsetTotal(s))
        )
      );
    });

    const calculateTotal = (array) =>
      array.reduce((total, value) => total + value, 0);
    const calculateAverage = (array) =>
      calculateTotal(array) / array.length || 0;

    return {
      totals: {
        sleepTotal: calculateTotal(sleepArray),
        onsetTotal: calculateTotal(onsetArray),
        awakeningTotal: calculateTotal(awakeningArray),
        sleepEfficiencyTotal: calculateTotal(sleepEfficiencyArray),
      },
      averages: {
        sleepAverage: calculateAverage(sleepArray),
        onsetAverage: calculateAverage(onsetArray),
        awakeningAverage: calculateAverage(awakeningArray),
        sleepEfficiencyAverage: calculateAverage(sleepEfficiencyArray),
      },
    };
  };

  function formatDate2(date1, date2) {
    if (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    ) {
      return date1.getDate();
    } else {
      return `${date1.getDate()}-${date2.getDate()}`;
    }
  }

  const getChartData = () => {
    const convertedSleep = sleep?.map((s) => {
      const awakeningActivities = s.activities?.filter(
        (activity) => activity.type === "awakenings"
      );

      const totalAwakeningsDuration = awakeningActivities.reduce(
        (totalDuration, activity) => {
          const startTime = convertTimeStringToDate(s.day, activity.start_time);
          const endTime = adjustDateIfNeeded(
            startTime,
            convertTimeStringToDate(s.day, activity.end_time)
          );
          totalDuration += (endTime - startTime) / (1000 * 60);
          return totalDuration;
        },
        0
      );

      return {
        ...s,
        time_asleep: convertTimeStringToDate(s.day, s.time_asleep),
        time_awake: adjustDateIfNeeded(
          convertTimeStringToDate(s.day, s.time_asleep),
          convertTimeStringToDate(s.day, s.time_awake)
        ),
        time_inbed: convertTimeStringToDate(s.day, s.time_inbed),
        time_outofbed: adjustDateIfNeeded(
          convertTimeStringToDate(s.day, s.time_inbed),
          convertTimeStringToDate(s.day, s.time_outofbed)
        ),
        totalAwakeningsDuration,
      };
    });

    return convertedSleep?.map((s) => ({
      x: new Date(s.day).toLocaleDateString(),
      y1: timeAsleepTotal(s),
      y2: timeOffsetTotal(s),
      y3: s.totalAwakeningsDuration,
      y4: parseFloat(
        calculateSleepEfficiency(timeAsleepTotal(s), timeOffsetTotal(s))
      ),
    }));
  };

  const optionsSleepStats = {
    chart: {
      id: "basic-line",
    },
    colors: [colors["asleep"], colors["in-bed"], colors["awakenings"]],
    xaxis: {
      type: "category",
      categories: getChartData()?.map((item) =>
        new Date(item.x).toLocaleDateString()
      ),
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Hours",
        },
        labels: {
          formatter: function (value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            let label = "";

            if (hours > 0) {
              label += `${hours.toFixed(0)}h`;
            }

            if (minutes > 0) {
              label += `${label.length > 0 ? " " : ""}${minutes.toFixed(0)}m`;
            }

            return label;
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value) {
          const hours = Math.floor(value / 60);
          const minutes = value % 60;
          return `${hours}h ${minutes}m`;
        },
      },
    },
    title: {
      text: "Sleep Stats",
      align: "center",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
  };

  const optionsSleepEfficiency = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      type: "category",
      categories: getChartData()?.map((item) =>
        new Date(item.x).toDateString()
      ),
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + "%";
        },
      },
    },
    title: {
      text: "Sleep Efficiency Score",
      align: "center",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
  };

  const series = [
    {
      name: "asleep",
      data: getChartData()?.map((item) => item.y1),
    },
    {
      name: "onset",
      data: getChartData()?.map((item) => item.y2),
    },
    {
      name: "awakenings",
      data: getChartData()?.map((item) => item.y3),
    },
    {
      name: "sleep efficiency",
      data: getChartData()?.map((item) => item.y4),
    },
  ];

  const formatDateTo12hr = (date) => {
    const formattedTime = date
      ?.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(/([APMapm]{2})$/g, "")
      .trim();
    const meridiem = date?.getHours() >= 12 ? "p" : "a";
    return `${formattedTime}${meridiem}`;
  };

  const formatHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const formattedMinutes = minutes.toFixed(0);

    return `${hours > 0 ? `${hours} hr` : ""} ${
      formattedMinutes > 0 ? `${formattedMinutes} min` : ""
    }`;
  };

  useEffect(() => {
    console.log({sleep});
  }, [sleep]);

  return (
    <Box width={"100%"} mx="auto" my="6" pb="6" px="8">
      <Flex placeSelf={"flex-end"} gap={8} mb={4} alignItems="center">
        <Flex gap={4}>
          <Input
            type="date"
            placeholder="Start Date"
            value={filterStartDate || ""}
            onChange={(e) => {
              setFilterStartDate(e.target.value);
              handleFilterClick(e.target.value, filterEndDate);
            }}
            size="sm"
          />
          <Input
            type="date"
            placeholder="End Date"
            value={filterEndDate || ""}
            onChange={(e) => {
              setFilterEndDate(e.target.value);
              handleFilterClick(filterStartDate, e.target.value);
            }}
            size="sm"
          />

          {(filterEndDate || filterStartDate) && (
            <Button
              size="sm"
              width="120px"
              onClick={() => {
                setFilterStartDate(null);
                setFilterEndDate(null);
                const startOfWeek = getStartOfWeek(new Date());
                const endOfWeek = new Date(startOfWeek);
                endOfWeek.setDate(startOfWeek?.getDate() + 6);

                const filteredSleep = allsleep.filter((s) => {
                  const sleepDate = new Date(s.day);
                  return sleepDate >= startOfWeek && sleepDate <= endOfWeek;
                });
                setSleep(filteredSleep);
              }}
            >
              Clear
            </Button>
          )}
        </Flex>

        {!filterEndDate && !filterStartDate && (
          <Flex alignItems="center" gap={4}>
            <Button
              rounded={"100%"}
              w={8}
              h={8}
              size="sm"
              onClick={() => handleToggleWeek("prev")}
            >
              <ChevronLeftIcon boxSize={4} />
            </Button>
            <Text
              fontWeight={"bold"}
              fontSize="sm"
            >{`Week of ${formatMonthAndDayRange(
              currentWeekStart,
              new Date(currentWeekStart).setDate(
                currentWeekStart?.getDate() + 6
              )
            )}`}</Text>
            <Button
              rounded={"100%"}
              w={8}
              h={8}
              size="sm"
              onClick={() => handleToggleWeek("next")}
            >
              <ChevronRightIcon boxSize={4} />
            </Button>
          </Flex>
        )}
      </Flex>
      <Legend />

      {sleep && sleep.length > 0 && (
        <Flex flexWrap={"wrap"} mt={"40px"} gap={20} width={"full"}>
          <ReactApexChart
            options={optionsSleepStats}
            series={series.slice(0, 3)}
            type="line"
            height={350}
            width={"175%"}
            flex="1"
          />
          <ReactApexChart
            options={optionsSleepEfficiency}
            series={series.slice(3)}
            type="bar"
            height={350}
            width={"175%"}
            flex="1"
          />
        </Flex>
      )}

      {/* Display totals section */}
      {sleep && sleep.length > 0 && (
        <Flex
          flexWrap={"wrap"}
          fontSize={"sm"}
          mt={8}
          gap={8}
          width={"full"}
          alignItems="center"
        >
          {calcTotalsAndAverages().totals.sleepTotal !== null &&
            calcTotalsAndAverages().totals.sleepTotal !== 0 && (
              <Box bg="gray.100" p={4} borderRadius={8}>
                <Text fontSize="sm">Total Sleep</Text>
                <Text fontWeight="bold" fontSize="md">
                  {formatHoursAndMinutes(
                    calcTotalsAndAverages().totals.sleepTotal
                  )}
                </Text>
              </Box>
            )}

          {calcTotalsAndAverages().totals.onsetTotal !== null &&
            calcTotalsAndAverages().totals.onsetTotal !== 0 && (
              <Box bg="gray.100" p={4} borderRadius={8}>
                <Text fontSize="sm">Total Onset</Text>
                <Text fontWeight="bold" fontSize="md">
                  {formatHoursAndMinutes(
                    calcTotalsAndAverages().totals.onsetTotal
                  )}
                </Text>
              </Box>
            )}

          {calcTotalsAndAverages().totals.awakeningTotal !== null &&
            calcTotalsAndAverages().totals.awakeningTotal !== 0 && (
              <Box bg="gray.100" p={4} borderRadius={8}>
                <Text fontSize="sm">Total Awakenings</Text>
                <Text fontWeight="bold" fontSize="md">
                  {formatHoursAndMinutes(
                    calcTotalsAndAverages().totals.awakeningTotal
                  )}
                </Text>
              </Box>
            )}

          {calcTotalsAndAverages().averages.sleepAverage !== null &&
            calcTotalsAndAverages().averages.sleepAverage !== 0 && (
              <Box bg="gray.100" p={4} borderRadius={8}>
                <Text fontSize="sm">Sleep Average</Text>
                <Text fontWeight="bold" fontSize="md">
                  {formatHoursAndMinutes(
                    calcTotalsAndAverages().averages.sleepAverage
                  )}
                </Text>
              </Box>
            )}

          {calcTotalsAndAverages().averages.onsetAverage !== null &&
            calcTotalsAndAverages().averages.onsetAverage !== 0 && (
              <Box bg="gray.100" p={4} borderRadius={8}>
                <Text fontSize="sm">Onset Average</Text>
                <Text fontWeight="bold" fontSize="md">
                  {formatHoursAndMinutes(
                    calcTotalsAndAverages().averages.onsetAverage
                  )}
                </Text>
              </Box>
            )}

          {calcTotalsAndAverages().averages.awakeningAverage !== null &&
            calcTotalsAndAverages().averages.awakeningAverage !== 0 && (
              <Box bg="gray.100" p={4} borderRadius={8}>
                <Text fontSize="sm">Awakenings Average</Text>
                <Text fontWeight="bold" fontSize="md">
                  {formatHoursAndMinutes(
                    calcTotalsAndAverages().averages.awakeningAverage
                  )}
                </Text>
              </Box>
            )}

          {calcTotalsAndAverages().averages.sleepEfficiencyAverage !== null &&
            calcTotalsAndAverages().averages.sleepEfficiencyAverage !== 0 && (
              <Box bg="gray.100" p={4} borderRadius={8}>
                <Text fontSize="sm">Sleep Efficiency Average</Text>
                <Text fontWeight="bold" fontSize="md">
                  {calcTotalsAndAverages().averages.sleepEfficiencyAverage.toFixed(
                    0
                  )}
                  %
                </Text>
              </Box>
            )}
        </Flex>
      )}

      {sleep?.map((s, index) => (
        <Box key={index} mb={4} pl={4} pr={4}>
          <Flex alignItems="center">
            <Box
              key={`day-box-${index}`}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flex="1"
              paddingTop={"20px"}
            >
              <Text fontSize="sm" color="grey">
                {s.dayType}
              </Text>
              <Box
                key={`date-box-${index}`}
                w="90px"
                h="80px"
                margin="2px"
                borderRadius="10px"
                bg="#F5F4F4"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="10px"
                flexDirection="column"
                textAlign="center"
              >
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    {new Date(s.day)
                      .toDateString()
                      .substring(0, 3)
                      .toUpperCase()}
                  </Text>
                  <Flex gap={"1"}>
                    <Text fontSize="sm">
                      {formatMonthAndDay(s.day).split(" ")[0]}
                    </Text>
                    <Text fontSize="sm" fontWeight={"bold"}>
                      {formatDate2(s.timeAsleep, s.timeAwake)}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </Box>
            <Flex
              padding="40px"
              gap="30px"
              flexDirection="column"
              width={"100%"}
            >
              <Flex
                placeItems={"center"}
                gap={s.activities.length > 0 ? 5 : 0}
                mt={2}
              >
                {s.activities && (
                  <Flex placeItems={"center"} gap={2}>
                    {s.activities?.map((activity, activityIndex) => {
                      const startTime = activity.startTime;
                      const endTime = activity.endTime;

                      return (
                        <Tooltip
                          key={`interval-${index}-${activityIndex}`}
                          label={
                            titles[activity.color] && (
                              <Box p={"2"}>
                                <Text
                                  fontSize={"md"}
                                  fontWeight="bold"
                                  color={"white"}
                                >
                                  {titles[activity.color]}
                                </Text>
                                <Text fontSize={"xs"} color={"white"}>
                                  {`${formatDateTo12hr(
                                    activity.startTime
                                  )} - ${formatDateTo12hr(activity.endTime)}`}
                                </Text>
                              </Box>
                            )
                          }
                        >
                          <Flex
                            key={`activity-${index}-${activityIndex}`}
                            gap={2}
                            alignItems="center"
                          >
                            <Box
                              key={`activity-box-${index}-${activityIndex}`}
                              w="25px"
                              h="25px"
                              borderRadius="40%"
                              bg={colors[activity.type] || "#CCCCCC"}
                            />
                            <Text
                              fontSize="xs"
                              color={colors[activity.type] || "#CCCCCC"}
                            >
                              <Text as="span" fontWeight="bold">
                                {formatDateTo12hr(startTime).slice(
                                  0,
                                  formatDateTo12hr(startTime).length - 1
                                )}
                              </Text>
                              <Text as="span">
                                {formatDateTo12hr(startTime).slice(
                                  formatDateTo12hr(startTime).length - 1,
                                  formatDateTo12hr(startTime).length
                                )}
                              </Text>
                              -
                              <Text as="span" fontWeight="bold">
                                {formatDateTo12hr(endTime).slice(
                                  0,
                                  formatDateTo12hr(endTime).length - 1
                                )}
                              </Text>
                              <Text as="span">
                                {formatDateTo12hr(endTime).slice(
                                  formatDateTo12hr(endTime).length - 1,
                                  formatDateTo12hr(endTime).length
                                )}
                              </Text>
                            </Text>
                          </Flex>
                        </Tooltip>
                      );
                    })}
                  </Flex>
                  //
                )}
                <Box>{s.additionalInfo && NotesPopover(s)}</Box>
              </Flex>

              <Box
                marginRight={10}
                key={`interval-box-${index}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex="5"
              >
                {calculateSleepIntervals(s).intervals.map(
                  (interval, intervalIndex) => {
                    showAbove = !showAbove;
                    const isLastInterval =
                      intervalIndex ===
                      calculateSleepIntervals(s).intervals.length - 1;

                    return (
                      <Tooltip
                        key={`interval-${index}-${intervalIndex}`}
                        label={
                          titles[interval.color] && (
                            <Box p={"2"}>
                              <Text
                                fontSize={"md"}
                                fontWeight="bold"
                                color={"white"}
                              >
                                {titles[interval.color]}
                              </Text>
                              <Text fontSize={"xs"} color={"white"}>
                                {`${formatDateTo12hr(
                                  interval.startTime
                                )} - ${formatDateTo12hr(interval.endTime)}`}
                              </Text>
                              <Text fontSize={"xs"} color={"white"}>
                                {interval.minutesInBetween > 60
                                  ? `(${Math.floor(
                                      interval.minutesInBetween / 60
                                    )} hr${
                                      interval.minutesInBetween % 60 !== 0
                                        ? ` ${
                                            interval.minutesInBetween % 60
                                          } min`
                                        : ""
                                    })`
                                  : `(${interval.minutesInBetween} min)`}
                              </Text>
                            </Box>
                          )
                        }
                      >
                        <Box
                          w={`${interval.minutesInBetween}%`}
                          h="20px"
                          borderRadius="20px"
                          bg={interval.color}
                          p={3.5}
                          position="relative"
                        >
                          <Text
                            key={`start-time-${index}-${intervalIndex}`}
                            position="absolute"
                            top={showAbove ? "-40%" : "140%"}
                            left="0%"
                            transform="translate(0%, -50%)"
                            fontWeight="bold"
                            fontSize="xs"
                            color="gray.600"
                          >
                            {formatDateTo12hr(interval.startTime)}
                          </Text>
                          {isLastInterval && (
                            <Text
                              key={`end-time-${index}-${intervalIndex}`}
                              position="absolute"
                              top={showAbove ? "140%" : "-40%"}
                              left="100%"
                              transform="translate(-50%, -50%)"
                              fontWeight="bold"
                              fontSize="xs"
                              color="gray.600"
                            >
                              {formatDateTo12hr(interval.endTime)}
                            </Text>
                          )}
                        </Box>
                      </Tooltip>
                    );
                  }
                )}
              </Box>
            </Flex>
          </Flex>
        </Box>
      ))}
    </Box>
  );
}
