import {Grid, Box, Text, useToast, Square, Flex, Button} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import Appointment from "./Appointment";
import AppointmentsList from "./AppointmentsList";
import Layout from "./Layout";
import {useContext, useState, useEffect, useMemo} from "react";
import Recording from "./Recording";
import {fetchSummaries} from "./helpers/fetchSummaries";
import {fetchBilling} from "./helpers/fetchBilling";
import PreviewFile from "../Files/PreviewFile";
import PreviewInvRecFile from "../../appointments/payment/helpers/PreviewFile";
import AppointmentModalWraper from "./AppointmentModalWraper";
import AppointmentNotes from "./AppointmentNotes";
import Summaries from "./Summaries";
import BookAppointment from "../../BookAppointment";
export default function Appointments({handleLinkToChart}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [summaries, setSummaries] = useState(null);
  const [billing, setBilling] = useState([]);
  const [layout, setLayout] = useState("list");
  const [preview, setPreview] = useState(false);
  const [previewInvRec, setPreviewInvRec] = useState(false);
  const [selectedTr, setSelectedTr] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false)
  const [bookRef, setBookRef] = useState(false);

  const [apptNotes, setApptNotes] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchSummaries(setSummaries, dashState, patient, toast);
    fetchBilling(setBilling, dashState, patient, toast);
  }, []);

  const hasAppointments = useMemo(() => {
    return (
      dashState.appointments &&
      dashState.appointments.some(
        (appointment) => appointment.pid === patient.pid
      )
    );
  }, [dashState.appointments]);

  const doctorsMap = useMemo(() => {
    if (dashState.doctor) return {[dashState.doctor.did]: dashState.doctor};
    return (
      dashState.doctors?.reduce((ac, e) => {
        ac[e.did] = e;
        return ac;
      }, {}) || {}
    );
  }, []);
  const billingMap = useMemo(() => {
    return (
      billing?.reduce((ac, e) => {
        ac[e.aid] = e;
        return ac;
      }, {}) || {}
    );
  }, [billing]);

  return (
    <Box
      textAlign="center"
      w="100%"
      height={"full"}
      my="2"
      display="flex"
      flexDirection={"column"}
      flexWrap={"wrap"}
      alignItems={"center"}
      position={"relative"}
      border="1pa solid red"
    >
      <AppointmentNotes {...{apptNotes, setApptNotes}} />
      {<AppointmentModalWraper {...{dispatch, schState: dashState}} />}

      {previewInvRec && (
        <PreviewInvRecFile
          preview={previewInvRec}
          setPreview={setPreviewInvRec}
          appointment={selectedTr}
          state={dashState}
        />
      )}
      {preview && (
        <PreviewFile
          preview={preview}
          setPreview={setPreview}
          state={dashState}
        />
      )}
      {hasAppointments && summaries != null ? (
        <>
          <Flex w="100%">
            <Flex justify="center" w="90%" mb="4">
            <Button
              onClick={() => setOpenAppointmentModal(true)}
              colorScheme='blue'
              className="mr-4"
              rounded="md"
              px="8"
              py="6"
              h="50px"
            >
              Add a new Appointment
            </Button>
            <Recording summaries={summaries} setSummaries={setSummaries} />
            </Flex>
            <Flex
              w="10%"
              justify={"flex-end"}
            >
              <Layout {...{setLayout, layout}} />
            </Flex>
          </Flex>
          {layout === "grid" ? (
            <Box overflowY="auto" w="full" flex={1}>
              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "1fr 1fr",
                  lg: "1fr 1fr 1fr",
                }}
                mt="4"
                gap={6}
                padding={4}
              >
                {dashState.appointments
                  .filter((appointment) => appointment.pid === patient.pid)
                  .sort((a, b) => new Date(b.ISOdate) - new Date(a.ISOdate))
                  .map((appointment) => (
                    <Appointment
                      apptDoctor={doctorsMap[appointment.did]}
                      setApptNotes={setApptNotes}
                      dispatch={dispatch}
                      state={dashState}
                      key={appointment.id}
                      appointment={appointment}
                      summaries={summaries}
                      transaction={billingMap[appointment.aid]}
                      setPreview={setPreview}
                      handleLinkToChart={handleLinkToChart}
                      setPreviewInvRec={setPreviewInvRec}
                      setSelectedTr={setSelectedTr}
                      {...{selectedSummary, setSelectedSummary}}
                    />
                  ))}
              </Grid>
            </Box>
          ) : (
            <AppointmentsList
              doctorsMap={doctorsMap}
              setApptNotes={setApptNotes}
              appointments={dashState.appointments
                .filter((appointment) => appointment.pid === patient.pid)
                .sort((a, b) => new Date(b.ISOdate) - new Date(a.ISOdate))}
              summaries={summaries}
              dashState={dashState}
              dispatch={dispatch}
              state={dashState}
              billing={billing}
              setPreview={setPreview}
              handleLinkToChart={handleLinkToChart}
              setPreviewInvRec={setPreviewInvRec}
              setSelectedTr={setSelectedTr}
              billingMap={billingMap}
              {...{selectedSummary, setSelectedSummary}}
            />
          )}
        </>
      ) : (
        <>
          <Box mx="auto" my="6" pb="6" px="6" boxShadow="md" bg="white">
            <Text textAlign="center" fontWeight="medium" color="gray.600" mt="4">
              No appointments found for this patient
            </Text>
          </Box>
          <Button
            onClick={() => setOpenAppointmentModal(true)}
            colorScheme='blue'
            className="mr-4"
            rounded="md"
            px="8"
            py="6"
            h="50px"
          >
            Add a new Appointment
          </Button>
        </>
      )}
      <Summaries
        selectedSummary={selectedSummary}
        setSelectedSummary={setSelectedSummary}
      />
      {openAppointmentModal && (
        <BookAppointment
          schState={dashState}
          stateChanger={setOpenAppointmentModal}
          dispatch={dispatch}
          patient={patient}
          defaultData={bookRef}
        />
      )}
    </Box>
  );
}
