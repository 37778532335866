import {AddIcon, DeleteIcon, SearchIcon} from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  ModalOverlay,
  useToast,
  Divider,
  Icon,
  Grid,
  GridItem,
  Box,
} from "@chakra-ui/react";
import {useCallback, useEffect, useState} from "react";
import {useProviderSearch} from "./helpers/providerAutocomplete";
import {BsFillSendFill, BsPencilFill} from "react-icons/bs";
import uploadProvider from "./helpers/insertProvider";
import changeProvider from "./helpers/updateProvider";
import removeProvider from "./helpers/deleteProvider";
import {debounce} from "lodash";
import {isValidPhoneNumber} from "./helpers/validateNumber";

const ProvidersList = ({
  onClose,
  setPage,
  state,
  field,
  setField,
  loading,
  providers,
  queryFn,
  setProvider,
  faxFn,
  search,
  setSearch,
}) => {
  const toast = useToast();
  const debouncedQueryFn = useCallback(
    debounce((query) => {
      queryFn(query);
    }, 1000),
    []
  );
  return (
    <Box h="530px">
      <ModalHeader>Choose a provider </ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Flex flexDirection="row" gap={2}>
          {/* search button */}
          <InputGroup>
            <InputRightElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputRightElement>
            <Input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debouncedQueryFn(e.target.value);
              }}
              type="text"
              placeholder="search provider"
            />
          </InputGroup>
          <IconButton
            onClick={() => setPage("add")}
            background="black"
            colorScheme="blue"
            aria-label="Add Provider"
            icon={<AddIcon />}
          />
        </Flex>
        {loading ? (
          <Text mt={4} h="300px">
            Loading...
          </Text>
        ) : (
          <Flex
            overflowY="scroll"
            h="300px"
            flexDirection="column"
            gap={2}
            mt={4}
          >
            {providers?.map((provider, index) => (
              <>
                <Grid templateColumns="1fr 1fr 1fr auto" p={2} key={index}>
                  <GridItem gridRow={1} gridColumn={1}>
                    <Text>{provider?.name}</Text>
                  </GridItem>
                  <GridItem gridRow={2} gridColumnStart={1} colSpan={3}>
                    <Text fontSize="sm">
                      <b>address: </b>
                      {provider?.address}
                    </Text>
                  </GridItem>
                  <GridItem gridRow={1} gridColumn={2}>
                    <Text>{provider?.fax}</Text>
                  </GridItem>
                  <GridItem gridRow={1} gridColumn={4}>
                    <Flex gap={2}>
                      <DeleteIcon
                        cursor="pointer"
                        onClick={() => {
                          toast.promise(
                            removeProvider(
                              provider?.provId || "",
                              state,
                              queryFn
                            ).then(() => setPage("list")),
                            {
                              success: {
                                title: "Deleted successfully",
                                description: "Looks great",
                              },
                              error: {
                                title: "Error",
                                description: "Something wrong",
                              },
                              loading: {
                                title: "Loading...",
                                description: "Please wait",
                              },
                            }
                          );
                        }}
                      />
                      <Icon
                        as={BsFillSendFill}
                        cursor="pointer"
                        fontSize="14px"
                        onClick={() => faxFn(provider?.fax || "")}
                      />
                      <Icon
                        cursor="pointer"
                        onClick={() => {
                          setProvider(provider);
                          setPage("edit");
                        }}
                        as={BsPencilFill}
                        fontSize="14px"
                      />
                    </Flex>
                  </GridItem>
                </Grid>
                <Divider />
              </>
            ))}
          </Flex>
        )}
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Box>
  );
};

const AddProvider = ({setPage, queryFn, state}) => {
  const toast = useToast();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [fax, setFax] = useState("");

  const validateFields = (name, fax) => {
    const isValidFax = isValidPhoneNumber(fax);
    if (name.length > 0 && isValidFax) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ModalHeader>Add a provider</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Flex gap={4} flexDirection="column">
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Provider Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input
              placeholder="Provider address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Fax</FormLabel>
            <Input
              placeholder="Provider Fax"
              value={fax}
              onChange={(e) => setFax(e.target.value)}
            />
          </FormControl>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            const areValidFields = validateFields(name, fax);
            if (areValidFields) {
              toast.promise(
                uploadProvider({fax, name, address}, state, queryFn).then(() =>
                  setPage("list")
                ),
                {
                  success: {
                    title: "Update successful",
                    description: "Looks great",
                  },
                  error: {
                    title: "Error",
                    description: "Something wrong",
                  },
                  loading: {title: "Loading...", description: "Please wait"},
                }
              );
            } else {
              toast({
                title: "Error",
                description: "Invalid Fields",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }}
          colorScheme="blue"
          mr={3}
        >
          Save
        </Button>
        <Button onClick={() => setPage("list")}>Cancel</Button>
      </ModalFooter>
    </>
  );
};
const EditProvider = ({setPage, queryFn, state, provider}) => {
  const toast = useToast();

  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [fax, setFax] = useState("");

  useEffect(() => {
    setName(provider?.name);
    setAddress(provider?.address);
    setFax(provider?.fax);
  }, [provider]);

  return (
    <>
      <ModalHeader>Edit provider</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Flex gap={4} flexDirection="column">
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Provider Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input
              placeholder="Provider address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Fax</FormLabel>
            <Input
              placeholder="Provider Fax"
              value={fax}
              onChange={(e) => setFax(e.target.value)}
            />
          </FormControl>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            toast.promise(
              changeProvider(
                {fax, name, address, provId: provider?.provId},
                state,
                queryFn
              ).then(() => setPage("list")),
              {
                success: {
                  title: "Update successful",
                  description: "Looks great",
                },
                error: {
                  title: "Error",
                  description: "Something wrong",
                },
                loading: {title: "Loading...", description: "Please wait"},
              }
            );
          }}
          colorScheme="blue"
          mr={3}
        >
          Save
        </Button>
        <Button onClick={() => setPage("list")}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

const ProvidersModal = ({isOpen, onClose, state, faxFn}) => {
  const [page, setPage] = useState("list");
  const {
    field,
    setField,
    loading,
    setLoading,
    providers,
    setProviders,
    search,
    setSearch,
    queryFn,
  } = useProviderSearch(state);

  const [provider, setProvider] = useState({});

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {page === "add" && (
          <AddProvider
            onClose={onClose}
            setPage={setPage}
            state={state}
            queryFn={queryFn}
          />
        )}
        {page === "list" && (
          <ProvidersList
            onClose={onClose}
            setPage={setPage}
            state={state}
            field={field}
            setField={setField}
            loading={loading}
            setLoading={setLoading}
            providers={providers}
            setProviders={setProviders}
            search={search}
            setSearch={setSearch}
            setProvider={setProvider}
            queryFn={queryFn}
            faxFn={faxFn}
          />
        )}
        {page === "edit" && (
          <EditProvider
            onClose={onClose}
            setPage={setPage}
            provider={provider}
            state={state}
            queryFn={queryFn}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProvidersModal;
