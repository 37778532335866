import axios from "axios";

import {url} from "../../../crudRequests";

function crudStorage(
  state,
  type,
  fileName,
  orgShareName,
  directoryName,
  subdirectoryName
) {
  return axios({
    method: "GET",
    url: `${url}/crudStorage`,
    headers: {
      Authorization: "Bearer " + state.jwt,
    },
    params: {
      s3BucketName: state.organization?.s3BucketName,
      id: state.doctor?.did || state.admin?.id,
      type: type,
      fileName: fileName,
      orgShareName: orgShareName,
      directoryName: directoryName,
      subdirectoryName: subdirectoryName,
    },
  });
}

export default async function deleteFile(state, path, match) {
  try {
    let res = await crudStorage(state, "view", "", "brightlighthealth", path);

    res = res.data;
    let arr = res?.filter(
      (f) => f.name.slice(-1) !== "/" && f.name.includes(match)
    );

    return Promise.all(
      arr.map((f) =>
        crudStorage(
          state,
          "deleteFile",
          path + "/" + f.name,
          "brightlighthealth",
          ""
        )
      )
    );
  } catch (error) {
    return Promise.resolve([]);
  }
}
