import {useContext} from "react";
import {Container, Flex, Text, Avatar, AvatarBadge} from "@chakra-ui/react";
import {lightFormat} from "date-fns";
import {DashStateContext, PatientContext} from "../../../pages/Patients";

export default function Messages({m, i, mark, usersStatus, selectedChat}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);

  return (
    <Container maxW={"100%"}>
      {mark}
      {m.senderId === dashState?.doctor.did ? (
        <Flex key={i} justifyContent={"flex-end"}>
          <Text
            mb={"-2.5px"}
            m={"3px"}
            mt="1.5"
            py={"6px"}
            px={"6px"}
            fontSize={"sm"}
            bg={"#4185F0"}
            color={"white"}
            borderRadius={"xl"}
            maxW={"50%"}
          >
            <Text pt={"4px"} pr={"30px"} pl={"6px"}>
              {m.message}
            </Text>

            <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
              {lightFormat(new Date(m.timestamp), "HH:mm")}
            </Text>
          </Text>
        </Flex>
      ) : (
        <Flex key={i} alignItems={"end"}>
          <Avatar
            size={"sm"}
            name={patient.fName + " " + patient.lName}
            mb={"8px"}
          >
            <AvatarBadge
              boxSize="0.75em"
              bg={
                usersStatus[selectedChat.usersId[0]] === "connected"
                  ? "green.300"
                  : "gray.400"
              }
            />
          </Avatar>
          <Text
            m={"10px"}
            ml={"7px"}
            mb="0"
            py={"6px"}
            px={"6px"}
            fontSize={"sm"}
            bg={"gray.200"}
            borderRadius={"xl"}
            maxW={"50%"}
          >
            <Text pt={"4px"} pr={"30px"} pl={"6px"}>
              {m.message}
            </Text>
            <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
              {lightFormat(new Date(m.timestamp), "HH:mm")}
            </Text>
          </Text>
        </Flex>
      )}
    </Container>
  );
}
