import {
  Grid,
  Box,
  Text,
  useToast,
  Square,
  Flex,
  Button,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {HiOutlinePlus} from "react-icons/hi";
import {useContext, useState, useEffect, useMemo} from "react";
import {IoSearch} from "react-icons/io5";
import {fetchNonSessions} from "./helpers/fetchNonSessions";
import NonSessionList from "./NonSessionList";
import EditOrCreate from "./EditOrCreate";
export default function Non_Sessions() {
  const [nsServices, setNsServices] = useState([]);
  const [showEditOrCreateModal, setShowEditOrCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);

  const toast = useToast();

  let filteredList = useMemo(() => {
    function filterText(service, text) {
      if (text.length === 0) {
        return service;
      } else if (service["dName"].toLowerCase().includes(text)) {
        // console.log(service["dName"]);
        return service;
      } else if (service["service"].toLowerCase().includes(text)) {
        //   console.log(service["service"]);
        return service;
      } else if (service["description"]?.toLowerCase().includes(text)) {
        console.log(service["description"]);
        return service;
      } else if (service["category"]?.toLowerCase().includes(text)) {
        return service;
      } else {
        return null;
      }
    }

    return nsServices.filter((s) => filterText(s, searchText));
  }, [searchText, nsServices]);

  useEffect(() => {
    fetchNonSessions((data) => setNsServices(data), dashState, patient, toast);
  }, []);

  return (
    <>
      {showEditOrCreateModal && (
        <EditOrCreate
          {...{
            showEditOrCreateModal,
            setShowEditOrCreateModal,
            selectedItem,
            setSelectedItem,
            patient,
            dashState,
            dispatch,
            setNsServices,
          }}
        />
      )}
      <Box
        textAlign="center"
        w="100%"
        height={"full"}
        display="flex"
        flexDirection={"column"}
        flexWrap={"wrap"}
        alignItems={"center"}
        position={"relative"}
        border="1ax solid red"
      >
        <Flex justify={"space-between"} w="full">
          <InputGroup p="1" pt="0" w="60">
            <InputLeftElement>
              <Icon as={IoSearch} color="gray.500" ml="2" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search..."
              onChange={(e) => {
                setSearchText(e.target.value.toLowerCase());
              }}
            />
          </InputGroup>
          <Button
            leftIcon={<HiOutlinePlus />}
            colorScheme="blue"
            pl={2}
            onClick={() => setShowEditOrCreateModal(true)}
          >
            Create
          </Button>
        </Flex>

        {filteredList.length > 0 ? (
          <NonSessionList
            {...{
              list: filteredList,
              setNsServices,
              dashState,
              patient,
              setSelectedItem,
              setShowEditOrCreateModal,
            }}
          />
        ) : (
          <Box
            mx="auto"
            my="6"
            pb="6"
            px="6"
            boxShadow="md"
            bg="white"
            w="full"
          >
            <Text
              textAlign="center"
              fontWeight="medium"
              color="gray.600"
              mt="4"
            >
              No Non-Sessions Service found for this patient
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
}
