import React, {useState} from "react";

import {AiOutlineDelete, AiOutlinePlusCircle} from "react-icons/ai";
import FileStorageSystem from "../../FileStorageSystem";
import verifyAccess from "../../../utils/verifyAccess";
import {FileIcon} from "react-file-icon";
import {fileProxy} from "../../../crudRequests";
// import PreviewFile from "./PreviewFile";
import {DeleteIcon, MinusIcon} from "@chakra-ui/icons";
import {Box, Button, Grid, GridItem, Text, Flex, Input} from "@chakra-ui/react";
import PreviewFile from "../../PreviewFile";

export const TherapySection = ({
  updateFormValue,
  therapyName,
  state,
  length_days,
  files,
  getFormValue,
  index,
  additionalNotes,
  removeSelf,
}) => {
  const [fileData, setFileData] = useState("");
  function previewFile(path, fileName) {
    // console.log(state, "brightlighthealth", path, fileName);
    fileProxy(state, "brightlighthealth", path, fileName)
      .then((res) => {
        // console.log({res});
        const mimeType = "image/jpeg";
        setFileData({data: res.data.data, fileName});
        setOpen(true);
      })
      .catch((e) => console.log({e}));
  }
  const [open, setOpen] = useState(false);
  return (
    <>
      <PreviewFile
        open={open}
        setOpen={setOpen}
        dataUrl={fileData.data}
        fileName={fileData.fileName}
      />
      <Grid templateColumns="repeat(7,1fr)" gap={4}>
        {index !== 0 && (
          <GridItem colSpan={7} justifySelf="end">
            <MinusIcon size="sm" cursor="pointer" onClick={removeSelf} />
          </GridItem>
        )}
        <GridItem colSpan={2}>
          <Text>name</Text>
          <Input
            value={therapyName}
            onChange={(e) => updateFormValue("therapyName", e.target.value)}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Text>Length (days)</Text>
          <Input
            value={length_days}
            onChange={(e) => updateFormValue("length_days", e.target.value)}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Text>Additional Notes</Text>
          <Input
            value={additionalNotes}
            onChange={(e) => updateFormValue("additionalNotes", e.target.value)}
          />
        </GridItem>
        <GridItem colSpan={4}>
          {state && verifyAccess(state, "documents") && (
            <FileStorageSystem
              state={state}
              path="therapies/"
              onClick={(obj) =>
                updateFormValue("files", files ? [...files, obj] : [obj])
              }
              patientData={{pid: "", fName: "", lName: ""}}
            />
          )}
        </GridItem>
        <GridItem colSpan={2}>
          <Text>Selected Files</Text>
          <Box bg="white" p={4} rounded="lg">
            {!files && <Text>No files selected</Text>}
            {files?.map(({name, path}, index) => {
              return (
                <Grid
                  key={index}
                  autoFlow="column"
                  py={2}
                  borderBottom={2}
                  alignItems="center"
                  cursor="pointer"
                  gap={2}
                  onClick={() => previewFile(path, name)}
                >
                  <Box w={10}>
                    <FileIcon
                      extension={
                        name?.split(".")[name?.split(".").length - 1] ||
                        "unknown"
                      }
                    />
                  </Box>
                  <Text fontSize="xs">{name}</Text>
                  <DeleteIcon
                    cursor="pointer"
                    onClick={() =>
                      updateFormValue(
                        "files",
                        files.filter((_, i) => i !== index)
                      )
                    }
                    size={27}
                  />
                </Grid>
              );
            })}
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};

// the medication section from the prescription page
const PrescribeTherapy = ({therapies, setTherapies, state}) => {
  return (
    <>
      {therapies.map((prescription, index) => {
        const getFormValue = (name) => therapies[index][name];
        return (
          <TherapySection
            index={index}
            getFormValue={getFormValue}
            removeSelf={() => {
              setTherapies((prev) => prev.filter((_, i) => i !== index));
            }}
            updateFormValue={(name, value) =>
              setTherapies((prev) => {
                let copy = [...prev];
                // get the medication from the array
                let obj = prev[index];
                // update the value
                obj[name] = value;
                // update the array
                copy[index] = obj;
                return copy;
              })
            }
            state={state}
            length_days={getFormValue("length_days")}
            additionalNotes={getFormValue("additionalNotes")}
            files={getFormValue("files")}
            therapyName={getFormValue("therapyName")}
          />
        );
      })}
      <Button
        bg="#9898B9"
        w="150px"
        onClick={() =>
          setTherapies((prev) => [
            ...prev,
            {
              therapyName: "",
              length_days: 0,
              additionalNotes: "",
              files: [],
            },
          ])
        }
      >
        <Text className="text-white">Add therapy</Text>
      </Button>
    </>
  );
};

export default PrescribeTherapy;
